import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ResumoImovelRural } from '../models/resumo-imovel-rural';
import { CafirService } from '../services/cafir.service';

@Component({
  selector: 'via-cafir',
  templateUrl: './cafir.component.html',
  styleUrls: ['./cafir.component.css'],
  providers: [ScopedMessageService]
})
export class CafirComponent implements OnInit {

  @Input() ni: string;

  imoveisRurais: ResumoImovelRural[];

  scopeId: string;
  initError: Error;
  stopLoading = false;
  executouObterDados = false;

  constructor(
    private messageService: ScopedMessageService,
    private cafirService: CafirService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterImoveis();
    }
  }

  obterImoveis(): void {
    this.executouObterDados = true;

    this.cafirService.obterImoveisRurais(this.ni).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum registro encontrado');
          this.stopLoading = true;
        }

        this.imoveisRurais = response;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro na consulta de imóveis rurais', error);
        this.initError = error;
      }
    );
  }
}
