<suite-rfb-collapse [title]="titulo">
    <div *ngIf="processos!=null else loadingOrError">
      <via-arrolamento-bens-sief-table
          [processos]="processos">
      </via-arrolamento-bens-sief-table>
    </div>
  </suite-rfb-collapse>
  
  <ng-template #loadingOrError>
    <div *ngIf="initError; else loading">
      Ocorreu um erro...
    </div>
    <ng-template #loading>
      <loading-animation></loading-animation>
    </ng-template>
  </ng-template>
