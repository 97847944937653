<div class="feature-container">
  <suite-rfb-collapse [title]="titulo">
    <loading-animation *ngIf="!debitos && !initError"></loading-animation>
    <div class="row" *ngIf="debitos">
      <fieldset *ngFor="let nomeTributo of debitos | keyvalue | currentPage:currentPage;">
        <via-ccpf-table 
          [titulo]="nomeTributo.key" 
          [debitos]="nomeTributo.value"
          [tipoDebito]="tipoPendencia"
          (regularizarDebito)="regularizarDebito($event)" 
          (emitirDarf)="emitirDarf($event)"
          (emitirDarfExercicio)="emitirDarfExercicio($event)">
        </via-ccpf-table>
      </fieldset>
    </div>
  </suite-rfb-collapse>
</div>