<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <ng-container *ngIf="resultadosPesquisa; else loadingOrError">
      <div class="table-responsive">
        <table suiteRfbTable>
          <caption *ngIf="parametrosPesquisa.periodoArrecadacao">Resultados da Pesquisa
            a partir de {{ parametrosPesquisa.periodoArrecadacao.dataInicial | date: 'dd/MM/yyyy' | vazio }}
            a {{ parametrosPesquisa.periodoArrecadacao.dataFinal | date: 'dd/MM/yyyy' | vazio }}
          </caption>
          <thead>
            <tr>
              <th class="text-center min-width">Ações</th>
              <th class="text-center min-width">
                <suite-rfb-toggle title="Expandir/Contrair Todos Detalhes" (expanded)="expandeTodosDetalhes($event)">
                </suite-rfb-toggle>
              </th>
              <th class="text-center min-width">Receita</th>
              <th class="text-center min-width">Data Arrec.</th>
              <th class="text-center min-width">Bco/Ag.</th>
              <th class="text-center min-width">Data Venc.</th>
              <th class="text-center min-width">PA</th>
              <th class="text-center min-width">Valor Total</th>
              <th class="text-center min-width">Situação</th>
              <th class="text-center">Sist. Interesse</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let resultado of resultadosPesquisa | currentPage: currentPage;">
              <tr class="vertical-align-middle">
                <td class="text-center">
                  <!-- TODO: implementar regra de impressão DAS/DARF -->
                  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes">
                  </suite-rfb-popover-button>
                  <ng-template #menuAcoes>
                    <div class="btn-group-vertical" role="group">
                      <button type="button" class="btn btn-default"
                        (click)="emitirComprovante(resultado); botaoAcoes.hide();">
                        Emitir Comprovante
                      </button>
                      <button type="button" class="btn btn-default"
                        (click)="iniciarRetificacaoSimplificada(resultado); botaoAcoes.hide();">
                        Retificação Simplificada
                      </button>
                      <button type="button" class="btn btn-default"
                        (click)="iniciarRetificacaoCompleta(resultado); botaoAcoes.hide();">
                        Retificação Completa
                      </button>
                      <button type="button" class="btn btn-default"
                        (click)="trocarAssinalamento(resultado); botaoAcoes.hide();">
                        Trocar Assinalamento
                      </button>
                      <button type="button" class="btn btn-default"
                        [disabled]="resultado.sistemaInteresseCd !== alocacaoAutomaticaTipoSistemaPermitido"
                        (click)="impedirAlocacaoAutomatica(resultado); botaoAcoes.hide();">
                        Impedir Alocação Automática
                      </button>
                      <button type="button" class="btn btn-default"
                        [disabled]="resultado.sistemaInteresseCd !== alocacaoAutomaticaTipoSistemaPermitido"
                        (click)="liberarAlocacaoAutomatica(resultado); botaoAcoes.hide();">
                        Liberar Alocação Automática
                      </button>
                    </div>
                  </ng-template>
                </td>
                <td>
                  <suite-rfb-toggle #detalhes></suite-rfb-toggle>
                </td>
                <td class="text-center">
                  <via-codigo-receita [codigo]="resultado.codigoReceita">
                  </via-codigo-receita>
                </td>
                <td class="text-center min-width text-nowrap">
                  {{ resultado.dataArrecadacao | date: 'dd/MM/yyyy' | vazio }}
                </td>
                <td class="text-center min-width text-nowrap">{{ resultado.bancoCd }}/{{ resultado.agenciaCd }}</td>
                <td class="text-center min-width text-nowrap">
                  {{ resultado.dataVencimento | date: 'dd/MM/yyyy' | vazio }}
                </td>
                <td class="text-center min-width text-nowrap">{{ resultado.periodoApuracao | vazio }}</td>
                <td class="text-right text-nowrap">{{ resultado.valorTotal | formatCurrency | vazio }}</td>
                <td class="text-center min-width text-nowrap">{{ resultado.situacaoDesc | vazio }}</td>
                <td class="text-left">{{ resultado.sistemaInteresseDesc | vazio }}</td>

              </tr>
              <ng-container *ngIf="detalhes.show">
                <tr></tr>
                <tr>

                  <td colspan="10" class="background-inherit">
                    <div class="contorno">
                      <darf-detalhes *ngIf="resultado.tipoDocumento !== constDJE && resultado.tipoDocumento !== constDAS"
                        [documento]="resultado"
                        [parametrosConsultaPagamento]="parametrosPesquisa"
                        [atendimento]="atendimento">
                      </darf-detalhes>
                      <das-detalhes  *ngIf="resultado.tipoDocumento == constDAS"
                        [documento]="resultado"
                        [parametrosConsultaPagamento]="parametrosPesquisa"
                        [atendimento]="atendimento">
                      </das-detalhes>
                      <dje-detalhes  *ngIf="resultado.tipoDocumento == constDJE"
                        [documento]="resultado"
                        [parametrosConsultaPagamento]="parametrosPesquisa"
                        [atendimento]="atendimento">
                      </dje-detalhes>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="10">
                <via-pagination [itemAmount]="resultadosPesquisa.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
  </div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
