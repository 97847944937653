import { Directive, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

const BLACK_LIST: string[] = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
];

@Directive({
  selector: '[viaValidNi]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NiValidatorDirective, multi: true }]
})
export class NiValidatorDirective implements Validator, OnInit {

  private valFn = Validators.nullValidator;

  ngOnInit(): void {
    this.valFn = niValidator();
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valFn(control);
  }
}
export function niValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value = control.value;
    const validNi = isValidNi(value);
    return validNi ? null : { 'invalidNi': { value } };
  };
}

export function isValidNi(maskedNi: string): boolean {
  if (!maskedNi) {
    return true;
  }
  const unmaskedNi: string = maskedNi.replace(/[^\d]+/g, '');
  if (!unmaskedNi) {
    return true;
  }
  if ((unmaskedNi.length !== 11 && unmaskedNi.length !== 14) || isBlackListed(unmaskedNi)) {
    return false;
  }

  if (unmaskedNi.length == 14) {
    const dvs: string = unmaskedNi.substring(12);
    const dv1: number = calculateCnpjDv(unmaskedNi.substring(0, 12));
    const dv2: number = calculateCnpjDv(unmaskedNi.substring(0, 13));
    return (dvs.charAt(0) === dv1.toString() && dvs.charAt(1) === dv2.toString());
  } else if (unmaskedNi.length == 11) {
    const dvs: string = unmaskedNi.substring(9);
    const dv1: number = calculateCpfDv(unmaskedNi.substring(0, 9));
    const dv2: number = calculateCpfDv(unmaskedNi.substring(0, 10));
    return (dvs.charAt(0) === dv1.toString() && dvs.charAt(1) === dv2.toString());
  }
}

function isBlackListed(ni: string): boolean {
  return BLACK_LIST.indexOf(ni) >= 0;
}

function calculateCnpjDv(base: string): number {
  const length: number = base.length;
  let sum: number = 0;
  let pos: number = length - 7;
  for (let i: number = length; i >= 1; i--) {
    sum += Number.parseInt(base.charAt(length - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  const result: number = sum % 11 < 2 ? 0 : 11 - sum % 11;
  return result;
}

function calculateCpfDv(base: string): number {
  const length: number = base.length;
  let sum: number = 0;
  for (let i: number = 0; i < length; i++) {
    sum += Number.parseInt(base.charAt(i)) * ((length + 1) - i);
  }
  let result: number = 11 - (sum % 11);
  if (result === 10 || result === 11) {
    result = 0;
  }
  return result;
}
