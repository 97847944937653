<div [ngClass]="{ 'modal-container': isModal }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <ng-container *ngIf="dadosBasicos; else loadingOrError;">
        <div class="container-fluid container-opcoes">
          <via-consulta-dados-basicos-pf [cpf]="cpf" [dadosBasicos]="dadosBasicos">
          </via-consulta-dados-basicos-pf>

          <via-historico [cpf]="cpf"></via-historico>

          <via-cnpjs-vinculados-pf [cpf]="cpf"></via-cnpjs-vinculados-pf>

          <via-cadeia-ni [dadosBasicos]="dadosBasicos"></via-cadeia-ni>

          <via-documentos [cpf]="cpf"></via-documentos>

          <via-dirf [cpf]="cpf"></via-dirf>

          <via-dirpf [cpf]="cpf"></via-dirpf>

          <via-doi [cpf]="cpf"></via-doi>

          <via-consulta-malha-irpf [cpf]="cpf"></via-consulta-malha-irpf>

          <via-informacoes-complementares-pf [cpf]="cpf"></via-informacoes-complementares-pf>

          <via-omissos-pf [cpf]="cpf"></via-omissos-pf>
        </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
