import { isEmpty } from 'lodash';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingService, Page } from '@serpro/ngx-suite-rfb';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { LogService } from 'src/app/shared/services/log.service';
import { DocumentoArrecadacao } from 'src/app/pagamento/models/documento-arrecadacao';
import { ParametrosPesquisaPagamento } from 'src/app/pagamento/models/parametros-pesquisa';
import { CONSTANTS } from 'src/app/pagamento/models/constants';
import { throwError } from 'rxjs';

@Component({
  selector: 'darf-detalhes',
  templateUrl: './darf-detalhes.component.html',
  styleUrls: ['./darf-detalhes.component.css'],
  providers: [ScopedMessageService]
})

export class DarfDetalhesComponent implements OnInit {

  @Input() documento: DocumentoArrecadacao;
  @Input() parametrosConsultaPagamento: ParametrosPesquisaPagamento;
  @Input() parentScopeId: string;
  @Input() atendimento = false;
  scopeId: string;

  semComponente = false;
  constSistemaInteresseCCPF: string = CONSTANTS.SISTEMA_INTERESSE_CCPF;


  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService
) {

  }

  ngOnInit(): void {
   // this.documento.sistemaInteresseDesc = '01';
    this.scopeId = this.messageService.scopeId;
    this.semComponente = isEmpty(this.documento);

  }


 };








