import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ConsultaPagamentosCcpfComponent } from '../consulta-pagamento/consulta-pagamentos.component';
import { ParametroPagamentosDebitoCCPF, ParametrosFiltroPagamentoCcpf } from '../models/parametros-pesquisa';

@Component({
  selector: 'via-ccpf-pagamentos-filtro-pesquisa',
  templateUrl: './filtros-pesquisa-pagamento.component.html',
  styleUrls: ['./filtros-pesquisa-pagamento.component.css'],
  providers: [ScopedMessageService]
})
export class FiltrosPesquisaCcpfPagamentoComponent implements IDynamicPanel, OnInit, OnDestroy {

  @Input() parametros: ParametroPagamentosDebitoCCPF;
  @Input() parentScopeId: string;
  @Input() atendimento: Boolean;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  parametrosFiltro: ParametrosFiltroPagamentoCcpf;

  featureEventSub: Subscription;

  titulo: string;

  initError: Error;

  formGroup: FormGroup;
  arrecadacaoDeCtrl: FormControl;
  arrecadacaoAteCtrl: FormControl;

  arrecadacaoDeName: string = 'arrecadacaoDe';
  arrecadacaoAteName: string = 'arrecadacaoAte';

  filtroColapsado = false;
  exibeResultado = false;

  ni: string;

  scopeId: string;

  constructor(
    private formBuilder: FormBuilder,
    private dynamicModalRequest: DynamicModalRequestService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  public init(ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametroPagamentosDebitoCCPF;
    this.ni = ni;
    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharFiltro();
          this.featureEventSub.unsubscribe();
        }
      });
  }

  public ngOnInit(): void {
    this.buildForm();
    this.addValidators();
    this.scopeId = this.messageService.scopeId;
    this.titulo = `CCPF - Consultar Pagamentos - CPF: ${FormatCpfPipe.formatCPF(this.parametros.cpfContribuinte)}`;
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      arrecadacaoDe: this.arrecadacaoDeCtrl = new FormControl(''),
      arrecadacaoAte: this.arrecadacaoAteCtrl = new FormControl(''),
    });
  }

  addValidators(): void {
    this.arrecadacaoDeCtrl.addValidators(this.dataArrecadacaoValidator());
    this.arrecadacaoAteCtrl.addValidators(this.dataArrecadacaoValidator());
  }

  dataArrecadacaoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if ((null != this.arrecadacaoDe && null != this.arrecadacaoAte)
        && (this.arrecadacaoDe.toString().trim() !== "" && this.arrecadacaoAte.toString().trim() !== "") && (this.arrecadacaoDe > this.arrecadacaoAte)) {
        this.messageService.showWarning("Data final da arrecadação deve ser maior do que a inicial", "Atenção");
        return { datas_arrecadacao_invalidas: true };
      }

      this.messageService.dismissMessages();
      return null;
    };
  }

  private fecharFiltro(): void {
    this.menuFeatureActionsService.deactivate({
      id: 'ccpf-consultarpagamentos' + this.parametros.cpfContribuinte,
      title: 'CCPF - Consultar Pagamentos',
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO,
      class: FiltrosPesquisaCcpfPagamentoComponent
    });
  }

  public get arrecadacaoDe(): Date {
    return this.formGroup.get(this.arrecadacaoDeName).value as Date;
  }

  public get arrecadacaoAte(): Date {
    return this.formGroup.get(this.arrecadacaoAteName).value as Date;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  onSubmit(): void {

    this.messageService.dismissMessages();

    let arrecadacaoInicial: Date;
    let arrecadacaoFinal: Date;

    let naoTemDataInicial = !this.arrecadacaoDe
      || (this.arrecadacaoDe.toString().trim() === "");

    let naoTemDataFinal = !this.arrecadacaoAte
      || (this.arrecadacaoAte.toString().trim() === "");

    if (naoTemDataInicial && naoTemDataFinal) {
      arrecadacaoInicial = new Date(0);
      arrecadacaoFinal = new Date();
    } else {
      arrecadacaoInicial = naoTemDataInicial ? new Date(0) : this.arrecadacaoDe;
      arrecadacaoFinal = naoTemDataFinal ? new Date() : this.arrecadacaoAte;
    }

    this.parametrosFiltro = {
      cpfContribuinte: this.parametros.cpfContribuinte,
      dataArrecadacaoInicial: arrecadacaoInicial,
      dataArrecadacaoFinal: arrecadacaoFinal,
      ct: this.parametros.ct,
      isModal: this.ni ? false : true
    }

    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
    } else {
      let feature = new FeatureAction();
      feature.class = ConsultaPagamentosCcpfComponent;
      feature.parametros = this.parametrosFiltro;
      this.dynamicModalRequest.abrirModal(feature);
    }
  }

  onReset() {
    this.messageService.dismissMessages();
    this.arrecadacaoDeCtrl.reset();
    this.arrecadacaoAteCtrl.reset();
  }

  public ngOnDestroy(): void {
    if (this.featureEventSub) {
      this.featureEventSub.unsubscribe();
    }
  }
}
