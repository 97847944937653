import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheDeclaracaoOperacaoImobiliaria } from '../../models/detalhe-declaracao-operacao-imobiliaria';
import { ResumoDeclaracaoOperacaoImobiliaria } from '../../models/resumo-declaracao-operacao-imobiliaria';
import { DoiService } from '../../services/doi.service';

/*
  ESTRUTURA PARA CONTROLAR A CHAMADA AO SERVIÇO DE DETALHE DA DECLARAÇÃO,
  PARA QUE OCORRA APENAS NO PRIMEIRO EXPANDED DO TOGGLE
*/
type ControleDeclaracao = {
  executouChamada: boolean;
  declaracao: DetalheDeclaracaoOperacaoImobiliaria;
};

@Component({
  selector: 'via-doi-table',
  templateUrl: './doi-table.component.html',
  styleUrls: ['./doi-table.component.css']
})
export class DoiTableComponent implements OnInit {

  @Input() cpf: string;
  @Input() declaracoes: ResumoDeclaracaoOperacaoImobiliaria[];
  @Input() parentScopeId: string;

  detalhePorDeclaracao = new Map<string, ControleDeclaracao>();

  scopeId: string;
  initError: Error;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private messageService: ScopedMessageService,
    private doiService: DoiService) { }

  ngOnInit(): void {
    this.scopeId = this.parentScopeId ? this.parentScopeId : this.messageService.scopeId;

    this.declaracoes?.forEach(declaracao => {
      this.detalhePorDeclaracao.set(declaracao.id, {
        executouChamada: false,
        declaracao: null
      });
    });
  }

  onCarregarDetalhe(expanded: boolean, declaracao: ResumoDeclaracaoOperacaoImobiliaria): void {

    const executouChamada = this.detalhePorDeclaracao.get(declaracao.id).executouChamada;

    if (expanded && !executouChamada) {
      this.obterDetalheDeclaracao(declaracao.id);
    }
  }

  private obterDetalheDeclaracao(identificadorDeclaracao: string): void {
    this.doiService.obterDetalheDeclaracaoOperacaoImobiliaria(this.cpf, identificadorDeclaracao).subscribe(
      response => {
        this.detalhePorDeclaracao.get(identificadorDeclaracao).executouChamada = true;
        this.detalhePorDeclaracao.get(identificadorDeclaracao).declaracao = response;
      },
      error => {
        this.messageService.showErrorException(`Ocorreu um erro na consulta de detalhe da declaração com ID: ${identificadorDeclaracao}`, error);
        this.initError = error;
      }
    );
  }
}
