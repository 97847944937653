import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPerdcompPipe'
})
export class FormatPerdcompPipe implements PipeTransform {
  transform(nroPerdcomp: string) {
    if(nroPerdcomp){
      return FormatPerdcompPipe.formatPerdcomp(nroPerdcomp);
    }
    return null;
  }

  static formatPerdcomp: Function = (nroPerdcomp: string) => {
    nroPerdcomp = nroPerdcomp+"";
      let pad = "000000000000000000000000";
      let perdcomp24pos = (pad + nroPerdcomp).slice(-pad.length);
      let perdcompFormat = perdcomp24pos.substring(0, 5) + "." + perdcomp24pos.substring(5, 10) + "." +
        perdcomp24pos.substring(10, 16) + "." + perdcomp24pos.substring(16, 17) + "." +
        perdcomp24pos.substring(17, 18) + "." + perdcomp24pos.substring(18, 20) + "-" +
        perdcomp24pos.substring(20, 24);
      return perdcompFormat;
  }

}


