import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { environment } from 'src/environments/environment';
import { DirpfDTO } from '../model/dirpf';

@Component({
  selector: 'via-dirpf-table',
  templateUrl: 'dirpf-table.component.html',
  styleUrls: ['dirpf-table.component.css']
})
export class DirpfTableComponent {

  @Input() dirpfs: DirpfDTO[];
  @Input() cpf: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  onClickExtrato(ano:string, numero:string): void {
    let url = environment.urlExtratoDirpf+`${this.cpf}/${ano}/${numero}`;
    window.open(url,"_blank");
  }
}