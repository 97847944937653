import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcessoSief } from '../../model/processo-sief';

@Injectable()
export class ProcessoSiefService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterProcessos(ni: string, numeroProtocolo: string, cobranca: boolean): Observable<ProcessoSief[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sief/processos/${ni}/${cobranca ? 'Cobranca' : 'Exigibilidade'}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ProcessoSief[]>(url, options);
  }
}
