import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { FeatureAction } from "../model/feature";

@Injectable({ providedIn: 'root' })
export class DynamicModalRequestService {

  private fechar = new Subject<number>();
  public fechar$: Observable<number> = this.fechar.asObservable();

  private cpf = new Subject<string>();
  public cpf$: Observable<string> = this.cpf.asObservable();

  private cnpj = new Subject<string>();
  public cnpj$: Observable<string> = this.cnpj.asObservable();

  private tituloEleitor = new Subject<string>();
  public tituloEleitor$: Observable<string> = this.tituloEleitor.asObservable();

  private modalFeature = new Subject<FeatureAction>();
  public modalFeature$: Observable<FeatureAction> = this.modalFeature.asObservable();

  constructor(private modalService: BsModalService) { }

  public fecharModal() {
    this.fechar.next(0);
  }

  public exibirDadosBasicosCnpj(cnpj: string) {
    this.cnpj.next(cnpj);
  }

  public exibirDadosBasicosCpf(cpf: string) {
    this.cpf.next(cpf);
  }

  public exibirDadosTituloEleitor(cpf: string) {
    this.tituloEleitor.next(cpf);
  }

  public abrirModal(featureAction: FeatureAction): void {
    this.modalFeature.next(featureAction);
  }
}
