import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DadosBasicosPfDTO } from '../dados-basicos-pf/model/dados-basicos-pf-dto';
import { DadosResumidosPfDTO } from '../dados-basicos-pf/model/dados-resumidos-pf-dto';

@Injectable()
export class CadeiaNiService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public registrarEventoCadeiaNi(cpf: string): Observable<void> {
    const url = `${this.baseUrl}/cpf/cadeia-ni/evento-lel/${cpf}`;
    return this.http.post<void>(url, null);
  }

  public obterDadosResumidosDaCadeiaNi(dadosBasicos: DadosBasicosPfDTO): Observable<DadosResumidosPfDTO[]> {

    if (isEmpty(dadosBasicos.cadeiaCpf)) {
      return of([]);
    }

    const resumos = [] as Observable<DadosResumidosPfDTO>[];

    dadosBasicos.cadeiaCpf.forEach(cpf => {
      let url = `${this.baseUrl}/cpf/resumo/${cpf}`;
      resumos.push(this.http.get<DadosResumidosPfDTO>(url));
    });

    return forkJoin(resumos);
  }
}
