import { Component, Input } from '@angular/core';
import { ParametrosPesquisaPessoaFisica } from 'src/app/cpf/models/parametros-pesquisa-pessoa-fisica';

@Component({
  selector: 'via-parametros-pesquisa-pf',
  templateUrl: './parametros-pesquisa-pf.component.html',
  styleUrls: ['./parametros-pesquisa-pf.component.css']
})
export class ParametrosPesquisaPfComponent {

  @Input() parametrosPesquisa: ParametrosPesquisaPessoaFisica;
}
