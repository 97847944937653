<div class="table-responsive">
  <table suiteRfbTable>
    <caption class="table-group-caption">Empresas Vinculadas</caption>
    <thead>
      <tr>
        <th class="text-center min-width">#</th>
        <th class="text-center min-width">CNPJ</th>
        <th class="text-center">Razão Social</th>
        <th class="text-center">Participação</th>
        <th class="text-center">Início</th>
        <th class="text-center">Término</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of cnpjsVinculados | currentPage:currentPage; index as linha" class="vertical-align-middle">
          <td class="text-center">
            {{ ((currentPage.number - 1) * pageSize + linha + 1) }}
          </td>
          <td class="text-center">
            <ni-com-link [ni]="item.cnpj" tipoNI="CNPJ"></ni-com-link>
          </td>
          <td class="text-left">{{ item.razaoSocial }}</td>
          <td class="text-center">{{ item.tipoParticipacao }}</td>
          <td class="text-center">{{ item.dataInicio | date: 'shortDate' | vazio }}</td>
          <td class="text-center">{{ item.dataFim | date: 'shortDate' | vazio }}</td>
          <td class="text-left">{{ item.situacaoEmpresa }}</td>
       </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="7">
          <via-pagination [itemAmount]="cnpjsVinculados.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
