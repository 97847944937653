import { Component } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DeclaracaoIrpfMalhaService } from './declaracao-irpf-malha.service';
import { DeclaracaoIrpfMalhaDTO } from './model/declaracao-irpf-malha';

@Component({
  selector: 'declaracao-irpf-malha',
  templateUrl: './declaracao-irpf-malha.component.html',
  styleUrls: ['./declaracao-irpf-malha.component.css']
})
export class DeclaracaoIrpfMalhaComponent implements IDynamicPanel {

  initError: Error;
  titulo: string;
  cpf: string;

  loadingError$ = new Subject<boolean>();
  declaracoesObservable$: Observable<DeclaracaoIrpfMalhaDTO[]>;

  constructor(
    private declaracaoIrpfService: DeclaracaoIrpfMalhaService,
    private messageService: ScopedMessageService) {
  }

  public init(cpf: string): void {
    this.cpf = cpf;
    this.titulo = `Malha Pendências Declarações IRPF - CPF: ${FormatCpfPipe.formatCPF(cpf)}`;

    this.declaracoesObservable$ = this.declaracaoIrpfService.obterDeclaracoesIRPFMalha(cpf)
      .pipe(
        catchError(err => {
          this.loadingError$.next(true);
          this.initError = err;
          this.messageService.showErrorException('Malha Pendências Declarações IRPF', err);
          return of<DeclaracaoIrpfMalhaDTO[]>();
        })
      );
  }

  public emitirExtrato(declaracao: DeclaracaoIrpfMalhaDTO): void {
    this.abreUrl(this.declaracaoIrpfService.getUrlExtratoIrpf(this.cpf, declaracao));
  }

  public abrirPortalIrpf(): void {
    this.abreUrl(this.declaracaoIrpfService.getUrlPortalIrpf());
  }

  public abrirPortalSiefWeb(): void {
    this.abreUrl(this.declaracaoIrpfService.getUrlPortalSiefWeb());
  }

  private abreUrl(url: string) {
    window.open(url, "_blank");
  }
}
