import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "@serpro/ngx-suite-rfb";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from 'rxjs';
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { environment } from 'src/environments/environment';
import { Das } from "../../model/das";
import { ParametrosDas } from "../../model/parametros-das";
import { DasMensagensComponent } from "./das-mensagens.component";
import { DasVisualizacaoComponent } from "./das-visualizacao.component";
import FileSaver from 'file-saver';

@Injectable()
export class DasService {

    constructor(
        private messageService: ScopedMessageService,
        private modalService: BsModalService,
        private http: HttpClient,
        private loadingService: LoadingService,
    ) { }

    private baseUrl = environment.APIEndpoint;

    emitirDas(parametro: ParametrosDas, mensagem: number) {
      let bsModalRef;
      if(mensagem != null && mensagem > 0) {
        DasMensagensComponent.prototype.mensagem = mensagem;
        bsModalRef = this.modalService.show(DasMensagensComponent);
        bsModalRef.content.resultado.subscribe(
          resultado => {
            bsModalRef.hide();
            if( resultado == "emitir" ) {
              this.exibirDas(parametro);
            }
          }
        )
      }
      else {
        this.exibirDas(parametro);
      }
    }

    exibirDas(parametro: ParametrosDas) {
        this.loadingService.show();
        this.gerarDas(parametro).subscribe(
            (das: Das) => {
              this.loadingService.hide();
              if(window['navigator']['pdfViewerEnabled']) {
                DasVisualizacaoComponent.prototype.das = das;
                let bsModalRef = this.modalService.show(DasVisualizacaoComponent,{class: 'das-dialog'});
                bsModalRef.content.bsModalRef = bsModalRef;
              }
              else {
                const byteCharacters = atob(das.das);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });
                FileSaver.saveAs(blob, `DAS-${parametro.processo}.pdf`);
              }
            },
            (erro) => {
                this.loadingService.hide();
                this.messageService.showErrorException('Ocorreu um erro ao gerar o DAS', erro);
            }
        );
    }

    public gerarDas(parametrosDas: ParametrosDas): Observable<Das>{
		const url = `${this.baseUrl}/pgdas/das`;
		return this.http.post<Das>(url, parametrosDas);
    }
}
