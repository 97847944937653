import { Pipe, PipeTransform } from '@angular/core';
import { EnderecoPfDTO } from 'src/app/cpf/components/dados-basicos-pf/model/endereco-pf-dto';
import { VazioPipe } from 'src/app/shared/pipes/vazio/vazio.pipe';
@Pipe({
  name: 'enderecoInfoCadPipe'
})
export class EnderecoInfoCadPipe implements PipeTransform {

  public transform(endereco: EnderecoPfDTO): string {


    return (endereco.logradouro ? endereco.logradouro : "") + 
        (endereco.numero ? ", " + endereco.numero : "") +
        (endereco.complemento ? ", " + endereco.complemento : "");

  }
}

