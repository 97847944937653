import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { HistoricoService } from './historico.service';
import { OperacaoHistorico } from './model/operacao-historico';

@Component({
  selector: 'via-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css'],
  providers: [ScopedMessageService]
})
export class HistoricoComponent implements OnInit {

  @Input() cpf: string;
  operacoes: OperacaoHistorico[];
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  scopeId: string;
  stopLoading = false;
  initError: Error;

  private executouObterDados = false;

  constructor(
    private historicoService: HistoricoService,
    private messageService: ScopedMessageService) { }

  ngOnInit() {
    this.scopeId = this.messageService.scopeId;
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterHistoricoOperacoes();
    }
  }

  private obterHistoricoOperacoes(): void {
    this.executouObterDados = true;

    this.historicoService.obterHistoricoOperacoes(this.cpf)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum registro encontrado');
            this.stopLoading = true;
          }

          this.operacoes = response;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter o histórico de operações da pessoa física', error);
          this.initError = error;
        }
      );
  }
}
