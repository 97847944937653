import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { CobrancaSuiteRfbModule } from './cobranca-suite-rfb.module';
import { CcpfModule } from './components/ccpf/ccpf.module';
import { CcitrModule } from './components/ccitr/ccitr.module';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		HttpClientModule,
		SharedModule,
		PipesModule.forRoot(),
		CobrancaSuiteRfbModule,
		CcpfModule,
		CcitrModule
	],
	declarations: [
	],
	exports: [
	],
	providers: [
	]
})
export class CobrancaModule { }
