<div class="menu-lateral-ativo">
  <div class="menulateral">
    <div id="suite-rfb-menu-container">
      <div class="busca">
        <label for="busca" class="sr-only">Buscar funcionalidade</label>

        <div class="input-group">
          <input #searchInput
            type="search"
            id="busca"
            class="form-control"
            style="width:100% !important;"
            (input)="search($event.target.value)"
            autocomplete="off">

          <div class="input-group-btn">
            <button
              id="clearFilter"
              title="Limpar filtros"
              type="button"
              class="btn-suite-rfb"
              (click)="searchInput.value=''; search('')">
              <em class="icon icon-suite-rfb-filter-remove icon-24"></em>
            </button>
          </div>
        </div>
      </div>

      <ul class="menu">
        <div class="text-center" *ngIf="!features">
          <loading-animation></loading-animation>
        </div>

        <ng-container *ngFor="let feature of features">
          <via-menu-item [feature]="feature"></via-menu-item>
        </ng-container>
      </ul>
    </div>
  </div>
</div>