<suite-rfb-collapse title="Receita" [collapsed]="false">
    <div class="container-fluid">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [suiteRfbHasError]="receitaCtrl">
              <label [for]="receitaFormGroupName" class="control-label">Receita</label>
              <input
                class="form-control"
                type="text"
                [formControlName]="receitaFormGroupName"
                autocomplete="off"
                suiteRfbInputMask="9999">
            </div>
          </div>
  
          <div class="col-md-6" style="margin-top: 25px;">
            <div class="form-group">
              <div class="text-center">
                <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
                <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </suite-rfb-collapse>
  
