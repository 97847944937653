<suite-rfb-collapse title="CNPJ" [collapsed]="false">
  <div class="container-fluid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group" [suiteRfbHasError]="cnpjCtrl">
            <label [for]="cnpjFormGroupName" class="control-label">CNPJ</label>
            <input
              class="form-control"
              type="text"
              [formControlName]="cnpjFormGroupName"
              autocomplete="off"
              suiteRfbInputMask="99.999.999/9999-99"
              suiteRfbValidCnpj>
          </div>
        </div>

        <div class="col-md-6" style="margin-top: 25px;">
          <div class="form-group">
            <div class="text-center">
              <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
              <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid">Buscar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>
