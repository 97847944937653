import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@serpro/ngx-suite-rfb';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.APIEndpoint;

  constructor(
    private http: HttpClient
  ) { }

  getLoggedUser(): Observable<User> {
    const url = this.baseUrl + '/loggeduser';
    return this.http.get<User>(url);
  }
}
