import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DeclaracaoAusenteService } from './declaracao-ausente.service';
import { AusenciaDeclaracao, TipoDeclaracao } from './model/declaracao-ausente';

@Component({
    selector: 'app-declaracao-ausente',
	templateUrl: './declaracao-ausente.component.html',
	styleUrls: ['./declaracao-ausente.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteComponent implements IDynamicPanel {

    TipoDeclaracao = TipoDeclaracao;

    initError: Error;

    ausenciasDeclaracao: AusenciaDeclaracao[];
	ni: string;
	titulo: string;
	protocolo: string;

    isPessoaFisica = false;
    isPessoaJuridica = false;

	tipoDeclaracao: TipoDeclaracao;

	constructor(
		private declaracaoAusenteService: DeclaracaoAusenteService,
		private atendimentoService: AtendimentoService,
		private messageService: ScopedMessageService,
		private logService: LogService,
	)

    {
		console.log('DeclaracaoAusenteComponent#constructor');
	}

	init(ni: string) {

		this.ni = ni;
		const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
    	this.protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
		console.log('DeclaracoesAusentesComponent#init NI: ', ni);
		this.logService.logAjaxCall('DeclaracoesAusentesComponent#init', 'NI: ', ni);
		console.log('Protocolo: ' + this.protocolo);
		
		try {
			let ausenciasDeclaracaoObservable : Observable<AusenciaDeclaracao[]>;
			ausenciasDeclaracaoObservable = this.declaracaoAusenteService.obterDeclaracoesAusentes(ni, this.protocolo, this.tipoDeclaracao);
						
			ausenciasDeclaracaoObservable.subscribe(
				(resp) => {
					this.ausenciasDeclaracao = resp;
					this.logService.logAjaxReturn('DeclaracaoAusenteComponent#obterDeclaracoesAusentes', this.tipoDeclaracao, this.ausenciasDeclaracao);
				},
				(err) => {
					this.initError = err;
					this.logService.logAjaxError('DeclaracaoAusenteComponent#obterDeclaracoesAusentes', 'Ocorreu um erro ao obter declarações ausentes ' + this.tipoDeclaracao, err);
					this.messageService.showErrorException('Ocorreu um erro ao obter declarações ausentes ', err);
				}
			);
		} catch(e) {
			this.initError = e;
			this.logService.logError('DeclaracaoAusenteComponent#obterDeclaracoesAusentes', 'Ocorreu um erro ao obter declarações ausentes ' + this.tipoDeclaracao, e);
			this.messageService.showErrorException('Ocorreu um erro ao obter declarações ausentes ', e);
		}
	}
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteDctfComponent extends DeclaracaoAusenteComponent {
    // indicador: DctfAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.DCTF;
        this.titulo = `Declarações Ausentes DCTF - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteDirfComponent extends DeclaracaoAusenteComponent {
    // indicador: DirfAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.DIRF;
        this.titulo = `Declarações Ausentes DIRF - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteDirpfComponent extends DeclaracaoAusenteComponent {
    // indicador: DirpfAusencia
    init(ni: string) {
        this.isPessoaFisica = true;
        this.tipoDeclaracao = TipoDeclaracao.DIRPF;
        this.titulo = `Declarações Ausentes DIRPF - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteSimeiComponent extends DeclaracaoAusenteComponent {
    // indicador: SimeiAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.SIMEI;
        this.titulo = `Declarações Ausentes SIMEI - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteObrapComponent extends DeclaracaoAusenteComponent {
    // indicador: SisobraprefAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.OBRAP;
        this.titulo = `Declarações Ausentes SISOBRAPREF - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusentePJDitrComponent extends DeclaracaoAusenteComponent {
    // indicador: DitrAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.DITR;
        this.titulo = `Declarações Ausentes DITR - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusentePFDitrComponent extends DeclaracaoAusenteComponent {
    // indicador: DitrAusencia
    init(ni: string) {
        this.isPessoaFisica = true;
        this.tipoDeclaracao = TipoDeclaracao.DITR;
        this.titulo = `Declarações Ausentes DITR - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusentePJGfipComponent extends DeclaracaoAusenteComponent {
    // indicador: GfipAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.GFIP;
        this.titulo = `Declarações Ausentes GFIP - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusentePFGfipComponent extends DeclaracaoAusenteComponent {
    // indicador: GfipAusencia
    init(ni: string) {
        this.isPessoaFisica = true;
        this.tipoDeclaracao = TipoDeclaracao.GFIP;
        this.titulo = `Declarações Ausentes GFIP - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusentePgdasComponent extends DeclaracaoAusenteComponent {
    // indicador: PgdasAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.PGDAS;
        this.titulo = `Declarações Ausentes PGDAS - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteEfdComponent extends DeclaracaoAusenteComponent {
    // indicador: EfdAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.EFD;
        this.titulo = `Declarações Ausentes EFD - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteEcfComponent extends DeclaracaoAusenteComponent {
    // indicador: EcfAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.ECF;
        this.titulo = `Declarações Ausentes ECF - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-declaracao-ausente',
    templateUrl: './declaracao-ausente.component.html',
    styleUrls: ['./declaracao-ausente.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DeclaracaoAusenteDefisComponent extends DeclaracaoAusenteComponent {
    // indicador: DasnAusencia
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.tipoDeclaracao = TipoDeclaracao.DEFIS;
        this.titulo = `Declarações Ausentes DEFIS - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}
