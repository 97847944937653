import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SocioAdminQSASuiteRfbModule } from './socio-admin-qsa-suite-rfb.module';
import { SocioAdminQSAComponent } from './socio-admin-qsa.component';
import { SocioAdminQSAService } from './socio-admin-qsa.service';
import { SocioAdminQSATableComponent } from './table/socio-admin-qsa-table.component';

@NgModule({
    declarations: [
        SocioAdminQSAComponent,
        SocioAdminQSATableComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        TooltipModule,
        SocioAdminQSASuiteRfbModule
    ], 
    providers: [
        SocioAdminQSAService
    ],
    entryComponents: [
        SocioAdminQSAComponent
    ]
})
export class SocioAdminQSAModule { }
