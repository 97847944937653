import { Injectable } from '@angular/core';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { NumberUtilService } from '../util/number-util.service';

enum Tamanho {
  TAMANHO_CPF = 11,
  TAMANHO_CNPJ = 14
} 

@Injectable()
export class AjustaTamanhoNIService {

  constructor(private numberUtilService: NumberUtilService) { }

  transform(ni: string, tipo: TipoNI): string {
    if (ni == null) {
      return ni;
    }
    if (tipo === TipoNI.CPF) {
      return this.transformaNi(ni, Tamanho.TAMANHO_CPF);
    } else if (tipo === TipoNI.CNPJ) {
      return this.transformaNi(ni, Tamanho.TAMANHO_CNPJ);
    } else {
      return ni;
    }
  }
  
  private transformaNi(ni: string, tamanhoNi: number): string {
    const tamanhoNIInformado = ni.length;
    if (tamanhoNIInformado == tamanhoNi) {
      return ni;
    } else if (tamanhoNIInformado > tamanhoNi) {
      return ni.slice(tamanhoNIInformado - tamanhoNi);
    } else {
      return this.numberUtilService.leftZeroes(ni, tamanhoNi);
    }
  }

}
