import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParcelamentoPaex } from '../model/parcelamento-paex';
import { ParcelamentoPaexService } from '../parcelamento-paex.service';

@Component({
  selector: 'via-parcelamento-paex-table',
  templateUrl: 'parcelamento-paex-table.component.html',
  styleUrls: ['parcelamento-paex-table.component.css']
})
export class ParcelamentoPaexTableComponent {

  @Input() nomeNi: string;

  @Input() parcelamentos: ParcelamentoPaex[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  get _window() {
    return window;
  }

  constructor(private parcelamentoPaexService: ParcelamentoPaexService) { }

  urlExtratoPaex(parcelamento: ParcelamentoPaex): string {
    if (this.isPessoaFisica) {
      parcelamento.ni = parcelamento.ni.slice(-11);
    }
    return this.parcelamentoPaexService.getUrlExtratoPaex(this.nomeNi, parcelamento);
  }
}
