import { Component, Input } from '@angular/core';

@Component({
  selector: 'via-informacoes-complementares-pj',
  templateUrl: './informacoes-complementares.component.html',
  styleUrls: ['./informacoes-complementares.component.css']
})
export class InformacoesComplementaresComponent {

  @Input() cnpj: string;
}
