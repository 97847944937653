<suite-rfb-collapse [title]="titulo">
  <div class="table-responsive">
    <table suiteRfbTable>
      <thead>
        <tr>
          <th class="text-center min-width">CPF</th>
          <th class="text-center">Nome da Mãe</th>
          <th class="text-center min-width">Situação</th>
          <th class="text-center min-width">UF</th>
          <th class="text-center">Município</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let homonimo of homonimos | currentPage:currentPage;">
          <td class="text-center text-nowrap">
            <ni-com-link [ni]="homonimo.cpf" [tipoNI]="CPF"></ni-com-link>
          </td>
          <td class="text-left">{{ homonimo.nomeMae | vazio }}</td>
          <td class="text-center">{{ homonimo.situacao | vazio }}</td>
          <td class="text-center">{{ homonimo.uf | vazio }}</td>
          <td class="text-left">{{ homonimo.municipio | vazio }}</td>
        </tr>
      </tbody>

      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="5">
            <via-pagination [itemAmount]="homonimos.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</suite-rfb-collapse>
