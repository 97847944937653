<div class="feature">
    <!-- <textarea>SituacaoFiscalComponent feature: {{ feature | json }}</textarea> -->
    
    <!-- se não está colapsado, apresenta o menu com os quadros disponíveis -->
    <div class="float-left">
        <fieldset class="menu-feature" [hidden]="feature.collapsed">
            <app-menu-feature-actions [feature]="feature" [featureActions]="featureActions"></app-menu-feature-actions>
        </fieldset>
    </div>
    
    <div class="feature-services">
        <!-- dashboard com os quadros ativados no menu de features -->
        <div #widgets style="border: 2px"></div>

    </div>
</div>
