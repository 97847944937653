<div class="modal-container">
  <div class="modal-header">
    <div class="row">
      <div class="col-md-6">
        <h4 class="modal-title">{{ documento.titulo }}</h4>
      </div>

      <div class="col-md-6">
        <span
          class="pull-right material-icons icon-24 cursor"
          title="Fechar"
          aria-hidden="true"
          (click)="onFecharVisualizacao()">
          close
        </span>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <iframe #iframe class="documento-dialog" [title]="documento.titulo"></iframe>
  </div>
</div>
