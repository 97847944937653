import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimLeft'
})
export class TrimLeftPipe implements PipeTransform {

  public transform(value: string, removeable: string): string {
    if (value) {
      if (removeable) {
        while (value.startsWith(removeable)) {
          value = value.slice(removeable.length, value.length)
        }
      }
      return value;
    }
    return '';
  }

}