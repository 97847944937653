import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeclaracaoIrpfMalhaSuiteRfbModule } from './declaracao-irpf-malha-suite-rfb.module';
import { DeclaracaoIrpfMalhaComponent } from './declaracao-irpf-malha.component';
import { DeclaracaoIrpfMalhaService } from './declaracao-irpf-malha.service';
import { DeclaracaoIrpfMalhaTableComponent } from './table/declaracao-irpf-malha-table.component';

@NgModule({
  declarations: [
    DeclaracaoIrpfMalhaComponent,
    DeclaracaoIrpfMalhaTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    TooltipModule,
    DeclaracaoIrpfMalhaSuiteRfbModule,
  ],
  providers: [
    DeclaracaoIrpfMalhaService
  ],
  exports: [
    DeclaracaoIrpfMalhaComponent,
    DeclaracaoIrpfMalhaTableComponent
  ]
})
export class DeclaracaoIrpfMalhaModule { }
