import { Component, OnInit } from "@angular/core";
import _ from "lodash";
import { FormatCpfCnpjCeiPipe } from "src/app/shared/pipes/formata-cpf-cnpj-cei.pipe";
import { TipoNI } from "src/app/sitfis/model/enum/tipo-ni";
import { AusenciaDeclaracao } from "../model/declaracao-ausente";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

const nomesPeriodos = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', '13°'];

interface AusenciaGfip {
  ano: string;
  apuracoes: string[];
}

interface DeclaracaoAusenteGFIPGroupedByNi {
  [ni: string]: AusenciaGfip[];
}

@Component({
  selector: 'via-declaracao-ausente-gfip-table',
  templateUrl: 'declaracao-ausente-gfip-table.component.html',
  styleUrls: [
    'declaracao-ausente-gfip-table.component.css',
    'declaracao-ausente-table.component.css'
  ]
})
export class DeclaracaoAusenteGfipTableComponent
  extends DeclaracaoAusenteTableComponent
  implements OnInit {

  ausenciasPorNi: DeclaracaoAusenteGFIPGroupedByNi;

  ngOnInit(): void {
    this.ausenciasPorNi = this.montarAusenciasPorNi();
  }

  private montarAusenciasPorNi(): DeclaracaoAusenteGFIPGroupedByNi {
    const ausenciasOrdenadas = this.ordenarAusencias();
    const ausenciasAgrupadas = this.agruparAusenciasPorNi(ausenciasOrdenadas);
    return this.montarAusenciasAgrupadas(ausenciasAgrupadas);
  }

  private ordenarAusencias(): AusenciaDeclaracao[] {
    return _.orderBy(this.ausenciasDeclaracao, ['tpCnpjCei', 'nuCnpjCei'], ['desc', 'asc']);
  }

  private agruparAusenciasPorNi(ausenciasOrdenadas: AusenciaDeclaracao[]): _.Dictionary<AusenciaDeclaracao[]> {
    return _.groupBy(ausenciasOrdenadas, ausencia => {
      const tipoNi = ausencia.tpCnpjCei;
      const niFormatado = FormatCpfCnpjCeiPipe.format(ausencia.nuCnpjCei, TipoNI[tipoNi]);
      return `${tipoNi}: ${niFormatado}`;
    });
  }

  private montarAusenciasAgrupadas(ausenciaAgrupadas: _.Dictionary<AusenciaDeclaracao[]>): DeclaracaoAusenteGFIPGroupedByNi {
    return _.mapValues(ausenciaAgrupadas, ausencias => {
      const ausenciasGfip: AusenciaGfip[] = [];

      _.forEach(ausencias, ausencia => {
        let ausenciaGfip: AusenciaGfip = {
          ano: ausencia.periodoApuracaoOmissao[0].slice(0, 4),// O período vem no formato YYYYMM, ex.: 202108
          apuracoes: Array<string>(13).fill('-')
        };

        _.forEach(ausencia.periodoApuracaoOmissao, periodo => {
          let mes = parseInt(periodo.slice(-2));
          ausenciaGfip.apuracoes[mes - 1] = nomesPeriodos[mes - 1];
        });

        ausenciasGfip.push(ausenciaGfip);
      });

      return ausenciasGfip;
    });
  }
}
