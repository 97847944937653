import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { User, UserService } from '@serpro/ngx-suite-rfb';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { DynamicModalService } from './shared/dynamic-modal/dynamic-modal.service';
import { AplicacaoInfoService } from './shared/services/aplicacao-info.service';
import { LelService } from './shared/services/lel.service';
import { MessageService } from './shared/services/message.service';
import { StorageService } from './shared/services/storage.service';
import { PdfViewerService } from './workplace/services/pdf-viewer.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  textoRodape: string = '';

  showMenu = false;
  pdfBase64: string;
  pdfFilename: string;
  pdfZoom = "page-width";

  displayedSub: Subscription;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private pdfViewerService: PdfViewerService,
    private messageService: MessageService,
    private dynamicModalService: DynamicModalService,
    private aplicacaoInfoService: AplicacaoInfoService,
    private lelService: LelService,
    private storageService: StorageService,
    private cookieService: CookieService,
    ) { }

  ngOnInit() {
    this.storageService.clearLogonDate();

    if(environment.APIEndpoint.indexOf('localhost') > 0) {
      const camwebPin = environment['camwebPin'];
      const cpf = environment['cpfUsuario'];
      const nome = environment['nomeUsuario'];
      if( !camwebPin || !cpf || !nome ) {
        let error = new HttpErrorResponse({
          error: new Error('Configure o ambiente de desenvolvimento local'),
          status: 401,
          statusText: 'Unauthorized'
        });
        this.messageService.showErrorException('Erro de inicialização', error );
        throw error;
      }
      this.cookieService.set('CAMWebPIN', camwebPin);
    }

    this.lelService.getDataLogonSessao().subscribe(
      (cipheredLogonDate: string) => {
        this.storageService.setLogonDate(cipheredLogonDate);
      },
      (error) => {
        if (error.message.indexOf('camweb') > 0) {
          window.location.href = '/';
        }
        else {
          this.messageService.showErrorException('Erro', error);
        }
      },
      () => {
        // Necessário levantar a aplicação completando inicialmente uma 
        // chamada isolada, sem concorrência, para pegar o cookie do token CSRF
        const viaCsrfToken = this.cookieService.get('via-csrf-token');
        console.log('via-csrf-token: ' + viaCsrfToken);

        this.obterUsuario();
        this.ajustaTextoRodape();
      }
    )

    this.displayedSub = this.pdfViewerService.displayed$.subscribe(
      (pdfDocument) => {
        if(pdfDocument) {
          this.pdfBase64 = pdfDocument.base64;
          this.pdfFilename = pdfDocument.filename;
        }
        else {
          this.pdfBase64 = null;
          this.pdfFilename = null;
        }
      }
    );
    this.dynamicModalService.ngOnInit();
  }

  private obterUsuario() {
    this.authService.getLoggedUser().subscribe(
      (loggedUser: User) => {
        this.userService.setUser(loggedUser);
        this.verificarPermissao(loggedUser);
      },
      (error) => {
        if (error.message.indexOf('camweb') > 0) {
          window.location.href = '/';
        }
        else {
          this.messageService.showErrorException('Erro', error);
        }
      }
    );
  }

  private verificarPermissao(usuario: User) {
    return usuario.roles.length > 0;
    // verificar se o usuário possui os perfis necessários.
  }

  private ajustaTextoRodape(): void {
    const versaoFrontend = this.aplicacaoInfoService.getFrontendVersion();
    const ambiente = this.aplicacaoInfoService.getEnvironment();
    this.aplicacaoInfoService.getBackendVersion().subscribe(
      (versaoBackend: string) => {
        this.textoRodape = `[ Ambiente: ${ambiente} | F: ${versaoFrontend} | B: ${versaoBackend} ]`;
      },
      (error) => {
        this.messageService.showErrorException('Erro', error);
      }
    );
  }

  onClose() {
    this.pdfViewerService.display(null);
  }

  public ngOnDestroy(): void {
    this.displayedSub.unsubscribe();
  }
}
