import { DadosCadastraisPfDto } from './dados-cadastrais-pf-sf';
import { InfoCadastralPessoaJuridicaDTO } from '../components/info-cadastrais-pj/model/info-cadastral-pj';
import { TipoNI } from './enum/tipo-ni';

export class InformacoesCompletasAtendimento {
    tabName: string;
    dadosContribuinteCpf: PessoaFisica; // carregados do serviço CPF
    dadosContribuinteCnpj: PessoaJuridica; // carregados do serviço CNPJ
    protocoloSitFis: ProtocoloDto; // numero de protocolo utilizado nos servicos Situação Fiscal

    get tipoNIAtendimento(): TipoNI {
        return this.protocoloSitFis ? this.protocoloSitFis.tipoNiContribuinte : null;
    }
}

export class PessoaFisica {
    // TODO complementar com todos os dados necessários de contribuinte
    cpf: string;
    nome: string;
    // TODO: implementar consulta a dados cadastrais
    dadosCadastrais?: DadosCadastraisPfDto;
}

export class PessoaJuridica {
    // TODO complementar com todos os dados necessários de contribuinte
    cnpj: string;
    razaoSocial: string;
    // TODO: implementar consulta a dados cadastrais
    dadosCadastrais?: InfoCadastralPessoaJuridicaDTO;
}

export class ProtocoloDto {
    numeroProtocolo: string;
    tipoNiContribuinte: TipoNI;
    niContribuinte: string;
    tipoNiRequerente: string;

    constructor(numeroProtocolo: string, tipoNi: TipoNI, ni: string, tipoNiRequerente: string) {
        this.numeroProtocolo = numeroProtocolo;
        this.tipoNiContribuinte = tipoNi;
        this.niContribuinte = ni;
        this.tipoNiRequerente = tipoNiRequerente;
    }

    public toString() {
        return `Protocolo Tipo=${this.tipoNiContribuinte} Ni=${this.niContribuinte}`;
    }
}

export class SolicitacaoProtocoloDto {
    tipoNI: TipoNI;
    ni: string;
}

export class NumeroProtocoloDto {
    numero: string;
}

export class StatusAnaliseFiscalDTO {
     data: Date;
     situacao: string;
}