import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheHistoricoAlteracaoPj } from '../models/detalhe-historico-alteracao-pj';

@Injectable()
export class HistoricoPjService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterHistorico(cnpj: string): Observable<DetalheHistoricoAlteracaoPj[]> {
    const url = `${this.baseUrl}/cnpj/${cnpj}/historico`;
    return this.httpClient.get<DetalheHistoricoAlteracaoPj[]>(url);
  }
}
