import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtilService } from '../util/number-util.service';

// formato 99.999.99
const mascaraUA: string = '$1.$2.$3';
const formatoUA: RegExp = /^([0-9]{2})([0-9]{3})([0-9]{2})$/;
const tamanho = 7;

@Pipe({
  name: 'uaPipe'
})
export class UAPipe implements PipeTransform {

  constructor(private numberUtils: NumberUtilService) { }

  public transform(ua: IUnidadeAdministrativa): string {
      return this.formataCodigoUA(ua.codigo + '') + ' ' + ua.nome;
  }

  /**
   * Formata o codigo de uma UA. 
   * 
   * @param ua 
   * @returns o numero formatado. Se o número informado for maior do que tamanho especificado, retorna o proprio valor informado como string sem formatar.
   */
  private formataCodigoUA(codigoUA: string) {
    if (codigoUA) {
      if (this.numberUtils.onlyDigits(codigoUA)) {
        if (codigoUA.length <= tamanho) {
          codigoUA = this.numberUtils.leftZeroes(codigoUA, tamanho);
          return codigoUA.replace(formatoUA, mascaraUA);
        }
      }
      return codigoUA;
    }
    return '';
  }

}

interface IUnidadeAdministrativa {
  codigo: number;
  nome: string;
}
