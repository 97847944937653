<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">NI</th>
        <th class="text-center">Tipo do Parcelamento</th>
        <th class="text-center">Situação</th>
        <th *ngIf="isAtivos" class="text-center min-width">Parcelas em Atraso</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parcelamento of parcelamentos | currentPage: currentPage;" class="vertical-align-middle">
        <td class="text-center">
          <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
          <ng-template #menuAcoes>
            <div class="btn-group-vertical" role="group">
              <button
                type="button"
                class="btn btn-default"
                (click)="_window.open(urlPortalRfb(parcelamento), '_blank'); botaoAcoes.hide();">
                Extrato PAEX
              </button>
            </div>
          </ng-template>
        </td>
        <td class="text-center">
          <ni-com-link [ni]="isPessoaFisica ? (parcelamento.ni | slice:-11) : parcelamento.ni"></ni-com-link>
        </td>
        <td class="text-left">{{ parcelamento.nomeParcelamento }}</td>
        <td class="text-left">{{ parcelamento.situacao }}</td>
        <td *ngIf="isAtivos" class="text-center">{{ parcelamento.quantidadePrestacoesAtraso }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="5">
          <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
