import { Component, Input, OnInit } from '@angular/core';
import { LogService } from 'src/app/shared/services/log.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DadosBasicosPjDTO } from '../../model/dados-basicos-pj-dto';
import { CnpjService } from '../../services/cnpj.service';

@Component({
  selector: 'via-consulta-dados-basicos-pj',   
  templateUrl: './dados-basicos-pj.component.html',
  styleUrls: ['./dados-basicos-pj.component.css']
})
export class DadosBasicosPJComponent implements IDynamicPanel {

  constructor(
    private cnpjService: CnpjService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
  }

  cnpj: string;
  titulo: string;
  dadosBasicos: DadosBasicosPjDTO;
  initError: Error;

  public init(cnpj: string): void {
    this.cnpj = cnpj;
    this.titulo = `Pessoa Jurídica - Dados Básicos CNPJ: ${FormatCnpjPipe.formatCNPJ(cnpj)}`;
    this.obterDadosBasicos();
  }

  private obterDadosBasicos(): void {
    this.cnpjService.obterDadosEmpresa(this.cnpj).subscribe(
      resp => {
        this.dadosBasicos = resp;
      },
      err => {
        this.initError = err;
        this.logService.logAjaxError('DadosBasicosPJComponent', 'obterDadosBasicos', err);
        this.messageService.showErrorException('Ocorreu um erro ao obter os dados basicos da pessoa jurídica', err);
      }
    );
  }
}
