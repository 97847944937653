import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { ToggleComponent } from '@serpro/ngx-suite-rfb';
import { UtilService } from 'src/app/shared/util/util.service';
import { VencimentoCTCcitr } from '../../../../models/vencimento-ct';
import { NumberUtilService } from '../../../../../shared/util/number-util.service';
import { CcitrBase } from '../../base/CcitrBase.component';
import { CCITRService } from '../../services/ccitr.service';
import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ParametroConsolidarDebitoCCITR } from '../consulta-creditos/model/parametro-consolidar-debito-ccitr';
import { DadosConsolidadoDebitoCCITRDTO } from './model/dados-consolidado-debito-ccitr';
import { ParametrosPesquisaCcitr } from 'src/app/cobranca/models/parametros-pesquisa-ccitr';
import { CONSTANTS } from '../../constants';
@Component({
  templateUrl: './consolidar-ct.component.html',
  styleUrls: ['./consolidar-ct.component.css', '../../base/ccitr.css' ],
  providers: [ScopedMessageService],
})

export default class ConsolidaCTComponent extends CcitrBase implements IDynamicPanel, OnChanges {

  @Input() parametros: ParametroConsolidarDebitoCCITR;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  titulo: string;

  dataConsolidacaoName: string = "dataConsolidacao";
  form: FormGroup;

  showCloseModal = true;
  scopeId: string;


  vencimentoCT: VencimentoCTCcitr;

  initError: Error;
  exibirDadosConsolidados: boolean = false;
  dadosConsolidadosCTCCITR: DadosConsolidadoDebitoCCITRDTO;
  filtroColapsado = false;


  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private cCITRService: CCITRService,
    private numberUtilService: NumberUtilService,
    private messageService: ScopedMessageService) {
      super();
    }

  init(_ni: string, params?: OptionalParams): void {
    super.init(_ni, params)
    let ehModal: boolean;
    this.scopeId = this.messageService.scopeId;
    this.showCloseModal = false;
    this.parametros = params as ParametroConsolidarDebitoCCITR;

    const numeroCTFormatado: String = this.numberUtilService.leftZeroes(this.parametros.numeroCT, 9);

    this.exibirDadosConsolidados = false;

    this.titulo = "Informe a Data de Consolidação do CT: " + numeroCTFormatado;

    this.form = this.fb.group({
      dataConsolidacao: ['']
    });

  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  onSubmit(): void {

    this.parametros.dataConsolidacao = this.utilService.converterDataParaTextoInvertidaSemHora(this.dataConsolidacaoAI);

    this.cCITRService.consolidarCT(this.parametros).subscribe(
      retorno => {
        this.messageService.showInfo('Data de Vencimento do Crédito Tributário alterado com sucesso.');
        //this.dataConsolidacao = this.parametros.dataConsolidacao;
        this.form.reset();
        this.formataTitulo(this.parametros.parametros, 'CCITR - Consolidação dos Créditos Tributários em Cobrança');
        this.exibirDadosConsolidados = true;
        this.dadosConsolidadosCTCCITR = retorno;
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao solicitar a Alteração da Data de Vencimento do Crédito Tributário', err);
        }
      }
    );
  }


  private get dataConsolidacaoAI(): Date {
    return this.form.get(this.dataConsolidacaoName).value as Date;
  }

  get dataConsolidacaoAICtrl(): FormControl {
    return this.form.get(this.dataConsolidacaoName) as FormControl;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  public esconderResultado(): void {
    this.filtroColapsado = false;
  }


}
