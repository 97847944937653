
import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { DynamicActionPanelComponent } from 'src/app/shared/dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { Vinculo } from 'src/app/sitfis/model/vinculos';
import { Feature, FeatureAction, TipoMenu } from '../../shared/model/feature';
import { ItensMenuAdicionais } from '../model/itens-menu';
import { FEATUREACTIONS_PF_VINCULADO } from './situacao-fiscal-pf.features';
import { FEATUREACTIONS_PJ_VINCULADO } from './situacao-fiscal-pj.features';

/* Componente que engloba o menu de features e o painel de quadros. */
@Component({
  selector: 'via-situacao-fiscal-vinculos',
  templateUrl: './situacao-fiscal-vinculos.component.html',
  styleUrls: ['./situacao-fiscal-vinculos.component.css']
})
export class SituacaoFiscalVinculosComponent implements OnInit {

  @Input() vinculos: Vinculo[];
  @Input() vinculosFeature: Feature;

  vinculo: Vinculo;
  menuFeature: Feature;
  featureActions: FeatureAction[];

  @ViewChild('widgets', { read: ViewContainerRef, static: true })
  private readonly _dashboard: ViewContainerRef;

  private quadrosDinamicosAtivos: Map<string, DynamicActionPanelComponent> = new Map();

  // notificador de componente destruido
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private logService: LogService,
    private menuService: MenuFeatureActionsService,
    private dynamicModalRequestService: DynamicModalRequestService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.menuFeature = this.vinculosFeature.children[0];
    this.menuFeature.collapsed = true;
    this.subscribeEventoAberturaQuadrosDinamicos();
  }

  ngOnDestroy(): void {
    // notifica que o componente foi destruido
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.logService.logStep('SituacaoFiscalPjComponent.ngOnDestroy', 'componente destruído');
  }

  abrirMenuVinculado(vinculo: Vinculo) {
    this.vinculo = vinculo;
    let itensMenuAdicionais: ItensMenuAdicionais[] = [];

    const ni = vinculo.getDescricao();

    let featuresVinculadosTmp: FeatureAction[];

    if (ni.length > 11) {
      this.menuFeature.title = `Situação Fiscal PJ - Vinculado CNPJ ${FormatCnpjPipe.formatCNPJ(ni)}`;
      featuresVinculadosTmp = _.cloneDeep(FEATUREACTIONS_PJ_VINCULADO);
    } else {
      this.menuFeature.title = `Situação Fiscal PF - Vinculado CPF ${FormatCpfPipe.formatCPF(ni)}`;
      featuresVinculadosTmp = _.cloneDeep(FEATUREACTIONS_PF_VINCULADO);
    }

    featuresVinculadosTmp.forEach((n: FeatureAction) => this.menuService.ajustaVisibilidade(n, vinculo, itensMenuAdicionais, TipoMenu.VINCULADO));
    this.featureActions = featuresVinculadosTmp.filter(m => m.visible);
    this.menuFeature.collapsed = false;
  }

  onMenuCollapsedChange(collapsed: boolean){
    if(collapsed){
      this.quadrosDinamicosAtivos.forEach(element => {
        element.onCloseClick();
      });
    }
  }

  abrirDadosVinculado(vinculo: Vinculo) {
    this.vinculo = vinculo;
    if (vinculo.getDescricao().length > 11) {
      this.dynamicModalRequestService.exibirDadosBasicosCnpj(vinculo.getDescricao())
    } else {
      this.dynamicModalRequestService.exibirDadosBasicosCpf(vinculo.getDescricao())
    }
  }

  private subscribeEventoAberturaQuadrosDinamicos(): void {
    this.menuService.featureActionEvent$
      .pipe(takeUntil(this.ngUnsubscribe))// desregistra quando o componente foi encerrado
      .subscribe(featureEvent => {
        const feature = featureEvent.featureAction;

        // se o quadro pode ser carregado dinamicamente
        if (feature.class && feature.tipoMenu === TipoMenu.VINCULADO) {
          this.logService.logStep('SituacaoFiscalVinculosComponent.subscribeEventoAberturaQuadrosDinamicos', 'menuService.featureActionEvent$', featureEvent);

          // se o quadro está sendo aberto e ainda não está aberto
          if (featureEvent.active && !this.quadrosDinamicosAtivos.has(feature.id)) {
            const ni = feature.id.split('_')[1];
            const widget = this.createWidgetPanel(feature, ni);
            // registra um quadro ativo aberto
            this.quadrosDinamicosAtivos.set(feature.id, widget);
            return;
          } else if (!featureEvent.active && this.quadrosDinamicosAtivos.has(feature.id)) {
            // fecha se quadro aberto
            this.quadrosDinamicosAtivos.get(feature.id).closeRequest();
          }
        }
      });
  }

  private createWidgetPanel(featureAction: FeatureAction, ni: string): DynamicActionPanelComponent {
    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `criando nova janela de chat`, featureAction);

    // cria um widget DynamicActionPanelComponent
    const widgetFactory = this.resolver.resolveComponentFactory(DynamicActionPanelComponent);
    const componentRef = this._dashboard.createComponent<DynamicActionPanelComponent>(widgetFactory, 0);
    const widget = componentRef.instance;
    featureAction.parametros = featureAction.parametros ?? {};
    featureAction.parametros.tipoMenu = TipoMenu.VINCULADO;
    featureAction.parametros.idFeature = featureAction.id;

    // registra para destruir o componente dinâmico quando ele for fechado
    widget.onFeatureClosed.pipe(take(1)).subscribe(featureId => {
      if (featureId) {
        this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', 'DynamicActionPanelComponent.onFeatureClosed');
        componentRef.destroy();
        // remove o quadro fechado da lista de ativos
        this.quadrosDinamicosAtivos.delete(featureId);
      }
    });

    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `widgets`, this._dashboard);
    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `componente DynamicActionPanelComponent criado`, widget);
    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `vai instanciar o painel interno`, featureAction.class);

    // instancia um componente de painel como filho do widget
    widget.instantiatePanel(featureAction, ni);

    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `painel instanciado com sucesso`);
    this.logService.logStep('SituacaoFiscalVinculosComponent.createWidgetPanel', `contador de widgets criados: ${this._dashboard.length}`);

    return widget;
  }
}
