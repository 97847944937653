import { Component, ViewEncapsulation } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DadosCadastraisPfDto } from '../../model/dados-cadastrais-pf-sf';
import { InfoCadastraisPFService } from './info-cadastrais-pf.service';

@Component({
	selector: 'via-info-cadastrais-pf',
	templateUrl: './info-cadastrais-pf.component.html',
	styleUrls: ['./info-cadastrais-pf.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class InfoCadastraisPFComponent implements IDynamicPanel {

	dadosCadastrais: DadosCadastraisPfDto;
	initError: Error;

    titulo: string;

	constructor(
		private service: InfoCadastraisPFService,
		private atendimentoService: AtendimentoService,
		private messageService: ScopedMessageService,
		private logService: LogService) {
	}

	public init(cpf: string): void {

        this.titulo = `Informações Cadastrais - CPF: ${FormatCpfPipe.formatCPF(cpf)}`;

		const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(cpf);
		const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

		try {
			this.service.obterInformacoesCadastraisPF(cpf, numeroProtocolo).subscribe(
				(resp) => {
					this.dadosCadastrais = resp;
					this.logService.logAjaxReturn('InfoCadastraisPFComponent.obterDadosCadastraisPF= ', 'recuperou Informações Cadastrais PF', this.dadosCadastrais);
				},
				(err) => {
					this.initError = err;
					this.logService.logAjaxError('InfoCadastraisPFComponent.obterDadosCadastraisPF =', 'recuperou Informações Cadastrais PF', err);
					this.messageService.showErrorExceptionNI(cpf, 'Ocorreu um erro ao obter os dados cadastrais', err);
				}
			);
		} catch (e) {
			this.initError = e;
			this.logService.logError('InfoCadastraisPFComponent.obterDadosCadastraisPF =', 'ocorreu um erro ao obter as informações cadastrais PF', e);
			this.messageService.showErrorExceptionNI(cpf,'Ocorreu um erro ao obter as informações cadastrais', e);
		}
	}

}
