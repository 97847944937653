import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { ParcelamentoSimplesNacionalAtivoComponent, ParcelamentoSimplesNacionalComponent, ParcelamentoSimplesNacionalSuspensoComponent } from './parcelamento-simples-nacional.component';
import { ParcelamentoSimplesNacionalService } from './parcelamento-simples-nacional.service';
import { ParcelamentoSimplesNacionalTableComponent } from './table/parcelamento-simples-nacional-table.component';

@NgModule({
  declarations: [
    ParcelamentoSimplesNacionalTableComponent,
    ParcelamentoSimplesNacionalComponent,
    ParcelamentoSimplesNacionalAtivoComponent,
    ParcelamentoSimplesNacionalSuspensoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SitfisPipesModule,
    SuiteRfbPaginationModule,
    SuiteRfbCollapseModule,
    SuiteRfbTableModule
  ],
  providers: [
    ParcelamentoSimplesNacionalService
  ],
  entryComponents: [
    ParcelamentoSimplesNacionalComponent,
    ParcelamentoSimplesNacionalAtivoComponent,
    ParcelamentoSimplesNacionalSuspensoComponent
  ]
})
export class ParcelamentoSimplesNacionalModule { }
