<suite-rfb-collapse [title]="titulo" [collapsed]="collapsed">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <loading-animation *ngIf="!dadosBasicos"></loading-animation>

  <div class="container-fluid" *ngIf="dadosBasicos">
    <div class="row" *ngIf="!isCadeiaNi; else membroCadeia;">
      <div class="col-md-3">
        <label class="label-formulario control-label">
          CPF
        </label>
        <p class="form-control-static dado-formulario">
          {{ cpf | formatCpfPipe }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          Situação
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.situacaoCadastral?.descricao | vazio }}
        </p>
      </div>
      <div class="col-md-5">
        <label class="label-formulario control-label">
          Nome
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.nome | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          Data de Nascimento
        </label>
        <ng-container *ngIf="dadosBasicos.dataNascimento; else templateVazio;">
          <p class="form-control-static dado-formulario">
            {{ dadosBasicos.dataNascimento | outputMask: '9999-99-99' | date: 'shortDate' }}
          </p>
        </ng-container>
      </div>
    </div>

    <ng-template #membroCadeia>
      <div class="row">
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Nome
          </label>
          <p class="form-control-static dado-formulario">
            {{ dadosBasicos.nome | vazio }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Data de Nascimento
          </label>
          <ng-container *ngIf="dadosBasicos.dataNascimento; else templateVazio;">
            <p class="form-control-static dado-formulario">
              {{ dadosBasicos.dataNascimento | outputMask: '9999-99-99' | date: 'shortDate' }}
            </p>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <div class="row">
      <div class="col-md-3">
        <label class="label-formulario control-label">
          Data de Inscrição
        </label>
        <ng-container *ngIf="dadosBasicos.dataInscricao; else templateVazio;">
          <p class="form-control-static dado-formulario">
            {{ dadosBasicos.dataInscricao | outputMask: '9999-99-99' | date: 'shortDate' }}
          </p>
        </ng-container>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">
          Nome da Mãe
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.nomeMae | vazio }}
        </p>
      </div>
      <div class="col-md-3">
        <label class="label-formulario control-label">
          Sexo
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.sexo | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">
          Naturalidade
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.naturalidade?.nome | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          UF
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.naturalidade?.uf | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          Estrangeiro
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.indEstrangeiro | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          Ano do Óbito
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.anoObito | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">
          Título de Eleitor
        </label>
        <div>
          <ng-container *ngIf="tituloEleitor?.numeroTitulo; else templateVazio;">
            <span
              class="link"
              tooltip="Consultar os dados do Título de Eleitor"
              container="body"
              (click)="exibirDadosTituloEleitor()">
              {{ tituloEleitor.numeroTitulo | vazio }}
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">
          Nacionalidade
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.nacionalidade?.nome | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <label class="label-formulario control-label">
          Logradouro
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.logradouro | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          Número
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.numeroLogradouro | vazio }}
        </p>
      </div>
      <div class="col-md-5">
        <label class="label-formulario control-label">
          Complemento
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.complemento | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class="label-formulario control-label">
          Bairro
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.bairro | vazio }}
        </p>
      </div>
      <div class="col-md-4">
        <label class="label-formulario control-label">
          Município
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.municipio?.nome | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          UF
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.municipio?.uf | vazio }}
        </p>
      </div>
      <div class="col-md-2">
        <label class="label-formulario control-label">
          CEP
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.endereco?.cep | cepPipe | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label class="label-formulario control-label">
          UA
        </label>
        <p class="form-control-static dado-formulario" *ngIf="dadosBasicos.ua; else templateVazio;">
          {{ dadosBasicos.ua | uaPipe }}
        </p>
      </div>
      <div class="col-md-3">
        <label class="label-formulario control-label">
          Residente no Exterior
        </label>
        <p class="form-control-static dado-formulario">
          {{ dadosBasicos.indResidenteExterior | vazio }}
        </p>
      </div>
      <div class="col-md-3">
        <label class="label-formulario control-label">
          Telefone
        </label>
        <ng-container *ngIf="dadosBasicos.codigoAreaTelefone && dadosBasicos.numeroTelefone; else templateVazio;">
          <p class="form-control-static dado-formulario">
            {{ dadosBasicos.codigoAreaTelefone | outputMask: '(99)' }}
            {{ dadosBasicos.numeroTelefone | outputMask: ' 9999-9999' }}
          </p>
        </ng-container>
      </div>
      <div class="col-md-3">
        <label class="label-formulario control-label">
          Celular
        </label>
        <ng-container *ngIf="dadosBasicos.codigoAreaTelefone && dadosBasicos.numeroTelefoneCelular; else templateVazio;">
          <p class="form-control-static dado-formulario">
            {{ dadosBasicos.codigoAreaTelefone | outputMask: '(99)' }}
            {{ dadosBasicos.numeroTelefoneCelular | outputMask: ' 9999-9999' }}
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</suite-rfb-collapse>

<ng-template #templateVazio>
  <p class="form-control-static dado-formulario">{{ '' | vazio }}</p>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>

