import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeroComponentePipe'
})
export class NumeroComponentePipe implements PipeTransform {

  transform(componente: IComponente): string {
    return `${componente.numero} ${componente.indicadorEncargo}`.trim();
  }
}

export interface IComponente {
  numero: string;
  indicadorEncargo: string;
}
