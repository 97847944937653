import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheOmissao } from '../models/detalhe-omissao';
import { OmissosService } from '../services/omissos.service';

@Component({
  selector: 'via-omissos-pf',
  templateUrl: './omissos.component.html',
  styleUrls: ['./omissos.component.css'],
  providers: [ScopedMessageService]
})
export class OmissosComponent implements OnInit {

  @Input() cpf: string;
  @Input() isModal = false;

  titulo = 'Omissos';

  omissoes: DetalheOmissao[];

  scopeId: string;
  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService,
    private omissosService: OmissosService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;

    if (this.isModal) {
      this.obterOmissoesDeclaracao();
      this.titulo = `Pessoa Física - Omissos - CPF: ${FormatCpfPipe.formatCPF(this.cpf)}`;
    }
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterOmissoesDeclaracao();
    }
  }

  abrirPortalIrpf(): void {
    window.open(this.omissosService.getUrlPortalIrpf(this.cpf), "_blank");
  }

  private obterOmissoesDeclaracao(): void {
    this.executouObterDados = true;

    this.omissosService.obterOmissoesDeclaracao(this.cpf)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }

          this.omissoes = response;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter as omissões da pessoa física', error);
          this.initError = error;
        }
      );
  }
}
