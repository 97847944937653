import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbPopoverModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbPopoverModule
  ]
})
export class DeclaracaoIrpfMalhaSuiteRfbModule { }
