import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MalhaIrpfComponent } from './malha-irpf.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeclaracaoIrpfMalhaModule } from 'src/app/sitfis/components/declaracao-irpf-malha/declaracao-irpf-malha.module';
import { SuiteRfbCollapseModule } from '@serpro/ngx-suite-rfb';

@NgModule({
  declarations: [
    MalhaIrpfComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DeclaracaoIrpfMalhaModule,
    SuiteRfbCollapseModule
  ],
  exports: [
    MalhaIrpfComponent
  ]
})
export class MalhaIrpfModule { }
