import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule
  ]
})
export class FiltrosPesquisaReceitaSuiteRfbModule { }
