export enum CodigoReceita {
  DAS_COMUM = "3333",
  DAS_MEI = "0055"
};

export enum OrdemDARF {
  DARF_NUMERADO = '1',
  DARF_COMUM = '2',
  DAS = '3',
  CONSOLIDA = '4',
  NAO_EMITE_CONSOLIDA = '5'
}
