<ng-container *ngIf="executouObterDados; else loadingOrError">
  <suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div class="container-fluid container-filtros">
      <div class="container-fluid">
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Receita
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.ct | vazio }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Exercício
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.exercicio | vazio }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Data de Vencimento
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.dataVencimentoNotificacao | formatDataPipe }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Valor Original
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.valorOriginal | formatCurrency }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Saldo Devedor
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.saldoDevedor | formatCurrency }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Unidade Monetária
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.unidadeMonetaria | vazio }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Situação
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.situacao | vazio }}
              </p>
            </div>
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Tipo Notificação
              </label>
              <p class="form-control-static dado-formulario">
                {{ credito.tipoNotificacao | codigoDescricao | vazio }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <suite-rfb-select
                label="Motivo de transferência"
                formControlName="motivoTransferencia"
                name="motivoTransferencia"
                [labels]="motivosTransferencia | motivosTransferenciaPipe"
                [values]="motivosTransferencia"
                [unselectedValue]="'Selecione o motivo'"
                [unselectedLabel]="'Selecione o motivo'"
                [required]="true"
                dropdownPosition="top"
                (ngModelChange)="configurarMotivo($event)">
              </suite-rfb-select>
            </div>
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Número Processo/PerdComp
              </label>
              <p class="form-control-static dado-formulario input-numero-processo">
                <input type="text" class="form-control" formControlName="numeroProcesso" name="numeroProcesso">
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">
                Motivo
              </label>
              <input type="text" class="form-control" name="motivoOutros" formControlName="motivoOutros">
            </div>
            <div class="col-md-6">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p>&nbsp;</p>
            </div>
            <div class="col-md-6">
              <p>&nbsp;</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p>&nbsp;</p>
            </div>
            <div class="col-md-6">
              <p>&nbsp;</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <button class="btn btn-default" type="reset" (click)="onReset()" [disabled]="!formGroup.dirty">Limpar</button>
                <button class="btn btn-primary" type="submit" [disabled]="!formGroup.valid">Transferir</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </suite-rfb-collapse>
</ng-container>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
