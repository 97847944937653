import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProcessoSief } from '../../model/processo-sief';
import { ProcessoSiefArrolamentoBens } from './model/arrolamento-bens'

@Injectable()
export class ArrolamentoBensSiefService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterArrolamentoBens(ni : string, tipoNi: string, numeroProtocolo: string): Observable<ProcessoSiefArrolamentoBens[]> {
		const url = `${this.baseUrl}/situacoesfiscais/sief/arrolamento-bens/${ni}`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		};
		return this.http.get<ProcessoSiefArrolamentoBens[]>(url, options)
		.pipe(
		  map(processos => {
			processos.forEach(p => {
			  p.tipoNi = tipoNi;
			});
			return processos;
		  })
		);

	}

}