import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedSuiteRfbModule } from 'src/app/shared/shared-suite-rfb.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultaDadosBasicosPfComponent } from "./consulta-dados-basicos-pf.component";
import { ParametrosPesquisaPfComponent } from './parametros-pesquisa/parametros-pesquisa-pf.component';
import { ConsultaDadosBasicosPfTableComponent } from './table/consulta-dados-basicos-pf-table.component';

@NgModule({
  declarations: [
    ConsultaDadosBasicosPfComponent,
    ConsultaDadosBasicosPfTableComponent,
    ParametrosPesquisaPfComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SharedSuiteRfbModule,
    TooltipModule.forRoot(),
  ],
  exports: [
    ConsultaDadosBasicosPfComponent,
  ]
})
export class ConsultaDadosBasicosPfModule { }
