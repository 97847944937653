<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitosAgrupados && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaDebitos>
  <div class="container-fluid" *ngFor="let debitosNirf of debitosAgrupados | keyvalue">
    <div class="nirf">
      <div class="nirf-toggle" style="float:left">
        <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
      </div>
      <div class="nirf-titulo">
        NIRF: {{ debitosNirf.key }}
      </div>
    </div>
    <ng-container *ngIf="rowToggle.show">
      <div class="nirf-conteudo">
        <via-conta-corrente-itr-table *ngFor="let debitosNirfReceita of debitosNirf.value | keyvalue"
          [nomeTributo]="debitosNirfReceita.value[0].nomeTributo"
          [debitos]="debitosNirfReceita.value"
          [isPessoaJuridica]="isPessoaJuridica"
          [isPessoaFisica]="isPessoaFisica"
          [isAtivos]="isAtivos"
          [isSuspensos]="isSuspensos"
          (emitirDarf)="emitirDarf($event)">
        </via-conta-corrente-itr-table>
      </div>
    </ng-container>
  </div>
</ng-template>
