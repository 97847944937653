import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfTokenInterceptor implements HttpInterceptor {

  constructor(
    private cookieService: CookieService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'x-via-csrf-token';
    if(!req.headers.has(headerName)) {
      const viaCsrfToken = this.cookieService.get("via-csrf-token");
      req = req.clone({ headers: req.headers.set(headerName, viaCsrfToken) });
    }
    return next.handle(req);
  }
}
