import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedSuiteRfbModule } from "src/app/shared/shared-suite-rfb.module";
import { SharedModule } from "src/app/shared/shared.module";
import { TituloEleitorComponent } from "./titulo-eleitor.component";
import { TituloEleitorService } from "./titulo-eleitor.service";

@NgModule({
    declarations: [
        TituloEleitorComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SharedSuiteRfbModule,
    ],
    providers: [
        TituloEleitorService
    ],
    exports: [
        TituloEleitorComponent
    ]
})
export class TituloEleitorModule { }
