import { Component, Input } from '@angular/core';
import { isEmpty } from 'lodash';
import { ParametrosPesquisaPessoaFisica } from 'src/app/cpf/models/parametros-pesquisa-pessoa-fisica';
import { CpfService } from 'src/app/cpf/services/cpf.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ParametrosPesquisaTituloEleitor } from '../../titulo-eleitor/model/parametros-pesquisa-titulo-eleitor';
import { TituloEleitor } from '../../titulo-eleitor/model/titulo-eleitor';
import { TituloEleitorService } from '../../titulo-eleitor/titulo-eleitor.service';
import { ConsultaPfComponent } from '../consulta-pf.component';

@Component({
  templateUrl: './consulta-titulo-eleitor.component.html',
  styleUrls: ['./consulta-titulo-eleitor.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaTituloEleitorComponent implements IDynamicPanel {

  @Input() parametrosPesquisa: ParametrosPesquisaTituloEleitor;

  resultadosPesquisa: TituloEleitor[];

  scopeId: string;
  initError: Error;
  stopLoading = false;

  titulo = 'Dados do Título de Eleitor';

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private messageService: ScopedMessageService,
    private tituloEleitorService: TituloEleitorService,
    private cpfService: CpfService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.scopeId = this.messageService.scopeId;

    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.tituloEleitorService.pesquisarTituloEleitor(this.parametrosPesquisa)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }

          this.resultadosPesquisa = response;
        },
        error => {
          this.messageService.showErrorException('Erro na pesquisa de título de eleitor', error);
          this.initError = error;
        }
      );
  }

  exibirResultadoUnico(): boolean {
    const numeroTitulo = this.parametrosPesquisa.numeroTitulo;
    return !isEmpty(numeroTitulo) && this.resultadosPesquisa.length === 1;
  }

  onConsultarDadosCadastrais(numeroTituloEleitor: string): void {
    const parametrosPesquisa = new ParametrosPesquisaPessoaFisica();
    parametrosPesquisa.numeroTituloEleitor = numeroTituloEleitor;

    this.cpfService.pesquisarPessoaFisica(parametrosPesquisa)
      .subscribe(
        response => {
          const cpfContribuinte = response[0].cpfContribuinte;
          this.abrirModalDadosCadastrais(cpfContribuinte);
        },
        error =>
          this.messageService.showErrorException('Houve um erro ao buscar as informações da pessoa física', error),
      );
  }

  private abrirModalDadosCadastrais(cpf: string): void {
    const consultarPfFeature = {
      id: `ConsultarPessoaFisica_${cpf}`,
      title: 'Pessoa Física - Consultar',
      class: ConsultaPfComponent,
      parametros: {
        cpf: cpf,
        isModal: true,
        imprimir: true,
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultarPfFeature);
  }
}
