import { ConsultaPjComponent } from "src/app/cnpj/components/consulta-pj/consulta-pj.component";
import { FiltrosPesquisaPjComponent } from "src/app/cnpj/components/filtros-pesquisa-pj/filtros-pesquisa-pj.component";
import { PesquisaObrigatoriedadeDirfComponent } from "src/app/obrigatoriedade-dirf/components/pesquisa/pesquisa-obrigatoriedade-dirf.component";
import { ConsultaPfComponent } from "src/app/cpf/components/consulta-pf/consulta-pf.component";
import { FiltrosPesquisaCcpfComponent } from "src/app/cobranca/components/ccpf/components/filtros-pesquisa-ccpf/filtros-pesquisa-ccpf.component";
import { FiltrosPesquisaCcitrComponent } from "src/app/cobranca/components/ccitr/components/filtros-pesquisa-ccitr/filtros-pesquisa-ccitr.component";
import { FiltrosPesquisaPfComponent } from "src/app/cpf/components/filtros-pesquisa-pf/filtros-pesquisa-pf.component";
import { FiltrosPesquisaPagamentoComponent } from "src/app/pagamento/components/filtros-pesquisa/filtros-pesquisa-pagamento.component";
import { FeatureAction } from "src/app/shared/model/feature";
import { FiltrosPesquisaReceitaComponent } from "src/app/sief/receitas/filtros-pesquisa-receita/filtros-pesquisa-receita.component";
import { environment } from "src/environments/environment";
import { CCPFContribuinteDepuradoComponent } from "src/app/cobranca/components/ccpf/components/contribuinte-depurado/contribuinte-depurado.component";

const TODOS_PERFIS = ['ATENDEARFB', 'ATENDENTE', 'CONSULTA', 'GERENCIAL1', 'GERENCIAL2'];

const ACTION_CONSULTAR_PF:                 FeatureAction = { id: 'ConsultarPessoaFisica', title: 'Consultar', visible: true, class: FiltrosPesquisaPfComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CONSULTAR_PF_SITFIS:          FeatureAction = { id: 'ConsultarPessoaFisicaSitFis', title: 'Consultar', visible: true, class: ConsultaPfComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_E_DEFESA:                     FeatureAction = { id: 'LinkEDefesa', title: 'e-Defesa', visible: true, url: environment.urlE_Defesa, rolesAllowed: TODOS_PERFIS } as FeatureAction;
const ACTION_PORTAL_CADASTROS:             FeatureAction = { id: 'LinkPortalCadastros', title: 'Portal de Cadastros', visible: true, url: environment.urlPortalCadastros, rolesAllowed: TODOS_PERFIS } as FeatureAction;
const ACTION_RELATORIO_CONSOLIDADO:        FeatureAction = { id: 'RelatorioConsolidado', title: 'Relatório Consolidado', visible: false } as FeatureAction;

const ACTION_CONSULTAR_CCITR:              FeatureAction = {
  id: 'ConsultarCCITR',
  title: 'Consultar',
  visible: true,
  class: FiltrosPesquisaCcitrComponent,
  rolesAllowed: TODOS_PERFIS
} as FeatureAction;

const ACTION_CCITR: FeatureAction = {
   id: 'CCITR',
   title: 'CCITR',
   collapsed: false,
   children: [ACTION_CONSULTAR_CCITR]
  } as FeatureAction;

const ACTION_CONSULTAR_PAGAMENTOS:         FeatureAction = { id: 'ConsultarPagamentos', title: 'Consultar', visible: true, class: FiltrosPesquisaPagamentoComponent, rolesAllowed: TODOS_PERFIS } as FeatureAction;

const ACTION_CONSULTAR_PJ:                 FeatureAction = { id: 'ConsultarPessoaJuridica', title: 'Consultar', visible: true, class: FiltrosPesquisaPjComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CONSULTAR_PJ_SITFIS:          FeatureAction = { id: 'ConsultarPessoaJuridicaSitFis', title: 'Consultar', visible: true, class: ConsultaPjComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_OBRIGATORIEDADE_DIRF:         FeatureAction = { id: 'ObrigatoriedadeDIRF', title: 'Obrigatoriedade DIRF', visible: true, class: PesquisaObrigatoriedadeDirfComponent, rolesAllowed: TODOS_PERFIS } as FeatureAction;

const ACTION_TOM:                          FeatureAction = { id: 'Tom', title: 'TOM', visible: true, url: environment.urlTOM, rolesAllowed: TODOS_PERFIS } as FeatureAction;
const ACTION_RECEITA:                      FeatureAction = { id: 'Receita', title: 'Receita', visible: true, class: FiltrosPesquisaReceitaComponent, rolesAllowed: TODOS_PERFIS } as FeatureAction;

const ACTION_PESSOA_FISICA: FeatureAction = {
  id: 'PessoaFisica',
  title: 'Pessoa Física',
  collapsed: false,
  children: [ACTION_CONSULTAR_PF, ACTION_E_DEFESA, ACTION_PORTAL_CADASTROS, ACTION_RELATORIO_CONSOLIDADO]
} as FeatureAction;

const ACTION_PESSOA_FISICA_SITFIS: FeatureAction = {
  id: 'PessoaFisicaSitFis',
  title: 'Pessoa Física',
  collapsed: false,
  children: [ACTION_CONSULTAR_PF_SITFIS, ACTION_E_DEFESA, ACTION_PORTAL_CADASTROS, ACTION_RELATORIO_CONSOLIDADO]
} as FeatureAction;

const ACTION_CCPF_CONSULTAR: FeatureAction = {
  id: 'CcpfConsultar',
  title: 'Consultar',
  visible: true,
  class: FiltrosPesquisaCcpfComponent,
  rolesAllowed: TODOS_PERFIS
} as FeatureAction;

const ACTION_CCPF_SOLICITAR_RETORNO_CONTRIBUINTE: FeatureAction = {
  id: 'CcpfSolicitarRetornoContribuinteDepurado',
  title: 'Solicitar Retorno Contribuinte Depurado',
  visible: true,
  rolesAllowed: TODOS_PERFIS,
  class: CCPFContribuinteDepuradoComponent
} as FeatureAction;

const ACTION_CCPF: FeatureAction = {
  id: 'CCPF',
  title: 'CCPF',
  visible: true,
  collapsed: false,
  rolesAllowed: TODOS_PERFIS,
  children: [ACTION_CCPF_CONSULTAR, ACTION_CCPF_SOLICITAR_RETORNO_CONTRIBUINTE]
} as FeatureAction;

const ACTION_COBRANCA_PESSOA_FISICA: FeatureAction = {
  id: 'CobrancaPessoaFisica',
  title: 'Cobrança',
  collapsed: false,
  children: [ACTION_CCPF, ACTION_CCITR]
} as FeatureAction;

const ACTION_PAGAMENTO: FeatureAction = {
  id: 'Pagamento',
  title: 'Pagamento',
  collapsed: false,
  children: [ACTION_CONSULTAR_PAGAMENTOS]
} as FeatureAction;

const ACTION_PESSOA_JURIDICA: FeatureAction = {
  id: 'PessoaJuridica',
  title: 'Pessoa Jurídica',
  collapsed: false,
  children: [ACTION_CONSULTAR_PJ, ACTION_OBRIGATORIEDADE_DIRF]
} as FeatureAction;

const ACTION_PESSOA_JURIDICA_SITFIS: FeatureAction = {
  id: 'PessoaJuridicaSitFis',
  title: 'Pessoa Jurídica',
  collapsed: false,
  children: [ACTION_CONSULTAR_PJ_SITFIS, ACTION_OBRIGATORIEDADE_DIRF]
} as FeatureAction;

const ACTION_COBRANCA_PESSOA_JURIDICA: FeatureAction = {
  id: 'CobrancaPessoaJuridica',
  title: 'Cobrança',
  collapsed: false,
  children: [ACTION_CCITR]
} as FeatureAction;

const ACTION_CONSULTAS_GERAIS: FeatureAction = {
  id: 'ConsultasGerais',
  title: 'Consultas Gerais',
  collapsed: false,
  children: [ACTION_TOM, ACTION_RECEITA]
} as FeatureAction;

export const FEATURES_MENU_PRINCIPAL: FeatureAction[] = [
  ACTION_PESSOA_FISICA,
  ACTION_PESSOA_JURIDICA,
  ACTION_COBRANCA_PESSOA_FISICA,
  ACTION_PAGAMENTO,
  ACTION_CONSULTAS_GERAIS
];

export const FEATURES_PF: FeatureAction[] = [
  ACTION_PESSOA_FISICA_SITFIS,
  ACTION_COBRANCA_PESSOA_FISICA,
  ACTION_PAGAMENTO
];

export const FEATURES_PJ: FeatureAction[] = [
  ACTION_PESSOA_JURIDICA_SITFIS,
  ACTION_COBRANCA_PESSOA_JURIDICA,
  ACTION_PAGAMENTO
];
