import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNirfPipe'
})
export class FormatNirfPipe implements PipeTransform {
  transform(nirf: number) {
    return FormatNirfPipe.formatNirf(nirf);
  }

  static formatNirf: Function = (nirf: any) => {
      if(nirf){
        let tam = nirf.length;
        let resultado = nirf.substring(tam-1);
        for (let ini = tam - 4; ini > -3 ; ini-=3 ){
          resultado = nirf.substring(ini > 0 ? ini : 0 , ini+3 ) 
            + (resultado.length>1 ? '.' : '-') 
            + resultado;
        }
        return resultado;
      }
  }
}