import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentoVisualizacaoComponent } from '../components/documento-visualizacao/documento-visualizacao.component';
import { DocumentoPessoaFisica } from '../models/documento-pessoa-fisica';

@Injectable()
export class DocumentosService {

  private baseUrl = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService) { }

  public obterComprovanteInscricao(cpf: string): Observable<DocumentoPessoaFisica> {
    const url = `${this.baseUrl}/cpf/documentos/comprovante-inscricao/${cpf}`;
    return this.http.get<DocumentoPessoaFisica>(url);
  }

  public obterComprovanteSituacaoCadastral(cpf: string): Observable<DocumentoPessoaFisica> {
    const url = `${this.baseUrl}/cpf/documentos/comprovante-situacao-cadastral/${cpf}`;
    return this.http.get<DocumentoPessoaFisica>(url);
  }

  public obterComprovanteCertidaoNarrativa(cpf: string): Observable<DocumentoPessoaFisica> {
    const url = `${this.baseUrl}/cpf/documentos/comprovante-certidao-narrativa/${cpf}`;
    return this.http.get<DocumentoPessoaFisica>(url);
  }  

  public visualizarDocumento(documento: DocumentoPessoaFisica): void {
    const bsModalRef = this.modalService.show(DocumentoVisualizacaoComponent, {
      class: 'documento-dialog',
      initialState: {
        documento: documento
      }
    });

    bsModalRef.content.bsModalRef = bsModalRef;
  }

  public salvarDocumento(documento: DocumentoPessoaFisica) {
    const base64Bytes = window.atob(documento.documentoBase64).split('').map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(base64Bytes);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    FileSaver.saveAs(file, documento.nomeArquivo, { autoBom: false });
  }
}
