import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { DetalheHomonimo } from '../../../models/detalhe-homonimo';

@Component({
  selector: 'via-homonimos-table',
  templateUrl: './homonimos-table.component.html',
  styleUrls: ['./homonimos-table.component.css']
})
export class HomonimosTableComponent {

  @Input() homonimos: DetalheHomonimo[];

  @Input() titulo: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
