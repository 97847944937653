import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { Component, Directive, Inject, Injectable, InjectFlags, InjectionToken, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ParametrosPesquisaCcitr } from "src/app/cobranca/models/parametros-pesquisa-ccitr";
import { FormatCnpjPipe } from "src/app/shared/pipes/formata-cnpj.pipe";
import { FormatCpfPipe } from "src/app/shared/pipes/formata-cpf.pipe";
import { FormatNirfPipe } from "src/app/shared/pipes/formata-nirf.pipe";

@Component({
  selector: '',
  templateUrl: './ccitr-base.component.html',
  styleUrls: ['./ccitr.css'],
  providers: [ScopedMessageService]
})

export class CcitrBase implements OnChanges {

  titulo = 'CCITR - Consultar NIRF CPF: 999.999.999-99 ou CNPJ 99.999.999/9999-99'

  @Input() atendimento: boolean;
  ehAtendimentoParametro: boolean = false;

  public ngOnChanges(changes: SimpleChanges): void {
    this.ehAtendimentoParametro = this.atendimentoEhInformadoComoParametro(changes);
  }

  public init(ni: string, params?: OptionalParams): void {
    if (!this.ehAtendimentoParametro)
      this.atendimento = params.atendimento;
  }

  protected retornaFormatoExibicao(ehModal: boolean): string {
    return ehModal ? "modal-container" : "";
  }

  protected atendimentoEhInformadoComoParametro(changes: SimpleChanges): boolean {

    if (changes.hasOwnProperty('atendimento')) {
      if (changes['atendimento'].isFirstChange()) {
        return true;
      }
      else {
        return false;
      }
    }

  }

  protected formataTitulo(parametros: ParametrosPesquisaCcitr, inicioTitulo: string): void {

    let cpfContribuinte = parametros.cpfContribuinte;
    let cnpjContribuinte = parametros.cnpjContribuinte;
    let indicadorCnpjBasico = parametros.indicadorCnpjBasico;
    this.titulo = inicioTitulo;

    if (parametros.nirf) {
      this.titulo += ` - NIRF: ${FormatNirfPipe.formatNirf(parametros.nirf)}`;
    }

    if (cpfContribuinte) {
      this.titulo += ` - CPF: ${FormatCpfPipe.formatCPF(parametros.cpfContribuinte)}`;
    } else {
      if (cnpjContribuinte) {
        if (indicadorCnpjBasico == '1') {
          this.titulo += ` - CNPJ BÁSICO: ${cnpjContribuinte}`;
        } else {
          this.titulo += ` - CNPJ: ${FormatCnpjPipe.formatCNPJ(cnpjContribuinte)}`;
        }
      }
    }
  }


  protected topoDaPaginaMensagem(){
    document.getElementsByClassName('modal')[document.getElementsByClassName('modal').length-1].scrollTop = 0;
  }

}
