import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParcelamentoSIEFParSuiteRfbModule } from './parcelamento-siefpar-suite-rfb.module';
import { SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { ParcelamentoSIEFParComponent, ParcelamentoSIEFParPFCobrancaComponent, ParcelamentoSIEFParPFExigibilidadeComponent, ParcelamentoSIEFParPJCobrancaComponent, ParcelamentoSIEFParPJExigibilidadeComponent } from './parcelamento-siefpar.component';
import { ParcelamentoSIEFParService } from './parcelamento-siefpar.service';
import { ParcelamentoSIEFParTableComponent } from './table/parcelamento-siefpar-table.component';

@NgModule({
  declarations: [
    ParcelamentoSIEFParComponent,
    ParcelamentoSIEFParTableComponent,
    ParcelamentoSIEFParPFCobrancaComponent,
    ParcelamentoSIEFParPFExigibilidadeComponent,
    ParcelamentoSIEFParPJCobrancaComponent,
    ParcelamentoSIEFParPJExigibilidadeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    ParcelamentoSIEFParSuiteRfbModule,
    SuiteRfbToggleModule    
  ],
  providers: [
    ParcelamentoSIEFParService
  ],
  entryComponents: [
    ParcelamentoSIEFParPFCobrancaComponent,
    ParcelamentoSIEFParPFExigibilidadeComponent,
    ParcelamentoSIEFParPJCobrancaComponent,
    ParcelamentoSIEFParPJExigibilidadeComponent
  ]
})
export class ParcelamentoSIEFPARModule { }