import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParcelamentoPgfn } from '../model/parcelamento-pgfn';
import { ParcelamentoPgfnService } from '../parcelamento-pgfn.service';

@Component({
  selector: 'via-parcelamento-pgfn-table',
  templateUrl: 'parcelamento-pgfn-table.component.html',
  styleUrls: ['parcelamento-pgfn-table.component.css']
})
export class ParcelamentoPgfnTableComponent {

  @Input() nomeNi: string;
  @Input() parcelamentos: ParcelamentoPgfn[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  get _window() {
    return window;
  }

  constructor(private parcelamentoPgfnService: ParcelamentoPgfnService) { }

  urlPortalRfb(parcelamento: ParcelamentoPgfn): string {
    if (this.isPessoaFisica) {
      parcelamento.ni = parcelamento.ni.slice(-11);
    }
    return this.parcelamentoPgfnService.getUrlExtratoPgfn(this.nomeNi, parcelamento);
  }
}
