<ng-container *ngIf="!featureAction.children && featureAction.visible">
    <a class="menu-item" href="javascript:void(0)" (click)="onClick(featureAction)">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { featureAction: featureAction }"></ng-container>
    </a>
</ng-container>

<ng-container *ngIf="featureAction.children && featureAction.visible">
    <a href="javascript:void(0)" class="menu-item raiz" [attr.aria-expanded]="!collapsed" [id]="this.featureAction.id"
        (click)="collapsed = !collapsed">{{featureAction.title}}</a>
    <ul class="menu nav-second-level collapse" [attr.aria-expanded]="!collapsed"
        [class.in]="!collapsed">
        <ng-container *ngFor="let childFeature of featureAction.children">
        <li *ngIf="childFeature.visible">
            <app-menu-feature-action [featureAction]="childFeature" [root]="false" class="active">
            </app-menu-feature-action>
        </li>
        </ng-container>
    </ul>
</ng-container>

<ng-template #itemTemplate let-featureAction="featureAction">
    <span *ngIf="featureAction.active" class="material-icons icon-24" style="margin-right: 10px;">add_task</span>
    <span [style.font-weight]="featureAction.active ? 'bold' : 'normal'" [id]="featureAction.id">
        {{featureAction.title}}
        <ng-container *ngIf="featureAction.shortcut"  >
            &nbsp;({{featureAction.shortcut}})
        </ng-container>
    </span>
    <span *ngIf="featureAction.favorite" class="material-icons icon-18">&#xE838;</span>
</ng-template>