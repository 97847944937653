import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DocumentoPessoaFisica } from '../../models/documento-pessoa-fisica';
import { DocumentosService } from '../../services/documentos.service';

@Component({
  selector: 'via-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css'],
  providers: [ScopedMessageService]
})
export class DocumentosComponent implements OnInit {

  @Input() cpf: string;

  comprovanteInscricao: DocumentoPessoaFisica;
  comprovanteSituacaoCadastral: DocumentoPessoaFisica;
  comprovanteCertidaoNarrativa: DocumentoPessoaFisica;

  scopeId: string;

  private executouObterDados = false;

  constructor(
    private messageService: ScopedMessageService,
    private documentosService: DocumentosService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onVisualizarComprovante(comprovante: DocumentoPessoaFisica): void {
    this.documentosService.visualizarDocumento(comprovante);
  }

  onSalvarComprovante(comprovante: DocumentoPessoaFisica): void {
    this.documentosService.salvarDocumento(comprovante);
  }

  hasDocumentos(): boolean {
    return !_.isNull(this.comprovanteInscricao)
      || !_.isNull(this.comprovanteSituacaoCadastral)
      || !_.isNull(this.comprovanteCertidaoNarrativa);
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterDocumentos();
    }
  }

  private obterDocumentos(): void {
    this.executouObterDados = true;

    this.documentosService.obterComprovanteInscricao(this.cpf)
      .subscribe(
        comprovante => {
          this.comprovanteInscricao = comprovante;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter o comprovante de inscrição', error);
        });

    this.documentosService.obterComprovanteSituacaoCadastral(this.cpf)
      .subscribe(
        comprovante => {
          this.comprovanteSituacaoCadastral = comprovante;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter o comprovante de situação cadastral', error);
        });

    this.documentosService.obterComprovanteCertidaoNarrativa(this.cpf)
      .subscribe(
        comprovante => {
          this.comprovanteCertidaoNarrativa = comprovante;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter o comprovante de certidão narrativa', error);
        });
  }
}
