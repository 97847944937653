<suite-rfb-collapse title="Homônimos" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <ng-container *ngIf="homonimosPorTipo; else loadingOrError;">
    <ng-container *ngIf="homonimosPerfeitos">
      <via-homonimos-table [homonimos]="homonimosPerfeitos" titulo="Homônimos Perfeitos">
      </via-homonimos-table>
    </ng-container>

    <ng-container *ngIf="homonimosMaisQuePerfeitos">
      <via-homonimos-table [homonimos]="homonimosMaisQuePerfeitos" titulo="Homônimos Mais que Perfeitos">
      </via-homonimos-table>
    </ng-container>

    <ng-container *ngIf="homonimosImperfeitos">
      <via-homonimos-table [homonimos]="homonimosImperfeitos" titulo="Homônimos Imperfeitos">
      </via-homonimos-table>
    </ng-container>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;">
    Ocorreu um erro...
  </div>

  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
