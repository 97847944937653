import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { ComponenteCcitr, CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';

@Component({
  selector: 'via-ccitr-alocacao-debito-componentes',
  templateUrl: './alocacao-debito-table-componentes.component.html',
  styleUrls: ['../alocacao-debito.component.css']
})
export class CCITRAlocacaoDebitoTableComponentesComponent implements OnInit {

  @Input() credito: CreditoTributarioCcitr;

  @Output() alocarDebito = new EventEmitter<{ componente: ComponenteCcitr, valorAlocar: string }>();

  componentes: ComponenteCcitr[];

  currencyMaskOptions = {
    thousands: '.',
    decimal: ',',
    prefix: '',
    allowNegative: false,
    nullable: false,
    min: 0,
    max: null,
    precision: 2
  };

  ngOnInit(): void {
    this.componentes = this.credito.componentes;
  }

  isValorParaAlocacaoInvalido(valorParaAlocacao: number): boolean {
    return isNil(valorParaAlocacao) || valorParaAlocacao <= 0;
  }

  onAlocarDebito(componente: ComponenteCcitr): void {
    const valorAlocar = currency(componente.valorParaAlocacao).toString();
    this.alocarDebito.emit({ componente, valorAlocar });
  }
}
