import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { InscricaoPgfnService } from './inscricao-pgfn.service';
import { InscricaoPgfn } from './model/inscricao-pgfn';

@Component({
  selector: 'app-parcelamento-pgfn',
  templateUrl: './inscricao-pgfn.component.html',
  styleUrls: ['./inscricao-pgfn.component.css']
})
export class InscricaoPgfnComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  inscricoes: InscricaoPgfn[];

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private inscricaoPgfnService: InscricaoPgfnService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('InscricaoPgfnComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let inscricoesObservable: Observable<InscricaoPgfn[]>;
      if (this.isAtivos) {
        inscricoesObservable = this.inscricaoPgfnService.obterInscricoesPgfnAtivas(ni, numeroProtocolo);
      }
      if (this.isSuspensos) {
        inscricoesObservable = this.inscricaoPgfnService.obterInscricoesPgfnSuspensas(ni, numeroProtocolo);
      }

      inscricoesObservable.subscribe(
        (resp) => {
          this.inscricoes = resp;
          this.logService.logAjaxReturn('InscricaoPgfnComponent', 'obterInscricoes');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('InscricaoPgfnComponent', 'obterInscricoes', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter as inscricoes PGFN', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('InscricaoPgfnComponent', 'obterInscricoes', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter as inscricoes PGFN', e);
    }
  }
}

@Component({
  selector: 'app-parcelamento-pgfn',
  templateUrl: './inscricao-pgfn.component.html',
  styleUrls: ['./inscricao-pgfn.component.css']
})
export class InscricaoPgfnPJAtivoComponent extends InscricaoPgfnComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos PGFN Inscrições - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-pgfn',
  templateUrl: './inscricao-pgfn.component.html',
  styleUrls: ['./inscricao-pgfn.component.css']
})
export class InscricaoPgfnPJSuspensoComponent extends InscricaoPgfnComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos PGFN Inscrições - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-pgfn',
  templateUrl: './inscricao-pgfn.component.html',
  styleUrls: ['./inscricao-pgfn.component.css']
})
export class InscricaoPgfnPFAtivoComponent extends InscricaoPgfnComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos PGFN Inscrições - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-pgfn',
  templateUrl: './inscricao-pgfn.component.html',
  styleUrls: ['./inscricao-pgfn.component.css']
})
export class InscricaoPgfnPFSuspensoComponent extends InscricaoPgfnComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos PGFN Inscrições - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}
