import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheEventoPj } from '../models/detalhe-evento-pj';

@Injectable()
export class EventosPjService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterEventos(cnpj: string): Observable<DetalheEventoPj[]> {
    const url = `${this.baseUrl}/cnpj/${cnpj}/eventos`;
    return this.httpClient.get<DetalheEventoPj[]>(url);
  }
}
