import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSisparDTO } from './model/parcelamento-sispar';

@Injectable()
export class ParcelamentoSisparService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosSispar(ni: string, numeroProtocolo: string, tipoPendencia: TipoPendencia): Observable<ParcelamentoSisparDTO[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sispar/${tipoPendencia}/${ni}`;

    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };

    return this.http.get<ParcelamentoSisparDTO[]>(url, options);
  }

  public getUrlSisparConsultaDarf(): string {
    return environment.urlSisparConsultaDarf;
  }
}
