import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { LoadingService, Page } from "@serpro/ngx-suite-rfb";
import { isEmpty } from "lodash";
import moment from 'moment';
import { EventoCreditoTributario } from "src/app/cobranca/models/evento-credito-tributario";
import { ParametrosConsultaEventosCT } from "src/app/cobranca/models/parametros-pesquisa";
import { IDynamicPanel, OptionalParams } from "src/app/shared/dynamic-panel/panel-interface";
import { FormatCpfPipe } from "src/app/shared/pipes/formata-cpf.pipe";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { CCPFService } from "../../services/ccpf.service";

@Component({
  selector: 'via-eventos-credito-tributario',
  templateUrl: './eventos-credito-tributario.component.html',
  styleUrls: ['./eventos-credito-tributario.component.css'],
  providers: [ScopedMessageService]
})
export class EventosCreditoTributario implements IDynamicPanel, OnInit, OnChanges {

  @Input() parametros: ParametrosConsultaEventosCT;
  @Input() atendimento = false;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  cpf: string;
  dataInicial: Date;
  dataFinal: Date;
  numeroCT: string;

  eventosCreditoTributario: EventoCreditoTributario[];

  initError: Error;
  scopeId: string;
  titulo: string;
  exibeMensagem = false;

  pageSize = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private loadingService: LoadingService,
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService) { }

  init(ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametrosConsultaEventosCT;
    this.atendimento = ni ? true : this.atendimento;
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.cpf = this.parametros.cpfContribuinte;
    this.numeroCT = this.parametros.numeroCT;
    this.dataInicial = this.parametros.dataInicial;
    this.dataFinal = this.parametros.dataFinal;
    this.titulo = `CCPF - Eventos do CT: ${this.numeroCT} - CPF: ${FormatCpfPipe.formatCPF(this.cpf)}`;
    this.recarregar();
  }

  ngOnChanges(): void {
    if (this.titulo) {
      this.dataInicial = this.parametros.dataInicial;
      this.dataFinal = this.parametros.dataFinal;
      this.recarregar();
    }
  }

  private recarregar(): void {
    this.initError = null;
    this.eventosCreditoTributario = null;
    this.pesquisarEventos();
  }

  private pesquisarEventos(): void {
    let sDataInicial = this.dataInicial ? moment(this.dataInicial).format('YYYYMMDD') : null;
    let sDataFinal = this.dataFinal ? moment(this.dataFinal).format('YYYYMMDD') : null;

    this.loadingService.show();
    this.esconderMensagem();

    this.ccpfService.consultarEventosCreditoTributario(this.cpf, this.numeroCT, sDataInicial, sDataFinal).subscribe(
      (resp) => {
        this.loadingService.hide();
        this.eventosCreditoTributario = resp;
        if (resp && isEmpty(resp)) {
          this.exibirMensagem();
          this.messageService.showInfo('Nenhum resultado encontrado');
        }
      },
      (err) => {
        this.loadingService.hide();
        this.initError = err;
        this.exibirMensagem();
        this.messageService.showErrorException(`Ocorreu um erro na consulta de eventos do CT ${this.numeroCT}`, err);
      }
    );
  }

  private exibirMensagem(): void {
    this.exibeMensagem = true;
  }

  private esconderMensagem(): void {
    this.exibeMensagem = false;
  }
}
