import { Component } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfCnpjPipe } from 'src/app/shared/pipes/formata-cpf-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSIPADE } from './model/parcelamento-sipade';
import { ParcelamentoSIPADEService } from './parcelamento-sipade.service';

@Component({
  templateUrl: './parcelamento-sipade.component.html',
  styleUrls: ['./parcelamento-sipade.component.css']
})
export class ParcelamentoSIPADEComponent implements IDynamicPanel {

  titulo: string;
  tipoPendencia: TipoPendencia;
  exigibilidadeSuspensa: boolean;

  initError: Error;
  loadingError$ = new Subject<boolean>();
  parcelamentosObservable$: Observable<ParcelamentoSIPADE[]>;

  constructor(
    private sipadeService: ParcelamentoSIPADEService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService) {
  }

  public init(ni: string): void {
    const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
    const protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
    const tipoNI = infosContribuinte.tipoNIAtendimento;

    this.titulo = `${this.titulo} - ${tipoNI}: ${FormatCpfCnpjPipe.format(ni)}`;

    this.parcelamentosObservable$ = this.sipadeService
      .obterParcelamentosSIPADE(ni, tipoNI, protocolo, this.tipoPendencia)
      .pipe(
        catchError(err => {
          this.loadingError$.next(true);
          this.messageService.showErrorException(this.titulo, err);
          return of<ParcelamentoSIPADE[]>();
        })
      );
  }
}

@Component({
    templateUrl: './parcelamento-sipade.component.html',
    styleUrls: ['./parcelamento-sipade.component.css']
})
export class ParcelamentoSIPADECobrancaComponent extends ParcelamentoSIPADEComponent {

  public init(ni: string): void {
    this.tipoPendencia = TipoPendencia.COBRANCA;
    this.titulo = 'Parcelamentos Ativos Fazendários SIPADE ';
    this.exigibilidadeSuspensa = false;
    super.init(ni);
  }
}
@Component({
    templateUrl: './parcelamento-sipade.component.html',
    styleUrls: ['./parcelamento-sipade.component.css']
})
export class ParcelamentoSIPADEExigibilidadeComponent extends ParcelamentoSIPADEComponent {

  public init(ni: string): void {
    this.tipoPendencia = TipoPendencia.EXIGIBILIDADE_SUSPENSA;
    this.titulo = 'Parcelamentos Suspensos Fazendários SIPADE ';
    this.exigibilidadeSuspensa = true;
    super.init(ni);
  }
}
