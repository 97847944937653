import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DebitoAguia } from '../../model/debitoAguia';

@Injectable()
export class AguiaService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterDebitosAguia(ni: string, numeroProtocolo: string): Observable<DebitoAguia[]> {
        const url = `${this.baseUrl}/situacoesfiscais/aguia/${ni}`;
        console.log('url= ' + url);
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<DebitoAguia[]>(url, options);
    }

}
