/*
 * ngx-suite-rfb
 *
 * A biblioteca ngx-suite-rfb implementa um conjunto de componentes,
 * diretivas, pipes e serviços nativos do Angular com o objetivo de
 * promover o reuso e facilitar a implementação de aplicações frontend
 * que serão implantandas na Suíte RFB e, portanto, devem seguir os
 * Padrões de Interface Visual RFB.
 *
 * Documentação disponível em: https://dedat.gitpages.serpro/deat3/ngx-suite-rfb/
 *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViaTabComponent } from './components/tab/tab.component';
import { ViaTabSetComponent } from './components/tab-set/tab-set.component';
import { DragDropModule } from '@angular/cdk/drag-drop'; 

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
  ],
  declarations: [
    ViaTabComponent,
    ViaTabSetComponent,
  ],
  exports: [
    ViaTabComponent,
    ViaTabSetComponent,
  ],
})
export class ViaTabsModule {}
