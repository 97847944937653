<via-tab-set (tabCloseClick)="onTabCloseClick($event)" (tabMoveClick)="onTabMoveClick($event)">
  <!-- para cada aba gera um menu de atendimento -->
  <via-tab *ngFor="let tab of tabs"
    [name]="tab.ni.nome"
    [tabSelectBody]="tabSelectBody"
    [data]="tabDataFromAba(tab)"
    [closeable]="true"
    [(active)]="tab.active"
    (activeChange)="abaAtivaAlterada(tab.ni.ni, $event)">

    <via-atendimento
      [ni]="tab.ni"
      [active]="tab.active"
      [tabName]="tab.ni.nome"
      (tabInitialized)="abaInicializada($event)">
    </via-atendimento>
  </via-tab>

  <via-service-status *ngIf="tabs.length === 0"></via-service-status>

  <ng-template #tabSelectBody let-tab="tab">
    <div [class.tab-select-body]="true" [class.drawing-attention]="tab.data.drawingAttention" [title]="tab.name">
      <img class="img" *ngIf="tab.data.img" [src]="tab.data.img | trustUrl" blob="true" [title]="tab.name">
      <div class="text" *ngFor="let text of tab.data.texts">
        <span [title]="tab.name">{{ text }}</span>
        <button
          title="Copiar NI"
          (click)="copiarNI($event, text); nitip.show();"
          (mouseout)="nitip.hide()"
          class="material-icons copiar"
          #nitip="bs-tooltip"
          tooltip="NI {{ text }} copiado para a área de transferência."
          [delay]="0"
          triggers=""
          placement="right"
          container="body">
          content_copy
        </button>
      </div>
    </div>
  </ng-template>
</via-tab-set>
