import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbPaginationModule,
  SuiteRfbTableModule,
  SuiteRfbToggleModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ]
})
export class EventosPjSuiteRfbModule { }
