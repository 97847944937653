<div [ngClass]="{ 'modal-container': isModal }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <ng-container *ngIf="(pagamentos$ | async) as pagamentos; else loadingOrError;">
      <div class="table-responsive">
        <table suiteRfbTable>
          <caption class="caption-ccpf">Pagamentos</caption>
          <thead>
            <tr>
              <th class="text-center min-width">Ações</th>
              <th class="text-center min-width">
                <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
                </suite-rfb-toggle>
              </th>
              <th class="text-center min-width">Receita</th>
              <th class="text-center min-width">Núm. Pagamento</th>
              <th class="text-center min-width">Data Arrecadação</th>
              <th class="text-center min-width">Vencimento</th>
              <th class="text-center">Valor Total</th>
              <th class="text-center">Utilizado</th>
              <th class="text-center">Disponível</th>
              <th class="text-center">Situação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let pagamento of pagamentos | currentPage: currentPage;">
              <tr class="vertical-align-middle">
                <td class="text-center">
                  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                  <ng-template #menuAcoes>
                    <div class="btn-group-vertical" role="group">
                      <button type="button" class="btn btn-default"
                        (click)="onAlocarDebito(pagamento); botaoAcoes.hide();">
                        Alocar Débito
                      </button>
                    </div>
                  </ng-template>
                </td>
                <td>
                  <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>
                <td class="text-center">
                  <via-codigo-receita [codigo]="pagamento.codigoReceita"></via-codigo-receita>
                </td>
                <td class="text-center">{{ pagamento.numeroPagamento | vazio }} </td>
                <td class="text-center">{{ pagamento.dataArrecadacao | formatDataPipe }}</td>
                <td class="text-center">{{ pagamento.dataVencimento | formatDataPipe }}</td>
                <td class="text-right">{{ pagamento.valorTotal | formatCurrency }}</td>
                <td class="text-right">{{ pagamento.valorUtilizado | formatCurrency }}</td>
                <td class="text-right">{{ pagamento.valorDisponivel | formatCurrency }}</td>
                <td class="text-left">{{ pagamento.situacaoPagamento | vazio }}</td>
              </tr>
              <ng-container *ngIf="rowToggle.show">
                <tr></tr>
                <tr>
                  <td colspan="10">
                    <div class="contorno">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Banco / Agência
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ pagamento.banco | vazio }} / {{ pagamento.agencia | vazio }}
                          </p>
                        </div>
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Valor Bloqueado
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ pagamento.valorBloqueado | formatCurrency }}
                          </p>
                        </div>
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Valor Reservado
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ pagamento.valorReservado | formatCurrency }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Valor Aloc. Aut.
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ pagamento.valorAlocacoesAutomaticas | formatCurrency }}
                          </p>
                        </div>
                        <div class="col-md-8">
                          <label class="label-formulario control-label">
                            Valor Aloc. Man.
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ pagamento.valorAlocacoesManuais | formatCurrency }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <via-ccpf-debitos-pagamento
                            [pagamento]="pagamento"
                            [cpfContribuinte]="parametros.cpfContribuinte"
                            [atendimento]="atendimento">
                          </via-ccpf-debitos-pagamento>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="10">
                <via-pagination [itemAmount]="pagamentos.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
