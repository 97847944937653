import { NaturezaDocumentoArrecadacao } from "./natureza-documento-arrecadacao";
import { TipoNIRDOC } from "./tipo-ni-rdoc";

export interface ParametrosPesquisaPagamento {
  numeroPagamento: string;
  numeroDocumento: string;
  numeroReferencia: string;
  periodoArrecadacao: PeriodoArrecadacao;
  niSemDv: NISemDV;
  codigoBanco: string;
  codigoAgencia: string;
  faixaArrecadacao: FaixaArrecadacao;
  naturezaDocumento: NaturezaDocumentoArrecadacao;
  codigoReceita: string;
  nivelPesquisa: NivelPesquisa;
}

export interface PeriodoArrecadacao {
  dataInicial: Date;
  dataFinal: Date;
}

export interface NISemDV {
  ni: string;
  tipoNi: TipoNIRDOC;
  niInvalido: boolean;
}

export interface FaixaArrecadacao {
  valorInicial: number;
  valorFinal: number;
}

export interface NivelPesquisa {
  tipo: TipoNivelPesquisa;
  valor: string;
}

export enum TipoNivelPesquisa {
  DELEGACIA = 1,
  REGIAO = 2,
  BRASIL = 3
}
