import { Component, ViewEncapsulation } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfCnpjPipe } from 'src/app/shared/pipes/formata-cpf-cnpj.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { QuadroSocioPjDTO } from '../../model/quadro-socio-pj-dto';
import { SocioAdminQSAService } from './socio-admin-qsa.service';

@Component({
	selector: 'via-socio-admin-qsa',
	templateUrl: './socio-admin-qsa.component.html',
	styleUrls: ['./socio-admin-qsa.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class SocioAdminQSAComponent implements IDynamicPanel {
	titulo: string = '';
	cnpj: string = '';
	razaoSocial: string = '';
	socios: QuadroSocioPjDTO[];
	initError: Error;

	constructor(
		private service: SocioAdminQSAService,
		private atendimentoService: AtendimentoService,
		private messageService: ScopedMessageService,
		private logService: LogService) {
	}

	public init(cnpj: string): void {
		const informacoesAtendimento = this.atendimentoService.getInfosAtendimento(cnpj);
		const numeroProtocolo = informacoesAtendimento.protocoloSitFis.numeroProtocolo;
		this.cnpj = informacoesAtendimento.dadosContribuinteCnpj.cnpj;
		this.razaoSocial = informacoesAtendimento.dadosContribuinteCnpj.razaoSocial;
		this.titulo = 'Quadro de Sócios e Administradores - CNPJ: ' + FormatCpfCnpjPipe.formatCNPJ(this.cnpj);

		try {
			this.service.obterSocioAdminQSA(cnpj, numeroProtocolo)
				.subscribe(
					res => this.socios = res,
					err => {
						this.initError = err;
						this.logService.logAjaxError('SitFisPjSocioAdminQSAComponent.obterSocioAdminQSA', 'erro recuperando dados do sócio/administrador do QSA', err);
					}
				);
		} catch (e) {
			this.initError = e;
			this.logService.logError('SitFisPjSocioAdminQSAComponent.obterSocioAdminQSA', 'ocorreu um erro ao obter dados do sócio/administrador do QSA', e);
			this.messageService.showErrorException('Ocorreu um erro ao obter dados de Sócios', e);
		}
	}


}
