import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParcelamentoPgfn } from './model/parcelamento-pgfn';

@Injectable()
export class ParcelamentoPgfnService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosPgfnAtivos(ni: string, numeroProtocolo: string): Observable<ParcelamentoPgfn[]> {
    const url = `${this.baseUrl}/situacoesfiscais/pgfn/parcelamento/Cobranca/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoPgfn[]>(url, options);
  }

  public obterParcelamentosPgfnSuspensos(ni: string, numeroProtocolo: string): Observable<ParcelamentoPgfn[]> {
    const url = `${this.baseUrl}/situacoesfiscais/pgfn/parcelamento/Exigibilidade/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoPgfn[]>(url, options);
  }

  public getUrlExtratoPgfn(nomeNi: string, parcelamento: ParcelamentoPgfn): string {
    return `${environment.urlExtratoPgfn}?strCNPJ=${parcelamento.ni}&strNomeCNPJ=${encodeURIComponent(nomeNi)}&strTributo=${parcelamento.codigoParcelamento}&strNomeTaxaJuros=${parcelamento.nomeTaxaJuros}&strOrigemParcela=SITFISWEB`;
  }
}
