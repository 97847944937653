import { Component } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ParcelamentoDivida, ParcelamentosDividaGroupedByNi } from './model/parcelamento-divida';
import { ParcelamentoDividaService } from './parcelamento-divida.service';

@Component({
  selector: 'app-parcelamento-divida',
  templateUrl: './parcelamento-divida.component.html',
  styleUrls: ['./parcelamento-divida.component.css']
})
export class ParcelamentoDividaComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  parcelamentosAgrupados: ParcelamentosDividaGroupedByNi;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private parcelamentoDividaService: ParcelamentoDividaService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('ParcelamentoDividaComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let parcelamentosAgrupadosObservable: Observable<ParcelamentosDividaGroupedByNi>;
      if (this.isAtivos) {
        parcelamentosAgrupadosObservable = this.agrupaPorNi(
          this.parcelamentoDividaService.obterParcelamentosDividaAtivos(ni, numeroProtocolo));
      }
      if (this.isSuspensos) {
        parcelamentosAgrupadosObservable = this.agrupaPorNi(
          this.parcelamentoDividaService.obterParcelamentosDividaSuspensos(ni, numeroProtocolo));
      }

      parcelamentosAgrupadosObservable.subscribe(
        (resp) => {
          this.parcelamentosAgrupados = resp;
          this.logService.logAjaxReturn('ParcelamentoDividaComponent', 'obterParcelamentos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ParcelamentoDividaComponent', 'obterParcelamentos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos Divida', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ParcelamentoDividaComponent', 'obterParcelamentos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos Divida', e);
    }
  }

  private agrupaPorNi(parcelamentos: Observable<ParcelamentoDivida[]>): Observable<ParcelamentosDividaGroupedByNi> {
    return parcelamentos.pipe(
      //tap(val => console.log(`parcelamentos Divida 1: ${JSON.stringify(val, undefined, 4)}`)),
      map(parcelamentos => _.orderBy(parcelamentos, ['tipoNi', 'ni', 'numeroDebito'], ['asc', 'asc', 'asc'])),
      //tap(val => console.log(`parcelamentos Divida 2: ${JSON.stringify(val, undefined, 4)}`)),
      map(parcelamentos => _.groupBy(parcelamentos, (parcelamento) => parcelamento.tipoNi + '|' + parcelamento.ni)),
      //tap(val => console.log(`parcelamentos Divida 3: ${JSON.stringify(val, undefined, 4)}`)),
    );
  }
}

@Component({
  selector: 'app-parcelamento-divida',
  templateUrl: './parcelamento-divida.component.html',
  styleUrls: ['./parcelamento-divida.component.css']
})
export class ParcelamentoDividaPJAtivoComponent extends ParcelamentoDividaComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Parcelamentos Ativos PGFN Dívidas - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-divida',
  templateUrl: './parcelamento-divida.component.html',
  styleUrls: ['./parcelamento-divida.component.css']
})
export class ParcelamentoDividaPJSuspensoComponent extends ParcelamentoDividaComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Parcelamentos Suspensos PGFN Dívidas - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-divida',
  templateUrl: './parcelamento-divida.component.html',
  styleUrls: ['./parcelamento-divida.component.css']
})
export class ParcelamentoDividaPFAtivoComponent extends ParcelamentoDividaComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Parcelamentos Ativos PGFN Dívidas - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-divida',
  templateUrl: './parcelamento-divida.component.html',
  styleUrls: ['./parcelamento-divida.component.css']
})
export class ParcelamentoDividaPFSuspensoComponent extends ParcelamentoDividaComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Parcelamentos Suspensos PGFN Dívidas - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}
