import { Pipe, PipeTransform } from '@angular/core';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { FormatCnpjPipe } from './formata-cnpj.pipe';
import { FormatCpfPipe } from './formata-cpf.pipe';

@Pipe({
  name: 'formatCpfCnpjCeiPipe'
})
export class FormatCpfCnpjCeiPipe implements PipeTransform {
  transform(ni: string, tipoNi: TipoNI) {
    return FormatCpfCnpjCeiPipe.format(ni, tipoNi);
  }

  static format: Function = (ni: string, tipoNi: TipoNI) => {
    if(!ni || ni.length == 0){
      return ni;
    }
    return (tipoNi == TipoNI.CNPJ ? FormatCnpjPipe.formatCNPJ(ni) : 
            tipoNi == TipoNI.CPF ? FormatCpfPipe.formatCPF(ni) : 
            FormatCpfCnpjCeiPipe.formatCEI(ni));

  }

  static formatCEI: Function = (nroCei: any) => {
    let pad = "000000000000";
    let cei = (pad+nroCei).slice(-pad.length);
    return cei.substring(0,2).concat(".")
                                .concat(cei.substring(2,5))
                                .concat(".")
                                .concat(cei.substring(5,10))
                                .concat("/")
                                .concat(cei.substring(10))
  }
}



