<div [ngClass]="{ 'modal-container': isModal }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <ng-container *ngIf="(creditos$ | async) as creditos; else loadingOrError;">
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center min-width">
                <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
              </th>
              <th class="text-center min-width">CT</th>
              <th class="text-center min-width">Data de Venc. Notificação</th>
              <th class="text-center">Valor Original</th>
              <th class="text-center">Saldo Devedor</th>
              <th class="text-center">Situação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let credito of creditos | currentPage: currentPage;">
              <tr class="vertical-align-middle">
                <td>
                  <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>
                <td class="text-center">{{ credito.ct | vazio }}</td>
                <td class="text-center">{{ credito.dataVencimentoNotificacao | formatDataPipe | vazio }}</td>
                <td class="text-right">{{ credito.valorOriginal | formatCurrency }}</td>
                <td class="text-right">{{ credito.saldoDevedor | formatCurrency }}</td>
                <td class="text-left">{{ credito.situacao | vazio }}</td>
              </tr>
              <ng-container *ngIf="rowToggle.show">
                <tr></tr>
                <tr>
                  <td colspan="7">
                    <div class="contorno">
                      <div class="row">
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Tipo Notificação
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.tipoNotificacao | codigoDescricao | vazio }}
                          </p>
                        </div>
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Número da Declaração
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.numeroDeclaracao | vazio }}
                          </p>
                        </div>
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Unidade Monetária
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.unidadeMonetaria | vazio }}
                          </p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <label class="label-formulario control-label">
                            Número de Lançamento
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.numeroLancamento | vazio }}
                          </p>
                        </div>
                        <div class="col-md-8">
                          <label class="label-formulario control-label">
                            N° do Processo
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.numeroProcesso | vazio }}
                          </p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <via-ccpf-alocacao-debito-componentes
                            [credito]="credito"
                            (alocarDebito)="alocarDebito($event, credito)">
                          </via-ccpf-alocacao-debito-componentes>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <via-ccpf-table-pagamentos
                            [credito]="credito"
                            [pagamentos]="credito.pagamentos"
                            [exibirAcoes]="false"
                            [atendimento]="!isModal">
                          </via-ccpf-table-pagamentos>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="6">
                <via-pagination [itemAmount]="creditos.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
