import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "@serpro/ngx-suite-rfb";
import _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FEATURES_MENU_PRINCIPAL } from "src/app/workplace/components/atendimento/atendimento.features";
import { DynamicModalRequestService } from "../../../dynamic-modal/dynamic-modal-request.service";
import { FeatureAction } from "../../../model/feature";
import { ViaMenuConfigService } from "../../services/menu-config.service";
import { ViaMenuService } from "../../services/menu.service";

/**
 * @deprecated Este componente não será mais utilizado
 */
@Component({
  selector: 'via-expanded-menu',
  templateUrl: './expanded-menu.component.html',
  styleUrls: ['./expanded-menu.component.css']
})
export class ViaExpandedMenuComponent implements OnInit {

  @Input() panelTitle: string = 'Menu expandido';

  features: FeatureAction[] = [];

  expandedMenuHierarchical: boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private menuService: ViaMenuService,
    private configService: ViaMenuConfigService,
    private dynamicModalRequest: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.expandedMenuHierarchical = this.configService.isExpandedMenuHierarchical();

    this.userService.getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(user => {
        if (user) {
          this.features = _.cloneDeep(FEATURES_MENU_PRINCIPAL);
          this.features.forEach(feature => this.menuService.ajustarVisibilidade(feature, user));
        }
      });
  }

  hasFeatures(feature: FeatureAction): boolean {
    return feature && feature.visible && feature.children?.filter(f => f.visible === true).length > 0;
  }

  onClickFeature(feature: FeatureAction): void {
    if (feature.url) {
      window.open(feature.url, '_blank');
    } else {
      this.dynamicModalRequest.abrirModal(feature);
    }
  }
}
