import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'extraiDescricao'
})
export class ExtraiDescricaoPipe implements PipeTransform {
  transform(items: any): string[] {
    return items.map(item => item.descricao ? item.descricao : item.nome);
  }
}
