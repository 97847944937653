import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'app-darf-mensagens',
	templateUrl: './darf-mensagens.component.html',
	styleUrls: ['./darf-mensagens.component.css'],	
})
export class DarfMensagensComponent {

	@Output() resultado = new EventEmitter<string>();

	mensagem: number;

	responder(resposta:string) {
		this.resultado.next(resposta);
	}	



}