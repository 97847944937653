import { CONSTANTS } from "../components/ccitr/constants";

export interface ErroPadrao {
  timestamp: number;
  status: number;
  message: string;
}

export interface Warning extends ErroPadrao {}

export const isErroPadrao = (e: any): e is ErroPadrao => {
  return (Object.keys(e).length == 3)
    && ('timestamp' in e)
    && ('status' in e)
    && ('message' in e);
}

export const isWarning = (e: any): e is Warning => {
  if(isErroPadrao(e)) {
    return (e.status == CONSTANTS.HTTP_STATUS_WARNING);
  }
  return false;
}
