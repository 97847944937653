import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { DADOS_BASICOS_PF, TITULO_ELEITOR } from "src/app/sitfis/components/situacao-fiscal-pf.features";
import { DADOS_BASICOS_PJ } from "src/app/sitfis/components/situacao-fiscal-pj.features";
import { FeatureAction } from "../model/feature";
import { DynamicModalRequestService } from "./dynamic-modal-request.service";
import { DynamicModalComponent } from "./dynamic-modal.component";

@Injectable({ providedIn: 'root' })
export class DynamicModalService implements OnInit, OnDestroy {

  constructor(
    private modalService: BsModalService,
    private dynamicModalRequestService: DynamicModalRequestService) { }

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private modais: BsModalRef[] = [];

  private abrirPopup(ni: string, featureAction: FeatureAction) {
    DynamicModalComponent.prototype.featureAction = featureAction;
    DynamicModalComponent.prototype.ni = ni;
    const bsModalRef = this.modalService.show(DynamicModalComponent);
    bsModalRef.onHidden.subscribe(
      (val) => {
        if(val === "backdrop-click") {
          this.modais.pop();
        }
      }
    );
    this.modais.push(bsModalRef);
  }

  ngOnInit(): void {
    this.dynamicModalRequestService.cnpj$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(cnpj => {
        console.log('abrir novo cnpj');
        this.abrirPopup(cnpj, DADOS_BASICOS_PJ);
      });

    this.dynamicModalRequestService.cpf$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(cpf => {
        console.log('abrir novo cpf');
        this.abrirPopup(cpf, DADOS_BASICOS_PF);
      });

    this.dynamicModalRequestService.tituloEleitor$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(cpf => {
        console.log('abrir novo titulo');
        this.abrirPopup(cpf, TITULO_ELEITOR);
      });

    this.dynamicModalRequestService.modalFeature$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(featureAction => {
        this.abrirPopup('', featureAction);
      });

    this.dynamicModalRequestService.fechar$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(n => {
        console.log('fecharModal');
        this.modais.pop().hide();
      });
  }

  public ngOnDestroy(): void {
    // notifica fechamento do quadro
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
