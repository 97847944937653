import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { DebitoDivida } from '../model/debito-divida';

@Component({
    selector: 'via-debito-divida-table',
    templateUrl: 'debito-divida-table.component.html',
    styleUrls: ['debito-divida-table.component.css']
})
export class DebitoDividaTableComponent {

    @Input() debitos: DebitoDivida[];

    @Input() isPessoaFisica: boolean;
    @Input() isPessoaJuridica: boolean;

    @Input() isAtivos: boolean;
    @Input() isSuspensos: boolean;

    @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;    

    pageSize: number = 10;
    currentPage: Page = {
        number: 1,
        first: 0,
        last: this.pageSize
    };

    expandedAll(show: boolean) {
        this.rowToggleQuery.forEach(toggle => toggle.show = show);
    }    
}
