import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DeclaracaoDcftDirf } from '../../models/declaracao-dctf-dirf';
import { DocumentoArrecadacaoDirf } from '../../models/documento-arrecadacao-dirf';
import { ObrigatoriedadeDirfService } from '../../services/obrigatoriedade-dirf.service';

@Component({
  selector: 'via-consulta-obrigatoriedade-dirf',
  templateUrl: './consulta-obrigatoriedade-dirf.component.html',
  styleUrls: ['./consulta-obrigatoriedade-dirf.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaObrigatoriedadeDirfComponent implements IDynamicPanel, OnInit, OnChanges {

  @Input() cnpj: string;
  @Input() anoCalendario: string;

  @Input() atendimento: boolean = false;
  @Input() parentScopeId: string;

  @Output() onError = new EventEmitter<void>();

  titulo: string;

  pagamentos: DocumentoArrecadacaoDirf[];
  dctfs: DeclaracaoDcftDirf[];

  scopeId: string;
  initError: Error;

  constructor(
    private bsModalRef: BsModalRef,
    private obrigatoriedadeDIRFservice: ObrigatoriedadeDirfService,
    private messageService: ScopedMessageService) { }

  init(cnpj: string, params?: OptionalParams): void {
    this.cnpj = cnpj;
    this.anoCalendario = params.anoCalendario;
  }

  ngOnInit(): void {
    this.titulo = `Pessoa Jurídica - Obrigatoriedade DIRF - CNPJ: ${FormatCnpjPipe.formatCNPJ(this.cnpj)}`;

    this.scopeId = this.parentScopeId ? this.parentScopeId : this.messageService.scopeId;

    this.recarregar();
  }

  ngOnChanges(): void {
    if (this.scopeId) {
      this.recarregar();
    }
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  private recarregar(): void {
    this.initError = null;
    this.pesquisarObrigatoriedadesDIRF();
  }

  private pesquisarObrigatoriedadesDIRF(): void {
    this.obrigatoriedadeDIRFservice.obterObrigatoriedadesDIRF(this.cnpj, this.anoCalendario)
      .subscribe(
        response => {
          this.pagamentos = response.pagamentos;
          this.dctfs = response.dctfs;
        },
        error => {
          this.initError = error;
          this.onError.emit();
          this.messageService.showErrorException('Ocorreu um erro ao consultar Obrigatoriedade DIRF', error);
        }
      );
  }
}
