import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCpfCnpjPipe'
})
export class FormatCpfCnpjPipe implements PipeTransform {
  transform(ni: string, tipoNi?: TipoNI) {
   if (!tipoNi) {
    return FormatCpfCnpjPipe.format(ni);
   }
   else {
    return FormatCpfCnpjPipe.formatComTipo(ni, tipoNi)
   }
  }

  static format: Function = (ni: any) => {
    if(!ni || ni.length == 0){
      return ni;
    }
    return (ni.length == 11 ? FormatCpfCnpjPipe.formatCPF(ni) : FormatCpfCnpjPipe.formatCNPJ(ni));

  }

  static formatComTipo: Function = (ni: any, tipoNi: TipoNI) => {
    if(!ni || ni.length == 0){
      return ni;
    }
    return (tipoNi == TipoNI.CPF ? FormatCpfCnpjPipe.formatCPF(ni) : FormatCpfCnpjPipe.formatCNPJ(ni));

  }

  static formatCPF: Function = (cpf: any) => {
    if(cpf){
      let pad = "00000000000";
      let cpf11pos = (pad+cpf).slice(-pad.length);
      return cpf11pos.substring(0,3).concat(".")
                                  .concat(cpf11pos.substring(3,6))
                                  .concat(".")
                                  .concat(cpf11pos.substring(6,9))
                                  .concat("-")
                                  .concat(cpf11pos.substring(9,11));
    }
  }


  static formatCNPJ: Function = (nroCnpj: any) => {
    if(nroCnpj){
      let pad = "00000000000000";
      let cnpj14pos = (pad+nroCnpj).slice(-pad.length);
      return cnpj14pos.substring(0,2).concat(".")
                                  .concat(cnpj14pos.substring(2,5))
                                  .concat(".")
                                  .concat(cnpj14pos.substring(5,8))
                                  .concat("/")
                                  .concat(cnpj14pos.substring(8,12))
                                  .concat("-")
                                  .concat(cnpj14pos.substring(12,14));
    }
  }
}



