import { Injectable } from '@angular/core';
import { InformacoesCompletasAtendimento } from 'src/app/sitfis/model/protocolo';
import { PainelAtendimentosService } from '../painel-atendimentos/painel-atendimentos.service';

@Injectable()
export class AtendimentoService {

  private readonly infosCompletas: Map<string, InformacoesCompletasAtendimento>;

  constructor(private painelAtendimentos: PainelAtendimentosService) {
    this.infosCompletas = new Map<string, InformacoesCompletasAtendimento>();
  }

  public getInfosAtendimento(ni: string): InformacoesCompletasAtendimento {
    return this.infosCompletas.get(ni);
  }

  public updateInfosAtendimento(ni: string, infos: InformacoesCompletasAtendimento) {
    this.infosCompletas.set(ni, infos);
  }

  public activateTab(tabName: string) {
    this.painelAtendimentos.activateTab(tabName);
  }

  public cancelaProcessamentoAtendimento(ni: string) {
    this.painelAtendimentos.cancelaProcessamentoAtendimento(ni);
  }

}
