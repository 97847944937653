import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AusenciaDeclaracao } from './model/declaracao-ausente';

@Injectable()
export class DeclaracaoAusenteService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterDeclaracoesAusentes(ni: string, numeroProtocolo: string, tipoDeclaracao: string): Observable<AusenciaDeclaracao[]> {
		const url = `${this.baseUrl}/situacoesfiscais/ausenciasdeclaracao/${ni}/${tipoDeclaracao}`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		};
		return this.http.get<AusenciaDeclaracao[]>(url, options);
	}
}
