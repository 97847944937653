import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CnpjsVinculadosService } from 'src/app/sitfis/components/cnpjs-vinculados/cnpjs-vinculados.service';
import { CnpjsVinculadosDTO } from 'src/app/sitfis/components/cnpjs-vinculados/model/cnpjs-vinculados';

@Component({
  selector: 'via-cnpjs-vinculados-pf',
  templateUrl: './cnpjs-vinculados-pf.component.html',
  styleUrls: ['./cnpjs-vinculados-pf.component.css'],
  providers: [ScopedMessageService]
})
export class CnpjsVinculadosPfComponent implements OnInit {

  @Input() cpf: string;

  titulo = 'CNPJs Vinculados';

  cnpjsVinculados: CnpjsVinculadosDTO[];

  scopeId: string;
  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private messageService: ScopedMessageService,
    private cnpjsVinculadosService: CnpjsVinculadosService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean) {
    if (!collapsed && !this.executouObterDados) {
      this.obterCnpjsVinculados();
    }
  }

  private obterCnpjsVinculados() {
    this.executouObterDados = true;
    this.cnpjsVinculadosService.obterCnpjsVinculados(this.cpf)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }

          this.cnpjsVinculados = response;
        },
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter os CNPJs vinculados', error);
          this.initError = error;
        });
  }
}
