import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { OperacaoSucessaoComponent } from './components/operacao-sucessao.component';
import { OperacaoSucessaoTableComponent } from './components/table/operacao-sucessao-table.component';
import { OperacoesSucessaoSuiteRfbModule } from './operacao-sucessao-suite-rfb.module';
import { OperacaoSucessaoService } from './services/operacao-sucessao.service';

@NgModule({
  declarations: [
    OperacaoSucessaoComponent,
    OperacaoSucessaoTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    OperacoesSucessaoSuiteRfbModule
  ],
  exports: [
    OperacaoSucessaoComponent
  ],
  providers: [
    OperacaoSucessaoService
  ]
})
export class OperacaoSucessaoModule { }
