import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedSuiteRfbModule } from 'src/app/shared/shared-suite-rfb.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { DadosBasicosPJComponent } from './dados-basicos-pj.component';
import { CnpjService } from '../../services/cnpj.service';
import { CnpjSuiteRfbModule } from '../../cnpj-suite-rfb.module';

@NgModule({
  declarations: [
    DadosBasicosPJComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedSuiteRfbModule,
    CnpjSuiteRfbModule,
    PipesModule,
  ],
  providers: [
    CnpjService
  ],  
  exports: [
    DadosBasicosPJComponent,
  ]
})
export class DadosBasicosPJModule { }
