import { Pipe, PipeTransform } from '@angular/core';
import currency from 'currency.js';
import { OcorrenciaMensal } from './model/ocorrencia-mensal';
import { SumarioAnual } from './model/sumario-anual';

@Pipe({
  name: 'totalMensalDeducoes'
})
export class CalculaTotalMensalDeducoesPipe implements PipeTransform {

  public transform(ocorrencia: OcorrenciaMensal): string {
    return currency(ocorrencia.deducaoTributavelPrevidenciaOficial)
      .add(ocorrencia.deducaoTributavelDependente)
      .add(ocorrencia.deducaoTributavelPensaoAlimenticia)
      .add(ocorrencia.deducaoTributavelPrevidenciaPrivada)
      .toString();
  }
}

@Pipe({
  name: 'nomeMes'
})
export class ConverteNumeroParaNomeDoMesPipe implements PipeTransform {
  public transform(mes: number): string {
    switch (mes) {
      case 1: return 'Janeiro';
      case 2: return 'Fevereiro';
      case 3: return 'Março';
      case 4: return 'Abril';
      case 5: return 'Maio';
      case 6: return 'Junho';
      case 7: return 'Julho';
      case 8: return 'Agosto';
      case 9: return 'Setembro';
      case 10: return 'Outubro';
      case 11: return 'Novembro';
      case 12: return 'Dezembro';
      case 13: return '13o. Salário';
    }
  }
}

@Pipe({
  name: 'totalAnualRendimentosTributaveis'
})
export class CalculaTotalAnualRendimentosTributaveisPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      if (ocorrencia.numeroOcorrencia != 13) {
        total = total.add(ocorrencia.rendimentoTributavel);
      }
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualImpostoRetido'
})
export class CalculaTotalAnualImpostoRetidoPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      if (ocorrencia.numeroOcorrencia != 13) {
        total = total.add(ocorrencia.impostoRetido);
      }
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualRendimentoIsento'
})
export class CalculaTotalAnualRendimentoIsentoPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      if (ocorrencia.numeroOcorrencia != 13) {
        total = total.add(ocorrencia.rendimentoIsento);
      }
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualDeducoes'
})
export class CalculaTotalAnualDeducoesPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      if (ocorrencia.numeroOcorrencia != 13) {
        total = total.add(ocorrencia.deducaoTributavelPrevidenciaOficial)
          .add(ocorrencia.deducaoTributavelDependente)
          .add(ocorrencia.deducaoTributavelPensaoAlimenticia)
          .add(ocorrencia.deducaoTributavelPrevidenciaPrivada);
      }
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualDeducaoTributavelPrevidenciaOficial'
})
export class CalculaTotalAnualDeducaoTributavelPrevidenciaOficialPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      total = total.add(ocorrencia.deducaoTributavelPrevidenciaOficial);
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualDeducaoTributavelDependente'
})
export class CalculaTotalAnualDeducaoTributavelDependentePipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      total = total.add(ocorrencia.deducaoTributavelDependente);
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualDeducaoTributavelPensaoAlimenticia'
})
export class CalculaTotalAnualDeducaoTributavelPensaoAlimenticiaPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      total = total.add(ocorrencia.deducaoTributavelPensaoAlimenticia);
    });
    return total.toString();
  }
}

@Pipe({
  name: 'totalAnualDeducaoTributavelPrevidenciaPrivada'
})
export class CalculaTotalAnualDeducaoTributavelPrevidenciaPrivadaPipe implements PipeTransform {
  public transform(retencao: SumarioAnual): string {
    let total = currency(0);
    retencao.ocorrencias.forEach(ocorrencia => {
      total = total.add(ocorrencia.deducaoTributavelPrevidenciaPrivada);
    });
    return total.toString();
  }
}
