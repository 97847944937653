<div class="contorno">
  <div class="row">
    <div class="col-md-7">
       Total de Crédito Tributário em Aberto
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Período de Apuração
      </label>
      <p class="form-control-static dado-formulario">
        {{ debito.periodoApuracao | formatDataPipe }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Receita
      </label>
      <p class="form-control-static dado-formulario">
        <via-codigo-receita [codigo]="debito.receita"></via-codigo-receita>
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Data de Vencimento
      </label>
      <p class="form-control-static dado-formulario">
        {{ debito.dataVencimento | formatDataPipe }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Unidade Monetária
      </label>
      <p class="form-control-static dado-formulario">
        {{ debito.unidadeMonetaria | vazio }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-offset-3 col-md-6">
      <div class="table-responsive" style="width: 460">
        <table suiteRfbTable>
          <caption>Total de Valores Consolidados</caption>
          <thead>
            <tr>
              <th class="text-center min-width">Receita</th>
              <th class="text-center min-width">Multa</th>
              <th class="text-center min-width">Juros</th>
              <th class="text-center min-width">Total</th>
            </tr>
          </thead>
          <tbody>
              <tr class="vertical-align-middle">
                <td class="text-center">
                  {{ debito.saldoConsolidadoReceita | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ debito.saldoConsolidadoMulta | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ debito.saldoConsolidadoJuros | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ debito.saldoConsolidadoTotal | formatCurrency }}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
