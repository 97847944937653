import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { DebitoProcessoSiefSuiteRfbModule } from './debito-processo-sief-suite-rfb.module';
import {
  DebitoProcessoSiefComponent,
  DebitoProcessoSiefPFAtivoComponent,
  DebitoProcessoSiefPFSuspensoComponent,
  DebitoProcessoSiefPJAtivoComponent,
  DebitoProcessoSiefPJSuspensoComponent
} from './debito-processo-sief.component';
import { DebitoProcessoSiefService } from './debito-processo-sief.service';
import { DebitoProcessoSiefTableComponent } from './table/debito-processo-sief-table.component';

@NgModule({
  declarations: [
    DebitoProcessoSiefComponent,
    DebitoProcessoSiefPFAtivoComponent,
    DebitoProcessoSiefPFSuspensoComponent,
    DebitoProcessoSiefPJAtivoComponent,
    DebitoProcessoSiefPJSuspensoComponent,
    DebitoProcessoSiefTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    SitfisPipesModule,
    DebitoProcessoSiefSuiteRfbModule
  ],
  providers: [
    DebitoProcessoSiefService
  ]
})
export class DebitoProcessoSiefModule { }
