import { DadosConsolidadoDebitoCCITRDTO, ComponenteDadosConsolidadoDebitoCCITRDTO } from './../../model/dados-consolidado-debito-ccitr';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'via-detalhe-dados-consolidar-ct',
  templateUrl: './detalhe-dados-consolidar-ct.component.html',
  styleUrls: ['../dados-consolidar-ct.component.css']
})
export class DetalheCCITRDadosConsolidarCTComponent {

  @Input() debito: DadosConsolidadoDebitoCCITRDTO;



}
