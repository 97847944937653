<div class="contorno">
  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Tipo Correção
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.tipoCorrecao | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Tipo Alteração
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.tipoAlteracao | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Tipo Armotização
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.tipoAmortizacao | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Tipo Alocação
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.tipoAlocacao | vazio }}
      </p>
    </div>    
  </div>

  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Situação Anterior
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.situacaoAnterior | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Tipo Lançamento
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.tipoLancamento | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Origem
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.sistemaOrigem | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Data Vencimento Atual
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.dataVencimentoAtual | formatDataPipe | vazio }}
      </p>
    </div>    
  </div>

  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Processo
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroProcesso | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Data Compensação
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.dataCompensacao | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        No. Componente
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroComponente | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        No. CRED/RESS
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroCredRess | vazio }}
      </p>
    </div>    
  </div>  

  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Valor DIR CRED
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.valorDireitoCreditorio | formatCurrency | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Valor Alocado Receita
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.valorAlocadoReceita | formatCurrency | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        No. Pagamento DARF
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroPagamentoDarf | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        No. Pagamento TDA
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroPagamentoTDA | vazio }}
      </p>
    </div>    
  </div>

  <div class="row">
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Sistema
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.sistemaTDA | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        Motivo Transferencia
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.motivoTransferencia | vazio }}
      </p>
    </div>
    <div class="col-md-3">
      <label class="label-formulario control-label">
        No. PERDCOMP
      </label>
      <p class="form-control-static dado-formulario">
        {{ item.numeroPerdcomp | vazio }}
      </p>
    </div>   
  </div>   

</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
