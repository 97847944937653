import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuFeatureActionsService } from '../menu-feature-actions/menu-feature-actions.service';
import { FeatureAction } from '../model/feature';
import { LogService } from '../services/log.service';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.css']
})
export class ActionPanelComponent {

  @Input()
  featureAction: FeatureAction;
  active: boolean;
}
