<suite-rfb-collapse title="Dados Básicos" [collapsed]="true">
  <div class="container-fluid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row row-align-items-center">
        <div class="col-md-6">
          <div class="form-group">
            <label [for]="nomeFormGroupName" class="control-label">Nome</label>
            <input class="form-control" type="text" [formControlName]="nomeFormGroupName" autocomplete="off">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <div class="text-center">
              <label class="control-label checkbox-inline">
                <input type="checkbox" class="checkbox" [formControlName]="foneticaFormGroupName" autocomplete="off">
                <span>Fonética</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label [for]="nomeMaeFormGroupName" class="control-label">Nome da Mãe</label>
            <input class="form-control" type="text" [formControlName]="nomeMaeFormGroupName" autocomplete="off">
          </div>

          <div class="form-group">
            <label [for]="tituloEleitorFormGroupName" class="control-label">Número do Título de Eleitor</label>
            <input class="form-control" type="text" [formControlName]="tituloEleitorFormGroupName" autocomplete="off" suiteRfbNumbersOnly>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <suite-rfb-date-picker
              label="Data de Nascimento"
              direction="up"
              adaptativePosition="true"
              outsideClick="true"
              [suiteRfbMaxDate]="maxDate"
              [formControlName]="dataNascimentoFormGroupName">
            </suite-rfb-date-picker>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label [for]="municipioFormGroupName" class="control-label">Município</label>
            <input class="form-control" type="text" [formControlName]="municipioFormGroupName" autocomplete="off">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group">
            <label [for]="ufFormGroupName" class="control-label">UF</label>
            <input class="form-control" type="text" [formControlName]="ufFormGroupName" autocomplete="off" maxlength="2">
          </div>
        </div>
      </div>

      <div class="row row-align-items-center">
        <div class="col-md-6">
          <div class="form-group">
            <label [for]="cepFormGroupName" class="control-label">CEP</label>
            <input class="form-control" type="text" [formControlName]="cepFormGroupName" autocomplete="off" >
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <div class="text-center botoes-acoes-formulario">
              <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
              <button type="submit" class="btn btn-primary" [disabled]="!isValidForm()">Buscar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>
