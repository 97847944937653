import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReceitaDTO } from './model/receita';

@Injectable()
export class ConsultarReceitaService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }
    
    public buscarReceitaSIEF(codigo: string): Observable<ReceitaDTO> {
		const url = `${this.baseUrl}/sief-receitas/${codigo}`;
		return this.http.get<ReceitaDTO>(url);
	}
}