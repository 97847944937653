import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DebitoDivida } from './model/debito-divida';

@Injectable()
export class DebitoDividaService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterDebitosDividaAtivos(ni: string, numeroProtocolo: string): Observable<DebitoDivida[]> {
        const url = `${this.baseUrl}/situacoesfiscais/divida/debito/Cobranca/${ni}`;
        console.log('url= ' + url);
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<DebitoDivida[]>(url, options);
    }

    public obterDebitosDividaSuspensos(ni: string, numeroProtocolo: string): Observable<DebitoDivida[]> {
        const url = `${this.baseUrl}/situacoesfiscais/divida/debito/Exigibilidade/${ni}`;
        console.log('url= ' + url);
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<DebitoDivida[]>(url, options);
    }
}
