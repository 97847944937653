import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, OnInit, OnChanges, OnDestroy, SimpleChanges, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { Subscription, Observable, throwError, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import _, { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AlertMessageService, LoadingService } from "@serpro/ngx-suite-rfb";
import { BsModalRef } from "ngx-bootstrap/modal";
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { CCITRService } from '../../services/ccitr.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { CompensacaoCcitrDTO, CompensacoesCcitrGroupedByExercicio } from '../../../../models/compensacao-ccitr-dto';
import { ParametrosPesquisaCcitr } from '../../../../models/parametros-pesquisa-ccitr';
import { ParametrosConsultaCompensacoesCcitr } from '../../../../models/parametros-consulta-compensacoes-ccitr';
import { CcitrBase } from '../../base/CcitrBase.component';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { LogService } from 'src/app/shared/services/log.service';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { CONSTANTS } from '../../constants';

@Component({
  selector: 'via-ccitr-consulta-compensacoes',
  templateUrl: './ccitr-consulta-compensacoes.component.html',
  styleUrls: ['./ccitr-consulta-compensacoes.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class CcitrConsultaCompensacoesComponent extends CcitrBase implements IDynamicPanel, OnInit, OnDestroy, OnChanges {
  @Input() parametros: ParametrosPesquisaCcitr;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  updateSubscription: Subscription;

  compensacoes: CompensacoesCcitrGroupedByExercicio;

  initError: Error;
  scopeId: string;
  titulo: string;
  stopLoading = false;
  filtroColapsado = false;
  showCloseModal = true;
  resultadoVazio: boolean = true;
  exercicios: string[];
  totalExercicios = 0;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalRequest: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private cCITRService: CCITRService ) {
    super();
  }

  public init(ni: string, params?: OptionalParams): void {
    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosPesquisaCcitr;

    this.recarregar();
  }

  ngOnInit(): void {
    let ehModal: boolean;

    this.initError = null;
    this.compensacoes = null;
    this.scopeId = this.messageService.scopeId;

    this.formataTitulo(this.parametros, 'CCITR - Consultar Compensações');

  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    if(this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  public recarregar(): void {
    this.initError = null;
    this.compensacoes = null;
    this.recuperarCompensacoes();
  }

  public esconderResultado(): void {
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  private recuperarCompensacoes(): void {

    const parametros: ParametrosConsultaCompensacoesCcitr = {
      nirf: this.parametros.nirf,
      cpfContribuinte: this.parametros.cpfContribuinte,
      cnpjContribuinte: this.parametros.cnpjContribuinte,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      tipoLancamento: this.parametros.tipoLancamento,
      dataInicial: '',
      dataFinal: '',
      numeroProcessoRestRess: '',
      nueroCreditoRess: '',
      numeroCt: '',
      numeroComponente: '',
      atendimento: this.atendimento
    };

    let compensacoesObservable: Observable<CompensacaoCcitrDTO[]>;

    compensacoesObservable = this.cCITRService.recuperarCompensacoes(parametros);
    this.agrupaPorExercicio(compensacoesObservable)
    .subscribe(
      response => {
        try {
            this.compensacoes = response;
            if (this.compensacoes && (isEmpty(this.compensacoes) ||
              (Object.keys(this.compensacoes).length == 1 && Object.values(this.compensacoes)[0][0].numeroComponenteCt == null))) {
              this.stopLoading = true;
              this.messageService.showInfo('Nenhum resultado encontrado');
            }
            this.totalExercicios = Object.keys(this.compensacoes).length;
            this.exercicios = Object.keys(this.compensacoes).map(x => x);
            this.exercicios.sort().reverse();
          } catch (e) {
            this.initError = e;
            this.logService.logError('CcitrConsultarCompensacoesComponent', 'recuperarCompensacoes', e);
            this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', e);
          }},
          (err: HttpErrorResponse) => {
            if (isWarning(err.error)) {
              this.messageService.showWarning(err.error.message);
              this.stopLoading = true;
              return null;
            } else {
                this.initError = err;
                this.onError.emit();
                this.messageService.showErrorException("Ocorreu um erro ao consultar CCITR Consulta Compensacoes ", err);
                return throwError(err);
              }
          }
      );

  }

  private agrupaPorExercicio(compensacoesObservable: Observable<CompensacaoCcitrDTO[]>): Observable<CompensacoesCcitrGroupedByExercicio> {
    return compensacoesObservable.pipe(
      map(compensacoes => _.orderBy(compensacoes, ['exercicio', 'dataVencimentoNotificacao'], ['desc', 'desc'])),
      map(compensacoes => _.groupBy(compensacoes, (compensacao) => compensacao.exercicioCt)),
    );
  }

}
