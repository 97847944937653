<suite-rfb-collapse [title]="titulo">
    <div *ngIf="(infoObservable$ | async) as info; else loadingOrError">
        <!-- pre>info: {{info | json }}</pre -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-5">
                    <label class="control-label label-formulario">
                        Nome
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.razaoSocial | vazio }}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Unidade da Receita do Domicílio Fiscal
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.unidade | uaPipe | vazio }}
                    </p>
                </div>
                <div class="col-md-3">
                    <label class="control-label label-formulario">
                        Abertura Empresa/Entidade
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.dataAbertura | date: 'shortDate' | vazio }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="control-label label-formulario">
                        Endereço
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.endereco | enderecoInfoCadPipe | vazio }}
                    </p>
                </div>
                <div class="col-md-4">
                    <div *ngIf="residenteNoBrasil">
                        <label class="control-label label-formulario">
                            Bairro
                        </label>
                        <p class="form-control-static dado-formulario">
                            {{ info.endereco.bairro | vazio }}
                        </p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div *ngIf="residenteNoBrasil">
                        <label class="control-label label-formulario">
                            CEP
                        </label>
                        <p class="form-control-static dado-formulario">
                            {{ info.endereco.cep | cepPipe | vazio }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="control-label label-formulario">
                        Município
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.endereco.municipio.nome | vazio }}
                    </p>
                </div>
                <div class="col-md-1">
                    <div *ngIf="residenteNoBrasil">
                        <label class="control-label label-formulario">
                            UF
                        </label>
                        <p class="form-control-static dado-formulario">
                            {{ info.endereco.municipio.uf | vazio }}
                        </p>
                    </div>
                </div>
                <div class="col-md-5">
                    <label class="control-label label-formulario">
                        Responsável/Titular perante o CNPJ
                    </label>
                    <p class="form-control-static dado-formulario">
                        <ni-com-link [ni]="info.responsavel.cpf" [nome]="info.responsavel.nome"></ni-com-link>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Situação Cadastral do Responsável no CPF
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.responsavel.situacaoCadastral | vazio }}
                    </p>
                </div>

                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Qualificação do Responsável
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.responsavel.qualificacao | vazio }}
                    </p>
                </div>

                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Preposto da Empresa
                    </label>
                    <p *ngIf="possuiPreposto; else semPreposto" class="form-control-static dado-formulario">
                        {{ info.preposto.cpf | formatCpfPipe }} - {{ info.preposto.nome }}
                    </p>
                    <ng-template #semPreposto>
                        <p class="form-control-static dado-formulario">
                            -
                        </p>
                    </ng-template>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Natureza Jurídica da Empresa/Entidade
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.naturezaJuridica.codigo }}-{{ info.naturezaJuridica.numeroDv }} {{ info.naturezaJuridica.nome }}
                    </p>
                </div>

                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Atividade Econômica da Matriz
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.atividadeEconomica | subclasseCnaePipe | vazio }}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Porte da Empresa
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.codigoPorteEmpresa | porteEmpresaPipe | vazio }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Acompanhamento Diferenciado em
                    </label>
                    <p  class="form-control-static dado-formulario">
                        {{ acompanhamentoDiferenciado | vazio:[]:info.anoAcompanhamentoDiferenciado }}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Obrigado a DCTF Mensal em
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.anoInteresseDctf | vazio:["0000,0000,0000,0000,0000"] }}
                    </p>
                </div>
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Situação no CNPJ
                    </label>
                    <p class="form-control-static dado-formulario">
                        {{ info.situacaoCadastral | vazio }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <label class="control-label label-formulario">
                        Motivo
                    </label>
                    <p *ngIf="possuiMotivoSituacao" class="form-control-static dado-formulario">
                        {{ info.motivoBaixa | vazio }}
                    </p>
                </div>

                <div class="col-md-4">
                    <div *ngIf="info.cnpjAdquirente && exibeAdquirente">                        
                        <label class="control-label label-formulario">
                            CNPJ Adquirente
                        </label>
                        <p class="form-control-static dado-formulario">
                            <ni-com-link [ni]="info.cnpjAdquirente"></ni-com-link>
                        </p>
                    </div>
                    <div *ngIf="info.cnpjAdquirente && !exibeAdquirente">  
                        <label class="control-label label-formulario">
                            Ente Federativo Responsável
                        </label>
                        <p class="form-control-static dado-formulario">
                            {{ info.codigoEnteFederativoResponsavel | vazio }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <via-info-cadastrais-pj-table 
                    *ngIf="optanteSimei" 
                    titulo="Opção pelo SIMEI" 
                    [opcoes]="info.simplesSimei">
                </via-info-cadastrais-pj-table>
            </div>
            <div class="row">
                <via-info-cadastrais-pj-table 
                    *ngIf="optanteSimplesFederal" 
                    titulo="Opção pelo Simples Federal"
                    [opcoes]="info.simplesSF">
                </via-info-cadastrais-pj-table>
            </div>
            <div class="row">
                <via-info-cadastrais-pj-table 
                    *ngIf="optanteSimplesNacional" 
                    titulo="Opção pelo Simples Nacional"
                    [opcoes]="info.simplesSN">
                </via-info-cadastrais-pj-table>
            </div>
        </div>
    </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
    <div *ngIf="(loadingError$ | async); else loading">
        Ocorreu um erro...
    </div>
    <ng-template #loading>
        <loading-animation></loading-animation>
    </ng-template>
</ng-template>
