<table suiteRfbTable class="tabelaInterna" [class.tabela_largura_minima]="this.atendimento">
  <caption class="caption-ccpf">Pagamentos Alocados</caption>

  <ng-container *ngIf="!semPagamento; else nenhumPagamento;">
  <thead>
    <tr>
      <th class="text-center min-width">Ações</th>
      <th class="text-center min-width">
        <suite-rfb-toggle
          title="Expandir/Contrair Todos"
          (expanded)="expandedAll($event)">
        </suite-rfb-toggle>
      </th>
      <th class="text-center min-width">Componente</th>
      <th class="text-center min-width">Receita</th>
      <th class="text-center">Pagamento</th>
      <th class="text-center min-width">Processo</th>
      <th class="text-center min-width">Data Venc. Componente</th>
      <th class="text-center min-width">Data Venc. Pagamento</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let pagamento of pagamentos" class="vertical-align-middle">

      <tr class="vertical-align-middle">
        <td class="text-center">
          <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
          <ng-template #menuAcoes>
            <div class="btn-group-vertical" role="group">
              <button [disabled]="false" type="button" class="btn btn-default" (click)="desalocarPagamento(pagamento); botaoAcoes.hide();">
                Desalocar
              </button>
              <button [disabled]="false" type="button" class="btn btn-default" (click)="impedirAlocacaoAutomatica(pagamento); botaoAcoes.hide()">
                Impedir Alocação Automática
              </button>
              <button [disabled]="false" type="button" class="btn btn-default" (click)="liberarAlocacaoAutomatica(pagamento); botaoAcoes.hide()">
                Liberar Alocação Automática
              </button>
              <button [disabled]="false" type="button" class="btn btn-default" (click)="consultarPagamento(pagamento); botaoAcoes.hide();">
                Pagamento
              </button>

            </div>
          </ng-template>
        </td>
        <td>
          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
        </td>
        <td class="text-center">{{ pagamento.componente | vazio }}</td>
        <td class="text-center">
          <via-codigo-receita [codigo]="pagamento.receita"></via-codigo-receita>
        </td>
        <td class="text-center">{{ pagamento.numeroPagamento | vazio }} </td>
        <td class="text-center">{{ pagamento.numeroProcesso | vazio }} </td>
        <td class="text-center">{{ pagamento.dataVencimentoComponente | formatDataPipe }}</td>
        <td class="text-center">{{ pagamento.dataVencimentoPagamento | formatDataPipe }}</td>
      </tr>
      <ng-container *ngIf="rowToggle.show">

        <tr></tr>
        <tr>
          <td colspan="12">
          <div class="contorno-ccitr">
            <div class="row" style="padding: 5px 5px 10px 5px;">
              <div class="col-md-3">
                <label class="label-formulario control-label">
                  Data Arrecadação
                </label>
                <p class="form-control-static dado-formulario">
                  {{ pagamento.dataArrecadacao | formatDataPipe }}
                </p>
              </div>
              <div class="col-md-3">
                <label class="label-formulario control-label">
                  Data Alocação
                </label>
                <p class="form-control-static dado-formulario">
                  {{ pagamento.dataAlocacao | formatDataPipe }}
                </p>
              </div>
              <div class="col-md-3">
                <label class="label-formulario control-label">
                  Banco/Agência
                </label>
                <p class="form-control-static dado-formulario">
                  {{ pagamento.banco | vazio }} / {{ pagamento.agencia | vazio }}
                </p>
              </div>
              <div class="col-md-3">
                <label class="label-formulario control-label">
                  Tipo Alocação
                </label>
                <p class="form-control-static dado-formulario">
                 {{ pagamento.tipoAlocacao | vazio }}
                </p>
              </div>
            </div>

              <div class="row">
                <div class="col-md-12">
                <table suiteRfbTable class="tabelaInterna" [class.tabela_largura_minima]="true">
                  <thead>
                    <tr>
                      <th class="text-center">Alocação Automática - Critério {{ pagamento.criterio }}</th>
                      <th class="text-center">Pagamento</th>
                      <th class="text-center">Utilizado</th>
                      <th class="text-center">Amortizado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let alocacao of pagamento.alocacoesAutomaticas" class="vertical-align-middle">
                      <tr class="vertical-align-middle">
                        <td class="text-left">{{ alocacao.descricao | vazio }}</td>
                        <td class="text-right">{{ alocacao.valorPagamento | formatCurrency }}</td>
                        <td class="text-right">{{ alocacao.valorUtilizado | formatCurrency }} </td>
                        <td class="text-right">{{ alocacao.valorAmortizado | formatCurrency }} </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              </div>
            </div>

        </tr>

      </ng-container>
    </ng-container>

  </tbody>
  <ng-container *ngIf="pagamentos.length > 0">
    <tfoot>
      <tr>
        <td colspan="8">
          <via-pagination [itemAmount]="pagamentos.length" [(page)]="currentPage">
          </via-pagination>
       </td>
      </tr>
    </tfoot>
  </ng-container>

  </ng-container>

  <ng-template #nenhumPagamento>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum pagamento alocado encontrado para este crédito tributário.
      </td>
    </tr>
  </ng-template>

</table>

