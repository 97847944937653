<table suiteRfbTable>
  <caption class="caption-ccpf">Componentes</caption>
  <ng-container *ngIf="!semComponente; else nenhumComponente;">
    <thead>
      <tr>
        <th class="text-center min-width" *ngIf="exibirAcoes">Ações</th>
        <th class="text-center min-width">Número</th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Data de Vencimento</th>
        <th class="text-center min-width">Data de Prorrogação</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Saldo Devedor</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let componente of componentes | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center" *ngIf="exibirAcoes">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button class="btn btn-default" type="button" (click)="onRevalidarData(componente); botaoAcoes.hide();">Revalidar Data</button>
                <button class="btn btn-default" type="button" (click)="onAlocarPagamentos(componente); botaoAcoes.hide();">Alocar Pagamentos</button>
              </div>
            </ng-template>
          </td>
          <td class="text-center">{{ componente | numeroComponentePipe | vazio }}</td>
          <td class="text-center">
            <via-codigo-receita [codigo]="componente.receita"></via-codigo-receita>
          </td>
          <td class="text-center">{{ componente.dataVencimento | formatDataPipe | vazio }}</td>
          <td class="text-center">{{ componente.dataProrrogacao | formatDataPipe | vazio }}</td>
          <td class="text-right">{{ componente.valorOriginal | formatCurrency | vazio }}</td>
          <td class="text-right">{{ componente.saldoDevedor | formatCurrency | vazio }}</td>
          <td class="text-left">{{ componente.situacao | vazio }}</td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
  <ng-template #nenhumComponente>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum componente encontrado para este crédito tributário.
      </td>
    </tr>
  </ng-template>
</table>
