import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DebitoSicob } from './model/debito-sicob';

@Injectable()
export class DebitoSicobService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDebitosSicobAtivos(ni: string, numeroProtocolo: string): Observable<DebitoSicob[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sicob/debito/Cobranca/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoSicob[]>(url, options);
  }

  public obterDebitosSicobSuspensos(ni: string, numeroProtocolo: string): Observable<DebitoSicob[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sicob/debito/Exigibilidade/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoSicob[]>(url, options);
  }
}
