import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ConsultarReceitaService } from './consultar-receita.service';
import { ReceitaDTO } from './model/receita';

@Component({
  templateUrl: './consultar-receita.component.html',
  styleUrls: ['./consultar-receita.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultarReceitaComponent implements OnInit {

  codigo: string;
  loadingError$ = new Subject<boolean>();
  receitaObservable$: Observable<ReceitaDTO>;

  scopeId: string;

  constructor(
    public bsModalRef: BsModalRef,
    private service: ConsultarReceitaService,
    private messageService: ScopedMessageService
  ) { }

  ngOnInit(): void {

    this.scopeId = this.messageService.scopeId;

    this.receitaObservable$ = this.service.buscarReceitaSIEF(this.codigo)
      .pipe(
        catchError(err => {
          this.messageService.showErrorException('Consulta Receita SIEF', err);
          return of<ReceitaDTO>();
        })
      );
  }
}
