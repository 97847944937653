import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ImpedimentoCndService } from './impedimento-cnd.service';
import { ImpedimentoCnd } from './model/impedimento-cnd';

@Component({
  selector: 'app-impedimento-cnd',
  templateUrl: './impedimento-cnd.component.html',
  styleUrls: ['./impedimento-cnd.component.css']
})
export class ImpedimentoCndComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  subtitulo: string;
  impedimentos: ImpedimentoCnd[];

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isRfb = false;
  isPgfn = false;

  constructor(
    private impedimentoCndService: ImpedimentoCndService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('ImpedimentoCndComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let impedimentosObservable: Observable<ImpedimentoCnd[]>;
      if (this.isPessoaJuridica) {
        if (this.isRfb) {
          impedimentosObservable = this.impedimentoCndService.obterImpedimentosCnpjMatrizRfb(ni, numeroProtocolo);
        }
        if (this.isPgfn) {
          impedimentosObservable = this.impedimentoCndService.obterImpedimentosCnpjMatrizPgfn(ni, numeroProtocolo);
        }
      }
      if (this.isPessoaFisica) {
        if (this.isRfb) {
          impedimentosObservable = this.impedimentoCndService.obterImpedimentosCpfAtivoRfb(ni, numeroProtocolo);
        }
        if (this.isPgfn) {
          impedimentosObservable = this.impedimentoCndService.obterImpedimentosCpfAtivoPgfn(ni, numeroProtocolo);
        }
      }

      impedimentosObservable.subscribe(
        (resp) => {
          this.impedimentos = resp;
          this.logService.logAjaxReturn('ImpedimentoCndComponent', 'obterImpedimentos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ImpedimentoCndComponent', 'obterImpedimentos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os impedimentos emissão CND', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ImpedimentoCndComponent', 'obterImpedimentos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os impedimentos emissão CND', e);
    }
  }
}

@Component({
  selector: 'app-impedimento-cnd',
  templateUrl: './impedimento-cnd.component.html',
  styleUrls: ['./impedimento-cnd.component.css']
})
export class ImpedimentoCndPJRfbComponent extends ImpedimentoCndComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isRfb = true;
    this.titulo = `Impedimentos Judiciais na RFB - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    this.subtitulo = "Relação de Impedimentos Judiciais à Emissão de Certidão Negativa ou Positiva com Efeitos de Negativa na RFB";
    super.init(ni);
  }
}

@Component({
  selector: 'app-impedimento-cnd',
  templateUrl: './impedimento-cnd.component.html',
  styleUrls: ['./impedimento-cnd.component.css']
})
export class ImpedimentoCndPJPgfnComponent extends ImpedimentoCndComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isPgfn = true;
    this.titulo = `Impedimentos Judiciais na PGFN - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    this.subtitulo = "Relação de Impedimentos Judiciais à Emissão de Certidão Negativa ou Positiva com Efeitos de Negativa na PGFN";
    super.init(ni);
  }
}

@Component({
  selector: 'app-impedimento-cnd',
  templateUrl: './impedimento-cnd.component.html',
  styleUrls: ['./impedimento-cnd.component.css']
})
export class ImpedimentoCndPFRfbComponent extends ImpedimentoCndComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isRfb = true;
    this.titulo = `Impedimentos Judiciais na RFB - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    this.subtitulo = "Relação de Impedimentos Judiciais à Emissão de Certidão Negativa ou Positiva com Efeitos de Negativa na RFB";
    super.init(ni);
  }
}

@Component({
  selector: 'app-impedimento-cnd',
  templateUrl: './impedimento-cnd.component.html',
  styleUrls: ['./impedimento-cnd.component.css']
})
export class ImpedimentoCndPFPgfnComponent extends ImpedimentoCndComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isPgfn = true;
    this.titulo = `Impedimentos Judiciais na PGFN - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    this.subtitulo = "Relação de Impedimentos Judiciais à Emissão de Certidão Negativa ou Positiva com Efeitos de Negativa na PGFN";
    super.init(ni);
  }
}
