import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationComponent, LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CampoAlteravel } from '../../models/campo-alteravel';
import { DocumentoArrecadacao } from '../../models/documento-arrecadacao';
import { ParametrosPesquisaPagamento } from '../../models/parametros-pesquisa';
import { TipoNIRDOC } from '../../models/tipo-ni-rdoc';
import { TipoOrigemErro } from '../../models/tipo-origem-erro';
import { TipoRetificacao } from '../../models/tipo-retificacao';
import { PagamentoComprovanteService } from '../../services/pagamento-comprovante.service';
import { PagamentoService } from '../../services/pagamento.service';

@Component({
  templateUrl: './retificacao-pagamento.component.html',
  styleUrls: ['./retificacao-pagamento.component.css'],
  providers: [ScopedMessageService]
})
export class RetificacaoPagamentoComponent implements IDynamicPanel, OnInit, OnDestroy, AfterViewInit {

  @Input() parametrosPesquisa: ParametrosPesquisaPagamento;
  @Input() atendimento: boolean = false;
  @Input() documentoArrecadacao: DocumentoArrecadacao;
  @Input() tipoRetificacao: TipoRetificacao;
  @Input() camposAlteraveisResponse: CampoAlteravel[];
  @ViewChildren('detalhes') detalhesQuery: QueryList<ToggleComponent>;

  @ViewChild('confirmaEmissaoComprovante') emissaoComprovante: ConfirmationComponent;

  @ViewChild('origemErroBanco') inputOrigemBanco: ElementRef<HTMLInputElement>;
  @ViewChild('origemErroNaoBanco') inputOrigemNaoBanco: ElementRef<HTMLInputElement>;

  TipoRetificacao = TipoRetificacao;
  initError: Error;
  camposCarregados: boolean = false;
  camposAlteraveis: any;
  formGroup: FormGroup;
  scopeId: string;
  featureEventSub: Subscription;

  titulo: string;
  tipoNIRDOC = TipoNIRDOC;
  totalReceitaDe: number = 0;
  totalReceitaPara: number;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  qtdListaValoresAlterados: number;

  readonly tipoOrigemErroBanco = TipoOrigemErro.BANCO;
  readonly tipoOrigemErroNaoBanco = TipoOrigemErro.NAO_BANCO;

  constructor(
    private formBuilder: FormBuilder,
    private pagamentoService: PagamentoService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private pagamentoComprovanteService: PagamentoComprovanteService,
    private loadingService: LoadingService) { }

  init(ni: string, params?: OptionalParams): void {
    this.parametrosPesquisa = params.parametrosPesquisa;
    this.atendimento = params.atendimento;
    this.documentoArrecadacao = params.documentoArrecadacao;
    this.tipoRetificacao = params.tipoRetificacao;
    this.camposAlteraveisResponse = params.camposAlteraveisResponse;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$
      .subscribe(featureEvent => {
        // se a chave de identificação da feature for a mesma
        if (featureEvent.active == false && featureEvent.featureAction.id == 'ConsultarPagamentos' && featureEvent.featureAction.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharRetificacao();
          this.featureEventSub.unsubscribe();
        }
      });
  }

  ngOnInit(): void {
    this.titulo = `Pagamento - Retificação ${this.tipoRetificacao}`;
    this.scopeId = this.messageService.scopeId;
    this.formGroup = this.formBuilder.group({
      ni: [''],
      tipoNi: [''],
      periodoApuracao: [''],
      dataVencimento: [''],
      processoNr: [''],
      referenciaNr: [''],
      idDepositoCef: [''],
      vrba: [''],
      vrbaPerc: [''],
      bancoCd: [''],
      agenciaCd: [''],
      dataArrecadacao: [''],
      origemErro: [''],
      processoRetificacaoNr: [''],
      recCd0: [''],
      recValor0: [''],
      recCd1: [''],
      recValor1: [''],
      recCd2: [''],
      recValor2: [''],
      cnpj1: [''],
      cnpj2: [''],
      cnpj3: [''],
    });
    this.ajustarCamposData();
    this.ajustarCamposAlteraveis();
  }

  ngAfterViewInit(): void {
    const campoOrigemErro = this.camposAlteraveisResponse.find(x => x.nomeCampo === 'origemErro');

    if (campoOrigemErro && campoOrigemErro.valor === 'BANCO') {
      this.inputOrigemBanco.nativeElement.checked = true;
    }

    else if (campoOrigemErro && campoOrigemErro.valor === 'NÃO BANCO') {
      this.inputOrigemNaoBanco.nativeElement.checked = true;
    }
  }

  private ajustarCamposData(): void {
    this.documentoArrecadacao.dataRecepcao = this.documentoArrecadacao.dataRecepcao ?
      new Date(this.documentoArrecadacao.dataRecepcao) : null;
    this.documentoArrecadacao.dataVencimento = this.documentoArrecadacao.dataVencimento ?
      new Date(this.documentoArrecadacao.dataVencimento) : null;
    this.documentoArrecadacao.dataArrecadacao = this.documentoArrecadacao.dataArrecadacao ?
      new Date(this.documentoArrecadacao.dataArrecadacao) : null;
  }

  private ajustarCamposAlteraveis(): void {
    this.camposAlteraveis = {};
    this.camposAlteraveisResponse.forEach(campo => {
      this.camposAlteraveis[campo.nomeCampo] = campo;

      if (!campo.alteravel && this.formGroup.controls[campo.nomeCampo]) {
        this.formGroup.controls[campo.nomeCampo].disable();
      }

      if (campo.nomeCampo == 'dataVencimento' || campo.nomeCampo == 'dataArrecadacao') {
        campo.valor = campo.valor ? campo.valor = new Date(campo.valor) : null;
        if (campo.valor != null && campo.valor.toString() != this.documentoArrecadacao[campo.nomeCampo].toString()) {
          this.formGroup.controls[campo.nomeCampo].setValue(campo.valor);
        }
      } else {
        if (campo.valor != null && campo.valor != this.documentoArrecadacao[campo.nomeCampo]) {
          this.formGroup.controls[campo.nomeCampo].setValue(campo.valor);
        }
      }
    })

    this.titulo += ' -  No. do Documento - ' + (_.isEmpty(this.documentoArrecadacao.numeroDocumento)
      ? '[NÚMERO NÃO DISPONÍVEL]'
      : this.documentoArrecadacao.numeroDocumento);

    let itemCount = 0;
    if (this.documentoArrecadacao.receitaList != null) {
      this.qtdListaValoresAlterados = this.documentoArrecadacao.receitaList.length;
      this.documentoArrecadacao.receitaList.forEach(
        receita => {
          this.totalReceitaDe += receita.valor;
          if (itemCount > 0 || this.camposAlteraveis.receitaList.alteravel == false) {
            this.formGroup.controls['recCd' + itemCount].disable();
          }
          if (this.camposAlteraveis.receitaList.alteravel == false) {
            this.formGroup.controls['recValor' + itemCount].disable();
          }
          itemCount++;
        }
      );
    }

    if (this.tipoRetificacao == TipoRetificacao.SIMPLIFICADA && this.camposAlteraveis.ni.alteravel) {
      this.formGroup.controls['cnpj1'].disable();
      this.formGroup.controls['cnpj2'].enable();
      this.formGroup.controls['cnpj3'].enable();
    } else {
      this.formGroup.controls['cnpj1'].disable();
      this.formGroup.controls['cnpj2'].disable();
      this.formGroup.controls['cnpj3'].disable();
    }

    this.camposCarregados = true;
  }

  public carregaReceita() {
    let pagamentoAlterado = _.cloneDeep(this.documentoArrecadacao);
    pagamentoAlterado.codigoReceita = this.formGroup.controls['recCd0'].value;
    this.pagamentoService.calcularValores(pagamentoAlterado).subscribe(
      resultado => {
        let listaValores = resultado;
        this.qtdListaValoresAlterados = listaValores.length;

        for (let cont = 0; cont < listaValores.length; cont++) {
          this.formGroup.controls['recCd' + cont].setValue(listaValores[cont].recCd);
          this.formGroup.controls['recValor' + cont].setValue(listaValores[cont].valor);
        }

        for (let cont = listaValores.length; cont < 3; cont++) {
          this.formGroup.controls['recCd' + cont].setValue('');
          this.formGroup.controls['recValor' + cont].setValue('');
        }

        this.somaReceita();
      },
      erro => {
        this.messageService.showErrorException("Ocorreu um erro ao alterar o código de receita", erro);
      }
    );
  }

  public somaReceita(): void {
    this.totalReceitaPara = 0;

    this.totalReceitaPara += this.formGroup.controls['recValor0'].value &&
      Number(this.formGroup.controls['recValor0'].value != ''
        ? Number(this.formGroup.controls['recValor0'].value)
        : 0);

    this.totalReceitaPara += this.formGroup.controls['recValor1'].value &&
      Number(this.formGroup.controls['recValor1'].value != ''
        ? Number(this.formGroup.controls['recValor1'].value)
        : 0);

    this.totalReceitaPara += this.formGroup.controls['recValor2'].value &&
      Number(this.formGroup.controls['recValor2'].value != ''
        ? Number(this.formGroup.controls['recValor2'].value)
        : 0);
  }

  public retificar(): void {
    const pagamentoAlterado = this.gerarPagamentoAlterado();
    this.loadingService.show();
    this.pagamentoService.realizarRetificacao(pagamentoAlterado, this.tipoRetificacao).subscribe(
      _sucesso => {
        this.documentoArrecadacao = pagamentoAlterado;
        this.pagamentoService.atualizarListagemPagamento(this.parametrosPesquisa);
        this.loadingService.hide();
        this.formGroup.reset();
        this.emissaoComprovante.show();
      },
      erro => {
        this.loadingService.hide();
        this.messageService.showErrorException("Ocorreu um erro ao solicitar a retificação", erro);
      }
    )
  }

  private gerarPagamentoAlterado(): DocumentoArrecadacao {
    let documento: DocumentoArrecadacao = _.cloneDeep(this.documentoArrecadacao);
    let tipoNI;

    if (this.formGroup.controls.ni.value && this.formGroup.controls.ni.value.length == 14) {
      tipoNI = TipoNIRDOC.CPF;
      documento.tipoNi = TipoNIRDOC.CPF;
    } else if (this.formGroup.controls.ni.value && this.formGroup.controls.ni.value.length == 18) {
      tipoNI = TipoNIRDOC.CNPJ;
      documento.tipoNi = TipoNIRDOC.CNPJ;
    }

    if (this.tipoRetificacao == TipoRetificacao.SIMPLIFICADA && this.documentoArrecadacao.ni && this.documentoArrecadacao.ni.length == 14) {
      let ni = "";

      if (this.formGroup.controls.cnpj1.value != null && this.formGroup.controls.cnpj1.value.trim() != '') {
        ni += this.formGroup.controls.cnpj1.value;
      }
      else {
        if (this.documentoArrecadacao.ni != null && this.documentoArrecadacao.ni && this.documentoArrecadacao.ni.length == 14) {
          ni += this.documentoArrecadacao.ni.substring(0, 8);
        }
      }

      if (this.formGroup.controls.cnpj2.value != null && this.formGroup.controls.cnpj2.value.trim() != '') {
        ni += this.formGroup.controls.cnpj2.value;
      }
      else {
        if (this.documentoArrecadacao.ni != null && this.documentoArrecadacao.ni && this.documentoArrecadacao.ni.length == 14) {
          ni += this.documentoArrecadacao.ni.substring(8, 12);
        }
      }

      if (this.formGroup.controls.cnpj3.value != null && this.formGroup.controls.cnpj3.value.trim() != '') {
        ni += this.formGroup.controls.cnpj3.value;
      }
      else {
        if (this.documentoArrecadacao.ni != null && this.documentoArrecadacao.ni.length == 14) {
          ni += this.documentoArrecadacao.ni.substring(12, 14);
        }
      }
      documento.ni = ni;
    } else {
      documento.ni = this.valorFinalString(this.formGroup.controls.ni.value ? this.formGroup.controls.ni.value.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '') : '', documento.ni);
    }

    documento.periodoApuracao = this.formGroup.controls.periodoApuracao.value && this.formGroup.controls.periodoApuracao.value != ''
      ? this.formGroup.controls.periodoApuracao.value.toLocaleDateString()
      : documento.periodoApuracao;

    documento.dataRecepcao = new Date(documento.dataRecepcao);
    documento.dataVencimento = this.valorFinalDate(this.formGroup.controls.dataVencimento.value, documento.dataVencimento);
    documento.processoNr = this.valorFinalString(this.formGroup.controls.processoNr.value, documento.processoNr);
    documento.referenciaNr = this.valorFinalString(this.formGroup.controls.referenciaNr.value, documento.referenciaNr);
    documento.idDepositoCef = this.valorFinalString(this.formGroup.controls.idDepositoCef.value, documento.idDepositoCef);

    documento.vrba = this.valorFinalNumber(this.formGroup.controls.vrba.value, documento.vrba);
    documento.vrbaPerc = this.valorFinalNumber(this.formGroup.controls.vrbaPerc.value, documento.vrbaPerc);
    documento.bancoCd = this.valorFinalString(this.formGroup.controls.bancoCd.value, documento.bancoCd);
    documento.agenciaCd = this.valorFinalString(this.formGroup.controls.agenciaCd.value, documento.agenciaCd);
    documento.dataArrecadacao = this.valorFinalDate(this.formGroup.controls.dataArrecadacao.value, documento.dataArrecadacao);

    documento.receitaList = this.formGroup.controls['recCd' + 0].value != '' ? [] : documento.receitaList;
    for (let cont = 0; cont <= 2; cont++) {
      if (this.formGroup.controls['recCd' + 0].value != '') {
        documento.receitaList[cont] = {
          recCd: this.formGroup.controls['recCd' + cont].value,
          recTp: (cont + 1).toString(),
          valor: this.formGroup.controls['recValor' + cont].value
        };
      }
    }

    if (this.inputOrigemBanco.nativeElement.checked) {
      documento.origemErro = TipoOrigemErro.BANCO;
    } else if (this.inputOrigemNaoBanco.nativeElement.checked) {
      documento.origemErro = TipoOrigemErro.NAO_BANCO;
    } else {
      documento.origemErro = null;
    }

    documento.processoRetificacaoNr = this.valorFinalString(this.formGroup.controls.processoRetificacaoNr.value, documento.processoRetificacaoNr);

    return documento;
  }

  public ngOnDestroy(): void {
    if (this.featureEventSub) {
      this.featureEventSub.unsubscribe();
    }
  }

  private valorFinalString(valorCampo: string, valorOriginal: string): string {
    if (valorCampo && valorCampo != '' && valorCampo != valorOriginal) {
      if (valorCampo != '0') {
        return valorCampo;
      } else {
        return null;
      }
    } else {
      return valorOriginal;
    }
  }

  private valorFinalNumber(valorCampo: string, valorOriginal: number): number {
    if (valorCampo && valorCampo != '' && Number(valorCampo) != valorOriginal) {
      if (valorCampo != '0') {
        return Number(valorCampo);
      } else {
        return null;
      }
    } else {
      return valorOriginal;
    }
  }

  private valorFinalDate(valorCampo: string, valorOriginal: Date): Date {
    if (valorCampo && valorCampo != '' && new Date(valorCampo) != valorOriginal) {
      return new Date(valorCampo);
    } else {
      return valorOriginal;
    }
  }

  private fecharRetificacao(): void {
    this.menuFeatureActionsService.deactivate({
      id: `pagamento_${this.documentoArrecadacao.numeroPagamento}`,
      title: `Pagamento - Retificação ${this.tipoRetificacao}`,
      class: RetificacaoPagamentoComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO,
    });
  }

  public formataVrba(): void {
    let tmp = this.formGroup.controls.vrba.value && this.formGroup.controls.vrba.value != ''
      ? this.formGroup.controls.vrba.value
      : '';
    let tmpNumber = Number(tmp.replaceAll('.', '')) / 100;
    this.formGroup.controls.vrba.setValue(tmpNumber.toString());
  }

  public formataVrbaPerc(): void {
    let tmp = this.formGroup.controls.vrbaPerc.value && this.formGroup.controls.vrbaPerc.value != ''
      ? this.formGroup.controls.vrbaPerc.value
      : '';
    let tmpNumber = Number(tmp.replaceAll('.', '')) / 10;
    this.formGroup.controls.vrbaPerc.setValue(tmpNumber.toString());
  }

  onEmitirComprovante(): void {
    this.loadingService.show();

    this.pagamentoComprovanteService.obterComprovanteRetificacao(this.documentoArrecadacao).subscribe(
      response => {
        this.loadingService.hide();
        this.pagamentoComprovanteService.visualizarComprovante(response, 'Comprovante de Retificação');
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException('Ocorreu um erro ao emitir o comprovante de retificação', error);
      }
    );
  }
}
