import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'porteEmpresaPipe'
})
export class PorteEmpresaPipe implements PipeTransform {
    public transform(codigo: string | number): string {
        if (codigo) {
            switch (this.normaliza(codigo)) {
                case '1':
                    return 'Micro Empresa';
                case '3':
                    return 'Empresa de Pequeno Porte';
                case '5':
                    return 'Demais';
                default:
                    return '';
            }
        }
        return '';
    }

    private normaliza(codigo: string | number): string {
        return codigo.toString().trim().replace(/^0+/, '');
    }

}