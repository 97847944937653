import { Component, ViewEncapsulation } from '@angular/core';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { LogService } from 'src/app/shared/services/log.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ProcessoSief } from '../../model/processo-sief';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ArrolamentoBensSiefService } from './arrolamento-bens-sief.service';
import { ProcessoSiefArrolamentoBens } from './model/arrolamento-bens';

@Component({
    selector: 'via-arrolamento-bens-sief',
	templateUrl: './arrolamento-bens-sief.component.html',
	styleUrls: ['./arrolamento-bens-sief.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class ArrolamentoBensSiefComponent implements IDynamicPanel {

	initError: Error;

	titulo: string;
	processos: ProcessoSiefArrolamentoBens [];
		
	constructor(
		private arrolamentoBensSiefService: ArrolamentoBensSiefService,
		private atendimentoService: AtendimentoService,
		private messageService: ScopedMessageService,
		private logService: LogService,
	)
    {
		console.log('ProcessoSIEFComponent.constructor');
	}

	init(ni: string) {

		console.log('ArrolamentoBensSIEFComponent.init ni', ni);
		const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
		const protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
		const tipoNi = infosContribuinte.tipoNIAtendimento;

		console.log("TipoNi");
		console.log(tipoNi);

		try {
			this.arrolamentoBensSiefService.obterArrolamentoBens(ni, tipoNi, protocolo).subscribe(
				(resp) => {
				  this.processos = resp;
				  console.log(resp);	
				},
				(err) => {
					this.initError = err;
				}
			);
		} catch (e) {
			this.initError = e;
			this.logService.logError('arrolamentoBensSiefService.obterArrolamentoBens.obterProcessoFiscal', 'ocorreu um erro ao obter debitos Processo Fiscal', e);
			this.messageService.showErrorException('Ocorreu um erro ao obter os arrolamentos de bens', e);
		}
	}
}

@Component({
    selector: 'via-arrolamento-bens-sief',
    templateUrl: './arrolamento-bens-sief.component.html',
    styleUrls: ['./arrolamento-bens-sief.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ArrolamentoBensSiefPJComponent extends ArrolamentoBensSiefComponent {
    init(ni: string) {
        this.titulo = `Arrolamento de Bens - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}
@Component({
    selector: 'via-arrolamento-bens-sief',
    templateUrl: './arrolamento-bens-sief.component.html',
    styleUrls: ['./arrolamento-bens-sief.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ArrolamentoBensSiefPFComponent extends ArrolamentoBensSiefComponent {
    init(ni: string) {
        this.titulo = `Arrolamento de Bens - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}
