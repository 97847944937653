import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { DebitoProfiscModule } from '../debito-profisc/debito-profisc.module';
import { ProcessoProfiscSuiteRfbModule } from './processo-profisc-suite-rfb.module';
import {
  ProcessoProfiscComponent,
  ProcessoProfiscPFAtivoComponent,
  ProcessoProfiscPFSuspensoComponent,
  ProcessoProfiscPJAtivoComponent,
  ProcessoProfiscPJSuspensoComponent
} from './processo-profisc.component';
import { ProcessoProfiscService } from './processo-profisc.service';
import { ProcessoProfiscTableComponent } from './table/processo-profisc-table.component';

@NgModule({
  declarations: [
    ProcessoProfiscComponent,
    ProcessoProfiscPFAtivoComponent,
    ProcessoProfiscPFSuspensoComponent,
    ProcessoProfiscPJAtivoComponent,
    ProcessoProfiscPJSuspensoComponent,
    ProcessoProfiscTableComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forChild(),
    SharedModule,
    PipesModule,
    SitfisPipesModule,
    ProcessoProfiscSuiteRfbModule,
    DebitoProfiscModule
  ],
  providers: [
    ProcessoProfiscService
  ],
  entryComponents: [
    ProcessoProfiscComponent,
    ProcessoProfiscPFAtivoComponent,
    ProcessoProfiscPFSuspensoComponent,
    ProcessoProfiscPJAtivoComponent,
    ProcessoProfiscPJSuspensoComponent
  ]
})
export class ProcessoProfiscModule { }
