import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { ImpedimentoCnd } from '../model/impedimento-cnd';

@Component({
  selector: 'via-impedimento-cnd-table',
  templateUrl: 'impedimento-cnd-table.component.html',
  styleUrls: ['impedimento-cnd-table.component.css']
})
export class ImpedimentoCndTableComponent implements OnInit {

  @Input() impedimentos: ImpedimentoCnd[];

  @Input() ni: string;
  @Input() subtitulo: string;

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isRfb: boolean;
  @Input() isPgfn: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  tipoNI: TipoNI;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  ngOnInit(): void {
    this.tipoNI = this.isPessoaFisica ? TipoNI.CPF : TipoNI.CNPJ;
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}
