import { Component } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DebitoSicobService } from './debito-sicob.service';
import { DebitoSicob, DebitosSicobGroupedByNi } from './model/debito-sicob';

@Component({
  selector: 'app-debito-sicob',
  templateUrl: './debito-sicob.component.html',
  styleUrls: ['./debito-sicob.component.css']
})
export class DebitoSicobComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  debitosAgrupados: DebitosSicobGroupedByNi;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private debitoSicobService: DebitoSicobService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('DebitoSicobComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let debitosAgrupadosObservable: Observable<DebitosSicobGroupedByNi>;
      if (this.isAtivos) {
        debitosAgrupadosObservable = this.agrupaPorNi(
          this.debitoSicobService.obterDebitosSicobAtivos(ni, numeroProtocolo));
      }
      if (this.isSuspensos) {
        debitosAgrupadosObservable = this.agrupaPorNi(
          this.debitoSicobService.obterDebitosSicobSuspensos(ni, numeroProtocolo));
      }

      debitosAgrupadosObservable.subscribe(
        (resp) => {
          this.debitosAgrupados = resp;
          this.logService.logAjaxReturn('DebitoSicobComponent', 'obterDebitos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('DebitoSicobComponent', 'obterDebitos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os débitos Sicob', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('DebitoSicobComponent', 'obterDebitos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os débitos Sicob', e);
    }
  }

  private agrupaPorNi(debitos: Observable<DebitoSicob[]>): Observable<DebitosSicobGroupedByNi> {
    return debitos.pipe(
      //tap(val => console.log(`debitos Sicob 1: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => _.orderBy(debitos, ['tipoNi', 'ni', 'numeroDebito'], ['asc', 'asc', 'asc'])),
      //tap(val => console.log(`debitos Sicob 2: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => _.groupBy(debitos, (debito) => debito.tipoNi + '|' + debito.ni)),
      //tap(val => console.log(`debitos Sicob 3: ${JSON.stringify(val, undefined, 4)}`)),
    );
  }
}

@Component({
  selector: 'app-debito-sicob',
  templateUrl: './debito-sicob.component.html',
  styleUrls: ['./debito-sicob.component.css']
})
export class DebitoSicobPJAtivoComponent extends DebitoSicobComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos Previdenciários SICOB - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-debito-sicob',
  templateUrl: './debito-sicob.component.html',
  styleUrls: ['./debito-sicob.component.css']
})
export class DebitoSicobPJSuspensoComponent extends DebitoSicobComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos Previdenciários SICOB - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-debito-sicob',
  templateUrl: './debito-sicob.component.html',
  styleUrls: ['./debito-sicob.component.css']
})
export class DebitoSicobPFAtivoComponent extends DebitoSicobComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos Previdenciários SICOB - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-debito-sicob',
  templateUrl: './debito-sicob.component.html',
  styleUrls: ['./debito-sicob.component.css']
})
export class DebitoSicobPFSuspensoComponent extends DebitoSicobComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos Previdenciários SICOB - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}
