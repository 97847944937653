import { Component, Input, OnInit } from '@angular/core';
import { CpfService } from 'src/app/cpf/services/cpf.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DadosBasicosPfDTO } from '../../dados-basicos-pf/model/dados-basicos-pf-dto';
import { TituloEleitor } from '../../titulo-eleitor/model/titulo-eleitor';
import { TituloEleitorService } from '../../titulo-eleitor/titulo-eleitor.service';

@Component({
  selector: 'via-consulta-dados-basicos-pf',
  templateUrl: './consulta-dados-basicos-pf.component.html',
  styleUrls: ['./consulta-dados-basicos-pf.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaDadosBasicosPfComponent implements OnInit {

  @Input() cpf: string;
  @Input() dadosBasicos: DadosBasicosPfDTO;
  @Input() collapsed = false;
  @Input() isCadeiaNi = false;

  tituloEleitor: TituloEleitor;
  titulo = 'Dados Básicos';
  scopeId: string;

  constructor(
    private cpfService: CpfService,
    private tituloEleitorService: TituloEleitorService,
    private messageService: ScopedMessageService,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.obterDadosBasicos();
    this.obterDadosTituloEleitor();
  }

  exibirDadosTituloEleitor(): void {
    this.dynamicModalService.exibirDadosTituloEleitor(this.cpf);
  }

  private obterDadosBasicos(): void {
    if (!this.dadosBasicos) {
      this.cpfService.obterDadosBasicos(this.cpf).subscribe(
        response => {
          this.dadosBasicos = response;
          if (this.dadosBasicos.cpfPontaCadeia) {
            this.titulo = FormatCpfPipe.formatCPF(this.dadosBasicos.cpf);
          }
        },
        error => this.messageService.showErrorException('Ocorreu um erro ao obter os dados básicos da pessoa física', error)
      );
    }
  }

  private obterDadosTituloEleitor(): void {
    this.tituloEleitorService.obterDadosTituloEleitor(this.cpf).subscribe(
      response => this.tituloEleitor = response,
      error => this.messageService.showErrorException('Ocorreu um erro ao obter o título de eleitor', error)
    );
  }
}
