import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IrregularidadeCadastral } from './model/irregularidade-cadastral';

@Injectable()
export class IrregularidadeCadastralService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterIrregularidadesCadastraisPessoaFisica(ni: string, numeroProtocolo: string): Observable<IrregularidadeCadastral[]> {
        const url = `${this.baseUrl}/situacoesfiscais/irregularidades/CPF/${ni}`;
        console.log('url= ' + url);
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<IrregularidadeCadastral[]>(url, options);
    }

    public obterIrregularidadesCadastraisPessoaJuridica(ni: string, numeroProtocolo: string): Observable<IrregularidadeCadastral[]> {
        const url = `${this.baseUrl}/situacoesfiscais/irregularidades/CNPJ/${ni}`;
        console.log('url= ' + url);
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<IrregularidadeCadastral[]>(url, options);
    }
}
