import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OperacaoSucessao } from '../models/operacao-sucessao';

@Injectable()
export class OperacaoSucessaoService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterOperacoesSucessao(cnpj: string): Observable<OperacaoSucessao> {
    const url = `${this.baseUrl}/cnpj/operacoes-sucessao/${cnpj}`;
    return this.http.get<OperacaoSucessao>(url);
  }
}
