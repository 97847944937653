import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { QuadroSocioPjDTO } from 'src/app/sitfis/model/quadro-socio-pj-dto';

@Component({
  selector: 'via-socio-admin-qsa-table',
  templateUrl: './socio-admin-qsa-table.component.html',
  styleUrls: ['./socio-admin-qsa-table.component.css']
})
export class SocioAdminQSATableComponent {
  @Input() cnpj: string;
  @Input() razaoSocial: string;
  @Input() socios: QuadroSocioPjDTO[];

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

}