import { FormControl, NgModel } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

export const DEFAULT_ERRORS = {
  ni: 'NI inválido.'
};

@Component({
  selector: 'via-local-messages',
  templateUrl: './local-messages.component.html',
  styleUrls: [ './local-messages.component.scss' ],
})

export class LocalMessagesComponent implements OnInit {

  @Input() niMessage: string = DEFAULT_ERRORS.ni;
  @Input() control: NgModel|FormControl;

  constructor() { }

  public ngOnInit(): void {}

}
