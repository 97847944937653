import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcessoProfiscDebito } from '../model/debito-profisc';

@Injectable()
export class DebitoProfiscService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDebitos(numeroProcesso: string, ni: string, numeroProtocolo: string): Observable<ProcessoProfiscDebito[]> {
    const url = `${this.baseUrl}/situacoesfiscais/profisc/processos/debitos/${numeroProcesso}/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ProcessoProfiscDebito[]>(url, options);
  }
}
