import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { TipoPendencia } from 'src/app/sitfis/model/enum/tipo-pendencia';
import { CCPFService } from '../ccpf.service';
import { DebitoContaCorrentePF } from '../model/debito-ccpf';

@Component({
  selector: 'via-ccpf-table',
  templateUrl: './ccpf-table.component.html',
  styleUrls: ['./ccpf-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CCPFTableComponent {

  TipoPendencia = TipoPendencia;

  @Input() titulo: string;
  @Input() debitos: DebitoContaCorrentePF[];
  @Input() tipoDebito: TipoPendencia;

  @Output() regularizarDebito = new EventEmitter<DebitoContaCorrentePF>();
  @Output() emitirDarf = new EventEmitter<DebitoContaCorrentePF>();
  @Output() emitirDARFExercicio = new EventEmitter<DebitoContaCorrentePF>();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  constructor(private service: CCPFService) { }

  // paginacao
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  permiteRegularizacao(debito: DebitoContaCorrentePF): boolean {
    return false;
    // TODO a condicao abaixo deve ser habilitada quando da implementacao da demanda COGEA 1590 - COAEF 0075/2018
    //return this.service.permiteRegularizacao(debito);
  }

  permiteEmissaoDarf(debito: DebitoContaCorrentePF): boolean {
    return this.service.permiteEmissaoDARF(debito);
  }

  permiteEmissaoDarfExercicio(debito: DebitoContaCorrentePF): boolean {
    // TODO remove temporariamente ate a implementacao da demanda COGEA 1590 - COAEF 0075/2018
    return false;
  }

  onRegularizarDebito(debito: DebitoContaCorrentePF): void {
    this.regularizarDebito.emit(debito);
  }

  onEmitirDarf(debito: DebitoContaCorrentePF): void {
    this.emitirDarf.emit(debito);
  }

  onEmitirDarfExercicio(debito: DebitoContaCorrentePF): void {
    this.emitirDARFExercicio.emit(debito);
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}
