import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheHistoricoAlteracaoPj } from '../models/detalhe-historico-alteracao-pj';
import { HistoricoPjService } from '../services/historico-pj.service';

@Component({
  selector: 'via-historico-pj',
  templateUrl: './historico-pj.component.html',
  styleUrls: ['./historico-pj.component.css'],
  providers: [ScopedMessageService]
})
export class HistoricoPjComponent implements OnInit {

  @Input() cnpj: string;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  titulo = `Histórico`;

  historicoAlteracoes: DetalheHistoricoAlteracaoPj[];

  scopeId: string;
  stopLoading = false;
  initError: Error;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  private executouObterDados = false;

  constructor(
    private service: HistoricoPjService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterHistoricoAlteracoes();
    }
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(row => row.show = show);
  }

  private obterHistoricoAlteracoes(): void {
    this.executouObterDados = true;

    this.service.obterHistorico(this.cnpj).subscribe(
      response => {
        this.historicoAlteracoes = response;

        if (isEmpty(this.historicoAlteracoes)) {
          this.messageService.showInfo('Nenhum registro encontrado');
          this.stopLoading = true;
        }
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter o histórico de alterações da pessoa jurídica', error);
        this.initError = error;
      }
    );
  }
}
