import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import currency from 'currency.js';
import { isEmpty, isEqual, isNil } from 'lodash';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Componente, CreditoTributario } from 'src/app/cobranca/models/credito-tributario';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../../services/ccpf.service';
import { Pagamento, ResumoAlocacaoPagamento } from '../models/pagamento';
import { ParametroComponenteDebito } from '../models/parametros-pesquisa';

@Component({
  templateUrl: 'aloca-pagamento.component.html',
  styleUrls: ['aloca-pagamento.component.css'],
  providers: [ScopedMessageService]
})
export class CcpfAlocaPagamentoComponent implements OnInit, OnDestroy, IDynamicPanel {

  @Input() cpfContribuinte: string;
  @Input() componente: Componente;
  @Input() credito: CreditoTributario;
  @Input() atendimento: boolean = false;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  parametros: ParametroComponenteDebito;
  alocacoesPorPagamento = new Map<string, ResumoAlocacaoPagamento[]>();

  titulo: string;
  scopeId: string;
  initError: Error;

  currencyMaskOptions = {
    thousands: '.',
    decimal: ',',
    prefix: '',
    allowNegative: false,
    nullable: false,
    min: 0,
    max: null,
    precision: 2
  };

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  isAtualizacaoPagamentos = false;

  pagamentosObservable$: Observable<Pagamento[]>;

  private updateSubscription: Subscription;
  private featureEventSub: Subscription;

  private readonly SITUACAO_TRANSFERENCIA_SIEFPAR = '59';

  constructor(
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private loadingService: LoadingService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  public init(_ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametroComponenteDebito;
    this.cpfContribuinte = this.parametros.cpfContribuinte;
    this.credito = this.parametros.credito;
    this.componente = this.parametros.componente;
    this.atendimento = this.parametros.atendimento;
    this.parametrosPesquisa = this.parametros.parametrosPesquisa;
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.titulo = `CCPF - Alocação de Pagamento - CT: ${this.credito.ct} - Componente: ${this.componente.numero}`;

    this.updateSubscription = this.ccpfService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.ct;
        this.credito = creditos.find(credito => isEqual(credito.ct, ct));
        this.updateSubscription?.unsubscribe();
      }
    });

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharAlocacaoPagamento();
          this.featureEventSub?.unsubscribe();
        }
      }
    );

    this.obterPagamentos();
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
    this.featureEventSub?.unsubscribe();
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }

  obterDescricaoProcessoOuParcelamento(): string {
    if (this.credito.codigoSituacao === this.SITUACAO_TRANSFERENCIA_SIEFPAR) {
      return 'N° Parcelamento';
    }
    return 'N° Processo';
  }

  isValorParaAlocacaoInvalido(valorParaAlocacao: number): boolean {
    return isNil(valorParaAlocacao) || valorParaAlocacao <= 0;
  }

  onAlocarPagamento(pagamento: Pagamento): void {
    const valorAlocar = currency(pagamento.valorParaAlocacao).toString();

    this.messageService.dismissMessages();
    this.loadingService.show();

    this.ccpfService.alocarPagamento(this.cpfContribuinte, pagamento.numeroPagamento, this.credito.ct, this.componente.numero, valorAlocar).subscribe(
      _sucesso => {
        this.messageService.showInfo('Alocação de pagamento realizada com sucesso');
        this.isAtualizacaoPagamentos = true;
        this.obterPagamentos();
        this.loadingService.hide();
      },
      error => {
        this.initError = error;
        this.messageService.showErrorException('Erro ao realizar a alocação do pagamento', error);
        this.loadingService.hide();
      }
    );
  }

  private obterPagamentos(): void {
    this.alocacoesPorPagamento.clear();

    this.pagamentosObservable$ = this.ccpfService.obterPagamentosParaAlocacao(this.cpfContribuinte)
      .pipe(
        tap(response => {
          this.montarResumoAlocacoes(response);

          if (this.isAtualizacaoPagamentos) {
            this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
          }
        }),
        catchError(error => {
          this.initError = error;
          this.messageService.showErrorException('Erro ao obter os pagamentos para alocação', error);
          return of<Pagamento[]>([]);
        })
      );
  }

  private montarResumoAlocacoes(pagamentos: Pagamento[]): void {
    pagamentos.forEach(pagamento => {

      const numeroPagamento = pagamento.numeroPagamento;

      pagamento.debitosAlocados.forEach(debito => {

        debito.componente.forEach(componente => {

          const resumoAlocacao: ResumoAlocacaoPagamento = {
            receita: componente.codigoReceita,
            numeroDebito: debito.numeroDebito,
            compCt: `${componente.numeroComponente} ${componente.indicadorEncargo}`,
            dataVencimento: componente.dataVencimento,
            dataProrrogacao: componente.alocacao.dataAlocacao,
            valorOriginal: componente.valorOriginario,
            saldoDevedor: debito.saldoDevedorDebito,
            situacao: componente.situacao,
            tipoNotificacao: `${debito.notificacao.tipo} - ${debito.notificacao.descricao}`,
            criterio: `${componente.alocacao.criterio} ${componente.alocacao.tipo}`,
            saldoComponente: componente.valorSaldo,
            valorAlocado: componente.alocacao.valorAlocado,
            valorAmortizado: componente.alocacao.valorAmortizado
          };

          if (this.alocacoesPorPagamento.has(numeroPagamento)) {
            this.alocacoesPorPagamento.get(numeroPagamento).push(resumoAlocacao);
          } else {
            this.alocacoesPorPagamento.set(numeroPagamento, new Array(resumoAlocacao));
          }
        })
      });
    });
  }

  private fecharAlocacaoPagamento(): void {
    const feature = {
      id: `CCPF_AlocarPagamentos_${this.credito.ct}_componente_${this.componente.numero}`,
      class: CcpfAlocaPagamentoComponent,
      title: 'CCPF - Alocar Pagamentos',
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
