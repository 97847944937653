import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatProcessoPipe'
})
export class FormatProcessoPipe implements PipeTransform {
  transform(nroProc: string, tamanho?: string) {
    if(nroProc){
      return FormatProcessoPipe.formatProcesso(nroProc, tamanho);
    }
    return null;
  }

  static formatProcesso: Function = (nroProc: string, tamanho?: string) => {
    nroProc = nroProc+"";
    if (tamanho == '2' || (nroProc.length == 17 && tamanho != '1')) {
      let pad = "00000000000000000";
      let proc17pos = (pad + nroProc).slice(-pad.length);
      let procFormat = proc17pos.substring(0, 5) + "-" + proc17pos.substring(5, 8) + "." +
        proc17pos.substring(8, 11) + "/" + proc17pos.substring(11, 15) + "-" +
        proc17pos.substring(15, 17);
      return procFormat;
    } else {
      if (tamanho == '1' || (nroProc.length == 15 && tamanho != '2')) {
        let pad = "000000000000000";
        let proc15pos = (pad + nroProc).slice(-pad.length);
        let procFormat = proc15pos.substring(0, 5) + "-" + proc15pos.substring(5, 8) + "." +
          proc15pos.substring(8, 11) + "/" + proc15pos.substring(11, 13) + "-" +
          proc15pos.substring(13, 15);
        return procFormat;
      }
    }
    return nroProc;
  }

}


