<suite-rfb-collapse title="COMPROT" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="comprotProcessos?.processos?.length > 0; else loadingOrError;">
    <via-comprot-table
      [ni]="ni"
      [processos]="comprotProcessos.processos">
    </via-comprot-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
