<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center">Motivo</th>
        <th class="text-center">Origem</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let detalhe of detalheOmissao.criterios | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td class="text-left text-nowrap">{{ detalhe.nomeCriterio | vazio }}</td>
          <td class="text-left">{{ detalhe.sistemas?.join(', ') | vazio }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="2">
          <via-pagination [itemAmount]="detalheOmissao.criterios.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>

  <button class="btn btn-md btn-default" (click)="onAbrirPortalIrpf()">Portal IRPF</button>
</div>
