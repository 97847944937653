<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
      <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
        <ng-container *ngIf="pagamentosTDA && pagamentosTDA.length > 0; else loadingOrError;">
          <div class="container-fluid" >
            <ng-container>
                <div class="table-responsive">
                  <table suiteRfbTable [class.tabela_largura_minima]="!this.classeModal">
                    <thead>
                      <tr>
                        <th class="text-center min-width">
                          <suite-rfb-toggle
                            title="Expandir/Contrair Todos"
                            (expanded)="expandedAll($event)">
                          </suite-rfb-toggle>
                        </th>
                        <th class="text-center min-width">Pagamento</th>
                        <th class="text-center min-width">Data Liquidação</th>
                        <th class="text-center min-width">Data Alocação</th>
                        <th class="text-center min-width">Componente Vinculado</th>
                        <th class="text-center min-width">Situação TDA</th>
                        <th class="text-center min-width">Processo TDA</th>
                        <th class="text-center min-width">Valor Utilizado</th>
                        <th class="text-center min-width">Valor Alocado</th>
                        <th class="text-center">Valor não Utilizado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let pagamento of pagamentosTDA|currentPage: currentPage;"
                        class="vertical-align-middle">
                        <tr class="vertical-align-middle">
                          <td>
                            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                          </td>
                          <td class="text-right">{{ pagamento.numeroPagamento| vazio}}</td>
                          <td class="text-center">{{ pagamento.dataLiquidacao | formatDataPipe }}</td>
                          <td class="text-center">{{ pagamento.dataAlocacao | formatDataPipe }}</td>
                          <td class="text-center">{{ pagamento.componenteVinculado| vazio}}</td>
                          <td class="text-left">{{ pagamento.situacaoTDA| vazio}}</td>
                          <td class="text-left" nowrap>{{ pagamento.processoTDA| vazio}}</td>
                          <td class="text-right">{{ pagamento.valorUtilizado | formatCurrency }}</td>
                          <td class="text-right">{{ pagamento.valorAlocado | formatCurrency }}</td>
                          <td class="text-right">{{ pagamento.valorNaoUtilizado | formatCurrency }}</td>
                        </tr>
                        <ng-container *ngIf="rowToggle.show">
                          <tr></tr>
                          <tr>
                            <td colspan="10" class="background-inherit">
                              <div class="contorno-ccitr">
                                  <via-ccitr-table-componentes [componentes]="pagamento.componentes">
                                  </via-ccitr-table-componentes>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </tbody>
                    <tfoot>
                      <tr class="tabela-acoes text-left">
                        <td colspan="10">
                          <div class="row">
                            <div class="col-md-12">
                              <via-pagination [itemAmount]="pagamentosTDA?.length" [(page)]="currentPage">
                              </via-pagination>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
            </ng-container>
          </div>
        </ng-container>
  </suite-rfb-collapse>
</div>


<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>

