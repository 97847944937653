  <suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
    <div class="container-fluid container-filtros">
        <div class="container-fluid">
          <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">CPF</label>
                  </div>

                  <div class="col-md-6">
                    <label class="control-label">Exercício</label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-data-arrecadacao">
                    <div class="form-inline">
                      <div class="form-group" [suiteRfbHasError]="cpfCtrl">
                        <input class="form-control" type="text" [formControlName]="cpfName" autocomplete="off"
                          suiteRfbInputMask="999.999.999-99" suiteRfbValidCpf>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-faixa-valor">
                    <div class="form-inline">
                      <input class="form-control input-exercicio" type="text" autocomplete="off" maxlength="4"
                        [formControlName]="exercicioDeName" suiteRfbInputMask="9999" NumbersOnlyDirective>

                      <label class="control-label faixa-valor-label-separador" style="margin: 0 5px;">a</label>

                      <input class="form-control input-exercicio" type="text" autocomplete="off" maxlength="4"
                        [formControlName]="exercicioAteName" suiteRfbInputMask="9999" NumbersOnlyDirective>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="radio-inline">
                    <input type="radio" [formControlName]="criterioSelecaoName" [value]="criterioSelecaoTodos">
                    <span>Todos os créditos tributários</span>
                  </label>
                </div>
                <div class="form-group">
                  <label class="radio-inline">
                    <input type="radio" [formControlName]="criterioSelecaoName" [value]="criterioSelecaoVencimento">
                    <span>Créditos tributários vencidos ou a vencer</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" style="margin-top: 25px;">
                <div class="form-group">
                  <div class="text-center">
                    <button type="button" (click)="onReset()" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
                    <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Buscar</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
  </suite-rfb-collapse>
<via-consulta-creditos
  *ngIf="exibeResultado"
  [parametros]="parametros"
  [atendimento]="true"
  [parentScopeId]="scopeId">
</via-consulta-creditos>
