import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SuiteRfbCollapseModule, SuiteRfbInputMaskModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CCPFContribuinteDepuradoComponent } from './contribuinte-depurado.component';

@NgModule({
  declarations: [
    CCPFContribuinteDepuradoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    SharedModule,
    SuiteRfbCollapseModule,
    SuiteRfbInputMaskModule
  ],
  exports: [
    CCPFContribuinteDepuradoComponent
  ]
})
export class CcpfContribuinteDepuradoModule { }
