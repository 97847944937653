import { CodigoReceita, OrdemDARF} from './../../model/enum/arrecadacao';
import { Component, OnDestroy } from '@angular/core';
import { LocalDate } from '@js-joda/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { Subscription } from 'rxjs';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { TipoMenu } from 'src/app/shared/model/feature';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DateUtilsService } from 'src/app/shared/util/date-utils.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DebitoSief } from '../../model/debito-sief';
import { TipoNI } from '../../model/enum/tipo-ni';
import { ParametrosDarf, SistemaOrigemDarf } from '../../model/parametros-darf';
import { ParametrosDas } from '../../model/parametros-das';
import { DarfService } from '../darf/darf.service';
import { DasService } from '../das/das.service';
import { DebitoProcessoSiefService } from './debito-processo-sief.service';


@Component({
  selector: 'via-debito-processo-sief',
  templateUrl: './debito-processo-sief.component.html',
  styleUrls: ['./debito-processo-sief.component.css']
})
export class DebitoProcessoSiefComponent implements IDynamicPanel, OnDestroy {

  titulo: string;

  ni: string;

  numeroProcesso: string;
  identificadorProcesso: string;
  numeroProtocolo: string;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;
  mensagem = 0;

  debitos: DebitoSief[];

  initError: Error;
  featureEventSub: Subscription;
  tipoMenu: TipoMenu;
  idFeature: string;

  pageSize: number = 10;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private debitoProcesoSiefService: DebitoProcessoSiefService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private dateUtilsService: DateUtilsService,
    private darfService: DarfService,
    private dasService: DasService) { }

  init(id: string, params?: OptionalParams): void {
    // id pode vir com o ni ou identificador processo a depender do menu de origem
    this.ni = params.ni;
    this.tipoMenu = params.tipoMenu;
    this.idFeature = params.idFeature;

    this.numeroProcesso = params.numeroProcesso as string;
    this.identificadorProcesso = params.identificadorProcesso as string;

    const infosContribuinte = this.atendimentoService.getInfosAtendimento(this.ni);
    this.numeroProtocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$
    .subscribe(featureEvent => {
      // se a chave de identificação da feature for a mesma

      if (featureEvent.active == false && featureEvent.featureAction.tipoMenu == params?.tipoMenu
        && ( featureEvent.featureAction.id == params?.idFeaturePrincipal)) {
          this.featureEventSub.unsubscribe();
          this.fecharPainel();
      }
    });

    this.debitoProcesoSiefService
      .obterDebitosProcesso(this.ni, this.identificadorProcesso, this.numeroProtocolo, this.isAtivos)
      .subscribe(
        debitos => this.debitos = debitos,
        error => {
          this.messageService.showErrorException("Ocorreu um erro ao obter os débitos do processo", error);
          this.initError = error;
        });
  }

  private fecharPainel(): void {
    this.menuFeatureActionsService.deactivate({
      id: this.idFeature,
      title: 'Debito',
      visible: true,
      rolesAllowed: [],
      tipoMenu: this.tipoMenu,
      class: DebitoProcessoSiefComponent
    });
  }

  public ngOnDestroy(): void {
    if(this.featureEventSub){
      this.featureEventSub.unsubscribe();
    }
  }

  public emitirDas(item: { numeroProcesso: string, debito: DebitoSief }): void {

    this.definirMensagemDas(item);
    this.preencherParametroeEmitirDas(item);

   }

   protected definirMensagemDas(item: { numeroProcesso: string, debito: DebitoSief }) {

      this.mensagem = 0;

      if (item.debito.inOrdemDarf == OrdemDARF.DAS) {
           if (item.debito.codigoReceita == CodigoReceita.DAS_MEI) {
              this.mensagem = 1;
            } else if (item.debito.codigoReceita == CodigoReceita.DAS_COMUM) {
                if (item.debito.indExibeDarf) {
                  const noPrazo = this.verificarDarfEstaNoPrazo(item.debito.dataVencimento);
                  if (noPrazo) {
                    this.mensagem = 3;
                  }
                } else {
                  this.mensagem = 2;
                }
            } else {
              this.mensagem = 1;
          }
        }

    }

  protected preencherParametroeEmitirDas(item: { numeroProcesso: string, debito: DebitoSief }) {
    const parametros = this.preencherParametrosDas(item);
    this.dasService.emitirDas(parametros, this.mensagem);
  }


  private preencherParametrosDas(item: { numeroProcesso: string, debito: DebitoSief }): ParametrosDas {
    const parametros: ParametrosDas = {
      cnpjBasico: this.ni.substring(0, 8),
      pa: item.debito.dataPaExercicio.substring(0, 6),
      processo: item.numeroProcesso,
      codigoExtensaoReceita: item.debito.codigoExtensaoReceita
    }

    return parametros;
  }

  public emitirDarf(debito: DebitoSief): void {

    this.definirMensagemDarf(debito);
    this.preencherParametroeEmitirDarf(debito);

  }

protected definirMensagemDarf(debito: DebitoSief) {

    this.mensagem = 0;

    switch (debito.inOrdemDarf.valueOf()) {

      case OrdemDARF.DARF_NUMERADO: {
        if (debito.indExibeDarf)
          this.mensagem = 2;
        else
          this.mensagem = 3;
        break;
      }

      case OrdemDARF.DARF_COMUM: {
        if (debito.indExibeDarf) {
          const noPrazo = this.verificarDarfEstaNoPrazo(debito.dataVencimento);
          if (noPrazo) {
            this.mensagem = 1;
          }
        } else {
          this.mensagem = 3;
        }
        break;
      }

      case OrdemDARF.CONSOLIDA:
      case OrdemDARF.NAO_EMITE_CONSOLIDA: {
        this.mensagem = 4;
        break;
      }


    }

}

protected preencherParametroeEmitirDarf(debito: DebitoSief) {
  const parametros = this.preencherParametrosDarf(debito);
  this.darfService.emitirDarf(parametros, this.mensagem);
}

  private preencherParametrosDarf(debito: DebitoSief): ParametrosDarf {
    const parametros: ParametrosDarf = {
      protocolo: this.numeroProtocolo,
      tipoNi: this.isPessoaJuridica ? TipoNI.CNPJ : this.isPessoaFisica ? TipoNI.CPF : null,
      ni: this.ni,
      sistemaOrigem: SistemaOrigemDarf.SFCOB,
      numeroDebito: debito.numeroDebito,
      numeroProcesso: '',
      numeroImovel: '',
      codigoReceita: '',
      numeroComponente: '',
      exercicio: '',
    }

    return parametros;
  }

  private verificarDarfEstaNoPrazo(dataVencimento: string) {

    let diferencaDias = this.dateUtilsService.diasDesde(LocalDate.parse(dataVencimento));
    return diferencaDias < 60;

  }
}

@Component({
  selector: 'via-debito-processo-sief',
  templateUrl: './debito-processo-sief.component.html',
  styleUrls: ['./debito-processo-sief.component.css']
})
export class DebitoProcessoSiefPFAtivoComponent extends DebitoProcessoSiefComponent {

  init(identificadorProcesso: string, params?: OptionalParams): void {
    this.titulo = `Débitos Ativos SIEF Processos - CPF: ${FormatCpfPipe.formatCPF(params.ni)}`;
    this.isPessoaFisica = true;
    this.isAtivos = true;
    super.init(identificadorProcesso, params);
  }
}

@Component({
  selector: 'via-debito-processo-sief',
  templateUrl: './debito-processo-sief.component.html',
  styleUrls: ['./debito-processo-sief.component.css']
})
export class DebitoProcessoSiefPFSuspensoComponent extends DebitoProcessoSiefComponent {

  init(identificadorProcesso: string, params?: OptionalParams): void {
    this.titulo = `Débitos Suspensos SIEF Processos - CPF: ${FormatCpfPipe.formatCPF(params.ni)}`;
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    super.init(identificadorProcesso, params);
  }

 emitirDarf(debito: DebitoSief): void {
    super.definirMensagemDarf(debito);
    if (debito.inOrdemDarf == OrdemDARF.DARF_COMUM && debito.indExibeDarf)
      this.mensagem = 0;
    super.preencherParametroeEmitirDarf(debito);
  }
}

@Component({
  selector: 'via-debito-processo-sief',
  templateUrl: './debito-processo-sief.component.html',
  styleUrls: ['./debito-processo-sief.component.css']
})
export class DebitoProcessoSiefPJAtivoComponent extends DebitoProcessoSiefComponent {

  init(identificadorProcesso: string, params?: OptionalParams): void {
    this.titulo = `Débitos Ativos SIEF Processos - CNPJ: ${FormatCnpjPipe.formatCNPJ(params.ni)}`;
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    super.init(identificadorProcesso, params);
  }
}

@Component({
  selector: 'via-debito-processo-sief',
  templateUrl: './debito-processo-sief.component.html',
  styleUrls: ['./debito-processo-sief.component.css']
})
export class DebitoProcessoSiefPJSuspensoComponent extends DebitoProcessoSiefComponent {

  init(identificadorProcesso: string, params?: OptionalParams): void {
    this.titulo = `Débitos Suspensos SIEF Processos - CNPJ: ${FormatCnpjPipe.formatCNPJ(params.ni)}`;
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    super.init(identificadorProcesso, params);
  }

  emitirDarf(debito: DebitoSief): void {
    super.definirMensagemDarf(debito);
    if (debito.inOrdemDarf == OrdemDARF.DARF_COMUM && debito.indExibeDarf)
      this.mensagem = 0;
    super.preencherParametroeEmitirDarf(debito);
  }

  emitirDas(item: { numeroProcesso: string, debito: DebitoSief }): void {
    super.definirMensagemDas(item);
    if (item.debito.inOrdemDarf == OrdemDARF.DAS)
      this.mensagem = 2;
    super.preencherParametroeEmitirDas(item);
  }
}
