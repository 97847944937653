import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCnoPipe'
})
export class FormatCnoPipe implements PipeTransform {
  transform(cno: number) {
      return FormatCnoPipe.formatCNO(cno);
  }

  static formatCNO: Function = (cno: number) => {
    if(cno){
      let pad = "000000000000";
      let cno12pos = (pad+cno).slice(-pad.length);
      return cno12pos.substring(0,2).concat(".")
                                  .concat(cno12pos.substring(2,5))
                                  .concat(".")
                                  .concat(cno12pos.substring(5,10))
                                  .concat("/")
                                  .concat(cno12pos.substring(10,12));
    }
  }
}
