import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodigoDescricaoDTO } from "src/app/cnpj/model/codigo-descricao-dto";
import { CompensacaoCcitrDTO } from 'src/app/cobranca/models/compensacao-ccitr-dto';
import { CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { PagamentoTDA } from 'src/app/cobranca/models/pagamento-tda';
import { ParametrosConsultaCompensacoesCcitr } from 'src/app/cobranca/models/parametros-consulta-compensacoes-ccitr';
import { ParametrosPesquisaCcitr } from 'src/app/cobranca/models/parametros-pesquisa-ccitr';
import { environment } from 'src/environments/environment';
import { DadosConsolidadoDebitoCCITRDTO } from '../components/consolidar-ct/model/dados-consolidado-debito-ccitr';
import { ParametroConsolidarDebitoCCITR } from '../components/consulta-creditos/model/parametro-consolidar-debito-ccitr';
import { CCITREventoDTO } from '../components/eventos/model/ccitr-evento-dto';
import { CcitrImoveisDoContribuinteDTO } from '../components/imoveis-do-contribuinte/model/ccitr-imoveis-do-contribuinte';
import { CcitrPagamentosDarfDTO } from '../components/pagamentos-darf/model/ccitr-pagamentos-darf';
import { VencimentoCTCcitr } from './../../../models/vencimento-ct';
import { CTDepuradoDTO } from './../components/recupera-ct-depurado/model/ct-depurado';
import { String } from 'lodash';

@Injectable()
export class CCITRService {

  constructor(private httpClient: HttpClient) { }

  private url = environment.APIEndpoint;

  public atualizarListagemEmitter: EventEmitter<ParametrosPesquisaCcitr> = new EventEmitter();
  public notificarAtualizacaoListaCreditosEmitter: EventEmitter<CreditoTributarioCcitr[]> = new EventEmitter();

  public atualizarListagemCreditos(parametros: ParametrosPesquisaCcitr): void {
    this.atualizarListagemEmitter.emit(parametros);
  }

  public notificarAtualizacaoListaCreditos(creditos: CreditoTributarioCcitr[]): void {
    this.notificarAtualizacaoListaCreditosEmitter.emit(creditos);
  }

  public obterImoveisDoContribuinte(parametros: ParametrosPesquisaCcitr): Observable<CcitrImoveisDoContribuinteDTO[]> {
    let params = new HttpParams();

    if (parametros.nirf) {
      params = params.set('nirf', parametros.nirf);
    }
    if (parametros.indicadorCnpjBasico) {
      params = params.set('indicador-cnpj-basico', parametros.indicadorCnpjBasico);
    }
    if (parametros.tipoLancamento) {
      params = params.set('tipo-lancamento', parametros.tipoLancamento);
    }
    if (parametros.cpfContribuinte) {
      params = params.set('cpf-contribuinte', parametros.cpfContribuinte);
    }
    if (parametros.cnpjContribuinte) {
      params = params.set('cnpj-contribuinte', parametros.cnpjContribuinte);
    }
    if (parametros.exercicioInicial) {
      params = params.set('exercicio-inicial', parametros.exercicioInicial);
    }
    if (parametros.exercicioFinal) {
      params = params.set('exercicio-final', parametros.exercicioFinal);
    }
    if (parametros.vencidosAVencer) {
      params = params.set('vencidos-a-vencer', parametros.vencidosAVencer);
    }

    const url = `${this.url}/cobranca/ccitr/imoveis`;

    return this.httpClient.get<CcitrImoveisDoContribuinteDTO[]>(url,  {params: params});
  }

  public consultarPagamentosTDA(parametros: ParametrosPesquisaCcitr): Observable<PagamentoTDA[]> {
    let ni = parametros.cpfContribuinte?parametros.cpfContribuinte:parametros.cnpjContribuinte;
    const url = `${this.url}/cobranca/ccitr/pagamentos-tda/${ni}/${parametros.nirf}/${parametros.tipoLancamento}?exercicio-inicial=${parametros.exercicioInicial}&exercicio-final=${parametros.exercicioFinal}`;
    return this.httpClient.get<PagamentoTDA[]>(url);
  }

  public obterEventos(nirf: string,
                      ni: string,
                      dataInicial: string,
                      dataFinal: string,
                      tipoEvento: string,
                      exercicioInicial: string,
                      exercicioFinal: string ): Observable<CCITREventoDTO[]> {

    let params= new HttpParams();
    let urlEventoService;
    if (dataInicial!=undefined && dataInicial!=null && dataInicial!=""){
      urlEventoService = `${this.url}/cobranca/ccitr/eventos/${ni}/${nirf}?data-inicial=${dataInicial}&data-final=${dataFinal}`;
    } else {
      urlEventoService = `${this.url}/cobranca/ccitr/eventos/${ni}/${nirf}`;
    }

    if (tipoEvento) {
      params = params.set('tipo-evento', tipoEvento);
    }

    if (exercicioInicial) {
      params = params.set('exercicio-inicial', exercicioInicial);
    }

    if (exercicioFinal) {
      params = params.set('exercicio-final', exercicioFinal);
    }

    return this.httpClient.get<CCITREventoDTO[]>(urlEventoService, {params: params});
  }

  public recuperarCompensacoes(parametros: ParametrosConsultaCompensacoesCcitr): Observable<CompensacaoCcitrDTO[]> {
    let params = new HttpParams().append('nirf', parametros.nirf)
                                 .append('indicador-cnpj-basico', parametros.indicadorCnpjBasico)
                                 .append('tipo-lancamento', parametros.tipoLancamento)
                                 .append('data-inicial', parametros.dataInicial)
                                 .append('data-final', parametros.dataFinal)
                                 .append('numero-processo-rest-ress', parametros.numeroProcessoRestRess)
                                 .append('numero-credito-ress', parametros.nueroCreditoRess)
                                 .append('numero-CT', parametros.numeroCt)
                                 .append('numero-componente', parametros.numeroComponente);

    if (parametros.cpfContribuinte) {
      params = params.set('cpf-contribuinte', parametros.cpfContribuinte);
    }

    if (parametros.cnpjContribuinte) {
      params = params.set('cnpj-contribuinte', parametros.cnpjContribuinte);
    }

    const url = `${this.url}/cobranca/ccitr/compensacoes/${parametros.nirf}`;

    return this.httpClient.get<CompensacaoCcitrDTO[]>(url, {params: params});
  }

  public desalocarPagamento(ni: string,
                            nirf: string,
                            numeroCT: string,
                            numeroComponente: string,
                            numeroPagamento: string): Observable<void> {

    const url = `${this.url}/cobranca/ccitr/desaloca-pagamento/${ni}/${nirf}`;

    let params = new HttpParams()
                     .set('numero-ct', numeroCT)
                     .set('numero-componente', numeroComponente)
                     .set('numero-pagamento', numeroPagamento);

    return this.httpClient.put<void>(url, null, { params: params });
  }

  public recuperarCTDepurados(parametros: ParametrosPesquisaCcitr) : Observable<CTDepuradoDTO[]> {

    let nirf = parametros.nirf;

    const url = `${this.url}/cobranca/ccitr/ct-depurados/${nirf}`;
    let params= new HttpParams();

    if (parametros.cpfContribuinte) {
      params = params.set('cpf-contribuinte', parametros.cpfContribuinte);
    }

    if (parametros.cnpjContribuinte) {
      params = params.set('cnpj-contribuinte', parametros.cnpjContribuinte);
    }

    if (parametros.indicadorCnpjBasico) {
      params = params.set('indicador-cnpj-basico', parametros.indicadorCnpjBasico);
    }

    if (parametros.tipoLancamento) {
      params = params.set('tipo-lancamento', parametros.tipoLancamento);
    }

    return this.httpClient.get<CTDepuradoDTO[]>(url, {params: params});
  }

  public retornarCTDepurados(nirf: string,
                             cpfContribuinte: string,
                             cnpjContribuinte: string,
                             indicadorCnpjBasico: string,
                             exercicioCT: string,
                             tipoLancamento: string) : Observable<void> {

    const url = `${this.url}/cobranca/ccitr/retorna-ct-depurado/${nirf}/${exercicioCT}/${tipoLancamento}`;
    let params = new HttpParams().append('cpf-contribuinte', cpfContribuinte)
                                 .append('cnpj-contribuinte', cnpjContribuinte)
                                 .append('indicador-cnpj-basico', indicadorCnpjBasico);

    return this.httpClient.put<void>(url, params);
  }

  public consultarCreditosTributarios(parametros: ParametrosPesquisaCcitr): Observable<CreditoTributarioCcitr[]> {
    let cpfCnpjContribuinte = (parametros.cpfContribuinte || parametros.cnpjContribuinte);
    let params = new HttpParams().append('exercicio-inicial', parametros.exercicioInicial)
                                 .append('exercicio-final', parametros.exercicioFinal)
                                 .append('vencidos-a-vencer', parametros.vencidosAVencer);

    const url = `${this.url}/cobranca/ccitr/credito-tributario/${cpfCnpjContribuinte}/${parametros.nirf}/${parametros.tipoLancamento}`;
    return this.httpClient.get<CreditoTributarioCcitr[]>(url, {params: params});
  }

  public recuperarAlocacoes(parametros: ParametrosConsultaCompensacoesCcitr): Observable<CompensacaoCcitrDTO[]> {
    let params = new HttpParams().append('nirf', parametros.nirf)
                                 .append('numeroCt', parametros.numeroCt)
                                 .append('numeroComponente', parametros.numeroComponente);

    const url = `${this.url}/cobranca/ccitr/creditos/${parametros.nirf}`;

    return this.httpClient.get<CompensacaoCcitrDTO[]>(url, {params: params});
  }

  public obterPagamentosDarf(
              nirf: string,
              tipoLancamento: string,
              cpfContribuinte: string,
              cnpjContribuinte: string,
              numeroCreditoTributario: string,
              paraAlocacao: boolean
  ): Observable<CcitrPagamentosDarfDTO[]> {

    const url = `${this.url}/cobranca/ccitr/pagamentos-darf/${cnpjContribuinte ? cnpjContribuinte : cpfContribuinte}/${nirf}/${tipoLancamento}`;

    let params = new HttpParams();

    if (numeroCreditoTributario) {
      params = params.append('numero-credito-tributario', numeroCreditoTributario);
    }
    params = params.append('para-alocacao', paraAlocacao);

    return this.httpClient.get<CcitrPagamentosDarfDTO[]>(url, {params});
  }

  public alterarVencimentoCT(parametros: VencimentoCTCcitr) : Observable<void> {

    let nirf = parametros.nirf;
    let numeroCT = parametros.numeroCT;
    let dataCienciaAI = parametros.dataVencimento;
    let cpfCnpjContribuinte = parametros.cpfCnpjContribuinte;

    let params = new HttpParams();

    const url = `${this.url}/cobranca/ccitr/alterar-vencimento-ct/${cpfCnpjContribuinte}/${nirf}/${numeroCT}/${dataCienciaAI}`;

    return this.httpClient.put<void>(url, params);
  }

  public transferirCT(niDeclarante: string,
                      nirf:	string,
                      numeroCt: string,
                      codigoMotivo: string,
                      textoMotivo: string,
                      numeroProcesso: string,
                      numeroPerdComp: string): Observable<void> {

    let params = new HttpParams();

    const url = `${this.url}/cobranca/ccitr/transferir-credito-tributario/${niDeclarante}/${nirf}/${numeroCt}?codigo-motivo=${codigoMotivo}&texto-motivo=${textoMotivo}&numero-processo=${numeroProcesso}&numero-perdcomp=${numeroPerdComp}`;

    return this.httpClient.put<void>(url, params);
  }

  public obterMotivosTransferencia(): Observable<CodigoDescricaoDTO[]> {
    const url = `${this.url}/cobranca/ccitr/motivo-transferencia`;

    return this.httpClient.get<CodigoDescricaoDTO[]>(url);
  }

  public impedirAlocacaoAutomaticaPagamento(nirf: string, numeroPagamento: string, motivoImpedimento: string): Observable<void> {
    let params = new HttpParams().append('numero-pagamento', numeroPagamento)
                                 .append('motivo-impedimento', motivoImpedimento);

    const url = `${this.url}/cobranca/ccitr/impedimento-alocacao-automatica-pagamento/${nirf}`;

    return this.httpClient.post<void>(url, params);
  }

  public retornarSituacaoCT(parametros: ParametrosPesquisaCcitr, credito: CreditoTributarioCcitr): Observable<Object> {
    let cpfCnpjContribuinte = (parametros.cpfContribuinte || parametros.cnpjContribuinte);
    const url = `${this.url}/cobranca/ccitr/situacao-credito-tributario/${cpfCnpjContribuinte}/${parametros.nirf}/${credito.numeroCt}`;
    return this.httpClient.put(url, { responseType: 'text' as const });
  }

  public consolidarCT(parametros: ParametroConsolidarDebitoCCITR): Observable<DadosConsolidadoDebitoCCITRDTO>{
    const url = `${this.url}/cobranca/ccitr/consolidar-debito-credito-tributario/${parametros.ni}/${parametros.nirf}/${parametros.numeroCT}?data-consolidacao=${parametros.dataConsolidacao}`;
    return this.httpClient.get<DadosConsolidadoDebitoCCITRDTO>(url);
  }

  public alocarPagamento(nirf: string,
                         numeroCT: string,
                         numeroPagamento: string,
                         numeroComponente: string,
                         valorAlocar: string,
                         ni: string): Observable<void> {

      const url = `${this.url}/cobranca/ccitr/alocar-pagamento/${ni}/${nirf}/${numeroCT}/${numeroPagamento}/${numeroComponente}?valor-alocar=${valorAlocar}&confirma=S`;
      return this.httpClient.put<void>(url, {});
  }

  public liberarAlocacaoAutomaticaPagamento(nirf: string,
                                            numeroPagamento: string): Observable<void> {
    const url = `${this.url}/cobranca/ccitr/impedimento-alocacao-automatica-pagamento/${nirf}/${numeroPagamento}`;
    return this.httpClient.delete<void>(url);
  }

  public alterarQtdQuotas(ni: string, nirf: string, ct: string, qtdQuotas: number): Observable<void> {
    const url = `${this.url}/cobranca/ccitr/demonstrativo-quotas/${ni}/${nirf}?numero-ct=${ct}&qtde-quotas=${qtdQuotas}&confirmacao=S`;
    return this.httpClient.put<void>(url, { responseType: 'text' as const });
  }
}
