import { SituacaoFiscalDto } from "src/app/sitfis/model/situacao-fiscal-dto";
import { OrigemPendenciaPessoaFisica } from "src/app/sitfis/model/situacao-fiscal-pf-dto";
import { OrigemPendenciaPessoaJuridica } from "src/app/sitfis/model/situacao-fiscal-pj-dto";
import { TipoNI } from "./enum/tipo-ni";

export interface Vinculo extends SituacaoFiscalDto{
    getDescricao(): string;
    getPendenciasExistentes(): any[];
}

export class VinculoPessoaFisica implements Vinculo {

    constructor(public cpf: string, public pendenciasExistentes: OrigemPendenciaPessoaFisica[]) { }

    public tipoNI = TipoNI.CPF;

    getDescricao(): string {
        return this.cpf;
    }
    getPendenciasExistentes(): OrigemPendenciaPessoaFisica[]{
        return this.pendenciasExistentes;
    }
}

export class VinculoPessoaJuridica implements Vinculo {

    constructor(public cnpj: string, public pendenciasExistentes: OrigemPendenciaPessoaJuridica[]) { }
    public tipoNI = TipoNI.CNPJ;
    
    getDescricao(): string {
        return this.cnpj;
    }

    getPendenciasExistentes(): OrigemPendenciaPessoaJuridica[]{
        return this.pendenciasExistentes;
    }

}