<fieldset #fs [ngClass]="{ 'service-container': !atendimento}">
  <suite-rfb-alert-messages-with-datetime *ngIf="!atendimento" [onlyFor]="scopeId">
  </suite-rfb-alert-messages-with-datetime>

  <div style="position: relative; z-index: 99;">
    <a *ngIf="!atendimento" after-title href="javascript:void(0)" title="Fechar" (click)="onFecharModal()">
      <span
        class="material-icons icon-18"
        style="font-size: 28px; position: absolute; right: 0;">
        close
      </span>
    </a>
  </div>

  <div [ngClass]="{ 'modal-container': !atendimento }">
    <ng-container *ngIf="atendimento">
      <ng-container *ngTemplateOutlet="resultados"></ng-container>
    </ng-container>

    <ng-container *ngIf="!atendimento">
      <suite-rfb-collapse [title]="titulo">
        <ng-container *ngTemplateOutlet="resultados"></ng-container>
      </suite-rfb-collapse>
    </ng-container>
  </div>
</fieldset>

<ng-template #resultados>
  <via-obrigatoriedade-dirf-pagamentos-table *ngIf="pagamentos; else loadingOrError;"
    [cnpj]="cnpj"
    [anoCalendario]="anoCalendario"
    [pagamentos]="pagamentos">
  </via-obrigatoriedade-dirf-pagamentos-table>

  <via-obrigatoriedade-dirf-dctfs-table *ngIf="dctfs"
    [cnpj]="cnpj"
    [anoCalendario]="anoCalendario"
    [dctfs]="dctfs">
  </via-obrigatoriedade-dirf-dctfs-table>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>

  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
