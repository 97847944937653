import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isValidNi } from 'src/app/shared/directives/ni-validator/ni-validator.directive';
import { DetalheSocio } from '../model/detalhe-socio';

@Component({
  selector: 'via-quadro-societario-table',
  templateUrl: './quadro-societario-table.component.html',
  styleUrls: ['./quadro-societario-table.component.css']
})
export class QuadroSocietarioTableComponent {

  @Input() detalheSocio: DetalheSocio;
  @Input() incluiHistorico: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  podeExibirNI(ni: string): boolean {
    return isValidNi(ni);
  }
}
