
<div class="contorno-ccitr">
  <div class="row">
    <div class="col-md-6">
      Valores em Abertos
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Receita
      </label>
      <p class="form-control-static dado-formulario">
        {{ componente.valorAbertoReceita | formatCurrency }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Multa
      </label>
      <p class="form-control-static dado-formulario">
        {{ componente.valorAbertoMulta | formatCurrency }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Juros
      </label>
      <p class="form-control-static dado-formulario">
        {{ componente.valorAbertoJuros | formatCurrency }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-offset-3 col-md-6">
      <div class="table-responsive" style="width: 460">
        <table suiteRfbTable>
          <caption>Total de Valores Consolidados</caption>
          <thead>
            <tr>
              <th class="text-center min-width">Receita</th>
              <th class="text-center min-width">Multa</th>
              <th class="text-center min-width">Juros</th>
              <th class="text-center min-width">Total</th>
            </tr>
          </thead>
          <tbody>
              <tr class="vertical-align-middle">
                <td class="text-center">
                  {{ componente.saldoConsolidadoReceita | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ componente.saldoConsolidadoMulta | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ componente.saldoConsolidadoJuros | formatCurrency }}
                </td>
                <td class="text-center">
                  {{ componente.saldoConsolidadoTotal | formatCurrency }}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
