import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DirpfService } from './dirpf.service';
import { DirpfDTO } from './model/dirpf';

@Component({
  selector: 'via-dirpf',
  templateUrl: './dirpf.component.html',
  styleUrls: ['./dirpf.component.css'],
  providers: [ScopedMessageService]
})
export class DirpfComponent implements OnInit {

  @Input() cpf: string;

  dirpfs: DirpfDTO[];

  scopeId: string;
  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private dirpfService: DirpfService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterDirpfs();
    }
  }

  private obterDirpfs(): void {
    this.executouObterDados = true;

    this.dirpfService.obterDirpfs(this.cpf).subscribe(
      response => {
        this.dirpfs = response;
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum registro encontrado');
        }
        this.stopLoading = true;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro durante a consulta à DIRPF', error);
        this.initError = error;
      }
    );
  }
}
