import { Component, Input, OnInit } from '@angular/core';
import _, { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheHomonimo } from '../../models/detalhe-homonimo';
import { InformacoesComplementaresService } from '../../services/informacoes-complementares.service';

@Component({
  selector: 'via-homonimos',
  templateUrl: './homonimos.component.html',
  styleUrls: ['./homonimos.component.css'],
  providers: [ScopedMessageService]
})
export class HomonimosComponent implements OnInit {

  @Input() cpf: string;

  titulo = 'Homônimos';

  homonimosPorTipo: { [key: string]: DetalheHomonimo[] };

  scopeId: string;
  stopLoading = false;
  initError: Error;

  private executouObterDados = false;

  constructor(
    private informacoesComplementaresService: InformacoesComplementaresService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterHomonimos();
    }
  }

  get homonimosPerfeitos(): DetalheHomonimo[] {
    return this.homonimosPorTipo?.['HOMONIMO_PERFEITO'];
  }

  get homonimosMaisQuePerfeitos(): DetalheHomonimo[] {
    return this.homonimosPorTipo?.['HOMONIMO_MAIS_QUE_PERFEITO'];
  }

  get homonimosImperfeitos(): DetalheHomonimo[] {
    return this.homonimosPorTipo?.['HOMONIMO_IMPERFEITO'];
  }

  private obterHomonimos(): void {
    this.executouObterDados = true;

    this.informacoesComplementaresService.obterHomonimos(this.cpf)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
          }

          this.homonimosPorTipo = _.groupBy(response, 'tipoHomonimia');

          this.stopLoading = true;
        },
        error => {
          this.messageService.showErrorException("Ocorreu um erro durante a consulta dos homônimos", error);
          this.initError = error;
        }
      );
  }
}
