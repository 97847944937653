<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Competência</th>
        <th class="text-center min-width">FPAS</th>
        <th class="text-center">Situação</th>
        <th class="text-center">Rubrica</th>
        <th class="text-center">Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of grupoDebito.debitosRubricas | currentPage: currentPage;" class="vertical-align-middle">
        <td class="text-center">{{ item.dataCompetenciaDivergencia | dataPAExercicioPipe: tipoPA }}</td>
        <td class="text-center text-nowrap">{{ item.codigoFpas }}</td>
        <td class="text-left">{{ item.situacao }}</td>
        <td class="text-left">{{ item.rubrica }}</td>
        <td class="text-right">{{ item.valor | formatCurrency }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="5">
          <via-pagination [itemAmount]="grupoDebito.debitosRubricas.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
