import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ParcelamentoSIEFParDTO } from '../model/parcelamento-siefpar';

@Component({
  selector: 'via-parcelamento-siefpar-table',
  templateUrl: 'parcelamento-siefpar-table.component.html',
  styleUrls: ['parcelamento-siefpar-table.component.css']
})
export class ParcelamentoSIEFParTableComponent {

  @Input() parcelamentos: ParcelamentoSIEFParDTO[];

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;  

  pageSize: number = 20;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }  

}