import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultaPfModule } from '../consulta-pf/consulta-pf.module';
import { FiltrosPesquisaPfSuiteRfbModule } from './filtros-pesquisa-pf-suite-rfb.module';
import { FiltrosPesquisaPfComponent } from './filtros-pesquisa-pf.component';
import { PesquisaCpfComponent } from './pesquisa-cpf/pesquisa-cpf.component';
import { PesquisaDadosBasicosComponent } from './pesquisa-dados-basicos/pesquisa-dados-basicos.component';
import { PesquisaTituloEleitorComponent } from './pesquisa-titulo-eleitor/pesquisa-titulo-eleitor.component';

@NgModule({
  declarations: [
    FiltrosPesquisaPfComponent,
    PesquisaCpfComponent,
    PesquisaDadosBasicosComponent,
    PesquisaTituloEleitorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SharedModule,
    FiltrosPesquisaPfSuiteRfbModule,
    ConsultaPfModule
  ],
  exports: [
    FiltrosPesquisaPfComponent
  ],
  providers: [
    BsModalRef
  ]
})
export class FiltrosPesquisaPfModule { }
