import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction } from 'src/app/shared/model/feature';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ParametrosPesquisaCcitr } from "../../../../models/parametros-pesquisa-ccitr";
import { CcitrImoveisDoContribuinteComponent } from '../imoveis-do-contribuinte/ccitr-imoveis-do-contribuinte.component';
import { CriterioSelecaoCcitr } from './../../../../models/parametros-pesquisa-ccitr';

@Component({
  templateUrl: './filtros-pesquisa-ccitr.component.html',
  styleUrls: ['./filtros-pesquisa-ccitr.component.css']
})
export class FiltrosPesquisaCcitrComponent implements IDynamicPanel {

  titulo = 'Cobrança - CCITR - Consultar';

  ni: string;

  exibeResultado: boolean = false;
  filtroColapsado: boolean = false;
  desabilitaBotaoBuscar: boolean = false;

  initError: Error;

  formGroup: FormGroup;

  parametros: ParametrosPesquisaCcitr;
  @ViewChild('buscar') botaoBuscar: ElementRef;

  nirfName: string = 'nirf';
  cpfName: string = 'cpf';
  cnpjName: string = 'cnpj';
  cnpjBasicoName: string = 'cnpjBasico';
  exercicioDeName: string = 'exercicioDe';
  exercicioAteName: string = 'exercicioAte';
  tipoLancamentoName: string = 'tipoLancamento';
  criterioSelecaoName: string = "criterioSelecao";
  criterioMarcacaoName: string = 'criterioMarcacao';

  radioSelected: string;

  readonly criterioSelecaoMaed = CriterioSelecaoCcitr.CREDITOS_SOMENTE_MAED;
  readonly criterioSelecaoTodos = CriterioSelecaoCcitr.CREDITOS_TODOS;
  readonly criterioMarcacaoVencimento = false;

  constructor(
    private formBuilder: FormBuilder,
    private dynamicModalRequest: DynamicModalRequestService) { }

  init(ni: string): void {

    let cpf: string = '';
    let cnpj: string = '';
    let cnpjBasico: string = '';
    let disableAllControls: boolean = false;

    if (ni) {
      if (ni.length > 11) {
        cnpj = FormatCnpjPipe.formatCNPJ(ni);
        this.ni = cnpj;
      } else if (ni.length == 8) {
        cnpjBasico = FormatCnpjPipe.formatCNPJ(ni);
        this.ni = cnpjBasico;
      } else {
        cpf = FormatCpfPipe.formatCPF(ni);
        this.ni = cpf;
      }
      disableAllControls = true;
    }

    this.formGroup = this.formBuilder.group({
      nirf: [''],
      cpf: [cpf],
      cnpj: [cnpj],
      cnpjBasico: [cnpjBasico],
      exercicioDe: [''],
      exercicioAte: [''],
      tipoLancamento: [CriterioSelecaoCcitr.CREDITOS_TODOS],
      criterioMarcacao: ['']
    });
    this.radioSelected = CriterioSelecaoCcitr.CREDITOS_TODOS;

    if (disableAllControls) {
      this.enableDisableControls(disableAllControls);
    } else {
      this.desabilitaBotaoBuscar = this.disableBotaoBuscar();
      this.formGroup.valueChanges.subscribe(
        (status) => {
          this.enableDisableControls();
        }
      );
    }
  }

  enableDisableControls(disableAll: boolean = false) {
    if (!disableAll) {
      if (this.disableCpf()) this.cpfCtrl.disable({ emitEvent: false })
      else this.cpfCtrl.enable({ emitEvent: false });

      if (this.disableCnpj()) this.cnpjCtrl.disable({ emitEvent: false })
      else this.cnpjCtrl.enable({ emitEvent: false });

      if (this.disableCnpjBasico()) this.cnpjBasicoCtrl.disable({ emitEvent: false })
      else this.cnpjBasicoCtrl.enable({ emitEvent: false });

      if (this.disableNirf()) this.nirfCtrl.disable({ emitEvent: false })
      else this.nirfCtrl.enable({ emitEvent: false });

      this.desabilitaBotaoBuscar = this.disableBotaoBuscar();

    } else {
      this.cpfCtrl.disable({ emitEvent: false });
      this.cnpjCtrl.disable({ emitEvent: false });
      this.cnpjBasicoCtrl.disable({ emitEvent: false });
    }
  }

  disableNirf(): boolean {
    return (this.cpf?.length > 0 || this.cnpj?.length > 0 || this.cnpjBasico?.length > 0);
  }

  disableCpf(): boolean {
    return (this.cnpj?.length > 0 || this.cnpjBasico?.length > 0 || (this.nirf?.length > 0));
  }

  disableCnpj(): boolean {
    return (this.cpf?.length > 0 || this.cnpjBasico?.length > 0 || (this.nirf?.length > 0));
  }

  disableCnpjBasico(): boolean {
    return (this.cpf?.length > 0 || this.cnpj?.length > 0 || (this.nirf?.length > 0));
  }

  disableBotaoBuscar(): boolean {
    return (this.cpf == null || this.cpf?.length == 0)
      && (this.cnpj == null || this.cnpj?.length == 0)
      && (this.cnpjBasico == null || this.cnpjBasico?.length == 0)
      && (this.nirf == null || this.nirf?.length < 8);
  }

  private get nirf(): string {
    const maskedNirf = this.formGroup.get(this.nirfName).value as string;
    return maskedNirf?.replace(/[^\d]+/g, '');
  }

  get nirfCtrl(): FormControl {
    return this.formGroup.get(this.nirfName) as FormControl;
  }

  private get cpf(): string {
    const maskedCpf = this.formGroup.get(this.cpfName).value as string;
    return maskedCpf?.replace(/[^\d]+/g, '');
  }

  get cpfCtrl(): FormControl {
    return this.formGroup.get(this.cpfName) as FormControl;
  }

  private get cnpj(): string {
    const maskedCnpj = this.formGroup.get(this.cnpjName).value as string;
    return maskedCnpj?.replace(/[^\d]+/g, '');
  }

  get cnpjCtrl(): FormControl {
    return this.formGroup.get(this.cnpjName) as FormControl;
  }

  private get cnpjBasico(): string {
    const maskedCnpjBasico = this.formGroup.get(this.cnpjBasicoName).value as string;
    return maskedCnpjBasico?.replace(/[^\d]+/g, '');
  }

  get cnpjBasicoCtrl(): FormControl {
    return this.formGroup.get(this.cnpjBasicoName) as FormControl;
  }

  private get exercicioDe(): string {
    return this.formGroup.get(this.exercicioDeName).value as string;
  }

  private get exercicioAte(): string {
    return this.formGroup.get(this.exercicioAteName).value as string;
  }

  private get tipoLancamento(): string {
    return this.formGroup.get(this.tipoLancamentoName).value as string;
  }

  private get criterioMarcacao(): string {
    return this.formGroup.get(this.criterioMarcacaoName).value as string;
  }

  private get cnpjPreenchido(): string {
    return (this.cnpj ? this.cnpj : this.cnpjBasico);
  }

  private get indicadorCnpjBasico(): string {
    return (this.cnpjBasico ? '1' : '0');
  }

  private get vencidosAVencer(): string {
    return (this.criterioMarcacao ? '1' : '0');
  }

  onSubmit(): void {
    this.parametros = {
      nirf: this.nirf,
      cpfContribuinte: this.cpf,
      cnpjContribuinte: this.cnpjPreenchido,
      indicadorCnpjBasico: this.indicadorCnpjBasico,
      tipoLancamento: this.tipoLancamento,
      vencidosAVencer: this.vencidosAVencer,
      exercicioInicial: this.exercicioDe,
      exercicioFinal: this.exercicioAte,
      atendimento: this.ni ? true : false
    }

    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
    } else {
      let feature = new FeatureAction();
      feature.class = CcitrImoveisDoContribuinteComponent;
      feature.parametros = this.parametros;
      this.dynamicModalRequest.abrirModal(feature);
    }
  }

  public esconderResultado(): void {
    this.exibeResultado = false;
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  onReset() {
    if (!this.ni) {
      this.formGroup.get(this.cpfName)?.reset();
    }

    this.formGroup.get(this.nirfName)?.reset();
    this.formGroup.get(this.cpfName)?.reset();
    this.formGroup.get(this.cnpjName)?.reset();
    this.formGroup.get(this.cnpjBasicoName)?.reset();
    this.formGroup.get(this.exercicioAteName)?.reset();
    this.formGroup.get(this.criterioMarcacaoName)?.setValue(false);
    this.formGroup.get(this.exercicioDeName)?.setValue('');
    this.formGroup.get(this.exercicioAteName)?.setValue('');
    this.formGroup.get(this.tipoLancamento)?.setValue(CriterioSelecaoCcitr.CREDITOS_TODOS);
  }
}
