<suite-rfb-collapse [title]="titulo">
    <loading-animation *ngIf="!debitosAgrupados && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaDebitos>
    <div class="container-fluid" *ngFor="let debitosByNi of debitosAgrupados | keyvalue; last as isLast">
        <div class="debito-divida">
            <div style="float:left"><suite-rfb-toggle #rowToggle></suite-rfb-toggle></div>
            <div>{{ debitosByNi.key }}</div>            
        </div>
        <ng-container *ngIf="rowToggle.show">
            <div class="debito-divida-conteudo">
                <via-debito-divida-table 
                    [debitos]="debitosByNi.value" 
                    [isPessoaJuridica]="isPessoaJuridica"
                    [isPessoaFisica]="isPessoaFisica" 
                    [isAtivos]="isAtivos" 
                    [isSuspensos]="isSuspensos">
                </via-debito-divida-table>
            </div>
        </ng-container>
    </div>
</ng-template>