export interface SocioEmpresa {
  cnpj: string;
  inCpfCnpjSocio: string;
  niSocio: string;
  qualificacaoSocio: string;
  dataInclusao: string;
  capitalSocial: number;
  cpfRepresentanteLegal: string;
  qualificacaoRepresentanteLegal: string;
  pais: string;
  nomeSocio: string;
}

export interface SocioEmpresaHistorico {
  cnpj: string;
  inCpfCnpjSocio: string;
  niSocio: string;
  qualificacaoSocio: string;
  dataInclusao: string;
  dataExclusao: string;
  capitalSocial: number;
  cpfRepresentanteLegal: string;
  qualificacaoRepresentanteLegal: string;
  pais: string;
  nomeSocio: string;
}

export enum TipoSocio {
  SocioEstrangeiro,
  SocioPessoaJuridica,
  SocioPessoaFisica
}
