import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DeclaracaoIrpfMalhaService } from 'src/app/sitfis/components/declaracao-irpf-malha/declaracao-irpf-malha.service';
import { DeclaracaoIrpfMalhaDTO } from 'src/app/sitfis/components/declaracao-irpf-malha/model/declaracao-irpf-malha';

@Component({
  selector: 'via-consulta-malha-irpf',
  templateUrl: './malha-irpf.component.html',
  styleUrls: ['./malha-irpf.component.css'],
  providers: [ScopedMessageService]
})
export class MalhaIrpfComponent implements OnInit {

  @Input() cpf: string;

  titulo = 'Malha IRPF';

  declaracoes: DeclaracaoIrpfMalhaDTO[];

  scopeId: string;
  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private messageService: ScopedMessageService,
    private malhaIrpfService: DeclaracaoIrpfMalhaService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean) {
    if (!collapsed && !this.executouObterDados) {
      this.obterDeclaracoesMalhaIrpf();
    }
  }

  onEmitirExtrato(declaracao: DeclaracaoIrpfMalhaDTO): void {
    this.abrirUrl(this.malhaIrpfService.getUrlExtratoIrpf(this.cpf, declaracao));
  }

  onAbrirPortalIrpf(): void {
    this.abrirUrl(this.malhaIrpfService.getUrlPortalIrpf());
  }

  onAbrirPortalSiefWeb(): void {
    this.abrirUrl(this.malhaIrpfService.getUrlPortalSiefWeb());
  }

  private obterDeclaracoesMalhaIrpf(): void {
    this.executouObterDados = true;
    this.malhaIrpfService.obterDeclaracoesIRPFMalha(this.cpf).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum resultado encontrado');
          this.stopLoading = true;
        }

        this.declaracoes = response;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter as declarações em Malha', error);
        this.initError = error;
      });
  }

  private abrirUrl(url: string): void {
    window.open(url, "_blank");
  }
}
