/*
 * ngx-suite-rfb
 *
 * A biblioteca ngx-suite-rfb implementa um conjunto de componentes,
 * diretivas, pipes e serviços nativos do Angular com o objetivo de
 * promover o reuso e facilitar a implementação de aplicações frontend
 * que serão implantandas na Suíte RFB e, portanto, devem seguir os
 * Padrões de Interface Visual RFB.
 *
 * Documentação disponível em: https://dedat.gitpages.serpro/deat3/ngx-suite-rfb/
 *
 */

import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'via-tab',
  templateUrl: './tab.component.html',
  styleUrls: [ './tab.component.scss' ]
})

export class ViaTabComponent implements OnInit {

  @Input() name: string;
  @Input() active: boolean;
  @Input() disabled: boolean;

  @Input() closeable = false;
  @Input() data: any;
  @Input() tabSelectBody: TemplateRef<any>;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  public select(): void {
    if (!this.disabled && !this.active) {
      this.active = true;
      this.activeChange.emit(this.active);
    }
  }

  public unselect(): void {
    if (!this.disabled && this.active) {
      this.active = false;
      this.activeChange.emit(this.active);
    }
  }

}
