import { Component, ViewEncapsulation } from '@angular/core';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { DebitoAguia, GrupoDebitoAguia } from '../../model/debitoAguia';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { AguiaService } from './aguia.service';

@Component({
  selector: 'app-aguia',
  templateUrl: './aguia.component.html',
  styleUrls: ['./aguia.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class AguiaComponent implements IDynamicPanel {

  initError: Error;
  titulo: string;
  gruposDebito : GrupoDebitoAguia[];

  constructor(
    private aguiaService: AguiaService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
  ) {
    console.log('AguiaComponent.constructor');
  }



  init(ni: string) {
    console.log('AguiaComponent.ngOnInit NI', ni);
    const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
    const protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
    console.log('AguiaComponent.ngOnInit vai obter Dados de Débitos AGUIA, protocolo:', ni);
    try {
      this.aguiaService.obterDebitosAguia(ni, protocolo).subscribe(
        (resp) => {
          this.gruposDebito = this.agruparOrdenar(resp);
          console.log('AguiaComponent.ngOnInit recuperou dados de Débitos AGUIA', this.gruposDebito);
        },
        (err) => {
          this.initError = err;
          console.log('Ocorreu um erro: ', err); // TODO: implementar tratamento de erros
          this.messageService.showErrorException('Ocorreu um erro ao obter Débitos AGUIA', err);
        }
      );
    } catch (e) {
      this.initError = e;
      console.log('Ocorreu um erro: ', e); // TODO: implementar tratamento de erros
      this.messageService.showErrorException('Ocorreu um erro ao obter os dados de Débitos AGUIA', e);
    }
  }

  agruparOrdenar(debitos: DebitoAguia[]): GrupoDebitoAguia[] {
    let gruposDebito : GrupoDebitoAguia[] = [];
    debitos.forEach(debito => {
      let grupo: GrupoDebitoAguia = gruposDebito.find(d => d.ni == debito.ni);
      if(!grupo) {
        grupo = {
          ni: debito.ni,
          tipoNi: debito.tipoNi,
          debitosRubricas:[]
        }
        gruposDebito.push(grupo);
      }
      debito.rubricas.forEach( rubrica => {
        grupo.debitosRubricas.push(
          {
            dataCompetenciaDivergencia: debito.dataCompetenciaDivergencia,
            situacao: debito.situacao,
            codigoFpas: debito.codigoFpas,
            rubrica: rubrica.rubrica,
            valor: rubrica.valor,
          }
        )
      });
      grupo.debitosRubricas = grupo.debitosRubricas.sort((a,b) => a.dataCompetenciaDivergencia.localeCompare(b.dataCompetenciaDivergencia));
    });
    gruposDebito = gruposDebito.sort( (a, b) => a.ni.localeCompare(b.ni));
    return gruposDebito;
  }
}

@Component({
    selector: 'app-aguia',
    templateUrl: './aguia.component.html',
    styleUrls: ['./aguia.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AguiaPFComponent extends AguiaComponent {
  init(ni: string) {
    this.titulo = `Débitos Ativos Previdenciários AGUIA - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
    selector: 'app-aguia',
    templateUrl: './aguia.component.html',
    styleUrls: ['./aguia.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AguiaPJComponent extends AguiaComponent {
  init(ni: string) {
    this.titulo = `Débitos Ativos Previdenciários AGUIA - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}
