import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ViaExpandedMenuComponent } from './components/expanded-menu/expanded-menu.component';
import { ViaMenuItemComponent } from './components/menu-item/menu-item.component';
import { ViaMenuTogglerComponent } from './components/menu-toggler/menu-toggler.component';
import { ViaMenuComponent } from './components/menu/menu.component';
import { ViaMenuSuiteRfbModule } from './menu-suite-rfb.module';
import { ViaMenuConfigService } from './services/menu-config.service';
import { ViaMenuService } from './services/menu.service';

@NgModule({
  declarations: [
    ViaMenuTogglerComponent,
    ViaMenuComponent,
    ViaMenuItemComponent,
    ViaExpandedMenuComponent
  ],
  imports: [
    CommonModule,
    ViaMenuSuiteRfbModule
  ],
  exports: [
    ViaMenuTogglerComponent,
    ViaMenuComponent,
    ViaMenuItemComponent,
    ViaExpandedMenuComponent
  ],
  providers: [
    ViaMenuConfigService,
    ViaMenuService
  ]
})
export class ViaMenuModule { }
