import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DebitoAutomatico } from 'src/app/cobranca/models/debito-automatico';
import { EventoCreditoTributario } from 'src/app/cobranca/models/evento-credito-tributario';
import { environment } from 'src/environments/environment';
import { CreditoTributario } from '../../../models/credito-tributario';
import { MotivoTransferencia } from '../../../models/motivo-transferencia';
import { ParametrosPesquisaCreditosTributarios } from '../../../models/parametros-pesquisa';
import { DebitoAlocado, Pagamento } from '../components/pagamento/models/pagamento';
import { ParametrosFiltroPagamentoCcpf } from '../components/pagamento/models/parametros-pesquisa';

@Injectable()
export class CCPFService {

  private readonly TAMANHO_NUMERO_PAGAMENTO = 17;

  private url = environment.APIEndpoint;

  public atualizarListagemEmitter: EventEmitter<ParametrosPesquisaCreditosTributarios> = new EventEmitter();

  public notificarAtualizacaoListaCreditosEmitter: EventEmitter<CreditoTributario[]> = new EventEmitter();

  constructor(private httpClient: HttpClient) { }

  public atualizarListagemCreditos(parametros: ParametrosPesquisaCreditosTributarios): void {
    this.atualizarListagemEmitter.emit(parametros);
  }

  public notificarAtualizacaoListaCreditos(creditos: CreditoTributario[]): void {
    this.notificarAtualizacaoListaCreditosEmitter.emit(creditos);
  }

  public consultarCreditosTributarios(parametros: ParametrosPesquisaCreditosTributarios, ehDebito: boolean): Observable<CreditoTributario[]> {

    let cpfContribuinte = parametros.cpfContribuinte;
    let criterioSelecao = parametros.criterioSelecao;
    let exercicio = parametros.exercicio ?? '';
    let exercicioFinal = parametros.exercicioFinal ?? '';

    const url = `${this.url}/cobranca/ccpf/creditos-tributarios/${cpfContribuinte}/${criterioSelecao}/exercicio?exercicio-inicial=${exercicio}&exercicio-final=${exercicioFinal}&eh-debito=${ehDebito}`;
    return this.httpClient.get<CreditoTributario[]>(url);
  }

  public consultarPagamentos(parametros: ParametrosFiltroPagamentoCcpf): Observable<Pagamento[]> {

    let cpfContribuinte = parametros.cpfContribuinte;
    let dataArrecadacaoInicial = formatDate(parametros.dataArrecadacaoInicial, 'yyyyMMdd', 'pt');
    let dataArrecadacaoFinal = formatDate(parametros.dataArrecadacaoFinal, 'yyyyMMdd', 'pt');
    let numeroDebito = parametros.ct;

    const url = `${this.url}/cobranca/ccpf/pagamentos/${cpfContribuinte}/${numeroDebito}/${dataArrecadacaoInicial}/${dataArrecadacaoFinal}`;
    return this.httpClient.get<Pagamento[]>(url);
  }

  public impedirAlocacaoAutomatica(cpfContribuinte: string, numeroPagamento: string): Observable<string> {
    const numeroPagamentoAjustado = numeroPagamento.padStart(this.TAMANHO_NUMERO_PAGAMENTO, '0');
    const url = `${this.url}/cobranca/ccpf/impedir-pagamento/${cpfContribuinte}/${numeroPagamentoAjustado}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public liberarAlocacaoAutomatica(cpfContribuinte: string, numeroPagamento: string): Observable<string> {
    const numeroPagamentoAjustado = numeroPagamento.padStart(this.TAMANHO_NUMERO_PAGAMENTO, '0');
    const url = `${this.url}/cobranca/ccpf/liberar-pagamento/${cpfContribuinte}/${numeroPagamentoAjustado}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public consultarEventosCreditoTributario(cpf: string, numeroCT: string, dataInicial: string, dataFinal: string) {
    let url: string;
    if (numeroCT) {
      url = dataInicial ?
        `${this.url}/cobranca/ccpf/eventos-credito-tributario/${cpf}/credito/${numeroCT}/${dataInicial}/${dataFinal}` :
        `${this.url}/cobranca/ccpf/eventos-credito-tributario/${cpf}/credito/${numeroCT}`;
    }
    else {
      url = dataInicial ?
        `${this.url}/cobranca/ccpf/eventos-credito-tributario/${cpf}/${dataInicial}/${dataFinal}` :
        `${this.url}/cobranca/ccpf/eventos-credito-tributario/${cpf}`;
    }
    return this.httpClient.get<EventoCreditoTributario[]>(url);
  }

  public suspenderCreditoTributario(cpf: string, numeroDebito: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/suspender-credito-tributario/${cpf}/${numeroDebito}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public reativarCreditoTributario(cpf: string, numeroDebito: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/reativar-credito-tributario/${cpf}/${numeroDebito}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public alocarPagamento(cpfContribuinte: string, numeroPagamento: string, numeroDebito: string, numeroComponente: string, valorAlocacao: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/alocar-pagamento/${cpfContribuinte}/${numeroPagamento}/${numeroDebito}/${numeroComponente}/${valorAlocacao}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public solicitarRetornoContribuinteDepurado(cpf: string): Observable<any> {
    const url = `${this.url}/cobranca/ccpf/retorno-contribuinte/${cpf}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public transferirCredito(cpfContribuinte: string, numeroDebito: string, codigoTransferencia: string, numeroProcessoPerdcomp: string, descricaoMotivoOutros: string): Observable<string> {
    let url: string;

    if (numeroProcessoPerdcomp) {
      url = descricaoMotivoOutros
        ? `${this.url}/cobranca/ccpf/transferir-credito/${cpfContribuinte}/${numeroDebito}/${codigoTransferencia}/processo/${numeroProcessoPerdcomp}/${descricaoMotivoOutros}`
        : `${this.url}/cobranca/ccpf/transferir-credito/${cpfContribuinte}/${numeroDebito}/${codigoTransferencia}/processo/${numeroProcessoPerdcomp}`;
    } else {
      url = descricaoMotivoOutros
        ? `${this.url}/cobranca/ccpf/transferir-credito/${cpfContribuinte}/${numeroDebito}/${codigoTransferencia}/${descricaoMotivoOutros}`
        : `${this.url}/cobranca/ccpf/transferir-credito/${cpfContribuinte}/${numeroDebito}/${codigoTransferencia}`;
    }

    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public obterDebitoAutomatico(cpf: string, exercicio: number): Observable<DebitoAutomatico> {
    const url = `${this.url}/cobranca/ccpf/consultar-debito-automatico/${cpf}/${exercicio}`;
    return this.httpClient.get<DebitoAutomatico>(url);
  }

  public recuperarMotivosTransferenciaCT(): Observable<MotivoTransferencia[]> {
    const url = `${this.url}/cobranca/ccpf/transferir-credito/motivos-transferencia`;
    return this.httpClient.get<MotivoTransferencia[]>(url);
  }

  public incluirDebitoAutomatico(cpf: string, numeroBanco: string, numeroAgencia: string, numeroContaCorrente: string, dvContaCorrente: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/incluir-debito-automatico/${cpf}/${numeroBanco}/${numeroAgencia}/${numeroContaCorrente}/${dvContaCorrente}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public alterarDebitoAutomatico(cpf: string, numeroBanco: string, numeroAgencia: string, numeroContaCorrente: string, dvContaCorrente: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/alterar-debito-automatico/${cpf}/${numeroBanco}/${numeroAgencia}/${numeroContaCorrente}/${dvContaCorrente}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public cancelarDebitoAutomatico(cpf: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/cancelar-debito-automatico/${cpf}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public alterarQtdCotas(cpf: string, numeroDebito: string, qtdCotas: number): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/alterar-qtd-cotas-credito-tributario/${cpf}/${numeroDebito}/${qtdCotas}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public revalidarDataCt(cpf: string, numeroDebito: string, numeroComponente: string, novaDataVencimento: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/revalidar-data-credito-tributario/${cpf}/${numeroDebito}/${numeroComponente}/${novaDataVencimento}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public desalocarPagamento(cpfContribuinte: string, numeroDebito: string, numeroComponente: string, numeroPagamento: string): Observable<string> {
    const url = `${this.url}/cobranca/ccpf/desalocar-pagamento/${cpfContribuinte}/${numeroDebito}/${numeroComponente}/${numeroPagamento}`;
    return this.httpClient.get(url, { responseType: 'text' as const });
  }

  public obterPagamentosParaAlocacao(cpfContribuinte: string): Observable<Pagamento[]> {
    const url = `${this.url}/cobranca/ccpf/pagamentos-alocacao/${cpfContribuinte}`;
    return this.httpClient.get<Pagamento[]>(url);
  }

  public obterDebitosAlocadosPorPagamento(cpfContribuinte: string, numeroPagamento: string): Observable<DebitoAlocado[]> {
    const url = `${this.url}/cobranca/ccpf/consultar-debitos-alocados/${cpfContribuinte}/${numeroPagamento}`;
    return this.httpClient.get<DebitoAlocado[]>(url);
  }

}
