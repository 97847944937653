import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule } from '@serpro/ngx-suite-rfb';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { DocumentosService } from './services/documentos.service';

@NgModule({
  declarations: [
    DocumentosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SuiteRfbCollapseModule
  ],
  exports: [
    DocumentosComponent
  ],
  providers: [
    BsModalRef,
    DocumentosService
  ]
})
export class DocumentosModule { }
