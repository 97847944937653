<div class="table-responsive">
  <table suiteRfbTable>
    <caption style="font-size: small">{{ subtitulo }}</caption>
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">NI</th>
        <th class="text-center">Nome</th>
        <th class="text-center">Motivo</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let impedimento of impedimentos | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <ni-com-link [ni]="impedimento.ni" [tipoNI]="tipoNI"></ni-com-link>
          </td>
          <td class="text-left">{{ impedimento.nome }}</td>
          <td class="text-left">{{ impedimento.motivo }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="4">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-3">
                    <label class="label-formulario control-label">Processo</label>
                    <p class="form-control-static dado-formulario">
                      {{ impedimento.numeroProcesso }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label class="label-formulario control-label">Município</label>
                    <p class="form-control-static dado-formulario">
                      {{ impedimento.municipio }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">UF</label>
                    <p class="form-control-static dado-formulario">
                      {{ impedimento.uf }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="impedimentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
