<suite-rfb-collapse [title]="titulo">
    <loading-animation *ngIf="!irregularidades && !initError; else listaIrregularidades;"></loading-animation>
</suite-rfb-collapse>
<ng-template #listaIrregularidades>
    <!-- pre>irregularidades: {{ irregularidades | json }}</pre -->
    <ul>
        <li *ngFor="let irregularidade of irregularidades">
          <span class="control-label">{{ irregularidade.descricao }}</span>
        </li>
    </ul>
</ng-template>
