import { Inject, Injectable } from "@angular/core";
import { SuiteRfbModuleConfig, SUITE_RFB_MODULE_CONFIG } from "@serpro/ngx-suite-rfb";

@Injectable({
  providedIn: 'root'
})
export class ViaMenuConfigService {

  constructor(@Inject(SUITE_RFB_MODULE_CONFIG) private config: SuiteRfbModuleConfig) { }

  public isExpandedMenuHierarchical(): boolean {
    return this.config && this.config.expandedMenuHierarchical;
  }
}
