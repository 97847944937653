import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "@serpro/ngx-suite-rfb";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from 'rxjs';
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { environment } from 'src/environments/environment';
import { Darf } from "../../model/darf";
import { ParametrosDarf } from "../../model/parametros-darf";
import { DarfMensagensComponent } from "./darf-mensagens.component";
import { DarfVisualizacaoComponent } from "./darf-visualizacao.component";
import FileSaver from 'file-saver';

@Injectable()
export class DarfService {

    constructor(
        private messageService: ScopedMessageService,
        private modalService: BsModalService,
        private http: HttpClient,
        private loadingService: LoadingService,
    ) {
    }

    private baseUrl = environment.APIEndpoint;

    emitirDarf (parametro: ParametrosDarf, mensagem: number) {
        let bsModalRef;
        if(mensagem != null && mensagem >0){
            DarfMensagensComponent.prototype.mensagem = mensagem;
            bsModalRef = this.modalService.show(DarfMensagensComponent);
            bsModalRef.content.resultado.subscribe(
                resultado =>{
                    bsModalRef.hide();
                    if (resultado == "emitir") {
                        this.exibirDarf(parametro);
                    }
                }
            )
        } else {
            this.exibirDarf(parametro);
        }
    }

    exibirDarf(parametro: ParametrosDarf) {
        this.loadingService.show();
        this.gerarDarf(parametro).subscribe(
            (darf: Darf) => {
              this.loadingService.hide();
              if(window['navigator']['pdfViewerEnabled']) {
                DarfVisualizacaoComponent.prototype.darf = darf;
                let bsModalRef = this.modalService.show(DarfVisualizacaoComponent,{class: 'darf-dialog'});
                bsModalRef.content.bsModalRef = bsModalRef;
              }
              else {
                const byteCharacters = atob(darf.pdf);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });
                FileSaver.saveAs(blob, `DARF-${parametro.numeroProcesso}.pdf`);
              }
            },
            (erro) => {
                this.loadingService.hide();
                this.messageService.showErrorException('Ocorreu um erro ao gerar o DARF', erro);
            }
        );
    }

    public gerarDarf(parametrosDarf: ParametrosDarf): Observable<Darf>{
		const url = `${this.baseUrl}/situacoesfiscais/darf`;
		return this.http.post<Darf>(url, parametrosDarf);
    }
}
