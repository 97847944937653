<div class="table-responsive">
  <table suiteRfbTable>
    <caption class="caption-ccpf">Débitos Alocados</caption>
    <ng-container *ngIf="alocacoes?.length > 0; else nenhumaAlocacao;">
      <thead>
        <tr *ngIf="!atendimento; else templateHeaderAtendimento;">
          <th class="text-center min-width">Número</th>
          <th class="text-center min-width">Exercício</th>
          <th class="text-center min-width">Tipo Lançamento</th>
          <th class="text-center min-width break-spaces">Data Vencimento</th>
          <th class="text-center min-width break-spaces">Data Alocação</th>
          <th class="text-center">Valor Original</th>
          <th class="text-center">Situação</th>
          <th class="text-center min-width">Tipo Alocação</th>
          <th class="text-center break-spaces">Data Original</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let alocacao of alocacoes | currentPage: currentPage;">
          <tr *ngIf="!atendimento; else templateAlocacaoAtendimento;" class="vertical-align-middle">
            <td class="text-center">
              {{ alocacao.numero | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.exercicio | vazio }}
            </td>
            <td class="text-left">
              {{ alocacao.tipoLancamento | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.dataVencimento | formatDataPipe | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.dataAlocacao | formatDataPipe | vazio }}
            </td>
            <td class="text-right">
              {{ alocacao.valorOriginal | formatCurrency | vazio }}
            </td>
            <td class="text-left">
              {{ alocacao.situacao | vazio }}
            </td>
            <td class="text-left">
              {{ alocacao.tipoAlocacao | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.dataOriginal | formatDataPipe | vazio }}
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="10">
            <via-pagination [itemAmount]="alocacoes.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </ng-container>
  </table>
</div>

<ng-template #nenhumaAlocacao>
  <tr class="vertical-align-middle">
    <td class="text-center" colspan="10">
      Não há alocações para este pagamento.
    </td>
  </tr>
</ng-template>


