import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LogService } from 'src/app/shared/services/log.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { CriterioSelecaoCcitr, ParametrosPesquisaCcitr } from "../../../../models/parametros-pesquisa-ccitr";
import { FeatureAction } from 'src/app/shared/model/feature';
import { CcitrImoveisDoContribuinteComponent } from '../imoveis-do-contribuinte/ccitr-imoveis-do-contribuinte.component';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { CCITRService } from '../../services/ccitr.service';
import { CcitrBase } from '../../base/CcitrBase.component';
import { CONSTANTS } from '../../constants';

@Component({
  selector: 'via-ccitr-impedir-alocacao-automatica-pagamento',
  templateUrl: './ccitr-impedir-alocacao-automatica-pagamento.component.html',
  styleUrls: ['./ccitr-impedir-alocacao-automatica-pagamento.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]})

export class CcitrImpedirAlocacaoAutomaticaPagamentoComponent extends CcitrBase implements IDynamicPanel, OnInit {
  @Input() parametros: ParametrosPesquisaCcitr;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  titulo = 'CCITR - Impedimento de Alocação Automática';

  ni: string;
  nirf: string;
  numeroPagamento: string;
  atendimentoInformado: boolean;

  scopeId: string;
  initError: Error;

  exibeResultado: boolean = false;
  filtroColapsado: boolean = false;

  formGroup: FormGroup;

  motivoName: string =  'motivo';

  constructor(
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalService: DynamicModalRequestService,
    private cCITRService: CCITRService ) {
    super();
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  init(_ni: string, params?: OptionalParams): void {
    this.nirf = params.nirf;
    this.numeroPagamento = params.numeroPagamento;

    this.formGroup = this.formBuilder.group({
      motivo: ['', [Validators.required]],
    });
  }

  private get motivo(): string {
    return this.formGroup.get(this.motivoName).value as string;
  }

  get motivoCtrl(): FormControl {
    return this.formGroup.get(this.motivoName) as FormControl;
  }

  onSubmit(): void {
    this.cCITRService.impedirAlocacaoAutomaticaPagamento(this.nirf, this.numeroPagamento, this.motivo)
    .subscribe(
      _sucess => {
        console.log(_sucess);
        this.messageService.showInfo("Impedimento de alocação automática de pagamento realizado com sucesso");
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          console.error(err);
          this.onError.emit();
          this.messageService.showErrorException("Ocorreu um erro ao impedir a alocação automática de pagamento", err);
        }
      }
    );
  }

  onFecharModal(): void {
    this.bsModalRef.hide();
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

}
