import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { DebitoContaCorrenteItr } from '../model/debito-conta-corrente-itr';

@Component({
  selector: 'via-conta-corrente-itr-table',
  templateUrl: 'conta-corrente-itr-table.component.html',
  styleUrls: ['conta-corrente-itr-table.component.css']
})
export class ContaCorrenteItrTableComponent {

  @Input() debitos: DebitoContaCorrenteItr[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  @Input() nomeTributo: string;
  @Input() podeEmitirDARF: boolean;

  @Output() emitirDarf = new EventEmitter<DebitoContaCorrenteItr>();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 20;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  onEmitirDarf(debito: DebitoContaCorrenteItr) {
    this.emitirDarf.emit(debito);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}
