<div class="modal-header">
    <h4 class="modal-title pull-left">Atenção</h4>
    <span title="Fechar" aria-hidden="true" class="pull-right material-icons icon-24 cursor" (click)="responder('fechar')">close</span>
</div>
<div class="modal-body">
  <span *ngIf="mensagem == 1">
    O DARF será emitido com cálculo de acréscimos legais para pagamento até o
    último dia útil do mês. Caso o vencimento tenha ocorrido há menos de 60 dias e o contribuinte queira
    efetuar o pagamento antes do último dia útil do mês, emitir o DARF no sistema de origem.
  </span>
  <span *ngIf="mensagem == 2">
      Emissão de DARF Numerado, temporariamente, indisponível pelo VIA. Emitir pelo
      Situação Fiscal Web (e-CAC) ou Sief.
  </span>
  <span *ngIf="mensagem == 3">
    DARF/DAS não disponível para impressão.
  </span>
  <span *ngIf="mensagem == 4">
     Documento de arrecadação impedido de emissão/consolidação.
  </span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="responder('emitir')" *ngIf="mensagem == 1">Emitir</button>
    <button type="button" class="btn btn-default" (click)="responder('fechar')" *ngIf="mensagem == 1">Cancelar</button>
    <button type="button" class="btn btn-default" (click)="responder('fechar')" *ngIf="mensagem != 1">Fechar</button>
</div>
