import { Component } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';

@Component({
  templateUrl: './filtros-pesquisa-receita.component.html',
  styleUrls: ['./filtros-pesquisa-receita.component.css']
})
export class FiltrosPesquisaReceitaComponent implements IDynamicPanel {

  titulo = 'Consultas Gerais - Consultar - Filtros de Pesquisa';

  initError: Error;
  init(): void {
  }

}
