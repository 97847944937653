import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CnpjsVinculadosSuiteRfbModule } from './cnpjs-vinculados-suite-rfb.module';
import { CnpjsVinculadosComponent } from './cnpjs-vinculados.component';
import { CnpjsVinculadosService } from './cnpjs-vinculados.service';
import { CnpjsVinculadosTableComponent } from './table/cnpjs-vinculados-table.component';

@NgModule({
  declarations: [
    CnpjsVinculadosComponent,
    CnpjsVinculadosTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    CnpjsVinculadosSuiteRfbModule
  ],
  exports: [
    CnpjsVinculadosTableComponent
  ],
  providers: [
    CnpjsVinculadosService
  ],
  entryComponents: [
    CnpjsVinculadosComponent
  ]
})
export class CnpjsVinculadosModule { }
