<suite-rfb-collapse [title]="titulo" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="cnpjsVinculados?.length > 0; else loadingOrError;">
    <via-cnpjs-vinculados-table [cnpjsVinculados]="cnpjsVinculados"></via-cnpjs-vinculados-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
