import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParcelamentoSisparSuiteRfbModule } from './parcelamento-sispar-suite-rfb.module';
import { ParcelamentoSisparComponent, ParcelamentoSisparPFCobrancaComponent, ParcelamentoSisparPFExigibilidadeComponent, ParcelamentoSisparPJCobrancaComponent, ParcelamentoSisparPJExigibilidadeComponent } from './parcelamento-sispar.component';
import { ParcelamentoSisparService } from './parcelamento-sispar.service';
import { ParcelamentoSisparTableComponent } from './table/parcelamento-sispar-table.component';

@NgModule({
  declarations: [
    ParcelamentoSisparComponent,
    ParcelamentoSisparTableComponent,
    ParcelamentoSisparPFCobrancaComponent,
    ParcelamentoSisparPFExigibilidadeComponent,
    ParcelamentoSisparPJCobrancaComponent,
    ParcelamentoSisparPJExigibilidadeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    ParcelamentoSisparSuiteRfbModule
  ],
  providers: [
    ParcelamentoSisparService
  ],
  entryComponents: [
    ParcelamentoSisparPFCobrancaComponent,
    ParcelamentoSisparPFExigibilidadeComponent,
    ParcelamentoSisparPJCobrancaComponent,
    ParcelamentoSisparPJExigibilidadeComponent
  ]
})
export class ParcelamentoSisparModule { }
