import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ProcessoProfisc } from '../model/processo-profisc';
import { DEBITO_PROFISC_PF_ATIVO, DEBITO_PROFISC_PF_SUSPENSO } from '../../situacao-fiscal-pf.features';
import { DEBITO_PROFISC_PJ_ATIVO, DEBITO_PROFISC_PJ_SUSPENSO } from '../../situacao-fiscal-pj.features';
import _ from 'lodash';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';

@Component({
  selector: 'via-processo-profisc-table',
  templateUrl: 'processo-profisc-table.component.html',
  styleUrls: ['processo-profisc-table.component.css']
})
export class ProcessoProfiscTableComponent {

  @Input() ni: string;

  @Input() processos: ProcessoProfisc[];
  @Input() parametros: any;
  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private menuFeatureActionsService: MenuFeatureActionsService
    ) { }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  onExibirDebitos(processo: ProcessoProfisc): void {
    let feature: FeatureAction;
    let tipoMenu = this.parametros?.tipoMenu;

    if (this.isPessoaFisica && this.isAtivos) {
      feature = _.clone(DEBITO_PROFISC_PF_ATIVO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAFISICA;
    }
    else if (this.isPessoaFisica && this.isSuspensos) {
      feature = _.clone(DEBITO_PROFISC_PF_SUSPENSO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAFISICA;
    }

    else if (this.isPessoaJuridica && this.isAtivos) {
      feature = _.clone(DEBITO_PROFISC_PJ_ATIVO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAJURIDICA;
    }

    else if (this.isPessoaJuridica && this.isSuspensos) {
      feature = _.clone(DEBITO_PROFISC_PJ_SUSPENSO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAJURIDICA;
    }

    feature.id = 'debito_' + processo.numeroProcesso;
    feature.parametros = {
      ni: this.ni,
      numeroProcesso: processo.numeroProcesso,
      idFeaturePrincipal: this.parametros.idFeature,
      tipoMenu: feature.tipoMenu
    }

    this.menuFeatureActionsService.activate(feature);      
  }
}

