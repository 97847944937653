<div class="panel pagination">
  <div class="panel-heading">
    <h3 class="panel-title">{{ panelTitle }}</h3>
  </div>

  <div class="panel-body">
    <div class="row">
      <ng-container *ngFor="let feature of features">
        <div class="col-md-4" *ngIf="feature.visible">
          <ng-container *ngIf="!expandedMenuHierarchical">
            <suite-rfb-collapse [title]="feature.title" [showIcon]="false">
              <ul>
                <ng-container *ngTemplateOutlet="featureTemplate; context: { feature: feature }"></ng-container>
              </ul>
            </suite-rfb-collapse>
          </ng-container>

          <ng-container *ngIf="expandedMenuHierarchical">
            <ng-container *ngTemplateOutlet="featureTemplate; context: { feature: feature }"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #featureTemplate let-feature="feature">
  <ng-container *ngIf="!hasFeatures(feature)">
    <li>
      <a class="cursor" *ngIf="feature.url" href="javascript:void(0)" (click)="onClickFeature(feature)">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { feature: feature }"></ng-container>
      </a>

      <a class="cursor" *ngIf="!feature.url" (click)="onClickFeature(feature)">
        <ng-container *ngTemplateOutlet="itemTemplate; context: { feature: feature }"></ng-container>
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="hasFeatures(feature)">
    <suite-rfb-collapse *ngIf="expandedMenuHierarchical" [title]="feature.title" [showIcon]="false">
      <ul>
        <ng-container *ngTemplateOutlet="childrenTemplate; context: { feature: feature }"></ng-container>
      </ul>
    </suite-rfb-collapse>

    <ng-container *ngIf="!expandedMenuHierarchical">
      <ng-container *ngTemplateOutlet="childrenTemplate; context: { feature: feature }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #childrenTemplate let-feature="feature">
  <ng-container *ngFor="let childFeature of feature.children">
    <ng-container *ngTemplateOutlet="featureTemplate; context: { feature: childFeature }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #itemTemplate let-feature="feature">
  <span>{{ feature.title }}</span>
</ng-template>
