import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { ComprotSuiteRfbModule } from './comprot-suite-rfb.module';
import { ComprotComponent } from './components/comprot.component';
import { ComprotTableComponent } from './components/table/comprot-table.component';
import { DetalheProcessoComprot } from './components/detalhe-processo/comprot-detalhe-processo';
import { ComprotService } from './services/comprot.service';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		HttpClientModule,
		SharedModule,
		PipesModule.forRoot(),
		ComprotSuiteRfbModule,
	],
	declarations: [
		ComprotComponent,
		ComprotTableComponent,
		DetalheProcessoComprot
	],
	exports: [
		ComprotComponent,
		ComprotTableComponent,
		DetalheProcessoComprot
	],
	providers: [
		DatePipe,
		ComprotService
	]
})
export class ComprotModule { }
