import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { TributosAcaoFiscal } from '../model/tributo-acao-fiscal';
import { AcaoFiscalService } from '../services/acao-fiscal.service';

@Component({
  selector: 'via-acao-fiscal',
  templateUrl: './acao-fiscal.component.html',
  styleUrls: ['./acao-fiscal.component.css'],
  providers: [ScopedMessageService]
})
export class AcaoFiscalComponent implements OnInit {

  @Input() cpf: string;

  @Input() cnpj: string;

  ni: string;

  tributos: TributosAcaoFiscal;

  scopeId: string;

  initError: Error;

  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private acaoFiscalService: AcaoFiscalService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    if (null == this.cpf) {
      this.ni = this.cnpj;
    } else {
      this.ni = this.cpf;
    }
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterTributos();
    }
  }

  private obterTributos(): void {
    this.executouObterDados = true;
    this.acaoFiscalService.obterTributosAcaoFiscal(this.ni)
      .subscribe(
        response => {
          this.tributos = response;

          if (this.tributos && isEmpty(this.tributos.tributos)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }
        },
        error => {
          this.messageService.showErrorException("Ocorreu um erro durante a consulta a Ação Fiscal", error);
          this.initError = error;
        }
      );
  }
}
