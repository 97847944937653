import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeclaracaoIrpfMalhaDTO } from './model/declaracao-irpf-malha';

@Injectable()
export class DeclaracaoIrpfMalhaService {
  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterQuantidadeDeclaracoesIRPFMalha(cpf: string): Observable<number> {
    const url = `${this.baseUrl}/declaracoes-irpf/${cpf}/em-malha/quantidade`;
    return this.http.get<number>(url);
  }

  public obterDeclaracoesIRPFMalha(cpf: string): Observable<DeclaracaoIrpfMalhaDTO[]> {
    const url = `${this.baseUrl}/declaracoes-irpf/${cpf}/em-malha`;
    return this.http.get<DeclaracaoIrpfMalhaDTO[]>(url);
  }

  public getUrlExtratoIrpf(cpf: string, declaracao: DeclaracaoIrpfMalhaDTO): string {
    return `${environment.urlExtratoIrpf}/${cpf}/${declaracao.anoExercicio}/${declaracao.numero}`;
  }

  public getUrlPortalIrpf(): string {
    return environment.urlPortalIrpf;
  }

  public getUrlPortalSiefWeb(): string {
    return environment.urlPortalSiefWeb;
  }
}
