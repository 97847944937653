<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitos && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>

<ng-template #tabelaDebitos>
  <via-debito-profisc-table
    [ni]="ni"
    [numeroProcesso]="numeroProcesso"
    [debitos]="debitos"
    [isAtivos]="isAtivos"
    (emitirDarf)="emitirDarf($event)">
  </via-debito-profisc-table>
</ng-template>
