import { Component, ViewEncapsulation } from '@angular/core';
import _ from 'lodash';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParametrosDarf, SistemaOrigemDarf } from '../../model/parametros-darf';
import { DarfService } from '../darf/darf.service';
import { CCPFService } from './ccpf.service';
import { DebitoContaCorrentePF } from './model/debito-ccpf';

interface DebitosCCPFGroupedByName {
  [nomeTributo: string]: DebitoContaCorrentePF[];
}

@Component({
  selector: 'via-ccpf',
  templateUrl: './ccpf.component.html',
  styleUrls: ['./ccpf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CCPFComponent implements IDynamicPanel {

  titulo: string;
  tipoPendencia: TipoPendencia;
  numeroIdentificador: string;
  initError: Error;
  debitos: DebitosCCPFGroupedByName;
  protocolo: string;

  constructor(
    private ccpfService: CCPFService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private darfService: DarfService) { }

  public init(cpf: string): void {
    this.numeroIdentificador = cpf;
    const infosContribuinte = this.atendimentoService.getInfosAtendimento(cpf);
    this.protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
    try {
      this.ccpfService.obterDebitosContaCorrentePF(cpf, this.protocolo, this.tipoPendencia)
        .subscribe(
          (res) => {
            res.forEach(r => r.tipoPendencia = this.tipoPendencia);
            const debitosOrdenados = _.orderBy(res, ['codigoReceita', 'numeroDebito'], ['asc', 'desc']);
            this.debitos = _.groupBy(debitosOrdenados, 'nomeTributo');
          },
          (err) => {
            this.initError = err;
            this.messageService.showErrorException('Ocorreu um erro ao obter os debitos CCPF', err);
          }
        );
    } catch (e) {
      this.initError = e;
      this.messageService.showErrorException('Ocorreu um erro ao obter os debitos CCPF', e);
    }
  }

  public regularizarDebito(debito: DebitoContaCorrentePF): void {
    console.log('Regularizar para Débito: ', debito);
    this.messageService.showWarning('Regularizar Débito', 'Esta funcionalidade ainda não foi implementada.');
  }

  emitirDarf(debito: DebitoContaCorrentePF) {
    const parametros = this.preencherParametros(debito);
    let msg = 0;
    this.darfService.emitirDarf(parametros, msg);
  }

  preencherParametros(debito: DebitoContaCorrentePF): ParametrosDarf {
    const parametros: ParametrosDarf = {
      protocolo: this.protocolo,
      tipoNi: TipoNI.CPF,
      ni: this.numeroIdentificador,
      sistemaOrigem: SistemaOrigemDarf.CCPF,
      numeroDebito: debito.numeroDebito,
      numeroProcesso: '',
      numeroImovel: '',
      codigoReceita: '',
      numeroComponente: '',
      exercicio: '',
    }
    return parametros;
  }

  public emitirDarfExercicio(debito: DebitoContaCorrentePF): void {
    console.log('Emitir DARF Exercício para Débito: ', debito);
    this.messageService.showWarning('Emitir DARF Exercício', 'Esta funcionalidade ainda não foi implementada.');
  }
}

@Component({
  selector: 'via-ccpf',
  templateUrl: './ccpf.component.html',
  styleUrls: ['./ccpf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CCPFCobrancaComponent extends CCPFComponent {

  public init(cpf: string): void {
    this.titulo = `Débitos Ativos Fazendários Conta Corrente - CPF: ${FormatCpfPipe.formatCPF(cpf)}`;
    this.tipoPendencia = TipoPendencia.COBRANCA;
    super.init(cpf);
  }
}

@Component({
  selector: 'via-ccpf',
  templateUrl: './ccpf.component.html',
  styleUrls: ['./ccpf.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CCPFExigibilidadeComponent extends CCPFComponent {

  public init(cpf: string): void {
    this.titulo = `Débitos Suspensos Fazendários Conta Corrente - CPF: ${FormatCpfPipe.formatCPF(cpf)}`;
    this.tipoPendencia = TipoPendencia.EXIGIBILIDADE_SUSPENSA;
    super.init(cpf);
  }
}
