import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComprovantePagamentoVisualizacaoComponent } from '../components/comprovante-visualizacao/comprovante-visualizacao.component';
import { ComprovantePagamento } from '../models/comprovante-pagamento';
import { DocumentoArrecadacao } from '../models/documento-arrecadacao';

@Injectable()
export class PagamentoComprovanteService {

  private url = environment.APIEndpoint;

  constructor(
    private httpClient: HttpClient,
    private modalService: BsModalService) { }

    public obterComprovantePagamento(documentoArrecadacao: DocumentoArrecadacao): Observable<ComprovantePagamento> {
      const url = `${this.url}/pagamentos/comprovante-pagamento`;
      return this.httpClient.post<ComprovantePagamento>(url, documentoArrecadacao);
    }
  

  public obterComprovanteRetificacao(documentoArrecadacao: DocumentoArrecadacao): Observable<ComprovantePagamento> {
    const url = `${this.url}/pagamentos/comprovante-retificacao`;
    return this.httpClient.post<ComprovantePagamento>(url, documentoArrecadacao);
  }

  public visualizarComprovante(comprovante: ComprovantePagamento, titulo: string): void {
    const bsModalRef = this.modalService.show(ComprovantePagamentoVisualizacaoComponent, {
      class: 'comprovante-dialog',
      initialState: {
        comprovante,
        titulo
      }
    });

    bsModalRef.content.bsModalRef = bsModalRef;
  }
}
