<div class="container-fluid" *ngIf="resultadosPesquisa">
  <via-parametros-pesquisa-titulo-eleitor
    [parametrosPesquisa]="parametrosPesquisa">
  </via-parametros-pesquisa-titulo-eleitor>

  <div class="table-responsive">
    <table suiteRfbTable>
      <caption>Resultados da Pesquisa</caption>
      <thead>
        <tr>
          <th class="text-center min-width">Título de Eleitor</th>
          <th class="text-center">Nome</th>
          <th class="text-center min-width">Data de Nascimento</th>
          <th class="text-center">Nome da Mãe</th>
          <th class="text-center min-width">Situação</th>
          <th class="text-center min-width">Data de Atualização</th>
        </tr>
      </thead>
      <tbody>
        <tr class="vertical-align-middle" *ngFor="let titulo of resultadosPesquisa | currentPage: currentPage">
          <td class="text-left">
            <span
              class="link"
              container="body"
              tooltip="Consultar os dados cadastrais"
              href="javascript:void(0)"
              (click)="onConsultarDadosCadastrais(titulo.numeroTitulo);">
              {{ titulo.numeroTitulo | vazio }}
            </span>
          </td>
          <td class="text-left">{{ titulo.nome | vazio }}</td>
          <td class="text-center">{{ titulo.dataNascimento | outputMask: '99/99/9999' | vazio }}</td>
          <td class="text-left">{{ titulo.nomeMae | vazio }}</td>
          <td class="text-center">{{ titulo.descricaoSituacao | vazio }}</td>
          <td class="text-center">{{ titulo.dataAtualizacao | outputMask: '99/99/9999' | vazio }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="6">
            <via-pagination [itemAmount]="resultadosPesquisa.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
