import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ProcessoSief } from 'src/app/sitfis/model/processo-sief';
import { DEBITO_PROCESSO_SIEF_PF_ATIVO, DEBITO_PROCESSO_SIEF_PF_SUSPENSO } from '../../situacao-fiscal-pf.features';
import { DEBITO_PROCESSO_SIEF_PJ_ATIVO, DEBITO_PROCESSO_SIEF_PJ_SUSPENSO } from '../../situacao-fiscal-pj.features';
import _ from 'lodash';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';

@Component({
  selector: 'via-processo-sief-table',
  templateUrl: './processo-sief-table.component.html',
  styleUrls: ['./processo-sief-table.component.css']
})
export class ProcessoSiefTableComponent {

  @Input() ni: string;

  @Input() processos: ProcessoSief[];
  @Input() parametros: any;

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private menuFeatureActionsService: MenuFeatureActionsService
  ) { }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(detalhe => detalhe.show = show);
  }

  onExibirDebitos(processo: ProcessoSief): void {
    let feature: FeatureAction;
    let tipoMenu = this.parametros?.tipoMenu;


    if (this.isPessoaFisica && this.isAtivos) {
      feature = _.clone(DEBITO_PROCESSO_SIEF_PF_ATIVO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAFISICA;
    }

    else if (this.isPessoaFisica && this.isSuspensos) {
      feature = _.clone(DEBITO_PROCESSO_SIEF_PF_SUSPENSO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAFISICA;
    }

    else if (this.isPessoaJuridica && this.isAtivos) {
      feature = _.clone(DEBITO_PROCESSO_SIEF_PJ_ATIVO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAJURIDICA;
    }

    else if (this.isPessoaJuridica && this.isSuspensos) {
      feature = _.clone(DEBITO_PROCESSO_SIEF_PJ_SUSPENSO);
      feature.tipoMenu = tipoMenu ?? TipoMenu.PESSOAJURIDICA;
    }

    feature.id = 'debito_'+processo.identificadorProcesso;
    feature.parametros = {
      ni: this.ni,
      numeroProcesso: processo.numeroProcesso,
      identificadorProcesso: processo.identificadorProcesso,
      idFeaturePrincipal: this.parametros.idFeature,
      tipoMenu: feature.tipoMenu
    };

    this.menuFeatureActionsService.activate(feature);      
  }
}
