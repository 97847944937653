<suite-rfb-collapse [title]="titulo" [collapsed]="false">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <via-ccitr-table-componentes-credito [componentes]="componentes"></via-ccitr-table-componentes-credito>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label [for]="qtdQuotasName" class="control-label">Nova Quantidade de Quotas</label>
                <input
                  class="form-control set-width"
                  type="text"
                  [formControlName]="qtdQuotasName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>

            <div class="col-md-4" style="margin-top: 25px;">
              <div class="form-group">
                <div class="text-center">
                  <button
                    type="reset"
                    class="btn btn-default"
                    [disabled]="!formGroup.dirty">
                    Limpar
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="formGroup.invalid"
                    (click)="onAlterarQtdQuotas()">
                    Alterar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</suite-rfb-collapse>

