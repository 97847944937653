import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CnpjService } from 'src/app/cnpj/services/cnpj.service';
import { CpfService } from 'src/app/cpf/services/cpf.service';
import { FormatCpfCnpjPipe } from 'src/app/shared/pipes/formata-cpf-cnpj.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ViaTabComponent, ViaTabSetComponent } from 'src/app/shared/tabs/public_api';
import { Ni } from '../../../shared/model/ni';
import { TabData } from './model/tab-data';
import { PainelAtendimentosService } from './painel-atendimentos.service';

//TODO: provavelmente é melhor unificar 'Aba', 'TabData' e 'Ni' em uma unica estrutura 'Atendimento'
// e passar como @Input para o ViaTabComponent e para o AtendimentoComponent...
type Aba = {
  ni: Ni;
  active: boolean;
  drawingAttention: boolean;
};

@Component({
  selector: 'via-painel-atendimentos',
  templateUrl: './painel-atendimentos.component.html',
  styleUrls: ['./painel-atendimentos.component.css']
})
export class PainelAtendimentosComponent implements OnInit, OnDestroy {
  @ViewChild(ViaTabSetComponent) tabSet:ViaTabSetComponent;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private imagemPadraoPJ = 'assets/img/business.png';
  private imagemPadraoPF = 'assets/img/person.png';

  // idenfificador do NI em atendimento
  ni: string;
  // conjunto das abas em atendimento
  tabs: Aba[] = [];

  constructor(
    private painelAtendimentosService: PainelAtendimentosService,
    private cpfService: CpfService,
    private cnpjService: CnpjService,
    private logService: LogService) { }

  public ngOnInit(): void {
    // registra um callback quando uma aba é ativada
    this.painelAtendimentosService.atendimentosEvent$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(event => {
        if (event.tipo === 'tab.create') {
          this.createTab(event.dados); // dados = ni em atendimento
        }
        if (event.tipo === 'tab.activate') {
          this.activateTab(event.dados); // dados = nome da aba
        }
        else if (event.tipo === 'tab.cancelaProcessamento') {
          this.cancelaProcessamentoAtendimento(event.dados); // dados = ni da aba
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  tabDataFromAba(aba: Aba): TabData {
    return {
      ni: aba.ni.ni,
      texts: [FormatCpfCnpjPipe.format(aba.ni.ni)],
      img: aba.ni.foto,
      drawingAttention: aba.drawingAttention
    } as TabData;
  }

  createTab(ni: string) {
    this.ni = ni;
    const tab_found = this.tabs.find(t => (t.ni.ni === this.ni));
    if (tab_found) {
      this.tabs.forEach(t => t.active = false); // desativa as outras abas
      tab_found.active = true;
      this.ni = '';
      this.tabSet.selecionaAtiva(this.tabSet);
      return;
    }

    const niReal = {} as Ni;
    niReal.ni = this.ni;

    if ((this.ni != null) && (this.ni.length == 11)) {

      this.painelAtendimentosService.obterFotoPessoaFisicaEmAtendimento(this.ni).subscribe(
        (resp) => {
          niReal.foto = "data:image/png;charset=utf-8;base64, " + resp.fotoBase64;
          this.logService.logAjaxReturn('PainelAtendimentosComponent', 'obterFotoPessoaFisicaEmAtendimento');
        },
        (err) => {
          this.logService.logAjaxError('PainelAtendimentosComponent', 'obterFotoPessoaFisicaEmAtendimento', err);
        }
      );

      this.cpfService.obterDadosResumidos(this.ni).subscribe(
        (resp) => {
          niReal.nome = resp.nome;

          const tab = this.tabs.find(t => t.ni.ni === this.ni);
          if (tab) {
            tab.ni = niReal;
          }

          this.logService.logAjaxReturn('PainelAtendimentosComponent', 'obterDadosResumidos');
        },
        (err) => {
          this.logService.logAjaxError('PainelAtendimentosComponent', 'obterDadosResumidos', err);
        }
      );

      niReal.nome = ''; // provisório
      niReal.foto = this.imagemPadraoPF; // provisório
    }
    else {
      this.cnpjService.obterDadosEmpresa(this.ni).subscribe(
        response => {
          niReal.nome = response.nomeEmpresarial;

          const tab = this.tabs.find(t => t.ni.ni === this.ni);
          if (tab) {
            tab.ni = niReal;
          }

          this.logService.logAjaxReturn('PainelAtendimentosComponent', 'obterDadosEmpresa');
        },

        error => {
          this.logService.logAjaxError('PainelAtendimentosComponent', 'obterDadosEmpresa', error);
        }
      );

      niReal.nome = '';
      niReal.foto = this.imagemPadraoPJ;
    }
    this.tabs.forEach(t => t.active = false); // desativa as outras abas
    this.addTab(niReal);
  }

  // Adiciona uma nova aba setando os respectivos dados
  private addTab(ni: Ni): void {
    this.tabs.push({ ni, active: true, drawingAttention: false });
  }

  // fechamento de aba
  onTabCloseClick(tab: ViaTabComponent): void {
    const index = this.tabs.map(t=>t.ni.ni).indexOf(tab.data.ni);
    this.tabs = this.tabs.filter(t => t.ni.ni !== tab.data.ni); // filtra somente as outras abas, utilizando o ni
    if (this.tabs.length && tab.active) {
      this.tabs.forEach(t => t.active = false); // desativa as outras abas
      this.tabs[index > 0 ? index - 1 : 0].active = true; // ativa a aba
    }
  }

  onTabMoveClick(evento: {previous: number, current: number}): void {
    moveItemInArray(this.tabs, evento.previous, evento.current);
  }

  // ativa aba pelo nome
  private activateTab(name: string): void {
    this.tabs.forEach(t => t.active = t.ni.nome === name);
  }

  cancelaProcessamentoAtendimento(ni: string): void {
    this.tabs = this.tabs.filter(t => t.ni.ni !== ni);
    if (this.tabs.length) {
      this.tabs.forEach(t => t.active = false);
      this.tabs[0].active = true;
    }
  }

  abaInicializada(ni: string) {
    this.tabs.forEach(t => {
      if (t.ni.ni == ni) {
        if (!t.active) {
          t.drawingAttention = true;
        }
      }
    });
  }

  abaAtivaAlterada(ni: string, active: boolean) {
    this.tabs.forEach(t => {
      if (t.ni.ni == ni) {
        if (active) {
          t.drawingAttention = false;
        }
      }
    });
  }

  copiarNI(event: Event, ni: string) {
    navigator.clipboard.writeText(ni);
    event.stopPropagation();
  }
}

