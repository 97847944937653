export enum SituacaoCadastral {
    IRREGULAR_CPF_CNPJ = 'IrregularCPFCNPJ',
    QSA_AUSENTE = 'QSAAusente',
    IRREGULAR_CPF_RESPONSAVEL = 'IrregularCPFResponsavel',
    CNAE_FISCAL_AUSENTE = 'CNAEFiscalAusente',
    EFR_AUSENTE = 'EFRAusente',
    EFP_AUSENTE = 'EFPAusente',
    SOCIO_IRREGULAR = 'SocioIrregular',
    UA_AUSENTE = 'UAAusente',
    ABERTURA_IRREGULAR = 'AberturaIrregular',
    RAZAO_SOCIAL_AUSENTE = 'RazaoSocialAusente'
}
