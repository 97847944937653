import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { DebitoSief } from 'src/app/sitfis/model/debito-sief';
import { TipoPA } from '../model/tipo-pa';
import { CodigoReceita, OrdemDARF } from 'src/app/sitfis/model/enum/arrecadacao';

@Component({
  selector: 'via-debito-sief-table',
  templateUrl: 'debito-sief-table.component.html',
  styleUrls: ['debito-sief-table.component.css']
})
export class DebitoSiefTableComponent {

  @Input() debitos: DebitoSief[];

  @Input() isPessoaJuridica: boolean;
  @Input() isPessoaFisica: boolean;

  ordemDARF= OrdemDARF;
  codigoReceita= CodigoReceita;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  @Output() emitirDarf = new EventEmitter<DebitoSief>();
  @Output() emitirDas = new EventEmitter<DebitoSief>();

  @ViewChildren('detalhes') detalhesQuery: QueryList<ToggleComponent>;

  tipoPA = TipoPA;
  objectValues = Object.values;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandeTodosDetalhes(show: boolean) {
    this.detalhesQuery.forEach(detalhe => {
      detalhe.show = show;
    });
  }

  onEmitirDarf(debito: DebitoSief): void {
    this.emitirDarf.emit(debito);
  }

  onEmitirDas(debito: DebitoSief): void {
    this.emitirDas.emit(debito);
  }
}
