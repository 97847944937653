<suite-rfb-collapse [title]="titulo">
    <loading-animation *ngIf="!ausenciasDeclaracao && !initError; else tabelaAusencias;"></loading-animation>
</suite-rfb-collapse>

<ng-template #tabelaAusencias>
    <ng-container [ngSwitch]="tipoDeclaracao">
        <via-declaracao-ausente-dctf-table
            *ngSwitchCase="TipoDeclaracao.DCTF"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-dctf-table>

        <via-declaracao-ausente-dirf-table
            *ngSwitchCase="TipoDeclaracao.DIRF"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-dirf-table>

        <via-declaracao-ausente-dirpf-table
            *ngSwitchCase="TipoDeclaracao.DIRPF"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-dirpf-table>

        <via-declaracao-ausente-simei-table
            *ngSwitchCase="TipoDeclaracao.SIMEI"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-simei-table>

        <via-declaracao-ausente-obrap-table
            *ngSwitchCase="TipoDeclaracao.OBRAP"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-obrap-table>

        <via-declaracao-ausente-ditr-table
            *ngSwitchCase="TipoDeclaracao.DITR"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-ditr-table>

        <via-declaracao-ausente-gfip-table
            *ngSwitchCase="TipoDeclaracao.GFIP"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-gfip-table>

        <via-declaracao-ausente-pgdas-table
            *ngSwitchCase="TipoDeclaracao.PGDAS"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-pgdas-table>

        <via-declaracao-ausente-efd-table
            *ngSwitchCase="TipoDeclaracao.EFD"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-efd-table>

        <via-declaracao-ausente-ecf-table
            *ngSwitchCase="TipoDeclaracao.ECF"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-ecf-table>

        <via-declaracao-ausente-defis-table
            *ngSwitchCase="TipoDeclaracao.DEFIS"
            [ausenciasDeclaracao]="ausenciasDeclaracao"
            [isPessoaJuridica]="isPessoaJuridica"
            [isPessoaFisica]="isPessoaFisica"
            [tipoDeclaracao]="tipoDeclaracao"
            [ni]="ni">
        </via-declaracao-ausente-defis-table>
    </ng-container>
</ng-template>
