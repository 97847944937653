
    <div class="container-fluid" >
      <ng-container>
        <div class="container-fluid">
          <div class="table-responsive">
            <table suiteRfbTable [class.tabela_largura_minima]="atendimento">
                <ng-container *ngIf="!semCreditoTributarioEmAberto; else nenhumCreditoTributarioEmAberto;">
                  <thead>
                    <tr>
                      <th class="text-center min-width"></th>
                      <th class="text-center">CT</th>
                      <th class="text-center">Data de Consolidação</th>
                      <th class="text-center">Data de Controle</th>
                      <th class="text-center">Consolidação até</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container class="vertical-align-middle">
                      <tr class="vertical-align-middle">
                        <td>
                          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                        </td>
                        <td class="text-center">{{  numeroCtFormatado | vazio }}</td>
                        <td class="text-center">{{ parametros.dataConsolidacao | formatDataPipe }}</td>
                        <td class="text-center">{{ debito.dataControle | formatDataPipe | vazio }}</td>
                        <td class="text-center">{{ parametros.dataConsolidacao | formatDataPipe }}</td>
                      </tr>
                      <ng-container *ngIf="rowToggle.show">
                        <tr></tr>
                        <tr>
                          <td colspan="10" class="background-inherit">
                            <div class="contorno-ccitr">
                                <via-detalhe-dados-consolidar-ct [debito]="debito">
                                </via-detalhe-dados-consolidar-ct>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </ng-container>
                <ng-template #nenhumCreditoTributarioEmAberto>
                  <tr class="vertical-align-middle">
                    <td class="text-center" colspan="7">
                      Nenhum crédito tributário em aberto.
                    </td>
                  </tr>
                </ng-template>
            </table>
          </div>
          <div class="table-responsive">

                <via-ccitr-componentes-dados-consolidar-ct [componentes]="debito.componentes" [atendimento]="atendimento">
                </via-ccitr-componentes-dados-consolidar-ct>
          </div>
        </div>
      </ng-container>
    </div>



