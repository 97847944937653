import { NgModule } from '@angular/core';

import {
  SuiteRfbTabsModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule,
  SuiteRfbSelectModule,
  SuiteRfbDatePickerModule,
  SuiteRfbFilePickerModule,
  SuiteRfbLoadingModule,
  SuiteRfbMonthPickerModule,
  SuiteRfbConfirmationsModule,
  SuiteRfbCollapseModule,
  SuiteRfbCurrencyModule,
  SuiteRfbPaginationModule,
  SuiteRfbFilterByModule,
  SuiteRfbSortByModule,
  SuiteRfbPopoverModule,
  SuiteRfbTableModule,
  SuiteRfbToggleModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbExpandedMenuModule,
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbTabsModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbSelectModule,
    SuiteRfbDatePickerModule,
    SuiteRfbFilePickerModule,
    SuiteRfbLoadingModule,
    SuiteRfbMonthPickerModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbCollapseModule,
    SuiteRfbCurrencyModule,
    SuiteRfbPaginationModule,
    SuiteRfbFilterByModule,
    SuiteRfbSortByModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbExpandedMenuModule
  ],
  exports: [
    SuiteRfbTabsModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbSelectModule,
    SuiteRfbDatePickerModule,
    SuiteRfbLoadingModule,
    SuiteRfbMonthPickerModule,
    SuiteRfbFilePickerModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbCollapseModule,
    SuiteRfbCurrencyModule,
    SuiteRfbPaginationModule,
    SuiteRfbFilterByModule,
    SuiteRfbSortByModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbExpandedMenuModule
  ]
})
export class SitFisSuiteRfbModule { }
