import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDataPipe'
})
export class FormatDataPipe implements PipeTransform {
  transform(data: string) {
    if (data) {
      return FormatDataPipe.formatData(data);
    }
    return null;
  }
  static formatData: Function = (data: string) => {
    data = data + "";
    if (data.length == 8) {
      return data.substring(6) + "/" + data.substring(4, 6) + "/" + data.substring(0, 4);
    }
    return data;
  }

}


