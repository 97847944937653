<div class="modal-container">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <suite-rfb-collapse [title]="titulo">
    <ng-container *ngIf="resultadosPesquisa; else loadingOrError;">
      <via-parametros-pesquisa-pf
        [parametrosPesquisa]="parametrosPesquisa">
      </via-parametros-pesquisa-pf>

      <div class="table-responsive">
        <table suiteRfbTable>
          <caption>Resultados da Pesquisa</caption>
          <thead>
            <tr>
              <th class="text-center min-width">CPF</th>
              <th class="text-center">Nome</th>
              <th class="text-center">Nome da Mãe</th>
              <th class="text-center min-width">Situação Cadastral</th>
              <th class="text-center min-width">Data de Nascimento</th>
              <th class="text-center min-width">UF</th>
              <th class="text-center">Município</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertical-align-middle" *ngFor="let resultado of resultadosPesquisa | currentPage: currentPage">
              <td class="text-center text-nowrap">
                <span
                  class="link"
                  container="body"
                  tooltip="Consultar os dados cadastrais"
                  href="javascript:void(0)"
                  (click)="onConsultarCpf(resultado.cpfContribuinte);">
                  {{ resultado.cpfContribuinte | formatCpfPipe | vazio }}
              </span>
              </td>
              <td class="text-left">{{ resultado.nomeContribuinte | vazio }}</td>
              <td class="text-left">{{ resultado.nomeMae | vazio }}</td>
              <td class="text-center">{{ resultado.situacaoCadastral | vazio }}</td>
              <td class="text-center">{{ resultado.dataNascimento | outputMask: '99/99/9999' | vazio }}</td>
              <td class="text-center">{{ resultado.ufResidencia | vazio }}</td>
              <td class="text-left">{{ resultado.municipioResidencia | vazio }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="8">
                <via-pagination [itemAmount]="resultadosPesquisa.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
