import { NgModule } from '@angular/core';
import { DataPAExercicioPipe } from './data-pa-exercicio.pipe';
import { InscricaoPgfnPipe } from './inscricao-pgfn.pipe';
import { PorteEmpresaPipe } from './porte-empresa.pipe';
import { SituacaoCndPipe } from './situacaoCnd.pipe';
import { TipoCndPipe } from './tipoCnd';

@NgModule({
    declarations:[
        DataPAExercicioPipe,
        InscricaoPgfnPipe,
        PorteEmpresaPipe,
        TipoCndPipe,
        SituacaoCndPipe,
    ],
    exports: [
        DataPAExercicioPipe,
        InscricaoPgfnPipe,
        PorteEmpresaPipe,
        TipoCndPipe,
        SituacaoCndPipe,
    ]
})
export class SitfisPipesModule { }