<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
        <div class="container-fluid">
          <ng-container *ngIf="eventosCCITR && eventosCCITR.length > 0; else loadingOrError;">
            <div class="table-responsive">
              <table suiteRfbTable [class.tabela_largura_minima]="!this.classeModal">
                <thead>
                  <tr>
                    <th class="text-center min-width">
                      <suite-rfb-toggle
                        title="Expandir/Contrair Todos"
                        (expanded)="expandedAll($event)">
                      </suite-rfb-toggle>
                    </th>
                    <th class="text-center min-width">Data/Hora</th>
                    <th class="text-center">Tipo de Evento</th>
                    <th class="text-center">Tipo</th>
                    <th class="text-center min-width">Exercício</th>
                    <th class="text-center min-width">UA</th>
                    <th class="text-center min-width">Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let evento of eventosCCITR | currentPage: currentPage;"
                    class="vertical-align-middle">
                    <tr class="vertical-align-middle">
                      <td>
                        <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                      </td>
                      <td class="text-center text-nowrap">{{ evento.dataEvento | formatDataHoraPipe }}</td>
                      <td class="text-left">{{ evento.tipo | vazio }}</td><!-- CONFORME VIA ATUAL -->
                      <td class="text-left">{{ evento.tipoEvento | vazio }}</td><!-- CONFORME VIA ATUAL -->
                      <td class="text-center">{{ evento.exercicio | vazio }}</td>
                      <td class="text-right">{{ evento.uaUsuario | vazio }}</td>
                      <td class="text-center text-nowrap">
                        <ng-container
                          *ngIf="!isCpfValido(evento.cpfUsuario)">
                          {{ evento.cpfUsuario | formatCpfPipe | vazio }}
                        </ng-container>
                        <ni-com-link
                          *ngIf="isCpfValido(evento.cpfUsuario)"
                          [ni]="evento.cpfUsuario"
                          tipoNI="CPF">
                        </ni-com-link>
                      </td>
                    </tr>
                    <ng-container *ngIf="rowToggle.show">
                      <tr></tr>
                      <tr>
                        <td colspan="7" class="background-inherit">
                          <div class="contorno-ccitr">
                          <via-detalhe-consulta-eventos [item]="evento"></via-detalhe-consulta-eventos>
                        </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr class="tabela-acoes text-left">
                    <td colspan="10">
                      <via-pagination [itemAmount]="eventosCCITR?.length" [(page)]="currentPage">
                      </via-pagination>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
        </ng-container>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
