import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { Page, ToggleComponent} from '@serpro/ngx-suite-rfb';
import { CcitrBase } from '../../../base/CcitrBase.component';
import { DadosConsolidadoDebitoCCITRDTO } from '../model/dados-consolidado-debito-ccitr';
import { ParametroConsolidarDebitoCCITR } from '../../consulta-creditos/model/parametro-consolidar-debito-ccitr';
import { NumberUtilService } from 'src/app/shared/util/number-util.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'via-ccitr-dados-consolidar-ct',
  templateUrl: './dados-consolidar-ct.component.html',
  styleUrls: ['./dados-consolidar-ct.component.css','../../../base/ccitr.css'],
  providers: [ScopedMessageService],
})
export class ConsultaConsolidarCTComponent extends CcitrBase implements OnInit {

  @Input() parametros: ParametroConsolidarDebitoCCITR;
  @Input() debito: DadosConsolidadoDebitoCCITRDTO;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;
  @Input() atendimento: boolean;

  semCreditoTributarioEmAberto: boolean = false;
  classeModal: string;
  atendimentoInformado: boolean;

  stopLoading = false;
  initError: Error;
  scopeId: string;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  showCloseModal = true;
  cpf: string;
  numeroCtFormatado: string;

  filtroColapsado = false;


  constructor(
    private numberUtilService: NumberUtilService,
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService) {
    super();
  }

  ngOnInit(): void {
    this.initError = null;
    this.numeroCtFormatado = this.numberUtilService.leftZeroes(this.parametros.numeroCT, 9);
    this.semCreditoTributarioEmAberto = (this.debito == null);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

}









