import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DadosBasicosPfDTO } from '../dados-basicos-pf/model/dados-basicos-pf-dto';
import { DadosResumidosPfDTO } from '../dados-basicos-pf/model/dados-resumidos-pf-dto';
import { CadeiaNiService } from './cadeia-ni.service';

@Component({
  selector: 'via-cadeia-ni',
  templateUrl: './cadeia-ni.component.html',
  styleUrls: ['./cadeia-ni.component.css'],
  providers: [ScopedMessageService]
})
export class CadeiaNiComponent implements OnInit {

  @Input() dadosBasicos: DadosBasicosPfDTO;
  dadosResumidosDaCadeiaNiAgrupadosPorSituacao: { [descSituacaoCadastral: string]: DadosResumidosPfDTO[] };
  scopeId: string;

  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private cadeiaNiService: CadeiaNiService,
    private messageService: ScopedMessageService) { }

  ngOnInit() {
    this.scopeId = this.messageService.scopeId;
  }

  // Método para garantir que os dados só serão carregados ao abrir o collapse pela primeira vez
  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterDadosResumidosDaCadeiaNi();
    }
  }

  private obterDadosResumidosDaCadeiaNi(): void {
    this.executouObterDados = true;

    this.cadeiaNiService.registrarEventoCadeiaNi(this.dadosBasicos.cpf).subscribe();

    this.cadeiaNiService.obterDadosResumidosDaCadeiaNi(this.dadosBasicos).subscribe(
      response => {
        this.dadosResumidosDaCadeiaNiAgrupadosPorSituacao =
          response.reduce((r, v, i, a, k = v.descSituacaoCadastral) => ((r[k] || (r[k] = [])).push(v), r), {});

        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum registro encontrado');
        }

        this.stopLoading = true;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter os dados resumidos da cadeia NI', error);
        this.initError = error;
      }
    );
  }
}
