import { Pipe, PipeTransform } from '@angular/core';
import { MunicipioPfDTO } from 'src/app/cpf/components/dados-basicos-pf/model/municipio-pf-dto';

@Pipe({
  name: 'formatMunicipio'
})
export class MunicipioFormatPipe implements PipeTransform {

  public transform(municipio: MunicipioPfDTO): string {
    return municipio.nome + '-' + municipio.uf;
  }
}
