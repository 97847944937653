import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbPaginationModule,
  SuiteRfbPopoverModule,
  SuiteRfbTableModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule
  ]
})
export class DebitoProfiscSuiteRfbModule { }
