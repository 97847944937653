import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { Page } from '@serpro/ngx-suite-rfb';
import { ComponentePagamentoTDA } from '../../../../../models/componente-pagamento-tda';

@Component({
  selector: 'via-ccitr-table-componentes',
  templateUrl: 'consulta-componentes-pagamento-tda-table.component.html',
  styleUrls: ['consulta-componentes-pagamento-tda-table.component.css','../../../base/ccitr.css']
})
export class CcpfConsultaComponentesPagamentoTDATableComponent implements OnInit {

  @Input() componentes: ComponentePagamentoTDA[];

  semComponente: Boolean = false;  

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  ngOnInit(): void {
    this.semComponente = isEmpty(this.componentes) || isEmpty(this.componentes[0].numero);
  }



}