import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { DocumentoArrecadacaoDirf } from 'src/app/obrigatoriedade-dirf/models/documento-arrecadacao-dirf';
import { ConsultaPagamentosComponent } from 'src/app/pagamento/components/consulta-pagamentos/consulta-pagamentos.component';
import { ParametrosPesquisaPagamento } from "src/app/pagamento/models/parametros-pesquisa";
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';

@Component({
  selector: 'via-obrigatoriedade-dirf-pagamentos-table',
  templateUrl: './obrigatoriedade-dirf-pagamentos-table.component.html',
  styleUrls: ['../consulta-obrigatoriedade-dirf.component.css'],
  providers: [ScopedMessageService]
})
export class ObrigatoriedadeDirfPagamentosTableComponent implements OnInit {

  @Input() cnpj: string;
  @Input() anoCalendario: string;
  @Input() pagamentos: DocumentoArrecadacaoDirf[];

  titulo: string;

  scopeId: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private messageService: ScopedMessageService,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;

    this.titulo = `Pagamentos que obrigam a entrega da DIRF do Ano Calendário ${this.anoCalendario}`;

    if (isEmpty(this.pagamentos)) {
      this.messageService.showInfo('Não foram encontrados pagamentos que obrigam a entrega da DIRF.');
    }
  }

  onDetalharPagamento(pagamento: DocumentoArrecadacaoDirf): void {
    const parametrosPesquisa = {
      numeroPagamento: pagamento.numeroPagamentoDocumento
    } as ParametrosPesquisaPagamento;

    const consultaPagamentosFeature = new FeatureAction();
    consultaPagamentosFeature.id = `ConsultaPagamentos_Pagamento_${pagamento.numeroPagamentoDocumento}`;
    consultaPagamentosFeature.class = ConsultaPagamentosComponent;
    consultaPagamentosFeature.parametros = {
                                            parametrosPesquisa: parametrosPesquisa
                                           }

    this.dynamicModalService.abrirModal(consultaPagamentosFeature);
  }
}
