import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NumberUtilService {

    /**
     * Inclui zeros a esquerda ate completar o tamanho especificado.
     * 
     * @param value 
     * @param targetLength 
     */
    public leftZeroes(value: string | number, targetLength: number): string {
        if (value && targetLength) {
            value = value + '';
            return value.padStart(targetLength, '0');
        } else {
            return value + '';
        }
    }

    /**
     * Verifica se o valor informado possui somente digitos.
     * 
     * @param value 
     */
    public onlyDigits(value: string): boolean {
        return value ? /^[\d]*$/.test(value) : false;
    }
}