import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CpfService } from '../../services/cpf.service';
import { CadeiaNiModule } from '../cadeia-ni/cadeia-ni.module';
import { CnpjsVinculadosPfModule } from '../cnpjs-vinculados-pf/cnpjs-vinculados-pf.module';
import { DirfModule } from '../dirf/dirf.module';
import { DirpfModule } from '../dirpf/dirpf.module';
import { DocumentosModule } from '../documentos/documentos.module';
import { DoiModule } from '../doi/doi.module';
import { HistoricoModule } from '../historico/historico.module';
import { InformacoesComplementaresModule } from '../informacoes-complementares/informacoes-complementares.module';
import { MalhaIrpfModule } from '../malha-irpf/malha-irpf.module';
import { OmissosModule } from '../omissos/omissos.module';
import { TituloEleitorService } from '../titulo-eleitor/titulo-eleitor.service';
import { ConsultaDadosBasicosPfModule } from './consulta-dados-basicos-pf/consulta-dados-basicos-pf.module';
import { ConsultaPfSuiteRfbModule } from './consulta-pf-suite-rfb.module';
import { ConsultaPfComponent } from './consulta-pf.component';
import { ConsultaTituloEleitorModule } from './consulta-titulo-eleitor/consulta-titulo-eleitor.module';

@NgModule({
  declarations: [
    ConsultaPfComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    ConsultaPfSuiteRfbModule,
    CnpjsVinculadosPfModule,
    HistoricoModule,
    DirpfModule,
    DocumentosModule,
    InformacoesComplementaresModule,
    CadeiaNiModule,
    ConsultaDadosBasicosPfModule,
    ConsultaTituloEleitorModule,
    DirfModule,
    OmissosModule,
    DoiModule,
    MalhaIrpfModule,
  ],
  exports: [
    ConsultaPfComponent,
  ],
  providers: [
    CpfService,
    TituloEleitorService
  ]
})
export class ConsultaPfModule { }
