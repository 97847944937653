import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TributosAcaoFiscal } from '../model/tributo-acao-fiscal';

@Injectable()
export class AcaoFiscalService {

  private baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterTributosAcaoFiscal(ni: string): Observable<TributosAcaoFiscal> {
    const hoje = (new Date(Date.now())).toISOString();
    let dataReferencia = hoje.substring(8, 10) + "-" + hoje.substring(5, 7) + "-" + hoje.substring(0, 4);
    const url = `${this.baseUrl}/acaofiscal/tributos/${ni}/${dataReferencia}`;
    return this.httpClient.get<TributosAcaoFiscal>(url);
  }
}
