<div [ngClass]="{ 'modal-container': !isAtendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <div class="container-fluid" *ngIf="(debito$ | async) as debito; else loadingOrError;">
      <div class="container-detalhe-debito">
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">Banco</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroBanco | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Agência</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroAgencia | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">C/C</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroConta | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">DV</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroDVConta | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">Exercício</label>
            <p class="form-control-static dado-formulario">
              {{ debito.exercicio | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">CT Ativo</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroDebitoAtivo | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Unidade Monetária</label>
            <p class="form-control-static dado-formulario">
              {{ debito.unidadeMonetaria | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Situação CT</label>
            <p class="form-control-static dado-formulario">
              {{ debito.situacao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">Valor Originário</label>
            <p class="form-control-static dado-formulario">
              {{ debito.valorOriginario | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Saldo Devedor</label>
            <p class="form-control-static dado-formulario">
              {{ debito.saldoDisponivel | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Número da Declaração</label>
            <p class="form-control-static dado-formulario">
              {{ debito.numeroDeclaracao | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Situação Débito Automático</label>
            <p class="form-control-static dado-formulario">
              {{ debito.tipoDebito | codigoDescricao | vazio }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-componentes">
        <table suiteRfbTable>
          <caption>Componentes</caption>

          <thead *ngIf="debito.cotas?.length > 0">
            <tr>
              <th class="text-center">Número</th>
              <th class="text-center">Vencimento</th>
              <th class="text-center">CT</th>
              <th class="text-center">Mensagem</th>
              <th class="text-center">Valor Enviado</th>
            </tr>
          </thead>

          <tbody *ngIf="debito.cotas?.length > 0">
            <ng-container *ngFor="let componente of debito.cotas">
              <tr>
                <td class="text-center">{{ componente.numeroComponente | vazio }}</td>
                <td class="text-center">{{ componente.dataVencimento | formatDataPipe | vazio }}</td>
                <td class="text-center">{{ componente.numeroDebito | vazio }}</td>
                <td class="text-center">{{ componente.mensagem | vazio }}</td>
                <td class="text-center">{{ componente.valorEnviado | formatCurrency | vazio }}</td>
              </tr>
            </ng-container>
          </tbody>

          <tfoot *ngIf="debito.cotas?.length <= 0">
            <tr class="tabela-acoes text-center text-uppercase">
              <td colspan="8" style="vertical-align: middle;">
                Nenhum componente encontrado
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="container-fluid">
      <div class="container-itens-alteraveis">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label [for]="bancoName" class="control-label">Banco</label>
                <input
                  class="form-control"
                  type="text"
                  [formControlName]="bancoName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label [for]="agenciaName" class="control-label">Agência S/ DV</label>
                <input
                  class="form-control"
                  type="text"
                  [formControlName]="agenciaName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label [for]="ccName" class="control-label">Conta Corrente</label>
                <input
                  class="form-control"
                  type="text"
                  [formControlName]="ccName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group">
                <label [for]="dvName" class="control-label">DV</label>
                <input
                  class="form-control"
                  type="text"
                  [formControlName]="dvName"
                  autocomplete="off"
                  suiteRfbNumbersOnly>
              </div>
            </div>
          </div>

          <div class="container-acoes">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="!podeIncluirDebitoAutomatico()"
                  (click)="onIncluirDebito()">
                  Incluir
                </button>

                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="!podeAlterarDebitoAutomatico()"
                  (click)="onAlterarDebito()">
                  Alterar
                </button>

                <button
                  type="button"
                  class="btn btn-primary"
                  [disabled]="!podeCancelarDebitoAutomatico()"
                  (click)="onCancelarDebito()">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
