import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CafirModule } from 'src/app/cafir/cafir.module';
import { AcaoFiscalModule } from '../../../acao-fiscal/acao-fiscal.module';
import { ComprotModule } from '../../../comprot/comprot.module';
import { InformacoesComplementaresComponent } from './components/informacoes-complementares.component';
import { InformacoesComplementaresSuiteRfbModule } from './informacoes-complementares-suite-rfb.module';

@NgModule({
  declarations: [
    InformacoesComplementaresComponent
  ],
  imports: [
    CommonModule,
    ComprotModule,
    AcaoFiscalModule,
    CafirModule,
    InformacoesComplementaresSuiteRfbModule
  ],
  exports: [
    InformacoesComplementaresComponent
  ]
})
export class InformacoesComplementaresModule { }
