import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { ConsultaPagamentosComponent } from 'src/app/pagamento/components/consulta-pagamentos/consulta-pagamentos.component';
import { ParametrosPesquisaPagamento } from 'src/app/pagamento/models/parametros-pesquisa';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { Componente, CreditoTributario, PagamentoAlocado } from '../../../../../models/credito-tributario';
import { CCPFService } from '../../../services/ccpf.service';

@Component({
  selector: 'via-ccpf-table-pagamentos',
  templateUrl: 'consulta-credito-pagamentos-table.component.html',
  styleUrls: ['consulta-credito-pagamentos-table.component.css']
})
export class CcpfConsultaCreditoPagamentosTableComponent implements OnInit {

  @Input() atendimento: boolean = false;
  @Input() pagamentos: PagamentoAlocado[];
  @Input() cpf: string;
  @Input() credito: CreditoTributario;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;
  @Input() exibirAcoes: boolean = true;

  @ViewChild('topo', { static: false }) contentPage: ElementRef;

  semPagamento = false;

  scopeId: string;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  initError: Error;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  constructor(
    private cCPFService: CCPFService,
    private messageService: ScopedMessageService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  ngOnInit(): void {
    this.semPagamento = (isEmpty(this.pagamentos) || ((!isEmpty(this.pagamentos)) && isEmpty(this.pagamentos[0]?.pagamento)));
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => {
      toggle.show = show;
    });
  }

  getComponente(numeroComponente: string): Componente {
    const componente = this.credito?.componentes?.find(c => c.numero === numeroComponente);
    return {
      numero: numeroComponente,
      indicadorEncargo: componente.indicadorEncargo
    } as Componente;
  }

  getCriterio(pagamento: PagamentoAlocado): string {
    return `${pagamento.criterio} ${pagamento.tipoCriterio}`.trim();
  }

  desalocarPagamento(pagamento: PagamentoAlocado): void {
    this.messageService.dismissMessages();
    this.cCPFService.desalocarPagamento(this.cpf, this.credito.ct, pagamento.componente, pagamento.pagamento).subscribe(
      _sucesso => {
        this.scrollTop();
        this.messageService.showInfo('Pagamento desalocado com sucesso.');
        this.cCPFService.atualizarListagemCreditos(this.parametrosPesquisa);
      },
      error => {
        this.scrollTop();
        this.messageService.showErrorException('Ocorreu um erro ao desalocar pagamento.', error);
      }
    );
  }

  impedirAlocacaoAutomatica(pagamento: PagamentoAlocado): void {
    this.messageService.dismissMessages();
    this.cCPFService.impedirAlocacaoAutomatica(this.cpf, pagamento.pagamento).subscribe(
      _sucesso => {
        this.scrollTop();
        this.messageService.showInfo('Alocação automática impedida com sucesso');
        this.cCPFService.atualizarListagemCreditos(this.parametrosPesquisa);
      },
      error => {
        this.scrollTop();
        this.messageService.showErrorException('Ocorreu um erro ao impedir a alocação automática', error);
      }
    );
  }

  liberarAlocacaoAutomatica(pagamento: PagamentoAlocado): void {
    this.messageService.dismissMessages();
    this.cCPFService.liberarAlocacaoAutomatica(this.cpf, pagamento.pagamento).subscribe(
      _sucesso => {
        this.scrollTop();
        this.messageService.showInfo('Alocação automática liberada com sucesso');
        this.cCPFService.atualizarListagemCreditos(this.parametrosPesquisa);
      },
      error => {
        this.scrollTop();
        this.messageService.showErrorException('Ocorreu um erro ao liberar a alocação automática', error);
      }
    );
  }

  tratarPagamento(pagamento: PagamentoAlocado): void {
    const parametrosPesquisa = {
      numeroPagamento: pagamento.pagamento
    } as ParametrosPesquisaPagamento;

    const consultaPagamentosFeature = {
      id: `ConsultaPagamentos_Pagamento_${pagamento.pagamento}`,
      title: 'Pagamentos - Consultar',
      class: ConsultaPagamentosComponent,
      parametros: {
        parametrosPesquisa: parametrosPesquisa,
        atendimento: this.atendimento
      }
    } as FeatureAction;

    if (this.atendimento) {
      consultaPagamentosFeature.active = true;
      consultaPagamentosFeature.rolesAllowed = [];
      consultaPagamentosFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaPagamentosFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaPagamentosFeature);
  }

  private scrollTop(): void {
    const element = document.querySelector('#topo');
    element.scrollIntoView();
  }
}
