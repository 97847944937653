import { TipoNI } from "./enum/tipo-ni";
import { SituacaoFiscalDto } from "./situacao-fiscal-dto";

export interface SituacaoFiscalPessoaJuridicaDto extends SituacaoFiscalDto {
    ni: string;
    tipoNI: TipoNI;
    tipoNIRequerente: TipoNIRequerente;
    razaoSocial: string;
    motivoEvento: string;
    dataEvento: Date;
    indicadorEnteFederativo: boolean;
    indicadorTipoEnteFederativo: string;
    indicadorDisponibilidadeSimplesNacional: boolean;
    pendenciasExistentes: OrigemPendenciaPessoaJuridica[];
    pendenciasNaoExistentes: OrigemPendenciaPessoaJuridica[];
    pendenciasNaoAnalisadas: OrigemPendenciaPessoaJuridica[];
    pendenciasIndisponiveis: OrigemPendenciaPessoaJuridica[];
    pendenciasEmProcessamento: OrigemPendenciaPessoaJuridica[];
    tipoCertidao: TipoCertidao;
    certidaoNumeroControle: string;
    certidaoDataValidade: Date;
    certidaoDataEmissao: Date;
    numeroLiberacao: string;
    indicadorUsoLiberacao: number;
    indicadorEnteFederativoPrincipal: number;
    indicadorVinculado: number;
}

export enum OrigemPendenciaPessoaJuridica {
    IrregularidadeCadastral = 'IrregularidadeCadastral', 
    DitrAusencia = 'DitrAusencia',
    GfipAusencia = 'GfipAusencia',
    ContaCorrente = 'ContaCorrente',
    ItrContaCorrente = 'ItrContaCorrente',
    CcitrExigibilidade = 'CcitrExigibilidade',
    CcpfExigibilidade = 'CcpfExigibilidade',
    ProfiscProcessoCobranca = 'ProfiscProcessoCobranca',
    ProfiscProcessoExigibilidade = 'ProfiscProcessoExigibilidade',
    SipadeParcelamentoCobranca = 'SipadeParcelamentoCobranca',
    SipadeParcelamentoExigibilidade = 'SipadeParcelamentoExigibilidade',
    PaexParcelamentoCobranca = 'PaexParcelamentoCobranca',
    PaexParcelamentoExigibilidade = 'PaexParcelamentoExigibilidade',
    SiefparParcelamentoCobranca = 'SiefparParcelamentoCobranca',
    SiefparParcelamentoExigibilidade = 'SiefparParcelamentoExigibilidade',
    ParcsnExigibilidade = 'ParcsnExigibilidade',
    ParcsnCobranca = 'ParcsnCobranca',
    SiefDebitoCobranca = 'SiefDebitoCobranca',
    SiefDebitoExigibilidade = 'SiefDebitoExigibilidade',
    SiefProcessoCobranca = 'SiefProcessoCobranca',
    SiefProcessoExigibilidade = 'SiefProcessoExigibilidade',
    SiefProcessoArrolamento = 'SiefProcessoArrolamento',
    PgfnInscricaoCobranca = 'PgfnInscricaoCobranca',
    PgfnInscricaoExigibilidade = 'PgfnInscricaoExigibilidade',
    PgfnParcelamentoCobranca = 'PgfnParcelamentoCobranca',
    PgfnParcelamentoExigibilidade = 'PgfnParcelamentoExigibilidade',
    SisparPgfnCobranca = 'SisparPgfnCobranca',
    SisparPgfnExigibilidade = 'SisparPgfnExigibilidade',
    AguiaCobranca = 'AguiaCobranca',
    SicobDebitoCobranca = 'SicobDebitoCobranca',
    SicobDebitoExigibilidade = 'SicobDebitoExigibilidade',
    SicobParcelamentoCobranca = 'SicobParcelamentoCobranca',
    SicobParcelamentoExigibilidade = 'SicobParcelamentoExigibilidade',
    DividaDebitoCobranca = 'DividaDebitoCobranca',
    DividaDebitoExigibilidade = 'DividaDebitoExigibilidade',
    DividaParcelamentoCobranca = 'DividaParcelamentoCobranca',
    DividaParcelamentoExigibilidade = 'DividaParcelamentoExigibilidade',
    ImpedimentoCertidaoRfb = 'ImpedimentoCertidaoRfb',
    ImpedimentoCertidaoPgfn = 'ImpedimentoCertidaoPgfn',
    DasnAusencia = 'DasnAusencia',
    DctfAusencia = 'DctfAusencia',
    DirfAusencia = 'DirfAusencia',
    SimeiAusencia = 'SimeiAusencia',
    SisobraprefAusencia = 'SisobraprefAusencia',
    PgdasAusencia = 'PgdasAusencia',
    EcfAusencia = 'EcfAusencia',
    EfdAusencia = 'EfdAusencia',
    RefisCobranca = 'RefisCobranca',
    RefisExigibilidade = 'RefisExigibilidade',
    QSA = 'QSA',
    OptanteParcSN = 'OptanteParcSN',
}

export enum TipoCertidao {
    Negativa, PosNeg, Positiva
}

export enum TipoNIRequerente {
    CPFAtivo, CPFCancelado, CNPJMatriz, CNPJAdquirida
}