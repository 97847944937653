<suite-rfb-collapse [title]="detalheSocio.rotuloQuadro">
  <div class="table-responsive">
    <table suiteRfbTable>
      <thead>
        <tr>
          <th class="text-center min-width">
            <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
            </suite-rfb-toggle>
          </th>
          <th class="text-center min-width">{{ detalheSocio.rotuloNi }}</th>
          <th class="text-center">Nome</th>
          <th class="text-center">Qualificação</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let socio of detalheSocio.socios | currentPage: currentPage;">
          <tr class="vertical-align-middle">
            <td>
              <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
            </td>
            <td class="text-center text-nowrap" *ngIf="detalheSocio.tipoSocio == 0">
              <ni-com-link *ngIf="podeExibirNI(socio.niSocio); else templateVazio;" [ni]="socio.niSocio" tipoNI="CPF"></ni-com-link>
            </td>
            <td class="text-center text-nowrap" *ngIf="detalheSocio.tipoSocio == 1">
              <ni-com-link *ngIf="podeExibirNI(socio.niSocio); else templateVazio;" [ni]="socio.niSocio" tipoNI="CNPJ"></ni-com-link>
            </td>
            <td class="text-center text-nowrap" *ngIf="detalheSocio.tipoSocio == 2">
              <ni-com-link *ngIf="podeExibirNI(socio.niSocio); else templateVazio;" [ni]="socio.niSocio" tipoNI="CPF"></ni-com-link>
            </td>
            <td class="text-left">{{ socio.nomeSocio | vazio }}</td>
            <td class="text-left">{{ socio.qualificacaoSocio | vazio }}</td>
          </tr>
          <ng-container *ngIf="rowToggle.show">
            <tr></tr>
            <tr>
              <td colspan="4">
                <div class="contorno">
                  <div class="row" *ngIf="incluiHistorico; else semDataExclusao">
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Pais
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ socio.pais | vazio }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Capital Social
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ socio.capitalSocial | number: '1.2-2' | vazio }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Incluído em
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ socio.dataInclusao | vazio: ["00000000"] : (socio.dataInclusao | outputMask: '9999-99-99' | date: 'shortDate') }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Excluído em
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ socio.dataExclusao | vazio: ["00000000"] : (socio.dataExclusao | outputMask: '9999-99-99' | date: 'shortDate') }}
                      </p>
                    </div>
                  </div>

                  <ng-template #semDataExclusao>
                    <div class="row">
                      <div class="col-md-4">
                        <label class="label-formulario control-label">
                          Pais
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ socio.pais | vazio }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <label class="label-formulario control-label">
                          Capital Social
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ socio.capitalSocial | number: '1.2-2' | vazio }}
                        </p>
                      </div>
                      <div class="col-md-4">
                        <label class="label-formulario control-label">
                          Incluído em
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ socio.dataInclusao | vazio: ["00000000"] : (socio.dataInclusao | outputMask: '9999-99-99' | date: 'shortDate') }}
                        </p>
                      </div>
                    </div>
                  </ng-template>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="label-formulario control-label">
                        Representante Legal
                      </label>
                      <p class="form-control-static dado-formulario" *ngIf="podeExibirNI(socio.cpfRepresentanteLegal); else templateVazio;">
                        <ni-com-link [ni]="socio.cpfRepresentanteLegal" tipoNI="CPF"></ni-com-link>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <label class="label-formulario control-label">
                        Qualificação do Representante Legal
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ socio.qualificacaoRepresentanteLegal | vazio }}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="4">
            <via-pagination [itemAmount]="detalheSocio.socios.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</suite-rfb-collapse>

<ng-template #templateVazio>
  <p class="form-control-static dado-formulario">{{ '' | vazio }}</p>
</ng-template>
