import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { options } from './../../../../../app.module';
import { ParametrosPesquisaCcitr } from 'src/app/cobranca/models/parametros-pesquisa-ccitr';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponenteCcitr, CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LogService } from 'src/app/shared/services/log.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import {  TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCITRService } from '../../services/ccitr.service';
import { CodigoDescricaoDTO } from "src/app/cnpj/model/codigo-descricao-dto";
import { CcitrBase } from '../../base/CcitrBase.component';
import { CONSTANTS } from '../../constants';
import { isEmpty, isNull } from 'lodash';

@Component({
  selector: 'via-ccitr-transferencia-ct',
  templateUrl: './ccitr-transferencia-ct.component.html',
  styleUrls: ['./ccitr-transferencia-ct.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class CCITRTransferenciaCtComponent extends CcitrBase implements IDynamicPanel, OnInit, OnDestroy {

  @Input() nirf: string;
  @Input() credito: CreditoTributarioCcitr;
  @Input() componente: ComponenteCcitr;
  @Input() parametros: ParametrosPesquisaCcitr;

  private readonly MOTIVO_TRANSFERENCIA_OUTROS = '99';
  private readonly PERDCOMP = '11';

  titulo: string;

  formGroup: FormGroup;
  motivoTransferenciaCtrl: FormControl;
  numeroProcessoCtrl: FormControl;
  motivoOutrosCtrl: FormControl;
  dataCtName = 'dataCt';
  textoMotivoValidators: any;

  motivosTransferencia: CodigoDescricaoDTO[] ;

  motivoTransferenciaName: string =  'motivoTransferencia';
  motivoOutrosName = 'motivoOutros';
  textoMotivoName: string = 'textoMotivo';
  numeroProcessoName: string =  'numeroProcesso';
  numeroPerdcompName: string =  'numeroPerdcomp';

  scopeId: string;
  initError: Error;
  executouObterDados = false;
  filtroColapsado = false;
  stopLoading = false;
  featureEventSub: Subscription;

  constructor(
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private cCITRService: CCITRService,
    private loadingService: LoadingService ) {
    super();
  }

  init(_ni: string, params?: OptionalParams): void {
    super.init(_ni, params);
    this.nirf = params?.nirf;
    this.credito = params?.credito;
    this.parametros = params?.parametros;

    this.obterMotivosTransferencia();

    this.titulo = `CCITR - Transferencia CT: ${this.credito?.numeroCt}`;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$
    .subscribe(featureEvent => {
      // se a chave de identificação da feature for a mesma
      if (featureEvent.active == false && featureEvent.featureAction.id == 'CcpfConsultar' && featureEvent.featureAction.tipoMenu == TipoMenu.ATENDIMENTO) {
        this.fecharFeature();
        this.featureEventSub.unsubscribe();
      }
    });
  }


  ngOnInit(): void {
    this.initError = null;
    this.scopeId = this.messageService.scopeId;
    this.buildForm();
    this.addValidators();
    this.subscribeValueChanges();
    this.obterMotivosTransferencia();
    this.formGroup.reset();
    this.configurarMotivo(false);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      motivoTransferencia: this.motivoTransferenciaCtrl = new FormControl(''),
      numeroProcesso: this.numeroProcessoCtrl = new FormControl(''),
      motivoOutros: this.motivoOutrosCtrl = new FormControl(''),
    });
  }

  addValidators(): void {
    this.motivoTransferenciaCtrl.addValidators( [Validators.required] );
    this.numeroProcessoCtrl.addValidators( this.numeroProcessoValidator() );
    this.motivoOutrosCtrl.addValidators( [Validators.required,Validators.maxLength(29)] );
  }

  subscribeValueChanges(): void {
    this.motivoTransferenciaCtrl.valueChanges.subscribe(value => {
      this.numeroProcessoCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
    });
  }

  public get numeroProcesso(): string {
    return this.formGroup.get(this.numeroProcessoName).value as string;
  }

  public set numeroProcesso(numero: string) {
    this.formGroup.patchValue({numeroProcesso: numero});
  }

  public get motivoTransferencia(): CodigoDescricaoDTO {
    return this.formGroup.get(this.motivoTransferenciaName).value as CodigoDescricaoDTO;
  }

  public set motivoTransferencia(motivo: CodigoDescricaoDTO) {
    this.formGroup.patchValue({motivoTransferencia: motivo});
  }

  public get motivoOutros(): string {
    return this.formGroup.get(this.motivoOutrosName).value as string;
  }

  public set motivoOutros(motivo: string) {
    this.formGroup.patchValue({motivoOutros: motivo});
  }

  numeroProcessoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.formGroup.get(this.numeroProcessoName).value) {
        return null;
      }
      if (this.motivoTransferencia.codigo == this.PERDCOMP && (this.numeroProcesso.length != 24)) {
        return { numero_processo_invalido: true };
      }
      if (this.motivoTransferencia.codigo != this.PERDCOMP && !(this.numeroProcesso.length == 15 || this.numeroProcesso.length == 17)) {
        return { numero_processo_invalido: true };
      }
      return null;
    };
  }

  public configurarMotivo(evento: Boolean): void {
    if (!isNull(this.motivoTransferencia) && this.motivoTransferencia.codigo === this.MOTIVO_TRANSFERENCIA_OUTROS) {
      this.formGroup.get(this.motivoOutrosName).enable();
    } else {
      this.formGroup.get(this.motivoOutrosName).disable();
      this.formGroup.patchValue({motivoOutros: ""});
    }
  }

  onReset() {
    this.formGroup.reset();
    this.messageService.dismissMessages();
  }

  private fecharFeature(): void {
    this.menuFeatureActionsService.deactivate({
      id: `ConsultaCCITR_TransferenciaCT_${this.nirf}`,
      title: "CCITR - Transferir CT:",      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO,
      class: CCITRTransferenciaCtComponent
    });
  }

  public ngOnDestroy(): void {
    if(this.featureEventSub){
      this.featureEventSub.unsubscribe();
    }
  }


  obterMotivosTransferencia() {
    this.cCITRService.obterMotivosTransferencia().subscribe(
      response => {
        this.motivosTransferencia = response;
        if (this.motivosTransferencia && isEmpty(this.motivosTransferencia)) {
          this.messageService.showError('Ocorreu um erro ao recuperar os motivos de transferência.');
          this.stopLoading = true;
        } else {
          this.executouObterDados = true;
        }
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao buscar os motivos de transferência', err);
        }
      }
    );
  }

  onSubmit(): void {
    this.loadingService.show();
    this.messageService.dismissMessages();

    const niDeclarante = this.credito.niDeclarante;

    const nirf = this.nirf;
    const numeroCt = this.credito.numeroCt;
    const codigoMotivo = this.motivoTransferencia?.codigo;
    const textoMotivo = this.motivoOutros;
    const processo = this.motivoTransferencia.codigo == this.PERDCOMP ? '':this.numeroProcesso;
    const perdcomp = this.motivoTransferencia.codigo == this.PERDCOMP ?this.numeroProcesso:'';
    this.cCITRService.transferirCT(niDeclarante, nirf, numeroCt, codigoMotivo,
                                   textoMotivo, processo,  perdcomp).subscribe(
      _sucesso => {
        this.messageService.showInfo('Crédito Tributário transferido com sucesso');
        this.cCITRService.atualizarListagemCreditos(this.parametros);
      }, (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao transferir o Crédito Tributário', err);
        }
      }
    ).add(() => {
      this.loadingService.hide();
    });;
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }
}
