import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { isEmpty, isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { Componente, CreditoTributario } from 'src/app/cobranca/models/credito-tributario';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../services/ccpf.service';

@Component({
  templateUrl: './revalidacao-data-ct.component.html',
  styleUrls: ['./revalidacao-data-ct.component.css'],
  providers: [ScopedMessageService]
})
export class CCPFRevalidacaoDataCtComponent implements IDynamicPanel, OnInit, OnDestroy {

  @Input() cpf: string;
  @Input() credito: CreditoTributario;
  @Input() componente: Componente;
  @Input() atendimento = false;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;

  titulo: string;

  formGroup: FormGroup;
  dataCtName = 'dataCt';

  scopeId: string;
  initError: Error;

  featureEventSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.cpf = params?.cpf;
    this.credito = params?.credito;
    this.componente = params?.componente;
    this.atendimento = params?.atendimento ?? this.atendimento;
    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.titulo = `CCPF - Revalidar Data do CT: ${this.credito?.ct} - Componente ${this.componente?.numero}`;

    this.formGroup = this.formBuilder.group({
      dataCt: ['', [Validators.required]]
    });

    this.scopeId = this.messageService.scopeId;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharRevalidacaoData();
          this.featureEventSub?.unsubscribe();
        }
      }
    );
  }

  ngOnInit(): void {
    this.ccpfService.notificarAtualizacaoListaCreditosEmitter.subscribe(creditos => {
      if (!isEmpty(creditos) && this.credito) {
        const ct = this.credito.ct;
        this.credito = creditos.find(credito => isEqual(credito.ct, ct));
        this.componente = this.credito?.componentes?.find(comp => isEqual(this.componente.numero, comp.numero));
      }
    });
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  onRevalidarDataCt(): void {
    // DATA REPASSADA COMO STRING NO FORMATO yyyyMMdd
    const _novaDataVencimento: string = [
      this.novaDataVencimento.getFullYear(),
      (this.novaDataVencimento.getMonth() + 1).toString().padStart(2, '0'),
      this.novaDataVencimento.getDate().toString().padStart(2, '0')
    ].join('');

    this.messageService.dismissMessages();
    this.loadingService.show();

    this.ccpfService.revalidarDataCt(this.cpf, this.credito.ct, this.componente.numero, _novaDataVencimento).subscribe(
      _sucesso => {
        this.messageService.showInfo(`Data do CT ${this.credito.ct} revalidada com sucesso`);
        this.formGroup.reset();
        this.loadingService.hide();
        this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
      },
      error => {
        this.loadingService.hide();
        const mensagemErro = `Ocorreu um erro ao revalidar a data do CT ${this.credito.ct}`;
        this.messageService.showErrorException(mensagemErro, error);
      }
    );
  }

  private get novaDataVencimento() {
    return this.formGroup.get(this.dataCtName).value as Date;
  }

  private fecharRevalidacaoData(): void {
    const feature = {
      id: `CCPFRevalidacaoDataCT_credito_${this.credito.ct}_componente_${this.componente.numero}`,
      title: 'CCPF - Revalidar Data',
      class: CCPFRevalidacaoDataCtComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
