import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDataHoraPipe'
})
export class FormatDataHoraPipe implements PipeTransform {
  transform(data: string) {
    if (data) {
      return FormatDataHoraPipe.formatDataHora(data);
    }
    return null;
  }
  static formatDataHora: Function = (data: string) => {
    data = data + "";
    if (data.length == 14) {
      return data.substring(6,8) + "/" + data.substring(4, 6) + "/" + data.substring(0, 4) + " " + data.substring(8, 10) + ":" + data.substring(10,12) + ":" + data.substring(12);
    }
    return data;
  }

}


