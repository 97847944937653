<div [id]="onlyFor" class="alert" [ngClass]="{'alert-success': message.type === 'INFO', 'alert-warning': message.type === 'WARN', 'alert-danger': message.type === 'ERROR'}" role="alert" *ngFor="let message of messages">
  <div class="alert-panel">
    <div class="alert-icone">
      <span *ngIf="message.type === 'INFO'" class="material-icons icon-48">&#xE86C;</span>
      <span *ngIf="message.type === 'WARN'" class="material-icons icon-48">&#xE002;</span>
      <span *ngIf="message.type === 'ERROR'" class="material-icons icon-48">&#xE000;</span>
    </div>
    <div class="alert-mensagem">
      <div class="title">{{message.title}}</div>
      <div class="description" *ngIf="message.description">{{message.description}}</div>
      <ul class="details" *ngIf="message.details && message.details.length > 0">
        <li class="detail" *ngFor="let detail of message.details">{{detail}}</li>
      </ul>
      <div class="more" *ngIf="message.options && message.options.hasMore">
        <button type="button" class="btn btn-primary show-more" (click)="showMore(message)">Ver mais</button>
      </div>
    </div>
    <div class="close" *ngIf="message.options && message.options.dismissible">
      <span class="close-icon material-icons icon-24" (click)="dismiss(message)">&#xE5CD;</span>
    </div>
  </div>
  <div class="alert-datetime">
      {{this.datetime}}
  </div>
</div>
