import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OperacaoHistorico } from './model/operacao-historico';

@Injectable()
export class HistoricoService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }

    public obterHistoricoOperacoes(cpf: string): Observable<OperacaoHistorico[]> {
        const url = `${this.baseUrl}/cpf/historico-operacoes/${cpf}`;
        return this.http.get<OperacaoHistorico[]>(url);
    }
}
