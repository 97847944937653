import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

export enum LogType {
  step = 'STEP',
  ajaxRead = 'AJAX CALL READ',
  ajaxWrite = 'AJAX CALL WRITE',
  ajaxReturn = 'AJAX RETURN',
  ajaxError = 'AJAX ERROR',
  messageSent = 'WEBSOCKET MSG SENT',
  messageReceived = 'WEBSOCKET MSG RECEIVED'
}

@Injectable({
	providedIn: 'root'
})
export class LogService {
  public static DEBUG = true;

  count = 0;

  public readonly data: {
    group: string,
    type: LogType,
    id: number,
    time: Date,
    key?: string,
    value?: any,
  }[] = [];

  constructor() { }

  public init() {
    this.data.length = 0;
  }

  entryToString(log: {
    group: string,
    type: LogType,
    id: number,
    time: Date,
    key?: string,
    value?: any,
  }) {
    const txt = moment(log.time).format('D/M/YYYY HH:mm:ss') +
       `\t${log.id}\t [${log.type}]\t ${log.group}\t - [${log.key}]` +
       (log.value ? ('\t  -->  ' + this.secureStringfy(log.value)) : '');
    return txt;
  }

  public logStep(group: string, key: string, value?: any, stringfy?: boolean) {
    const entry = {
      group,
      type: LogType.step,
      id: this.count++,
      time: new Date(),
      key,
      value,
    };
    this.data.push(entry);
    if(!stringfy)
      console.log(`\t${this.count}\t [${LogType.step}]\t ${group}\t - [${key}]`, (value ? value : ''));
    else
      console.log(this.entryToString(entry));
  }

  public logAjaxCall(group: string, ajax: string, params?: any) {
    const entry = {
      group,
      type: LogType.ajaxRead,
      id: this.count++,
      time: new Date(),
      key: ajax,
      value: params,
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  public logAjaxReturn(group: string, ajax: string, result?: any) {
    const entry = {
      group,
      type: LogType.ajaxReturn,
      id: this.count++,
      time: new Date(),
      key: ajax,
      value: result
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  public logAjaxError(group: string, ajax: string, error: Error) {
    const entry = {
      group,
      type: LogType.ajaxError,
      id: this.count++,
      time: new Date(),
      key: ajax,
      value: this.extractMessageFromError(error),
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  public logError(group: string, details: string, error: Error) {
    const entry = {
      group,
      type: LogType.ajaxError,
      id: this.count++,
      time: new Date(),
      key: details,
      value: this.extractMessageFromError(error),
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  public logSentMessage(group: string, messageType: string, messageId: any, message: any) {
    const entry = {
      group,
      type: LogType.messageSent,
      id: this.count++,
      time: new Date(),
      key: messageType,
      value: { messageId, message }
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  public logReceivedMessage(group: string, messageType: string, messageId: any, message: any) {
    const entry = {
      group,
      type: LogType.messageReceived,
      id: this.count++,
      time: new Date(),
      key: messageType,
      value: { messageId, message }
    };
    this.data.push(entry);
    console.log(this.entryToString(entry));
  }

  private extractMessageFromError(error: any): {message: string, exception: string} {
    // recupera a mensagem se for Error
    let message = null;
    let detail = null;

    // se expirou a sessão do token de autenticação
    switch (error.status) {
        case 0: {
          if (error.message && error.message.indexOf('unknown url') >= 0) {
            // trata o caso de backend offline
            message = error.message;
          } else if (error.message && error.message.indexOf('Http failure response for') >= 0) {
            message = 'Ocorreu um erro no servidor que impossibilitou a execução da ação.';
          } else {
            // title = 'Sessão Expirada';
            // message = 'A sessão atual expirou! Favor retornar à página de login para nova autenticação.';
            // redireciona para a suite RFB
          }
          break;
        }
        case 500: {
            detail = '';
            try {
                const msg = error.error.message;
                const err = JSON.parse(msg.match(/\[.*\]/))[0];
                if (err.msg) {
                    message = err.msg;
                } else if (err.error) {
                    message = err.error;
                    if (err.erros && err.erros.length > 0) {
                        detail = err.erros[0].id;
                    }
                }
            } catch (e) {
                message = 'Ocorreu um erro interno no servidor.';
            }
            break;
        }
        case 503: {
          detail = '';
          message = 'O servidor atualmente é incapaz de lidar com o pedido devido a uma sobrecarga temporária ou manutenção do servidor. ' +
          'Por favor, aguarde alguns instantes e tente novamente.';
          break;
        }
        default: {
            if (error instanceof HttpErrorResponse) {
                if (error.error && error.error.message) {
                    message = error.error.message;
                    if (error.error.exception) { // inclui a exceção no erro
                        detail = error.error.exception;
                    }
                } else {
                    message = error.message;
                }
            } else {
              message = JSON.stringify(error);
            }

            break;
        }
    }
    if (!detail && error.status !== 0 && error.status !== 400 && error.status !== 404 &&
       error.status !== 412) {
      detail = `Status [${error.status}] ${detail}`;
    }

    return {message, exception: detail};

  }

  public secureStringfy(o: any): string {
    const replaceCircular = (val: any, cache?: any) => {
        cache = cache || new WeakSet();
        if (val && typeof(val) === 'object') {
            if (cache.has(val)) { return '[Circular]'; }
            cache.add(val);
            const obj = (Array.isArray(val) ? [] : {});
            // tslint:disable-next-line: forin
            for (const idx in val) {
                try {
                  obj[idx] = replaceCircular(val[idx], cache);
                } catch (e) {
                  obj[idx] = `* ERROR when stringfy attribute: ${idx}`;
                }
            }
            cache.delete(val);
            return obj;
        }
        return val;
    };

    return JSON.stringify(replaceCircular(o));
  }

}
