import { Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { isEmpty } from 'lodash';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ComponenteDadosConsolidadoDebitoCCITRDTO } from '../../model/dados-consolidado-debito-ccitr';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';

@Component({
  selector: 'via-ccitr-componentes-dados-consolidar-ct',
  templateUrl: 'componentes-dados-consolidar-ct.component.html',
  styleUrls: ['componentes-dados-consolidar-ct.component.css','../../../../base/ccitr.css'],
  providers: [ScopedMessageService],
})
export class ConsultaComponentesConsolidaCTComponent implements OnInit {

  @Input() componentes: ComponenteDadosConsolidadoDebitoCCITRDTO[];
  @Input() atendimento: boolean;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  semComponente: Boolean = false;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  ngOnInit(): void {
    this.semComponente = isEmpty(this.componentes[0].numero);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }



}
