import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ParcelamentoSimplesNacional } from './model/parcelamento-simples-nacional';
import { ParcelamentoSimplesNacionalService } from './parcelamento-simples-nacional.service';

@Component({
  selector: 'app-parcelamento-simples-nacional',
  templateUrl: './parcelamento-simples-nacional.component.html',
  styleUrls: ['./parcelamento-simples-nacional.component.css']
})
export class ParcelamentoSimplesNacionalComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  parcelamentos: ParcelamentoSimplesNacional[];

  isAtivos = false;
  isSuspensos = false;

  constructor(
    private parcelamentoSimplesNacionalService: ParcelamentoSimplesNacionalService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('ParcelamentoSimplesNacionalComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let parcelamentosObservable: Observable<ParcelamentoSimplesNacional[]>;
      if (this.isAtivos) {
        parcelamentosObservable = this.parcelamentoSimplesNacionalService.obterParcelamentosSimplesNacionalAtivos(ni, numeroProtocolo);
      }
      if (this.isSuspensos) {
        parcelamentosObservable = this.parcelamentoSimplesNacionalService.obterParcelamentosSimplesNacionalSuspensos(ni, numeroProtocolo);
      }

      parcelamentosObservable.subscribe(
        (resp) => {
          this.parcelamentos = resp;
          this.logService.logAjaxReturn('ParcelamentoSimplesNacionalComponent', 'obterParcelamentos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ParcelamentoSimplesNacionalComponent', 'obterParcelamentos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos Simples Nacional', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ParcelamentoSimplesNacionalComponent', 'obterParcelamentos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos Simples Nacional', e);
    }
  }
}

@Component({
  selector: 'app-parcelamento-simples-nacional',
  templateUrl: './parcelamento-simples-nacional.component.html',
  styleUrls: ['./parcelamento-simples-nacional.component.css']
})
export class ParcelamentoSimplesNacionalAtivoComponent extends ParcelamentoSimplesNacionalComponent {
  init(ni: string) {
    this.isAtivos = true;
    this.titulo = `Parcelamentos Ativos Fazendários Simples Nacional - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-parcelamento-simples-nacional',
  templateUrl: './parcelamento-simples-nacional.component.html',
  styleUrls: ['./parcelamento-simples-nacional.component.css']
})
export class ParcelamentoSimplesNacionalSuspensoComponent extends ParcelamentoSimplesNacionalComponent {
  init(ni: string) {
    this.isSuspensos = true;
    this.titulo = `Parcelamentos Suspensos Fazendários Simples Nacional - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}
