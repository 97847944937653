<div class="modal-container">
  <suite-rfb-collapse [title]="titulo">
    <loading-animation *ngIf="!tituloEleitor && !initError"></loading-animation>
    <div *ngIf="initError">Ocorreu um erro...</div>
    <div *ngIf="tituloEleitor" class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Titulo de Eleitor
          </label>
          <p class="form-control-static dado-formulario">
            {{ tituloEleitor.numeroTitulo }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Situação
          </label>
          <p class="form-control-static dado-formulario">
            {{ tituloEleitor.descricaoSituacao }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Nome
          </label>
          <p class="form-control-static dado-formulario">
            {{ tituloEleitor.nome }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Data de Nascimento
          </label>
          <p class="form-control-static dado-formulario">
            {{ tituloEleitor.dataNascimento | outputMask: '99/99/9999' | date: 'shortDate' | vazio }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="label-formulario control-label">
            Nome da Mãe
          </label>
          <p class="form-control-static dado-formulario">
            {{ tituloEleitor.nomeMae }}
          </p>
        </div>
      </div>
    </div>
  </suite-rfb-collapse>
</div>
