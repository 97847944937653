import { LogService } from './../../../../../../shared/services/log.service';
import { isEmpty, isEqual } from 'lodash';
import { DocumentoArrecadacao } from './../../../../../models/documento-arrecadacao';
import { CCPFService } from 'src/app/cobranca/components/ccpf/services/ccpf.service';
import { AfterContentInit, Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { Observable, Subscription, of } from 'rxjs';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ParametrosPesquisaPagamento } from 'src/app/pagamento/models/parametros-pesquisa';
import { ParametroComponenteDebito } from 'src/app/cobranca/components/ccpf/components/pagamento/models/parametros-pesquisa';
import { Componente, DebitoAlocado, Pagamento, ResumoAlocacaoPagamento } from 'src/app/cobranca/components/ccpf/components/pagamento/models/pagamento';
import { PagamentoService } from 'src/app/pagamento/services/pagamento.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isWarning } from 'src/app/cobranca/models/erro-padrao';

@Component({
  selector: 'via-consulta-componentes-ccpf-table',
  templateUrl: 'consulta-componentes-ccpf-table.component.html',
  styleUrls: ['consulta-componentes-ccpf-table.component.css']
})
export class ConsultaComponentesCCPFComponent implements OnInit {

  @Input() documentoArrecadacao: DocumentoArrecadacao;
  @Input() atendimento: boolean = false;
  @Input() parametrosPesquisa: ParametrosPesquisaPagamento;
  @Input() exibirAcoes: boolean = true;
 // @Input() parentScopeId: string;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  debitosAlocados: DebitoAlocado[];
  cpf: string;
  numeroPagamento:string;
  temDebitos: boolean = false;
  scopeId: string;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  parametros: ParametroComponenteDebito;
  alocacoesPorPagamento = new Map<string, ResumoAlocacaoPagamento[]>();

  titulo: string;
  initError: Error;

  currencyMaskOptions = {
    thousands: '.',
    decimal: ',',
    prefix: '',
    allowNegative: false,
    nullable: false,
    min: 0,
    max: null,
    precision: 2
  };

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  isAtualizacaoPagamentos = false;

  private updateSubscription: Subscription;
  private featureEventSub: Subscription;

  constructor(
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private pagamentoService: PagamentoService,
    private loadingService: LoadingService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private logService: LogService) {

     }

  ngOnInit(): void {
  //  this.scopeId = this.parentScopeId ? this.parentScopeId : this.messageService.scopeId;
    this.preencheVariaveis();
    this.obterDebitosAlocados();
  }

  public possuiDebitos(): boolean {
    return !isEmpty(this.debitosAlocados);
  }

  preencheVariaveis(): void {
    this.cpf = this.documentoArrecadacao.ni;
    this.numeroPagamento = this.documentoArrecadacao.numeroPagamento;
  }

  ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
    this.featureEventSub?.unsubscribe();
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }


  desalocarAlocacoes(debito: DebitoAlocado, componente: Componente ): void {
    this.loadingService.show();
    this.messageService.dismissMessages();
    this.ccpfService.desalocarPagamento(this.cpf, debito.numeroDebito, componente.numeroComponente, this.numeroPagamento)
    .subscribe(
      _sucesso => {
        this.messageService.showInfo('Pagamento desalocado com sucesso.');
        this.pagamentoService.atualizarListagemPagamento(this.parametrosPesquisa);
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao desalocar pagamento.', error);
      }
    ).add(() => {
      this.loadingService.hide();
    });
  }

  private obterDebitosAlocados(): void {

   this.ccpfService.obterDebitosAlocadosPorPagamento(this.cpf, this.numeroPagamento)
    .subscribe(
      (response) => {
        try {
          this.debitosAlocados = response;
          if (this.isAtualizacaoPagamentos) {
            //this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
          }
        }
        catch(error) {
          this.initError = error;
          this.logService.logError('ConsultaComponentesCCPFComponent', 'ConsultaComponentesCCPFComponent', error);
          this.messageService.showErrorException('Erro ao obter débitos alocados por numero de pagamento CCPF', error);
          return of<DebitoAlocado[]>([]);
        }
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao obter débitos alocados por numero de pagamento CCPF.', err);
        }
      },
      () => {
        this.temDebitos = !isEmpty(this.debitosAlocados);
      }
    );
  }


}

