<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitos && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>

<ng-template #tabelaDebitos>
  <via-debito-processo-sief-table
    [numeroProcesso]="numeroProcesso"
    [debitos]="debitos"
    (emitirDarf)="emitirDarf($event)"
    (emitirDas)="emitirDas($event)">
  </via-debito-processo-sief-table>
</ng-template>
