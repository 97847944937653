import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessageService, Role, UserService } from '@serpro/ngx-suite-rfb';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	userRoles: Role[];

	constructor(
		private datePipe: DatePipe,
		private userService: UserService,
		private alertMessageService: AlertMessageService,
		private routerService: Router
	) { }

	// copiado de https://stackoverflow.com/questions/36198136/cast-json-object-to-typescript-class-instance
	// transforma objeto Json em um objeto da classe T (para poder acessar os métodos da classe, p.ex.)
	// https://stackoverflow.com/questions/15338610/dynamically-loading-a-typescript-class-reflection-for-typescript
	// OBS: PARA FUNCIONAR, A CLASSE TEM QUE TER CONSTRUTOR COM TODOS OS PARAMETROS OPCIONAIS
	fromJSON<T>(json: any, clazz: new () => T): T {
		//var newInstance = Object.create(window[className].prototype);
		var newInstance = new clazz();
		for (var key in json) {
			newInstance[key] = json[key];
		}
		return newInstance;
	}

	public converterDataParaTexto(pdata: Date): string {
		var retorno: string;
		if (pdata) {
			retorno = this.datePipe.transform(pdata, 'dd/MM/yyyy');
		} else {
			retorno = '';
		}
		return retorno;
	}

	public converterDataParaTextoInvertida(pdata: Date): string {
		var retorno: string;
		if (pdata) {
			retorno = this.datePipe.transform(pdata, 'yyyyMMdd HH:mm:ss');
		} else {
			retorno = '';
		}
		return retorno;
	}

  public converterDataParaTextoInvertidaSemHora(pdata: Date): string {
		var retorno: string;
		if (pdata) {
			retorno = this.datePipe.transform(pdata, 'yyyyMMdd');
		} else {
			retorno = '';
		}
		return retorno;
	}


	//  static formatarCpf: Function = (cpf: any) => {
	public formatarCpf(cpf: number): string {
		let retorno: string = ' ';
		if (cpf) {
			let pad = '00000000000';
			let cpf11pos = this.geraCpfComDv(cpf);
			cpf11pos = (pad + cpf11pos).slice(-pad.length);
			retorno = cpf11pos
				.substring(0, 3)
				.concat('.')
				.concat(cpf11pos.substring(3, 6))
				.concat('.')
				.concat(cpf11pos.substring(6, 9))
				.concat('-')
				.concat(cpf11pos.substring(9, 11));
		}
		return retorno;
	}

	public ehNiCpf(ni: any): boolean {
		return ni && ni.toString().replace('.', '').replace('-', '').length === 11;
		//return true;
		//if(ni === '1'){
		//	return true;
		//}
		//else {
		//			return false;
		//		}
	}

	public calculaDV(cpf9pos: number): string {
		let cpf9str = cpf9pos + "";
		if (cpf9str.length > 9) {
			return null;
		}
		let pad = "000000000";
		cpf9str = (pad + cpf9str).slice(-pad.length);
		const dv1: number = this.calculateDv(cpf9str.substring(0, 9));
		cpf9str += dv1;
		const dv2: number = this.calculateDv(cpf9str.substring(0, 10));
		return dv1 + "" + dv2;
	}

	public geraCpfComDv(pfNr9Pos: number): string {
		if (!pfNr9Pos) {
			return null;
		}
		let pfNr9str = pfNr9Pos + "";
		let pad = "000000000";
		pfNr9str = (pad + pfNr9str).slice(-pad.length);
		return pfNr9str + this.calculaDV(pfNr9Pos);
	}

	private calculateDv(base: string): number {
		const length: number = base.length;
		let sum: number = 0;
		for (let i: number = 0; i < length; i++) {
			sum += Number.parseInt(base.charAt(i)) * ((length + 1) - i);
		}
		let result: number = 11 - (sum % 11);
		if (result === 10 || result === 11) {
			result = 0;
		}
		return result;
	}

	public converterNumericoParaTexto(pnumero: number): string {
		let retorno: string;
		if (pnumero) {
			retorno = pnumero.toString();
		} else {
			retorno = ' ';
		}
		return retorno;
	}

	// Preenche um texto com zeros a esquerda
	public preencherTextoComZeros(pnumero: string): string {
		let pad = '000000000';
		let retorno = pnumero;
		retorno = (pad + retorno).slice(-pad.length);
		return retorno;
	}

	// formatarRPF(uaRPF: number, anoRPF: number, seqRPF: number): string {
	// 	if (!uaRPF || !anoRPF || !seqRPF) {
	// 		return null;
	// 	}
	// 	let uaFormatado: string;
	// 	let anoFormatado: string;
	// 	let seqFormatado: string;
	// 	let pad = '0000000';
	// 	uaFormatado = (pad + uaRPF).slice(-pad.length);
	// 	pad = '0000';
	// 	anoFormatado = (pad + anoRPF).slice(-pad.length);
	// 	pad = '00000';
	// 	seqFormatado = (pad + seqRPF).slice(-pad.length);
	// 	return uaFormatado + '.' + anoFormatado + '.' + seqFormatado;
	// }

	// formatarRAC(anoRAC: number, seqRAC: number): string {
	// 	if (!anoRAC || !seqRAC) {
	// 		return null;
	// 	}
	// 	let anoFormatado: string;
	// 	let seqFormatado: string;
	// 	let pad = '0000';
	// 	anoFormatado = (pad + anoRAC).slice(-pad.length);
	// 	pad = '00000000';
	// 	seqFormatado = (pad + seqRAC).slice(-pad.length);
	// 	return anoFormatado + '.' + seqFormatado;
	// }

	public formatarTamanho(tamanho: number, si: boolean): string {
		if (tamanho == null) {
			return '-';
		}
		let retorno: string;
		let unit: number = si ? 1000 : 1024;
		let resultado: number;
		if (tamanho < unit) return tamanho + ' B';
		let exp: number = Math.trunc(Math.log(tamanho) / Math.log(unit));
		let pre: string = (si ? 'kMGTPE' : 'KMGTPE').charAt(exp - 1) + (si ? 'i' : '');
		resultado = tamanho / Math.pow(unit, exp);
		retorno = resultado.toFixed(1);
		retorno = retorno + ' ' + pre + 'B';
		return retorno;
	}

	obterUsuario() {
		// quando entra numa funcionalidade e chama este método, o user já deve ter sido lido pelo appcomponent, aqui tem que fazer subscrição somente
		// porque a biblioteca fornece um "observável"
		this.userService
			.getUser()
			.pipe(take(1))
			.subscribe(
				(user) => {
					console.log(user);
					if (!user) {
						this.alertMessageService.warn(
							'Aguardando dados do usuário',
							'Tente efetuar a operação novamente quando os dados do usuário estiverem disponíveis no cabeçalho.',
							[],
							{ delay: 2000 }
						);
						this.routerService.navigate(['/']);
					} else {
						// if (
						// 	user.roles.filter((role) => role.name == TransacaoEnum['CONSULTA_RPF'])
						// 		.length <= 0
						// ) {
						// this.alertMessageService.error(
						// 	'Usuário não autorizado para esta transação',
						// 	TransacaoEnum['CONSULTA_RPF'] +
						// 		' - ' +
						// 		DescrTransacaoEnum[TransacaoEnum['CONSULTA_RPF']]
						// );
						// 	this.routerService.navigate(['/']);
						// } else {
						// 	this.userRoles = user.roles;
						// }
					}
				},
				(err) => {
					this.alertMessageService.error('Erro', 'Usuário não logado');
					this.routerService.navigate(['/']);
				}
			);
	}
}
