import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ComprotProcesso } from '../../model/comprot-processos';
import { DetalheProcessoComprot } from '../detalhe-processo/comprot-detalhe-processo';

@Component({
  selector: 'via-comprot-table',
  templateUrl: './comprot-table.component.html',
  styleUrls: ['./comprot-table.component.css']
})
export class ComprotTableComponent {

  @Input() ni: string;
  @Input() processos: ComprotProcesso[];
  @Input() titulo: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(private dynamicModalService: DynamicModalRequestService) { }

  onDetalharProcesso(processo: ComprotProcesso): void {
    const feature = new FeatureAction();
    feature.class = DetalheProcessoComprot;
    feature.parametros = {
      ni: this.ni,
      processo: processo
    };
    this.dynamicModalService.abrirModal(feature);
  }
}
