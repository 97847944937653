import { FormatCnpjPipe } from './../../../shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from './../../../shared/pipes/formata-cpf.pipe';
import { FormatNirfPipe } from './../../../shared/pipes/formata-nirf.pipe';
import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEqual } from 'lodash';
import { Subscription, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { CCPFService } from 'src/app/cobranca/components/ccpf/services/ccpf.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DocumentoArrecadacao } from '../../models/documento-arrecadacao';
import { ParametrosPesquisaPagamento } from '../../models/parametros-pesquisa';
import { TipoRetificacao } from '../../models/tipo-retificacao';
import { PagamentoComprovanteService } from '../../services/pagamento-comprovante.service';
import { PagamentoService } from '../../services/pagamento.service';
import { RetificacaoPagamentoComponent } from '../retificacao/retificacao-pagamento.component';
import { TrocaAssinalamentoPagamentoComponent } from '../troca-assinalamento/troca-assinalamento-pagamento.component';
import { CONSTANTS } from '../../models/constants';

@Component({
  selector: 'via-consulta-pagamentos',
  templateUrl: './consulta-pagamentos.component.html',
  styleUrls: ['./consulta-pagamentos.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaPagamentosComponent implements IDynamicPanel, OnChanges, OnInit, OnDestroy {

  @Input() parametrosPesquisa: ParametrosPesquisaPagamento;
  @Input() atendimento: boolean = true;
  @ViewChildren('detalhes') detalhesQuery: QueryList<ToggleComponent>;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  initError: Error;
  stopLoading = false;
  scopeId: string;
  resultadosPesquisa: DocumentoArrecadacao[];
  titulo: string;
  updateSubscription: Subscription;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  // '01' - SISTEMA DE INTERESSE 'CONTA COR-PF'
  alocacaoAutomaticaTipoSistemaPermitido = '01';
  constDJE: string = CONSTANTS.DJE;
  constDAS: string = CONSTANTS.DAS;


  constructor(
    private loadingService: LoadingService,
    private pagamentoService: PagamentoService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private dynamicModalRequest: DynamicModalRequestService,
    private ccpfService: CCPFService,
    private pagamentoComprovanteService: PagamentoComprovanteService) { }

  public ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    if (!this.titulo ) {
      this.titulo = this.atendimento ? 'Resultado da Pesquisa' : 'Pagamento - Resultado da Pesquisa';
    }
    this.recarregar();
    this.updateSubscription = this.pagamentoService.atualizarListagemEmitter.subscribe(
      parametros => {
        if (isEqual(parametros, this.parametrosPesquisa)) {
          this.recarregar();
        }
      }
    );
  }

  public init(_ni: string, params?: OptionalParams): void {
    this.scopeId = this.messageService.scopeId;
    this.atendimento = params?.atendimento;
    this.parametrosPesquisa = params?.parametrosPesquisa as ParametrosPesquisaPagamento;
    let cpfContribuinte = params?.cpf;
    let cnpjContribuinte = params?.cnpj;

    if (cpfContribuinte || cnpjContribuinte)
      this.formataTitulo(cpfContribuinte, cnpjContribuinte);

  }

  private formataTitulo(cpfContribuinte: string, cnpjContribuinte: string): void {
    if (cpfContribuinte) {
      this.titulo = ` Pagamento - CPF: ${FormatCpfPipe.formatCPF(cpfContribuinte)}`;
    }
    if (cnpjContribuinte) {
      this.titulo = ` Pagamento - CNPJ: ${FormatCnpjPipe.formatCNPJ(cnpjContribuinte)}`;
    }
  }

  public ngOnChanges(): void {
    if (this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
  }

  public recarregar(): void {
    this.stopLoading = false;
    this.initError = null;
    this.resultadosPesquisa = null;
    this.pesquisarPagamentos();
  }

  private pesquisarPagamentos(): void {
    this.pagamentoService.consultarPagamentos(this.parametrosPesquisa)
      .pipe(take(1))
      .subscribe(
        response => {
          this.stopLoading = true;
          this.resultadosPesquisa = response;
          this.currentPage.last = this.resultadosPesquisa.length > this.pageSize ? this.pageSize : this.resultadosPesquisa.length;
        },
        error => {
          this.initError = error;
          this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', error);
        }
      );
  }

  public expandeTodosDetalhes(show: boolean): void {
    this.detalhesQuery.forEach(detalhe => {
      detalhe.show = show;
    });
  }

  public emitirComprovante(resultado: DocumentoArrecadacao): void {
    this.loadingService.show();

    this.pagamentoComprovanteService.obterComprovantePagamento(resultado.numeroPagamento).subscribe(
      response => {
        this.loadingService.hide();
        this.pagamentoComprovanteService.visualizarComprovante(response, 'Comprovante de Pagamento');
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException('Ocorreu um erro na emissão do comprovante de pagamento', error);
      }
    );
  }

  public iniciarRetificacaoSimplificada(documentoArrecadacao: DocumentoArrecadacao): void {
    this.iniciarRetificacao(documentoArrecadacao, TipoRetificacao.SIMPLIFICADA);
  }

  public iniciarRetificacaoCompleta(documentoArrecadacao: DocumentoArrecadacao): void {
    this.iniciarRetificacao(documentoArrecadacao, TipoRetificacao.COMPLETA);
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(row => row.show = show);
  }

  private iniciarRetificacao(documentoArrecadacao: DocumentoArrecadacao, tipoRetificacao: TipoRetificacao): void {
    this.loadingService.show();
    this.pagamentoService.obterCamposAlteraveis(documentoArrecadacao, tipoRetificacao).subscribe(
      response => {
        let camposAlteraveisResponse = response;
        this.loadingService.hide();

        if (this.atendimento) {
          let item: FeatureAction = {
            id: `pagamento_${documentoArrecadacao.numeroPagamento}`,
            title: `Pagamento - Retificação ${tipoRetificacao}`,
            class: RetificacaoPagamentoComponent,
            visible: true,
            rolesAllowed: [],
            tipoMenu: TipoMenu.ATENDIMENTO,
            parametros: {
              parametrosPesquisa: this.parametrosPesquisa,
              atendimento: this.atendimento,
              documentoArrecadacao: documentoArrecadacao,
              camposAlteraveisResponse: camposAlteraveisResponse,
              tipoRetificacao: tipoRetificacao
            }
          };

          this.menuFeatureActionsService.activate(item);
        }
        else {
          let feature = new FeatureAction();
          feature.class = RetificacaoPagamentoComponent;
          feature.parametros = {
            parametrosPesquisa: this.parametrosPesquisa,
            documentoArrecadacao: documentoArrecadacao,
            camposAlteraveisResponse: camposAlteraveisResponse,
            tipoRetificacao: tipoRetificacao,
          }

          this.dynamicModalRequest.abrirModal(feature);
        }
      },
      error => {
        this.loadingService.hide();
        if (error.error.message.includes('retificação simplificada')) {
          this.messageService.showErrorException('', error);
        } else {
          this.messageService.showErrorException('Ocorreu um erro ao verificar permissões', error);
        }
      });
  }

  public trocarAssinalamento(resultado: DocumentoArrecadacao): void {
    const trocaAssinalamentoFeature = {
      id: `PagamentoTrocaAssinalamento_pagamento_${resultado.numeroPagamento}`,
      title: `Pagamento - Trocar Assinalamento - ${resultado.numeroPagamento}`,
      class: TrocaAssinalamentoPagamentoComponent,
      parametros: {
        parametrosPesquisa: this.parametrosPesquisa,
        atendimento: this.atendimento,
        pagamento: resultado
      }
    } as FeatureAction;

    if (this.atendimento) {
      trocaAssinalamentoFeature.active = true;
      trocaAssinalamentoFeature.rolesAllowed = [];
      trocaAssinalamentoFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(trocaAssinalamentoFeature);
      return;
    }

    this.dynamicModalRequest.abrirModal(trocaAssinalamentoFeature);
  }

  public impedirAlocacaoAutomatica(resultado: DocumentoArrecadacao): void {
    this.loadingService.show();

    this.ccpfService.impedirAlocacaoAutomatica(resultado.ni, resultado.numeroPagamento).subscribe(
      _sucesso => {
        this.loadingService.hide();
        this.pagamentoService.atualizarListagemPagamento(this.parametrosPesquisa);
        this.messageService.showInfo('Operação Impedir Alocação Automática realizada com sucesso', `Pagamento ${resultado.numeroPagamento}`);
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException('Ocorreu um erro ao tentar executar a operação Impedir Alocação Automática', error);
      }
    );
  }

  public liberarAlocacaoAutomatica(resultado: DocumentoArrecadacao): void {
    this.loadingService.show();

    this.ccpfService.liberarAlocacaoAutomatica(resultado.ni, resultado.numeroPagamento).subscribe(
      _sucesso => {
        this.loadingService.hide();
        this.pagamentoService.atualizarListagemPagamento(this.parametrosPesquisa);
        this.messageService.showInfo('Operação Liberar Alocação Automática realizada com sucesso', `Pagamento ${resultado.numeroPagamento}`);
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException('Ocorreu um erro ao tentar executar a operação Liberar Alocação Automática', error);
      }
    );
  }
}
