import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DadosBasicosPjDTO } from '../model/dados-basicos-pj-dto';

@Injectable({
	providedIn: 'root'
})
export class CnpjService {

	private baseUrl = environment.APIEndpoint;

	constructor(private http: HttpClient) { }

	/**
	 * Obtem os dados básicos da Pessoa Jurídica
	 * @param cnpj 
	 */
	public obterDadosEmpresa(cnpj: string): Observable<DadosBasicosPjDTO> {
		const url = `${this.baseUrl}/cnpj/${cnpj}`;
		return this.http.get<DadosBasicosPjDTO>(url).pipe(take(1));
    }

}
