import { Component, ComponentFactoryResolver, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DynamicActionPanelComponent } from 'src/app/shared/dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { Feature, FeatureAction, TipoMenu } from '../../shared/model/feature';
import { TipoNI } from '../model/enum/tipo-ni';
import { ItensMenuAdicionais } from '../model/itens-menu';
import { InformacoesCompletasAtendimento } from '../model/protocolo';
import { SituacaoFiscalPessoaJuridicaDto } from '../model/situacao-fiscal-pj-dto';
import { InfoCadastraisPJService } from './info-cadastrais-pj/info-cadastrais-pj.service';
import { LiberacaoCndService } from './liberacao-cnd/liberacao-cnd.service';
import { FEATUREACTIONS_PJ } from './situacao-fiscal-pj.features';

/* Componente que engloba o menu de features e o painel de quadros. */
@Component({
  selector: 'via-situacao-fiscal-pj',
  templateUrl: './situacao-fiscal-pj.component.html',
  styleUrls: ['./situacao-fiscal-pj.component.css']
})
export class SituacaoFiscalPjComponent implements OnInit, OnChanges {

  @Input() situacaoFiscal: SituacaoFiscalPessoaJuridicaDto;

  /** Todas as opções do Menu em estrutura hierárquica */
  featureActions = null;
  itensMenuAdicionais: ItensMenuAdicionais[];

  // Ni
  @Input() cnpj: string;

  // opção do menu ativa
  @Input() feature: Feature;

  codEnte: string;
  infosAtendimento: InformacoesCompletasAtendimento;

  // elemento HTML conteiner dos paineis de funcionalidades
  @ViewChild('widgets', { read: ViewContainerRef, static: true })
  private readonly _dashboard: ViewContainerRef;

  public get dashboard(): ViewContainerRef {
    return this._dashboard;
  }

  // notificador de componente destruido
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  // colecao de quadros apresentados no dashboard
  private quadrosDinamicosAtivos: Map<string, DynamicActionPanelComponent> = new Map();

  constructor(
    private atendimentoService: AtendimentoService,
    private menuFeatureActionService: MenuFeatureActionsService,
    private logService: LogService,
    private messageService: ScopedMessageService,
    private resolver: ComponentFactoryResolver,
    private infoCadastraisService: InfoCadastraisPJService,
    private liberacaoService: LiberacaoCndService) { }

  ngOnInit() {
    this.menuFeatureActionService.featureActionEvent$
      .pipe(takeUntil(this.ngUnsubscribe)) // desregistra quando o componente foi encerrado
      .subscribe(featureEvent => {
        // se o quadro pode ser carregado dinamicamente
        if (featureEvent.featureAction.class && featureEvent.featureAction.tipoMenu == TipoMenu.PESSOAJURIDICA) {
          this.logService.logStep('SituacaoFiscalPjComponent.ngOnInit', 'menuFeatureActionService.featureActionEvent', featureEvent);
          // se o quadro está sendo aberto e ainda não está aberto
          if (featureEvent.active && !this.quadrosDinamicosAtivos.has(featureEvent.featureAction.id)) {
            const widget = this.createWidgetPanel(featureEvent.featureAction, this.cnpj);
            // registra um quadro ativo aberto
            this.quadrosDinamicosAtivos.set(featureEvent.featureAction.id, widget);
          } else if (!featureEvent.active && this.quadrosDinamicosAtivos.has(featureEvent.featureAction.id)) {
            // fecha se quadro aberto
            this.quadrosDinamicosAtivos.get(featureEvent.featureAction.id).closeRequest();
            featureEvent.active = false;
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.infosAtendimento = this.atendimentoService.getInfosAtendimento(this.cnpj);
    this.situacaoFiscal.tipoNI = TipoNI.CNPJ;
    this.itensMenuAdicionais = [];
    if (this.situacaoFiscal.indicadorEnteFederativo) {
      this.infoCadastraisService.obterInformacoesCadastraisPJ(this.cnpj, this.infosAtendimento.protocoloSitFis.numeroProtocolo).subscribe(
        (info) => {
          this.codEnte = info.codigoEnteFederativoResponsavel;
          this.liberacaoService.obterLiberacaoCnd(TipoNI.CNPJ, this.cnpj, info.codigoEnteFederativoResponsavel).subscribe(
            (liberacao) => {
              if (liberacao.length > 0) {
                this.itensMenuAdicionais.push(ItensMenuAdicionais.LiberacaoCnd);
              }
              this.constroiMenu();
            }, (erro) => {
              this.messageService.showErrorException('Ocorreu um erro ao verificar liberação de CND', erro);
              this.constroiMenu();
            }
          )
        }, (erro) => {
          this.messageService.showErrorException('Ocorreu um erro ao verificar Informações Cadastrais', erro);
        }
      );
    } else {
      this.constroiMenu();
    }
  }

  constroiMenu(): void {
    let featureActionsTmp = _.cloneDeep(FEATUREACTIONS_PJ);
    featureActionsTmp.forEach(m => this.menuFeatureActionService.ajustaVisibilidade(m, this.situacaoFiscal, this.itensMenuAdicionais, TipoMenu.PESSOAJURIDICA));
    featureActionsTmp = featureActionsTmp.filter(m => m.visible);
    this.featureActions = featureActionsTmp;
  }

  ngOnDestroy(): void {
    // notifica que o componente foi destruido
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.logService.logStep('SituacaoFiscalPjComponent.ngOnDestroy', 'componente destruído');
  }

  private createWidgetPanel(featureAction: FeatureAction, ni: string): DynamicActionPanelComponent {
    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `criando nova janela de chat`, featureAction);

    // cria um widget ActionPanelComponent
    const wFactory = this.resolver.resolveComponentFactory(DynamicActionPanelComponent);
    const wComponentRef = this._dashboard.createComponent<DynamicActionPanelComponent>(wFactory, 0);
    const widget = wComponentRef.instance;

    // registra para destruir o componente dinâmico quando ele for fechado
    widget.onFeatureClosed.pipe(take(1)).subscribe(featureId => {
      if (featureId) {
        this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', 'DynamicActionPanelComponent.onClose');
        wComponentRef.destroy();
        // remove o quadro fechado da lista de ativos
        this.quadrosDinamicosAtivos.delete(featureId);
      }
    });

    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `widgets`, this._dashboard);
    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `componente DynamicActionPanelComponent criado`, widget);
    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `vai instanciar o painel interno`, featureAction.class);

    // instancia um componente de painel como filho do widget
    widget.instantiatePanel(featureAction, ni);
    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `painel instanciado com sucesso`);

    this.logService.logStep('SituacaoFiscalPjComponent.createWidgetPanel', `contador de widgets criados: ${this._dashboard.length}`);

    return widget;
  }
}
