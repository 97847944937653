import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { AcaoFiscalSuiteRfbModule } from './acao-fiscal-suite-rfb.module';
import { AcaoFiscalComponent } from './components/acao-fiscal.component';
import { AcaoFiscalTableComponent } from './components/table/acao-fiscal-table.component';
import { AcaoFiscalService } from './services/acao-fiscal.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    PipesModule.forRoot(),
    AcaoFiscalSuiteRfbModule,
  ],
  declarations: [
    AcaoFiscalComponent,
    AcaoFiscalTableComponent
  ],
  exports: [
    AcaoFiscalComponent
  ],
  providers: [
    AcaoFiscalService
  ]
})
export class AcaoFiscalModule { }
