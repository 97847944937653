<suite-rfb-collapse [title]="titulo" [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)">
  <div class="container-fluid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label [for]="cnpjFormName" class="control-label">CNPJ Básico ou Completo</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              mask="00.000.000||00.000.000/0000-00"
              [formControlName]="cnpjFormName">
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label [for]="anoCalendarioFormName" class="control-label">Ano-Calendário</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              minlength="4"
              maxlength="4"
              [formControlName]="anoCalendarioFormName">
          </div>
        </div>

        <div class="col-md-5" style="margin-top: 25px;">
          <div class="form-group">
            <div class="text-center">
              <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
              <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Buscar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>

<via-consulta-obrigatoriedade-dirf
  *ngIf="exibeResultado"
  [cnpj]="cnpj"
  [anoCalendario]="anoCalendario"
  [atendimento]="true"
  [parentScopeId]="scopeId"
  (onError)="esconderResultado()">
</via-consulta-obrigatoriedade-dirf>
