<suite-rfb-collapse [title]="titulo">
  <div *ngIf="(parcelamentosObservable$ | async) as parcelamentos; else loadingOrError">
    <via-parcelamento-siefpar-table [parcelamentos]="parcelamentos"></via-parcelamento-siefpar-table>
  </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>