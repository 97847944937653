import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codigoDescricao'
})
export class CodigoDescricaoPipe implements PipeTransform {

  public transform(value: ICodigoDescricao, separator: string = '-'): string {
    if (value) {
      if (value.codigo && value.descricao) {
        return `${value.codigo} ${separator} ${value.descricao}`;
      }
    }
    return '';
  }

}

export interface ICodigoDescricao {
  codigo: string;
  descricao: string;
}