export class AusenciaDeclaracao {
    tipoDeclaracao: TipoDeclaracao;
    tipoPaExercicio: TipoPaExercicio;
    periodoApuracaoOmissao: string[];
    inscricaoGdf: string;
    tipoObrigatoriedadeOmissao: string[];
    motivoOmissao: string[];
    tpCnpjCei: string;
    nuCnpjCei: string;
    inCnpjCei: string;
    ni: string;
    codigoImovel: string;
    codigoImovelVinculado: string;
}

export enum TipoDeclaracao {
    DCTF = "DCTF",
    DIRF = "DIRF",
    DEFIS = "DEFIS",
    DIRPF = "DIRPF",
    SIMEI = "SIMEI",
    OBRAP = "OBRAP",
    DITR = "DITR",
    GFIP = "GFIP",
    PGDAS = "PGDAS",
    EFD = "EFD",
    ECF = "ECF",
}

export enum TipoPaExercicio {
    ANUAL = "Anual",
    MENSAL = "Mensal",
    TRIMESTRAL = "Trimestral",
    SEMESTRAL = "Semestral",
}
