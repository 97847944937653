import { Type } from '@angular/core';
import { Feature as SuiteFeature } from '@serpro/ngx-suite-rfb';
import { IDynamicPanel } from '../dynamic-panel/panel-interface';

export class Feature implements SuiteFeature {
    id: string;
    collapsed: boolean;

    title: string;
    route?: string;
    url?: string;
    icon?: string;
    favorite?: boolean;
    menu?: boolean;
    linked?: boolean;
    children?: Feature[];
    rolesAllowed?: string[];
    shortcut?: string;
}

export class FeatureAction {
    id: string;
    title: string;
    tag?: string;
    collapsed?: boolean;
    children?: FeatureAction[];
    active?: boolean;
    visible?: boolean = false;
    rolesAllowed?: string[];
    tipoMenu?: TipoMenu;
    class?: Type<IDynamicPanel>;
    url?: string;
    parametros?: any;
}

export enum TipoMenu {
    PRINCIPAL,
    VINCULADO,
    ATENDIMENTO,
    PESSOAFISICA,
    PESSOAJURIDICA,
}
