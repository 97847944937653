<fieldset #fs class="service-container">
    <suite-rfb-alert-messages-with-datetime modal [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div style="position: relative; z-index: 99;">
      <div *ngIf="imprimir" after-title title="Imprimir" class="link impressora naoimprimir"
        (click)="onPrintClick(); ">
        <span class="material-icons icon-18" style="font-size: 28px">print</span>
      </div>
      <div after-title title="Fechar" (click)="onCloseClick(); " class="link fechar">
            <span class="material-icons icon-18" style="font-size: 28px">close</span>
      </div>
    </div><div #container></div>
</fieldset>
