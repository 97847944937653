import { CcitrAlocaPagamentoComponent } from './../../aloca-pagamento/aloca-pagamento.component';
import { TipoNIConsultaCredito } from './../../../../../models/tipo-ni-consulta-credito';
import { CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { ParametrosPesquisaCcitr } from './../../../../../models/parametros-pesquisa-ccitr';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, LoadingService, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { Componente, CreditoTributario } from '../../../../../models/credito-tributario';
import { ParametroComponenteDebitoCcitr } from '../../aloca-pagamento/models/parametros-pesquisa';
// import { CCPFRevalidacaoDataCtComponent } from '../../revalidacao-data-ct/revalidacao-data-ct.component';

@Component({
  selector: 'via-ccitr-table-componentes-credito',
  templateUrl: 'consulta-credito-componentes-table.component.html',
  styleUrls: ['consulta-credito-componentes-table.component.css','../../../base/ccitr.css']
})
export class CcitrConsultaCreditoComponentesTableComponent implements OnInit {

  @Input() atendimento;
  @Input() cpf: string;
  @Input() nirf: string;
  @Input() credito: CreditoTributarioCcitr;
  @Input() componentes: Componente[];
  @Input() exibirAcoes = true;
  @Input() parametrosPesquisa: ParametrosPesquisaCcitr;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  semComponente = false;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService)
  {

  }

  ngOnInit(): void {
    this.semComponente = isEmpty(this.componentes[0].numero);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => {
      toggle.show = show;
    });
  }

  alocarPagamento(componente: Componente): void {
    let niDeclarante = this.credito.niDeclarante;

    const parametros = {
      cpfContribuinte: this.credito.tipoNiDeclarante == TipoNIConsultaCredito.CPF ?  niDeclarante.substring(3,niDeclarante.length) : "",
      cnpjContribuinte: this.credito.tipoNiDeclarante == TipoNIConsultaCredito.CNPJ ?  niDeclarante: "",
      credito: this.credito,
      nirf: this.nirf,
      componente: componente,
      atendimento: this.atendimento,
      parametrosPesquisa: this.parametrosPesquisa
    } as ParametroComponenteDebitoCcitr;

    const AlocarPagamentoFeature = {
      id: `ConsultaCCITR_AlocarPagamento_${componente.numero}`,
      title: "CCITR - Alocação de Pagamento:",
      class: CcitrAlocaPagamentoComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      AlocarPagamentoFeature.active = true;
      AlocarPagamentoFeature.rolesAllowed = [];
      AlocarPagamentoFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(AlocarPagamentoFeature);
      return;
    }

    this.dynamicModalService.abrirModal(AlocarPagamentoFeature);


  }

}
