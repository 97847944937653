import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { CnpjSuiteRfbModule } from './cnpj-suite-rfb.module';
import { ConsultaPjModule } from './components/consulta-pj/consulta-pj.module';
import { DadosBasicosPJModule } from './components/dados-basicos-pj/dados-basicos-pj.module';
import { EventosPjModule } from './components/eventos/eventos-pj.module';
import { FiltrosPesquisaPjModule } from './components/filtros-pesquisa-pj/filtros-pesquisa-pj.module';
import { HistoricoPjModule } from './components/historico/historico-pj.module';
import { OperacaoSucessaoModule } from './components/operacao-sucessao/operacao-sucessao.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    PipesModule.forRoot(),
    CnpjSuiteRfbModule,
    DadosBasicosPJModule,
    ConsultaPjModule,
    FiltrosPesquisaPjModule,
    OperacaoSucessaoModule,
    HistoricoPjModule,
    EventosPjModule,
  ],
  providers: [
    DatePipe
  ]
})
export class CnpjModule { }
