import { Component, QueryList, ViewChildren } from '@angular/core';
import { ToggleComponent } from '@serpro/ngx-suite-rfb';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ProcessoProfisc } from './model/processo-profisc';
import { ProcessoProfiscService } from './processo-profisc.service';

@Component({
  selector: 'via-processo-profisc',
  templateUrl: './processo-profisc.component.html',
  styleUrls: ['./processo-profisc.component.css']
})
export class ProcessoProfiscComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  numeroProtocolo: string;
  processos: ProcessoProfisc[];
  parametros: any;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  constructor(
    private processoProfiscService: ProcessoProfiscService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) { }

  public init(ni: string, parametros: any): void {
    this.ni = ni;
    this.parametros = parametros;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    this.numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let processosObservable: Observable<ProcessoProfisc[]>;
      if (this.isAtivos) {
        processosObservable = this.processoProfiscService.obterProcessosProfiscAtivos(ni, this.numeroProtocolo);
      }
      if (this.isSuspensos) {
        processosObservable = this.processoProfiscService.obterProcessosProfiscSuspensos(ni, this.numeroProtocolo);
      }

      processosObservable.subscribe(
        (resp) => {
          this.processos = resp;
          this.logService.logAjaxReturn('ProcessoProfiscComponent', 'obterProcessos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ProcessoProfiscComponent', 'obterProcessos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os processos PROFISC', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ProcessoProfiscComponent', 'obterProcessos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os processos PROFISC', e);
    }
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}

@Component({
  selector: 'via-processo-profisc',
  templateUrl: './processo-profisc.component.html',
  styleUrls: ['./processo-profisc.component.css']
})
export class ProcessoProfiscPJAtivoComponent extends ProcessoProfiscComponent {
  init(ni: string, parametros: any) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Processos Ativos PROFISC - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-profisc',
  templateUrl: './processo-profisc.component.html',
  styleUrls: ['./processo-profisc.component.css']
})
export class ProcessoProfiscPJSuspensoComponent extends ProcessoProfiscComponent {
  init(ni: string, parametros: any) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Processos Suspensos PROFISC - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-profisc',
  templateUrl: './processo-profisc.component.html',
  styleUrls: ['./processo-profisc.component.css']
})
export class ProcessoProfiscPFAtivoComponent extends ProcessoProfiscComponent {
  init(ni: string, parametros: any) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Processos Ativos PROFISC - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-profisc',
  templateUrl: './processo-profisc.component.html',
  styleUrls: ['./processo-profisc.component.css']
})
export class ProcessoProfiscPFSuspensoComponent extends ProcessoProfiscComponent {
  init(ni: string, parametros: any) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Processos Suspensos PROFISC - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni, parametros);
  }
}
