import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedSuiteRfbModule } from "src/app/shared/shared-suite-rfb.module";
import { SharedModule } from "src/app/shared/shared.module";
import { QuadroSocietarioComponent } from "./quadro-societario.component";
import { QuadroSocietarioService } from "./quadro-societario.service";
import { QuadroSocietarioTableComponent } from './table/quadro-societario-table.component';

@NgModule({
  declarations: [
    QuadroSocietarioComponent,
    QuadroSocietarioTableComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forChild(),
    NgPipesModule,
    SharedModule,
    PipesModule,
    SharedSuiteRfbModule,
  ],
  providers: [
    QuadroSocietarioService
  ],
  exports: [
    QuadroSocietarioComponent
  ]
})
export class QuadroSocietarioModule { }
