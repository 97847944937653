import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodigoDescricaoDTO } from 'src/app/cnpj/model/codigo-descricao-dto';
import { environment } from 'src/environments/environment';
import { CampoAlteravel } from '../models/campo-alteravel';
import { DocumentoArrecadacao } from '../models/documento-arrecadacao';
import { NaturezaDocumentoArrecadacao } from '../models/natureza-documento-arrecadacao';
import { ParametrosPesquisaPagamento } from '../models/parametros-pesquisa';
import { SistemaInteresse } from '../models/sistema-interesse';
import { TipoRetificacao } from '../models/tipo-retificacao';
import { ValoresReceita } from '../models/valoresReceita';

@Injectable()
export class PagamentoService {

  private url = environment.APIEndpoint;
  public atualizarListagemEmitter: EventEmitter<ParametrosPesquisaPagamento> = new EventEmitter();

  constructor(private httpClient: HttpClient) { }

  public atualizarListagemPagamento(parametros: ParametrosPesquisaPagamento): void {
    this.atualizarListagemEmitter.emit(parametros);
  }

  public obterNaturezasDocumentoArrecadacao(): Observable<NaturezaDocumentoArrecadacao[]> {
    return this.httpClient.get<NaturezaDocumentoArrecadacao[]>(`${this.url}/pagamentos/naturezas`);
  }

  public consultarPagamentos(parametros: ParametrosPesquisaPagamento): Observable<DocumentoArrecadacao[]> {
    return this.httpClient.post<DocumentoArrecadacao[]>(`${this.url}/pagamentos/documentos`, parametros);
  }

  public obterCamposAlteraveis(documentoArrecadacao: DocumentoArrecadacao, tipoRetificacao: TipoRetificacao): Observable<CampoAlteravel[]> {
    if (tipoRetificacao == TipoRetificacao.COMPLETA) {
      return this.httpClient.post<CampoAlteravel[]>(`${this.url}/pagamentos/retificacao-completa/campos-alteraveis`, documentoArrecadacao);
    } else {
      return this.httpClient.post<CampoAlteravel[]>(`${this.url}/pagamentos/retificacao-simplificada/campos-alteraveis`, documentoArrecadacao);
    }
  }

  public calcularValores(pagamentoAlterado: DocumentoArrecadacao): Observable<ValoresReceita[]> {
    return this.httpClient.post<ValoresReceita[]>(`${this.url}/pagamentos/valores-receitas`, pagamentoAlterado);
  }

  public realizarRetificacao(pagamentoAlterado: DocumentoArrecadacao, tipoRetificacao: TipoRetificacao): Observable<ValoresReceita[]> {
    if (tipoRetificacao == TipoRetificacao.COMPLETA) {
      return this.httpClient.put<ValoresReceita[]>(`${this.url}/pagamentos/retificacao-completa`, pagamentoAlterado);
    } else {
      return this.httpClient.put<ValoresReceita[]>(`${this.url}/pagamentos/retificacao-simplificada`, pagamentoAlterado);
    }
  }

  public obterSistemasInteresse(documentoArrecadacao: DocumentoArrecadacao): Observable<SistemaInteresse> {
    const url = `${this.url}/pagamentos/sistemas-interesse`;
    return this.httpClient.post<SistemaInteresse>(url, documentoArrecadacao);
  }

  public trocarAssinalamento(documentoArrecadacao: DocumentoArrecadacao, sistemaSelecionado: CodigoDescricaoDTO): Observable<Object> {
    const request = { documentoArrecadacao, sistemaSelecionado };
    const url = `${this.url}/pagamentos/troca-assinalamento`;
    return this.httpClient.put(url, request);
  }
}
