import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageService { 

  constructor(
    private cookieService: CookieService,
  ) { }

  getLogonDate(): string {
    let camWebPin = this.cookieService.get('CAMWebPIN');
    let key = 'cipheredLogonDate@' + camWebPin;
    return sessionStorage.getItem(key);
  }

  setLogonDate(value: string): void {
    let camWebPin = this.cookieService.get('CAMWebPIN');
    let key = 'cipheredLogonDate@' + camWebPin;
    sessionStorage.setItem(key, value);
  }

  clearLogonDate(): void {
    Object.keys(sessionStorage).forEach (
      (element) => {
        if(element.startsWith('cipheredLogonDate@')) {
          sessionStorage.removeItem(element);
        }
      }
    )
  }

}
