import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuFeatureActionsService } from '../menu-feature-actions/menu-feature-actions.service';
import { FeatureAction } from '../model/feature';
import { LogService } from '../services/log.service';

@Component({
  selector: 'app-menu-feature-action',
  templateUrl: './menu-feature-action.component.html',
  styleUrls: ['./menu-feature-action.component.css']
})
export class MenuFeatureActionComponent implements OnInit, OnChanges, OnDestroy {

  @Input() featureAction: FeatureAction;
  @Input() root: boolean;
  
  collapsed: boolean;
  featureEventSub: Subscription;

  constructor(private menuFeatureActionService: MenuFeatureActionsService,
              private logService: LogService) { }

  ngOnInit() {
    // se for feature ativada no evento então seta como ativa
    this.featureEventSub = this.menuFeatureActionService.featureActionEvent$
    .subscribe(featureEvent => {
      // se a chave de identificação da feature for a mesma
      if (this.featureAction.id && featureEvent.featureAction.id === this.featureAction.id && featureEvent.featureAction.tipoMenu == this.featureAction.tipoMenu) {
        this.logService.logStep('MenuFeatureActionComponent.featureEventSub ', 'feature selecionada',
         this.featureAction.id);
        this.featureAction.active = featureEvent.active;        
      }
    });
  }

  ngOnChanges() {
    this.collapsed = this.featureAction.collapsed == true || this.featureAction.collapsed == null;
  }

  ngOnDestroy(): void {
    this.logService.logStep('MenuFeatureActionComponent.ngOnDestroy', 'featureEventSub.unsubscribe()');
    this.featureEventSub.unsubscribe();
  }

  onClick(item: FeatureAction) {
    if(item.url) {
      window.open(item.url, '_blank');
    }
    else {
      if(!item.active) {
        this.menuFeatureActionService.activate(item);
      }
      else {
        this.menuFeatureActionService.deactivate(item);
      }
    }
  }
}
