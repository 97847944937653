import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbPaginationModule,
  SuiteRfbPopoverModule,
  SuiteRfbTableModule,
  SuiteRfbToggleModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ]
})
export class ProcessoProfiscSuiteRfbModule { }
