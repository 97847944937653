import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ParametrosPesquisaPessoaFisica } from 'src/app/cpf/models/parametros-pesquisa-pessoa-fisica';
import { ResumoPessoaFisica } from 'src/app/cpf/models/resumo-pessoa-fisica';
import { CpfService } from 'src/app/cpf/services/cpf.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ConsultaPfComponent } from '../../consulta-pf.component';

@Component({
  templateUrl: './consulta-dados-basicos-pf-table.component.html',
  styleUrls: ['./consulta-dados-basicos-pf-table.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaDadosBasicosPfTableComponent implements IDynamicPanel {

  @Input() parametrosPesquisa: ParametrosPesquisaPessoaFisica;

  resultadosPesquisa: ResumoPessoaFisica[];

  titulo = 'Pessoa Física - Consultar - Filtros de Pesquisa';

  scopeId: string;
  initError: Error;
  stopLoading = false;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private cpfService: CpfService,
    private dynamicModalService: DynamicModalRequestService,
    private messageService: ScopedMessageService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.scopeId = this.messageService.scopeId;

    this.pesquisarPessoaFisica();
  }

  onConsultarCpf(cpf: string): void {
    const consultarPfFeature = {
      id: `ConsultarPessoaFisica_${cpf}`,
      title: 'Pessoa Física - Consultar',
      class: ConsultaPfComponent,
      parametros: {
        cpf: cpf,
        isModal: true,
        imprimir: true,
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultarPfFeature);
  }

  private pesquisarPessoaFisica(): void {
    this.cpfService.pesquisarPessoaFisica(this.parametrosPesquisa)
      .subscribe(
        response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }

          this.resultadosPesquisa = response;
        },
        error => {
          this.messageService.showErrorException('Falha ao realizar a consulta de Pessoa Física', error);
          this.initError = error;
        }
      );
  }
}
