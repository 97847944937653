<suite-rfb-collapse [title]="titulo">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div class="container-fluid">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-4">
              <label class="label-formulario control-label">Data de Vencimento</label>
              <p class="dado-formulario">
                {{ dataVencimento | formatDataPipe}}
              </p>
            </div>
          </div>
            <div class="row">
              <div class="col-md-4">
                  <div class="form-group">
                    <label class="label-formulario control-label">Data Ciência AI</label>
                    <div class="form-group dado-formulario" [suiteRfbHasError]="dataCienciaAICtrl">
                      <suite-rfb-date-picker
                        adaptivePosition="true"
                        outsideClick="true"
                        required="true"
                        direction="right"
                        [formControlName]="dataCienciaAIName">
                      </suite-rfb-date-picker>
                    </div>
                  </div>
              </div>
          </div>
            <div class="row">
              <div class="col-md-4 botoes-margem" >
                <div class="form-group">
                  <button class="btn btn-default" type="reset" [disabled]="!form.dirty">Limpar</button>
                  <button class="btn btn-primary" type="submit" [disabled]="form.invalid">Alterar</button>
                  <!--<button type="button" class="btn btn-default" (click)="onConfirmarTroca()" [disabled]="formGroup.invalid">Confirmar</button> -->
                </div>
              </div>
            </div>
      </form>
  </div>
</suite-rfb-collapse>

