import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbPaginationModule,
  SuiteRfbTableModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbTableModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbTableModule
  ]
})
export class ConsultaTituloEleitorSuiteRfbModule { }
