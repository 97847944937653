<table suiteRfbTable>
  <caption class="caption-ccpf">Componentes</caption>
  <ng-container *ngIf="!semComponente; else nenhumComponente;">
    <thead>
      <tr>
        <th class="text-center min-width">Número</th>
        <th class="text-center min-width">Data Vencimento</th>
        <th class="text-center min-width">Data Alocação</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Valor Alocado</th>
        <th class="text-center">Saldo Devedor</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let componente of componentes" class="vertical-align-middle">
        <tr class="vertical-align-middle">
          <td class="text-center">{{ (componente.numeroComponente + ' ' + componente.indicadorEncargo) | vazio }}</td>
          <td class="text-center">{{ componente.dataVencimento | formatDataPipe }}</td>
          <td class="text-center">{{ componente.alocacao.dataAlocacao | formatDataPipe }}</td>
          <td class="text-right">{{ componente.valorOriginario | formatCurrency }}</td>
          <td class="text-right">{{ componente.alocacao.valorAlocado | formatCurrency }}</td>
          <td class="text-right">{{ componente.valorSaldo | formatCurrency }}</td>
          <td class="text-left">{{ componente.situacao | vazio }}</td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
  <ng-template #nenhumComponente>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum componente encontrado para este débito.
      </td>
    </tr>
  </ng-template>
</table>
