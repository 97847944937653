import { NgModule } from '@angular/core';
import {
  SuiteRfbAlertMessagesModule,
  SuiteRfbCollapseModule,
  SuiteRfbConfirmationsModule,
  SuiteRfbCurrencyModule,
  SuiteRfbDatePickerModule,
  SuiteRfbExpandedMenuModule,
  SuiteRfbFeaturesModule,
  SuiteRfbFilePickerModule,
  SuiteRfbFilterByModule,
  SuiteRfbFooterModule,
  SuiteRfbHeaderModule,
  SuiteRfbImgModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule,
  SuiteRfbLoadingModule,
  SuiteRfbMenuModule,
  SuiteRfbMonthPickerModule,
  SuiteRfbNavbarModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbPaginationModule,
  SuiteRfbPopoverModule,
  SuiteRfbSelectModule,
  SuiteRfbSidebarModule,
  SuiteRfbSortByModule,
  SuiteRfbTableModule,
  SuiteRfbTabsModule,
  SuiteRfbToggleModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbTabsModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbSelectModule,
    SuiteRfbDatePickerModule,
    SuiteRfbFilePickerModule,
    SuiteRfbMonthPickerModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbCollapseModule,
    SuiteRfbCurrencyModule,
    SuiteRfbPaginationModule,
    SuiteRfbFilterByModule,
    SuiteRfbSortByModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbExpandedMenuModule,
    SuiteRfbFeaturesModule,
    SuiteRfbHeaderModule,
    SuiteRfbNavbarModule,
    SuiteRfbFooterModule,
    SuiteRfbAlertMessagesModule,
    SuiteRfbLoadingModule,
    SuiteRfbMenuModule,
    SuiteRfbSidebarModule,
    SuiteRfbImgModule
  ],
  exports: [
    SuiteRfbTabsModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbSelectModule,
    SuiteRfbDatePickerModule,
    SuiteRfbFilePickerModule,
    SuiteRfbMonthPickerModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbCollapseModule,
    SuiteRfbCurrencyModule,
    SuiteRfbPaginationModule,
    SuiteRfbFilterByModule,
    SuiteRfbSortByModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbExpandedMenuModule,
    SuiteRfbFeaturesModule,
    SuiteRfbHeaderModule,
    SuiteRfbNavbarModule,
    SuiteRfbFooterModule,
    SuiteRfbAlertMessagesModule,
    SuiteRfbLoadingModule,
    SuiteRfbMenuModule,
    SuiteRfbSidebarModule,
    SuiteRfbImgModule
  ]
})
export class SharedSuiteRfbModule { }
