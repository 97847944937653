import { Pipe, PipeTransform } from '@angular/core';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';

const regex = new RegExp('{-?[0-9]+}', 'g');

/**
 * Substitui na string informada o texto descritivo relativo ao tipo de NI informado. 
 */
@Pipe({
  name: 'tipoNiDescricao'
})
export class TipoNIDescricaoPipe implements PipeTransform {

  transform(value: string, type: TipoNI): string {
    return this.replace(value, this.replacer(type));
  }

  private replacer(type: TipoNI): string {
    switch (type) {
      case TipoNI.CPF:
        return 'Pessoa Física';
      case TipoNI.CNPJ:
        return 'Pessoa Jurídica';
      default:
        return '';
    }
  }

  private replace(value: string, replacer: string): string {
    return value.replace(regex, item => {
      const intVal = parseInt(item.substring(1, item.length - 1));
      if (intVal >= 0) {
        return replacer;
      } else if (intVal === -1) {
        return '{';
      } else if (intVal === -2) {
        return '}';
      } else {
        return '';
      }
    });
  }

}
