<fieldset class="vinculos-container float-left" [hidden]="!menuFeature.collapsed">
  <suite-rfb-collapse [(collapsed)]="vinculosFeature.collapsed" title="Vínculos" vertical="true">
    <span before-title class="feature-icon material-icons icon-24">link</span>
    <div class="row" *ngFor="let vinculo of vinculos">
      <span class="vinculo">
        <span tooltip="Consultar os Dados Cadastrais"
          class="link"
          (click)="abrirDadosVinculado(vinculo)">
          {{ vinculo.getDescricao() | formatCpfCnpjPipe }}
        </span>
        <span (click)="abrirMenuVinculado(vinculo)" style="cursor: pointer" class="material-icons icon-18">menu</span>
      </span>
    </div>
  </suite-rfb-collapse>
</fieldset>

<div class="feature">
  <div class="float-left" [hidden]="menuFeature.collapsed">
    <fieldset class="menu-feature vinculos-menu">
      <app-menu-feature-actions (collapsedChange)= "onMenuCollapsedChange($event)" [feature]="menuFeature" [featureActions]="featureActions"></app-menu-feature-actions>
    </fieldset>
  </div>

  <div class="feature-services">
    <div #widgets style="border: 2px"></div>
  </div>
</div>

