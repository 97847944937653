<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <div class="container-dados">
      <div class="row">
        <div class="col-md-2">
          <label class="label-formulario control-label">Receita</label>
          <via-codigo-receita [codigo]="pagamento.codigoReceita"></via-codigo-receita>
        </div>
        <div class="col-md-2">
          <label class="label-formulario control-label">Data Arrec.</label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.dataArrecadacao | date: 'dd/MM/yyyy' | vazio }}
          </p>
        </div>
        <div class="col-md-2">
          <label class="label-formulario control-label">Data Venc.</label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.dataVencimento | date: 'dd/MM/yyyy' | vazio }}
          </p>
        </div>
        <div class="col-md-2">
          <label class="label-formulario control-label">PA</label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.periodoApuracao | vazio }}
          </p>
        </div>
        <div class="col-md-4">
          <label class="label-formulario control-label">Valor Total</label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorTotal | formatCurrency | vazio }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <label class="label-formulario control-label">Sistema de Interesse Atual</label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.sistemaInteresseDesc | vazio }}
          </p>
        </div>
        <div class="col-md-4">
          <label class="label-formulario control-label">Sistema de Interesse Indicado</label>
          <p class="form-control-static dado-formulario">
            {{ sistemaInteresseIndicado?.descricao | vazio }}
          </p>
        </div>
        <div class="col-md-4">
          <label class="label-formulario control-label">Selecione um Sistema de Interesse</label>
          <ng-container *ngTemplateOutlet="sistemasInteresseTemplate"></ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="btn-group">
          <button type="button" class="btn btn-default" (click)="onConfirmarTroca()" [disabled]="formGroup.invalid">Confirmar</button>
        </div>
      </div>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #sistemasInteresseTemplate>
  <ng-container *ngIf="sistemasInteresseDisponiveis?.length > 0; else nenhumSistemaTemplate;">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="formGroup" novalidate>
          <suite-rfb-select
            unselectedLabel="Selecione um Sistema de Interesse"
            [inline]="true"
            [formControlName]="sistemasInteresseFormName"
            [values]="sistemasInteresseDisponiveis"
            [labels]="sistemasInteresseDisponiveis | sistemasInteressePipe">
          </suite-rfb-select>
        </form>
      </div>
    </div>
  </ng-container>

  <ng-template #nenhumSistemaTemplate>
    <p class="form-control-static">Nenhum sistema de interesse disponível</p>
  </ng-template>
</ng-template>
