import { Component } from '@angular/core';
import { LocalDate } from '@js-joda/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { FormatNirfPipe } from 'src/app/shared/pipes/formata-nirf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DateUtilsService } from 'src/app/shared/util/date-utils.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { ParametrosDarf, SistemaOrigemDarf } from '../../model/parametros-darf';
import { DarfService } from '../darf/darf.service';
import { ContaCorrenteItrService } from './conta-corrente-itr.service';
import { DebitoContaCorrenteItr, DebitosCCITRGroupedByCodigoReceitaGroupedByNirf } from './model/debito-conta-corrente-itr';

@Component({
  selector: 'app-conta-corrente-itr',
  templateUrl: './conta-corrente-itr.component.html',
  styleUrls: ['./conta-corrente-itr.component.css']
})
export class ContaCorrenteItrComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  protocolo: string;

  debitosAgrupados: DebitosCCITRGroupedByCodigoReceitaGroupedByNirf;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private contaCorrenteItrService: ContaCorrenteItrService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private darfService: DarfService,
    private dateUtilsService: DateUtilsService) {
    console.log('ContaCorrenteItrComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    this.protocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    try {
      let debitosAgrupadosObservable: Observable<DebitosCCITRGroupedByCodigoReceitaGroupedByNirf>;
      if (this.isAtivos) {
        debitosAgrupadosObservable = this.agrupaPorNirfCodigoReceita(
          this.contaCorrenteItrService.obterDebitosContaCorrenteItrAtivos(ni, numeroProtocolo), ni);
      }
      if (this.isSuspensos) {
        debitosAgrupadosObservable = this.agrupaPorNirfCodigoReceita(
          this.contaCorrenteItrService.obterDebitosContaCorrenteItrSuspensos(ni, numeroProtocolo), ni);
      }

      debitosAgrupadosObservable.subscribe(
        (resp) => {
          this.debitosAgrupados = resp;
          this.logService.logAjaxReturn('ContaCorrenteItrComponent', 'obterDebitos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ContaCorrenteItrComponent', 'obterDebitos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os débitos conta corrente ITR', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ContaCorrenteItrComponent', 'obterDebitos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os débitos conta corrente ITR', e);
    }
  }

  private agrupaPorNirfCodigoReceita(debitosObservable: Observable<DebitoContaCorrenteItr[]>, ni: string): Observable<DebitosCCITRGroupedByCodigoReceitaGroupedByNirf> {
    return debitosObservable.pipe(
      //tap(val => console.log(`debitos ITR 1: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => {
        debitos.forEach(debito => debito.niEstabelecimentoDebito = ni);
        return debitos;
      }),
      //tap(val => console.log(`debitos ITR 2: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => _.orderBy(debitos, ['nirf', 'codigoReceita', 'exercicio', 'dataVencimento'], ['asc', 'asc', 'desc', 'desc'])),
      //tap(val => console.log(`debitos ITR 3: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => _.groupBy(debitos, (debito) => this.geraDescricaoGrupo(debito))),
      //tap(val => console.log(`debitos ITR 4: ${JSON.stringify(val, undefined, 4)}`)),
      map(debitos => _.mapValues(debitos, (debitosPorNirf) => _.groupBy(debitosPorNirf, 'codigoReceita'))),
      //tap(val => console.log(`debitos ITR 5: ${JSON.stringify(val, undefined, 4)}`)),
    );
  }

  geraDescricaoGrupo(debito: DebitoContaCorrenteItr) {
    let descricao = FormatNirfPipe.formatNirf(debito.nirf);
    if (debito.nomeNIRF) {
      descricao = descricao + ' - ' + debito.nomeNIRF;
    }
    if (debito.nirfVinculado) {
      descricao = descricao + ' - Vinculado ao imóvel ' + FormatNirfPipe.formatNirf(debito.nirfVinculado);
    }
    return descricao;
  }

  public emitirDarf(debito: DebitoContaCorrenteItr): void {
    const diferencaDias = this.dateUtilsService.diasDesde(LocalDate.parse(debito.dataVencimento));
    const noPrazo = diferencaDias < 60;
    const msg = noPrazo ? 1 : 0;
    const parametros = this.preencherParametros(debito);
    this.darfService.emitirDarf(parametros, msg);
  }

  private preencherParametros(debito: DebitoContaCorrenteItr): ParametrosDarf {
    const parametros: ParametrosDarf = {
      protocolo: this.protocolo,
      tipoNi: this.isPessoaJuridica ? TipoNI.CNPJ : TipoNI.CPF,
      ni: this.ni,
      sistemaOrigem: SistemaOrigemDarf.CCITR,
      numeroDebito: debito.numeroDebito,
      numeroProcesso: '',
      numeroImovel: debito.nirf,
      codigoReceita: debito.codigoReceita,
      numeroComponente: debito.numeroComponente,
      exercicio: debito.exercicio,
    }
    return parametros;
  }
}

@Component({
  selector: 'app-conta-corrente-itr',
  templateUrl: './conta-corrente-itr.component.html',
  styleUrls: ['./conta-corrente-itr.component.css']
})
export class ContaCorrenteItrPJAtivoComponent extends ContaCorrenteItrComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos Fazendários Conta Corrente ITR - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-conta-corrente-itr',
  templateUrl: './conta-corrente-itr.component.html',
  styleUrls: ['./conta-corrente-itr.component.css']
})
export class ContaCorrenteItrPJSuspensoComponent extends ContaCorrenteItrComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos Fazendários Conta Corrente ITR - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-conta-corrente-itr',
  templateUrl: './conta-corrente-itr.component.html',
  styleUrls: ['./conta-corrente-itr.component.css']
})
export class ContaCorrenteItrPFAtivoComponent extends ContaCorrenteItrComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Débitos Ativos Fazendários Conta Corrente ITR - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-conta-corrente-itr',
  templateUrl: './conta-corrente-itr.component.html',
  styleUrls: ['./conta-corrente-itr.component.css']
})
export class ContaCorrenteItrPFSuspensoComponent extends ContaCorrenteItrComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Débitos Suspensos Fazendários Conta Corrente ITR - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}
