<div class="table-responsive">
  <table suiteRfbTable>
    <caption>Filtros</caption>
    <tbody>
      <tr>
        <th class="col-md-2" scope="col">Nome</th>
        <td class="col-md-4">{{ parametrosPesquisa.nome | vazio }}</td>

        <th class="col-md-3" scope="col">Pesquisa Fonética</th>
        <td class="col-md-3">{{ parametrosPesquisa.pesquisaFonetica ? 'Sim' : 'Não' }}</td>
      </tr>

      <tr>
        <th class="col-md-2" scope="col">Nome da Mãe</th>
        <td class="col-md-4">{{ parametrosPesquisa.nomeMae | vazio }}</td>

        <th class="col-md-3" scope="col">Número do Título de Eleitor</th>
        <td class="col-md-3">{{ parametrosPesquisa.numeroTituloEleitor | vazio }}</td>
      </tr>

      <tr>
        <th class="col-md-2" scope="col">Data de Nascimento</th>
        <td class="col-md-4">
          {{ parametrosPesquisa.dataNascimento | date: 'dd/MM/yyyy' | vazio }}
        </td>

        <th class="col-md-2" scope="col">CEP</th>
        <td class="col-md-4">{{ parametrosPesquisa.cep | vazio }}</td>
      </tr>

      <tr>
        <th class="col-md-2" scope="col">Município</th>
        <td class="col-md-4">{{ parametrosPesquisa.municipio | vazio }}</td>

        <th class="col-md-2" scope="col">UF</th>
        <td class="col-md-4">{{ parametrosPesquisa.uf | vazio }}</td>
      </tr>
    </tbody>
  </table>
</div>
