import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSIPADE } from './model/parcelamento-sipade';

@Injectable()
export class ParcelamentoSIPADEService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosSIPADE(ni: string, tipoNI: string, numeroProtocolo: string, tipoPendencia: TipoPendencia): Observable<ParcelamentoSIPADE[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sipade/${tipoPendencia}/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };

    return this.http.get<ParcelamentoSIPADE[]>(url, options)
      .pipe(
        map(parcelamentos => {
          parcelamentos.forEach(p => {
            p.tipoNi = tipoNI;
          });
          return parcelamentos;
        })
      );
  }
}
