<div #viaTab cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="nav via-tabset" role="tablist" [hidden]="!tabs || tabs.length == 0">
  <div class="via-tab-scroll-left" *ngIf="habilitaAnterior">
    <div (click)="scrollLeft()" 
      class="via-tab-scroll-button via-tab-scroll-button-prior">
      <span class="material-icons via-tab-scroll-button-icon">
        chevron_left
      </span>
    </div>
  </div>
  <div class="via-tab-scroll-right" *ngIf="habilitaPosterior">
    <div (click)="scrollRight()" 
      class="via-tab-scroll-button via-tab-scroll-button-next">
      <span class="material-icons via-tab-scroll-button-icon">
        chevron_right
      </span>
    </div>
  </div>
  <div class="via-tab-scroll-area" #tabScrollArea id="tabScrollArea">
    <div class="via-tab-area" #tabArea id="tabArea">
      <div role="presentation" *ngFor="let tab of tabs" class="via-tab" [class.active]="tab.active" cdkDragLockAxis="x" cdkDrag>
        <div class="via-tab-header" href="javascript:void(0)" [class.disabled]="tab.disabled" (click)="select(tab)" role="tab" data-toggle="tab">
          <ng-container *ngIf="tab.tabSelectBody">
            <ng-container *ngTemplateOutlet="tab.tabSelectBody; context: {tab: tab}"></ng-container>
          </ng-container>
          <ng-container *ngIf="!tab.tabSelectBody">
            <div class="name" [title]="tab.name">{{tab.name}}</div>
          </ng-container>
          <button *ngIf="tab.closeable" title="Fechar" class="via-tab-close-button" (click)="onClose(tab)">
            <span class="material-icons">clear</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tab-content">
  <ng-content></ng-content>
</div>
