import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuFeatureService, User, UserService } from '@serpro/ngx-suite-rfb';
import _ from 'lodash';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Feature, FeatureAction } from 'src/app/shared/model/feature';
import { Ni } from 'src/app/shared/model/ni';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CnpjsVinculadosService } from 'src/app/sitfis/components/cnpjs-vinculados/cnpjs-vinculados.service';
import { QuantidadeCnpjsVinculadosDTO } from 'src/app/sitfis/components/cnpjs-vinculados/model/cnpjs-vinculados';
import { DarfService } from 'src/app/sitfis/components/darf/darf.service';
import { DasService } from 'src/app/sitfis/components/das/das.service';
import { DeclaracaoIrpfMalhaService } from 'src/app/sitfis/components/declaracao-irpf-malha/declaracao-irpf-malha.service';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { InformacoesCompletasAtendimento, PessoaFisica, PessoaJuridica, ProtocoloDto, SolicitacaoProtocoloDto, StatusAnaliseFiscalDTO } from 'src/app/sitfis/model/protocolo';
import { SituacaoFiscalPessoaFisicaDto } from 'src/app/sitfis/model/situacao-fiscal-pf-dto';
import { SituacaoFiscalPessoaJuridicaDto } from 'src/app/sitfis/model/situacao-fiscal-pj-dto';
import { Vinculo, VinculoPessoaFisica, VinculoPessoaJuridica } from 'src/app/sitfis/model/vinculos';
import { SitFisService } from 'src/app/sitfis/services/sitfis.service';
import { FEATURE_INICIAL_PF, FEATURE_INICIAL_PJ } from '../../../app-features';
import { MenuFeatureActionsService } from '../../../shared/menu-feature-actions/menu-feature-actions.service';
import { ProtocoloService } from '../../services/protocolo.service';
import { FEATURES_PF, FEATURES_PJ } from './atendimento.features';
import { AtendimentoService } from './atendimento.service';

// Componente que apresenta um painel que
@Component({
  selector: 'via-atendimento',
  templateUrl: './atendimento.component.html',
  styleUrls: ['./atendimento.component.css'],
  providers: [
    MenuFeatureActionsService,
    AtendimentoService,
    DasService,
    DarfService,
    ScopedMessageService
  ]
})
export class AtendimentoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() ni: Ni;
  @Input() active: boolean;
  @Input() tabName: string;

  @Output() tabInitialized = new EventEmitter<string>();

  scopeId: string;

  private _consultaSituacaoFiscalCompleta = false;
  private _consultaCnpjsVinculadosCompleta = false;
  private _consultaMalhaIrpfCompleta = false;

  set consultaSituacaoFiscalCompleta(value: boolean) {
    this._consultaSituacaoFiscalCompleta = value;
    this.checkTabInitialized();
  }
  get consultaSituacaoFiscalCompleta() {
    return this._consultaSituacaoFiscalCompleta;
  }

  set consultaCnpjsVinculadosCompleta(value: boolean) {
    this._consultaCnpjsVinculadosCompleta = value;
    this.checkTabInitialized();
  }
  get consultaCnpjsVinculadosCompleta() {
    return this._consultaCnpjsVinculadosCompleta;
  }

  set consultaMalhaIrpfCompleta(value: boolean) {
    this._consultaMalhaIrpfCompleta = value;
    this.checkTabInitialized();
  }
  get consultaMalhaIrpfCompleta() {
    return this._consultaMalhaIrpfCompleta;
  }

  private checkTabInitialized(): void {
    if (this._consultaSituacaoFiscalCompleta && this._consultaCnpjsVinculadosCompleta && this._consultaMalhaIrpfCompleta) {
      this.tabInitialized.emit(this.ni.ni);
    }
  }

  usuario: User;
  statusProcessamento = '';
  qtdVerificacoes = 0;

  menu: Feature;
  protocoloSitFis: ProtocoloDto;

  // opções hierárquicas do menu global do atendimento
  features: FeatureAction[];
  featureClickSub: Subscription;
  // característica selecionada
  feature: Feature;
  vinculosFeature: Feature = {
    id: 'vinculos',
    collapsed: true,
    title: 'vinculos',
    children: [
      { id: 'menu-vinculado', collapsed: true, title: 'vinculado' }
    ]
  };

  protocolo: ProtocoloDto;
  dadosContribuinte: PessoaFisica;
  dadosContribuintePj: PessoaJuridica;

  situacaoFiscalPF: SituacaoFiscalPessoaFisicaDto;
  situacaoFiscalPJ: SituacaoFiscalPessoaJuridicaDto;
  vinculos: Vinculo[];

  possuiCnpjsVinculados = false;
  possuiDeclaracoesIrpfMalha = false;

  tipoNi: TipoNI;
  tipoNiRequerente: string;

  numeroInscricao = new BehaviorSubject<string>(undefined);
  numeroInscricaoSub: Subscription;
  numeroInscricaoSub2: Subscription;
  declaracoesIrpfMalhaSub: Subscription;
  get numeroInscricao$() {
    return this.numeroInscricao.asObservable().pipe(filter(value => typeof value !== 'undefined'));
  }

  numeroProtocolo = new BehaviorSubject<string>(undefined);
  numeroProtocoloSub: Subscription;
  get numeroProtocolo$() {
    return this.numeroProtocolo.asObservable().pipe(filter(value => typeof value !== 'undefined'));
  }

  statusAnaliseFiscal = new BehaviorSubject<StatusAnaliseFiscalDTO>(undefined);
  statusAnaliseFiscalSub: Subscription;
  get statusAnaliseFiscal$() {
    return this.statusAnaliseFiscal.asObservable().pipe(filter(value => typeof value !== 'undefined'));
  }

  situacaoFiscalPessoaFisica = new BehaviorSubject<SituacaoFiscalPessoaFisicaDto[]>(undefined);
  situacaoFiscalPessoaFisicaSub: Subscription;
  get situacaoFiscalPessoaFisica$() {
    return this.situacaoFiscalPessoaFisica.asObservable().pipe(filter(value => typeof value !== 'undefined'));
  }

  situacaoFiscalPessoaJuridica = new BehaviorSubject<SituacaoFiscalPessoaJuridicaDto[]>(undefined);
  situacaoFiscalPessoaJuridicaSub: Subscription;
  get situacaoFiscalPessoaJuridica$() {
    return this.situacaoFiscalPessoaJuridica.asObservable().pipe(filter(value => typeof value !== 'undefined'));
  }

  constructor(
    private menuFeatureService: MenuFeatureService,
    private atendimentoService: AtendimentoService,
    private protocoloService: ProtocoloService,
    private cnpjsVinculadosService: CnpjsVinculadosService,
    private declaracaoIrpfMalhaService: DeclaracaoIrpfMalhaService,
    private sitfisService: SitFisService,
    private logService: LogService,
    private userService: UserService,
    private messageService: ScopedMessageService) { }

  public ngOnInit(): void {

    this.scopeId = this.messageService.scopeId;

    //TODO: remover após bem testado ------------------------------------------
    if (typeof (window as any).viaAtraso === "number") {
      var viaAtraso: number = (window as any).viaAtraso;
    }
    //-------------------------------------------------------------------------

    this.logService.logStep('AtendimentoComponent.ngOnInit', 'NI', this.ni.ni);

    const niFormatado = this.ni.ni.length < 12 ? FormatCpfPipe.formatCPF(this.ni.ni) : FormatCnpjPipe.formatCNPJ(this.ni.ni)
    this.menu = { id: this.ni.ni, collapsed: true, title: niFormatado } as Feature;

    // registra callback para receber a opção selecionada do Menu Principal
    // TODO: VERIFICAR SE ESTE TRECHO AINDA É NECESSÁRIO
    this.featureClickSub = this.menuFeatureService.featureClick$.subscribe(
      (feature: Feature) => {
        if (this.active) {
          this.feature = feature;
          this.feature.collapsed = false;
          this.menu.collapsed = true;
        }
      }
    );

    this.userService.getUser().subscribe((usuario) => {
      this.usuario = usuario;
    });

    this.numeroInscricaoSub = this.numeroInscricao$.subscribe(
      (value: string) => {
        this.active = false;
        const solicitacao = new SolicitacaoProtocoloDto();
        solicitacao.ni = value;
        solicitacao.tipoNI = this.tipoNi;
        this.statusProcessamento = 'Gerando protocolo de atendimento...';
        this.protocoloService.recuperaProtocolo(solicitacao)
        .pipe(
          tap(numero => {
            this.statusProcessamento = `Protocolo gerado: ${numero}`;
            //console.log(`protocolo ${numero}`);
          })
        )
        .subscribe(
          (protocolo: string) => {
            // espera 3 segundos ao receber o número de protocolo antes de
            // proceder à primeira verificação de status da análise fiscal
            timer(3000).subscribe(x => this.numeroProtocolo.next(protocolo));
          },
          (error: any) => {
            console.error('erro recuperaProtocolo: ' + error);
            this.logService.logAjaxError('recuperaProtocolo', '', error);
            this.messageService.showErrorExceptionNI(this.ni.ni, 'Erro ao recuperar dados do NI', error);
          }
        )
      }
    );

    this.numeroProtocoloSub = this.numeroProtocolo$.subscribe(
      (value: string) => {
        this.protocoloSitFis = new ProtocoloDto(value, this.tipoNi, this.ni.ni, this.tipoNiRequerente);
        this.atendimentoService.getInfosAtendimento(this.ni.ni).protocoloSitFis = this.protocoloSitFis;
        this.statusProcessamento = `Processando situação fiscal (${++this.qtdVerificacoes})...`;
        this.protocoloService.verificarProtocolo(value).subscribe(
          (status: StatusAnaliseFiscalDTO) => {
            this.statusAnaliseFiscal.next(status);
          },
          (error: any) => {
            console.error('erro verificarProtocolo: ' + error);
            this.logService.logAjaxError('verificarProtocolo', '', error);
            this.messageService.showErrorException('Erro ao recuperar dados do NI', error);
          }
        )
      }
    );

    this.statusAnaliseFiscalSub = this.statusAnaliseFiscal$.subscribe(
      (value: StatusAnaliseFiscalDTO) => {
        const numeroProtocolo = this.atendimentoService.getInfosAtendimento(this.ni.ni).protocoloSitFis.numeroProtocolo;

        switch (value.situacao) {
          case 'Erro': {
            const mensagem = 'Erro na busca da situação fiscal para o NI informado';
            this.messageService.showError(mensagem);
            console.error(mensagem);
            this.consultaSituacaoFiscalCompleta = true;
            break;
          }
          case 'Inexistente': {
            const mensagem = 'A situação fiscal para o NI informado não existe';
            this.messageService.showError(mensagem);
            console.error(mensagem);
            this.consultaSituacaoFiscalCompleta = true;
            break;
          }
          case 'NiNaoCadastrado': {
            const mensagem = 'NI não cadastrado';
            this.messageService.showError(mensagem);
            console.error(mensagem);
            this.consultaSituacaoFiscalCompleta = true;
            break;
          }
          case 'Finalizada':
          case 'EmProcessamentoDataprev':

            //TODO: remover apos bem testado ----------------------------------
            //simula que continua em processamento mais viaAtraso vezes
            if (typeof viaAtraso !== "undefined") {
              console.log("viaAtraso: " + viaAtraso);
              viaAtraso--;
              if (viaAtraso > 0) {
                this.numeroProtocolo.next(this.numeroProtocolo.value);
                break;
              }
            }
            //-----------------------------------------------------------------

            this.statusProcessamento = 'Obtendo os dados da situação fiscal...';
            switch (this.tipoNi) {
              case 'CPF':
                this.sitfisService.obterSituacaoFiscalPessoaFisica(this.ni.ni, numeroProtocolo).subscribe(
                  (situacoes: SituacaoFiscalPessoaFisicaDto[]) => {
                    this.situacaoFiscalPessoaFisica.next(situacoes);
                  },
                  (error: any) => {
                    console.error('erro obterSituacaoFiscalPessoaFisica: ' + error);
                    this.logService.logAjaxError('obter obterSituacaoFiscalPessoaFisica', '', error);
                    this.messageService.showErrorException('Erro ao recuperar dados do NI', error);
                  }
                );
                break;
              case 'CNPJ':
                this.sitfisService.obterSituacaoFiscalPessoaJuridica(this.ni.ni, numeroProtocolo).subscribe(
                  (situacoes: SituacaoFiscalPessoaJuridicaDto[]) => {
                    this.situacaoFiscalPessoaJuridica.next(situacoes);
                  },
                  (error: any) => {
                    console.error('erro obterSituacaoFiscalPessoaJuridica: ' + error);
                    this.logService.logAjaxError('obterSituacaoFiscalPessoaJuridica', '', error);
                    this.messageService.showErrorException('Erro ao recuperar dados do NI', error);
                  }
                );
                break;
            }
            break;
          case 'EmProcessamento':
            // se ainda está em processamento, espera mais 2 segundos antes de verificar novamente
            timer(2000).subscribe(x => this.numeroProtocolo.next(this.numeroProtocolo.value));
            break;
        }
      }
    );

    this.situacaoFiscalPessoaFisicaSub = this.situacaoFiscalPessoaFisica$.subscribe(
      (value: SituacaoFiscalPessoaFisicaDto[]) => {
        this.situacaoFiscalPF = value.find(situacao => situacao.ni === this.ni.ni);
        this.vinculos = value
          .filter(situacao => situacao.ni !== this.ni.ni)
          .map(s => new VinculoPessoaFisica(s.ni, s.pendenciasExistentes));

        this.logService.logStep('AtendimentoComponent.recuperaDadosNi', 'recuperou dados.', this.atendimentoService.getInfosAtendimento(this.ni.ni));
        this.consultaSituacaoFiscalCompleta = true;
        this.carregaVinculos();
      }
    );

    this.situacaoFiscalPessoaJuridicaSub = this.situacaoFiscalPessoaJuridica$.subscribe(
      (value: SituacaoFiscalPessoaJuridicaDto[]) => {
        // VERIFICA SE O CNPJ COMPLETO OU BASE É IGUAL AO DO ATENDIMENTO
        this.situacaoFiscalPJ = value.find(situacao => situacao.ni === this.ni.ni
          || situacao.ni.substring(0, 8) === this.ni.ni.substring(0, 8));

        this.ni.nome = this.situacaoFiscalPJ.razaoSocial;
        this.vinculos = value
          .filter(situacao => situacao.ni !== this.ni.ni)
          .map(s => new VinculoPessoaJuridica(s.ni, s.pendenciasExistentes));

        this.logService.logStep('AtendimentoComponent.recuperaDadosNi', 'recuperou dados.', this.atendimentoService.getInfosAtendimento(this.ni.ni));
        this.consultaSituacaoFiscalCompleta = true;
        this.carregaVinculos();
      }
    );

    this.numeroInscricaoSub2 = this.numeroInscricao$.subscribe(
      (value: string) => {
        if (this.tipoNi === TipoNI.CPF) {
          this.cnpjsVinculadosService.obterQuantidadeCnpjsVinculados(value).subscribe(
            (quantidades: QuantidadeCnpjsVinculadosDTO[]) => {
              let quantidade = 0;
              quantidades.forEach(q => {
                quantidade += q.quantidade;
              });
              this.possuiCnpjsVinculados = (quantidade > 0);
            },
            (error: any) => {
              console.error('erro obterQuantidadeCnpjsVinculados: ' + error);
              this.logService.logAjaxError('obterQuantidadeCnpjsVinculados', '', error);
              this.consultaCnpjsVinculadosCompleta = true;
            },
            () => {
              this.consultaCnpjsVinculadosCompleta = true;
            }
          );
        }
        else {
          this.consultaCnpjsVinculadosCompleta = true;
        }
      }
    );

    this.declaracoesIrpfMalhaSub = this.numeroInscricao$.subscribe(
      (value: string) => {
        if (this.tipoNi === TipoNI.CPF) {
          this.declaracaoIrpfMalhaService.obterQuantidadeDeclaracoesIRPFMalha(value).subscribe(
            quantidade => this.possuiDeclaracoesIrpfMalha = quantidade > 0,
            (error: any) => {
              console.error('erro obterQuantidadeDeclaracoesIRPFMalha: ' + error);
              this.logService.logAjaxError('obterQuantidadeDeclaracoesIRPFMalha', '', error);
              this.consultaMalhaIrpfCompleta = true;
            },
            () => {
              this.consultaMalhaIrpfCompleta = true;
            }
          );
        }
        else {
          this.consultaMalhaIrpfCompleta = true;
        }
      }
    );

    this.atendimentoService.updateInfosAtendimento(this.ni.ni, new InformacoesCompletasAtendimento())
    this.atendimentoService.getInfosAtendimento(this.ni.ni).tabName = this.tabName;

    if ((this.ni.ni != null) && (this.ni.ni.length == 11)) {
      this.tipoNi = TipoNI.CPF;
      this.tipoNiRequerente = 'PF';
      this.features = _.cloneDeep(FEATURES_PF);
      this.feature = _.cloneDeep(FEATURE_INICIAL_PF);
      this.atualizarInfosAtendimentoDadosCpf();
    }
    else {
      this.tipoNi = TipoNI.CNPJ;
      this.tipoNiRequerente = 'PJ';
      this.features = _.cloneDeep(FEATURES_PJ);
      this.feature = _.cloneDeep(FEATURE_INICIAL_PJ);
      this.atualizarInfosAtendimentoDadosCnpj();
    }
    this.feature.collapsed = false;
    this.numeroInscricao.next(this.ni.ni);
  }

  private carregaVinculos(): void {
    if (this.vinculos) {
      this.vinculos.forEach(vinculo => {
        const informacoesVinculo = new InformacoesCompletasAtendimento();
        informacoesVinculo.protocoloSitFis = new ProtocoloDto(this.protocoloSitFis.numeroProtocolo, this.tipoNi, vinculo.getDescricao(), this.tipoNiRequerente);
        this.atendimentoService.updateInfosAtendimento(vinculo.getDescricao(), informacoesVinculo);
      });
    }
  }

  public onCancelarProcessamentoAtendimento(): void {
    this.numeroProtocolo.next(undefined);
    this.atendimentoService.cancelaProcessamentoAtendimento(this.ni.ni);
  }

  public ngOnDestroy(): void {
    this.featureClickSub.unsubscribe();
    this.numeroInscricaoSub.unsubscribe();
    this.numeroInscricaoSub2.unsubscribe();
    this.declaracoesIrpfMalhaSub.unsubscribe();
    this.numeroProtocoloSub.unsubscribe();
    this.statusAnaliseFiscalSub.unsubscribe();
    this.situacaoFiscalPessoaFisicaSub.unsubscribe();
    this.situacaoFiscalPessoaJuridicaSub.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.tipoNi === TipoNI.CPF) {
      this.atualizarInfosAtendimentoDadosCpf();
      return;
    }

    if (this.tipoNi === TipoNI.CNPJ) {
      this.atualizarInfosAtendimentoDadosCnpj();
    }
  }

  clickPrincipal(): void {
    this.menu.collapsed = !this.menu.collapsed;
    this.vinculosFeature.collapsed = true;
    this.feature.collapsed = true;
  }

  clickFeature(): void {
    this.feature.collapsed = !this.feature.collapsed;
    this.vinculosFeature.collapsed = true;
    this.menu.collapsed = true;
  }

  clickVinculos(): void {
    this.vinculosFeature.collapsed = !this.vinculosFeature.collapsed;
    this.feature.collapsed = true;
    this.menu.collapsed = true;
  }

  private atualizarInfosAtendimentoDadosCpf(): void {
    const dadosContribuinteCpf = new PessoaFisica();
    dadosContribuinteCpf.cpf = this.ni.ni;
    dadosContribuinteCpf.nome = this.ni.nome;
    this.atendimentoService.getInfosAtendimento(this.ni.ni).dadosContribuinteCpf = dadosContribuinteCpf;
    this.logService.logStep("AtendimentoComponent.atualizarInfosAtendimentoDadosCpf", "Atualizou informações do contribuinte CPF", dadosContribuinteCpf);
  }

  private atualizarInfosAtendimentoDadosCnpj(): void {
    const dadosContribuinteCnpj = new PessoaJuridica();
    dadosContribuinteCnpj.cnpj = this.ni.ni;
    dadosContribuinteCnpj.razaoSocial = this.ni.nome;
    this.atendimentoService.getInfosAtendimento(this.ni.ni).dadosContribuinteCnpj = dadosContribuinteCnpj;
    this.logService.logStep("AtendimentoComponent.atualizarInfosAtendimentoDadosCnpj", "Atualizou informações do contribuinte CNPJ", dadosContribuinteCnpj);
  }
}
