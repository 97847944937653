import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { ParametrosPesquisaPessoaFisica } from 'src/app/cpf/models/parametros-pesquisa-pessoa-fisica';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';
import { ConsultaDadosBasicosPfTableComponent } from '../../consulta-pf/consulta-dados-basicos-pf/table/consulta-dados-basicos-pf-table.component';

@Component({
  selector: 'via-pesquisa-dados-basicos',
  templateUrl: './pesquisa-dados-basicos.component.html',
  styleUrls: ['./pesquisa-dados-basicos.component.css']
})
export class PesquisaDadosBasicosComponent implements OnInit {

  maxDate = new Date();

  formGroup: FormGroup;

  nomeFormGroupName = 'nome';
  nomeMaeFormGroupName = 'nomeMae';
  tituloEleitorFormGroupName = 'tituloEleitor';
  dataNascimentoFormGroupName = 'dataNascimento';
  municipioFormGroupName = 'municipio';
  ufFormGroupName = 'uf';
  cepFormGroupName = 'cep';
  foneticaFormGroupName = 'pesquisaFonetica';

  constructor(
    private formBuilder: FormBuilder,
    private dynamicModalService: DynamicModalRequestService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      nome: [''],
      nomeMae: [''],
      tituloEleitor: [''],
      dataNascimento: [''],
      municipio: [''],
      uf: [''],
      cep: [''],
      pesquisaFonetica: ['']
    });
  }

  private get nome(): string {
    return this.formGroup.get(this.nomeFormGroupName).value as string;
  }

  private get nomeMae(): string {
    return this.formGroup.get(this.nomeMaeFormGroupName).value as string;
  }

  private get tituloEleitor(): string {
    return this.formGroup.get(this.tituloEleitorFormGroupName).value as string;
  }

  private get dataNascimento(): Date {
    return this.formGroup.get(this.dataNascimentoFormGroupName).value as Date;
  }

  private get municipio(): string {
    return this.formGroup.get(this.municipioFormGroupName).value as string;
  }

  private get uf(): string {
    return this.formGroup.get(this.ufFormGroupName).value as string;
  }

  private get cep(): string {
    return this.formGroup.get(this.cepFormGroupName).value as string;
  }

  private get pesquisaFonetica() {
    return Boolean(this.formGroup.get(this.foneticaFormGroupName).value);
  }

  isValidForm(): boolean {
    return this.formGroup.valid && this.temCamposPreenchidos();
  }

  onSubmit(): void {
    const parametros: ParametrosPesquisaPessoaFisica = {
      nome: this.nome,
      nomeMae: this.nomeMae,
      numeroTituloEleitor: this.tituloEleitor,
      dataNascimento: this.dataNascimento,
      pesquisaFonetica: this.pesquisaFonetica,
      municipio: this.municipio,
      uf: this.uf,
      cep: this.cep
    };

    const consultaPfTableFeature = {
      id: 'ConsultaPfTable',
      title: 'Pessoa Física - Consultar',
      class: ConsultaDadosBasicosPfTableComponent,
      parametros: {
        parametrosPesquisa: parametros
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(consultaPfTableFeature);
  }

  private temCamposPreenchidos(): boolean {
    const isFoneticaPreenchido = this.formGroup.get(this.foneticaFormGroupName).dirty;
    const isNomePreenchido = !isEmpty(this.nome);
    const isNomeMaePreenchido = !isEmpty(this.nomeMae);
    const isTituloPreenchido = !isEmpty(this.tituloEleitor);
    const isMunicipioPreenchido = !isEmpty(this.municipio);
    const isUfPreenchida = !isEmpty(this.uf);
    const isCepPreenchido = !isEmpty(this.cep);
    const isDataNascimentoPreenchida = isDateValid(this.dataNascimento);

    return isFoneticaPreenchido
      || isNomePreenchido
      || isNomeMaePreenchido
      || isTituloPreenchido
      || isMunicipioPreenchido
      || isUfPreenchida
      || isCepPreenchido
      || isDataNascimentoPreenchida;
  }
}
