<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Detalhe</th>
        <th class="text-center min-width">NIRF</th>
        <th class="text-center">Nome Imóvel</th>
        <th class="text-center min-width">UA</th>
        <th class="text-center min-width">Situação do Imóvel</th>
        <th class="text-center min-width">Situação do Contribuinte</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let imovelRural of imoveisRurais | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-toggle
              #rowToggle
              (expanded)="onCarregarDetalhe($event, imovelRural)"
              title="Detalhar Imóvel">
            </suite-rfb-toggle>
          </td>

          <td class="text-center text-nowrap">{{ imovelRural.nirf | formatNirfPipe | vazio }}</td>

          <td class="text-left">{{ imovelRural.nomeImovel | vazio }}</td>

          <td class="text-center">{{ imovelRural.uaJurisdicao?.codigo | vazio }}</td>

          <td class="text-center">{{ imovelRural.situacaoImovel | vazio }}</td>

          <td class="text-center">{{ imovelRural.situacaoContribuinte | vazio }}</td>
        </tr>

        <ng-container *ngIf="rowToggle.show">
          <ng-container *ngIf="detalhePorNirf.get(imovelRural.nirf).executouChamada; else loadingOrError;">
            <tr></tr>
            <tr>
              <td colspan="6" class="background-inherit">
                <via-detalhe-imovel-rural
                  [ni]="ni"
                  [nirf]="imovelRural.nirf"
                  [detalhe]="detalhePorNirf.get(imovelRural.nirf).imovel">
                </via-detalhe-imovel-rural>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="6">
          <via-pagination [itemAmount]="imoveisRurais.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
