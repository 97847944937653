import { CcitrBase } from './../../base/CcitrBase.component';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { cpfValidator } from "@serpro/ngx-suite-rfb";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ParametrosPesquisaCcitr } from "src/app/cobranca/models/parametros-pesquisa-ccitr";
import { DynamicModalRequestService } from "src/app/shared/dynamic-modal/dynamic-modal-request.service";
import { IDynamicPanel, OptionalParams } from "src/app/shared/dynamic-panel/panel-interface";
import { MenuFeatureActionsService } from "src/app/shared/menu-feature-actions/menu-feature-actions.service";
import { FeatureAction, TipoMenu } from "src/app/shared/model/feature";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { ConsultaEventosComponent } from "../eventos/consulta-eventos.component";
import { ParametrosConsultaEventos } from 'src/app/cobranca/models/parametros-consulta-eventos';

@Component({
  templateUrl: './eventos-filtros.component.html',
  styleUrls: ['./eventos-filtros.component.css', '../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class EventosFiltrosCCITR extends CcitrBase implements IDynamicPanel, OnInit, OnChanges {

  dataInicialCtrl: FormControl;
  dataFinalCtrl: FormControl;
  filtrosForm: FormGroup;

  exibeResultado: boolean = false;
  filtroColapsado: boolean = false;

  initError: Error;
  titulo = 'CCITR - Consultar Eventos';
  parametros: ParametrosConsultaEventos;
  scopeId: string;

  constructor(
    private formBuilder: FormBuilder,
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private dynamicModalRequest: DynamicModalRequestService,
    private messageService: ScopedMessageService,
  ) {
    super();
  }

  init(ni: string, params?: OptionalParams): void {

    super.init(ni, params);
    this.parametros= params as ParametrosConsultaEventos;

  }


  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.buildForm();
    this.addValidators();
    this.subscribeValueChanges();
    this.onReset();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  buildForm(): void {
    this.filtrosForm = this.formBuilder.group({
      dataInicial: this.dataInicialCtrl = new FormControl(''),
      dataFinal: this.dataFinalCtrl = new FormControl(''),
    });
  }

  public esconderResultado(): void {
    this.exibeResultado = false;
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  onFecharModal(): void {
    this.bsModalRef.hide();
  }

  addValidators(): void {
    this.dataInicialCtrl.addValidators( this.dataInicialValidator() );
    this.dataFinalCtrl.addValidators( this.dataFinalValidator() );
  }

  subscribeValueChanges(): void {
    this.dataInicialCtrl.valueChanges.subscribe(value => {
      this.onDataInicialChange(value);
      this.dataFinalCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
    });
    this.dataFinalCtrl.valueChanges.subscribe(value => {
      this.onDataFinalChange(value);
      this.dataInicialCtrl.updateValueAndValidity({onlySelf: true, emitEvent: false});
    });
  }

  onReset() {
    this.filtrosForm.reset();
  }


  onDataInicialChange(value: Date): void {
  }

  onDataFinalChange(value: Date): void {
  }



  dataInicialValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!this.dataFinalCtrl.value) {
        return null;
      }
      if(!this.dataInicialCtrl.value) {
        return { data_inicial_nao_informada: true };
      }
      if(this.dataInicialCtrl.value > this.dataFinalCtrl.value) {
        return { data_inicial_maior_data_final: true };
      }
      return null;
    };
  }

  dataFinalValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!this.dataInicialCtrl.value) {
        return null;
      }
      if(!this.dataFinalCtrl.value) {
        return { data_final_nao_informada: true };
      }
      if(this.dataInicialCtrl.value > this.dataFinalCtrl.value) {
        return { data_inicial_maior_data_final: true };
      }
      return null;
    };
  }


  onSubmit(): void {

    this.messageService.dismissMessages();
    this.parametros = {
      dataInicial: this.dataInicialCtrl.value,
      dataFinal: this.dataFinalCtrl.value,
      tipoEvento: "",
      nirf: this.parametros.nirf,
      cpfContribuinte: this.parametros.cpfContribuinte,
      cnpjContribuinte: this.parametros.cnpjContribuinte,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      tipoLancamento: this.parametros.tipoLancamento,
      vencidosAVencer: this.parametros.vencidosAVencer,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      atendimento: this.atendimento
    };

    if (this.atendimento) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
    } else {
      let feature = new FeatureAction();
      feature.parametros = this.parametros;
      feature.class = ConsultaEventosComponent;
      this.dynamicModalRequest.abrirModal(feature);
    }


  }
}
