import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParcelamentoSimplesNacional } from '../model/parcelamento-simples-nacional';

@Component({
  selector: 'via-parcelamento-simples-nacional-table',
  templateUrl: 'parcelamento-simples-nacional-table.component.html',
  styleUrls: ['parcelamento-simples-nacional-table.component.css']
})
export class ParcelamentoSimplesNacionalTableComponent {

  @Input() parcelamentos: ParcelamentoSimplesNacional[];

  @Input() ni: string;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
