<suite-rfb-collapse [title]="titulo" [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)">
  <div class="container-fluid container-filtros">
    <div class="container-fluid">
      <form [formGroup]="filtrosForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-4">
            <label class="control-label">CPF</label>
            <input class="form-control" type="text" formControlName="cpf" mask="000.000.000-00">
          </div>
          <div class="col-md-8">
            <label class="control-label">Período de:</label>
            <div class="form-inline col-data-arrecadacao">
              <div class="form-group form-group-data-arrecadacao">
                <suite-rfb-date-picker
                  adaptivePosition="true"
                  formControlName="dataInicial"
                  outsideClick="true">
                </suite-rfb-date-picker>
              </div>
              <label class="control-label data-arrecadacao-label-separador">a</label>
              <div class="form-group form-group-data-arrecadacao">
                <suite-rfb-date-picker
                  adaptivePosition="true"
                  formControlName="dataFinal"
                  outsideClick="true">
                </suite-rfb-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-top: 25px;">
            <div class="form-group">
              <div class="text-center">
                <button type="button" (click)="onReset()" class="btn btn-default" [disabled]="!filtrosForm.dirty">Limpar</button>
                <button type="submit" class="btn btn-primary" [disabled]="filtrosForm.invalid">Buscar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</suite-rfb-collapse>

<via-eventos-credito-tributario *ngIf="exibeResultado"
  [parametros]="parametros"
  [atendimento]="true"
  (onError)="esconderResultado()">
</via-eventos-credito-tributario>
