<div class="feature-container">
  <suite-rfb-collapse [title]="titulo">
    <div *ngIf="(declaracoesObservable$ | async) as declaracoes; else loadingOrError">
      <via-declaracao-irpf-malha-table 
        [declaracoes]="declaracoes" 
        (emitirExtrato)="emitirExtrato($event)"
        (abrirPortalIrpf)="abrirPortalIrpf()"
        (abrirPortalSiefWeb)="abrirPortalSiefWeb()">
      </via-declaracao-irpf-malha-table>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>