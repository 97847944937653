<suite-rfb-collapse [title]="titulo">
  <div *ngIf="gruposDebito!=null else loadingOrError">
    <div class="container-fluid" *ngFor="let grupoDebito of gruposDebito">
      <div class="debito">
        <div style="float: left">
          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
        </div>
        <div>
          Motivo da divergência do contribuinte - {{ grupoDebito.tipoNi }}: {{ grupoDebito.ni | formatCpfCnpjCeiPipe: grupoDebito.tipoNi }}
        </div>
      </div>
      <ng-container *ngIf="rowToggle.show">
        <via-aguia-table [grupoDebito]="grupoDebito">
        </via-aguia-table>
      </ng-container>
    </div>
  </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
