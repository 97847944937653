<table suiteRfbTable class="tabelaInterna" class="tabela_largura_minima">
  <caption class="caption-ccpf">Alocações CPF</caption>
  <ng-container *ngIf="temDebitos; else nenhumDebito;">
      <thead>
        <tr>
          <th class="text-center min-width">Ações</th>
          <th class="text-center min-width">
            <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
            </suite-rfb-toggle>
          </th>
          <th class="text-center min-width">Receita</th>
          <th class="text-center min-width">Pagamento</th>
          <th class="text-center min-width">COMP-CT</th>
          <th class="text-center min-width">Data Vencimento</th>
          <th class="text-center min-width">Data Prorrogação</th>
          <th class="text-center min-width">Valor Original</th>
          <th class="text-center">Saldo Componente</th>
          <th class="text-center">Valor Alocado</th>
          <th class="text-center">Valor Amortizado</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let debito of debitosAlocados">
          <ng-container *ngFor="let componente of debito.componente">
            <tr class="vertical-align-middle">
              <td class="text-center">
                <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                <ng-template #menuAcoes>
                  <div class="btn-group-vertical" role="group">
                    <button class="btn btn-default" type="button" (click)="desalocarAlocacoes(debito, componente); botaoAcoes.hide();">Desalocar</button>
                  </div>
                </ng-template>
              </td>
              <ng-container>
                <td>
                  <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>
                <td class="text-center">
                  <via-codigo-receita [codigo]="componente.codigoReceita"></via-codigo-receita>
                </td>
                <td class="text-center">{{ debito.numeroDebito | vazio }} </td>
                <td class="text-center">{{ componente.numeroComponente | vazio }}</td>
                <td class="text-center">{{ componente.dataVencimento | formatDataPipe | vazio }}</td>
                <td class="text-center">{{ componente.dataProrrogada | formatDataPipe | vazio }}</td>
                <td class="text-left">{{ componente.valorOriginario | formatCurrency }}</td>
                <td class="text-left">{{ componente.valorSaldo | formatCurrency }}</td>
                <td class="text-left">{{ componente.alocacao.valorAlocado | formatCurrency }}</td>
                <td class="text-left">{{ componente.alocacao.valorAmortizado | formatCurrency }}</td>
              </ng-container>
            </tr>
            <ng-container *ngIf="rowToggle.show">
              <tr></tr>
              <tr>
                <ng-container>
                  <td colspan="11">
                    <div class="contorno">
                      <div class="row">
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Data Alocação
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.alocacao.dataAlocacao | formatDataPipe | vazio  }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Critério
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.alocacao.criterio + componente.alocacao.tipo | vazio }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Valor Componente
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.valorOriginario| formatCurrency }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Saldo Componente
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.valorSaldo | formatCurrency }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Valor Alocado
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.alocacao.valorAlocado | formatCurrency }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <label class="label-formulario control-label">
                            Valor Amortizado
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ componente.alocacao.valorAmortizado | formatCurrency }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </ng-container>
</table>

<ng-template #nenhumDebito>
  <tr class="vertical-align-middle">
    <td class="text-center" colspan="11">
      Nenhum componente alocado encontrado.
    </td>
  </tr>
</ng-template>



