<suite-rfb-collapse title="Histórico" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <ng-container *ngIf="operacoes && operacoes.length > 0; else loadingOrError;">
    <div class="table-responsive">
      <table suiteRfbTable>
        <thead>
          <tr>
            <th class="text-center min-width">
              <suite-rfb-toggle
                title="Expandir/Contrair Todos"
                (expanded)="expandedAll($event)">
              </suite-rfb-toggle>
            </th>
            <th class="text-center min-width">Data</th>
            <th class="text-center min-width">Hora</th>
            <th class="text-center min-width">Origem</th>
            <th class="text-center">Operação Realizada</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let operacao of operacoes">
            <tr class="vertical-align-middle">
              <td>
                <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
              </td>
              <td class="text-center">
                {{ operacao.data | lpad: 8: '0' | mask: '99/99/9999' }}
              </td>
              <td class="text-center">
                {{ operacao.hora | lpad: 6: '0' | mask: '99:99:99' }}
              </td>
              <td class="text-left text-nowrap">
                {{ operacao.origem }}
              </td>
              <td class="text-left">
                {{ operacao.operacao }}
              </td>
            </tr>
            <ng-container *ngIf="rowToggle.show">
              <tr></tr>
              <tr>
                <td colspan="5" class="background-inherit">
                  <div class="grupo background-inherit">
                    <label class="grupo-titulo grupo-operacao-titulo">
                      Dados sobre a operação
                    </label>
                    <div class="dados-operacao-conteudo">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Data e Hora da Operação
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.data | lpad: 8: '0' | mask: '99/99/9999' }}
                            &nbsp;-&nbsp;
                            {{ operacao.hora | lpad: 6: '0' | mask: '99:99:99' }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Terminal
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.terminalUsuario }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Número do Documento
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.numeroDocumento }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Data do Documento
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.dataDocumento | lpad: 8: '0' | mask: '99/99/9999' }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Usuário
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.cpfUsuario | lpad: 11: '0' | mask: '999.999.999-99' }}
                          </p>
                        </div>
                        <div class="col-md-6">
                          <label class="label-formulario control-label">
                            Local de Trabalho
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ operacao.localTrabalhoUsuario }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table suiteRfbTable tableClass="table table-striped tabela alternativo table-margin">
                          <thead>
                            <tr>
                              <th class="text-center dados-label">Dados</th>
                              <th class="text-center">Antes Operação</th>
                              <th class="text-center">Pós Operação</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Situação</td>
                              <td>{{ operacao.situacaoAnterior.descricao }}</td>
                              <td>{{ operacao.situacaoPosterior.descricao }}</td>
                            </tr>
                            <tr>
                              <td>Nome</td>
                              <td>{{ operacao.nomeAnterior }}</td>
                              <td>{{ operacao.nomePosterior }}</td>
                            </tr>
                            <tr>
                              <td>Data de Nascimento</td>
                              <td>{{ operacao.dataNascimentoAnterior | lpad: 8: '0' | mask: '99/99/9999' }}</td>
                              <td>{{ operacao.dataNascimentoPosterior | lpad: 8: '0' | mask: '99/99/9999' }}</td>
                            </tr>
                            <tr>
                              <td>Titulo de Eleitor</td>
                              <td>{{ operacao.tituloEleitorAnterior }}</td>
                              <td>{{ operacao.tituloEleitorPosterior }}</td>
                            </tr>
                            <tr>
                              <td>Naturalidade</td>
                              <td>{{ operacao.naturalidadeAnterior | formatMunicipio }}</td>
                              <td>{{ operacao.naturalidadePosterior | formatMunicipio }}</td>
                            </tr>
                            <tr>
                              <td>Nome da Mãe</td>
                              <td>{{ operacao.nomeMaeAnterior }}</td>
                              <td>{{ operacao.nomeMaePosterior }}</td>
                            </tr>
                            <tr>
                              <td>Endereço</td>
                              <td>{{ operacao.enderecoAnterior | formatEndereco }}</td>
                              <td>{{ operacao.enderecoPosterior | formatEndereco }}</td>
                            </tr>
                            <tr>
                              <td>UA</td>
                              <td>{{ operacao.uaAnterior.nome }}</td>
                              <td>{{ operacao.uaPosterior.nome }}</td>
                            </tr>
                            <tr>
                              <td>Residente no Exterior</td>
                              <td>{{ operacao.indResidenteExteriorAnterior }}</td>
                              <td>{{ operacao.indResidenteExteriorPosterior }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="row background-inherit bottom-margin">
                    <div class="col-md-12 background-inherit">
                      <div class="grupo background-inherit">
                        <label class="grupo-titulo grupo-descricao-titulo">
                          Descrição
                        </label>
                        <p class="form-control-static dado-formulario dado-formulario-descricao">
                          {{ operacao.descricao | vazio }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
