import { NgModule } from '@angular/core';
import {
  SuiteRfbAlertMessagesModule,
  SuiteRfbCollapseModule,
  SuiteRfbFeaturesModule,
  SuiteRfbFooterModule,
  SuiteRfbHeaderModule,
  SuiteRfbImgModule,
  SuiteRfbInputValidationModule,
  SuiteRfbLoadingModule,
  SuiteRfbMenuModule,
  SuiteRfbModuleConfig,
  SuiteRfbNavbarModule,
  SuiteRfbPaginationModule,
  SuiteRfbSidebarModule,
  SuiteRfbTableModule,
  SuiteRfbTabsModule,
  SuiteRfbToggleModule
} from '@serpro/ngx-suite-rfb';
import { FEATURES } from './app-features';

const CONFIG: SuiteRfbModuleConfig = {
  showLoadingOnNavigation: true,
  expandedMenuHierarchical: true,
  menuOptionsHidden: true,
  ignoreLoadingBackdropClick: true,
  collapsedMenu: false,
  showUserProfiles: true,
  logoutHidden: true,
  userConfigurationsHidden: true,
  notificationsHidden: true,
  applicationsMenuHidden: true
}
@NgModule({
  imports: [
    SuiteRfbFeaturesModule.forRoot(FEATURES, CONFIG),
    SuiteRfbHeaderModule,
    SuiteRfbNavbarModule,
    SuiteRfbFooterModule,
    SuiteRfbAlertMessagesModule,
    SuiteRfbLoadingModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbPaginationModule,
    SuiteRfbInputValidationModule,
    SuiteRfbCollapseModule,
    SuiteRfbTabsModule,
    SuiteRfbMenuModule,
    SuiteRfbSidebarModule,
    SuiteRfbImgModule
  ],
  exports: [
    SuiteRfbFeaturesModule,
    SuiteRfbAlertMessagesModule,
    SuiteRfbHeaderModule,
    SuiteRfbNavbarModule,
    SuiteRfbFooterModule,
    SuiteRfbLoadingModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule,
    SuiteRfbPaginationModule,
    SuiteRfbInputValidationModule,
    SuiteRfbCollapseModule,
    SuiteRfbTabsModule,
    SuiteRfbMenuModule,
    SuiteRfbSidebarModule,
    SuiteRfbImgModule
  ]
})
export class AppSuiteRfbModule { }
