import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { AusenciaPorAno } from '../model/ausencia-por-ano';
import { AusenciaDeclaracao } from '../model/declaracao-ausente';

const nomesPeriodosMensais = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', '13°'];

@Component({
  selector: 'via-declaracao-ausente-table',
  templateUrl: 'declaracao-ausente-table.component.html',
  styleUrls: ['declaracao-ausente-table.component.css']
})
export class DeclaracaoAusenteTableComponent {

  @Input() ausenciasDeclaracao: AusenciaDeclaracao[];
  @Input() tipoDeclaracao: string;

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() ni: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  formataAusencias(ausencias: string[]): string[] {
    return ausencias.map(periodo => {
      let ano = periodo.substr(0, 4);
      let parte = periodo.substr(4, undefined);
      return (parte.length > 0) ? parte + "/" + ano : ano;
    });
  }

  agruparAusenciaMensaisPorAno(): AusenciaPorAno[] {
    return this.ausenciasDeclaracao.map(ausencia => {
      const ausenciaPorAno = new AusenciaPorAno();
      ausenciaPorAno.ano = ausencia.periodoApuracaoOmissao[0].slice(0, 4);

      ausencia.periodoApuracaoOmissao.forEach(periodoOmissao => {
        const mes = parseInt(periodoOmissao.slice(-2));
        ausenciaPorAno.periodoOmissao[mes - 1] = nomesPeriodosMensais[mes - 1];
      });

      return ausenciaPorAno;
    });
  }
}
