import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbOutputMaskModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
})
export class DoiSuiteRfbModule { }
