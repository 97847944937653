<div [ngClass]="{'modal-container': !atendimento}">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <form [formGroup]="formGroup" (ngSubmit)="retificar()">
      <div class="table-responsive">
        <table suiteRfbTable>
          <caption>Retificação {{tipoRetificacao}}</caption>
          <thead>
            <tr>
              <th class="text-center min-width"></th>
              <th class="text-center min-width">DE</th>
              <th class="text-center min-width">PARA</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Contribuinte
              </td>
              <td class="text-left">
                {{documentoArrecadacao.ni | formatCpfCnpjPipe | vazio}}
              </td>
              <td class="text-center" *ngIf="tipoRetificacao==TipoRetificacao.COMPLETA || documentoArrecadacao.tipoNi != tipoNIRDOC.CNPJ">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'ni'"
                      viaValidNi
                      suiteRfbMaskCpfCnpj>
                  </div>
                </div>
              </td>
              <td class="text-center" *ngIf="tipoRetificacao==TipoRetificacao.SIMPLIFICADA && documentoArrecadacao.tipoNi == tipoNIRDOC.CNPJ">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      class="form-control cnpj1"
                      type="text"
                      maxlength="8"
                      autocomplete="off"
                      [formControlName]="'cnpj1'"
                      suiteRfbNumbersOnly>
                  </div>
                  /
                  <div class="form-group">
                    <input
                      class="form-control cnpj2"
                      type="text"
                      autocomplete="off"
                      maxlength="4"
                      [formControlName]="'cnpj2'"
                      suiteRfbNumbersOnly>
                  </div>
                  -
                  <div class="form-group">
                    <input
                      class="form-control cnpj3"
                      type="text"
                      autocomplete="off"
                      maxlength="2"
                      [formControlName]="'cnpj3'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                PA
              </td>
              <td class="text-left">
                {{documentoArrecadacao.periodoApuracao | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group form-group-data-arrecadacao">
                    <suite-rfb-date-picker
                      adaptivePosition="true"
                      [formControlName]="'periodoApuracao'">
                    </suite-rfb-date-picker>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Vencimento
              </td>
              <td class="text-left">
                {{documentoArrecadacao.dataVencimento | date: 'shortDate' | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group form-group-data-arrecadacao">
                    <suite-rfb-date-picker
                      adaptivePosition="true"
                      [formControlName]="'dataVencimento'">
                    </suite-rfb-date-picker>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Processo
              </td>
              <td class="text-left">
                {{documentoArrecadacao.processoNr | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'processoNr'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Referência
              </td>
              <td class="text-left">
                {{documentoArrecadacao.referenciaNr | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'referenciaNr'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                ID CEF
              </td>
              <td class="text-left">
                {{documentoArrecadacao.idDepositoCef | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'idDepositoCef'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                VRBA/Percentual
              </td>
              <td class="text-left">
                {{documentoArrecadacao.vrba | vazio}}/{{documentoArrecadacao.vrbaPerc | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline campos-menores">
                  <div class="form-group numerico">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'vrba'"
                      (keyup)="formataVrba()"
                      suiteRfbNumbersOnly>
                  </div>
                /
                  <div class="form-group numerico">
                    <input
                      width="50"
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'vrbaPerc'"
                      (keyup)="formataVrbaPerc()"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Banco/Agência
              </td>
              <td class="text-left">
                {{documentoArrecadacao.bancoCd | vazio}}/{{documentoArrecadacao.agenciaCd | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline campos-menores numerico">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'bancoCd'"
                      suiteRfbNumbersOnly>
                  </div>
                  /
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'agenciaCd'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle">
              <td class="text=left">
                Data de Arrecadação
              </td>
              <td class="text-left">
                {{documentoArrecadacao.dataArrecadacao | date: 'shortDate'| vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline">
                  <div class="form-group form-group-data-arrecadacao">
                    <suite-rfb-date-picker
                      adaptivePosition="true"
                      [formControlName]="'dataArrecadacao'">
                    </suite-rfb-date-picker>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive">
        <table suiteRfbTable class="tabela-receita campos-menores">
          <caption>Valores do Pagamento</caption>
          <thead>
            <tr>
              <th class="text-center min-width">Receita (DE)</th>
              <th class="text-center min-width">Valor (DE)</th>
              <th class="text-center min-width">Receita (PARA)</th>
              <th class="text-center min-width">Valor (PARA)</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertical-align-middle" *ngIf="documentoArrecadacao.receitaList.length>0 || qtdListaValoresAlterados>0">
              <td class="text-center">
                <via-codigo-receita
                  *ngIf="documentoArrecadacao.receitaList[0]?.recCd; else templateVazio"
                  [codigo]="documentoArrecadacao.receitaList[0]?.recCd">
                </via-codigo-receita>
              </td>
              <td class="text-right">
                {{documentoArrecadacao.receitaList[0]?.valor | number :'1.2-2' | vazio}}
              </td>
              <td class="text-center">
                <div class="form-inline" *ngIf="qtdListaValoresAlterados>0">
                  <div class="form-group">
                    <input
                      class="form-control codigo-receita"
                      type="text"
                      autocomplete="off"
                      [formControlName]="'recCd0'"
                      suiteRfbNumbersOnly>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <div class="form-inline" *ngIf="qtdListaValoresAlterados>0">
                  <div class="form-group">
                    <suite-rfb-currency
                      class="numerico"
                      inline
                      [formControlName]="'recValor0'"
                      (keyup)="somaReceita()"
                      ></suite-rfb-currency>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle" *ngIf="documentoArrecadacao.receitaList.length>1 || qtdListaValoresAlterados>1">
              <td class="text-center">
                <via-codigo-receita
                  *ngIf="documentoArrecadacao.receitaList[1]?.recCd; else templateVazio"
                  [codigo]="documentoArrecadacao.receitaList[1]?.recCd">
                </via-codigo-receita>
              </td>
              <td class="text-right">
                {{documentoArrecadacao.receitaList[1]?.valor | number :'1.2-2' | vazio}}
              </td>
              <td class="text-center">
                <div *ngIf="qtdListaValoresAlterados>1">
                  {{formGroup.controls.recCd1.value | vazio}}
                </div>
              </td>
              <td class="text-right">
                <div class="form-inline" *ngIf="qtdListaValoresAlterados>1">
                  <div class="form-group">
                    <suite-rfb-currency
                      class="numerico"
                      inline
                      [formControlName]="'recValor1'"
                      (keyup)="somaReceita()"
                      ></suite-rfb-currency>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle" *ngIf="documentoArrecadacao.receitaList.length>2 || qtdListaValoresAlterados>2">
              <td class="text-center">
                <via-codigo-receita
                  *ngIf="documentoArrecadacao.receitaList[2]?.recCd; else templateVazio"
                  [codigo]="documentoArrecadacao.receitaList[2]?.recCd">
                </via-codigo-receita>
              </td>
              <td class="text-right">
                {{documentoArrecadacao.receitaList[2]?.valor | number :'1.2-2' | vazio}}
              </td>
              <td class="text-center">
                <div *ngIf="qtdListaValoresAlterados>2">
                  {{formGroup.controls.recCd2.value | vazio}}
                </div>
              </td>
              <td class="text-right">
                <div class="form-inline" *ngIf="qtdListaValoresAlterados>2">
                  <div class="form-group">
                    <suite-rfb-currency
                      class="numerico"
                      inline
                      [formControlName]="'recValor2'"
                      (keyup)="somaReceita()"
                      ></suite-rfb-currency>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="vertical-align-middle" *ngIf="documentoArrecadacao.receitaList">
              <td class="text=left">
                TOTAL
              </td>
              <td class="text-right">
                {{totalReceitaDe | formatCurrency | vazio}}
              </td>
              <td></td>
              <td class="text-right">
                {{totalReceitaPara | number :'1.2-2'}}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="tabela-receita tabela-recalcular">
          <tr>
            <td width="25%"></td>
            <td width="25%"></td>
            <td width="25%">
              <button type="button" class="btn btn-default" (click)="carregaReceita()" [disabled]="formGroup.controls.recCd0.pristine">Carregar</button>
            </td>
            <td width="25%"></td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div class="form-group dados-retificacao">
          <div class="col-md-4">
            Origem Erro Banco: {{ documentoArrecadacao.origemErro }}
          </div>
          <div class="col-md-2">
            <label class="radio-inline">
              <input type="radio" [formControlName]="'origemErro'" [value]="tipoOrigemErroBanco" #origemErroBanco>
              <span>Sim</span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="radio-inline">
              <input type="radio" [formControlName]="'origemErro'" [value]="tipoOrigemErroNaoBanco" #origemErroNaoBanco>
              <span>Não</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group dados-retificacao">
          <div class="col-md-4">
            Processo de Retificação
          </div>
          <div class="col-md-4">
            <input
              class="input-text-pesquisa"
              type="text"
              autocomplete="off"
              [formControlName]="'processoRetificacaoNr'"
              suiteRfbNumbersOnly>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" style="margin-top: 25px;">
          <div class="form-group">
            <div class="text-left">
              <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
              <button type="submit" class="btn btn-primary">Retificar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </suite-rfb-collapse>
</div>

<suite-rfb-confirmation
  #confirmaEmissaoComprovante
  [customBody]="perguntaEmissao"
  (accept)="onEmitirComprovante()">
</suite-rfb-confirmation>

<ng-template #perguntaEmissao>
  <div style="width: 480px;">
    <span>{{ 'Deseja emitir o comprovante de retificação?' }}</span>
  </div>
</ng-template>

<ng-template #templateVazio>
  {{ '' | vazio }}
</ng-template>
