<div class="table-responsive">
  <table suiteRfbTable>
    <caption class="caption-ccitr">Componentes</caption>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>

        <th class="text-center min-width">Número</th>
        <th class="text-center min-width break-spaces">Data de Vencimento</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Situação</th>

        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Multa</th>
        <th class="text-center min-width">Juros</th>

        <th class="text-center">Valor a Alocar</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let componente of componentes;">
        <tr class="vertical-align-middle">

          <td class="text-center">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button
                  class="btn btn-default"
                  type="button"
                  (click)="onAlocarDebito(componente); botaoAcoes.hide();"
                  [disabled]="isValorParaAlocacaoInvalido(componente.valorParaAlocacao)">
                  Alocar
                </button>
              </div>
            </ng-template>
          </td>

          <td class="text-center">{{ componente.numero | vazio }}</td>
          <td class="text-center">{{ componente.dataVencimento | formatDataPipe | vazio }}</td>
          <td class="text-right">{{ componente.valorOriginal | formatCurrency | vazio }}</td>
          <td class="text-left">{{ componente.situacao | vazio }}</td>

          <td class="text-right">{{ componente.valorEmAbertoReceita | formatCurrency | vazio }}</td>
          <td class="text-right">{{ componente.valorEmAbertoMulta | formatCurrency | vazio }}</td>
          <td class="text-right">{{ componente.valorEmAbertoJuros | formatCurrency | vazio }}</td>

          <td class="text-center">
            <input
              [name]="componente.numero"
              class="numerico"
              type="text"
              currencyMask
              [(ngModel)]="componente.valorParaAlocacao"
              [options]="currencyMaskOptions"
              style="max-width: 150px;">
          </td>

        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
