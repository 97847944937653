import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CafirModule } from 'src/app/cafir/cafir.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AcaoFiscalModule } from '../../../acao-fiscal/acao-fiscal.module';
import { ComprotModule } from '../../../comprot/comprot.module';
import { HomonimosComponent } from './components/homonimos/homonimos.component';
import { HomonimosTableComponent } from './components/homonimos/table/homonimos-table.component';
import { InformacoesComplementaresComponent } from './components/informacoes-complementares.component';
import { InformacoesComplementaresSuiteRfbModule } from './informacoes-complementares-suite-rfb.module';
import { InformacoesComplementaresService } from './services/informacoes-complementares.service';

@NgModule({
  declarations: [
    InformacoesComplementaresComponent,
    HomonimosComponent,
    HomonimosTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    ComprotModule,
    AcaoFiscalModule,
    CafirModule,
    InformacoesComplementaresSuiteRfbModule
  ],
  exports: [
    InformacoesComplementaresComponent
  ],
  providers: [
    InformacoesComplementaresService
  ]
})
export class InformacoesComplementaresModule { }
