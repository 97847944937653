import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { InfoCadastraisPJService } from './info-cadastrais-pj.service';
import { InfoCadastralPessoaJuridicaDTO } from './model/info-cadastral-pj';
import { PainelAtendimentosService } from 'src/app/workplace/components/painel-atendimentos/painel-atendimentos.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';

@Component({
	selector: 'via-info-cadastrais-pj',
	templateUrl: './info-cadastrais-pj.component.html',
	styleUrls: ['./info-cadastrais-pj.component.css']
})
export class InfoCadastraisPJComponent implements IDynamicPanel, OnDestroy {

	readonly codigoResidenteExterior = '9707';

	bsModalRef: BsModalRef;

	initError: Error;
	loadingError$ = new Subject<boolean>();
	infoObservable$: Observable<InfoCadastralPessoaJuridicaDTO>;
	infoSubscription: Subscription;
	info: InfoCadastralPessoaJuridicaDTO;

    titulo: string;

	residenteNoBrasil: boolean = false;
	acompanhamentoDiferenciado: boolean = false;
	possuiPreposto: boolean = false;
	possuiMotivoSituacao: boolean = false;
	exibeAdquirente: boolean = false;
	optanteSimei: boolean = false;
	optanteSimplesFederal: boolean = false;
	optanteSimplesNacional: boolean = false;

	constructor(
		private informacoesService: InfoCadastraisPJService,
		private atendimentoService: AtendimentoService,
		private painelAtendimentosService: PainelAtendimentosService,
		private modalService: BsModalService,
		private messageService: ScopedMessageService) {
	}

	public init(cnpj: string): void {

        this.titulo = `Informações Cadastrais - CNPJ: ${FormatCnpjPipe.formatCNPJ(cnpj)}`;

		const atendimento = this.atendimentoService.getInfosAtendimento(cnpj);
		const numeroProtocolo = atendimento.protocoloSitFis.numeroProtocolo;
		this.infoObservable$ = this.informacoesService.obterInformacoesCadastraisPJ(cnpj, numeroProtocolo)
			.pipe(
				catchError(err => {
					this.loadingError$.next(true);
					this.initError = err;
					this.messageService.showErrorException('Situação Fiscal - Informações Cadastrais PJ', err);
					return of<InfoCadastralPessoaJuridicaDTO>();
				})
			);
		this.infoSubscription = this.infoObservable$.subscribe(info => this.atualiza(info));
	}

	public ngOnDestroy(): void {
		this.infoSubscription.unsubscribe();
	}

	private atualiza(info: InfoCadastralPessoaJuridicaDTO): void {
		this.info = info;
		this.setResidenteNoBrasil(info);
		this.setAcompanhamentoDiferenciado(info);
		this.setOptanteSimei(info);
		this.setOptanteSimplesFederal(info);
		this.setOptanteSimplesNacional(info);
		this.setPossuiMotivoSituacaoCadastral(info);
		this.setPossuiPreposto(info);
		this.setExibeAdquirente(info);
	}

	private setResidenteNoBrasil(info: InfoCadastralPessoaJuridicaDTO): void {
		this.residenteNoBrasil = info && info.endereco.municipio.codigo != this.codigoResidenteExterior;
	}

	private setAcompanhamentoDiferenciado(info: InfoCadastralPessoaJuridicaDTO): void {
		this.acompanhamentoDiferenciado = info && info.inAcompanhamentoDiferenciado.toUpperCase() === 'S';
	}

	private setOptanteSimei(info: InfoCadastralPessoaJuridicaDTO): void {
		this.optanteSimei = info.inOpcaoSimei === '1';
	}

	private setOptanteSimplesFederal(info: InfoCadastralPessoaJuridicaDTO): void {
		this.optanteSimplesFederal = info.inOpcaoSimplesFederal === '1';
	}

	private setOptanteSimplesNacional(info: InfoCadastralPessoaJuridicaDTO): void {
		this.optanteSimplesNacional = info.inOpcaoSimplesNacional === '1';
	}

	private setPossuiPreposto(info: InfoCadastralPessoaJuridicaDTO): void {
		this.possuiPreposto = info.preposto.cpf.trim().replace(/^0+/, '').length > 0;
	}

	private setPossuiMotivoSituacaoCadastral(info: InfoCadastralPessoaJuridicaDTO): void {
		this.possuiMotivoSituacao = info.situacaoCadastral.toUpperCase() != 'ATIVA';
	}

	public setExibeAdquirente(info: InfoCadastralPessoaJuridicaDTO): void {
		this.exibeAdquirente = info.inCNPJAdquirente != 2;
	}

	solicitaAtendimento(ni: string): void {
		this.painelAtendimentosService.createTab(ni);
	}

	openModalWithTemplate(template: TemplateRef<any>) {
		this.bsModalRef = this.modalService.show(template,
			{ class: 'modal-lg' });
	}
}