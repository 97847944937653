import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ComprotProcesso, MovimentacoesProcesso } from '../../model/comprot-processos';
import { ComprotService } from '../../services/comprot.service';

@Component({
  templateUrl: './comprot-detalhe-processo.html',
  styleUrls: ['./comprot-detalhe-processo.css'],
  providers: [ScopedMessageService]
})
export class DetalheProcessoComprot implements IDynamicPanel {

  @Input() ni: string;
  @Input() processo: ComprotProcesso;

  titulo: string;

  detalheProcesso: MovimentacoesProcesso;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  scopeId: string;
  stopLoading = false;
  initError: Error;

  constructor(
    private comprotService: ComprotService,
    private messageService: ScopedMessageService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.scopeId = this.messageService.scopeId;
    this.ni = params.ni as string;
    this.processo = params.processo as ComprotProcesso;
    this.titulo = `Informações Complementares - Movimentações COMPROT - Processo: ${this.processo.numeroProcessoEditado}`;
    this.obterDetalhesProcessoComprot();
  }

  private obterDetalhesProcessoComprot(): void {
    this.comprotService.obterDetalheProcesso(this.ni, this.processo).subscribe(
      response => {
        this.detalheProcesso = response;
        this.stopLoading = true;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro durante a consulta ao COMPROT', error);
        this.initError = error;
      }
    );
  }
}
