import { Component } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfCnpjCeiPipe } from 'src/app/shared/pipes/formata-cpf-cnpj-cei.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DebitoDividaService } from './debito-divida.service';
import { DebitoDivida, DebitosDividaGroupedByNi } from './model/debito-divida';

@Component({
    selector: 'app-debito-divida',
    templateUrl: './debito-divida.component.html',
    styleUrls: ['./debito-divida.component.css']
})
export class DebitoDividaComponent implements IDynamicPanel {

    initError: Error;

    ni: string;
    titulo: string;
    debitosAgrupados: DebitosDividaGroupedByNi;

    isPessoaFisica = false;
    isPessoaJuridica = false;
    isAtivos = false;
    isSuspensos = false;

    constructor(
        private debitoDividaService: DebitoDividaService,
        private atendimentoService: AtendimentoService,
        private messageService: ScopedMessageService,
        private logService: LogService)
    {
        console.log('DebitoDividaComponent#constructor'); 
    }

    init(ni: string)
    {
        this.ni = ni;
        const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
        const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

        try {
            let debitosAgrupadosObservable: Observable<DebitosDividaGroupedByNi>;
            if(this.isAtivos) {
                debitosAgrupadosObservable = this.agrupaPorNi(
                    this.debitoDividaService.obterDebitosDividaAtivos(ni, numeroProtocolo));
            }
            if(this.isSuspensos) {
                debitosAgrupadosObservable = this.agrupaPorNi(
                    this.debitoDividaService.obterDebitosDividaSuspensos(ni, numeroProtocolo));
            }

            debitosAgrupadosObservable.subscribe(
                (resp) => {
                    this.debitosAgrupados = resp;
                    this.logService.logAjaxReturn('DebitoDividaComponent', 'obterDebitos');
                },
                (err) => {
                    this.initError = err;
                    this.logService.logAjaxError('DebitoDividaComponent', 'obterDebitos', err);
                    this.messageService.showErrorException('Ocorreu um erro ao obter os débitos Divida', err);
                }
            );
        }
        catch (e) {
            this.initError = e;
            this.logService.logError('DebitoDividaComponent', 'obterDebitos', e);
            this.messageService.showErrorException('Ocorreu um erro ao obter os débitos Divida', e);
        }
    }

    private agrupaPorNi(debitos: Observable<DebitoDivida[]>): Observable<DebitosDividaGroupedByNi> {
        return debitos.pipe(  
            map(debitos => _.orderBy(debitos, ['tipoNi', 'ni', 'numeroDebito'], ['asc', 'asc', 'asc'])),
            map(debitos => _.groupBy(debitos, (debito) => debito.tipoNi + ': ' + FormatCpfCnpjCeiPipe.format(debito.ni, debito.tipoNi))),
        );
    }
}

@Component({
    selector: 'app-debito-divida',
    templateUrl: './debito-divida.component.html',
    styleUrls: ['./debito-divida.component.css']
})
export class DebitoDividaPJAtivoComponent extends DebitoDividaComponent {
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.isAtivos = true;
        this.titulo = `Débitos Ativos PGFN Dívidas - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-debito-divida',
    templateUrl: './debito-divida.component.html',
    styleUrls: ['./debito-divida.component.css']
})
export class DebitoDividaPJSuspensoComponent extends DebitoDividaComponent {
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.isSuspensos = true;
        this.titulo = `Débitos Suspensos PGFN Dívidas - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-debito-divida',
    templateUrl: './debito-divida.component.html',
    styleUrls: ['./debito-divida.component.css']
})
export class DebitoDividaPFAtivoComponent extends DebitoDividaComponent {
    init(ni: string) {
        this.isPessoaFisica = true;
        this.isAtivos = true;
        this.titulo = `Débitos Ativos PGFN Dívidas - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-debito-divida',
    templateUrl: './debito-divida.component.html',
    styleUrls: ['./debito-divida.component.css']
})
export class DebitoDividaPFSuspensoComponent extends DebitoDividaComponent {
    init(ni: string) {
        this.isPessoaFisica = true;
        this.isSuspensos = true;
        this.titulo = `Débitos Suspensos PGFN Dívidas - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}
