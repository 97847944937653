import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Relatorio } from '../../model/relatorio';

@Injectable()
export class RelatorioApoioCndService {
  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterRelatorioApoioCnd(tipoNi: string, ni: string, numeroProtocolo: string): Observable<Relatorio> {
    const url = `${this.baseUrl}/situacoesfiscais/relatorio/${tipoNi}/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<Relatorio>(url, options);
  }
}