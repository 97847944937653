import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { DebitoProfiscSuiteRfbModule } from './debito-profisc-suite-rfb.module';
import {
  DebitoProfiscComponent,
  DebitoProfiscPFAtivoComponent,
  DebitoProfiscPFSuspensoComponent,
  DebitoProfiscPJAtivoComponent,
  DebitoProfiscPJSuspensoComponent
} from './debito-profisc.component';
import { DebitoProfiscService } from './services/debito-profisc.service';
import { DebitoProfiscTableComponent } from './table/debito-profisc-table.component';

@NgModule({
  declarations: [
    DebitoProfiscComponent,
    DebitoProfiscPFAtivoComponent,
    DebitoProfiscPFSuspensoComponent,
    DebitoProfiscPJAtivoComponent,
    DebitoProfiscPJSuspensoComponent,
    DebitoProfiscTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    SitfisPipesModule,
    DebitoProfiscSuiteRfbModule
  ],
  providers: [
    DebitoProfiscService
  ],
  exports: [
    DebitoProfiscComponent,
    DebitoProfiscPFAtivoComponent,
    DebitoProfiscPFSuspensoComponent,
    DebitoProfiscPJAtivoComponent,
    DebitoProfiscPJSuspensoComponent
  ]
})
export class DebitoProfiscModule { }
