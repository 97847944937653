<div class="table-responsive">
  <table suiteRfbTable>
    <caption>Processo: {{ numeroProcesso | formatProcessoPipe }}</caption>

    <thead>
      <tr>
        <th *ngIf="isAtivos" class="text-center min-width">Ações</th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">PA/Ex.</th>
        <th class="text-center min-width">Data Vencimento</th>
        <th class="text-center">Valor Original</th>
        <th *ngIf="isAtivos" class="text-center">Saldo Devedor</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let debito of debitos | currentPage: currentPage;" class="vertical-align-middle">
        <td *ngIf="isAtivos" class="text-center">
          <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>

          <ng-template #menuAcoes>
            <div class="btn-group-vertical" role="group">
              <button
                *ngIf="debito.indExibeDarf"
                type="button"
                class="btn btn-default"
                (click)="onEmitirDarf(debito); botaoAcoes.hide();">
                Emitir DARF
              </button>

              <button
                *ngIf="!debito.indExibeDarf"
                type="button"
                class="btn btn-default disabled">
                Emitir DARF
              </button>
            </div>
          </ng-template>
        </td>

        <td class="text-center">
          <via-codigo-receita [codigo]="debito.codigoReceita"></via-codigo-receita>
        </td>

        <td class="text-center">{{ debito.dataPaExercicio | dataPAExercicioPipe: debito.tipoPA }}</td>

        <td class="text-center">{{ debito.dataVencimento | date: 'dd/MM/yyyy' }}</td>

        <td class="text-right">{{ debito.valorOriginal | currency: 'BRL': '' }}</td>

        <td *ngIf="isAtivos" class="text-right">{{ debito.valorSaldoDevedor | currency: 'BRL': '' }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="6">
          <via-pagination [itemAmount]="debitos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
