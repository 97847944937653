import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbOutputMaskModule } from '@serpro/ngx-suite-rfb';

@NgModule({
    imports: [
        SuiteRfbCollapseModule,
        SuiteRfbOutputMaskModule
    ],
    exports: [
        SuiteRfbCollapseModule,
        SuiteRfbOutputMaskModule
    ]
})
export class ConsultarReceitaSuiteRfbModule { }