<suite-rfb-collapse [title]="titulo">
  <ng-container *ngIf="(cnpjsVinculados$ | async) as cnpjsVinculados; else loadingOrError">
    <via-cnpjs-vinculados-table 
      [cnpjsVinculados]="cnpjsVinculados">
    </via-cnpjs-vinculados-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
