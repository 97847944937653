import { formatDate } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { LocalDate } from '@js-joda/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { DateUtilsService } from 'src/app/shared/util/date-utils.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { ParametrosDarf, SistemaOrigemDarf } from '../../model/parametros-darf';
import { DarfService } from '../darf/darf.service';
import { DebitoProfiscService } from './services/debito-profisc.service';
import { ProcessoProfiscDebito } from './model/debito-profisc';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { Subscription } from 'rxjs';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { TipoMenu } from 'src/app/shared/model/feature';
import { Params } from '@angular/router';

@Component({
  selector: 'via-debito-profisc',
  templateUrl: './debito-profisc.component.html',
  styleUrls: ['./debito-profisc.component.css'],
  providers: [ScopedMessageService]
})
export class DebitoProfiscComponent implements IDynamicPanel, OnDestroy {

  titulo: string;

  ni: string;

  numeroProcesso: string;
  numeroProtocolo: string;

  isPessoaFisica: boolean;
  isPessoaJuridica: boolean;
  isAtivos: boolean;
  isSuspensos: boolean;

  debitos: ProcessoProfiscDebito[];

  initError: Error;
  featureEventSub: Subscription;
  idFeature: string;
  tipoMenu: TipoMenu;

  pageSize: number = 10;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private atendimentoService: AtendimentoService,
    private debitoProfiscService: DebitoProfiscService,
    private dateUtilsService: DateUtilsService,
    private darfService: DarfService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  init(id: string, params: any): void {
    // id pode vir com o ni ou identificador processo a depender do menu de origem
    this.ni = params.ni;
    this.tipoMenu = params.tipoMenu;
    this.idFeature = params.idFeature;
  

    this.numeroProcesso = params.numeroProcesso as string;

    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(this.ni);
    this.numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$
    .subscribe(featureEvent => {
      // se a chave de identificação da feature for a mesma

      if (featureEvent.active == false && featureEvent.featureAction.tipoMenu == params?.tipoMenu
        && ( featureEvent.featureAction.id == params?.idFeaturePrincipal)) {
          this.featureEventSub.unsubscribe();
          this.fecharPainel();
      }
    });

    this.debitoProfiscService.obterDebitos(this.numeroProcesso, this.ni, this.numeroProtocolo)
      .subscribe(
        debitos => this.debitos = debitos,
        error => {
          this.messageService.showErrorException("Ocorreu um erro ao obter os débitos do processo", error)
          this.initError = error;
        }
      );
  }

  private fecharPainel(): void {
    this.menuFeatureActionsService.deactivate({
      id: this.idFeature,
      title: 'Débito',
      visible: true,
      rolesAllowed: [],
      tipoMenu: this.tipoMenu,
      class: DebitoProfiscComponent
    });
  }

  public ngOnDestroy(): void {
    if(this.featureEventSub){
      this.featureEventSub.unsubscribe();
    }
  }

  emitirDarf(debito: ProcessoProfiscDebito): void {
    const diferencaDias = this.dateUtilsService.diasDesde(LocalDate.parse(formatDate(debito.dataVencimento, 'yyyy-MM-dd', 'pt')));
    const noPrazo = diferencaDias < 60;
    const msg = noPrazo ? 1 : 0;
    const parametros = this.preencherParametros(debito);
    this.darfService.emitirDarf(parametros, msg);
  }

  private preencherParametros(debito: ProcessoProfiscDebito): ParametrosDarf {
    const parametros: ParametrosDarf = {
      protocolo: this.numeroProtocolo,
      tipoNi: this.isPessoaJuridica ? TipoNI.CNPJ : this.isPessoaFisica ? TipoNI.CPF : null,
      ni: this.ni,
      sistemaOrigem: SistemaOrigemDarf.PROF,
      numeroDebito: debito.numeroDebito,
      numeroProcesso: this.numeroProcesso,
      numeroImovel: '',
      codigoReceita: debito.codigoReceita,
      numeroComponente: '',
      exercicio: '',
    }

    return parametros;
  }
}

@Component({
  selector: 'via-debito-profisc',
  templateUrl: './debito-profisc.component.html',
  styleUrls: ['./debito-profisc.component.css']
})
export class DebitoProfiscPFAtivoComponent extends DebitoProfiscComponent {

  init(numeroProcesso: string, parametrosProcesso: Params): void {
    this.titulo = `Débitos Ativos PROFISC - CPF: ${FormatCpfPipe.formatCPF(parametrosProcesso.ni)}`;
    this.isPessoaFisica = true;
    this.isPessoaJuridica = false;
    this.isAtivos = true;
    this.isSuspensos = false;
    super.init(numeroProcesso, parametrosProcesso);
  }
}

@Component({
  selector: 'via-debito-profisc',
  templateUrl: './debito-profisc.component.html',
  styleUrls: ['./debito-profisc.component.css']
})
export class DebitoProfiscPFSuspensoComponent extends DebitoProfiscComponent {

  init(numeroProcesso: string, parametrosProcesso: Params): void {
    this.titulo = `Débitos Suspensos PROFISC - CPF: ${FormatCpfPipe.formatCPF(parametrosProcesso.ni)}`;
    this.isPessoaFisica = true;
    this.isPessoaJuridica = false;
    this.isAtivos = false;
    this.isSuspensos = true;
    super.init(numeroProcesso, parametrosProcesso);
  }
}

@Component({
  selector: 'via-debito-profisc',
  templateUrl: './debito-profisc.component.html',
  styleUrls: ['./debito-profisc.component.css']
})
export class DebitoProfiscPJAtivoComponent extends DebitoProfiscComponent {

  init(numeroProcesso: string, parametrosProcesso: Params): void {
    this.titulo = `Débitos Ativos PROFISC - CNPJ: ${FormatCnpjPipe.formatCNPJ(parametrosProcesso.ni)}`;
    this.isPessoaFisica = false;
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.isSuspensos = false;
    super.init(numeroProcesso, parametrosProcesso);
  }
}

@Component({
  selector: 'via-debito-profisc',
  templateUrl: './debito-profisc.component.html',
  styleUrls: ['./debito-profisc.component.css']
})
export class DebitoProfiscPJSuspensoComponent extends DebitoProfiscComponent {

  init(numeroProcesso: string, parametrosProcesso: Params): void {
    this.titulo = `Débitos Suspensos PROFISC - CNPJ: ${FormatCnpjPipe.formatCNPJ(parametrosProcesso.ni)}`;
    this.isPessoaFisica = false;
    this.isPessoaJuridica = true;
    this.isAtivos = false;
    this.isSuspensos = true;
    super.init(numeroProcesso, parametrosProcesso);
  }
}
