<div class="modal-header">
    <h4 class="modal-title pull-left">Atenção</h4>
    <span title="Fechar" aria-hidden="true" class="pull-right material-icons icon-24 cursor" (click)="responder('fechar')">close</span>
</div>
<div class="modal-body">
  <span *ngIf="mensagem==1">
     DAS não disponível para impressão no VIA. Acesse o Portal do Simples Nacional.
  </span>
  <span *ngIf="mensagem==2">
    DARF/DAS não disponível para impressão.
  </span>
  <span *ngIf="mensagem==3">
    O DARF/DAS será emitido com cálculo de acréscimos legais para pagamento
    até o último dia útil do mês. Caso o vencimento tenha ocorrido há menos de 60 dias e o contribuinte
    queira efetuar o pagamento antes do último dia útil do mês, emitir o DARF/DAS no sistema de origem.
  </span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="responder('emitir')" *ngIf="mensagem == 3">Emitir</button>
  <button type="button" class="btn btn-default" (click)="responder('fechar')" *ngIf="mensagem == 3">Cancelar</button>
  <button type="button" class="btn btn-default" (click)="responder('fechar')" *ngIf="mensagem != 3">Fechar</button>
</div>
