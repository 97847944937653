<suite-rfb-collapse [title]="titulo">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <table suiteRfbTable *ngIf="dctfs && dctfs.length > 0">
    <thead>
      <tr>
        <th class="text-center">Período</th>
        <th class="text-center">Data Recepção</th>
        <th class="text-center">Número da Declaração</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let dctf of dctfs | currentPage: currentPage">
        <td class="text-center text-nowrap">{{ dctf.anoCalendario | outputMask: '9999/99' | vazio }}</td>
        <td class="text-center text-nowrap">{{ dctf.dataRecepcao | date: 'dd/MM/yyyy' | vazio }}</td>
        <td class="text-center text-nowrap">{{ dctf.numeroDeclaracao | vazio }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="3">
          <via-pagination [itemAmount]="dctfs.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</suite-rfb-collapse>
