import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DynamicActionPanelComponent } from 'src/app/shared/dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { Feature, FeatureAction, TipoMenu } from 'src/app/shared/model/feature';

@Component({
  selector: 'via-situacao-fiscal-atendimento-pf',
  templateUrl: './situacao-fiscal-atendimento-pf.component.html',
  styleUrls: ['./situacao-fiscal-atendimento-pf.component.css']
})
export class SituacaoFiscalAtendimentoPfComponent implements OnInit, OnDestroy {

  @Input() cpf: string;

  @Input() feature: Feature;

  @Input() featureActions: FeatureAction[];

  @ViewChild('widgets', { read: ViewContainerRef, static: true })
  private readonly dashboard: ViewContainerRef;

  private ngUnsubscribe = new Subject<void>();

  private quadrosDinamicosAtivos = new Map<string, DynamicActionPanelComponent>();

  constructor(
    private menuFeatureActionsService: MenuFeatureActionsService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.featureActions.forEach(item => this.menuFeatureActionsService.ajustaVisibilidade(item, null, null, TipoMenu.ATENDIMENTO));

    this.menuFeatureActionsService.featureActionEvent$
      .pipe(takeUntil(this.ngUnsubscribe))// desregistra quando o componente foi encerrado
      .subscribe(featureEvent => {

        const featureAction = featureEvent.featureAction;
        const featureId = featureAction.id;

        // se o quadro pode ser carregado dinamicamente
        if (featureAction.class && featureAction.tipoMenu === TipoMenu.ATENDIMENTO) {

          // se o quadro está sendo aberto e ainda não está aberto
          if (featureEvent.active && !this.quadrosDinamicosAtivos.has(featureId)) {
            const widgetPanel: DynamicActionPanelComponent = this.createWidgetPanel(featureAction, this.cpf);
            // registra um quadro ativo aberto
            this.quadrosDinamicosAtivos.set(featureId, widgetPanel);
            return;
          }

          if (!featureEvent.active && this.quadrosDinamicosAtivos.has(featureId)) {
            // fecha se quadro aberto
            this.quadrosDinamicosAtivos.get(featureId).closeRequest();
            featureEvent.active = false;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private createWidgetPanel(featureAction: FeatureAction, cpf: string): DynamicActionPanelComponent {

    const factory = this.resolver.resolveComponentFactory(DynamicActionPanelComponent);
    const componentRef = this.dashboard.createComponent<DynamicActionPanelComponent>(factory, 0);
    const widget = componentRef.instance;

    widget.onFeatureClosed.pipe(take(1))
      .subscribe(featureId => {
        if (featureId) {
          componentRef.destroy();
          this.quadrosDinamicosAtivos.delete(featureId)
        }
      });

    widget.instantiatePanel(featureAction, cpf);

    return widget;
  }
}
