<div [ngClass]="{ 'modal-container': isModal }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <ng-container *ngIf="dadosBasicos; else loadingOrError;">
      <div class="container-fluid container-opcoes">
        <via-consulta-dados-basicos-pj
          [cnpj]="cnpj"
          [dadosBasicos]="dadosBasicos">
        </via-consulta-dados-basicos-pj>

        <via-historico-pj [cnpj]="cnpj"></via-historico-pj>

        <via-quadro-societario
          [cnpj]="cnpj"
          [incluiHistorico]=false>
        </via-quadro-societario>

        <via-quadro-societario
          [cnpj]="cnpj"
          [incluiHistorico]=true>
        </via-quadro-societario>

        <via-eventos-pj [cnpj]="cnpj"></via-eventos-pj>

        <via-operacao-sucessao
          [cnpj]="cnpj"
          [dadosEmpresa]="dadosBasicos">
        </via-operacao-sucessao>

        <via-informacoes-complementares-pj
          [cnpj]="cnpj">
        </via-informacoes-complementares-pj>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
