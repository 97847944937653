import { Component, Input } from '@angular/core';
import { UtilService } from 'src/app/shared/util/util.service';
import { CNPJService } from "src/app/shared/services/cnpj.service";
import { DetalheDeclaracaoOperacaoImobiliaria } from '../../models/detalhe-declaracao-operacao-imobiliaria';

@Component({
  selector: 'via-doi-detalhe',
  templateUrl: './doi-detalhe.component.html',
  styleUrls: [
    '../table/doi-table.component.css',
    './doi-detalhe.component.css'
  ]
})
export class DoiDetalheComponent {

  @Input() cpf: string;
  @Input() detalhe: DetalheDeclaracaoOperacaoImobiliaria;

  constructor(
    private utilService: UtilService,
    private cnpjService: CNPJService) { }

  /**
   * @param ni CPF ou CNPJ
   * @returns CPF com DV ou CNPJ
   */
  getIdentificadorAjustado(ni: string): string {
    const TAMANHO_CPF_SEM_DV = 9;
    if (ni.length <= TAMANHO_CPF_SEM_DV) {
      return this.utilService.geraCpfComDv(Number(ni));
    }
    return this.cnpjService.geraCnpjComDv(Number(ni));
  }
}
