import { NgModule } from "@angular/core";
import {
  SuiteRfbCollapseModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbPaginationModule,
  SuiteRfbPopoverModule,
  SuiteRfbTableModule,
  SuiteRfbToggleModule
} from "@serpro/ngx-suite-rfb";

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule,
    SuiteRfbPopoverModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
})
export class ObrigatoriedadeDirfSuiteRfbModule { }
