import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { DebitoContaCorrentePF } from './model/debito-ccpf';

@Injectable()
export class CCPFService {

  private baseUrl = environment.APIEndpoint;

  private readonly CODIGO_RECEITA_MULTA_OFICIO_IRPF = '3018';

  constructor(private http: HttpClient) { }

  public obterDebitosContaCorrentePF(cpf: string, numeroProtocolo: string, tipoPendencia: TipoPendencia): Observable<DebitoContaCorrentePF[]> {
    const url = `${this.baseUrl}/situacoesfiscais/pessoasfisicas/${cpf}/ccpf/${tipoPendencia}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoContaCorrentePF[]>(url, options);
  }

  public permiteRegularizacao(debito: DebitoContaCorrentePF): boolean {
    return debito && debito.tipoPendencia === TipoPendencia.COBRANCA;
  }

  public permiteEmissaoDARF(debito: DebitoContaCorrentePF): boolean {
    return debito && !(debito.tipoPendencia === TipoPendencia.EXIGIBILIDADE_SUSPENSA && this.ehMultaOficioIRPF(debito));
  }

  private ehMultaOficioIRPF(debito: DebitoContaCorrentePF): boolean {
    return debito && debito.codigoReceita === this.CODIGO_RECEITA_MULTA_OFICIO_IRPF;
  }
}
