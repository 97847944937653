import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjustaTamanhoNIService } from 'src/app/shared/services/ajusta-tamanho-ni.service';
import { environment } from 'src/environments/environment';
import { TipoNI } from '../../model/enum/tipo-ni';
import { QuadroSocioPjDTO } from '../../model/quadro-socio-pj-dto';

@Injectable()
export class SocioAdminQSAService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient, private ajustaTamanhoNiService: AjustaTamanhoNIService) { }

    // O campo socioAdministrador é tratado pois não traz zeros à esquerda do NI
    public obterSocioAdminQSA(cnpj: string, numeroProtocolo: string): Observable<QuadroSocioPjDTO[]> {
        const url = `${this.baseUrl}/situacoesfiscais/pessoasjuridicas/${cnpj}/quadrosociosadministradores`;
        const options = {
            params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
        };
        return this.http.get<QuadroSocioPjDTO[]>(url, options).pipe(
			map(socios => {
                socios.forEach(socio => {
                    socio.niSocioAdministrador = socio.tipoSocio == '1' ? 
                        this.ajustaTamanhoNiService.transform(socio.niSocioAdministrador,TipoNI.CNPJ) : 
                        this.ajustaTamanhoNiService.transform(socio.niSocioAdministrador,TipoNI.CPF);                        
			    });
			    return socios;
			})
		  );
    }
}