import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedSuiteRfbModule } from "src/app/shared/shared-suite-rfb.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ConsultaDadosBasicosPfModule } from "../consulta-pf/consulta-dados-basicos-pf/consulta-dados-basicos-pf.module";
import { DirfComponent } from "./dirf.component";
import {
  CalculaTotalAnualDeducaoTributavelDependentePipe,
  CalculaTotalAnualDeducaoTributavelPensaoAlimenticiaPipe,
  CalculaTotalAnualDeducaoTributavelPrevidenciaOficialPipe,
  CalculaTotalAnualDeducaoTributavelPrevidenciaPrivadaPipe,
  CalculaTotalAnualDeducoesPipe,
  CalculaTotalAnualImpostoRetidoPipe,
  CalculaTotalAnualRendimentoIsentoPipe,
  CalculaTotalAnualRendimentosTributaveisPipe,
  CalculaTotalMensalDeducoesPipe,
  ConverteNumeroParaNomeDoMesPipe
} from "./dirf.pipes";
import { DirfService } from "./dirf.service";

@NgModule({
  declarations: [
    DirfComponent,
    CalculaTotalMensalDeducoesPipe,
    ConverteNumeroParaNomeDoMesPipe,
    CalculaTotalAnualRendimentosTributaveisPipe,
    CalculaTotalAnualImpostoRetidoPipe,
    CalculaTotalAnualRendimentoIsentoPipe,
    CalculaTotalAnualDeducoesPipe,
    CalculaTotalAnualDeducaoTributavelPrevidenciaOficialPipe,
    CalculaTotalAnualDeducaoTributavelDependentePipe,
    CalculaTotalAnualDeducaoTributavelPensaoAlimenticiaPipe,
    CalculaTotalAnualDeducaoTributavelPrevidenciaPrivadaPipe,
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forChild(),
    NgPipesModule,
    SharedModule,
    PipesModule,
    SharedSuiteRfbModule,
    ConsultaDadosBasicosPfModule,
  ],
  providers: [
    DirfService
  ],
  exports: [
    DirfComponent
  ]
})
export class DirfModule { }
