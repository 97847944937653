import { Component, OnInit } from "@angular/core";
import { AusenciaPorAno } from "../model/ausencia-por-ano";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

@Component({
  selector: 'via-declaracao-ausente-dctf-table',
  templateUrl: 'declaracao-ausente-dctf-table.component.html',
  styleUrls: ['declaracao-ausente-table.component.css']
})
export class DeclaracaoAusenteDctfTableComponent
  extends DeclaracaoAusenteTableComponent
  implements OnInit {

  ausenciasPorAno: AusenciaPorAno[];

  ngOnInit(): void {
    this.ausenciasPorAno = this.agruparAusenciaMensaisPorAno();
  }
}
