import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultarReceitaModule } from '../consultar-receita.module';
import { FiltrosPesquisaReceitaSuiteRfbModule } from './filtros-pesquisa-receita-suite-rfb.module';
import { FiltrosPesquisaReceitaComponent } from './filtros-pesquisa-receita.component';
import { PesquisaReceitaComponent } from './pesquisa-receita/pesquisa-receita.component';

@NgModule({
  declarations: [
    FiltrosPesquisaReceitaComponent,
    PesquisaReceitaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SharedModule,
    FiltrosPesquisaReceitaSuiteRfbModule,
    ConsultarReceitaModule
  ],
  exports: [
    FiltrosPesquisaReceitaComponent
  ],
  providers: [
    BsModalRef
  ]
})
export class FiltrosPesquisaReceitaModule { }
