import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { DetalheOmissao } from '../../models/detalhe-omissao';

@Component({
  selector: 'via-omissos-pf-table',
  templateUrl: './omissos-table.component.html',
  styleUrls: ['./omissos-table.component.css']
})
export class OmissosTableComponent {

  @Input() detalheOmissao: DetalheOmissao;

  @Output() abrirPortalIrpf = new EventEmitter<void>();

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  public onAbrirPortalIrpf(): void {
    this.abrirPortalIrpf.emit();
  }
}
