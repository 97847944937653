<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div class="container-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Receita
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.receita | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Período de Apuração
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.dataPeriodoApuracao | formatDataPipe | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Vencimento
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.dataVencimento| formatDataPipe | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Exercício
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.exercicio | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Valor do Débito
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.valorOriginal | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Saldo Devedor
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.saldoReceita | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Situação
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.situacao | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              N° Processo
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.processoTransferencia| vazio }}
            </p>
          </div>
        </div>
      </div>
      <br>
      <ng-container *ngIf="pagamentosDarf && pagamentosDarf.length > 0 else loadingOrError">
        <div class="table-responsive">
          <form #form="ngForm">
            <table suiteRfbTable>
              <caption>Pagamentos Disponíveis para Alocação</caption>
              <thead>
                <tr>
                  <th class="text-center min-width">Ações</th>
                  <th class="text-center min-width">
                    <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
                    </suite-rfb-toggle>
                  </th>
                  <th class="text-center min-width">Receita</th>
                  <th class="text-center min-width">Número</th>
                  <th class="text-center min-width break-spaces">Data de Arrecadação</th>
                  <th class="text-center min-width break-spaces">Data de Vencimento</th>
                  <ng-container *ngIf="!atendimento">
                    <th class="text-center">Valor Total</th>
                    <th class="text-center">Valor Utilizado</th>
                  </ng-container>
                  <th class="text-center">Valor Disponível</th>
                  <th class="text-center min-width">Valor a Alocar</th>
                  <th class="text-center">Situação</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let pagamentoAlocavel of pagamentosDarf | currentPage: currentPage;">
                  <tr class="vertical-align-middle">
                    <td class="text-center">
                     <ng-container
                        *ngTemplateOutlet="templateAcoesPagamento; context: { pagamentoAlocavel: pagamentoAlocavel }">
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                    </td>
                    <td class="text-center">
                      <via-codigo-receita [codigo]="pagamentoAlocavel.receita"></via-codigo-receita>
                    </td>
                    <td class="text-center">
                      {{ pagamentoAlocavel.numeroPagamento | vazio }}
                    </td>
                    <td class="text-center">
                      {{ pagamentoAlocavel.dataArrecadacao | formatDataPipe | vazio }}
                    </td>
                    <td class="text-center">
                      {{ pagamentoAlocavel.dataVencimento | formatDataPipe | vazio }}
                    </td>
                    <ng-container *ngIf="!atendimento">
                      <td class="text-right">
                        {{ pagamentoAlocavel.detalhes[0].darf | formatCurrency | vazio }}
                      </td>
                      <td class="text-right">
                        {{ pagamentoAlocavel.valorUtilizado | formatCurrency | vazio }}
                      </td>
                    </ng-container>
                    <td class="text-right">
                      {{ pagamentoAlocavel.valorTotalSaldoDisponivel | formatCurrency | vazio }}
                    </td>
                    <td class="text-center">
                      <input
                        [name]="pagamentoAlocavel.numeroPagamento"
                        class="numerico"
                        type="text"
                        currencyMask
                        [(ngModel)]="pagamentoAlocavel.valorParaAlocacao"
                        [options]="currencyMaskOptions"
                        style="max-width: 150px;">
                    </td>
                    <td class="text-left">
                      {{ pagamentoAlocavel.situacaoPagamento | vazio }}
                    </td>
                  </tr>
                  <ng-container *ngIf="rowToggle.show">
                    <ng-container
                      *ngTemplateOutlet="templateDetalhePagamento; context: { pagamentoAlocavel: pagamentoAlocavel }">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
              <tfoot>
                <tr class="tabela-acoes text-left">
                  <td colspan="11">
                    <via-pagination [itemAmount]="pagamentosDarf.length" [(page)]="currentPage"></via-pagination>
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </ng-container>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #templateAcoesPagamento let-pagamentoAlocavel="pagamentoAlocavel">
  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
  <ng-template #menuAcoes>
    <div class="btn-group-vertical" role="group">
      <button type="button" class="btn btn-default"
        [disabled]="isValorParaAlocacaoInvalido(pagamentoAlocavel.valorParaAlocacao)"
        (click)="onAlocarPagamento(pagamentoAlocavel); botaoAcoes.hide();">
        Alocar
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #templateDetalhePagamento let-pagamentoAlocavel="pagamentoAlocavel">
  <tr></tr>
  <tr>
    <td colspan="11">
      <div class="contorno">
        <div class="row" *ngIf="atendimento">
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Total</label>
            <p class="form-control-static dado-formulario">
              {{  pagamentoAlocavel.detalhes[0].darf | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Utilizado</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.valorUtilizado | formatCurrency | vazio }}
            </p>
          </div>
        </div>
        <div class="row" >
          <div class="col-md-4">
            <label class="label-formulario control-label">Banco/Agência</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.bancoAgencia | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Bloqueado Depuração</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.valorBloqueadoDepuracao | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Bloqueado Sinal</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.valorBloqueadoSinal | formatCurrency | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Reservado</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.valorReservado | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Alocação Automática</label>
            <p class="form-control-static dado-formulario">
              {{pagamentoAlocavel.detalhes[3].alocacaoAutomatica | formatCurrency }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Alocação Manual</label>
            <p class="form-control-static dado-formulario">
              {{ pagamentoAlocavel.detalhes[3].alocacaoManual | formatCurrency }}
            </p>
          </div>
        </div>
        <div>
          <via-ccitr-aloca-pagamento-detalhe-alocacoes
            [atendimento]="atendimento"
            [alocacoes]="pagamentoAlocavel.componentes">
          </via-ccitr-aloca-pagamento-detalhe-alocacoes>
        </div>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
