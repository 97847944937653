import { FeatureAction } from 'src/app/shared/model/feature';
import { environment } from 'src/environments/environment';
import { DadosBasicosPJComponent } from '../../cnpj/components/dados-basicos-pj/dados-basicos-pj.component';
import { AguiaPJComponent } from './aguia/aguia.component';
import { ArrolamentoBensSiefPJComponent } from './arrolamento-bens-sief/arrolamento-bens-sief.component';
import { ContaCorrenteItrPJAtivoComponent, ContaCorrenteItrPJSuspensoComponent } from './conta-corrente-itr/conta-corrente-itr.component';
import { DebitoDividaPJAtivoComponent, DebitoDividaPJSuspensoComponent } from './debito-divida/debito-divida.component';
import { DebitoProcessoSiefPJAtivoComponent, DebitoProcessoSiefPJSuspensoComponent } from './debito-processo-sief/debito-processo-sief.component';
import { DebitoProfiscPJAtivoComponent, DebitoProfiscPJSuspensoComponent } from './debito-profisc/debito-profisc.component';
import { DebitoSicobPJAtivoComponent, DebitoSicobPJSuspensoComponent } from './debito-sicob/debito-sicob.component';
import { DebitoSiefPJAtivoComponent, DebitoSiefPJSuspensoComponent } from './debito-sief/debito-sief.component';
import { DeclaracaoAusenteDctfComponent, DeclaracaoAusenteDefisComponent, DeclaracaoAusenteDirfComponent, DeclaracaoAusenteEcfComponent, DeclaracaoAusenteEfdComponent, DeclaracaoAusenteObrapComponent, DeclaracaoAusentePgdasComponent, DeclaracaoAusentePJDitrComponent, DeclaracaoAusentePJGfipComponent, DeclaracaoAusenteSimeiComponent } from './declaracao-ausente/declaracao-ausente.component';
import { ImpedimentoCndPJPgfnComponent, ImpedimentoCndPJRfbComponent } from './impedimento-cnd/impedimento-cnd.component';
import { InfoCadastraisPJComponent } from './info-cadastrais-pj/info-cadastrais-pj.component';
import { InscricaoPgfnPJAtivoComponent, InscricaoPgfnPJSuspensoComponent } from './inscricao-pgfn/inscricao-pgfn.component';
import { IrregularidadeCadastralPJComponent } from './irregularidade-cadastral/irregularidade-cadastral.component';
import { LiberacaoCndPJComponent } from './liberacao-cnd/liberacao-cnd.component';
import { ParcelamentoDividaPJAtivoComponent, ParcelamentoDividaPJSuspensoComponent } from './parcelamento-divida/parcelamento-divida.component';
import { ParcelamentoPaexPJAtivoComponent, ParcelamentoPaexPJSuspensoComponent } from './parcelamento-paex/parcelamento-paex.component';
import { ParcelamentoPgfnPJAtivoComponent, ParcelamentoPgfnPJSuspensoComponent } from './parcelamento-pgfn/parcelamento-pgfn.component';
import { ParcelamentoRefisAtivoComponent, ParcelamentoRefisSuspensoComponent } from './parcelamento-refis/parcelamento-refis.component';
import { ParcelamentoSicobPJAtivoComponent, ParcelamentoSicobPJSuspensoComponent } from './parcelamento-sicob/parcelamento-sicob.component';
import { ParcelamentoSIEFParPJCobrancaComponent, ParcelamentoSIEFParPJExigibilidadeComponent } from './parcelamento-siefpar/parcelamento-siefpar.component';
import { ParcelamentoSimplesNacionalAtivoComponent, ParcelamentoSimplesNacionalSuspensoComponent } from './parcelamento-simples-nacional/parcelamento-simples-nacional.component';
import { ParcelamentoSIPADECobrancaComponent, ParcelamentoSIPADEExigibilidadeComponent } from './parcelamento-sipade/parcelamento-sipade.component';
import { ParcelamentoSisparPJCobrancaComponent, ParcelamentoSisparPJExigibilidadeComponent } from './parcelamento-sispar/parcelamento-sispar.component';
import { ProcessoProfiscPJAtivoComponent, ProcessoProfiscPJSuspensoComponent } from './processo-profisc/processo-profisc.component';
import { ProcessoSiefPJAtivoComponent, ProcessoSiefPJSuspensoComponent } from './processo-sief/processo-sief.component';
import { RelatorioApoioCndComponent } from './relatorio-apoio-cnd/relatorio-apoio-cnd.component';
import { SocioAdminQSAComponent } from './socio-admin-qsa/socio-admin-qsa.component';

const TODOS_PERFIS = ['ATENDEARFB', 'ATENDENTE', 'CONSULTA', 'GERENCIAL1', 'GERENCIAL2'];

const ACTION_RELATORIO_APOIO_CND:       FeatureAction = { id: 'relatorio-apoio-cnd', title: 'Relatório de Apoio à Certidão', class: RelatorioApoioCndComponent, visible: true, rolesAllowed: TODOS_PERFIS };

const ACTION_DADOS_BASICOS:             FeatureAction = { id: 'DadosBasicosCnpj', title: 'Dados Básicos', class: DadosBasicosPJComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_INFORMACOES_CADASTRAIS:    FeatureAction = { id: 'informacoes-cadastrais', title: 'Informações Cadastrais', class: InfoCadastraisPJComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_QUADRO_QSA:                FeatureAction = { id: 'QSA', title: 'Quadro de Sócios e Administradores', class: SocioAdminQSAComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_IRREGULARIDADE_CADASTRAL:  FeatureAction = { id: 'IrregularidadeCadastral', title: 'Irregularidade Cadastral', class: IrregularidadeCadastralPJComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_ARROLAMENTO_BENS_SIEF:     FeatureAction = { id: 'SiefProcessoArrolamento', title: 'Arrolamento de Bens', class: ArrolamentoBensSiefPJComponent, rolesAllowed: TODOS_PERFIS };

/*IMPEDIMENTOS DE EMISSÃO DE CND*/
const ACTION_IMPEDIMENTO_CND_RFB :      FeatureAction = { id: 'ImpedimentoCertidaoRfb', title: 'Na RFB', class: ImpedimentoCndPJRfbComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_IMPEDIMENTO_CND_PGFN:      FeatureAction = { id: 'ImpedimentoCertidaoPgfn', title: 'Na PGFN', class: ImpedimentoCndPJPgfnComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_IMPEDIMENTOS_EMISSAO_CND:  FeatureAction = { id: 'impedimentos-emissao-cnd', title: 'Impedimentos Judiciais',
                                                          children: [ACTION_IMPEDIMENTO_CND_RFB, ACTION_IMPEDIMENTO_CND_PGFN]
                                                        };

/*DÉBITOS FAZENDÁRIOS*/
const ACTION_CCITR_ATIVO:               FeatureAction = { id: 'ItrContaCorrente', title: 'Conta Corrente ITR', class: ContaCorrenteItrPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCSIEF_ATIVO:              FeatureAction = { id: 'SiefDebitoCobranca', title: 'SIEF FISCEL', class: DebitoSiefPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCITR_SUSPENSO:            FeatureAction = { id: 'CcitrExigibilidade', title: 'Conta Corrente ITR', class: ContaCorrenteItrPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCSIEF_SUSPENSO:           FeatureAction = { id: 'SiefDebitoExigibilidade', title: 'Conta Corrente SIEF', class: DebitoSiefPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS PREVIDENCIÁRIOS*/
const ACTION_AGUIA_ATIVO:               FeatureAction = { id: 'AguiaCobranca', title: 'AGUIA', class: AguiaPJComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SICOB_ATIVO:               FeatureAction = { id: 'SicobDebitoCobranca', title: 'SICOB', class: DebitoSicobPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SICOB_SUSPENSO:            FeatureAction = { id: 'SicobDebitoExigibilidade', title: 'SICOB',  class: DebitoSicobPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS PGFN*/
const ACTION_INSC_PGFN_ATIVAS:          FeatureAction = { id: 'PgfnInscricaoCobranca', title: 'Inscrições', class: InscricaoPgfnPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DIVIDAS_PGFN_ATIVAS:       FeatureAction = { id: 'DividaDebitoCobranca', title: 'Dívidas', class: DebitoDividaPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_INSC_PGFN_SUSPENSAS:       FeatureAction = { id: 'PgfnInscricaoExigibilidade', title: 'Inscrições', class: InscricaoPgfnPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DIVIDAS_PGFN_SUSPENSAS:    FeatureAction = { id: 'DividaDebitoExigibilidade', title: 'Dívidas', class: DebitoDividaPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS HISTÓRICO*/
const ACTION_DEBITOS_HISTORICO:         FeatureAction = { id: 'historico-debitos', title: 'Histórico da Sit. Fisc.', visible: false};

/*PROCESSOS FISCAIS*/
const ACTION_PROFISC_ATIVO:             FeatureAction = { id: 'ProfiscProcessoCobranca', title: 'PROFISC', class: ProcessoProfiscPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SIEFPROCESSO_ATIVO:        FeatureAction = { id: 'SiefProcessoCobranca', title: 'SIEF Processos', class: ProcessoSiefPJAtivoComponent, rolesAllowed: TODOS_PERFIS }; //TODO
const ACTION_PROFISC_SUSPENSO:          FeatureAction = { id: 'ProfiscProcessoExigibilidade', title: 'PROFISC', class: ProcessoProfiscPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SIEFPROCESSO_SUSPENSO:     FeatureAction = { id: 'SiefProcessoExigibilidade', title: 'SIEF Processos', class: ProcessoSiefPJSuspensoComponent, rolesAllowed: TODOS_PERFIS }; //TODO

/*OUTROS PROCESSOS*/
const ACTION_PROCESSOS_ARQUIVADOS:      FeatureAction = { id: 'outros-processos-arquivados', title: 'Arquivados', visible: false}; //TODO
const ACTION_PROCESSOS_ATIVOS:          FeatureAction = { id: 'outros-processos-ativos', title: 'Em Andamento', visible: false}; //TODO

/*PARCELAMENTOS FAZENDÁRIOS*/
const ACTION_PARC_SIPADE_ATIVO:         FeatureAction = { id: 'SipadeParcelamentoCobranca', title: 'SIPADE', class: ParcelamentoSIPADECobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_REFIS_ATIVO:          FeatureAction = { id: 'RefisCobranca', title: 'REFIS', class: ParcelamentoRefisAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PAEX_ATIVO:           FeatureAction = { id: 'PaexParcelamentoCobranca', title: 'PAEX', class: ParcelamentoPaexPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIMPLES_NACIONAL_ATIVO: FeatureAction = { id: 'ParcsnCobranca', title: 'Simples Nacional', class: ParcelamentoSimplesNacionalAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIEFPAR_ATIVO:        FeatureAction = { id: 'SiefparParcelamentoCobranca', title: 'SIEFPAR', class: ParcelamentoSIEFParPJCobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIPADE_SUSPENSO:      FeatureAction = { id: 'SipadeParcelamentoExigibilidade', title: 'SIPADE', class: ParcelamentoSIPADEExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_REFIS_SUSPENSO:       FeatureAction = { id: 'RefisExigibilidade', title: 'REFIS', class: ParcelamentoRefisSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PAEX_SUSPENSO:        FeatureAction = { id: 'PaexParcelamentoExigibilidade', title: 'PAEX', class: ParcelamentoPaexPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIMPLES_NACIONAL_SUSPENSO: FeatureAction = { id: 'ParcsnExigibilidade', title: 'Simples Nacional', class: ParcelamentoSimplesNacionalSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIEFPAR_SUSPENSO:     FeatureAction = { id: 'SiefparParcelamentoExigibilidade', title: 'SIEFPAR', class: ParcelamentoSIEFParPJExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };

/*PARCELAMENTOS PREVIDENCIÁRIOS*/
const ACTION_PARC_SICOB_ATIVO:          FeatureAction = { id: 'SicobParcelamentoCobranca', title: 'SICOB', class: ParcelamentoSicobPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SICOB_SUSPENSO:       FeatureAction = { id: 'SicobParcelamentoExigibilidade', title: 'SICOB', class: ParcelamentoSicobPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*PARCELAMENTOS PGFN*/
const ACTION_PARC_PGFN_LEI_ATIVOS:      FeatureAction = { id: 'PgfnParcelamentoCobranca', title: 'Lei 11.941/2009', class: ParcelamentoPgfnPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_SISPAR_ATIVOS:   FeatureAction = { id: 'SisparPgfnCobranca', title: 'SISPAR', class: ParcelamentoSisparPJCobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_DIVIDA_ATIVOS:   FeatureAction = { id: 'DividaParcelamentoCobranca', title: 'Dívidas', class: ParcelamentoDividaPJAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_LEI_SUSPENSOS:   FeatureAction = { id: 'PgfnParcelamentoExigibilidade', title: 'Lei 11.941/2009', class: ParcelamentoPgfnPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_SISPAR_SUSPENSOS:FeatureAction = { id: 'SisparPgfnExigibilidade', title: 'SISPAR', class: ParcelamentoSisparPJExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_DIVIDA_SUSPENSOS:FeatureAction = { id: 'DividaParcelamentoExigibilidade', title: 'Dívidas', class: ParcelamentoDividaPJSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*OUTROS PARCELAMENTOS*/
const ACTION_PARC_E_SOCIAL_ATIVOS:      FeatureAction = { id: 'parcelamentos-e-social-ativos', title: 'E-Social', visible: false};
const ACTION_PARC_E_SOCIAL_SUSPENSOS:   FeatureAction = { id: 'parcelamentos-e-social-suspensos', title: 'E-Social', visible: false};
const ACTION_PARC_PERT_ATIVOS:          FeatureAction = { id: 'parcelamentos-pert-ativos', title: 'PERT', visible: false};
const ACTION_PARC_PERT_SUSPENSOS:       FeatureAction = { id: 'parcelamentos-pert-suspensos', title: 'PERT', visible: false};

/*DECLARAÇÕES - AUSÊNCIA*/
const ACTION_DECL_AUSENCIA_DASN:        FeatureAction = { id: 'DasnAusencia',        title: 'DEFIS',       class: DeclaracaoAusenteDefisComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_DITR:        FeatureAction = { id: 'DitrAusencia',        title: 'DITR',        class: DeclaracaoAusentePJDitrComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_GFIP:        FeatureAction = { id: 'GfipAusencia',        title: 'GFIP',        class: DeclaracaoAusentePJGfipComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_DIRF:        FeatureAction = { id: 'DirfAusencia',        title: 'DIRF',        class: DeclaracaoAusenteDirfComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_DCTF:        FeatureAction = { id: 'DctfAusencia',        title: 'DCTF',        class: DeclaracaoAusenteDctfComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_SIMEI:       FeatureAction = { id: 'SimeiAusencia',       title: 'SIMEI',       class: DeclaracaoAusenteSimeiComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_SISOBRAPREF: FeatureAction = { id: 'SisobraprefAusencia', title: 'SISOBRAPREF', class: DeclaracaoAusenteObrapComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_PGDAS:       FeatureAction = { id: 'PgdasAusencia',       title: 'PGDAS',       class: DeclaracaoAusentePgdasComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_EFD:         FeatureAction = { id: 'EfdAusencia',         title: 'EFD',         class: DeclaracaoAusenteEfdComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_ECF:         FeatureAction = { id: 'EcfAusencia',         title: 'ECF',         class: DeclaracaoAusenteEcfComponent, rolesAllowed: TODOS_PERFIS };

 /** DECLARAÇÕES COM OBRIGATORIEDADE DE ENTREGA */
 const ACTION_DECL_OBRIGA_DIRPJ:        FeatureAction = { id: 'obriga-declaracao-dirpj'         , title: 'DIRPJ (?)' , visible: false};
 const ACTION_DECL_OBRIGA_DASN:         FeatureAction = { id: 'obriga-declaracao-dasn'          , title: 'DASN  (?)' , visible: false};
 const ACTION_DECL_OBRIGA_DITR:         FeatureAction = { id: 'obriga-declaracao-ditr'          , title: 'DITR  (?)' , visible: false};
 const ACTION_DECL_OBRIGA_DIRF:         FeatureAction = { id: 'obriga-declaracao-dirf'          , title: 'DIRF' , visible: false};
 const ACTION_DECL_OBRIGA_DCTF:         FeatureAction = { id: 'obriga-declaracao-dctf'          , title: 'DCTF  (?)' , visible: false};
 const ACTION_DECL_OBRIGA_SIMEI:        FeatureAction = { id: 'obriga-declaracao-simei'         , title: 'SIMEI (?)' , visible: false};
 const ACTION_DECL_OBRIGA_SISOBRAPREF:  FeatureAction = { id: 'obriga-declaracao-sisobrapref'   , title: 'SISOBRAPREF (?)' , visible: false};

/*DECLARAÇÕES - HISTÓRICO*/
const ACTION_DECL_HISTORICO:            FeatureAction = { id: 'historico-declaracao', title: 'Histórico das Declarações', visible: false};

/*LIBERAÇÃO DE CND*/
const ACTION_LIBERACAO_CND:             FeatureAction = { id: 'LiberacaoCnd', title: 'Liberação de CND', class: LiberacaoCndPJComponent, visible: true, rolesAllowed: TODOS_PERFIS };

const ACTION_CERTIDAO_INTRANET:         FeatureAction = { id: 'intranet', title: 'Intranet', visible: true, url: environment.urlCertidoesIntranet, rolesAllowed: TODOS_PERFIS };
const ACTION_CERTIDAO_INTERNET:         FeatureAction = { id: 'internet', title: 'Internet', visible: true, url: `${environment.urlCertidoesInternetPJ}?Tipo=1`, rolesAllowed: TODOS_PERFIS };
const ACTION_CERTIDOES:                 FeatureAction = { id: 'certidoes', title: 'Certidões',
                                                          children: [ACTION_CERTIDAO_INTRANET, ACTION_CERTIDAO_INTERNET]
                                                        };

const ACTION_DEBITOS_ATIVOS_FAZENDARIOS: FeatureAction = {
  id: 'SITFIS - Débitos Ativos - Fazendários',
  title: 'Fazendários',
  collapsed: false,
  children: [ACTION_CCITR_ATIVO, ACTION_CCSIEF_ATIVO]
};

const ACTION_DEBITOS_ATIVOS_PREVIDENCIARIOS: FeatureAction = {
  id: 'SITFIS - Débitos Ativos - Previdenciários',
  title: 'Previdenciários',
  collapsed: false,
  children: [ACTION_SICOB_ATIVO, ACTION_AGUIA_ATIVO]
};

const ACTION_DEBITOS_ATIVOS_PGFN: FeatureAction = {
  id: 'SITFIS - Débitos Ativos - PGFN',
  title: 'PGFN',
  collapsed: false,
  children: [ACTION_INSC_PGFN_ATIVAS,ACTION_DIVIDAS_PGFN_ATIVAS]
};

const ACTION_DEBITOS_ATIVOS: FeatureAction = {
  id: 'SITFIS - Débitos Ativos',
  title: 'Ativos',
  collapsed: false,
  children: [ACTION_DEBITOS_ATIVOS_FAZENDARIOS, ACTION_DEBITOS_ATIVOS_PREVIDENCIARIOS, ACTION_DEBITOS_ATIVOS_PGFN]
};

const ACTION_DEBITOS_SUSPENSOS_FAZENDARIOS: FeatureAction = {
  id: 'SITFIS - Débitos Suspensos - Fazendários',
  title: 'Fazendários',
  collapsed: false,
  children: [ACTION_CCITR_SUSPENSO, ACTION_CCSIEF_SUSPENSO]
};

const ACTION_DEBITOS_SUSPENSOS_PREVIDENCIARIOS: FeatureAction = {
  id: 'SITFIS - Débitos Suspensos - Previdenciários',
  title: 'Previdenciários',
  collapsed: false,
  children: [ACTION_SICOB_SUSPENSO]
};

const ACTION_DEBITOS_SUSPENSOS_PGFN: FeatureAction = {
  id: 'SITFIS - Débitos Suspensos - PGFN',
  title: 'PGFN',
  collapsed: false,
  children: [ACTION_INSC_PGFN_SUSPENSAS, ACTION_DIVIDAS_PGFN_SUSPENSAS]
};

const ACTION_DEBITOS_SUSPENSOS: FeatureAction = {
  id: 'SITFIS - Débitos Suspensos',
  title: 'Suspensos',
  collapsed: false,
  children: [ACTION_DEBITOS_SUSPENSOS_FAZENDARIOS, ACTION_DEBITOS_SUSPENSOS_PREVIDENCIARIOS, ACTION_DEBITOS_SUSPENSOS_PGFN]
};

const ACTION_SITFIS_DEBITOS_HISTORICO: FeatureAction = {
  id: 'SITFIS - Débitos Histórico',
  title: 'Histórico',
  collapsed: false,
  children: [ACTION_DEBITOS_HISTORICO]
};

const ACTION_DEBITOS: FeatureAction = {
  id: 'SITFIS - Débitos', title: 'Débitos',
  collapsed: false,
  children: [ACTION_DEBITOS_ATIVOS, ACTION_DEBITOS_SUSPENSOS, ACTION_SITFIS_DEBITOS_HISTORICO]
};

const ACTION_PROCESSOS: FeatureAction = {
  id: 'SITFIS - Processos',
  title: 'Processos',
  children: [
    {
      id: 'SITFIS - Processos - Ativos',
      title: 'Ativos',
      children: [ACTION_PROFISC_ATIVO, ACTION_SIEFPROCESSO_ATIVO]
    },
    {
      id: 'SITFIS - Processos - Suspensos',
      title: 'Suspensos',
      children: [ACTION_PROFISC_SUSPENSO, ACTION_SIEFPROCESSO_SUSPENSO]
    },
    {
      id: 'SITFIS - Processos - Outros',
      title: 'Outros Processos',
      children: [ACTION_PROCESSOS_ARQUIVADOS, ACTION_PROCESSOS_ATIVOS]
    },
  ]
};

const ACTION_PARCELAMENTOS: FeatureAction = {
  id: 'SITFIS - Parcelamentos',
  title: 'Parcelamentos',
  children: [
    {
      id: 'SITFIS - Parcelamentos - Ativos',
      title: 'Ativos',
      children: [
        {
          id: 'SITFIS - Parcelamentos - Ativos - Fazendários',
          title: 'Fazendários',
          children: [ACTION_PARC_SIPADE_ATIVO, ACTION_PARC_REFIS_ATIVO, ACTION_PARC_PAEX_ATIVO, ACTION_PARC_SIMPLES_NACIONAL_ATIVO, ACTION_PARC_SIEFPAR_ATIVO]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - Previdenciários',
          title: 'Previdenciários',
          children: [ACTION_PARC_SICOB_ATIVO]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - PGFN',
          title: 'PGFN',
          children: [ACTION_PARC_PGFN_LEI_ATIVOS, ACTION_PARC_PGFN_SISPAR_ATIVOS, ACTION_PARC_PGFN_DIVIDA_ATIVOS]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - Outros',
          title: 'Outros Parcelamentos',
          children: [ACTION_PARC_E_SOCIAL_ATIVOS, ACTION_PARC_PERT_ATIVOS]
        },
      ]
    },
    {
      id: 'SITFIS - Parcelamentos - Suspensos',
      title: 'Suspensos',
      children: [
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Fazendários',
          title: 'Fazendários',
          children: [ACTION_PARC_SIPADE_SUSPENSO, ACTION_PARC_REFIS_SUSPENSO, ACTION_PARC_PAEX_SUSPENSO, ACTION_PARC_SIMPLES_NACIONAL_SUSPENSO, ACTION_PARC_SIEFPAR_SUSPENSO]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Previdenciários',
          title: 'Previdenciários',
          children: [ACTION_PARC_SICOB_SUSPENSO]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - PGFN',
          title: 'PGFN',
          children: [ACTION_PARC_PGFN_LEI_SUSPENSOS, ACTION_PARC_PGFN_SISPAR_SUSPENSOS, ACTION_PARC_PGFN_DIVIDA_SUSPENSOS]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Outros',
          title: 'Outros Parcelamentos',
          children: [ACTION_PARC_E_SOCIAL_SUSPENSOS, ACTION_PARC_PERT_SUSPENSOS]
        },
      ]
    },
  ]
};

const ACTION_DECLARACOES: FeatureAction = {
  id: 'SITFIS - Declarações',
  title: 'Declarações',
  children: [
    {
      id: 'SITFIS - Declarações - Ausentes',
      title: 'Ausentes',
      children: [
        ACTION_DECL_AUSENCIA_DASN,
        ACTION_DECL_AUSENCIA_DITR,
        ACTION_DECL_AUSENCIA_GFIP,
        ACTION_DECL_AUSENCIA_DIRF,
        ACTION_DECL_AUSENCIA_DCTF,
        ACTION_DECL_AUSENCIA_SIMEI,
        ACTION_DECL_AUSENCIA_SISOBRAPREF,
        ACTION_DECL_AUSENCIA_PGDAS,
        ACTION_DECL_AUSENCIA_EFD,
        ACTION_DECL_AUSENCIA_ECF,
      ]
    },
    {
      id: 'SITFIS - Declarações - Obrigatoriedade Entrega',
      title: 'Obrigatoriedade de Entrega',
      children: [
        ACTION_DECL_OBRIGA_DIRPJ,
        ACTION_DECL_OBRIGA_DASN,
        ACTION_DECL_OBRIGA_DITR,
        ACTION_DECL_OBRIGA_DIRF,
        ACTION_DECL_OBRIGA_DCTF,
        ACTION_DECL_OBRIGA_SIMEI,
        ACTION_DECL_OBRIGA_SISOBRAPREF
      ]
    },
    {
      id: 'SITFIS - Declarações - Histórico',
      title: 'Histórico',
      children: [ACTION_DECL_HISTORICO]
    },
  ]
};

// Coleção de features que são opções do menu.
// Aqui é uma estrutura hierárquica, os itens do array são galhos de primeiro nível.
// A formatação visual do menu suporta até 2 níveis: pai -> filhos -> netos (folhas).
// Todos os itens deve possuir um id único de indentificação, os id das folhas serão utilizados para acionar
// seus respectivos quadros no panel de quadros.
export const FEATUREACTIONS_PJ: FeatureAction[] = [
  ACTION_RELATORIO_APOIO_CND,
  ACTION_INFORMACOES_CADASTRAIS,
  ACTION_QUADRO_QSA,
  ACTION_IRREGULARIDADE_CADASTRAL,
  ACTION_ARROLAMENTO_BENS_SIEF,
  ACTION_IMPEDIMENTOS_EMISSAO_CND,
  ACTION_DEBITOS,
  ACTION_PROCESSOS,
  ACTION_PARCELAMENTOS,
  ACTION_DECLARACOES,
  ACTION_CERTIDOES,
  ACTION_LIBERACAO_CND,
];

export const FEATUREACTIONS_PJ_VINCULADO: FeatureAction[] = [
  ACTION_QUADRO_QSA,
  ACTION_IRREGULARIDADE_CADASTRAL,
  ACTION_IMPEDIMENTOS_EMISSAO_CND,
  ACTION_DEBITOS,
  ACTION_PROCESSOS,
  ACTION_PARCELAMENTOS,
  ACTION_DECLARACOES,
  ACTION_CERTIDOES,
  ACTION_LIBERACAO_CND,
];

export const DADOS_BASICOS_PJ = ACTION_DADOS_BASICOS;

// AÇÕES ACIONADAS A PARTIR DOS QUADROS PROCESSOS PROFISC
export const DEBITO_PROFISC_PJ_ATIVO: FeatureAction = { id: 'DebitoProfiscPJAtivo', title: 'Débito PROFISC Pessoa Jurídica Ativo', class: DebitoProfiscPJAtivoComponent, visible: true };
export const DEBITO_PROFISC_PJ_SUSPENSO: FeatureAction = { id: 'DebitoProfiscPJSuspenso', title: 'Débito PROFISC Pessoa Jurídica Suspenso', class: DebitoProfiscPJSuspensoComponent, visible: true };

// AÇÕES ACIONADAS A PARTIR DOS QUADROS PROCESSOS SIEF
export const DEBITO_PROCESSO_SIEF_PJ_ATIVO: FeatureAction = { id: 'DebitoProcessoSiefPJAtivo', title: 'Débito Processo SIEF Pessoa Jurídica Ativo', class: DebitoProcessoSiefPJAtivoComponent, visible: true };
export const DEBITO_PROCESSO_SIEF_PJ_SUSPENSO: FeatureAction = { id: 'DebitoProcessoSiefPJSuspenso', title: 'Débito Processo SIEF Pessoa Jurídica Suspenso', class: DebitoProcessoSiefPJSuspensoComponent, visible: true };
