import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CnpjSuiteRfbModule } from '../../cnpj-suite-rfb.module';
import { CnpjService } from '../../services/cnpj.service';
import { EventosPjModule } from '../eventos/eventos-pj.module';
import { HistoricoPjModule } from '../historico/historico-pj.module';
import { InformacoesComplementaresModule } from '../informacoes-complementares/informacoes-complementares.module';
import { OperacaoSucessaoModule } from '../operacao-sucessao/operacao-sucessao.module';
import { QuadroSocietarioModule } from '../quadro-societario/quadro-societario.module';
import { ConsultaDadosBasicosPJModule } from './consulta-dados-basicos-pj/consulta-dados-basicos-pj.module';
import { ConsultaPjComponent } from './consulta-pj.component';

@NgModule({
  declarations: [
    ConsultaPjComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    CnpjSuiteRfbModule,
    ConsultaDadosBasicosPJModule,
    InformacoesComplementaresModule,
    QuadroSocietarioModule,
    OperacaoSucessaoModule,
    HistoricoPjModule,
    EventosPjModule,
  ],
  exports: [
    ConsultaPjComponent
  ],
  providers: [
    CnpjService
  ]
})
export class ConsultaPjModule { }
