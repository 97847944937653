import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ConsultaObrigatoriedadeDirfComponent } from "src/app/obrigatoriedade-dirf/components/consulta/consulta-obrigatoriedade-dirf.component";
import { AusenciaDeclaracao } from "../model/declaracao-ausente";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

@Component({
  selector: 'via-declaracao-ausente-dirf-table',
  templateUrl: 'declaracao-ausente-dirf-table.component.html',
  styleUrls: ['declaracao-ausente-table.component.css']
})
export class DeclaracaoAusenteDirfTableComponent
  extends DeclaracaoAusenteTableComponent {

  constructor(private modalService: BsModalService) { super(); }

  onDetalharDeclaracaoAusenteDirf(ausencia: AusenciaDeclaracao): void {
    this.modalService.show(ConsultaObrigatoriedadeDirfComponent, {
      initialState: {
        cnpj: this.ni,
        anoCalendario: ausencia.periodoApuracaoOmissao[0]
      }
    });
  }
}
