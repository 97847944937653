<suite-rfb-collapse [title]="titulo" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="eventos && eventos.length > 0; else loadingOrError;">
    <div class="table-responsive">
      <table suiteRfbTable>
        <thead>
          <tr>
            <th class="text-center min-width">
              <suite-rfb-toggle
                title="Expandir/Contrair Todos"
                (expanded)="expandedAll($event)">
              </suite-rfb-toggle>
            </th>
            <th class="text-center min-width">Data Evento</th>
            <th class="text-center">Evento</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let detalhe of eventos | currentPage: currentPage">
            <tr class="vertical-align-middle">
              <td>
                <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
              </td>
              <td class="text-center text-nowrap">
                {{ detalhe.dataEvento | date: 'dd/MM/yyyy' | vazio }}
              </td>
              <td class="text-left">
                {{ detalhe.evento | codigoDescricao | vazio }}
              </td>
            </tr>

            <ng-container *ngIf="rowToggle.show">
              <tr></tr>
              <tr>
                <td colspan="3" class="background-inherit">
                  <via-detalhe-evento-pj [detalhe]="detalhe"></via-detalhe-evento-pj>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>

        <tfoot>
          <tr class="tabela-acoes text-left">
            <td colspan="3">
              <via-pagination [itemAmount]="eventos.length" [(page)]="currentPage"></via-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
