<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <ng-container *ngIf="ccitrPagamentosDarf && ccitrPagamentosDarf.length > 0; else loadingOrError;">
    <div class="table-responsive">
      <table suiteRfbTable>
          <thead>
          <tr>
            <th class="text-center min-width">Ações</th>
            <th class="text-center min-width">
              <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
            </th>
            <th class="text-center">Pagamento</th>
            <th class="text-center">PA</th>
            <th class="text-center">Receita</th>
            <th class="text-center">Data<br/> Arrecadação</th>
            <th class="text-center">Data<br/> Vencimento</th>
            <th class="text-center">Situação Pagamento</th>
            <th class="text-center">Motivo Impedimento</th>
            <th class="text-center">Banco / Agência</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of ccitrPagamentosDarf | currentPage: currentPage">
            <tr class="vertical-align-middle">
              <td class="text-center">
                <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                <ng-template #menuAcoes>
                  <div class="btn-group-vertical" role="group">
                    <button type="button" class="btn btn-default" (click)="onAlocarDebito(item); botaoAcoes.hide();">
                      Alocar Débito
                    </button>
                  </div>
                </ng-template>
              </td>
              <td>
                <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
              </td>
              <td class="text-right">{{ item.numeroPagamento }}</td>
              <td class="text-center">{{ item.periodoApuracao | formatDataPipe }}</td>
              <td class="text-center">
                <via-codigo-receita [codigo]="item.receita"></via-codigo-receita>
              </td>
              <td class="text-center">{{ item.dataArrecadacao | formatDataPipe }}</td>
              <td class="text-center">{{ item.dataVencimento | formatDataPipe }}</td>
              <td class="text-left">{{ item.situacaoPagamento | vazio }}</td>
              <td class="text-left">{{ item.motivoImpedimento | vazio }}</td>
              <td class="text-center">{{ item.bancoAgencia | vazio }}</td>
            </tr>
            <ng-container *ngIf="rowToggle.show">
              <tr></tr>
              <tr>
                  <td colspan="13" class="background-inherit">
                    <div class="contorno-ccitr">
                    <via-detalhe-ccitr-pagamentos-darf [item]="item" [nirf]="nirf" [numeroCt]="numeroCreditoTributario" [parametrosConsultaPagamento]="parametros" [atendimento]="atendimento"></via-detalhe-ccitr-pagamentos-darf>
                  </div>
                  </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="tabela-acoes text-left">
           <td colspan="13">
             <via-pagination [itemAmount]="ccitrPagamentosDarf.length" [(page)]="currentPage"></via-pagination>
            </td>
         </tr>
        </tfoot>
      </table>
    </div>
  </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
