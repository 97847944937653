export const environment = {
  production: false,
  csrfEnabled: true,
  APIEndpoint: 'https://via-angular-des.suiterfb.desenv.serpro/api',
  version: '0.0.0-SNAPSHOT', // NÂO MEXER! Ajustado automaticamente pelo pipeline do git no processo de build (gitlab-ci.yml)
  urlCertidoesIntranet: 'https://certidao.suiterfb.receita.fazenda/CertidaoSuiteRFB',
  urlCertidoesInternetPF: 'http://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PF/Emitir',
  urlCertidoesInternetPJ: 'http://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PJ/Emitir',
  urlExtratoPaex: 'https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATSPO/Paex/PaexExtrato/PaexExtrato_P11.asp',
  urlExtratoPgfn: 'https://www.receita.fazenda.gov.br/Aplicacoes/SSL/ATSPO/Paex/PaexExtrato/PaexExtrato_P11.asp',
  urlInscricaoPgfn: 'https://www2.pgfn.fazenda.gov.br/ecac/contribuinte/inscricao/consulta.jsf',
  urlSisparConsultaDarf: 'https://sisparnet.pgfn.fazenda.gov.br/sisparInternet/internet/darf/consultaParcelamentoDarfInternet.xhtml',
  urlExtratoIrpf: 'https://prtlirpf.suiterfb.hom.serpro/extratodirpf/#/acessovia',
  urlPortalIrpf: 'https://prtlirpf.suiterfb..hom.serpro/portalirpf-declaracoes',
  urlPortalSiefWeb: 'https://siefweb.suiterfb.desenv.serpro',
  urlPortalDirf: 'http://10.39.20.98/dirfportal',
  urlE_Defesa: 'https://requer.suiterfb.hom.serpro/requer-intraCont/private/pages/telaInicial.jsf',
  urlPortalCadastros: 'https://portaldecadastros.rfb.gov.br/portalCadastro-intra/public/login.html',
  urlTOM: 'http://tom-web.receita.fazenda/TomWeb/logado.jsf',
  urlExtratoDirpf: 'https://www1.suiterfb.hom.serpro/camweb/grupo?sis=PRTLIRPFJAVA&url=prtlirpf.suiterfb.hom.serpro%2Fextratodirpf%2F#/acessovia/',
  urlPortalIrpfOmissos: 'https://malha.serpro/omissospf/OMS/OMSGC006.asp'
};

