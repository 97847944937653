import { Component } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { InfoCadastraisPJService } from '../info-cadastrais-pj/info-cadastrais-pj.service';
import { LiberacaoCndService } from './liberacao-cnd.service';
import { LiberacaoCnd } from './model/liberacao-cnd';

@Component({
  templateUrl: './liberacao-cnd.component.html',
  styleUrls: ['./liberacao-cnd.component.css']
})
export class LiberacaoCndComponent implements IDynamicPanel {

  initError: Error;
  liberacoes: LiberacaoCnd[];

  ni: string;
  tipoNI: TipoNI;
  codEnte: string;
  titulo: string;

  constructor(
    private liberacaoService: LiberacaoCndService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private infoCadastraisService: InfoCadastraisPJService,
    private atendimentoService: AtendimentoService) {
    console.log('LiberacaoCndComponent#constructor');
  }

  init(ni: string): void {
    this.ni = ni;
    if (this.tipoNI == TipoNI.CNPJ) {
      let infosAtendimento = this.atendimentoService.getInfosAtendimento(this.ni);
      this.infoCadastraisService.obterInformacoesCadastraisPJ(this.ni, infosAtendimento.protocoloSitFis.numeroProtocolo).subscribe(
        (info) => {
          this.codEnte = info.codigoEnteFederativoResponsavel;
          this.obterCnd();
        }, (erro) => {
          this.messageService.showErrorException('Erro ao verificar Informações Cadastrais', erro);
        }
      )
    } else {
      this.obterCnd();
    }
  }

  private obterCnd(): void {
    this.liberacaoService.obterLiberacaoCnd(this.tipoNI, this.ni, this.codEnte).subscribe(
      (resp) => {
        this.liberacoes = resp;
        this.logService.logAjaxReturn('LiberacaoCndComponent.obterLiberacaoCnd = ', 'Recuperar Liberacao CND', this.liberacoes);
      },
      (err) => {
        this.initError = err;
        this.logService.logAjaxError('LiberacaoCndComponent.obterLiberacaoCnd = ', 'Recuperar Liberacao CND', err);
        this.messageService.showErrorExceptionNI(this.ni, 'Ocorreu um erro ao obter as Liberações de CND', err);
      }
    );
  }
}

@Component({
  templateUrl: './liberacao-cnd.component.html',
  styleUrls: ['./liberacao-cnd.component.css']
})
export class LiberacaoCndPFComponent extends LiberacaoCndComponent {

  init(ni: string) {
    this.tipoNI = TipoNI.CPF;
    this.titulo = 'Liberação da emissão da Certidão Conjunta - CPF: ' + FormatCpfPipe.formatCPF(ni);
    super.init(ni);
  }
}

@Component({
  templateUrl: './liberacao-cnd.component.html',
  styleUrls: ['./liberacao-cnd.component.css']
})
export class LiberacaoCndPJComponent extends LiberacaoCndComponent {

  init(ni: string) {
    this.tipoNI = TipoNI.CNPJ;
    this.titulo = 'Liberação da emissão da Certidão Conjunta - CNPJ: ' + FormatCnpjPipe.formatCNPJ(ni);
    super.init(ni);
  }
}
