import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { DebitoSicob } from '../model/debito-sicob';

@Component({
  selector: 'via-debito-sicob-table',
  templateUrl: 'debito-sicob-table.component.html',
  styleUrls: ['debito-sicob-table.component.css']
})
export class DebitoSicobTableComponent {

  @Input() debitos: DebitoSicob[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
