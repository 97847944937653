import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxMaskModule } from "ngx-mask";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";
import { PagamentoModule } from "../pagamento/pagamento.module";
import { ConsultaObrigatoriedadeDirfComponent } from "./components/consulta/consulta-obrigatoriedade-dirf.component";
import { ObrigatoriedadeDirfDctfsTableComponent } from "./components/consulta/dctfs-table/obrigatoriedade-dirf-dctfs-table.component";
import { ObrigatoriedadeDirfPagamentosTableComponent } from "./components/consulta/pagamentos-table/obrigatoriedade-dirf-pagamentos-table.component";
import { PesquisaObrigatoriedadeDirfComponent } from "./components/pesquisa/pesquisa-obrigatoriedade-dirf.component";
import { ObrigatoriedadeDirfSuiteRfbModule } from "./obrigatoriedade-dirf-suite-rfb.module";
import { ObrigatoriedadeDirfService } from "./services/obrigatoriedade-dirf.service";

@NgModule({
  declarations: [
    PesquisaObrigatoriedadeDirfComponent,
    ConsultaObrigatoriedadeDirfComponent,
    ObrigatoriedadeDirfPagamentosTableComponent,
    ObrigatoriedadeDirfDctfsTableComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    NgxMaskModule,
    SharedModule,
    PipesModule,
    PagamentoModule,
    ObrigatoriedadeDirfSuiteRfbModule
  ],
  exports: [
    PesquisaObrigatoriedadeDirfComponent
  ],
  providers: [
    ObrigatoriedadeDirfService
  ]
})
export class ObrigatoriedadeDirfModule { }
