import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { Componente, CreditoTributario } from '../../../../../models/credito-tributario';
import { CcpfAlocaPagamentoComponent } from '../../pagamento/aloca-pagamento/aloca-pagamento.component';
import { ParametroComponenteDebito } from '../../pagamento/models/parametros-pesquisa';
import { CCPFRevalidacaoDataCtComponent } from '../../revalidacao-data-ct/revalidacao-data-ct.component';

@Component({
  selector: 'via-ccpf-table-componentes',
  templateUrl: 'consulta-credito-componentes-table.component.html',
  styleUrls: ['consulta-credito-componentes-table.component.css']
})
export class CcpfConsultaCreditoComponentesTableComponent implements OnInit {

  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;
  @Input() atendimento = false;
  @Input() cpf: string;
  @Input() credito: CreditoTributario;
  @Input() componentes: Componente[];
  @Input() exibirAcoes = true;

  semComponente = false;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  ngOnInit(): void {
    this.semComponente = isEmpty(this.componentes[0].numero);
  }

  onRevalidarData(componente: Componente): void {
    const revalidacaoCotasFeature = {
      id: `CCPFRevalidacaoDataCT_credito_${this.credito.ct}_componente_${componente.numero}`,
      title: 'CCPF - Revalidar Data',
      class: CCPFRevalidacaoDataCtComponent,
      parametros: {
        cpf: this.cpf,
        credito: this.credito,
        componente: componente,
        parametrosPesquisa: this.parametrosPesquisa
      }
    } as FeatureAction;

    if (this.atendimento) {
      revalidacaoCotasFeature.visible = true;
      revalidacaoCotasFeature.rolesAllowed = [];
      revalidacaoCotasFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(revalidacaoCotasFeature);
      return;
    }

    this.dynamicModalService.abrirModal(revalidacaoCotasFeature);
  }

  onAlocarPagamentos(componente: Componente): void {
    const parametros: ParametroComponenteDebito = {
      cpfContribuinte: this.cpf,
      componente: componente,
      credito: this.credito,
      parametrosPesquisa: this.parametrosPesquisa,
      atendimento: this.atendimento
    };

    const feature = {
      id: `CCPF_AlocarPagamentos_${this.credito.ct}_componente_${componente.numero}`,
      class: CcpfAlocaPagamentoComponent,
      title: 'CCPF - Alocar Pagamentos',
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      feature.visible = true;
      feature.rolesAllowed = [];
      feature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(feature);
      return;
    }

    this.dynamicModalService.abrirModal(feature);
  }
}
