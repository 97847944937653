<div class="">
    <suite-rfb-collapse [title]="titulo">
        <div class="botoes">

            <button *ngIf="relatorio" type="button" class="btn btn-default" (click)="onVisualizar()">Visualizar</button>
            <button *ngIf="relatorio" type="button" class="btn btn-default" (click)="onSalvar()">Salvar</button>
        
            <span style="color:blue;font-weight:bold" *ngIf="!relatorio">Processando relatorio...</span>
        
        </div>
    </suite-rfb-collapse>
</div>
