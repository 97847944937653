<suite-rfb-collapse [title]="titulo">
  <div class="container-fluid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label [for]="cpfFormGroupName" class="control-label">CPF</label>
            <input
              class="form-control"
              type="text"
              [formControlName]="cpfFormGroupName"
              autocomplete="off"
              suiteRfbInputMask="999.999.999-99"
              viaValidNi>
          </div>
        </div>

        <div class="col-md-6" style="margin-top: 25px;">
          <div class="form-group">
            <div class="text-center">
              <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Limpar</button>
              <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Solicitar</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <ng-container *ngIf="solicitouRetorno">
      <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId" *ngIf="processouResultado"></suite-rfb-alert-messages-with-datetime>
      <loading-animation *ngIf="!stopLoading"></loading-animation>
    </ng-container>
  </div>
</suite-rfb-collapse>
