import { Component } from '@angular/core';
import FileSaver from 'file-saver';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { PdfViewerService } from 'src/app/workplace/services/pdf-viewer.service';
import { Relatorio } from '../../model/relatorio';
import { RelatorioApoioCndService } from './relatorio-apoio-cnd.service';

@Component({
    selector: 'via-relatorio-apoio-cnd',
    templateUrl: './relatorio-apoio-cnd.component.html',
    styleUrls: ['./relatorio-apoio-cnd.component.css']
})
export class RelatorioApoioCndComponent implements IDynamicPanel {

    constructor(
        private pdfViewerService: PdfViewerService,
        private relatorioService: RelatorioApoioCndService,
        private atendimentoService: AtendimentoService,
        private messageService: ScopedMessageService,
        private logService: LogService) {
    }

    initError: Error;
    relatorio: Relatorio;
    ni: string;
    titulo: string;

    init(ni: string) {
        this.ni = ni;

        if (ni.length > 11) {
            this.titulo = `Relatório de Apoio à Certidão - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        }
        else {
            this.titulo = `Relatório de Apoio à Certidão - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        }

        const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
        const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;
        const tipoNi = informacoesCompletasAtendimento.protocoloSitFis.tipoNiContribuinte;

        try {
            this.relatorioService.obterRelatorioApoioCnd(tipoNi, ni, numeroProtocolo).subscribe(
                (resp) => {
                    this.relatorio = resp;
                    this.logService.logAjaxReturn('RelatorioApoioCndComponent', 'obterRelatorioApoioCnd', resp.situacao);
                },
                (err) => {
                    this.initError = err;
                    this.logService.logAjaxError('RelatorioApoioCndComponent', 'obterRelatorioApoioCnd', err);
                    this.messageService.showErrorException('Ocorreu um erro ao obter o relatorio de apoio à certidão', err);
                }
            );
        }
        catch (e) {
            this.initError = e;
            this.logService.logError('RelatorioApoioCndComponent', 'obterRelatorioApoioCnd', e);
            this.messageService.showErrorException('Ocorreu um erro ao obter o relatorio de apoio à certidão', e);
        }
    }

    onVisualizar() {
        this.pdfViewerService.display({
            base64: this.relatorio.pdf,
            filename: `Relatorio-Situacao-Fiscal-${this.ni}.pdf`,
        });
    }

    onSalvar() {
        const byteCharacters = atob(this.relatorio.pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        FileSaver.saveAs(blob, `Relatorio-Situacao-Fiscal-${this.ni}.pdf`);
    }
}

