import { Component, Input } from '@angular/core';
import { CCITREventoDTO } from '../model/ccitr-evento-dto';

@Component({
  selector: 'via-detalhe-consulta-eventos',
  templateUrl: './detalhe-consulta-eventos.component.html',
  styleUrls: ['../consulta-eventos.component.css']
})
export class DetalheCCITRConsultaEventosComponent {

  @Input() item: CCITREventoDTO;
}
