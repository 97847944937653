import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbDatePickerModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule,
  SuiteRfbOutputMaskModule,
  SuiteRfbPaginationModule, 
  SuiteRfbPopoverModule, 
  SuiteRfbTableModule, 
  SuiteRfbToggleModule  
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule, 
    SuiteRfbPopoverModule, 
    SuiteRfbTableModule, 
    SuiteRfbToggleModule  
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule,
    SuiteRfbPaginationModule, 
    SuiteRfbPopoverModule, 
    SuiteRfbTableModule, 
    SuiteRfbToggleModule    
  ]
})
export class CobrancaSuiteRfbModule { }
