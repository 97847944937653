import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentoPessoaFisica } from '../../models/documento-pessoa-fisica';

@Component({
  templateUrl: './documento-visualizacao.component.html',
  styleUrls: ['./documento-visualizacao.component.css']
})
export class DocumentoVisualizacaoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() documento: DocumentoPessoaFisica;

  @ViewChild('iframe') iframe: ElementRef;

  bsModalRef: BsModalRef;

  private url: string;

  ngOnInit(): void {
    this.url = this.gerarUrlArquivo();
  }

  ngAfterViewInit(): void {
    this.iframe.nativeElement.src = this.url;
  }

  ngOnDestroy(): void {
    window.URL.revokeObjectURL(this.url);
  }

  onFecharVisualizacao(): void {
    this.bsModalRef?.hide();
  }

  private gerarUrlArquivo(): string {
    const base64Bytes = window.atob(this.documento.documentoBase64).split('').map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(base64Bytes);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    return window.URL.createObjectURL(file);
  }
}
