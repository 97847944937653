import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtilService } from '../util/number-util.service';

// formato 99999-999
const mascaraCEP: string = '$1-$2';
const formatoCEP: RegExp = /^([0-9]{5})([0-9]{3})$/;
const tamanho = 8;

@Pipe({
  name: 'cepPipe'
})
export class CEPPipe implements PipeTransform {

  constructor(private numberUtils: NumberUtilService) {}

  public transform(cep: string | number): string {
    return this.formataCEP(cep);
  }

  /**
 * Formata um CEP. 
 * 
 * @param cep 
 * @returns o numero formatado. Se o número informado for maior do que tamanho especificado, retorna o proprio valor informado como string sem formatar.
 */
  private formataCEP(cep: string | number): string {
    if (cep) {
      cep = '' + cep;
      if (this.numberUtils.onlyDigits(cep)) {
        if (cep.length <= tamanho) {
          cep = this.numberUtils.leftZeroes(cep, tamanho);
          return cep.replace(formatoCEP, mascaraCEP);
        }
      }
      return cep;
    }
    return '';
  }

}
