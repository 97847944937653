import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { Pagamento } from '../../models/pagamento';

@Component({
  selector: 'via-ccpf-debitos-pagamento',
  templateUrl: 'debitos-pagamento.component.html',
  styleUrls: ['debitos-pagamento.component.css']
})
export class CcpfDebitosPagamentoComponent implements OnInit {

  @Input() pagamento: Pagamento;
  @Input() cpfContribuinte: string;
  @Input() atendimento: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  semDebito: boolean = false;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  ngOnInit(): void {
    this.semDebito = isEmpty(this.pagamento.debitosAlocados);
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}
