<suite-rfb-collapse title="Cadeia NI" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <ng-container *ngIf="dadosResumidosDaCadeiaNiAgrupadosPorSituacao; else loadingOrError;">
    <ng-container *ngFor="let grupo of dadosResumidosDaCadeiaNiAgrupadosPorSituacao | keyvalue">
      <div class="grupo-situacao-ni">
        <label class="grupo-situacao-ni-titulo">
          {{ grupo.key }}
        </label>
        <div class="container-fluid container-opcoes grupo-situacao-ni-conteudo">
          <ng-container *ngFor="let dadosResumidos of grupo.value">
            <via-consulta-dados-basicos-pf
              [cpf]="dadosResumidos.cpf"
              [collapsed]="true"
              [isCadeiaNi]="true">
            </via-consulta-dados-basicos-pf>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;">
    Ocorreu um erro...
  </div>

  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
