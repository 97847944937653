import { Injectable } from '@angular/core';
import { ChronoUnit, LocalDate } from '@js-joda/core';

@Injectable(
    { providedIn: 'root' }
)
export class DateUtilsService {

    /**
     * Calcula a diferença de dias entre a data informada e a data corrente.
     *
     * @param dataInicial
     * @return a diferença de dias (pode ser negativa se a data informada for posterior a data atual)
     */
    public diasDesde(dataInicial: LocalDate) {
        return ChronoUnit.DAYS.between(dataInicial, LocalDate.now());
    }
}