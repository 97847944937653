import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { OpcaoSimplesDTO } from '../model/info-cadastral-pj';

@Component({
    selector: 'via-info-cadastrais-pj-table',
    templateUrl: './info-cadastrais-pj-table.component.html',
    styleUrls: ['./info-cadastrais-pj-table.component.css']
})
export class InfoCadastraisPJTableComponent {
    @Input() titulo: string;
    @Input() opcoes: OpcaoSimplesDTO[];

    pageSize: number = 10;
    currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
