import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultaDadosBasicosPjComponent } from './consulta-dados-basicos-pj.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedSuiteRfbModule } from 'src/app/shared/shared-suite-rfb.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    ConsultaDadosBasicosPjComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedSuiteRfbModule,
    PipesModule,
  ],
  exports: [
    ConsultaDadosBasicosPjComponent,
  ]
})
export class ConsultaDadosBasicosPJModule { }
