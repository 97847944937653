import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SumarioAnual } from './model/sumario-anual';

@Injectable()
export class DirfService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterRetencoes(cpf: string): Observable<SumarioAnual[]> {
    const url = `${this.baseUrl}/dirf/${cpf}/retencoes`;
    return this.http.get<SumarioAnual[]>(url);
  }

  public getUrlPortalDirf(): string {
    return `${environment.urlPortalDirf}`;
  }
}
