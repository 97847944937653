import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { CnpjsVinculadosDTO } from '../model/cnpjs-vinculados';

@Component({
  selector: 'via-cnpjs-vinculados-table',
  templateUrl: 'cnpjs-vinculados-table.component.html',
  styleUrls: ['cnpjs-vinculados-table.component.css']
})
export class CnpjsVinculadosTableComponent {

  @Input() cnpjsVinculados: CnpjsVinculadosDTO[];

  pageSize: number = 20;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

}