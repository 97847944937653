import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { LiberacaoCndComponent, LiberacaoCndPFComponent, LiberacaoCndPJComponent } from './liberacao-cnd.component';
import { LiberacaoCndService } from './liberacao-cnd.service';

@NgModule({
  declarations: [
    LiberacaoCndPJComponent,
    LiberacaoCndPFComponent,
    LiberacaoCndComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SitfisPipesModule,
    SuiteRfbCollapseModule,
    SuiteRfbToggleModule,
  ],
  providers: [
    LiberacaoCndService
  ],
  entryComponents: [
    LiberacaoCndPJComponent,
    LiberacaoCndPFComponent,
    LiberacaoCndComponent,
  ]
})
export class LiberacaoCndModule { }
