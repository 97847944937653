<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Processo</th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center">Situação</th>
        <th class="text-center" *ngIf="!exigibilidadeSuspensa">Parcelas em Atraso</th>
        <th class="text-center" *ngIf="exigibilidadeSuspensa">Validade da Medida Judicial</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parcelamento of parcelamentos">
        <tr class="vertical-align-middle">
          <td class="text-center text-nowrap">
            {{ parcelamento.numeroProcesso | formatProcessoPipe:parcelamento.inTamanhoProcesso | vazio }}
          </td>
          <td class="text-center">
            <via-codigo-receita [codigo]="parcelamento.codigoReceita"></via-codigo-receita>
          </td>
          <td class="text-left">{{ parcelamento.situacao }}</td>
          <td class="text-center" *ngIf="!exigibilidadeSuspensa">{{ parcelamento.quantidadePrestacoesAtraso }}</td>
          <td class="text-center" *ngIf="exigibilidadeSuspensa">{{ parcelamento.dataValidadeAnaliseMedidaJudicial }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
