<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!processos && !initError; else tabelaProcessos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaProcessos>
  <via-processo-profisc-table
    [ni]="ni"
    [processos]="processos"
    [parametros]="parametros"
    [isPessoaJuridica]="isPessoaJuridica"
    [isPessoaFisica]="isPessoaFisica"
    [isAtivos]="isAtivos"
    [isSuspensos]="isSuspensos">
  </via-processo-profisc-table>
</ng-template>
