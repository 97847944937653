<div class="modal-container">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <ng-container *ngIf="detalheProcesso; else loadingOrError;">
      <div class="container-fluid container-opcoes">
        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Nome do Interessado
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.nomeInteressado | vazio}}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Orgão de cadastramento
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.orgaoCadastramento | vazio}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Observação
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.observacao | vazio}}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Procedência
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.procedencia | vazio}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Tipo
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.tipo | vazio}}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              PROFISC
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.profisc | vazio}}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              SIEF
            </label>
            <p class="form-control-static dado-formulario">
              {{detalheProcesso.sief | vazio}}
            </p>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center min-width">Data Última Movimentação</th>
              <th class="text-center min-width">Data Movimentação</th>
              <th class="text-center min-width">Sequência</th>
              <th class="text-center min-width">RM</th>
              <th class="text-center">Órgão de Origem</th>
              <th class="text-center">Órgão de Destino</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let movimentacao of detalheProcesso.movimentacoes | currentPage: currentPage;"
              class="vertical-align-middle">
              <tr>
                <td class="text-center">{{movimentacao.dataUltimaMovimentacao | vazio | outputMask: '99/99/9999'}}</td>
                <td class="text-center">{{movimentacao.dataMovimentacao | formatDataPipe | vazio}}</td>
                <td class="text-left">{{movimentacao.sequencia | vazio}}</td>
                <td class="text-left">{{movimentacao.rm | vazio}}</td>
                <td class="text-left">{{movimentacao.orgaoOrigem | vazio}}</td>
                <td class="text-left">{{movimentacao.orgaoDestino | vazio}}</td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="6">
                <via-pagination [itemAmount]="detalheProcesso.movimentacoes.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
