import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OmissosComponent } from './components/omissos.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OmissosSuiteRfbModule } from './omissos-suite-rfb.module';
import { OmissosService } from './services/omissos.service';
import { OmissosTableComponent } from './components/table/omissos-table.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [
    OmissosComponent,
    OmissosTableComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    OmissosSuiteRfbModule
  ],
  exports: [
    OmissosComponent
  ],
  providers: [
    OmissosService
  ]
})
export class OmissosModule { }
