import { Pipe, PipeTransform } from '@angular/core';
//import { Lote } from '../../model/lote';

@Pipe({
  name: 'filterByAttributePipe'
})
export class FilterByAttributePipe implements PipeTransform {
  transform(listaQq: any[], conteudoAtributo: string, nomeAtributo: string): any[] {
    // cria uma nova instancia de array (_tmp) para que os pipes puros detectem a mudanca no array
    // ver: https://angular.io/guide/pipes#pure-and-impure-pipes


    //TODO: estudar para fazer uma função que vá pegando os atributos encadeados pelos pontos, para o caso
    // de filtrar por atributos em mais níveis do lote. Algo do tipo: ver se tem "." no nomeAtributo e ir encadeando
    // if(nomeAtributo === "sitLote.nmSit"){
    //   return (!listaQq) ? undefined : (!conteudoAtributo) ? listaQq : [].concat(listaQq.filter(lote => {
    //     return lote["sitLote"]["nmSit"].toLowerCase().startsWith(conteudoAtributo.toLowerCase());
    //   }));
    // }

    return (!listaQq) ? undefined : (!conteudoAtributo) ? listaQq : [].concat(listaQq.filter(itemLista => {
      return itemLista[nomeAtributo].toLowerCase().startsWith(conteudoAtributo.toLowerCase());
    }));


  }
}
