import { Component } from "@angular/core";
import { IDynamicPanel } from "src/app/shared/dynamic-panel/panel-interface";
import { FormatCpfPipe } from "src/app/shared/pipes/formata-cpf.pipe";
import { LogService } from "src/app/shared/services/log.service";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { TituloEleitor } from "./model/titulo-eleitor";
import { TituloEleitorService } from "./titulo-eleitor.service";

@Component({
  selector: 'via-titulo-eleitor',
  templateUrl: './titulo-eleitor.component.html',
  styleUrls: ['./titulo-eleitor.component.css']
})
export class TituloEleitorComponent implements IDynamicPanel {

  initError: Error;
  titulo: string;
  cpf: string;

  tituloEleitor: TituloEleitor;

  constructor(
    private tituloEleitorService: TituloEleitorService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('TituloEleitorComponent#constructor');
  }

  init(cpf: string) {

    this.cpf = cpf;
    this.titulo = `Dados Título de Eleitor - CPF: ${FormatCpfPipe.formatCPF(cpf)}`;

    this.tituloEleitorService.obterDadosTituloEleitor(cpf).subscribe(
      (resp) => {
        this.tituloEleitor = resp;
        this.logService.logAjaxReturn('TituloEleitorComponent', 'obterDadosTituloEleitor');
      },
      (err) => {
        this.initError = err;
        this.logService.logAjaxError('TituloEleitorComponent', 'obterDadosTituloEleitor', err);
        this.messageService.showErrorException('Ocorreu um erro ao obter os dados do título de eleitor', err);
      }
    );
  }
}
