import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheOperacaoSucessao } from '../../models/detalhe-operacao-sucessao';

@Component({
  selector: 'via-operacao-sucessao-table',
  templateUrl: './operacao-sucessao-table.component.html',
  styleUrls: ['./operacao-sucessao-table.component.css'],
  providers: [ScopedMessageService]
})
export class OperacaoSucessaoTableComponent implements OnInit {

  @Input() titulo: string;
  @Input() operacoesSucessao: DetalheOperacaoSucessao[];

  scopeId: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;

    if (isEmpty(this.operacoesSucessao)) {
      this.messageService.showInfo('Nenhum resultado encontrado');
    }
  }
}
