<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitos && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaDebitos>
  <via-debito-sief-table
    [debitos]="debitos"
    [isPessoaJuridica]="isPessoaJuridica"
    [isPessoaFisica]="isPessoaFisica"
    [isAtivos]="isAtivos"
    [isSuspensos]="isSuspensos"
    (emitirDarf)="emitirDarf($event)"
    (emitirDas)="emitirDas($event)">
  </via-debito-sief-table>
</ng-template>
