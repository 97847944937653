import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ContentChildren, QueryList, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { ElementRef, ViewChild} from '@angular/core';

import { ViaTabComponent } from '../tab/tab.component';

@Component({
  selector: 'via-tab-set',
  templateUrl: './tab-set.component.html',
  styleUrls: ['./tab-set.component.scss']
})

export class ViaTabSetComponent implements AfterViewInit{

  @ContentChildren(ViaTabComponent) tabs: QueryList<ViaTabComponent>;

  @ViewChild('viaTab')
  viaTab: ElementRef;
  @ViewChild('tabScrollArea')
  tabScrollArea: ElementRef;
  @ViewChild('tabArea')
  tabArea: ElementRef;

  @Output() tabCloseClick: EventEmitter<ViaTabComponent> = new EventEmitter();
  @Output() tabMoveClick: EventEmitter<{previous, current}> = new EventEmitter();

  habilitaAnterior: boolean = false;
  habilitaPosterior: boolean = false;
  
  constructor() { }

  public ngAfterViewInit(): void {
  
    const targetNode = this.tabArea.nativeElement;
    const config = { childList: true, subtree: true };
    const $this = this;
    const callback = function(mutationsList, observer) {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
              $this.selecionaAtiva($this);
              return;
            }
        }
    };
  
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.atualizarPaginacao(this);
  }

  private atualizarPaginacao($this): void {
    const larguraCabecalho = $this.viaTab.nativeElement.clientWidth;
    const larguraTotalAbas = $this.tabArea.nativeElement.clientWidth;
    const posicaoInicialScroll = $this.tabScrollArea.nativeElement.scrollLeft;
    const exibePaginacao = larguraCabecalho < larguraTotalAbas;
    $this.habilitaAnterior = exibePaginacao && posicaoInicialScroll > 0;
    $this.habilitaPosterior = exibePaginacao && posicaoInicialScroll < 
      ( larguraTotalAbas - larguraCabecalho + ($this.habilitaAnterior ? 35 : 0));
  }

  public selecionaAtiva($this: any = this): void {
    // timeout necessário para atualizar o dom
    setTimeout(() => {
      const tabAtiva = $this.tabs.toArray().map(r=>r.active).indexOf(true);
      if (tabAtiva > -1) {
        const larguraCabecalho = $this.viaTab.nativeElement.clientWidth;
        const larguraTotalAbas = $this.tabArea.nativeElement.clientWidth;
        const exibePaginacao = larguraCabecalho < larguraTotalAbas;

        if (!exibePaginacao) {
          this.habilitaAnterior = false;
          this.habilitaPosterior = false;
          return;
        }

        let posicaoInicialScroll = $this.tabScrollArea.nativeElement.scrollLeft;
        const tabAtivaInicio = tabAtiva * 127;
        const tabAtivaFinal = tabAtivaInicio + 127;

        // se for anterior à página é só colocar como primeiro
        if (tabAtivaInicio < posicaoInicialScroll) {
          posicaoInicialScroll = tabAtivaInicio;
        } else {
          // se estiver posterior à pagina, coloca como último visivel
          const larguraBotoesScroll = (tabAtiva == $this.tabs.length -1) ? 35 : 70;
          if(tabAtivaFinal > posicaoInicialScroll + larguraCabecalho - larguraBotoesScroll) {
            posicaoInicialScroll = tabAtivaFinal - larguraCabecalho + larguraBotoesScroll;
          }
        }

        $this.tabScrollArea.nativeElement.scrollLeft = posicaoInicialScroll;
        $this.habilitaAnterior = exibePaginacao && posicaoInicialScroll > 0;
        $this.habilitaPosterior = exibePaginacao && posicaoInicialScroll < 
          ( larguraTotalAbas - larguraCabecalho + ($this.habilitaAnterior ? 35 : 0));
      }
    },100);
  }

  public select(tab: ViaTabComponent): void {
    if (!tab.disabled) {
      this.tabs.forEach(t => {
        if (t === tab) {
          t.select();
        } else {
          t.unselect();
        }
      });
    }
    this.selecionaAtiva(this);
  }

  public selectIndex(index: number): void {
    const tab: ViaTabComponent = this.tabs.find((t: ViaTabComponent, i: number) => index === i);
    this.select(tab);
  }

  public onClose(tab: ViaTabComponent): void {
    this.tabCloseClick.emit(tab);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    this.tabMoveClick.emit({previous: event.previousIndex, current:event.currentIndex});
  }

  public scrollLeft(): void {
    if(this.habilitaAnterior){
      this.tabScrollArea.nativeElement.scrollLeft -=127;
      this.atualizarPaginacao(this);
    }
  }

  public scrollRight(): void {
    if(this.habilitaPosterior){
      this.tabScrollArea.nativeElement.scrollLeft +=127;
      this.atualizarPaginacao(this);
    }
  }
}
