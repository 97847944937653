import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../../services/ccpf.service';
import { CCPFAlocacaoDebitoComponent } from '../alocacao-debito/alocacao-debito.component';
import { Pagamento } from '../models/pagamento';
import { ParametrosFiltroPagamentoCcpf } from '../models/parametros-pesquisa';

@Component({
  selector: 'via-ccpf-pagamentos',
  templateUrl: './consulta-pagamentos.component.html',
  styleUrls: ['./consulta-pagamentos.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaPagamentosCcpfComponent implements IDynamicPanel, OnInit, OnChanges {

  @Input() parametros: ParametrosFiltroPagamentoCcpf;
  @Input() parentScopeId: string;
  @Input() atendimento: boolean;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pagamentos$: Observable<Pagamento[]>;

  isModal = false;

  initError: Error;
  scopeId: string;
  titulo: string;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  cpf: string;

  constructor(
    private messageService: ScopedMessageService,
    private ccpfService: CCPFService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  public init(ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametrosFiltroPagamentoCcpf;
    this.cpf = ni;
    this.isModal = this.parametros.isModal;
  }

  ngOnInit(): void {
    this.titulo = `CCPF - Consultar Pagamentos - CPF: ${FormatCpfPipe.formatCPF(this.parametros.cpfContribuinte)}`;
    this.scopeId = this.messageService.scopeId;
    this.recarregar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.titulo) {
      this.recarregar();
    }
  }

  public recarregar(): void {
    this.initError = null;
    this.obterPagamentos();
  }

  private obterPagamentos(): void {
    this.pagamentos$ = this.ccpfService.consultarPagamentos(this.parametros)
      .pipe(
        tap(response => {
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum pagamento encontrado nas datas informadas.');
          }
          this.currentPage.last = response.length > this.pageSize ? this.pageSize : response.length;
        }),
        catchError(error => {
          this.initError = error;
          this.onError.emit();
          this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', error);
          return of<Pagamento[]>([]);
        })
      );
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  onAlocarDebito(pagamento: Pagamento): void {
    const feature = {
      id: `CCPF_Alocar_Debito_pagamento_${pagamento.numeroPagamento}`,
      title: 'CCPF - Alocar Débito',
      class: CCPFAlocacaoDebitoComponent,
      parametros: {
        pagamento: pagamento,
        parametrosPesquisa: this.parametros
      }
    } as FeatureAction;

    if (this.isModal) {
      this.dynamicModalService.abrirModal(feature);
      return;
    }

    feature.visible = true;
    feature.rolesAllowed = [];
    feature.tipoMenu = TipoMenu.ATENDIMENTO;

    this.menuFeatureActionsService.activate(feature);
  }
}
