import { Component, Input, OnInit } from '@angular/core';
import { DadosBasicosPjDTO } from 'src/app/cnpj/model/dados-basicos-pj-dto';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { OperacaoSucessao } from '../models/operacao-sucessao';
import { OperacaoSucessaoService } from '../services/operacao-sucessao.service';

@Component({
  selector: 'via-operacao-sucessao',
  templateUrl: './operacao-sucessao.component.html',
  styleUrls: ['./operacao-sucessao.component.css'],
  providers: [ScopedMessageService]
})
export class OperacaoSucessaoComponent implements OnInit {

  @Input() cnpj: string;
  @Input() dadosEmpresa: DadosBasicosPjDTO;

  titulo = 'Operações de Sucessão';
  operacaoSucessao: OperacaoSucessao;

  scopeId: string;

  initError: Error;

  private executouObterDados = false;

  constructor(
    private operacaoSucessaoService: OperacaoSucessaoService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterOperacoesSucessao();
    }
  }

  private obterOperacoesSucessao(): void {
    this.executouObterDados = true;

    this.operacaoSucessaoService.obterOperacoesSucessao(this.cnpj)
      .subscribe(
        operacaoSucessao => this.operacaoSucessao = operacaoSucessao,
        error => {
          this.messageService.showErrorException('Ocorreu um erro ao obter os dados de operação de sucessão', error);
          this.initError = error;
        });
  }
}
