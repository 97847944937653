<suite-rfb-collapse title="DIRF" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <div class="table-responsive" *ngIf="retencoes?.length > 0; else loadingOrError;">
    <table suiteRfbTable>
      <thead>
        <tr>
          <th class="text-center min-width">
            <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
            </suite-rfb-toggle>
          </th>
          <th class="text-center min-width">CPF/CNPJ</th>
          <th class="text-center min-width">Ano Retenção</th>
          <th class="text-center min-width">Receita</th>
          <th class="text-center">Rendimentos Tributáveis</th>
          <th class="text-center">Imposto Retido</th>
          <th class="text-center">Rendimentos Isentos</th>
          <th class="text-center">Deduções</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let retencao of retencoes | currentPage: currentPage;">
          <tr class="vertical-align-middle">
            <td>
              <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
            </td>
            <td class="text-center text-nowrap" *ngIf="retencao.tipoNiDeclarante == 1">
              <ni-com-link [ni]="retencao.niDeclarante" tipoNi="CPF"></ni-com-link>
            </td>
            <td class="text-center text-nowrap" *ngIf="retencao.tipoNiDeclarante == 2">
              <ni-com-link [ni]="retencao.niDeclarante" tipoNi="CNPJ"></ni-com-link>
            </td>
            <td class="text-center">
              {{ retencao.anoCalendario }}
            </td>
            <td class="text-center">
              <via-codigo-receita [codigo]="retencao.codigoReceita"></via-codigo-receita>
            </td>
            <td class="text-right">
              {{ retencao | totalAnualRendimentosTributaveis | formatCurrency }}
            </td>
            <td class="text-right">
              {{ retencao | totalAnualImpostoRetido | formatCurrency }}
            </td>
            <td class="text-right">
              {{ retencao | totalAnualRendimentoIsento | formatCurrency }}
            </td>
            <td class="text-right">
              {{ retencao | totalAnualDeducoes | formatCurrency }}
            </td>
          </tr>
          <ng-container *ngIf="rowToggle.show">
            <tr></tr>
            <tr>
              <td colspan="8">
                <div class="dirf-beneficiario">
                  <label class="dirf-beneficiario-titulo">
                    DIRF Beneficiário
                  </label>
                  <div class="dirf-beneficiario-conteudo">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="label-formulario control-label">
                          Nome do Beneficiário na DIRF
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ retencao.nomeBeneficiario }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label class="label-formulario control-label">
                          Nome do Beneficiário no CPF
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ nomeBeneficiario }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label class="label-formulario control-label">
                          Declarante
                        </label>
                        <p class="form-control-static dado-formulario" *ngIf="retencao.tipoNiDeclarante == 1">
                          <ni-com-link [ni]="retencao.niDeclarante" tipoNi="CPF" [nome]="retencao.nomeDeclarante"></ni-com-link>
                        </p>
                        <p class="form-control-static dado-formulario" *ngIf="retencao.tipoNiDeclarante == 2">
                          <ni-com-link [ni]="retencao.niDeclarante" tipoNi="CNPJ" [nome]="retencao.nomeDeclarante"></ni-com-link>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label class="label-formulario control-label">
                          Tributo
                        </label>
                        <p class="form-control-static dado-formulario">
                          <via-codigo-receita [codigo]="retencao.codigoReceita" [nome]="retencao.nomeTributo"></via-codigo-receita>
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12" class="detalhes-dirf">
                        <label class="detalhes-dirf-titulo">
                          Detalhes da DIRF
                        </label>
                        <div class="detalhes-dirf-conteudo">
                          <div class="grid-detalhes-dirf">
                            <div class="grid-item-text-align-center">
                              Mês
                            </div>
                            <div class="grid-item-text-align-center">
                              Rendimentos Tributáveis
                            </div>
                            <div class="grid-item-text-align-center">
                              Imposto Retido
                            </div>
                            <div class="grid-item-text-align-center">
                              Valores Mensais Isentos
                            </div>
                            <div class="grid-item-text-align-center">
                              Deduções
                            </div>
                            <ng-container *ngFor="let ocorrencia of retencao.ocorrencias; last as isLast">
                              <ng-template #linhaOcorrencia>
                                <div class="grid-item-text-align-start">
                                  {{ ocorrencia.numeroOcorrencia | nomeMes }}
                                </div>
                                <div class="grid-item-text-align-end">
                                  {{ ocorrencia.rendimentoTributavel | formatCurrency }}
                                </div>
                                <div class="grid-item-text-align-end">
                                  {{ ocorrencia.impostoRetido | formatCurrency }}
                                </div>
                                <div class="grid-item-text-align-end">
                                  {{ ocorrencia.rendimentoIsento | formatCurrency }}
                                </div>
                                <div class="grid-item-text-align-end">
                                  {{ ocorrencia | totalMensalDeducoes | formatCurrency }}
                                </div>
                              </ng-template>
                              <div *ngIf="ocorrencia.numeroOcorrencia < 13; then linhaOcorrencia"></div>
                              <div *ngIf="isLast" class="grid-item-text-align-start">
                                Total
                              </div>
                              <div *ngIf="isLast" class="grid-item-text-align-end">
                                {{ retencao | totalAnualRendimentosTributaveis | formatCurrency }}
                              </div>
                              <div *ngIf="isLast" class="grid-item-text-align-end">
                                {{ retencao | totalAnualImpostoRetido | formatCurrency }}
                              </div>
                              <div *ngIf="isLast" class="grid-item-text-align-end">
                                {{ retencao | totalAnualRendimentoIsento | formatCurrency }}
                              </div>
                              <div *ngIf="isLast" class="grid-item-text-align-end">
                                {{ retencao | totalAnualDeducoes | formatCurrency }}
                              </div>
                              <div *ngIf="ocorrencia.numeroOcorrencia == 13; then linhaOcorrencia"></div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12" class="resumo-deducoes">
                        <label class="resumo-deducoes-titulo">
                          Resumo das Deduções
                        </label>
                        <div class="resumo-deducoes-conteudo">
                          <div class="grid-resumo-deducoes">
                            <div class="grid-item-text-align-center">
                              Previdência Oficial
                            </div>
                            <div class="grid-item-text-align-center">
                              Pensão Alimentícia
                            </div>
                            <div class="grid-item-text-align-center">
                              Dependentes
                            </div>
                            <div class="grid-item-text-align-center">
                              Previdência Privada/FAPI
                            </div>
                            <div class="grid-item-text-align-end">
                              {{ retencao | totalAnualDeducaoTributavelPrevidenciaOficial | formatCurrency }}
                            </div>
                            <div class="grid-item-text-align-end">
                              {{ retencao | totalAnualDeducaoTributavelPensaoAlimenticia | formatCurrency }}
                            </div>
                            <div class="grid-item-text-align-end">
                              {{ retencao | totalAnualDeducaoTributavelDependente | formatCurrency }}
                            </div>
                            <div class="grid-item-text-align-end">
                              {{ retencao | totalAnualDeducaoTributavelPrevidenciaPrivada | formatCurrency }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12" class="valores-anuais-isentos">
                        <label class="valores-anuais-isentos-titulo">
                          Valores Anuais Isentos
                        </label>
                        <div class="valores-anuais-isentos-conteudo">
                          <div class="grid-valores-anuais-isentos">
                            <div class="grid-item-text-align-start">
                              Lucros e dividendos pagos a partir de 1996, valores pagos a titular ou
                              sócio de empresa de pequeno porte, exceto pró-labore e aluguéis, e outros
                            </div>
                            <div class="grid-item-text-align-end">
                              {{ retencao.rendimentoIsentoAnual | formatCurrency }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <button type="button" class="btn btn-default"
                          (click)="_window.open(urlPortalDirf(retencao), '_blank');">
                          Portal da DIRF
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="8">
            <via-pagination [itemAmount]="retencoes.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
