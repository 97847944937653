<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center">Exercícios Ausentes</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ausencia of ausenciasDeclaracao | currentPage:currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-popover-button [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button
                  class="btn btn-default"
                  type="button"
                  (click)="onDetalharDeclaracaoAusenteDirf(ausencia)">
                  Detalhar
                </button>
              </div>
            </ng-template>
          </td>
          <td class="text-center">
            {{ ausencia.periodoApuracaoOmissao[0] }}
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="2">
          <via-pagination [itemAmount]="ausenciasDeclaracao.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
