<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">NI</th>
        <th class="text-center">Situação</th>
        <th *ngIf="isAtivos" class="text-center">Parcelas em Atraso</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parcelamento of parcelamentos | currentPage:currentPage;" class="vertical-align-middle">
        <td class="text-center">
          <ni-com-link [ni]="parcelamento.ni"></ni-com-link>
        </td>
        <td class="text-left">{{ parcelamento.situacao }}</td>
        <td *ngIf="isAtivos" class="text-center">{{ parcelamento.quantidadePrestacoesAtraso }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="3">
          <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
