<suite-rfb-collapse [title]="titulo">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <table suiteRfbTable *ngIf="pagamentos && pagamentos.length > 0">
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Data Arrec.</th>
        <th class="text-center min-width">Bco/Ag.</th>
        <th class="text-center min-width">Data Venc.</th>
        <th class="text-center min-width">PA</th>
        <th class="text-center min-width">Valor Total</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let pagamento of pagamentos | currentPage: currentPage">
        <td class="text-center">
          <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>

          <ng-template #menuAcoes>
            <div class="btn-group-vertical" role="group">
              <button
                type="button"
                class="btn btn-default"
                (click)="onDetalharPagamento(pagamento); botaoAcoes.hide();">
                Detalhar
              </button>
            </div>
          </ng-template>
        </td>
        <td class="text-center text-nowrap">
          <via-codigo-receita [codigo]="pagamento.codigoReceita"></via-codigo-receita>
        </td>
        <td class="text-center text-nowrap">{{ pagamento.dataArrecadacao | date: 'dd/MM/yyyy' | vazio }}</td>
        <td class="text-center text-nowrap">{{ pagamento.banco | vazio }}/{{ pagamento.agencia | vazio }}</td>
        <td class="text-center text-nowrap">{{ pagamento.dataVencimento | date: 'dd/MM/yyyy' | vazio }}</td>
        <td class="text-center text-nowrap">{{ pagamento.periodoApuracao | date: 'dd/MM/yyyy' | vazio }}</td>
        <td class="text-right text-nowrap">{{ pagamento.valorTotal | formatCurrency | vazio }}</td>
        <td class="text-left text-nowrap">{{ pagamento.situacao | codigoDescricao | vazio }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="8">
          <via-pagination [itemAmount]="pagamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</suite-rfb-collapse>
