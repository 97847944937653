import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParcelamentoDivida } from '../model/parcelamento-divida';

@Component({
  selector: 'via-parcelamento-divida-table',
  templateUrl: 'parcelamento-divida-table.component.html',
  styleUrls: ['parcelamento-divida-table.component.css']
})
export class ParcelamentoDividaTableComponent {

  @Input() parcelamentos: ParcelamentoDivida[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
