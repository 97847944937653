<suite-rfb-collapse [title]="titulo">
  <div *ngIf="(parcelamentosObservable$ | async) as parcelamentos; else loadingOrError">
    <via-parcelamento-sispar-table 
      [parcelamentos]="parcelamentos" 
      (emitirDarf)="emitirDarf($event)">
    </via-parcelamento-sispar-table>
  </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>