<div class="table-responsive">
  <table suiteRfbTable>
    <caption class="caption-ccpf">Alocações</caption>
    <ng-container *ngIf="alocacoes?.length > 0; else nenhumaAlocacao;">
      <thead>
        <tr *ngIf="!atendimento; else templateHeaderAtendimento;">
          <th class="text-center min-width">Receita</th>
          <th class="text-center min-width">N° Débito</th>
          <th class="text-center min-width">COMP-CT</th>
          <th class="text-center min-width break-spaces">Data de Vencimento</th>
          <th class="text-center min-width break-spaces">Data de Alocação</th>
          <th class="text-center">Valor Original</th>
          <th class="text-center">Valor Alocado</th>
          <th class="text-center min-width">Critério</th>
          <th class="text-center">Saldo Devedor</th>
          <th class="text-center">Situação</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let alocacao of alocacoes | currentPage: currentPage;">
          <tr *ngIf="!atendimento; else templateAlocacaoAtendimento;" class="vertical-align-middle">
            <td class="text-center">
              <via-codigo-receita [codigo]="alocacao.receita"></via-codigo-receita>
            </td>
            <td class="text-center">
              {{ alocacao.numeroDebito | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.compCt | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.dataVencimento | formatDataPipe | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.dataProrrogacao | formatDataPipe | vazio }}
            </td>
            <td class="text-right">
              {{ alocacao.valorOriginal | formatCurrency | vazio }}
            </td>
            <td class="text-right">
              {{ alocacao.valorAlocado | formatCurrency | vazio }}
            </td>
            <td class="text-center">
              {{ alocacao.criterio | vazio }}
            </td>
            <td class="text-right">
              {{ alocacao.saldoComponente | formatCurrency | vazio }}
            </td>
            <td class="text-left">
              {{ alocacao.situacao | vazio }}
            </td>
          </tr>

          <ng-template #templateAlocacaoAtendimento>
            <ng-container
              *ngTemplateOutlet="templateDetalheAlocacaoAtendimento; context: { alocacao: alocacao }">
            </ng-container>
          </ng-template>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="10">
            <via-pagination [itemAmount]="alocacoes.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </ng-container>
  </table>
</div>

<ng-template #nenhumaAlocacao>
  <tr class="vertical-align-middle">
    <td class="text-center" colspan="10">
      Não há alocações para este pagamento.
    </td>
  </tr>
</ng-template>

<ng-template #templateHeaderAtendimento>
  <tr>
    <th class="text-center min-width">
      <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
      </suite-rfb-toggle>
    </th>
    <th class="text-center min-width">Receita</th>
    <th class="text-center min-width">N° Débito</th>
    <th class="text-center min-width">COMP-CT</th>
    <th class="text-center min-width break-spaces">Data de Vencimento</th>
    <th class="text-center min-width break-spaces">Data de Alocação</th>
    <th class="text-center">Valor Original</th>
    <th class="text-center">Valor Alocado</th>
    <th class="text-center">Saldo Devedor</th>
  </tr>
</ng-template>

<ng-template #templateDetalheAlocacaoAtendimento let-alocacao="alocacao">
  <tr class="vertical-align-middle">
    <td class="text-center">
      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
    </td>
    <td class="text-center">
      <via-codigo-receita [codigo]="alocacao.receita"></via-codigo-receita>
    </td>
    <td class="text-center">
      {{ alocacao.numeroDebito | vazio }}
    </td>
    <td class="text-center">
      {{ alocacao.compCt | vazio }}
    </td>
    <td class="text-center">
      {{ alocacao.dataVencimento | formatDataPipe | vazio }}
    </td>
    <td class="text-center">
      {{ alocacao.dataProrrogacao | formatDataPipe | vazio }}
    </td>
    <td class="text-right">
      {{ alocacao.valorOriginal | formatCurrency | vazio }}
    </td>
    <td class="text-right">
      {{ alocacao.valorAlocado | formatCurrency | vazio }}
    </td>
    <td class="text-right">
      {{ alocacao.saldoComponente | formatCurrency | vazio }}
    </td>
  </tr>

  <ng-container *ngIf="rowToggle.show">
    <tr></tr>
    <tr>
      <td colspan="10">
        <div class="contorno">
          <div class="row">
            <div class="col-md-4">
              <label class="label-formulario control-label">Critério</label>
              <p class="form-control-static dado-formulario">
                {{ alocacao.criterio | vazio }}
              </p>
            </div>
            <div class="col-md-4">
              <label class="label-formulario control-label">Situação</label>
              <p class="form-control-static dado-formulario">
                {{ alocacao.situacao | vazio }}
              </p>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>
