<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <div class="container-fluid form-height">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <label class="control-label faixa-valor-label">Período de arrecadação:</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-data-arrecadacao">
              <div class="form-inline">
                <label class="control-label data-arrecadacao-label-separador">De</label>
                <div class="form-group form-group-data-arrecadacao">
                  <suite-rfb-date-picker
                    direction="right"
                    [outsideClick]="true"
                    adaptivePosition="true"
                    [formControlName]="arrecadacaoDeName"
                    >
                  </suite-rfb-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-data-arrecadacao">
              <div class="form-inline">
                <label class="control-label data-arrecadacao-label-separador">a</label>
                <div class="form-group form-group-data-arrecadacao">
                  <suite-rfb-date-picker
                    direction="right"
                    [outsideClick]="true"
                    adaptivePosition="true"
                    [formControlName]="arrecadacaoAteName"
                    >
                  </suite-rfb-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <button class="btn btn-default" type="reset" (click)="onReset()" [disabled]="!formGroup.dirty">Limpar</button>
            <button class="btn btn-primary" type="submit" [disabled]="!formGroup.valid">Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>
<via-ccpf-pagamentos
  *ngIf="exibeResultado"
  [parametros]="parametrosFiltro"
  [atendimento]="true"
  [parentScopeId]="scopeId">
</via-ccpf-pagamentos>
