<div class="table-responsive">
  <table suiteRfbTable>
    <caption style="text-align:left; padding-left:10px;">{{ titulo }}</caption>
    <thead>
      <tr>
        <th class="text-center min-width" *ngIf="tipoDebito == TipoPendencia.COBRANCA">Ações</th>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Débito</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let debito of debitos | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center" *ngIf="tipoDebito == TipoPendencia.COBRANCA">
            <suite-rfb-popover-button
              #popoverAcoesDebito
              [template]="botoesAcaoDebito"
              [templateContext]="{ debito: debito }">
            </suite-rfb-popover-button>
            <ng-template #botoesAcaoDebito let-debito="debito">
              <div class="btn-group-vertical" role="group">
                <button
                  type="button"
                  class="btn btn-default"
                  *ngIf="permiteRegularizacao(debito)"
                  (click)="onRegularizarDebito(debito); popoverAcoesDebito.hide()">
                  Regularizar
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  *ngIf="permiteEmissaoDarf(debito)"
                  [disabled]="!debito.indExibeDarf"
                  (click)="onEmitirDarf(debito); popoverAcoesDebito.hide()">
                  Emitir DARF
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  *ngIf="permiteEmissaoDarfExercicio(debito)"
                  (click)="onEmitirDarfExercicio(debito); popoverAcoesDebito.hide()">
                  DARF Exercício
                </button>
              </div>
            </ng-template>
          </td>
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <via-codigo-receita [codigo]="debito.codigoReceita"></via-codigo-receita>
          </td>
          <td class="text-center">{{ debito.numeroDebito }}</td>
          <td class="text-left">{{ debito.situacaoDebito }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="5">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Exercício</label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.dataPaExercicio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Data Vencimento</label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.dataVencimento | date: 'shortDate' }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Valor Original</label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.valorOriginal | formatCurrency }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Saldo Devedor</label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.valorSaldoDevedor | formatCurrency }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Unidade Monetária</label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.unidadeMonetaria }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="5">
          <via-pagination [itemAmount]="debitos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
