import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ObrigatoriedadeDirf } from "../models/obrigatoriedade-dirf";

@Injectable()
export class ObrigatoriedadeDirfService {
  private readonly baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterObrigatoriedadesDIRF(cnpj: string, anoCalendario: string): Observable<ObrigatoriedadeDirf> {
    return this.httpClient.get<ObrigatoriedadeDirf>(`${this.baseUrl}/obrigatoriedades-dirf/${cnpj}?anoCalendario=${anoCalendario}`);
  }
}
