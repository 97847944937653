import { DadosConsolidadoDebitoCCITRDTO, ComponenteDadosConsolidadoDebitoCCITRDTO } from '../../../model/dados-consolidado-debito-ccitr';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'via-detalhe-componentes-dados-consolidar-ct',
  templateUrl: './detalhe-componentes-dados-consolidar-ct.component.html',
  styleUrls: ['../componentes-dados-consolidar-ct.component.css', '../../../../../base/ccitr.css']
})
export class DetalheCCITRComponentesConsolidarCTComponent {

  @Input() componente: ComponenteDadosConsolidadoDebitoCCITRDTO;



}
