import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SolicitacaoProtocoloDto, StatusAnaliseFiscalDTO } from 'src/app/sitfis/model/protocolo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProtocoloService {

  private baseUrl = environment.APIEndpoint;

  constructor(
    private http: HttpClient
  ) { }

  public recuperaProtocolo(solicitacao: SolicitacaoProtocoloDto): Observable<string> {
    const url = `${this.baseUrl}/situacoesfiscais/protocolos`;
    const options = {
        responseType: 'text' as const
    };
    return this.http.post(url, solicitacao, options);
  }

  public verificarProtocolo(numeroProtocolo: string): Observable<StatusAnaliseFiscalDTO> {
    const url = `${this.baseUrl}/situacoesfiscais/protocolos`;
    const options = {
        params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<StatusAnaliseFiscalDTO>(url, options);
  }
}
