<table width="100%" class="via-paginacao">
    <tr>
      <td colspan="2">
        Registros: {{page.last}} de {{ itemAmount }}
      </td>
      <td colspan="2" *ngIf="page.last < itemAmount"  style="text-align:center">
        <span (click)="carregarMais()" class="link">
          <span class="material-icons icon-18" style="transform: rotate(90deg)">
            navigate_next</span>
          Carregar mais registros</span>
      </td>
      <td colspan="2" *ngIf="page.last < itemAmount"  style="text-align:right">
        <span (click)="carregarTodos()" class="link">
          <span class="material-icons icon-18" style="transform: rotate(90deg)">
            last_page</span>
          Carregar todos os registros</span>
      </td>
    </tr>
  </table>