import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ProcessoProfiscDebito } from '../model/debito-profisc';

@Component({
  selector: 'via-debito-profisc-table',
  templateUrl: './debito-profisc-table.component.html',
  styleUrls: ['./debito-profisc-table.component.css']
})
export class DebitoProfiscTableComponent {

  @Input() ni: string;
  @Input() numeroProcesso: string;

  @Input() isAtivos: boolean;

  @Input() debitos: ProcessoProfiscDebito[];

  @Output() emitirDarf = new EventEmitter<ProcessoProfiscDebito>();

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  onEmitirDarf(debito: ProcessoProfiscDebito): void {
    this.emitirDarf.emit(debito);
  }
}
