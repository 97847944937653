import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoCadastraisSuiteRfbModule } from './info-cadastrais-pf-suite-rfb.module';
import { InfoCadastraisPFService } from './info-cadastrais-pf.service';
import { InfoCadastraisPFComponent } from './info-cadastrais-pf.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        InfoCadastraisPFComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        InfoCadastraisSuiteRfbModule
    ],
    providers: [
        InfoCadastraisPFService
    ],
    entryComponents: [
        InfoCadastraisPFComponent
    ]
})
export class InfoCadastraisPFModule { }