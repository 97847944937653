import { Injectable } from '@angular/core';
import { AllowedFeaturePipe, UserService } from '@serpro/ngx-suite-rfb';
import { Observable, Subject } from 'rxjs';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { ItensMenuAdicionais } from 'src/app/sitfis/model/itens-menu';
import { SituacaoFiscalDto } from 'src/app/sitfis/model/situacao-fiscal-dto';
import { OrigemPendenciaPessoaFisica } from 'src/app/sitfis/model/situacao-fiscal-pf-dto';
import { OrigemPendenciaPessoaJuridica } from 'src/app/sitfis/model/situacao-fiscal-pj-dto';
import { Vinculo } from 'src/app/sitfis/model/vinculos';
import { Feature, FeatureAction, TipoMenu } from '../model/feature';
import { LogService } from '../services/log.service';

export interface ActivateEvent {
  featureAction: FeatureAction;
  active: boolean;
}

@Injectable()
export class MenuFeatureActionsService {

  private featureActionEventSource = new Subject<ActivateEvent>();
  featureActionEvent$: Observable<ActivateEvent> = this.featureActionEventSource.asObservable();

  constructor(
    private logService: LogService,
    private userService: UserService,
    private allowedFeaturePipe: AllowedFeaturePipe) { }

  public activate(feature: FeatureAction): void {
    this.logService.logStep('MenuFeatureActionsService.activate', 'feature ativada', feature);
    this.featureActionEventSource.next({ featureAction: feature, active: true });
  }

  public deactivate(feature: FeatureAction) {
    this.logService.logStep('MenuFeatureActionsService.deactivate', 'feature desativada', feature);
    this.featureActionEventSource.next({ featureAction: feature, active: false });
  }

  public ajustaVisibilidade(featureAction: FeatureAction, situacaoFiscal: SituacaoFiscalDto, itensMenuAdicionais: ItensMenuAdicionais[], tipoMenu: TipoMenu): void {
    this.userService.getUser().subscribe(
      usuario => {
        featureAction.tipoMenu = tipoMenu;
        if (featureAction.children && featureAction.children.length > 0) {
          // so exibe se possuir algum filho visivel
          featureAction.children.forEach(m => this.ajustaVisibilidade(m, situacaoFiscal, itensMenuAdicionais, tipoMenu));
          featureAction.children = featureAction.children.filter(m => m.visible);
          featureAction.visible = featureAction.children.some(m => m.visible);
        } else {
          featureAction.visible = this.possuiPendencia(situacaoFiscal, itensMenuAdicionais, featureAction);
        }
        if (featureAction.rolesAllowed != null) {
          const feature = new Feature();
          feature.rolesAllowed = featureAction.rolesAllowed;
          featureAction.visible = featureAction.visible && this.allowedFeaturePipe.transform(feature, usuario);
        }
        if (tipoMenu == TipoMenu.VINCULADO) {
          featureAction.id = featureAction.id + '_' + (situacaoFiscal as Vinculo).getDescricao();
        }
      }
    );
  }

  private possuiPendencia(situacaoFiscal: SituacaoFiscalDto, itensMenuAdicionais: ItensMenuAdicionais[], feature: FeatureAction): boolean {
    const pendencia: string = feature.id;
    let enumPendencia;

    if (situacaoFiscal?.tipoNI == TipoNI.CNPJ) {
      enumPendencia = OrigemPendenciaPessoaJuridica[pendencia];
    } else {
      enumPendencia = OrigemPendenciaPessoaFisica[pendencia];
    }

    if (enumPendencia) {
      let retorno = situacaoFiscal != null && situacaoFiscal.pendenciasExistentes.includes(enumPendencia);
      console.log('menu.id: ' + feature.id + 'Retorno: ', retorno);
      return retorno;
    } else {

      enumPendencia = ItensMenuAdicionais[pendencia];
      if (enumPendencia) {
        let retorno = itensMenuAdicionais != null && itensMenuAdicionais.includes(enumPendencia);
        console.log('menu.id: ' + feature.id + 'Retorno: ', retorno);
        return retorno;
      }

      console.log('menu.id: ' + feature.id + 'Retorno: ', feature.visible);
      return feature.visible;
    }
  }
}
