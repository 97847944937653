<div class="ni-sicob">
  <div class="sicob-toggle">
    <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
  </div>
  <div>
    {{ debitos[0].tipoNi }}: {{ debitos[0].ni | formatCpfCnpjCeiPipe: debitos[0].tipoNi }}
  </div>
</div>
<ng-container *ngIf="rowToggle.show">
  <div class="sicob-conteudo">
    <div class="table-responsive">
      <table suiteRfbTable>
        <thead>
          <tr>
            <th class="text-center min-width">Débito</th>
            <th class="text-center">Situação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let debito of debitos | currentPage: currentPage;" class="vertical-align-middle">
            <td class="text-center text-nowrap">{{ debito.numeroDebito | trimLeft: '0' | mask: '00000000-0' }}</td>
            <td class="text-left">{{ debito.situacaoFase }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="tabela-acoes text-left">
            <td colspan="2">
              <via-pagination [itemAmount]="debitos.length" [(page)]="currentPage"></via-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-container>
