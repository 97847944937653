import { Pipe, PipeTransform } from '@angular/core';
import { CodigoDescricaoDTO } from 'src/app/cnpj/model/codigo-descricao-dto';

@Pipe({
    name: 'codigoDescricaoPipe'
  })
  export class CodigoDescricaoPipe implements PipeTransform {
    transform(motivosTransferencia: CodigoDescricaoDTO[]): string[] {
      return motivosTransferencia.map(motivo => motivo.descricao);
    }
  }