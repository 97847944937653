import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjustaTamanhoNIService } from 'src/app/shared/services/ajusta-tamanho-ni.service';
import { environment } from 'src/environments/environment';
import { DebitoSief } from '../../model/debito-sief';
import { TipoNI } from '../../model/enum/tipo-ni';

@Injectable()
export class DebitoSiefService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient, private ajustaTamanhoNiService: AjustaTamanhoNIService) { }

  // O campo niDevedorPrincipal pode trazer CPF ou CNPJ
  public obterDebitosSuspensos(ni: string, numeroProtocolo: string): Observable<DebitoSief[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sief/debitos/${ni}/Exigibilidade`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoSief[]>(url, options).pipe(
      map(debitos => {
        debitos.forEach(debito => {
          debito.niDevedorPrincipal = debito.tipoDevedorPrincipal == '1' ?
            this.ajustaTamanhoNiService.transform(debito.niDevedorPrincipal, TipoNI.CNPJ) :
            this.ajustaTamanhoNiService.transform(debito.niDevedorPrincipal, TipoNI.CPF);
        });
        return debitos;
      })
    );
  }

  // O campo niDevedorPrincipal é tratado pois pode trazer CPF ou CNPJ
  public obterDebitosAtivos(ni: string, numeroProtocolo: string): Observable<DebitoSief[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sief/debitos/${ni}/Cobranca`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoSief[]>(url, options).pipe(
      map(debitos => {
        debitos.forEach(debito => {
          debito.niDevedorPrincipal = debito.tipoDevedorPrincipal == '1' ?
            this.ajustaTamanhoNiService.transform(debito.niDevedorPrincipal, TipoNI.CNPJ) :
            this.ajustaTamanhoNiService.transform(debito.niDevedorPrincipal, TipoNI.CPF);
        });
        return debitos;
      })
    );
  }
}
