import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DynamicActionPanelComponent } from 'src/app/shared/dynamic-panel/dynamic-action-panel/dynamic-action-panel.component';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { LogService } from 'src/app/shared/services/log.service';
import { Feature, FeatureAction, TipoMenu } from '../../shared/model/feature';
import { TipoNI } from '../model/enum/tipo-ni';
import { ItensMenuAdicionais } from '../model/itens-menu';
import { SituacaoFiscalPessoaFisicaDto } from '../model/situacao-fiscal-pf-dto';
import { LiberacaoCndService } from './liberacao-cnd/liberacao-cnd.service';
import { FEATUREACTIONS_PF } from './situacao-fiscal-pf.features';

/* Componente que engloba o menu de features e a o painel de quadros. */
@Component({
  selector: 'via-situacao-fiscal-pf',
  templateUrl: './situacao-fiscal-pf.component.html',
  styleUrls: ['./situacao-fiscal-pf.component.css']
})
// TODO: Lucas - implementar interface para componentes de dominio IDominio
export class SituacaoFiscalPfComponent implements OnInit {

  @Input() situacaoFiscal: SituacaoFiscalPessoaFisicaDto;

  /** Todas as opções do Menu em estrutura hierárquica */
  featureActions = null;
  // Ni
  @Input() cpf: string;
  // opção do menu ativa
  @Input() feature: Feature;

  @Input()
  possuiCnpjsVinculados: boolean;

  @Input()
  possuiDeclaracoesIrpfMalha: boolean = false;

  itensMenuAdicionais: ItensMenuAdicionais[];

  // elemento HTML conteiner dos paineis de funcionalidades
  @ViewChild('widgets', { read: ViewContainerRef, static: true })
  private readonly _dashboard: ViewContainerRef;

  public get dashboard(): ViewContainerRef {
    return this._dashboard;
  }

  // notificador de componente destruido
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  // colecao de quadros apresentados no dashboard
  private quadrosDinamicosAtivos: Map<string, DynamicActionPanelComponent> = new Map();

  constructor(
    private menuFeatureActionService: MenuFeatureActionsService,
    private logService: LogService,
    private resolver: ComponentFactoryResolver,
    private liberacaoService: LiberacaoCndService) { }

  ngOnInit() {
    this.constroiMenu();
    this.menuFeatureActionService.featureActionEvent$
      .pipe(takeUntil(this.ngUnsubscribe)) // desregistra quando o componente foi encerrado
      .subscribe(featureEvent => {
        // se o quadro pode ser carregado dinamicamente
        if (featureEvent.featureAction.class && featureEvent.featureAction.tipoMenu == TipoMenu.PESSOAFISICA) {
          this.logService.logStep('SituacaoFiscalComponent.ngOnInit', 'menuFeatureActionService.featureActionEvent', featureEvent);
          // se o quadro está sendo aberto e ainda não está aberto
          if (featureEvent.active && !this.quadrosDinamicosAtivos.has(featureEvent.featureAction.id)) {
            const widget = this.createWidgetPanel(featureEvent.featureAction, this.cpf);
            // registra um quadro ativo aberto
            this.quadrosDinamicosAtivos.set(featureEvent.featureAction.id, widget);
          } else if (!featureEvent.active && this.quadrosDinamicosAtivos.has(featureEvent.featureAction.id)) {
            // fecha se quadro aberto
            this.quadrosDinamicosAtivos.get(featureEvent.featureAction.id).closeRequest();
          }
        }
      });
  }

  private constroiMenu(): void {
    this.situacaoFiscal.tipoNI = TipoNI.CPF;
    this.itensMenuAdicionais = [];

    this.liberacaoService.obterLiberacaoCnd(TipoNI.CPF, this.cpf)
      .subscribe(
        liberacao => {
          if (liberacao.length > 0) {
            this.itensMenuAdicionais.push(ItensMenuAdicionais.LiberacaoCnd);
          }
        },
        erro => this.logService.logAjaxError('Ocorreu um erro ao verificar liberação de CND', '', erro),
        () => {
          const itensMenu = _.cloneDeep(FEATUREACTIONS_PF);
          this.ajustaVisibilidadeItensMenuExtras();
          itensMenu.forEach(itemMenu => this.menuFeatureActionService.ajustaVisibilidade(itemMenu, this.situacaoFiscal, this.itensMenuAdicionais, TipoMenu.PESSOAFISICA));
          this.featureActions = itensMenu.filter(itemMenu => itemMenu.visible);
        }
      );
  }

  private ajustaVisibilidadeItensMenuExtras(): void {
    if (this.possuiCnpjsVinculados) {
      this.itensMenuAdicionais.push(ItensMenuAdicionais.CnpjsVinculados);
    }
    if (this.possuiDeclaracoesIrpfMalha) {
      this.itensMenuAdicionais.push(ItensMenuAdicionais.DeclaracaoIrpfMalha);
    }
  }

  ngOnDestroy(): void {
    // notifica que o componente foi destruido
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private createWidgetPanel(featureAction: FeatureAction, ni: string): DynamicActionPanelComponent {

    // cria um widget ActionPanelComponent
    const wFactory = this.resolver.resolveComponentFactory(DynamicActionPanelComponent);
    const wComponentRef = this._dashboard.createComponent<DynamicActionPanelComponent>(wFactory, 0);
    const widget = wComponentRef.instance;

    // registra para destruir o componente dinâmico quando ele for fechado
    widget.onFeatureClosed.pipe(take(1)).subscribe(featureId => {
      if (featureId) {
        wComponentRef.destroy();
        // remove o quadro fechado da lista de ativos
        this.quadrosDinamicosAtivos.delete(featureId);
      }
    });

    // instancia um componente de painel como filho do widget
    widget.instantiatePanel(featureAction, ni);

    return widget;
  }
}
