import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParcelamentoPaex } from './model/parcelamento-paex';

@Injectable()
export class ParcelamentoPaexService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosPaexAtivos(ni: string, numeroProtocolo: string): Observable<ParcelamentoPaex[]> {
    const url = `${this.baseUrl}/situacoesfiscais/paex/Cobranca/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoPaex[]>(url, options);
  }

  public obterParcelamentosPaexSuspensos(ni: string, numeroProtocolo: string): Observable<ParcelamentoPaex[]> {
    const url = `${this.baseUrl}/situacoesfiscais/paex/Exigibilidade/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoPaex[]>(url, options);
  }

  public getUrlExtratoPaex(nomeNi: string, parcelamento: ParcelamentoPaex): string {
    return `${environment.urlExtratoPaex}?strCNPJ=${parcelamento.ni}&strNomeCNPJ=${encodeURIComponent(nomeNi)}&strTributo=${parcelamento.codigoParcelamento}&strNomeTaxaJuros=${parcelamento.nomeTaxaJuros}&strOrigemParcela=SITFISWEB`;
  }
}
