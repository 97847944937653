<suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

<ng-template [ngIf]="consultaSituacaoFiscalCompleta && consultaCnpjsVinculadosCompleta && consultaMalhaIrpfCompleta">
  <div class="content">
    <div class="min-container">
      <div class="trigger">
        <button type="button" class="btn btn-default" (click)="clickPrincipal()">
          <span *ngIf="situacaoFiscalPF" title="Menu de Opções de Pessoa Física" class="material-icons icon-18">menu</span>
          <span *ngIf="situacaoFiscalPJ" title="Menu de Opções de Pessoa Jurídica" class="material-icons icon-18">menu</span>
        </button>
      </div>
      <div class="trigger">
        <button type="button" class="btn btn-default" (click)="clickFeature()">
          <span *ngIf="situacaoFiscalPF" title="Menu Situação Fiscal Pessoa Física" class="material-icons icon-18">list</span>
          <span *ngIf="situacaoFiscalPJ" title="Menu Situação Fiscal Pessoa Jurídica" class="material-icons icon-18">list</span>
        </button>
      </div>
        <div class="trigger">
          <button type="button" class="btn btn-default" *ngIf="vinculos?.length > 0" (click)="clickVinculos()">
            <span title="Vinculos" class="material-icons icon-18">link</span>
          </button>
        </div>
    </div>

    <div class="feature-container">
      <div class="situacao-fiscal">
        <ng-container *ngIf="situacaoFiscalPF">
          <via-situacao-fiscal-atendimento-pf
            [hidden]="menu.collapsed"
            [cpf]="ni.ni"
            [feature]="menu"
            [featureActions]="features">
          </via-situacao-fiscal-atendimento-pf>

          <via-situacao-fiscal-pf
            [hidden]="feature.collapsed"
            [cpf]="ni.ni"
            [feature]="feature"
            [situacaoFiscal]="situacaoFiscalPF"
            [possuiCnpjsVinculados]="possuiCnpjsVinculados"
            [possuiDeclaracoesIrpfMalha]="possuiDeclaracoesIrpfMalha">
          </via-situacao-fiscal-pf>
        </ng-container>

        <ng-container *ngIf="situacaoFiscalPJ">
          <via-situacao-fiscal-atendimento-pj
            [hidden]="menu.collapsed"
            [cnpj]="ni.ni"
            [feature]="menu"
            [featureActions]="features">
          </via-situacao-fiscal-atendimento-pj>

          <via-situacao-fiscal-pj
            [hidden]="feature.collapsed"
            [cnpj]="ni.ni"
            [feature]="feature"
            [situacaoFiscal]="situacaoFiscalPJ">
          </via-situacao-fiscal-pj>
        </ng-container>

        <ng-container *ngIf="vinculos?.length > 0">
          <via-situacao-fiscal-vinculos
            [hidden]="vinculosFeature.collapsed"
            [vinculos]="vinculos"
            [vinculosFeature]="vinculosFeature">
          </via-situacao-fiscal-vinculos>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!consultaSituacaoFiscalCompleta">
  <div class="content">
    <div class="consultas-iniciais-em-processamento-container">
      <p>{{statusProcessamento}}</p>
      <button class="btn btn-primary" (click)="onCancelarProcessamentoAtendimento()">Cancelar</button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="consultaSituacaoFiscalCompleta && !consultaCnpjsVinculadosCompleta">
  <div class="content">
    <div class="consultas-iniciais-em-processamento-container">
      <p>Consulta existência de CNPJs vinculados em processamento...</p>
      <button class="btn btn-primary" (click)="onCancelarProcessamentoAtendimento()">Cancelar</button>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="consultaSituacaoFiscalCompleta && consultaCnpjsVinculadosCompleta && !consultaMalhaIrpfCompleta">
  <div class="content">
    <div class="consultas-iniciais-em-processamento-container">
      <p>Consulta existência de Declarações IRPF em Malha em processamento...</p>
      <button class="btn btn-primary" (click)="onCancelarProcessamentoAtendimento()">Cancelar</button>
    </div>
  </div>
</ng-template>
