<div class="ni-parcelamento-sicob">
  <div class="parcelamento-sicob-toggle">
    <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
  </div>
  <div>
    {{ parcelamentos[0].tipoNi }}: {{ parcelamentos[0].ni | formatCpfCnpjCeiPipe : parcelamentos[0].tipoNi }}
  </div>
</div>
<ng-container *ngIf="rowToggle.show">
  <div class="parcelamento-sicob-conteudo">
    <div class="table-responsive">
      <table suiteRfbTable>
        <thead>
          <tr>
            <th class="text-center min-width">Parcelamento</th>
            <th class="text-center">Situação</th>
            <th class="text-center">Modalidade</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let parcelamento of parcelamentos | currentPage: currentPage;" class="vertical-align-middle">
            <td class="text-center">{{ parcelamento.numeroDebito | trimLeft: '0' | mask: '00000000-0' }}</td>
            <td class="text-left">{{ parcelamento.situacaoFase }}</td>
            <td class="text-left">{{ parcelamento.modalidade }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="tabela-acoes text-left">
            <td colspan="3">
              <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</ng-container>
