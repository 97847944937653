import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges, ViewChildren
} from "@angular/core";
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { Page, ToggleComponent } from "@serpro/ngx-suite-rfb";
import { isEmpty } from "lodash";
import { Subscription } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { DynamicModalRequestService } from "src/app/shared/dynamic-modal/dynamic-modal-request.service";
import {
  IDynamicPanel,
  OptionalParams
} from "src/app/shared/dynamic-panel/panel-interface";
import { MenuFeatureActionsService } from "src/app/shared/menu-feature-actions/menu-feature-actions.service";
import { ScopedMessageService } from "src/app/shared/services/scoped-message.service";
import { ParametrosPesquisaCcitr } from "../../../../models/parametros-pesquisa-ccitr";
import { CcitrBase } from "../../base/CcitrBase.component";
import { CONSTANTS } from "../../constants";
import { CCITRService } from "../../services/ccitr.service";
import { CTDepuradoDTO } from "./model/ct-depurado";

@Component({
  selector: "recupera-ct-depurado",
  templateUrl: "./recupera-ct-depurado.component.html",
  styleUrls: ["./recupera-ct-depurado.component.css" ,'../../base/ccitr.css'],
  providers: [ScopedMessageService],
})
export class RecuperaCTDepuradoComponent
  extends CcitrBase
  implements IDynamicPanel, OnInit, OnChanges, OnDestroy
{
  @Input() parametros: ParametrosPesquisaCcitr;
  @ViewChildren("rowToggle") rowToggleQuery: QueryList<ToggleComponent>;

  updateSubscription: Subscription;

  CTDepurados: CTDepuradoDTO[];
  classeModal: string;

  scopeId: string;
  exibirMensagem: boolean = true;

  initError: Error;
  stopLoading = false;
  showCloseModal = true;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize,
  };

  private executouObterDados = false;

  public init(ni: string, params?: OptionalParams): void {
    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosPesquisaCcitr;

    this.formataTitulo(this.parametros, "CCITR - CTs Depurados");

    this.recuperarCTDepurados();
  }

  constructor(
    private _renderer: Renderer2,
    private cCITRService: CCITRService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private messageService: ScopedMessageService,
    private loadingService: LoadingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public recarregar(): void {
    this.initError = null;
    // this.creditosTributarios = null;
    this.recuperarCTDepurados();
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.recuperarCTDepurados();
    }
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach((row) => (row.show = show));
  }

  private recuperarCTDepurados(): void {
    this.executouObterDados = true;

    this.cCITRService.recuperarCTDepurados(this.parametros).subscribe(
      (response) => {
        this.CTDepurados = response;
        if (isEmpty(response)) {
          this.messageService.showInfo("Nenhum registro encontrado");
        }
        this.stopLoading = true;
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          this.stopLoading = true;
          return null;
        } else {
          this.messageService.showErrorException("Ocorreu um erro durante a consulta para recuperação dos CTs depurados", err);
          this.initError = err;
        }
      }
    );
  }

  private retornarCTDepurado(item: CTDepuradoDTO): void {

    this.loadingService.show();
    this.messageService.dismissMessages();

    const requisicao = this.cCITRService
      .retornarCTDepurados(
        this.parametros.nirf,
        this.parametros.cpfContribuinte,
        this.parametros.cnpjContribuinte,
        this.parametros.indicadorCnpjBasico,
        item.exercicioCT,
        item.tipoLancamento
      )
      .subscribe(
        (response) => {
          this.messageService.showInfo(
            `Retorno de CT Depurado para o exercício ${item.exercicioCT} realizado com sucesso!`
          );
        },
        (error) => {
          if (error.status == CONSTANTS.HTTP_STATUS_WARNING) {
            this.messageService.showWarning(error.error.text);
            this.stopLoading = true;
            return null;
          } else {
            this.messageService.showErrorException(
              "Ocorreu um erro ao retornar o CT Depurado",
              error
            );
          }
        }
      ).add(() => {
        this.loadingService.hide();
      });
  }
}
