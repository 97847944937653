<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">NI Devedor Principal</th>
        <th class="text-center min-width">Inscrição</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let inscricao of inscricoes | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <ni-com-link [ni]="inscricao.niDevedorPrincipal"></ni-com-link>
          </td>
          <td class="text-center text-nowrap">
            <span class="link" tooltip="Consultar Inscrição no Portal PGFN"
              (click)="_window.open(urlPortalPgfn(inscricao), '_blank')">
              {{ inscricao.numeroInscricao | inscricaoPgfnPipe }}
            </span>
          </td>
          <td class="text-left">{{ inscricao.situacao }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="4">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      NI Estabelecimento
                    </label>
                    <p class="form-control-static dado-formulario">
                      <ni-com-link [ni]="inscricao.niEstabelecimento"></ni-com-link>
                    </p>
                  </div>
                  <div class="col-md-8">
                    <label class="label-formulario control-label">
                      Número do Processo
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.numeroProcesso | formatProcessoPipe:inscricao.inTamanhoProcesso | vazio }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Data da Inscrição
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.dataInscricao | date: 'shortDate' | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Data do Ajuizamento
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.dataAjuizamento | date: 'shortDate' | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Data da Análise
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.dataAnalise | date: 'shortDate' | vazio }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Órgão Responsável
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.orgaoResponsavel | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Receita
                    </label>
                    <p class="form-control-static dado-formulario">
                      <via-codigo-receita [codigo]="inscricao.codigoReceita"></via-codigo-receita>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Tributo
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ inscricao.nomeTributo | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="inscricoes.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
