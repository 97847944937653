import { Component } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfCnpjPipe } from 'src/app/shared/pipes/formata-cpf-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSIEFParDTO } from './model/parcelamento-siefpar';
import { ParcelamentoSIEFParService } from './parcelamento-siefpar.service';

@Component({
  templateUrl: './parcelamento-siefpar.component.html',
  styleUrls: ['./parcelamento-siefpar.component.css']
})
export class ParcelamentoSIEFParComponent implements IDynamicPanel {
  
  titulo: string;
  
  initError: Error;
  loadingError$ = new Subject<boolean>();
  parcelamentosObservable$: Observable<ParcelamentoSIEFParDTO[]>;

  constructor(
    private siefparService: ParcelamentoSIEFParService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService) {
  }
  public init(ni: string): void {
    throw new Error('Este método não deve ser chamado diretamente. Utilize carregaParcelamentos(ni, tipoNI, tipoPendencia)');
  }

  public carregaParcelamentos(ni: string, tipoNI: TipoNI, tipoPendencia: TipoPendencia): void {
    if(ni && tipoNI && tipoPendencia) {
      this.montaTitulo(ni, tipoNI, tipoPendencia);
  
      const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
      const protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
         
      this.parcelamentosObservable$ = this.siefparService
        .obterParcelamentosSIEFPAR(tipoNI, ni, protocolo, tipoPendencia)
        .pipe(
          catchError(err => {
            this.loadingError$.next(true);
            this.messageService.showErrorException(this.titulo, err);
            return of<ParcelamentoSIEFParDTO[]>();
          })
        );
    } else {
      throw new Error('Este método não recebeu os parâmetros necessários.');
    }
  }

  private montaTitulo(ni: string, tipoNI: TipoNI, tipoPendencia: TipoPendencia): void {
    this.titulo = `Parcelamentos ${this.getTipoParcelamento(tipoPendencia)} Fazendários SIEFPAR - ${tipoNI}: ${FormatCpfCnpjPipe.format(ni)}`;
  }

  private getTipoParcelamento(tipoPendencia: TipoPendencia): string {
    return tipoPendencia === TipoPendencia.COBRANCA ? 'Ativos' : 'Suspensos';
  }

}

@Component({
    templateUrl: './parcelamento-siefpar.component.html',
    styleUrls: ['./parcelamento-siefpar.component.css']
})
export class ParcelamentoSIEFParPFCobrancaComponent extends ParcelamentoSIEFParComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CPF, TipoPendencia.COBRANCA);
  }

}

@Component({
    templateUrl: './parcelamento-siefpar.component.html',
    styleUrls: ['./parcelamento-siefpar.component.css']
})
export class ParcelamentoSIEFParPJCobrancaComponent extends ParcelamentoSIEFParComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CNPJ, TipoPendencia.COBRANCA);  
  }

}

@Component({
    templateUrl: './parcelamento-siefpar.component.html',
    styleUrls: ['./parcelamento-siefpar.component.css']
})
export class ParcelamentoSIEFParPFExigibilidadeComponent extends ParcelamentoSIEFParComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CPF, TipoPendencia.EXIGIBILIDADE_SUSPENSA);
  }

}

@Component({
    templateUrl: './parcelamento-siefpar.component.html',
    styleUrls: ['./parcelamento-siefpar.component.css']
})
export class ParcelamentoSIEFParPJExigibilidadeComponent extends ParcelamentoSIEFParComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CNPJ, TipoPendencia.EXIGIBILIDADE_SUSPENSA);
  }

}