import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoNI } from '../../model/enum/tipo-ni';
import { LiberacaoCnd } from './model/liberacao-cnd';

@Injectable()
export class LiberacaoCndService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterLiberacaoCnd(tipoNi: TipoNI, ni: string, codEnte?: string): Observable<LiberacaoCnd[]> {
    let url = `${this.baseUrl}/situacoesfiscais/liberacao/${tipoNi}/${ni}`;
    if (codEnte) {
      url += `/${codEnte}`
    }
    return this.http.get<LiberacaoCnd[]>(url, {});
  }
}
