<div class="table-responsive">
  <table suiteRfbTable>
    <caption>Filtros</caption>
    <tbody>
      <tr>
        <th class="col-md-3">Número do Título de Eleitor</th>
        <td class="col-md-9">{{ parametrosPesquisa.numeroTitulo | vazio }}</td>
      </tr>

      <tr>
        <th class="col-md-3">Nome</th>
        <td class="col-md-10">{{ parametrosPesquisa.nome | vazio }}</td>
      </tr>

      <tr>
        <th class="col-md-3">Nome da Mãe</th>
        <td class="col-md-9">{{ parametrosPesquisa.nomeMae | vazio }}</td>
      </tr>

      <tr>
        <th class="col-md-3">Data de Nascimento</th>
        <td class="col-md-9">
          {{ parametrosPesquisa.dataNascimento | date: 'dd/MM/yyyy' | vazio }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
