import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { CafirSuiteRfbModule } from './cafir-suite-rfb.module';
import { CafirComponent } from './components/cafir.component';
import { DetalheImovelRuralComponent } from './components/detalhe/detalhe-imovel-rural.component';
import { CafirTableComponent } from './components/table/cafir-table.component';
import { CafirService } from './services/cafir.service';

@NgModule({
  declarations: [
    CafirComponent,
    CafirTableComponent,
    DetalheImovelRuralComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    PipesModule,
    SharedModule,
    CafirSuiteRfbModule
  ],
  exports: [
    CafirComponent,
    DetalheImovelRuralComponent
  ],
  providers: [
    CafirService
  ]
})
export class CafirModule { }
