<fieldset #fs class="service-container">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <div style="position: relative; z-index: 99;">
      <a after-title href="javascript:void(0)" title="Fechar" (click)="bsModalRef.hide()">
          <span class="material-icons icon-18" style="font-size: 28px">close</span>
      </a>
  </div>
  <div class="modal-container">
    <suite-rfb-collapse title="Consultas Gerais - Dados Código de Receita">
      <ng-container *ngIf="(receitaObservable$ | async) as receita; else loadingOrError">
        <div class="container-fluid">
  
          <div class="row">
            <div class="col-md-12">
              <label class="label-formulario control-label">
                Tributo
              </label>
              <p class="form-control-static dado-formulario">
                {{ receita.nomeTributo | vazio }}
              </p>
            </div>
          </div>
  
          <div class="row">
              <div class="col-md-12">
                <label class="label-formulario control-label">
                Receita
              </label>
              <p class="form-control-static dado-formulario">
                {{ receita.descricao | vazio }}
              </p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-12">
              <label class="label-formulario control-label">
                Vigência
              </label>
              <p *ngIf="receita.dataFimVigencia" class="form-control-static dado-formulario">
                  De {{ receita.dataInicioVigencia }} a {{ receita.dataFimVigencia }}
              </p>
              <p *ngIf="!receita.dataFimVigencia" class="form-control-static dado-formulario">
                  A partir de {{ receita.dataInicioVigencia }}
            </p>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-12">
              <label class="label-formulario control-label">
                Administracao pela RFB
              </label>
              <p class="form-control-static dado-formulario">
                {{ receita.administradaRfb ? "Sim" : "Não" }}
              </p>
            </div>
          </div>
  
        </div>
      </ng-container>
    </suite-rfb-collapse>
  </div>
</fieldset>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
