import { CreditoTributario } from '../models/credito-tributario';

export enum CriterioSelecao {
  CREDITOS_VENCIDOS_VENCER = '3',
  CREDITOS_TODOS = '1'
}

export interface ParametrosPesquisaCreditosTributarios {
  cpfContribuinte: string;
  criterioSelecao: string;
  exercicio: string;
  exercicioFinal: string;
  isModal: boolean;
}

export interface ParametroTransferenciaCredito {
  cpfContribuinte: string;
  creditoTributario: CreditoTributario;
  atendimento: boolean;
  parametrosPesquisa: ParametrosPesquisaCreditosTributarios;
}

export interface ParametrosConsultaEventosCT {
  cpfContribuinte: string;
  numeroCT: string;
  dataInicial: Date;
  dataFinal: Date;
}
