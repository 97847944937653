export enum TipoDebitoInclusao {
  SEM_OPCAO = '1',
  CANCELADO = '5',
  CANCELADO_POR_OPCAO = '6',
  CANCELADO_POR_RETIFICADORA = '9'
}

export enum TipoDebitoAlteracaoCancelamento {
  ATIVO_1A_COTA = '2',
  ATIVO_2A_COTA = '3',
  ATIVO_POR_DEBITO = '4'
}
