import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbDatePickerModule,
  SuiteRfbInputMaskModule,
  SuiteRfbInputValidationModule,
  SuiteRfbOutputMaskModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbInputValidationModule,
    SuiteRfbOutputMaskModule
  ]
})
export class CnpjSuiteRfbModule { }
