<div class="feature">
  <div class="float-left">
    <fieldset class="menu-feature" [hidden]="feature.collapsed">
      <app-menu-feature-actions
        [feature]="feature"
        [featureActions]="featureActions">
      </app-menu-feature-actions>
    </fieldset>
  </div>

  <div class="feature-services">
    <div #widgets style="border: 2px;"></div>
  </div>
</div>
