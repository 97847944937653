import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtilService } from '../util/number-util.service';

// formato 9999-9/99
const mascaraCNAE: string = '$1-$2/$3';
const formatoCNAE: RegExp = /^([0-9]{4})([0-9]{1})([0-9]{2})$/;
const tamanho = 7;

@Pipe({
  name: 'subclasseCnaePipe'
})
export class SubclasseCNAEPipe implements PipeTransform {

  constructor(private numberUtils: NumberUtilService) { }

  public transform(cnae: IAtividadeEconomica): string {
    return this.formataSubclasseCNAE(cnae.codigo + '') + ' - ' + cnae.nome;
  }

  /**
  * Formata um codigo de subclasse CNAE.
  *
  * @param codigo
  * @returns o numero formatado. Se o número informado for maior do que tamanho especificado, retorna o proprio valor informado como string sem formatar.
  */
  private formataSubclasseCNAE(codigo: string): string {
    if (codigo) {
      if (this.numberUtils.onlyDigits(codigo)) {
        if (codigo.length <= tamanho) {
          codigo = this.numberUtils.leftZeroes(codigo, tamanho);
          return codigo.replace(formatoCNAE, mascaraCNAE);
        }
      }
      return codigo;
    }
    return '';
  }

}

interface IAtividadeEconomica {
  codigo: number;
  nome: string;
}
