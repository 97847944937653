<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!parcelamento && !initError; else infoParcelamento;"></loading-animation>
</suite-rfb-collapse>
<ng-template #infoParcelamento>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <label class="control-label label-formulario">Data de Opção</label>
        <p class="form-control-static dado-formulario">{{ parcelamento.dataOpcao | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div class="col-md-6">
        <label class="control-label label-formulario">Motivo</label>
        <p class="form-control-static dado-formulario">{{ parcelamento.motivo }}</p>
      </div>
      <div class="col-md-3">
        <ng-container *ngIf="parcelamento.dataExclusao">
          <label class="control-label label-formulario">Data de Exclusão</label>
          <p class="form-control-static dado-formulario">{{ parcelamento.dataExclusao | date: 'dd/MM/yyyy' }}</p>
        </ng-container>
      </div>
    </div>
    <div *ngIf="isAtivos">
      <label class="control-label label-ausencias">Ausência de Pagamento nos Meses de Vencimento</label>
      <div class="box">
        <ng-container *ngFor="let ano of ausenciasAnos">
          <ng-container *ngFor="let mes of ['01','02','03','04','05','06','07','08','09','10','11','12']">
            <ng-container
              [ngTemplateOutlet]='ausencias.get(ano).get(mes) ? mesNaoPago : mesPago'
              [ngTemplateOutletContext]="{ ano: ano, mes: mes }">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <label class="control-label label-total-de-registros">
        Total de Registros: {{ parcelamento.ausencias.length }}
      </label>
    </div>
  </div>
</ng-template>

<ng-template #mesNaoPago let-ano='ano' let-mes='mes'>
  <div class="mes-nao-pago">{{ ausencias.get(ano).get(mes) }}</div>
</ng-template>

<ng-template #mesPago>
  <div class="mes-pago"></div>
</ng-template>
