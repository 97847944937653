<div class="table-responsive">
  <table suiteRfbTable>
    <caption style="text-align: left; padding-left: 10px;">{{ nomeTributo }}</caption>
    <thead>
      <tr>
        <th *ngIf="isAtivos" class="text-center min-width">Ações</th>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Exercício</th>
        <th class="text-center min-width">Data de Vencimento</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Saldo Devedor</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let debito of debitos | currentPage: currentPage;" class="vertical-align-middle">
        <tr class="vertical-align-middle">
          <td *ngIf="isAtivos" class="text-center">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button
                  [disabled]="!debito.indExibeDARF"
                  type="button"
                  class="btn btn-default"
                  (click)="onEmitirDarf(debito);">
                  Emitir DARF
                </button>
              </div>
            </ng-template>
          </td>
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <via-codigo-receita [codigo]="debito.codigoReceita"></via-codigo-receita>
          </td>
          <td class="text-center">{{ debito.exercicio }}</td>
          <td class="text-center">{{ debito.dataVencimento | date: 'shortDate' }}</td>
          <td class="text-right">{{ debito.valorOriginal | formatCurrency }}</td>
          <td class="text-right">{{ debito.valorSaldoDevedor | formatCurrency }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="7">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      NI Estabelecimento
                    </label>
                    <p class="form-control-static dado-formulario">
                      <ni-com-link [ni]="debito.niEstabelecimentoDebito"></ni-com-link>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Tributo
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.nomeTributo | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Situação
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.situacaoDebito | vazio }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Débito
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroDebito | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Unidade Monetária
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.unidadeMonetaria | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="7">
          <via-pagination [itemAmount]="debitos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
