import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultaTituloEleitorSuiteRfbModule } from './consulta-titulo-eleitor-suite-rfb.module';
import { ConsultaTituloEleitorComponent } from './consulta-titulo-eleitor.component';
import { ParametrosPesquisaTituloEleitorComponent } from './parametros-pesquisa/parametros-pesquisa-titulo-eleitor.component';
import { ConsultaTituloEleitorTableComponent } from './table/consulta-titulo-eleitor-table.component';

@NgModule({
  declarations: [
    ConsultaTituloEleitorComponent,
    ConsultaTituloEleitorTableComponent,
    ParametrosPesquisaTituloEleitorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    TooltipModule.forRoot(),
    ConsultaTituloEleitorSuiteRfbModule
  ]
})
export class ConsultaTituloEleitorModule { }
