import { NgModule } from '@angular/core';
import {
  SuiteRfbCollapseModule,
  SuiteRfbConfirmationsModule,
  SuiteRfbDatePickerModule,
  SuiteRfbInputMaskModule,
  SuiteRfbSelectModule,
  SuiteRfbToggleModule
} from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbSelectModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbConfirmationsModule,
    SuiteRfbDatePickerModule,
    SuiteRfbInputMaskModule,
    SuiteRfbSelectModule,
    SuiteRfbToggleModule
  ]
})
export class PagamentoSuiteRfbModule { }
