<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!parcelamentos && !initError; else tabelaParcelamentos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaParcelamentos>
  <via-parcelamento-simples-nacional-table
    [parcelamentos]="parcelamentos"
    [ni]="ni"
    [isAtivos]="isAtivos"
    [isSuspensos]="isSuspensos">
  </via-parcelamento-simples-nacional-table>
</ng-template>
