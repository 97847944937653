<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <ng-container *ngIf="!exibirDadosConsolidados; else dadosConsolidados;">
  <div class="container-fluid form-height">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <label class="control-label faixa-valor-label">Data de Consolidação</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-data-arrecadacao">
              <div class="form-inline">

                <div class="form-group form-group-data-arrecadacao">
                  <suite-rfb-date-picker
                    direction="right"
                    [outsideClick]="true"
                    adaptivePosition="true"
                    [formControlName]="dataConsolidacaoName"
                    >
                  </suite-rfb-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <button class="btn btn-default" type="reset" [disabled]="!form.dirty">Limpar</button>
            <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Enviar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
</suite-rfb-collapse>

<ng-template #dadosConsolidados>
  <via-ccitr-dados-consolidar-ct [parametros]="parametros" [debito]="dadosConsolidadosCTCCITR" [atendimento]="atendimento">
  </via-ccitr-dados-consolidar-ct>
</ng-template>
