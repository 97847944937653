import { Features } from '@serpro/ngx-suite-rfb';
import { Feature } from './shared/model/feature';

export const FEATURE_INICIAL_PF = { title: 'Situação Fiscal PF', icon: 'attach_money ' } as Feature;
export const FEATURE_INICIAL_PJ = { title: 'Situação Fiscal PJ', icon: 'attach_money ' } as Feature;

export const FEATURES: Features = [
  {
    title: 'Pessoa Física',
    children: [
      { title: 'Consultar' } as Feature,
      { title: 'e-Defesa' } as Feature,
      { title: 'Portal de Cadastros' } as Feature,
      { title: 'Relatório Consolidado' } as Feature
    ]
  } as Feature,
  {
    title: 'Pessoa Jurídica',
    children: [
      { title: 'Consultar' } as Feature,
      { title: 'Obrigatoriedade DIRF' } as Feature
    ]
  } as Feature,
  {
    title: 'Cobrança',
    children: [
      { title: 'CCPF' } as Feature,
      {
        title: 'CCITR', 
        children: [
          { title: 'Consultar' } as Feature      
        ]
       } as Feature,
    ],    
  } as Feature,
  {
    title: 'Pagamento',
    children: [
      { title: 'Consultar / Retificar' } as Feature
    ]
  } as Feature,
  {
    title: 'Consultas Gerais',
    children: [
      { title: 'TOM' } as Feature,
      { title: 'Receita' } as Feature
    ]
  } as Feature
];
