import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DadosCadastraisPfDto } from '../../model/dados-cadastrais-pf-sf';

@Injectable()
export class InfoCadastraisPFService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }
    
    public obterInformacoesCadastraisPF(cpf: string,numeroProtocolo: string): Observable<DadosCadastraisPfDto> {
		const url = `${this.baseUrl}/situacoesfiscais/pessoasfisicas/${cpf}/informacoescadastrais`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		}
		return this.http.get<DadosCadastraisPfDto>(url,options).pipe(take(1));
	}
}