import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ResumoDeclaracaoOperacaoImobiliaria } from '../models/resumo-declaracao-operacao-imobiliaria';
import { DoiService } from '../services/doi.service';

@Component({
  selector: 'via-doi',
  templateUrl: './doi.component.html',
  styleUrls: ['./doi.component.css'],
  providers: [ScopedMessageService]
})
export class DoiComponent implements OnInit {

  @Input() cpf: string;

  titulo = 'DOI';

  declaracoes: ResumoDeclaracaoOperacaoImobiliaria[];

  scopeId: string;
  initError: Error;
  stopLoading = false;
  executouObterDados = false;

  constructor(
    private doiService: DoiService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterDeclaracoes();
    }
  }

  private obterDeclaracoes(): void {
    this.executouObterDados = true;

    this.doiService.obterDeclaracoesOperacaoImobiliaria(this.cpf).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum registro encontrado');
          this.stopLoading = true;
        }

        this.declaracoes = response;

        this.declaracoes.forEach(declaracao => declaracao.cnpjCartorio = declaracao.cnpjCartorio?.padStart(14, '0'));
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro na consulta de declarações de operação imobiliária', error);
        this.initError = error;
      }
    );
  }
}
