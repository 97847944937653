import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParcelamentoSIPADE } from '../model/parcelamento-sipade';

@Component({
  selector: 'via-parcelamento-sipade-table',
  templateUrl: 'parcelamento-sipade-table.component.html',
  styleUrls: ['parcelamento-sipade-table.component.css']
})
export class ParcelamentoSIPADETableComponent {

  @Input() parcelamentos: ParcelamentoSIPADE[];
  @Input() exigibilidadeSuspensa: boolean;

  pageSize: number = 20;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
