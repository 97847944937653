import { TipoNIImovel } from './../../../../../models/tipo-ni-imovel-ccitr';
import { Component, Input } from '@angular/core';
import { CcitrImoveisDoContribuinteDTO } from '../model/ccitr-imoveis-do-contribuinte';

@Component({
  selector: 'via-detalhe-ccitr-imoveis-do-contribuinte',
  templateUrl: './detalhe-ccitr-imoveis-do-contribuinte.component.html',
  styleUrls: ['../ccitr-imoveis-do-contribuinte.component.css']
})
export class DetalheCcitrImoveisDoContribuinteComponent {

  @Input() item: CcitrImoveisDoContribuinteDTO;

  tipoDeNi(tipoNI: string) {
    if (tipoNI == TipoNIImovel.CPF)
      return "CPF";
    else if (tipoNI == TipoNIImovel.CNPJ || tipoNI == TipoNIImovel.CNPJ2)
      return "CNPJ";
  }

}
