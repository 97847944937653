<table suiteRfbTable class="table table-striped tabela">
  <caption>{{ titulo }}</caption>
  <thead>
    <tr>
      <th class="text-center">Inclusão</th>
      <th class="text-center">Exclusão</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let opcao of opcoes" class="vertical-align-middle">
      <td class="text-center">{{ opcao.dataOpcao | date: 'shortDate' | vazio }}</td>
      <td class="text-center">{{ opcao.dataExclusao | date: 'shortDate' | vazio }}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr class="tabela-acoes text-left">
      <td colspan="2">
        <via-pagination [itemAmount]="opcoes.length" [(page)]="currentPage"></via-pagination>
      </td>
    </tr>
  </tfoot>
</table>
