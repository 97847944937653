import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TipoNI } from '../../model/enum/tipo-ni';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSIEFParDTO } from './model/parcelamento-siefpar';

@Injectable()
export class ParcelamentoSIEFParService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosSIEFPAR(tipoNI: TipoNI, ni: string, numeroProtocolo: string, tipoPendencia: TipoPendencia): Observable<ParcelamentoSIEFParDTO[]> {
    const url = `${this.baseUrl}/situacoesfiscais/siefpar/${tipoPendencia}/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoSIEFParDTO[]>(url, options)
      .pipe(
        map(parcelamentos => {
          parcelamentos.forEach(p => {
            p.tipoNI = tipoNI
          });
          return parcelamentos;
        })
      );
  }

}