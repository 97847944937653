import { TipoNI } from "./enum/tipo-ni";
import { SituacaoFiscalDto } from "./situacao-fiscal-dto";

export interface SituacaoFiscalPessoaFisicaDto extends SituacaoFiscalDto {

    pendenciasExistentes: OrigemPendenciaPessoaFisica[];
    pendenciasNaoExistentes: OrigemPendenciaPessoaFisica[];
    pendenciasNaoAnalisadas: OrigemPendenciaPessoaFisica[];
    pendenciasIndisponiveis: OrigemPendenciaPessoaFisica[];
    pendenciasEmProcessamento: OrigemPendenciaPessoaFisica[];
    tipoNI: TipoNI;
    ni: string;
    dataEvento: Date;
    certidaoNumeroControle: string;
    certidaoDataValidade: Date;
    certidaoDataEmissao: Date;
    tipoCertidao: TipoCertidao;    
    numeroLiberacao: string;
    indicadorUsoLiberacao: number;
    indicadorEnteFederativoPrincipal: number;
    indicadorVinculado: number;
    tipoNIRequerente: TipoNIRequerente;
}

export enum TipoCertidao {
    Negativa = 'Negativa',
    PosNeg = 'PosNeg',
    Positiva = 'Positiva',
}

export enum TipoNIRequerente {
    CPFAtivo = 'CPFAtivo',
    CPFCancelado = 'CPFCancelado',
    CNPJMatriz = 'CNPJMatriz',
    CNPJAdquirida = 'CNPJAdquirida',
}

export enum OrigemPendenciaPessoaFisica {
    IrregularidadeCadastral = 'IrregularidadeCadastral',
    DitrAusencia = 'DitrAusencia',
    GfipAusencia = 'GfipAusencia',
    ContaCorrente = 'ContaCorrente',
    ItrContaCorrente = 'ItrContaCorrente',
    CcitrExigibilidade = 'CcitrExigibilidade',
    CcpfExigibilidade = 'CcpfExigibilidade',
    ProfiscProcessoCobranca = 'ProfiscProcessoCobranca',
    ProfiscProcessoExigibilidade = 'ProfiscProcessoExigibilidade',
    SipadeParcelamentoCobranca = 'SipadeParcelamentoCobranca',
    SipadeParcelamentoExigibilidade = 'SipadeParcelamentoExigibilidade',
    PaexParcelamentoCobranca = 'PaexParcelamentoCobranca',
    PaexParcelamentoExigibilidade = 'PaexParcelamentoExigibilidade',
    SiefparParcelamentoCobranca = 'SiefparParcelamentoCobranca',
    SiefparParcelamentoExigibilidade = 'SiefparParcelamentoExigibilidade',
    ParcsnCobranca = 'ParcsnCobranca',
    ParcsnExigibilidade = 'ParcsnExigibilidade',
    SiefDebitoCobranca = 'SiefDebitoCobranca',
    SiefDebitoExigibilidade = 'SiefDebitoExigibilidade',
    SiefProcessoCobranca = 'SiefProcessoCobranca',
    SiefProcessoExigibilidade = 'SiefProcessoExigibilidade',
    SiefProcessoArrolamento = 'SiefProcessoArrolamento',
    PgfnInscricaoCobranca = 'PgfnInscricaoCobranca',
    PgfnInscricaoExigibilidade = 'PgfnInscricaoExigibilidade',
    PgfnParcelamentoCobranca = 'PgfnParcelamentoCobranca',
    PgfnParcelamentoExigibilidade = 'PgfnParcelamentoExigibilidade',
    SisparPgfnCobranca = 'SisparPgfnCobranca',
    SisparPgfnExigibilidade = 'SisparPgfnExigibilidade',
    AguiaCobranca = 'AguiaCobranca',
    SicobDebitoCobranca = 'SicobDebitoCobranca',
    SicobDebitoExigibilidade = 'SicobDebitoExigibilidade',
    SicobParcelamentoCobranca = 'SicobParcelamentoCobranca',
    SicobParcelamentoExigibilidade = 'SicobParcelamentoExigibilidade',
    DividaDebitoCobranca = 'DividaDebitoCobranca',
    DividaDebitoExigibilidade = 'DividaDebitoExigibilidade',
    DividaParcelamentoCobranca = 'DividaParcelamentoCobranca',
    DividaParcelamentoExigibilidade = 'DividaParcelamentoExigibilidade',
    ImpedimentoCertidaoRfb = 'ImpedimentoCertidaoRfb',
    ImpedimentoCertidaoPgfn = 'ImpedimentoCertidaoPgfn',
    CadPreposto = 'CadPreposto',
    CadResp = 'CadResp',
    CadSocio = 'CadSocio',
    DirpfAusencia = 'DirpfAusencia'
}
