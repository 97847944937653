import { Component, Input, OnInit } from '@angular/core';
import { FavoriteFeaturesService } from '@serpro/ngx-suite-rfb';
import { Feature } from '../model/feature';

@Component({
  selector: 'app-current-feature',
  templateUrl: './current-feature.component.html',
  styleUrls: ['./current-feature.component.css']
})
export class CurrentFeatureComponent implements OnInit {

  @Input() feature: Feature;

  constructor(private favoriteFeaturesService: FavoriteFeaturesService) { }

  ngOnInit() {
    console.log('CurrentFeatureComponent.ngOnInit feature', this.feature);
  }

  public toggleFavorite(): void {
    this.favoriteFeaturesService.toggle(this.feature);
  }
}
