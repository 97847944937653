export enum CriterioSelecaoCcitr {
  CREDITOS_SOMENTE_MAED = '1',
  CREDITOS_TODOS = '0'
}

export interface ParametrosPesquisaCcitr {
  nirf: string;
  cpfContribuinte: string;
  cnpjContribuinte: string;
  indicadorCnpjBasico: string;
  tipoLancamento: string;
  vencidosAVencer: string;
  exercicioInicial: string;
  exercicioFinal: string;
  atendimento: boolean;
}
