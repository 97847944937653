<div class="row">
  <div class="col-md-2">
    <label class="label-formulario control-label">
      CPF/CNPJ
    </label>
    <p class="form-control-static dado-formulario">
      <span *ngIf="!documento.ni">
        {{ documento.ni | vazio }}
      </span>
      <ni-com-link *ngIf="documento.ni" [ni]="documento.ni" [tipoNI]="documento.tipoNi"></ni-com-link>
    </p>
  </div>
  <div class="col-md-2">
    <label class="label-formulario control-label">
      Processo
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.processoNr | vazio }}
    </p>
  </div>
  <div class="col-md-2">
    <label class="label-formulario control-label">
      UA de Arrecadação
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.uaArrecadacao | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Parcela
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.parcelaNr | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Referência
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.referenciaNr | vazio }}
    </p>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Data de Recepção
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.dataRecepcao | date: 'dd/MM/yyyy' | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      BDA/Seq
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.bdaSeq | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Natureza do Documento
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.naturezaDocArrec?.tnrNm | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      No. do Pagamento
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.numeroPagamento | vazio }}
    </p>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Origem do Erro
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.origemErro | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Valor Devolvido
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.valorDevolvido | formatCurrency | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Valor Transformado
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.valorTransformado | formatCurrency | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Data Emissão
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.dataEmissao | date: 'dd/MM/yyyy' | vazio }}
    </p>
  </div>
</div>
<div class="row">
  <div class="col-md-3">
    <label class="label-formulario control-label">
      Tipo Documento
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.tipoDocumentoDesc | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      ID CEF
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.idDepositoCef | vazio }}
    </p>
  </div>
  <div class="col-md-3">
    <label class="label-formulario control-label">
      No. de Protocolo
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.protocolDebCcNr | vazio }}
    </p>
  </div>
  <div class="col-md-6">
    <label class="label-formulario control-label">
      Código de Barras
    </label>
    <p class="form-control-static dado-formulario">
      {{ documento.codigoDeBarras | vazio }}
    </p>
  </div>
</div>
<div class="row">
  <div class ="col-md-4">
    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Informações de Saldo</legend>
        <label class="label-formulario control-label">
          Saldo
        </label>
        <p class="form-control-static dado-formulario">
          {{ documento.saldoDisponivel | vazio }}
        </p>
    </fieldset>
  </div>
  <div class ="col-md-4">
    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Saldo não Levantado</legend>
      <div class="row">
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Disponível
          </label>
          <p class="form-control-static dado-formulario">
            {{ documento.sdnlDje | formatCurrency | vazio }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Utilizado
          </label>
          <p class="form-control-static dado-formulario">
            {{ documento.sunlDje | formatCurrency | vazio }}
          </p>
        </div>
      </div>
    </fieldset>
  </div>
  <div class ="col-md-4">
    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Saldo Transformado</legend>
      <div class="row">
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Disponível
          </label>
          <p class="form-control-static dado-formulario">
            {{ documento.sdtdDje | formatCurrency | vazio }}
          </p>
        </div>
        <div class="col-md-6">
          <label class="label-formulario control-label">
            Utilizado
          </label>
          <p class="form-control-static dado-formulario">
            {{ documento.sutdDje | formatCurrency | vazio }}
          </p>
        </div>
      </div>
    </fieldset>
  </div>
</div>
<div class="row">
  <div class="col-md-offset-3 col-md-6">
    <div class="table-responsive" style="width: 460">
      <table suiteRfbTable>
        <caption>Valor(es) do Pagamento</caption>
        <thead>
          <tr>
            <th class="text-center min-width">Receita</th>
            <th class="text-center min-width">Valor</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let valor of documento.receitaList">
            <tr class="vertical-align-middle">
              <td class="text-center">
                <via-codigo-receita [codigo]="valor.recCd"></via-codigo-receita>
              </td>
              <td class="text-right">{{ valor?.valor | formatCurrency | vazio }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
