<suite-rfb-collapse [title]="titulo" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="operacaoSucessao; else loadingOrError;">
    <div class="row">
      <div class="col-md-6">
        <label class="form-control-static text-left">
          Razão Social: {{ dadosEmpresa?.nomeEmpresarial | vazio }}
        </label>
      </div>

      <div class="col-md-6">
        <label class="form-control-static text-right">
          Situação Cadastral: {{ dadosEmpresa?.situacaoNoCnpj | codigoDescricao | vazio }}
        </label>
      </div>
    </div>

    <via-operacao-sucessao-table
      *ngIf="operacaoSucessao.operacoesPorSucedida"
      titulo="Sucessoras"
      [operacoesSucessao]="operacaoSucessao.operacoesPorSucedida">
    </via-operacao-sucessao-table>

    <via-operacao-sucessao-table
      *ngIf="operacaoSucessao.operacoesPorSucessora"
      titulo="Sucedidas"
      [operacoesSucessao]="operacaoSucessao.operacoesPorSucessora">
    </via-operacao-sucessao-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
