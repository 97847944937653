<suite-rfb-collapse [title]="titulo" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="declaracoes?.length > 0; else loadingOrError;">
    <via-declaracao-irpf-malha-table
      [declaracoes]="declaracoes"
      (emitirExtrato)="onEmitirExtrato($event)"
      (abrirPortalIrpf)="onAbrirPortalIrpf()"
      (abrirPortalSiefWeb)="onAbrirPortalSiefWeb()">
    </via-declaracao-irpf-malha-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
