import { Pipe, PipeTransform } from '@angular/core';
import { NumberUtilService } from '../../shared/util/number-util.service';

//formato 99.9.99.999999-99
const mascaraInscricao: string = '$1.$2.$3.$4-$5';
const formatoInscricao: RegExp = /^([0-9]{2})([0-9]{1})([0-9]{2})([0-9]{6})([0-9]{2})$/;
const tamanho = 13;

@Pipe({
  name: 'inscricaoPgfnPipe'
})
export class InscricaoPgfnPipe implements PipeTransform {

  constructor(private numberUtils: NumberUtilService) {}
  
  public transform(inscricao: string): string {
    return this.formataInscricao(inscricao);
  }

  /**
 * Formata uma inscricao. 
 * 
 * @param inscricao 
 * @returns o numero formatado. Se o número informado for maior do que o tamanho, retorna o proprio valor informado como string sem formatar.
 */
  private formataInscricao(inscricao: string): string {
    if (inscricao) {
      if (this.numberUtils.onlyDigits(inscricao)) {
        if (inscricao.length <= tamanho) {
          inscricao = this.numberUtils.leftZeroes(inscricao, tamanho);
          return inscricao.replace(formatoInscricao, mascaraInscricao);
        }
      }
      return inscricao;
    }
    return '';
  }

}
