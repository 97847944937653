import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CNPJService {

  calculaDV(cnpj12Pos: number): string {
    if (!cnpj12Pos) {
      return null;
    }
    let cnpjNr12str = cnpj12Pos + "";
    let pad = "000000000000";
    cnpjNr12str = (pad + cnpj12Pos).slice(-pad.length);
    const dv1: number = calculateDv(cnpjNr12str.substring(0, 12));
    cnpjNr12str += dv1;
    const dv2: number = calculateDv(cnpjNr12str.substring(0, 13));
    return dv1 + "" + dv2;
  }

  geraCnpjComDv(cnpj12Pos: number): string {
    if (!cnpj12Pos) {
      return null;
    }
    let cnpjNr12str = cnpj12Pos + "";
    let pad = "000000000000";
    cnpjNr12str = (pad + cnpjNr12str).slice(-pad.length);
    return cnpjNr12str + this.calculaDV(cnpj12Pos);
  }

  getEstbPjNr(cnpjComDv: number): number {
    let pad = "00000000000000";
    let cnpj14pos = (pad + cnpjComDv).slice(-pad.length);
    return +cnpj14pos.substr(0, 8);
  }

  getEstbNr(cnpjComDv: number): number {
    let pad = "00000000000000";
    let cnpj14pos = (pad + cnpjComDv).slice(-pad.length);
    return +cnpj14pos.substr(8, 4);
  }
}

function calculateDv(base: string): number {
  const length: number = base.length;
  let sum: number = 0;
  let pos: number = length - 7;
  for (let i: number = length; i >= 1; i--) {
    sum += Number.parseInt(base.charAt(length - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  const result: number = sum % 11 < 2 ? 0 : 11 - sum % 11;
  return result;
}
