<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!dadosCadastrais && !initError"></loading-animation>
  <fieldset *ngIf="dadosCadastrais">
    <div class="row">
      <div class="col-sm-2">
        <label class="label-formulario control-label">CPF</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.cpf | formatCpfPipe | vazio }}</p>
      </div>
      <div class="col-sm-4">
        <label class="label-formulario control-label">Nome</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.nome | vazio }}</p>
      </div>
      <div class="col-sm-4">
        <label class="label-formulario control-label">Unidade da Receita do Domicílio Fiscal</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.unidade | uaPipe | vazio }}</p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">Data de Nascimento</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.dataNascimento | date:'shortDate' | vazio }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <label class="label-formulario control-label">Endereço</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.endereco | enderecoInfoCadPipe | vazio }}</p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">Bairro</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.endereco.municipio.codigo | vazio:["9707"]:(dadosCadastrais.endereco.bairro | vazio) }}</p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">CEP</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.endereco.municipio.codigo | vazio:["9707"]:(dadosCadastrais.endereco.cep | cepPipe | vazio ) }}</p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">Município</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.endereco.municipio.nome | vazio }}</p>
      </div>
      <div class="col-sm-1">
        <label class="label-formulario control-label">UF</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.endereco.municipio.codigo | vazio:["9707"]:(dadosCadastrais.endereco.municipio.uf | vazio) }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-2">
        <label class="label-formulario control-label">Data de Inscrição</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.dataInscricao | date:'shortDate' | vazio }}</p>
      </div>
      <div class="col-sm-4">
        <label class="label-formulario control-label">Situação do CPF</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.situacaoCadastral | vazio }}</p>
      </div>
      <div class="col-sm-2" *ngIf="(dadosCadastrais.situacaoCadastral === 'CANCELADA POR MULTIPLICIDADE')">
        <label class="label-formulario control-label">CPF Ativo</label>
        <p class="dado-formulario form-control-static">
          <ni-com-link [ni]="dadosCadastrais.cpfAtivo"></ni-com-link>
        </p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">Ano do Óbito</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.anoObito | vazio:["0000"] }}</p>
      </div>
      <div class="col-sm-2">
        <label class="label-formulario control-label">Data da Baixa</label>
        <p class="dado-formulario form-control-static">{{ dadosCadastrais.dataBaixa | date:'shortDate' | vazio }}</p>
      </div>
    </div>
  </fieldset>

</suite-rfb-collapse>
