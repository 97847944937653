<suite-rfb-collapse *ngIf="isModal" [title]="modalTitulo" [collapsed]="false">
  <suite-rfb-alert-messages-with-datetime *ngIf="isModal" [onlyFor]="modalScopeId">
  </suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="detalhe != null; else loadingOrError;">
    <div class="via-modal-lg">
      <ng-container *ngTemplateOutlet="templateDetalheContainer"></ng-container>
    </div>
  </ng-container>
</suite-rfb-collapse>

<ng-container *ngIf="!isModal && detalhe != null">
  <div class="grupo background-inherit imovel-container">
    <ng-container *ngTemplateOutlet="templateDetalhe"></ng-container>
  </div>
</ng-container>

<ng-template #templateDetalheContainer>
  <ng-container *ngTemplateOutlet="templateDetalhe"></ng-container>
</ng-template>

<ng-template #templateDetalhe>
  <ng-container *ngIf="detalhe != null">
    <div class="row">
      <div class="col-md-12">
        <div class="toggle">
          <div style="float: left;">
            <suite-rfb-toggle #imovelToggle></suite-rfb-toggle>
          </div>
          <div>Imóvel</div>
        </div>
        <fieldset class="grupo background-inherit" *ngIf="imovelToggle.show">
          <div class="grupo-conteudo-nolegend">
            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">Situação Atual</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.situacaoImovel | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">Motivo</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.motivoSituacao | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">UA de Jurisdição</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.uaJurisdicao?.codigo | vazio }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">Área Total</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.areaTotal | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">Logradouro</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.endereco?.logradouro | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">Distrito</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.endereco?.distrito | vazio }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">Município</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.endereco?.municipio?.nome | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">UF</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.endereco?.municipio?.uf | vazio }}
                </p>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">CEP</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.endereco?.cep | vazio }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">Dados atualizados por</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.origemAtualizacao | vazio }}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="toggle">
          <div style="float: left;">
            <suite-rfb-toggle #contribuinteToggle></suite-rfb-toggle>
          </div>
          <div>Contribuinte</div>
        </div>
        <fieldset class="grupo background-inherit" *ngIf="contribuinteToggle.show">
          <div class="grupo-conteudo-nolegend">
            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">CPF/CNPJ</label>
                <ng-container *ngIf="detalhe.declarante?.ni; else templateVazio;">
                  <ni-com-link
                    [ni]="detalhe.declarante?.ni"
                    [tipoNI]="getTipoNi(detalhe.declarante?.ni)"
                    [nome]="detalhe.declarante?.nome">
                  </ni-com-link>
                </ng-container>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">CPF do Cônjuge</label>
                <ng-container *ngIf="detalhe.declarante?.cpfConjuge; else templateVazio;">
                  <ni-com-link [ni]="detalhe.declarante?.cpfConjuge" tipoNI="CPF"></ni-com-link>
                </ng-container>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">UA de Jurisdição</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.declarante?.uaJurisdicao?.codigo | vazio }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label class="label-formulario control-label">Inventariante</label>
                <ng-container *ngIf="detalhe.declarante?.cpfInventariante; else templateVazio;">
                  <ni-com-link
                    [ni]="detalhe.declarante?.cpfInventariante"
                    [nome]="detalhe.declarante?.nomeInventariante"
                    tipoNI="CPF">
                  </ni-com-link>
                </ng-container>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">Representante Legal</label>
                <ng-container *ngIf="detalhe.declarante?.cpfRepresentante; else templateVazio;">
                  <ni-com-link
                    [ni]="detalhe.declarante?.cpfRepresentante"
                    [nome]="detalhe.declarante?.nomeRepresentante"
                    tipoNI="CPF">
                  </ni-com-link>
                </ng-container>
              </div>
              <div class="col-md-4">
                <label class="label-formulario control-label">E-mail</label>
                <p class="form-control-static dado-formulario">
                  {{ detalhe.declarante?.email | vazio }}
                </p>
              </div>
            </div>

            <fieldset class="grupo background-inherit">
              <legend class="grupo-titulo text-nowrap">Telefones</legend>
              <ng-container *ngIf="detalhe.declarante?.telefones; else templateVazio;">
                <div class="table-responsive">
                  <table suiteRfbTable aria-hidden="true">
                    <tbody>
                      <ng-container *ngFor="let telefone of detalhe.declarante?.telefones">
                        <tr class="vertical-align-middle">
                          <td class="text-center">
                            {{ telefone.ddd | vazio }} {{ telefone.numero | vazio }}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </fieldset>

            <fieldset class="grupo background-inherit">
              <legend class="grupo-titulo text-nowrap">Endereço Correspondência</legend>
              <div class="grupo-conteudo">
                <div class="row">
                  <div class="col-md-8">
                    <label class="label-formulario control-label">Logradouro</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoCorrespondencia?.logradouro | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Bairro</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoCorrespondencia?.bairro | vazio }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Município</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoCorrespondencia?.municipio?.nome | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">UF</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoCorrespondencia?.municipio?.uf | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">CEP</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoCorrespondencia?.cep | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="grupo background-inherit">
              <legend class="grupo-titulo text-nowrap">Endereço Declarante</legend>
              <div class="grupo-conteudo">
                <div class="row">
                  <div class="col-md-8">
                    <label class="label-formulario control-label">Logradouro</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoDeclarante?.logradouro | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Bairro</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoDeclarante?.bairro | vazio }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">Município</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoDeclarante?.municipio?.nome | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">UF</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoDeclarante?.municipio?.uf | vazio }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label class="label-formulario control-label">CEP</label>
                    <p class="form-control-static dado-formulario">
                      {{ detalhe.declarante?.enderecoDeclarante?.cep | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="toggle">
          <div style="float: left;">
            <suite-rfb-toggle #declaracoesToggle></suite-rfb-toggle>
          </div>
          <div>Declarações</div>
        </div>
        <fieldset class="grupo background-inherit" *ngIf="declaracoesToggle.show">
          <div class="grupo-conteudo-nolegend">
            <div class="table-responsive" *ngIf="detalhe.declaracoes?.length > 0; else nenhumaDeclaracao;">
              <table suiteRfbTable>
                <thead>
                  <tr>
                    <th class="text-center min-width">Exercício</th>
                    <th class="text-center min-width">N° Declaração</th>
                    <th class="text-center min-width">Data Entrega</th>
                    <th class="text-center min-width">Tipo</th>
                    <th class="text-center">DRF Arquivamento</th>
                    <th class="text-center">Situação</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let declaracao of detalhe.declaracoes | currentPage: currentPage;">
                    <tr>
                      <td class="text-center">{{ declaracao.idDeclaracao?.exercicio | vazio }}</td>
                      <td class="text-center">{{ declaracao.idDeclaracao?.numeroDeclaracao | vazio }}</td>
                      <td class="text-center">{{ declaracao.dataEntrega | vazio }}</td>
                      <td class="text-center">{{ declaracao.tipo | vazio }}</td>
                      <td class="text-left text-nowrap">{{ declaracao.drfArquivamento | codigoDescricao | vazio }}</td>
                      <td class="text-left text-nowrap">{{ declaracao.situacao | vazio }}</td>
                    </tr>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr class="tabela-acoes text-left">
                    <td colspan="6">
                      <via-pagination [itemAmount]="detalhe.declaracoes?.length" [(page)]="currentPage"></via-pagination>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #templateVazio>
  <p>{{ '' | vazio }}</p>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>

<ng-template #nenhumaDeclaracao>
  <div class="row">
    <div class="col-md-12 text-center text-uppercase">
      Nenhuma declaração encontrada
    </div>
  </div>
</ng-template>
