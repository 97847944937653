<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!inscricoes && !initError; else tabelaInscricoes;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaInscricoes>
  <via-inscricao-pgfn-table
    [inscricoes]="inscricoes"
    [isPessoaJuridica]="isPessoaJuridica"
    [isPessoaFisica]="isPessoaFisica"
    [isAtivos]="isAtivos"
    [isSuspensos]="isSuspensos">
  </via-inscricao-pgfn-table>
</ng-template>
