<suite-rfb-collapse [title]="titulo">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <div class="table-responsive" *ngIf="operacoesSucessao && operacoesSucessao.length > 0">
    <table suiteRfbTable>
      <thead>
        <tr>
          <th class="text-center min-width">CNPJ</th>
          <th class="text-center">Razão Social</th>
          <th class="text-center min-width">Data</th>
          <th class="text-center">Operação</th>
        </tr>
      </thead>
      <tbody>
        <tr class="vertical-align-middle" *ngFor="let operacao of operacoesSucessao | currentPage: currentPage">
          <td class="text-center text-nowrap">
            <ni-com-link [ni]="operacao.cnpj" tipoNi="CNPJ"></ni-com-link>
          </td>
          <td class="text-left">
            {{ operacao.razaoSocial | vazio }}
          </td>
          <td class="text-center text-nowrap">
            {{ operacao.dataOperacao | date: 'shortDate' | vazio }}
          </td>
          <td class="text-left">
            {{ operacao.operacao | codigoDescricao | vazio }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="tabela-acoes text-left">
          <td colspan="4">
            <via-pagination [itemAmount]="operacoesSucessao.length" [(page)]="currentPage"></via-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
