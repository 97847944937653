import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedSuiteRfbModule } from 'src/app/shared/shared-suite-rfb.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DadosBasicosPFComponent } from './dados-basicos-pf.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CpfService } from '../../services/cpf.service';
import { TituloEleitorService } from '../titulo-eleitor/titulo-eleitor.service';

@NgModule({
  declarations: [
    DadosBasicosPFComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SharedSuiteRfbModule,
    TooltipModule,
  ],
  providers: [
    CpfService,
    TituloEleitorService,
  ],
  exports: [
    DadosBasicosPFComponent
  ]
})
export class DadosBasicosPFModule { }
