import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { Componente } from '../../models/pagamento';
import { ParametroComponenteDebito } from '../../models/parametros-pesquisa';

@Component({
  selector: 'via-ccpf-componentes-debito',
  templateUrl: 'componentes-debito.component.html',
  styleUrls: ['componentes-debito.component.css']
})
export class CcpfComponentesDebitoComponent implements OnInit {

  @Input() componentes: Componente[];
  @Input() cpfContribuinte: string;
  @Input() numeroDebito: string;
  @Input() atendimento: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  semComponente: boolean = false;

  parametroComponenteDebito: ParametroComponenteDebito;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  ngOnInit(): void {
    this.semComponente = isEmpty(this.componentes);
  }
}
