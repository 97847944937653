import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { TributoAcaoFiscal } from '../../model/tributo-acao-fiscal';

@Component({
  selector: 'via-acao-fiscal-table',
  templateUrl: './acao-fiscal-table.component.html',
  styleUrls: ['./acao-fiscal-table.component.css']
})
export class AcaoFiscalTableComponent {

  @Input() tributos: TributoAcaoFiscal[];

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
}
