<div class="container-fluid">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center" colspan="14">Exercícios Ausentes</th>
      </tr>
    </thead>
    <tbody>
      <tr class="vertical-align-middle" *ngFor="let ausencia of ausenciasPorAno | currentPage:currentPage;">
        <td class="text-center">{{ ausencia.ano }}</td>
        <td class="text-center" *ngFor="let periodo of ausencia.periodoOmissao">{{ periodo }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="14">
          <via-pagination [itemAmount]="ausenciasPorAno.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
