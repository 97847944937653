import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedSuiteRfbModule } from 'src/app/shared/shared-suite-rfb.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { InscricaoPgfnComponent, InscricaoPgfnPFAtivoComponent, InscricaoPgfnPFSuspensoComponent, InscricaoPgfnPJAtivoComponent, InscricaoPgfnPJSuspensoComponent } from './inscricao-pgfn.component';
import { InscricaoPgfnService } from './inscricao-pgfn.service';
import { InscricaoPgfnTableComponent } from './table/inscricao-pgfn-table.component';

@NgModule({
  declarations: [
    InscricaoPgfnTableComponent,
    InscricaoPgfnComponent,
    InscricaoPgfnPFAtivoComponent,
    InscricaoPgfnPFSuspensoComponent,
    InscricaoPgfnPJAtivoComponent,
    InscricaoPgfnPJSuspensoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SitfisPipesModule,
    SharedSuiteRfbModule,
    TooltipModule,
  ],
  providers: [
    InscricaoPgfnService
  ],
  entryComponents: [
    InscricaoPgfnComponent,
    InscricaoPgfnPFAtivoComponent,
    InscricaoPgfnPFSuspensoComponent,
    InscricaoPgfnPJAtivoComponent,
    InscricaoPgfnPJSuspensoComponent
  ]
})
export class InscricaoPgfnModule { }
