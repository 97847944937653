import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SituacaoFiscalPessoaFisicaDto } from '../model/situacao-fiscal-pf-dto';
import { SituacaoFiscalPessoaJuridicaDto } from '../model/situacao-fiscal-pj-dto';

@Injectable({
	providedIn: 'root'
})
export class SitFisService {

	private baseUrl = environment.APIEndpoint;

	constructor(private http: HttpClient) { }

	/**
	 * Obtem os dados da situacao fiscal dado um CPF e o protocolo do situacao fiscal
	 * @param cpf 
	 * @param numeroProtocolo 
	 */
	public obterSituacaoFiscalPessoaFisica(cpf: string, numeroProtocolo: string): Observable<SituacaoFiscalPessoaFisicaDto[]> {
		const url = `${this.baseUrl}/situacoesfiscais/pessoasfisicas/${cpf}`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		};
		return this.http.get<SituacaoFiscalPessoaFisicaDto[]>(url, options).pipe(take(1));
	}

	/**
	 * Obtem os dados da situacao fiscal de um CNPJ (e de suas adquiridas) a partir de um protocolo de analise fiscal.
	 * @param cnpj 
	 * @param numeroProtocolo 
	 */
	public obterSituacaoFiscalPessoaJuridica(cnpj: string, numeroProtocolo: string): Observable<SituacaoFiscalPessoaJuridicaDto[]> {
		const url = `${this.baseUrl}/situacoesfiscais/pessoasjuridicas/${cnpj}`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		};
		return this.http.get<SituacaoFiscalPessoaJuridicaDto[]>(url, options).pipe(take(1));
	}

}