<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div class="container-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Receita
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.componentes?.[0]?.receita | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Período de Apuração
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.periodoApuracao | formatDataPipe | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Vencimento
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.dataVencimentoNotificacao | formatDataPipe | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Exercício
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.exercicio | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Valor do Débito
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.valorOriginal | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Saldo Devedor
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.saldoDevedor | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Situação
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.situacao | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              {{ obterDescricaoProcessoOuParcelamento() }}
            </label>
            <p class="form-control-static dado-formulario">
              {{ credito.numeroProcesso | vazio }}
            </p>
          </div>
        </div>
      </div>
      <br>
      <ng-container *ngIf="(pagamentosObservable$ | async) as pagamentosAlocaveis; else loadingOrError;">
        <div class="table-responsive" *ngIf="pagamentosAlocaveis?.length > 0">
          <form #form="ngForm">
            <table suiteRfbTable>
              <caption>Pagamentos Disponíveis para Alocação</caption>
              <thead>
                <tr>
                  <th class="text-center min-width">Ações</th>
                  <th class="text-center min-width">
                    <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
                    </suite-rfb-toggle>
                  </th>
                  <th class="text-center min-width">Receita</th>
                  <th class="text-center min-width">Número</th>
                  <th class="text-center min-width break-spaces">Data de Arrecadação</th>
                  <th class="text-center min-width break-spaces">Data de Vencimento</th>
                  <ng-container *ngIf="!atendimento">
                    <th class="text-center">Valor Total</th>
                    <th class="text-center">Valor Utilizado</th>
                  </ng-container>
                  <th class="text-center">Valor Disponível</th>
                  <th class="text-center min-width">Valor a Alocar</th>
                  <th class="text-center">Situação</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let pagamento of pagamentosAlocaveis | currentPage: currentPage;">
                  <tr class="vertical-align-middle">
                    <td class="text-center">
                      <ng-container
                        *ngTemplateOutlet="templateAcoesPagamento; context: { pagamento: pagamento }">
                      </ng-container>
                    </td>
                    <td class="text-center">
                      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                    </td>
                    <td class="text-center">
                      <via-codigo-receita [codigo]="pagamento.codigoReceita"></via-codigo-receita>
                    </td>
                    <td class="text-center">
                      {{ pagamento.numeroPagamento | vazio }}
                    </td>
                    <td class="text-center">
                      {{ pagamento.dataArrecadacao | formatDataPipe | vazio }}
                    </td>
                    <td class="text-center">
                      {{ pagamento.dataVencimento | formatDataPipe | vazio }}
                    </td>
                    <ng-container *ngIf="!atendimento">
                      <td class="text-right">
                        {{ pagamento.valorTotal | formatCurrency | vazio }}
                      </td>
                      <td class="text-right">
                        {{ pagamento.valorUtilizado | formatCurrency | vazio }}
                      </td>
                    </ng-container>
                    <td class="text-right">
                      {{ pagamento.valorDisponivel | formatCurrency | vazio }}
                    </td>
                    <td class="text-center">
                      <input
                        [name]="pagamento.numeroPagamento"
                        class="numerico"
                        type="text"
                        currencyMask
                        [(ngModel)]="pagamento.valorParaAlocacao"
                        [options]="currencyMaskOptions"
                        style="max-width: 150px;">
                    </td>
                    <td class="text-left">
                      {{ pagamento.situacaoPagamento | vazio }}
                    </td>
                  </tr>

                  <ng-container *ngIf="rowToggle.show">
                    <ng-container
                      *ngTemplateOutlet="templateDetalhePagamento; context: { pagamento: pagamento }">
                    </ng-container>
                  </ng-container>
                </ng-container>
              </tbody>
              <tfoot>
                <tr class="tabela-acoes text-left">
                  <td colspan="11">
                    <via-pagination [itemAmount]="pagamentosAlocaveis.length" [(page)]="currentPage"></via-pagination>
                  </td>
                </tr>
              </tfoot>
            </table>
          </form>
        </div>
      </ng-container>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #templateAcoesPagamento let-pagamento="pagamento">
  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
  <ng-template #menuAcoes>
    <div class="btn-group-vertical" role="group">
      <button type="button" class="btn btn-default"
        [disabled]="isValorParaAlocacaoInvalido(pagamento.valorParaAlocacao)"
        (click)="onAlocarPagamento(pagamento); botaoAcoes.hide();">
        Alocar
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #templateDetalhePagamento let-pagamento="pagamento">
  <tr></tr>
  <tr>
    <td colspan="11">
      <div class="contorno">
        <div class="row" *ngIf="atendimento">
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Total</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorTotal | formatCurrency | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Utilizado</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorUtilizado | formatCurrency | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="label-formulario control-label">Banco/Agência</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.banco | vazio }} / {{ pagamento.agencia | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Bloqueado</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorBloqueado | formatCurrency }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Reservado</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorReservado | formatCurrency }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Alocação Automática</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorAlocacoesAutomaticas | formatCurrency }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Valor Alocação Manual</label>
            <p class="form-control-static dado-formulario">
              {{ pagamento.valorAlocacoesManuais | formatCurrency }}
            </p>
          </div>
        </div>

        <via-ccpf-aloca-pagamento-detalhe-alocacoes
          [atendimento]="atendimento"
          [alocacoes]="alocacoesPorPagamento.get(pagamento.numeroPagamento)">
        </via-ccpf-aloca-pagamento-detalhe-alocacoes>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
