import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DebitoContaCorrenteItr } from './model/debito-conta-corrente-itr';

@Injectable()
export class ContaCorrenteItrService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDebitosContaCorrenteItrAtivos(ni: string, numeroProtocolo: string): Observable<DebitoContaCorrenteItr[]> {
    const url = `${this.baseUrl}/situacoesfiscais/ccitr/${ni}/Cobranca`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoContaCorrenteItr[]>(url, options);
  }

  public obterDebitosContaCorrenteItrSuspensos(ni: string, numeroProtocolo: string): Observable<DebitoContaCorrenteItr[]> {
    const url = `${this.baseUrl}/situacoesfiscais/ccitr/${ni}/Exigibilidade`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<DebitoContaCorrenteItr[]>(url, options);
  }
}
