import { Component, Input } from '@angular/core';
import { DetalheEventoPj } from '../../models/detalhe-evento-pj';

@Component({
  selector: 'via-detalhe-evento-pj',
  templateUrl: './detalhe-evento-pj.component.html',
  styleUrls: ['../eventos-pj.component.css']
})
export class DetalheEventoPjComponent {

  @Input() detalhe: DetalheEventoPj;
}
