<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <div [ngClass]="{ 'modal-container': !atendimento }">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <div class="container-fluid">

    </div>
  </div>

<ng-container *ngIf="compensacoes && !initError; else loadingOrError;">


  <table suiteRfbTable>
    <ng-container *ngIf="!stopLoading">
      <div class="container-fluid" *ngFor="let exercicio of exercicios | currentPage: currentPage;">
        <div class="exercicio">
          <div class="exercicio-toggle" style="float:left">
            <suite-rfb-toggle #rowToggleExercicio></suite-rfb-toggle>
          </div>
          <div class="exercicio-titulo">{{ exercicio }}</div>
        </div>
        <ng-container *ngIf="rowToggleExercicio.show">
          <div class="exercicio-conteudo">
            <div class="table-responsive">
              <table suiteRfbTable>
                <thead>
                  <tr>
                    <th class="text-center min-width">
                      <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
                      </suite-rfb-toggle>
                    </th>
                    <th class="text-center min-width">Número</th>
                    <th class="text-center">Descrição</th>
                    <th class="text-center min-width">No. Direito Creditório</th>
                    <th class="text-center min-width">Valor Direito Creditório</th>
                    <th class="text-center min-width">Valor Amortizado Receita</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let compensacao of compensacoes[exercicio]"
                    class="vertical-align-middle">
                    <tr class="vertical-align-middle">
                      <td>
                        <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                      </td>
                      <td class="text-right">{{ compensacao.numeroComponenteCt | vazio }}</td>
                      <td class="text-left">{{ compensacao.descricaoEventoCompensacao | vazio }}</td>
                      <td class="text-right">{{ compensacao.numeroDireitoCreditorio | vazio }}</td>
                      <td class="text-right">{{ compensacao.valorDireitoCreditorio | formatCurrency }}</td>
                      <td class="text-right">{{ compensacao.valorUtilizadoReceita | formatCurrency}}</td>
                    </tr>
                    <ng-container *ngIf="rowToggle.show">
                      <tr></tr>
                      <tr>
                        <td colspan="6">
                          <div class="contorno-ccitr">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="label-formulario control-label">
                                  Número Processo
                                </label>
                                <p class="form-control-static dado-formulario">
                                  {{ compensacao.numeroProcesso | vazio }}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <label class="label-formulario control-label">
                                  Evento de Compensação
                                </label>
                                <p class="form-control-static dado-formulario">
                                  {{ compensacao.dataEventoCompensacao | formatDataPipe }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
                <tfoot>
                  <tr class="tabela-acoes text-left">
                    <td colspan="7">
                      <via-pagination [itemAmount]="compensacoes[exercicio].length" [(page)]="currentPage">
                      </via-pagination>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </table>

</ng-container>
</suite-rfb-collapse>


<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>

