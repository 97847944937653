<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <div class="container-fluid form-height">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <form [formGroup]="filtrosForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <label class="control-label faixa-valor-label">Período:</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-data-arrecadacao">
              <div class="form-inline">
                <label class="control-label data-arrecadacao-label-separador">De</label>
                <div class="form-group form-group-data-arrecadacao">
                  <suite-rfb-date-picker
                    direction="right"
                    [outsideClick]="true"
                    adaptivePosition="true"
                    formControlName="dataInicial"
                    >
                  </suite-rfb-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-data-arrecadacao">
              <div class="form-inline">
                <label class="control-label data-arrecadacao-label-separador">a</label>
                <div class="form-group form-group-data-arrecadacao">
                  <suite-rfb-date-picker
                    direction="right"
                    [outsideClick]="true"
                    adaptivePosition="true"
                    formControlName="dataFinal"
                    >
                  </suite-rfb-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <button class="btn btn-default" type="reset" (click)="onReset()" [disabled]="!filtrosForm.dirty">Limpar</button>
            <button class="btn btn-primary" type="submit" [disabled]="!filtrosForm.valid">Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>
<via-consulta-eventos *ngIf="exibeResultado" [parametros]="parametros" [atendimento]="true" (onError)="esconderResultado()"></via-consulta-eventos>

