<ng-container *ngIf="feature.visible && feature.children?.length <= 0">
  <a class="menu-item" href="javascript:void(0)" (click)="onClickFeature(feature)">
    <ng-container *ngTemplateOutlet="itemTemplate; context: { feature: feature }"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="feature.visible && feature.children?.length > 0">
  <a
    class="menu-item raiz"
    href="javascript:void(0)"
    [attr.aria-expanded]="!collapsed"
    (click)="collapsed = !collapsed">
    {{ feature.title }}
  </a>

  <ul class="menu nav-second-level collapse" [attr.aria-expanded]="!collapsed" [class.in]="!collapsed">
    <ng-container *ngFor="let childFeature of feature.children">
      <li *ngIf="childFeature.visible">
        <via-menu-item [feature]="childFeature"></via-menu-item>
      </li>
    </ng-container>
  </ul>
</ng-container>

<ng-template #itemTemplate let-feature="feature">
  <span>
    {{ feature.title }}
    <ng-container *ngIf="feature.shortcut">
      &nbsp;({{ feature.shortcut }})
    </ng-container>
  </span>
</ng-template>
