import { Component, Input } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { GrupoDebitoAguia } from 'src/app/sitfis/model/debitoAguia';

@Component({
  selector: 'via-aguia-table',
  templateUrl: './aguia-table.component.html',
  styleUrls: ['./aguia-table.component.css']
})
export class AguiaTableComponent {

    @Input() grupoDebito: GrupoDebitoAguia;
    tipoPA = 5;
    
    pageSize: number = 10;
    currentPage: Page = {
        number: 1,
        first: 0,
        last: this.pageSize
    };
    
}