<table suiteRfbTable class="tabelaInterna" [class.tabela_largura_minima]="this.atendimento">
  <caption class="caption-ccpf">Componentes</caption>
  <ng-container *ngIf="!semComponente; else nenhumComponente;">
    <thead>
      <tr>
        <th class="text-center min-width" *ngIf="exibirAcoes" rowspan="2">Ações</th>
        <th class="text-center min-width" rowspan="2">Número</th>
        <th class="text-center min-width" rowspan="2">Data de Vencimento</th>
        <th class="text-center min-width" rowspan="2">Valor Original</th>
        <th class="text-center" rowspan="2">Situação</th>
        <th class="text-center" colspan="3">Valores em Aberto</th>
      </tr>
      <tr>
        <th class="text-center">Receita</th>
        <th class="text-center">Multa</th>
        <th class="text-center">Juros</th>
      </tr>
    </thead>
    <tbody>

      <ng-container *ngFor="let componente of componentes">
        <tr class="vertical-align-middle">
          <td class="text-center" *ngIf="exibirAcoes">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                 <button class="btn btn-default" type="button" (click)="alocarPagamento(componente); botaoAcoes.hide();">Alocar Pagamento</button>
              </div>
            </ng-template>
          </td>
          <td class="text-center">{{ componente.numero | vazio }}</td>
          <td class="text-center">{{ componente.dataVencimento | formatDataPipe | vazio }}</td>
          <td class="text-right">{{ componente.valorOriginal | formatCurrency | vazio }}</td>
          <td class="text-left">{{ componente.situacao | vazio }}</td>
          <td class="text-right">{{ componente.valorEmAbertoReceita| formatCurrency | vazio }}</td>
          <td class="text-right">{{ componente.valorEmAbertoMulta | formatCurrency | vazio }}</td>
          <td class="text-right">{{ componente.valorEmAbertoJuros | formatCurrency | vazio }}</td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
  <ng-template #nenhumComponente>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum componente encontrado para este crédito tributário.
      </td>
    </tr>
  </ng-template>
</table>
