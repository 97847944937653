/*
 * ngx-suite-rfb
 *
 * A biblioteca ngx-suite-rfb implementa um conjunto de componentes,
 * diretivas, pipes e serviços nativos do Angular com o objetivo de
 * promover o reuso e facilitar a implementação de aplicações frontend
 * que serão implantandas na Suíte RFB e, portanto, devem seguir os
 * Padrões de Interface Visual RFB.
 *
 * Documentação disponível em: https://dedat.gitpages.serpro/deat3/ngx-suite-rfb/
 *
 */

import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertMessage, AlertMessages, AlertMessageService, WindowService } from '@serpro/ngx-suite-rfb';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'suite-rfb-alert-messages-with-datetime',
  templateUrl: './alert-messages-with-datetime.component.html',
  styleUrls: ['./alert-messages-with-datetime.component.css'],
})

export class AlertMessagesWithDatetimeComponent implements DoCheck, OnInit, OnDestroy  {

  @Input() onlyFor: string;
  @Input() modal: boolean;

  messages: AlertMessages = [];
  lastMessages: string = "";

  public datetime = new Date().toLocaleString('pt-br');
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private alertMessageService: AlertMessageService,
    private windowService: WindowService,
  ) {
  }
  
  public ngOnInit(): void {
    this.alertMessageService.getMessages$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messages: AlertMessages) => {
        let mensagensLocais = this.messages.length;
        this.messages = messages.filter(message =>
          this.onlyFor === message.target
        );
        if ( mensagensLocais < this.messages.length){
          setTimeout(()=> (document.getElementById(this.onlyFor).parentNode.parentNode as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest'}),300);
        };
      });
  }

  private isVisible(elemento): boolean {
    var rect = elemento.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public dismiss(message: AlertMessage): void {
    this.alertMessageService.dismiss(message);
  }

  public showMore(message: AlertMessage): void {
    this.alertMessageService.showMore(message);
  }

  private get window(): Window {
    return this.windowService.get();
  }

  public ngDoCheck() {
    this.checkDateTime();
  }

  private checkDateTime() {
    if (this.messagesToString() != this.lastMessages) {
      this.datetime = new Date().toLocaleString('pt-br');
      // save the current error messages to a string to compare later (check changes)
      // just to prevent changing error message datetime without changing message error
      this.lastMessages = this.messagesToString();
    }
  }

  private messagesToString(): string {
    // returns a string with all messages
    let msgs: string = "";
    this.messages.forEach( (message) => {
      msgs = msgs + "#" + message.title + "#" + message.description;
    });
    return msgs;
  }
}
