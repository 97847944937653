<suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
  <div class="container-fluid">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Número do Pagamento</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="numeroPagamentoName"
              suiteRfbNumbersOnly>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Número do Documento</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="numeroDocumentoName"
              suiteRfbNumbersOnly>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Número de Referência</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="numeroReferenciaName"
              suiteRfbNumbersOnly>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <label class="control-label">Data de Arrecadação</label>
            </div>

            <div class="col-md-6">
              <label class="control-label faixa-valor-label">Faixa de Valor</label>
            </div>
          </div>

          <div class="col-md-6 col-data-arrecadacao">
            <div class="form-inline">
              <div class="form-group form-group-data-arrecadacao">
                <suite-rfb-date-picker
                  adaptivePosition="true"
                  [formControlName]="dataArrecadacaoInicioName">
                </suite-rfb-date-picker>
              </div>

              <label class="control-label data-arrecadacao-label-separador">a</label>

              <div class="form-group form-group-data-arrecadacao">
                <suite-rfb-date-picker
                  adaptivePosition="true"
                  [formControlName]="dataArrecadacaoFimName">
                </suite-rfb-date-picker>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-faixa-valor">
            <div class="form-inline">
              <div class="form-group form-group-faixa-valor">
                <input
                  class="form-control numerico"
                  type="text"
                  [formControlName]="faixaInicioName"
                  currencyMask
                  [options]="currencyMaskOptions">
              </div>

              <label class="control-label faixa-valor-label-separador">a</label>

              <div class="form-group form-group-faixa-valor">
                <input
                  class="form-control numerico"
                  type="text"
                  [formControlName]="faixaFimName"
                  currencyMask
                  [options]="currencyMaskOptions">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">CPF (sem DV)</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="cpfSemDvName"
              suiteRfbInputMask="999.999.999">
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">CNPJ (sem DV)</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="cnpjSemDvName"
              suiteRfbInputMask="99.999.999/9999">
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <div class="text-center">
              <label class="control-label checkbox-inline" style="margin-top: 25px;">
                <input class="checkbox" type="checkbox" [formControlName]="niInvalidoName">
                <span>NI Inválido</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Banco</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="bancoName"
              suiteRfbNumbersOnly>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label class="control-label">Agência</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="agenciaName"
              suiteRfbNumbersOnly>
          </div>
        </div>

        <div class="col-md-offset-2 col-md-3">
          <div class="form-group">
            <label class="control-label">Receita</label>
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              [formControlName]="receitaName"
              suiteRfbNumbersOnly>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <suite-rfb-select label="Natureza do Documento"
              [labels]="naturezasDocumentoArrecadacao | naturezasDocArrecadacaoPipe"
              [values]="naturezasDocumentoArrecadacao"
              [formControlName]="naturezaDocumentoName">
            </suite-rfb-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Nível de Pesquisa</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-inline">
              <div class="form-group">
                <label class="radio-inline">
                  <input type="radio" [formControlName]="tipoPesquisaName" [value]="tipoPesquisaDelegacia">
                  <span>Delegacia</span>
                </label>
                <input
                  class="input-text-pesquisa"
                  type="text"
                  autocomplete="off"
                  maxlength="5"
                  [formControlName]="pesquisaDelegaciaCodigoName"
                  suiteRfbNumbersOnly>
              </div>

              <div class="form-group">
                <label class="radio-inline">
                  <input type="radio" [formControlName]="tipoPesquisaName" [value]="tipoPesquisaRegiao">
                  <span>Regional</span>
                </label>
                <input
                  class="input-text-pesquisa"
                  type="text"
                  autocomplete="off"
                  maxlength="2"
                  [formControlName]="pesquisaRegiaoCodigoName"
                  suiteRfbNumbersOnly>
              </div>

              <div class="form-group">
                <label class="radio-inline">
                  <input type="radio" [formControlName]="tipoPesquisaName" [value]="tipoPesquisaBrasil">
                  <span>Brasil</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <button class="btn btn-default" type="button" (click)="limparFormulario()">Limpar</button>
            <button class="btn btn-primary" type="submit">Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</suite-rfb-collapse>

<via-consulta-pagamentos *ngIf="exibeResultado"
  [parametrosPesquisa]="parametros"
  [atendimento]="true">
</via-consulta-pagamentos>
