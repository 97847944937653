import { Component } from "@angular/core";
import { OmissosService } from "src/app/cpf/components/omissos/services/omissos.service";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

@Component({
  selector: 'via-declaracao-ausente-dirpf-table',
  templateUrl: 'declaracao-ausente-dirpf-table.component.html',
  styleUrls: [
    'declaracao-ausente-dirpf-table.component.css',
    'declaracao-ausente-table.component.css'
  ]
})
export class DeclaracaoAusenteDirpfTableComponent
  extends DeclaracaoAusenteTableComponent {

  constructor(private omissosService: OmissosService) { super(); }

  agruparMotivos(motivos: string[]): string {
    return motivos?.filter(m => m.trim().length > 0)?.join(', ');
  }

  onConsultarOmissosEntregaDeclaracao(): void {
    window.open(this.omissosService.getUrlPortalIrpf(this.ni), "_blank");
  }
}
