<div class="table-responsive">
  <table suiteRfbTable>
    <caption class="table-group-caption">Declarações DIRPF</caption>
    <thead>
      <tr>
        <th class="text-center min-width">Extrato</th>
        <th class="text-center">Exercício</th>
        <th class="text-center">Data Entrega</th>
        <th class="text-center">Recibo</th>
        <th class="text-center">Modelo</th>
        <th class="text-center">Tipo</th>
        <th class="text-center">DRF de Arquivamento</th>
        <th class="text-center">ND Declaração</th>
        <th class="text-center">Situação</th>        
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of dirpfs | currentPage:currentPage; index as linha" class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button #bt_extrato
                  type="button"
                  class="btn btn-default"
                  (click)="onClickExtrato(item.anoExercicio,item.numero);">
                  Extrato
                </button>
              </div>
            </ng-template>            
          </td>
          <td class="text-center">{{ item.anoExercicio }}</td>
          <td class="text-center">{{ item.dataHoraEntrega | vazio }}</td>
          <td class="text-center">{{ item.reciboDeclaracao }}</td>
          <td class="text-center">{{ item.modelo }}</td>
          <td class="text-center">{{ item.tipo }}</td>          
          <td class="text-center">{{ item.codigoDRF + ' - ' + item.nomeDRF }}</td>
          <td class="text-center">{{ item.numero }}</td>          
          <td class="text-center">{{ item.situacao }}</td>                    
       </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="9">
          <via-pagination [itemAmount]="dirpfs.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
