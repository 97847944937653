import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DebitoSief } from 'src/app/sitfis/model/debito-sief';
import { environment } from 'src/environments/environment';

@Injectable()
export class DebitoProcessoSiefService {

  private baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterDebitosProcesso(ni: string, identificadorProcesso: string, numeroProtocolo: string, isCobranca: boolean): Observable<DebitoSief[]> {
    const url = `${this.baseUrl}/situacoesfiscais/sief/processos/debitos/${ni}/${identificadorProcesso}/${isCobranca ? 'Cobranca' : 'Exigibilidade'}`;

    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };

    return this.httpClient.get<DebitoSief[]>(url, options);
  }
}
