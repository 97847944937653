<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Código Receita</th>
        <th class="text-center">Tributos</th>
        <th class="text-center min-width">Período de Apuração Inicial</th>
        <th class="text-center min-width">Período de Apuração Final</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let tributo of tributos | currentPage: currentPage;" class="vertical-align-middle">
        <tr>
          <td class="text-center">
            <via-codigo-receita [codigo]="tributo.codigoTributo"></via-codigo-receita>
          </td>
          <td class="text-left text-nowrap">{{ tributo.nomeTributo | vazio }}</td>
          <td class="text-center">{{ tributo.anoMesInicio | vazio }}</td>
          <td class="text-center">{{ tributo.anoMesFim | vazio }}</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="tributos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
