export enum TipoPA {
  DIARIO = "DIÁRIO",
  SEMANAL = "SEMANAL",
  DECENDIAL = "DECENDIAL",
  QUINZENAL = "QUINZENAL",
  MENSAL = "MENSAL",
  BIMENSAL = "BIMENSAL",
  TRIMESTRAL = "TRIMESTRAL",
  SEMESTRAL = "SEMESTRAL",
  ANUAL = "ANUAL",
}
