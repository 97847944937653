import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DoiDetalheComponent } from './components/detalhe/doi-detalhe.component';
import { DoiComponent } from './components/doi.component';
import { DoiTableComponent } from './components/table/doi-table.component';
import { DoiSuiteRfbModule } from './doi-suite-rfb.module';
import { DoiService } from './services/doi.service';

@NgModule({
  declarations: [
    DoiComponent,
    DoiTableComponent,
    DoiDetalheComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    DoiSuiteRfbModule
  ],
  exports: [
    DoiComponent
  ],
  providers: [
    DoiService
  ]
})
export class DoiModule { }
