<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">NI</th>
        <th class="text-center min-width">Número do Parcelamento</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parcelamento of parcelamentos | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <ni-com-link [ni]="parcelamento.ni" [tipoNI]="parcelamento.tipoNI"></ni-com-link>
          </td>
          <td class="text-center text-nowrap">{{ parcelamento.numeroParcelamento }}</td>
          <td class="text-left">{{ parcelamento.situacao }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="4">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-3">
                    <label class="label-formulario control-label">
                      Prestações em atraso
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.quantidadePrestacoesAtraso }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">
                      Valor do Atraso
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.valorAtraso | formatCurrency }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">
                      Valor Suspenso
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.valorSuspenso | formatCurrency }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">
                      Modalidade
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.modalidade }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
