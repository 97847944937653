import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { CCPFService } from '../cobranca/components/ccpf/services/ccpf.service';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedSuiteRfbModule } from '../shared/shared-suite-rfb.module';
import { SharedModule } from '../shared/shared.module';
import { SitfisPipesModule } from '../sitfis/pipes/sitfis-pipes.module';
import { ComprovantePagamentoVisualizacaoComponent } from './components/comprovante-visualizacao/comprovante-visualizacao.component';
import { ConsultaPagamentosComponent } from './components/consulta-pagamentos/consulta-pagamentos.component';
import { FiltrosPesquisaPagamentoComponent } from './components/filtros-pesquisa/filtros-pesquisa-pagamento.component';
import { RetificacaoPagamentoComponent } from './components/retificacao/retificacao-pagamento.component';
import { TrocaAssinalamentoPagamentoComponent } from './components/troca-assinalamento/troca-assinalamento-pagamento.component';
import { PagamentoSuiteRfbModule } from './pagamento-suite-rfb.module';
import { NaturezasDocArrecadacaoPipe } from './pipes/naturezas-doc-arrecadacao.pipe';
import { SistemasInteressePipe } from './pipes/sistemas-interesse.pipe';
import { PagamentoComprovanteService } from './services/pagamento-comprovante.service';
import { PagamentoService } from './services/pagamento.service';
import { DarfDetalhesComponent } from './components/consulta-pagamentos/darf-detalhes/darf-detalhes.components';
import { DasDetalhesComponent } from './components/consulta-pagamentos/das-detalhes/das-detalhes.components';
import { DjeDetalhesComponent } from './components/consulta-pagamentos/dje-detalhes/dje-detalhes.components';
import { ConsultaComponentesCCPFComponent } from './components/consulta-pagamentos/darf-detalhes/aloca-pagamento/table-componentes/consulta-componentes-ccpf-table..component';

@NgModule({
  declarations: [
    FiltrosPesquisaPagamentoComponent,
    ConsultaPagamentosComponent,
    DarfDetalhesComponent,
    DasDetalhesComponent,
    DjeDetalhesComponent,
    NaturezasDocArrecadacaoPipe,
    RetificacaoPagamentoComponent,
    TrocaAssinalamentoPagamentoComponent,
    SistemasInteressePipe,
    ComprovantePagamentoVisualizacaoComponent,
    ConsultaComponentesCCPFComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedSuiteRfbModule,
    SharedModule,
    PagamentoSuiteRfbModule,
    PipesModule,
    SitfisPipesModule,
    NgxMaskModule,
    NgxCurrencyModule,
    PagamentoSuiteRfbModule
  ],
  exports: [
    FiltrosPesquisaPagamentoComponent,
    ConsultaPagamentosComponent
  ],
  providers: [
    PagamentoService,
    PagamentoComprovanteService,
    CCPFService,
    MaskPipe,
  ]
})
export class PagamentoModule { }
