import { Component, OnInit } from "@angular/core";
import _ from "lodash";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

@Component({
  selector: 'via-declaracao-ausente-defis-table',
  templateUrl: 'declaracao-ausente-defis-table.component.html',
  styleUrls: ['declaracao-ausente-table.component.css']
})
export class DeclaracaoAusenteDefisTableComponent
  extends DeclaracaoAusenteTableComponent
  implements OnInit {

  periodosOmissao: string[];

  ngOnInit(): void {
    this.periodosOmissao = _.flatten(this.ausenciasDeclaracao.map(ausencia => ausencia.periodoApuracaoOmissao));
  }
}
