<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Exercícios Ausentes</th>
        <th class="text-center">Motivos</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ausencia of ausenciasDeclaracao | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center">
            {{ ausencia.periodoApuracaoOmissao[0] | vazio }}
          </td>
          <td class="class-center">
            {{ agruparMotivos(ausencia.motivoOmissao) | vazio }}
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="2">
          <via-pagination [itemAmount]="ausenciasDeclaracao.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>

  <button type="button" class="btn btn-default" (click)="onConsultarOmissosEntregaDeclaracao()">
    Omissos Pessoa Física
  </button>
</div>
