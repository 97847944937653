<header class="header">
  <suite-rfb-header appName="VIA " appDescription="Visão Integrada do Atendimento"></suite-rfb-header>
  <div class="container">
    <nav class="navegacao via-navegacao">
      <via-menu-toggler></via-menu-toggler>
      <via-entrada-ni></via-entrada-ni>
      <div class="sidebar">
        <div class="menu-lateral">
          <via-menu></via-menu>
        </div>
      </div>
    </nav>
    <suite-rfb-linked-features></suite-rfb-linked-features>
  </div>
</header>
<article>
  <div class="container" [hidden]="pdfBase64">
    <suite-rfb-alert-messages-with-datetime></suite-rfb-alert-messages-with-datetime>
    <via-painel-atendimentos></via-painel-atendimentos>
  </div>

  <ng-container #pdfContainer *ngIf="pdfBase64">
    <ngx-extended-pdf-viewer #pdfViewer
      [customToolbar]="toolbarButtons"
      [textLayer]="true"
      [showHandToolButton]="true"
      [contextMenuAllowed]="true"
      [showBookmarkButton]="true"
      [showBorders]="false"
      [showDownloadButton]="true"
      [showFindButton]="true"
      [showHandToolButton]="true"
      [showOpenFileButton]="false"
      [showPagingButtons]="true"
      [showPresentationModeButton]="true"
      [showPrintButton]="true"
      [showPropertiesButton]="true"
      [showRotateButton]="false"
      [showScrollingButton]="true"
      [showSecondaryToolbarButton]="true"
      [showSidebarButton]="true"
      [showSpreadButton]="false"
      [showToolbar]="true"
      [showZoomButtons]="true"
      [(zoom)]="pdfZoom"
      [base64Src]="pdfBase64"
      useBrowserLocale="true"
      pageViewMode="single"
      [filenameForDownload]="pdfFilename">
    </ngx-extended-pdf-viewer>

    <ng-template #toolbarButtons>
      <div id="toolbarViewer">
        <div id="toolbarViewerLeft">
          <pdf-toggle-sidebar></pdf-toggle-sidebar>
          <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
          <pdf-paging-area></pdf-paging-area>
        </div>
        <div id="toolbarViewerRight">
          <pdf-presentation-mode></pdf-presentation-mode>
          <pdf-print></pdf-print>
          <pdf-download></pdf-download>
          <pdf-toggle-secondary-toolbar></pdf-toggle-secondary-toolbar>
          <button
            type="button"
            class="toolbarButton new-tab hiddenMediumView"
            title="Fechar a visualização do relatório e voltar para o atendimento"
            (click)="onClose()">
            <img src="../assets/img/close.png">
          </button>
        </div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
      </div>
    </ng-template>
  </ng-container>
</article>

<suite-rfb-footer text="Ministério da Fazenda - Secretaria Especial da Receita Federal do Brasil | Esplanada dos Ministérios, Bloco P, Brasília - DF"></suite-rfb-footer>
<p class="footer versao">{{ textoRodape }}</p>
<suite-rfb-loading></suite-rfb-loading>
