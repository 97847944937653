import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsultarReceitaComponent } from 'src/app/sief/receitas/consultar-receita.component';
import { NumberUtilService } from '../util/number-util.service';

@Component({
  selector: 'via-codigo-receita',
  templateUrl: './codigo-receita.component.html',
  styleUrls: ['./codigo-receita.component.css']
})
export class CodigoReceitaComponent implements OnInit {

  @Input() codigo: string;
  @Input() nome: string;

  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private numberUtilService: NumberUtilService) { }

  ngOnInit(): void {
    this.codigo = this.numberUtilService.leftZeroes(this.codigo, 4);
  }

  public consultarCodigoReceita() {
    const initialState = {
      codigo: this.codigo
    };
    this.bsModalRef = this.modalService.show(ConsultarReceitaComponent, { initialState });
  }
}
