<suite-rfb-collapse [title]="titulo" [collapsed]="false">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">Receita</label>
        <p class="form-control-static dado-formulario">
          <via-codigo-receita [codigo]="credito.componentes?.[0].receita"></via-codigo-receita>
        </p>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">Período de Apuração</label>
        <p class="form-control-static dado-formulario">
          {{ credito.periodoApuracao | date: 'dd/MM/yyyy' | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">Data de Vencimento</label>
        <p class="form-control-static dado-formulario">
          {{ credito.dataVencimentoNotificacao | outputMask: '9999-99-99' | date: 'shortDate' | vazio }}
        </p>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">Exercício</label>
        <p class="form-control-static dado-formulario">
          {{ credito.exercicio | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">Valor do Débito</label>
        <p class="form-control-static dado-formulario">
          {{ credito.valorOriginal | formatCurrency | vazio }}
        </p>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">Saldo Devedor</label>
        <p class="form-control-static dado-formulario">
          {{ credito.saldoDevedor | formatCurrency | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <label class="label-formulario control-label">Situação</label>
        <p class="form-control-static dado-formulario">
          {{ credito.situacao | vazio }}
        </p>
      </div>
      <div class="col-md-6">
        <label class="label-formulario control-label">Número do Processo</label>
        <p class="form-control-static dado-formulario">
          {{ credito.numeroProcesso | vazio }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <suite-rfb-date-picker
                  label="Nova Data"
                  direction="up"
                  adaptativePosition="true"
                  outsideClick="true"
                  [formControlName]="dataCtName">
                </suite-rfb-date-picker>
              </div>
            </div>

            <div class="col-md-7" style="margin-top: 25px;">
              <div class="form-group">
                <div class="text-center">
                  <button
                    type="reset"
                    class="btn btn-default"
                    [disabled]="!formGroup.dirty">
                    Limpar
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="formGroup.invalid"
                    (click)="onRevalidarDataCt()">
                    Revalidar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</suite-rfb-collapse>
