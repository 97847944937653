<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <ng-container *ngIf="creditos; else loadingOrError;">
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center min-width">
                <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
              </th>
              <th class="text-center min-width">CT</th>
              <th class="text-center min-width">Receita</th>
              <th class="text-center min-width">Data Vencimento</th>
              <th class="text-center">Valor Original</th>
              <th class="text-center">Saldo Receita</th>
              <th class="text-center">Situação</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let credito of creditos | currentPage: currentPage;">
              <tr class="vertical-align-middle">
                <td>
                  <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>
                <td class="text-center">{{ credito.numeroCt | vazio }}</td>
                <td class="text-center">{{ credito.receita | vazio }}</td>
                <td class="text-center">{{ credito.dataVencimento | formatDataPipe | vazio }}</td>
                <td class="text-right">{{ credito.valorOriginal | formatCurrency }}</td>
                <td class="text-right">{{ credito.saldoReceita | formatCurrency }}</td>
                <td class="text-left">{{ credito.situacao | vazio }}</td>
              </tr>
              <ng-container *ngIf="rowToggle.show">
                <tr></tr>
                <tr>
                  <td colspan="7">
                    <div class="contorno">
                      <div class="row">
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            NI Declarante
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.niDeclarante | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Número da PERDCOMP
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.numeroPerdComp | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Processo Transferência
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.processoTransferencia | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Tipo de Lançamento
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.tipoLancamento | vazio }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Indicador de TDA
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.indicadorTDA | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Compensação
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.compensacao | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Saldo Multa
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.saldoMulta | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Saldo Juros
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.saldoJuros | vazio }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Unidade Monetária
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.unidadeMonetaria | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Origem
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.origem | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Natureza
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.natureza | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Ano Numeração
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.anoNumeracao | vazio }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Número da Declaração
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.numeroDeclaracao | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Data Recepção Declaração
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.dataRecepcaoDeclaracao | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Data Ciência AR
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.dataCienciaAR | vazio }}
                          </p>
                        </div>
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Data Período Apuração
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ credito.dataPeriodoApuracao | vazio }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <via-ccitr-alocacao-debito-componentes
                            [credito]="credito"
                            (alocarDebito)="alocarDebito($event, credito)">
                          </via-ccitr-alocacao-debito-componentes>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <via-ccitr-table-pagamentos
                            [credito]="credito"
                            [pagamentos]="credito.pagamentos"
                            [exibirAcoes]="false"
                            [nirf]="parametrosPesquisa.nirf"
                            [numeroCT]="credito.numeroCt"
                            [ni]="credito.niDeclarante"
                            [parametrosPesquisa]="parametrosPesquisa"
                            [atendimento]="atendimento">
                          </via-ccitr-table-pagamentos>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
