import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RelatorioApoioCndSuiteRfbModule } from './relatorio-apoio-cnd-suite-rfb.module';
import { RelatorioApoioCndComponent } from './relatorio-apoio-cnd.component';
import { RelatorioApoioCndService } from './relatorio-apoio-cnd.service';

@NgModule({
  declarations: [
    RelatorioApoioCndComponent
  ],
  imports: [
    CommonModule,
    RelatorioApoioCndSuiteRfbModule

  ],
  providers: [
    RelatorioApoioCndService
  ]
})
export class RelatorioApoioCndModule { }