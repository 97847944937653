import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { ComprotProcessos } from '../model/comprot-processos';
import { ComprotService } from '../services/comprot.service';

@Component({
  selector: 'via-comprot',
  templateUrl: './comprot.component.html',
  styleUrls: ['./comprot.component.css'],
  providers: [ScopedMessageService]
})
export class ComprotComponent implements OnInit {

  @Input() cpf: string;

  @Input() cnpj: string;

  ni: string;

  comprotProcessos: ComprotProcessos;

  scopeId: string;

  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  constructor(
    private comprotService: ComprotService,
    private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    if (null == this.cpf) {
      this.ni = this.cnpj;
    } else {
      this.ni = this.cpf;
    }
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterProcessosComprot();
    }
  }

  private obterProcessosComprot(): void {
    this.executouObterDados = true;
    this.comprotService.obterProcessosComprot(this.ni)
      .subscribe(
        response => {
          this.comprotProcessos = response;

          if (this.comprotProcessos && isEmpty(this.comprotProcessos.processos)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
            this.stopLoading = true;
          }
        },
        error => {
          this.messageService.showErrorException("Ocorreu um erro durante a consulta ao COMPROT", error);
          this.initError = error;
        }
      );
  }
}
