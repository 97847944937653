import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Darf } from "../../model/darf";

@Component({
    selector: 'app-darf-visualizacao',
	templateUrl: './darf-visualizacao.component.html',
	styleUrls: ['./darf-visualizacao.component.css'],	
})
export class DarfVisualizacaoComponent implements OnInit {
	@ViewChild('iframe') iframe: ElementRef;

	darf: Darf;
	bsModalRef: BsModalRef;
	url:any;
	responder(resposta:string) {
		this.bsModalRef.hide();
	}	

	ngOnInit() {
		const byteArray = new Uint8Array(atob(this.darf.pdf).split('').map(char => char.charCodeAt(0)));
		const file = new Blob([byteArray], {type: 'application/pdf'});
		this.url = window.URL.createObjectURL(file);

	}

	ngAfterViewInit(): void {
		this.iframe.nativeElement.src=this.url;
	}



}
