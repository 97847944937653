import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { InscricaoPgfnService } from '../inscricao-pgfn.service';
import { InscricaoPgfn } from '../model/inscricao-pgfn';

@Component({
  selector: 'via-inscricao-pgfn-table',
  templateUrl: 'inscricao-pgfn-table.component.html',
  styleUrls: ['inscricao-pgfn-table.component.css']
})
export class InscricaoPgfnTableComponent {

  @Input() inscricoes: InscricaoPgfn[];

  @Input() isPessoaFisica: boolean;
  @Input() isPessoaJuridica: boolean;

  @Input() isAtivos: boolean;
  @Input() isSuspensos: boolean;

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  get _window() {
    return window;
  }

  constructor(private inscricaoPgfnService: InscricaoPgfnService) { }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  urlPortalPgfn(inscricao: InscricaoPgfn) {
    console.log(`inscricao PGFN: ${JSON.stringify(inscricao, undefined, 4)}`);
    let url = this.inscricaoPgfnService.getUrlInscricaoPgfn(inscricao);
    console.log("urlPortalPgfn: " + url);
    return url;
  }
}
