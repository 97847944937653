import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Das } from "../../model/das";

@Component({
    selector: 'app-das-visualizacao',
	templateUrl: './das-visualizacao.component.html',
	styleUrls: ['./das-visualizacao.component.css'],	
})
export class DasVisualizacaoComponent implements OnInit {
	@ViewChild('iframe') iframe: ElementRef;

	das: Das;
	bsModalRef: BsModalRef;
	url:any;
	responder(resposta:string) {
		this.bsModalRef.hide();
	}	

	ngOnInit() {
		const byteArray = new Uint8Array(atob(this.das.das).split('').map(char => char.charCodeAt(0)));
		const file = new Blob([byteArray], {type: 'application/pdf'});
		this.url = window.URL.createObjectURL(file);

	}

	ngAfterViewInit(): void {
		this.iframe.nativeElement.src=this.url;
	}



}
