import { PipeTransform, Pipe } from '@angular/core';

/**
 * Formata a dataPAExercicio de acordo com o tipo do PA.
  */
@Pipe({
    name: 'dataPAExercicioPipe'
})
export class DataPAExercicioPipe implements PipeTransform {

    public transform(data: string, tipoPA: number): string {
        switch (tipoPA) {
            case 1: // diario
                return this.formataDiario(data);
            case 2: // semanal
                return this.formataSemanal(data);
            case 3: // decendial
                return this.formataDecendial(data);
            case 4: // quinzenal
                return this.formataQuinzenal(data);
            case 5: // mensal
                return this.formataMensal(data);
            case 6: // bimensal
                return this.formataBimestral(data);
            case 7: // trimestral
                return this.formataTrimestral(data);
            case 8: // semestral
                return this.formataSemestral(data);
            default: // anual
                return this.formataAnual(data);
        }
    }

    private formataDiario(data: string): string {
        return `${this.getDia(data)}/${this.getMes(data)}/${this.getAno(data)}`;
    }

    private formataSemanal(data: string): string {
        return `${this.getSemana(data)}ª Semana ${this.getMes(data)}/${this.getAno(data)}`;
    }

    private formataDecendial(data: string): string {
        return `${this.getDecendio(data)}º Decendio ${this.getMes(data)}/${this.getAno(data)}`;
    }

    private formataQuinzenal(data: string): string {
        return `${this.getQuinzena(data)}ª Quinzena ${this.getMes(data)}/${this.getAno(data)}`;
    }

    private formataMensal(data: string): string {
        return `${this.getMes(data)}/${this.getAno(data)}`;
    }

    private formataBimestral(data: string): string {
        return `${this.getBimestre(data)}º Bimestre de ${this.getAno(data)}`;
    }

    private formataTrimestral(data: string): string {
        return `${this.getTrimestre(data)}º Trimestre de ${this.getAno(data)}`;
    }

    private formataSemestral(data: string): string {
        return `${this.getSemestre(data)}º Semestre de ${this.getAno(data)}`;
    }

    private formataAnual(data: string): string {
        return this.getAno(data);
    }

    private getDia(data: string): string {
        return data.substring(6, 8);
    }

    private getSemana(data: string): string {
        return data.substring(7, 8);
    }

    private getDecendio(data: string): string {
        return data.substring(7, 8);
    }

    private getQuinzena(data: string): string {
        return data.substring(7, 8);
    }

    private getMes(data: string): string {
        return data.substring(4, 6);
    }

    private getBimestre(data: string): string {
        return data.substring(7, 8);
    }

    private getTrimestre(data: string): string {
        return data.substring(5, 6);
    }

    private getSemestre(data: string): string {
        return data.substring(7, 8);
    }

    private getAno(data: string): string {
        return data.substring(0, 4);
    }
}