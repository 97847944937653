import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCpfPipe'
})
export class FormatCpfPipe implements PipeTransform {
  transform(nroCpf: number) {
    return FormatCpfPipe.formatCPF(nroCpf);
  }

  static formatCPF: Function = (cpf: any) => {
      if(cpf){
        let pad = "00000000000";
        let cpf11pos = (pad+cpf).slice(-pad.length);
        return cpf11pos.substring(0,3).concat(".")
                                    .concat(cpf11pos.substring(3,6))
                                    .concat(".")
                                    .concat(cpf11pos.substring(6,9))
                                    .concat("-")
                                    .concat(cpf11pos.substring(9,11));
      }
  }
}