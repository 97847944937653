import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { InformacoesCompletasAtendimento } from '../../model/protocolo';
import { ParcelamentoPaex } from './model/parcelamento-paex';
import { ParcelamentoPaexService } from './parcelamento-paex.service';

@Component({
  selector: 'via-parcelamento-paex',
  templateUrl: './parcelamento-paex.component.html',
  styleUrls: ['./parcelamento-paex.component.css']
})
export class ParcelamentoPaexComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  nomeNi: string;
  titulo: string;
  parcelamentos: ParcelamentoPaex[];

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private parcelamentoPaexService: ParcelamentoPaexService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) {
    console.log('ParcelamentoPaexComponent#constructor');
  }

  init(ni: string) {
    this.ni = ni;
    const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
    const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

    this.setNomeNi(informacoesCompletasAtendimento);

    try {
      let parcelamentosObservable: Observable<ParcelamentoPaex[]>;
      if (this.isAtivos) {
        parcelamentosObservable = this.parcelamentoPaexService.obterParcelamentosPaexAtivos(ni, numeroProtocolo);
      }
      if (this.isSuspensos) {
        parcelamentosObservable = this.parcelamentoPaexService.obterParcelamentosPaexSuspensos(ni, numeroProtocolo);
      }

      parcelamentosObservable.subscribe(
        (resp) => {
          this.parcelamentos = resp;
          this.logService.logAjaxReturn('ParcelamentoPaexComponent', 'obterParcelamentos');
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ParcelamentoPaexComponent', 'obterParcelamentos', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos PAEX', err);
        }
      );
    }
    catch (e) {
      this.initError = e;
      this.logService.logError('ParcelamentoPaexComponent', 'obterParcelamentos', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamentos PAEX', e);
    }
  }

  private setNomeNi(infosAtendimento: InformacoesCompletasAtendimento): void {
    if (this.isPessoaFisica) {
      this.nomeNi = infosAtendimento.dadosContribuinteCpf.nome;
    }

    if (this.isPessoaJuridica) {
      this.nomeNi = infosAtendimento.dadosContribuinteCnpj.razaoSocial
    }
  }
}

@Component({
  selector: 'via-parcelamento-paex',
  templateUrl: './parcelamento-paex.component.html',
  styleUrls: ['./parcelamento-paex.component.css']
})
export class ParcelamentoPaexPJAtivoComponent extends ParcelamentoPaexComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Parcelamentos Ativos Fazendários PAEX - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'via-parcelamento-paex',
  templateUrl: './parcelamento-paex.component.html',
  styleUrls: ['./parcelamento-paex.component.css']
})
export class ParcelamentoPaexPJSuspensoComponent extends ParcelamentoPaexComponent {
  init(ni: string) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Parcelamentos Suspensos Fazendários PAEX - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'via-parcelamento-paex',
  templateUrl: './parcelamento-paex.component.html',
  styleUrls: ['./parcelamento-paex.component.css']
})
export class ParcelamentoPaexPFAtivoComponent extends ParcelamentoPaexComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Parcelamentos Ativos Fazendários PAEX - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'via-parcelamento-paex',
  templateUrl: './parcelamento-paex.component.html',
  styleUrls: ['./parcelamento-paex.component.css']
})
export class ParcelamentoPaexPFSuspensoComponent extends ParcelamentoPaexComponent {
  init(ni: string) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Parcelamentos Suspensos Fazendários PAEX - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}
