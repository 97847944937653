import { Component, Input, OnInit } from '@angular/core';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DadosBasicosPjDTO } from '../../../model/dados-basicos-pj-dto';

@Component({
  selector: 'via-consulta-dados-basicos-pj',   
  templateUrl: './consulta-dados-basicos-pj.component.html',
  styleUrls: ['./consulta-dados-basicos-pj.component.css']
})
export class ConsultaDadosBasicosPjComponent implements OnInit {

  constructor(
    private messageService: ScopedMessageService) { }

  titulo: string;
  initError: Error;
  atividadesCollapsed = true;
  scopeId: string;

  @Input() cnpj: string;
  @Input() dadosBasicos: DadosBasicosPjDTO;

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.titulo = `Pessoa Jurídica - Dados Básicos CNPJ: ${FormatCnpjPipe.formatCNPJ(this.cnpj)}`;
  }
}
