import { Component, Input, OnInit } from '@angular/core';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { PainelAtendimentosService } from 'src/app/workplace/components/painel-atendimentos/painel-atendimentos.service';
import { DynamicModalRequestService } from '../dynamic-modal/dynamic-modal-request.service';
import { AjustaTamanhoNIService } from '../services/ajusta-tamanho-ni.service';

@Component({
  selector: 'ni-com-link',
  templateUrl: './ni-com-link.component.html',
  styleUrls: ['./ni-com-link.component.css']
})
export class NiComLinkComponent implements OnInit{

  @Input() ni: string;
  @Input() nome: string;
  @Input() tipoNI: TipoNI;
  niAjustado: string;

  constructor(
    private painelAtendimentosService: PainelAtendimentosService,
    private dynamicModalRequestService: DynamicModalRequestService,
    private ajustaTamanhoNiService: AjustaTamanhoNIService,
  ){}

  ngOnInit(){
    this.niAjustado = this.tipoNI != null ? 
      this.ajustaTamanhoNiService.transform(this.ni, this.tipoNI) : this.ni;
  }

  solicitaAtendimento(): void {
    this.painelAtendimentosService.createTab(this.ajustaTamanhoNiService.transform(this.ni, this.tipoNI));
  }

  solicitaDadosBasicos(): void {

    if (this.ajustaTamanhoNiService.transform(this.ni, this.tipoNI).length == 14){
      this.dynamicModalRequestService.exibirDadosBasicosCnpj(this.ni);
    } else {
      this.dynamicModalRequestService.exibirDadosBasicosCpf(this.ni);
    }
  }
}
