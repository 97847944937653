import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { TipoNIConsultaCredito } from './../../../../models/tipo-ni-consulta-credito';
import { TipoNI } from './../../../../../sitfis/model/enum/tipo-ni';
import { VencimentoCTCcitr } from './../../../../models/vencimento-ct';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import _, { isEmpty, isEqual } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, delay, take } from 'rxjs/operators';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CreditosTributariosCcitrGroupedByExercicio, CreditoTributarioCcitr } from 'src/app/cobranca/models/credito-tributario-ccitr';
import { ParametrosPesquisaCreditosTributarios } from '../../../../models/parametros-pesquisa';
import { CCITRService } from '../../services/ccitr.service';
import { CcitrBase } from '../../base/CcitrBase.component';
import { ParametrosPesquisaCcitr } from '../../../../models/parametros-pesquisa-ccitr';
import AlteraVencimentoCTComponent from '../altera-vencimento-ct/altera-vencimento-ct.component';
import { CCITRTransferenciaCtComponent } from '../transferencia-ct/ccitr-transferencia-ct.component';
import { CcitrPagamentosDarfComponent } from '../pagamentos-darf/ccitr-pagamentos-darf.component';
import { ParametrosConsultaPagamentosDARF } from 'src/app/cobranca/models/parametros-consulta-pagamentos_darf';
import ConsolidarCTComponent from '../consolidar-ct/consolidar-ct.component';
import { ParametroConsolidarDebitoCCITR } from './model/parametro-consolidar-debito-ccitr';
import { ParametroDemonstrativoQuotasCCITR } from './model/parametro-demonstrativo-quotas-ccitr';
import { CCITRDemonstrativoQuotasComponent } from '../demonstrativo-quotas/ccitr-demonstrativo-quotas.component';
import { CONSTANTS } from '../../constants';

@Component({
  selector: 'via-ccitr-consulta-creditos',
  templateUrl: './ccitr-consulta-creditos.component.html',
  styleUrls: ['./ccitr-consulta-creditos.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]})

export class CcitrConsultaCreditosComponent extends CcitrBase implements IDynamicPanel, OnInit, OnDestroy, OnChanges {
  @Input() parametros: ParametrosPesquisaCcitr;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  updateSubscription: Subscription;

  creditosTributarios: CreditosTributariosCcitrGroupedByExercicio;
  tipoNI: string;

  initError: Error;
  scopeId: string;
  titulo: string;
  stopLoading = false;
  filtroColapsado = false;
  showCloseModal = true;
  resultadoVazio: boolean = true;
  exercicios: string[];
  totalExercicios = 0;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  currentPages = {};

  constructor(
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private cCITRService: CCITRService,
    private loadingService: LoadingService ) {
    super();
  }

  public init(ni: string, params?: OptionalParams): void {

    super.init(ni, params);
    let ehModal: boolean;

    this.showCloseModal = false;


    this.parametros = params as ParametrosPesquisaCcitr;

   // this.recarregar();

  }

  ngOnInit(): void {
    this.initError = null;
    this.creditosTributarios = null;
    this.scopeId = this.messageService.scopeId;
    this.formataTitulo(this.parametros, 'CCITR - Consultar Créditos Tributários');
   // this.cCITRService.atualizarListagemEmitter.pipe(delay(100)).subscribe(() => this.recarregar());
    this.recarregar();
    this.updateSubscription = this.cCITRService.atualizarListagemEmitter.subscribe(
      parametros => {
        if (isEqual(parametros, this.parametros)) {
          this.recarregar();
        }
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  public recarregar(): void {
    this.initError = null;
    this.creditosTributarios = null;
    this.recuperarCreditos();
  }

  public esconderResultado(): void {
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  private tipoDeNi(tipoNI: string): string {
    if (tipoNI == TipoNIConsultaCredito.CNPJ)
      return "CNPJ";
    if (tipoNI = TipoNIConsultaCredito.CPF)
      return "CPF";
    else return "CNPJ";
  }

  private recuperarCreditos(): void {

    let compensacoesObservable: Observable<CreditosTributariosCcitrGroupedByExercicio>;

    this.messageService.dismissMessages();
    try {
      compensacoesObservable = this.agrupaPorExercicio(
        this.cCITRService.consultarCreditosTributarios(this.parametros).pipe(
          take(1),
          catchError(err => {
            this.initError = err;
            this.onError.emit;
            this.messageService.showErrorException("Ocorreu um erro ao colsultar CCITR", err);
            return throwError(err);
          }))
      );
 
      compensacoesObservable.subscribe(
        response => {
          this.creditosTributarios = response

          if (this.creditosTributarios && (isEmpty(this.creditosTributarios) ||
            (Object.keys(this.creditosTributarios).length == 1 && Object.values(this.creditosTributarios)[0][0].numeroCt == null))) {
            this.stopLoading = true;
            this.messageService.showInfo('Nenhum resultado encontrado');
          }
          this.totalExercicios = Object.keys(this.creditosTributarios).length;
          this.exercicios = Object.keys(this.creditosTributarios).map(x => x);
          this.exercicios.sort().reverse();

          for (var key in this.creditosTributarios) {
            let page: Page = {
              first: this.currentPage.first,
              number: this.currentPage.number,
              last: this.creditosTributarios[key].length
            }
            this.currentPages[key] = page;
          }

          const creditos = _.flatten(this.exercicios.map(e => this.creditosTributarios[e]));
          this.cCITRService.notificarAtualizacaoListaCreditos(creditos);

        }, (err: HttpErrorResponse) => {
          if(isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
            this.stopLoading = true;
            return null;
          } else {
            this.initError = err;
            this.onError.emit();
            this.messageService.showErrorException("Ocorreu um erro ao consultar CCITR Consulta Créditos ", err);
            return throwError(err);
          }
        }
      );
    }
    catch (error) {
      this.initError = error;
      this.logService.logError('CcitrConsultaCreditosComponent', 'recuperarCreditos', error);
      this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', error);
    }

  }

  private agrupaPorExercicio(compensacoesObservable: Observable<CreditoTributarioCcitr[]>): Observable<CreditosTributariosCcitrGroupedByExercicio> {
    return compensacoesObservable.pipe(
      map(compensacoes => _.orderBy(compensacoes, ['exercicio', 'dataVencimentoNotificacao'], ['desc', 'desc'])),
      map(compensacoes => _.groupBy(compensacoes, (compensacao) => compensacao.exercicio)),
    );
  }

  consultarPagamentosDARF(credito: CreditoTributarioCcitr): void {
    let parametrosPesquisa = {
      cnpjContribuinte: this.parametros.cnpjContribuinte,
      cpfContribuinte: this.parametros.cpfContribuinte,
      exercicioInicial: this.parametros.exercicioInicial,
      exercicioFinal: this.parametros.exercicioFinal,
      tipoLancamento: this.parametros.tipoLancamento,
      indicadorCnpjBasico: this.parametros.indicadorCnpjBasico,
      nirf: this.parametros.nirf,
      numeroCreditoTributario: credito.numeroCt,
      atendimento: this.atendimento
    } as ParametrosConsultaPagamentosDARF;

    const consultaPagamentosDARFFeature = {
      id: `ConsultaCCITR_PagamentoDARF_${credito.numeroCt}`,
      title: 'Pagamentos - Consultar',
      class: CcitrPagamentosDarfComponent,
      parametros: { ...parametrosPesquisa, paraAlocacao: true }
    } as FeatureAction;

    if (this.atendimento) {
      consultaPagamentosDARFFeature.active = true;
      consultaPagamentosDARFFeature.rolesAllowed = [];
      consultaPagamentosDARFFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consultaPagamentosDARFFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consultaPagamentosDARFFeature);
  }

  retornarSituacaoCT(credito: CreditoTributarioCcitr): void {
      this.loadingService.show();

      this.cCITRService.retornarSituacaoCT(this.parametros, credito).subscribe(
        (resp) => {
          this.messageService.showInfo(`Situação do Crédito Tributário retornada com sucesso.`);
          this.topoDaPaginaMensagem();
          this.cCITRService.atualizarListagemCreditos(this.parametros);
        },
        (err: HttpErrorResponse) => {
          if (isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
            this.stopLoading = true;
            return null;
          } else {
            this.messageService.showErrorException('Ocorreu um erro ao retornar Situação do Crédito Tributário', err);
          }
          this.topoDaPaginaMensagem();
        }
      ).add(() => {
        this.loadingService.hide();
      });
  }

  alterarDemonstrativoQuotas(credito: CreditoTributarioCcitr): void {

    const demonstrativoQuotasFeature = {
      id: `CCITRDemonstrativoQuotas_credito_${credito.numeroCt}`,
      title: 'CCITR - Alterar Demonstrativo de Quotas',
      class: CCITRDemonstrativoQuotasComponent,
      parametros: {
        parametros: this.parametros,
        credito: credito
      } as ParametroDemonstrativoQuotasCCITR
    } as FeatureAction;

    if (this.atendimento) {
      demonstrativoQuotasFeature.active = true;
      demonstrativoQuotasFeature.rolesAllowed = [];
      demonstrativoQuotasFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(demonstrativoQuotasFeature);
      return;
    }

    this.dynamicModalService.abrirModal(demonstrativoQuotasFeature);
  }

  alterarVencimentoCT(item: CreditoTributarioCcitr) {

      let parametros = {
        nirf: this.parametros.nirf,
        cpfCnpjContribuinte: this.parametros.cpfContribuinte ? this.parametros.cpfContribuinte : this.parametros.cnpjContribuinte,
        numeroCT: item.numeroCt,
        dataVencimento: item.dataVencimento,
        atendimento: this.atendimento
      } as VencimentoCTCcitr;

      const VencimentoCTFeature = {
        id: `ConsultaCCITR_VencimentoCT_${item.numeroCt}`,
        title: "CCITR - Vencimento do CT:",
        class: AlteraVencimentoCTComponent,
        parametros: parametros
      } as FeatureAction;

      if (this.atendimento) {
        VencimentoCTFeature.active = true;
        VencimentoCTFeature.rolesAllowed = [];
        VencimentoCTFeature.tipoMenu = TipoMenu.ATENDIMENTO;
        this.menuFeatureActionsService.activate(VencimentoCTFeature);
        return;
      }

      this.dynamicModalService.abrirModal(VencimentoCTFeature);
  }

  consolidarCT(item: CreditoTributarioCcitr) {

    let parametros = {
      ni: (this.parametros.cpfContribuinte || this.parametros.cnpjContribuinte),
      nirf: this.parametros.nirf,
      numeroCT: item.numeroCt,
      dataConsolidacao: item.dataVencimento,
      parametros: this.parametros,
      atendimento: this.atendimento
    } as ParametroConsolidarDebitoCCITR;

    const consolidarCTFeature = {
      id: `ConsultaCCITR_ConsolidarCT_${item.numeroCt}`,
      title: "CCITR - Consolidar CT",
      class: ConsolidarCTComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      consolidarCTFeature.active = true;
      consolidarCTFeature.rolesAllowed = [];
      consolidarCTFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(consolidarCTFeature);
      return;
    }

    this.dynamicModalService.abrirModal(consolidarCTFeature);

  }

  transferirCreditoTributario(item: CreditoTributarioCcitr) {

    let parametros = {
      nirf: this.parametros.nirf,
      credito: item,
      cpfUsuario: this.parametros.cpfContribuinte,
      numeroCT: item.numeroCt,
      dataVencimento: item.dataVencimento,
      parametros: this.parametros,
      atendimento: this.atendimento
    };

    const TransferenciaCTCTFeature = {
      id: `ConsultaCCITR_TransferenciaCT_${item.numeroCt}`,
      title: "CCITR - Transferir CT:",
      class: CCITRTransferenciaCtComponent,
      parametros: parametros
    } as FeatureAction;

    if (this.atendimento) {
      TransferenciaCTCTFeature.active = true;
      TransferenciaCTCTFeature.rolesAllowed = [];
      TransferenciaCTCTFeature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(TransferenciaCTCTFeature);
      return;
    }

    this.dynamicModalService.abrirModal(TransferenciaCTCTFeature);
  }

}
