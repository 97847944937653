import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CnpjModule } from '../cnpj/cnpj.module';
import { CobrancaModule } from '../cobranca/cobranca.module';
import { CpfModule } from '../cpf/cpf.module';
import { ObrigatoriedadeDirfModule } from '../obrigatoriedade-dirf/obrigatoriedade-dirf.module';
import { PagamentoModule } from '../pagamento/pagamento.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { ConsultarReceitaModule } from '../sief/receitas/consultar-receita.module';
import { FiltrosPesquisaReceitaModule } from '../sief/receitas/filtros-pesquisa-receita/filtros-pesquisa-receita.module';
import { SitFisModule } from '../sitfis/sitfis.module';
import { AtendimentoComponent } from './components/atendimento/atendimento.component';
import { EntradaNiComponent } from './components/entrada-ni/entrada-ni.component';
import { PainelAtendimentosComponent } from './components/painel-atendimentos/painel-atendimentos.component';
import { ServiceStatusComponent } from './components/service-status/service-status.component';
import { WorkplaceSuiteRfbModule } from './workplace-suite-rfb.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PipesModule.forRoot(),
    TooltipModule.forRoot(),
    WorkplaceSuiteRfbModule,
    SharedModule,
    SitFisModule,
    CpfModule,
    CnpjModule,
    ConsultarReceitaModule,
    FiltrosPesquisaReceitaModule,
    PagamentoModule,
    CobrancaModule,
    ObrigatoriedadeDirfModule,
  ],
  declarations: [
    AtendimentoComponent,
    PainelAtendimentosComponent,
    ServiceStatusComponent,
    EntradaNiComponent,
  ],
  exports: [
    AtendimentoComponent,
    PainelAtendimentosComponent,
    ServiceStatusComponent,
    EntradaNiComponent,
  ]
})
export class WorkplaceModule { }
