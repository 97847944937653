import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PdfViewerService {

    constructor() {}

    private displayed = new BehaviorSubject<PdfDocument>(null);
    displayed$ = this.displayed.asObservable();

    display(pdfDoc: PdfDocument) {
        this.displayed.next(pdfDoc);
    }
}

interface PdfDocument {
    base64: string;
    filename: string;
    onClose?(): any;
}

