import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ParcelamentoSisparDTO } from '../model/parcelamento-sispar';

@Component({
  selector: 'via-parcelamento-sispar-table',
  templateUrl: 'parcelamento-sispar-table.component.html',
  styleUrls: ['parcelamento-sispar-table.component.css']
})
export class ParcelamentoSisparTableComponent {

  @Input() parcelamentos: ParcelamentoSisparDTO[];
  @Output() emitirDarf = new EventEmitter<ParcelamentoSisparDTO>();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 20;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  onEmitirDarf(parcelamento: ParcelamentoSisparDTO): void {
    this.emitirDarf.emit(parcelamento);
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }
}
