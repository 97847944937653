import { Component } from '@angular/core';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { IrregularidadeCadastral } from './model/irregularidade-cadastral';
import { IrregularidadeCadastralService } from './irregularidade-cadastral.service';
import { Observable } from 'rxjs';
import { LogService } from 'src/app/shared/services/log.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { SituacaoCadastral } from '../../model/enum/situacao-cadastral';
import { TipoNI } from '../../model/enum/tipo-ni';

@Component({
    selector: 'app-irregularidade-cadastral',
    templateUrl: './irregularidade-cadastral.component.html',
    styleUrls: ['./irregularidade-cadastral.component.css']
})
export class IrregularidadeCadastralComponent implements IDynamicPanel {

    initError: Error;

    situacaoCadastral = SituacaoCadastral;
    tipoNi = TipoNI;

    ni: string;
    titulo: string;
    irregularidades: IrregularidadeCadastral[];

    isPessoaFisica = false;
    isPessoaJuridica = false;

    constructor(
        private irregularidadeCadastralService: IrregularidadeCadastralService,
        private atendimentoService: AtendimentoService,
        private messageService: ScopedMessageService,
        private logService: LogService)
    {
        console.log('IrregularidadeCadastralComponent#constructor'); 
    }

    init(ni: string)
    {
        this.ni = ni;
        const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
        const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

        try {
            let irregularidadesObservable : Observable<IrregularidadeCadastral[]>;
            if(this.isPessoaFisica) {
                irregularidadesObservable = this.irregularidadeCadastralService.obterIrregularidadesCadastraisPessoaFisica(ni, numeroProtocolo);
            }
            if(this.isPessoaJuridica) {
                irregularidadesObservable = this.irregularidadeCadastralService.obterIrregularidadesCadastraisPessoaJuridica(ni, numeroProtocolo);
            }

            irregularidadesObservable.subscribe(
                (resp) => {
                    this.irregularidades = resp;
                    this.logService.logAjaxReturn('IrregularidadeCadastralComponent', 'obterIrregularidades');
                },
                (err) => {
                    this.initError = err;
                    this.logService.logAjaxError('IrregularidadeCadastralComponent', 'obterIrregularidades', err);
                    this.messageService.showErrorException('Ocorreu um erro ao obter as irregularidades cadastrais', err);
                }
            );
        }
        catch (e) {
            this.initError = e;
            this.logService.logError('IrregularidadeCadastralComponent', 'obterIrregularidades', e);
            this.messageService.showErrorException('Ocorreu um erro ao obter as irregularidades cadastrais', e);
        }
    }
}

@Component({
    selector: 'app-irregularidade-cadastral',
    templateUrl: './irregularidade-cadastral.component.html',
    styleUrls: ['./irregularidade-cadastral.component.css']
})
export class IrregularidadeCadastralPJComponent extends IrregularidadeCadastralComponent {
    init(ni: string) {
        this.isPessoaJuridica = true;
        this.titulo = `Irregularidade Cadastral - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-irregularidade-cadastral',
    templateUrl: './irregularidade-cadastral.component.html',
    styleUrls: ['./irregularidade-cadastral.component.css']
})
export class IrregularidadeCadastralPFComponent extends IrregularidadeCadastralComponent {
    init(ni: string) {
        this.isPessoaFisica = true;
        this.titulo = `Irregularidade Cadastral - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
        super.init(ni);
    }
}
