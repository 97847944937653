import { Pipe, PipeTransform } from '@angular/core';
import { MotivoTransferencia } from '../../../models/motivo-transferencia';

@Pipe({
    name: 'motivosTransferenciaPipe'
  })
  export class MotivosTransferenciaPipe implements PipeTransform {
    transform(motivosTransferencia: MotivoTransferencia[]): string[] {
      return motivosTransferencia.map(motivo => motivo.descricao);
    }
  }