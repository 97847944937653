<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!impedimentos && !initError; else tabelaImpedimentos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaImpedimentos>
  <via-impedimento-cnd-table
    [impedimentos]="impedimentos"
    [ni]="ni"
    [subtitulo]="subtitulo"
    [isPessoaJuridica]="isPessoaJuridica"
    [isPessoaFisica]="isPessoaFisica"
    [isRfb]="isRfb"
    [isPgfn]="isPgfn">
  </via-impedimento-cnd-table>
</ng-template>
