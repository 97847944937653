import { PipeTransform, Pipe } from '@angular/core';
/**
 * Pipe para remover de um array itens informados como parametro.
 */
@Pipe({
    name: 'arrayRemovePipe'
})
export class ArrayRemovePipe implements PipeTransform {
    
    public transform(itensArray: any[], ...itensRemover: any[]): string[] {
        return itensArray.filter(i => !itensRemover.includes(i));
    }

}