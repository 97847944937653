import { Component } from '@angular/core';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ParcelamentoRefis } from './model/parcelamento-refis';
import { ParcelamentoRefisService } from './parcelamento-refis.service';
import { Observable } from 'rxjs';
import { LogService } from 'src/app/shared/services/log.service';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';

@Component({
    selector: 'app-parcelamento-refis',
    templateUrl: './parcelamento-refis.component.html',
    styleUrls: ['./parcelamento-refis.component.css']
})
export class ParcelamentoRefisComponent implements IDynamicPanel {

    initError: Error;

    ni: string;
    titulo: string;
    parcelamento: ParcelamentoRefis;

    isAtivos = false;
    isSuspensos = false;

    ausencias = new Map();
    ausenciasAnos: string[];

    constructor(
        private parcelamentoRefisService: ParcelamentoRefisService,
        private atendimentoService: AtendimentoService,
        private messageService: ScopedMessageService,
        private logService: LogService)
    {
        console.log('ParcelamentoRefisComponent#constructor'); 
    }

    init(ni: string)
    {
        this.ni = ni;
        const informacoesCompletasAtendimento = this.atendimentoService.getInfosAtendimento(ni);
        const numeroProtocolo = informacoesCompletasAtendimento.protocoloSitFis.numeroProtocolo;

        try {
            let parcelamentoObservable : Observable<ParcelamentoRefis>;
            if(this.isAtivos) {
                parcelamentoObservable = this.parcelamentoRefisService.obterParcelamentoRefisAtivo(ni, numeroProtocolo);
            }
            if(this.isSuspensos) {
                parcelamentoObservable = this.parcelamentoRefisService.obterParcelamentoRefisSuspenso(ni, numeroProtocolo);
            }

            parcelamentoObservable.subscribe(
                (resp) => {
                    this.parcelamento = resp;
                    this.logService.logAjaxReturn('ParcelamentoRefisComponent', 'obterParcelamento');

                    this.parcelamento.ausencias.forEach( a => {
                        let ano = a.substr(0, 4);
                        let mes = a.substr(4, 2);
                        let ausenciasAno = this.ausencias.get(ano);
                        if(!ausenciasAno) {
                            ausenciasAno = new Map();
                            this.ausencias.set(ano, ausenciasAno);
                        }
                        ausenciasAno.set(mes, mes + "/" + ano);
                    });
                    this.ausenciasAnos = Array.from(this.ausencias.keys());
                },
                (err) => {
                    this.initError = err;
                    this.logService.logAjaxError('ParcelamentoRefisComponent', 'obterParcelamento', err);
                    this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamento REFIS', err);
                }
            );
        }
        catch (e) {
            this.initError = e;
            this.logService.logError('ParcelamentoRefisComponent', 'obterParcelamento', e);
            this.messageService.showErrorException('Ocorreu um erro ao obter os parcelamento REFIS', e);
        }
    }
}

@Component({
    selector: 'app-parcelamento-refis',
    templateUrl: './parcelamento-refis.component.html',
    styleUrls: ['./parcelamento-refis.component.css']
})
export class ParcelamentoRefisAtivoComponent extends ParcelamentoRefisComponent {
    init(ni: string) {
        this.isAtivos = true;
        this.titulo = `Parcelamentos Ativos Fazendários REFIS - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}

@Component({
    selector: 'app-parcelamento-refis',
    templateUrl: './parcelamento-refis.component.html',
    styleUrls: ['./parcelamento-refis.component.css']
})
export class ParcelamentoRefisSuspensoComponent extends ParcelamentoRefisComponent {
    init(ni: string) {
        this.isSuspensos = true;
        this.titulo = `Parcelamentos Suspensos Fazendários REFIS - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
        super.init(ni);
    }
}
