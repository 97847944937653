<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!liberacoes && !initError; else tabelaLiberacoes;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaLiberacoes>
  <div class="container-fluid" *ngFor="let liberacao of liberacoes">
    <ng-container *ngIf="liberacao.liberacaoRfb.coSituacao > 0">
      <div class="liberacao">
        <div style="float:left">
          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
        </div>
        <div>Liberação concedida pela RFB</div>
      </div>
      <ng-container *ngIf="rowToggle.show">
        <div class="liberacao-conteudo">
          <div class="row">
            <div class="col-md-8">
              <label class="control-label label-formulario">Tipo da Certidão</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.liberacaoRfb.coTipoCertidao | tipoCndPipe | vazio }}
              </p>
            </div>
            <div class="col-md-4">
              <label class="control-label label-formulario">Data de Validade da Liberação</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.dtValidade | date:'dd/MM/yyyy' | vazio }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Registro</label>
              <p class="form-control-static dado-formulario" *ngIf="liberacao.liberacaoRfb; else templateVazio">
                Liberação da RFB registrada pelo usuário {{ liberacao.liberacaoRfb.cpfUsuario | formatCpfPipe }} em
                {{ liberacao.liberacaoRfb.dtLiberacao | date:'dd/MM/yyyy' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Observações que serão impressas na certidão</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.liberacaoRfb.txObservacoes | vazio }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Situação</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.liberacaoRfb.coSituacao | situacaoCndPipe | vazio }}
              </p>
            </div>
          </div>
          <fieldset class="ente-fieldset" *ngIf="codEnte">
            <legend>Ente Federativo</legend>
            <div class="row">
              <div class="col-md-3">
                <label class="control-label label-formulario">CNPJ</label>
                <p class="form-control-static dado-formulario">
                  {{ liberacao.liberacaoRfb.nuCnpjEnte | formatCnpjPipe | vazio }}
                </p>
              </div>
              <div class="col-md-3">
                <label class="control-label label-formulario">Nome</label>
                <p class="form-control-static dado-formulario">{{ liberacao.liberacaoRfb.noEnte | vazio }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label class="control-label label-formulario">UA</label>
                <p class="form-control-static dado-formulario">{{ liberacao.liberacaoRfb.uaEnte | vazio }}</p>
              </div>
              <div class="col-md-3">
                <label class="control-label label-formulario">Código do Ente Federativo Principal</label>
                <p class="form-control-static dado-formulario">{{ liberacao.coEnteFederativoResponsavel | vazio }}</p>
              </div>
            </div>
          </fieldset>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="liberacao.liberacaoPgfn.coSituacao > 0">
      <div class="liberacao">
        <div style="float:left">
          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
        </div>
        <div>Liberação concedida pela PGFN</div>
      </div>
      <ng-container *ngIf="rowToggle.show">
        <div class="liberacao-conteudo">
          <div class="row">
            <div class="col-md-8">
              <label class="control-label label-formulario">Tipo da Certidão</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.liberacaoPgfn.coTipoCertidao | tipoCndPipe | vazio }}
              </p>
            </div>
            <div class="col-md-4">
              <label class="control-label label-formulario">Data de Validade da Liberação</label>
              <p class="form-control-static dado-formulario">{{ liberacao.dtValidade | date:'dd/MM/yyyy' | vazio }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Registro</label>
              <p class="form-control-static dado-formulario" *ngIf="liberacao.liberacaoPgfn; else templateVazio">
                Liberação da PGFN registrada pelo usuário {{ liberacao.liberacaoPgfn.cpfUsuario | formatCpfPipe }} em
                {{ liberacao.liberacaoPgfn.dtLiberacao | date:'dd/MM/yyyy' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Observações que serão impressas na certidão</label>
              <p class="form-control-static dado-formulario">{{ liberacao.liberacaoPgfn.txObservacoes | vazio }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="control-label label-formulario">Situação</label>
              <p class="form-control-static dado-formulario">
                {{ liberacao.liberacaoPgfn.coSituacao | situacaoCndPipe | vazio }}
              </p>
            </div>
          </div>
          <fieldset class="ente-fieldset" *ngIf="codEnte">
            <legend>Ente Federativo</legend>
            <div class="row">
              <div class="col-md-3">
                <label class="control-label label-formulario">CNPJ</label>
                <p class="form-control-static dado-formulario">{{ liberacao.liberacaoPgfn.nuCnpjEnte | formatCnpjPipe | vazio }}
                </p>
              </div>
              <div class="col-md-3">
                <label class="control-label label-formulario">Nome</label>
                <p class="form-control-static dado-formulario">{{ liberacao.liberacaoPgfn.noEnte | vazio }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label class="control-label label-formulario">UA</label>
                <p class="form-control-static dado-formulario">{{ liberacao.liberacaoPgfn.uaEnte | vazio }}</p>
              </div>
              <div class="col-md-3">
                <label class="control-label label-formulario">Código do Ente Federativo Principal</label>
                <p class="form-control-static dado-formulario">{{ liberacao.coEnteFederativoResponsavel | vazio }}</p>
              </div>
            </div>
          </fieldset>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #templateVazio>
  {{ '' | vazio }}
</ng-template>
