import { Injectable } from "@angular/core";
import { AllowedFeaturePipe, User } from "@serpro/ngx-suite-rfb";
import { Feature, FeatureAction } from "../../model/feature";

@Injectable()
export class ViaMenuService {

  constructor(private allowedFeaturePipe: AllowedFeaturePipe) { }

  public ajustarVisibilidade(featureAction: FeatureAction, user: User): void {
    if (featureAction.children && featureAction.children.length > 0) {
      featureAction.children.forEach(f => this.ajustarVisibilidade(f, user));
      featureAction.children = featureAction.children.filter(f => f.visible);
      featureAction.visible = featureAction.children.some(f => f.visible);
      return;
    }

    if (featureAction.rolesAllowed && featureAction.rolesAllowed.length > 0) {
      const feature = new Feature();
      feature.rolesAllowed = featureAction.rolesAllowed;
      featureAction.visible = featureAction.visible && this.allowedFeaturePipe.transform(feature, user);
      return;
    }

    // SE NÃO TEM FILHOS E NÃO TEM ROLES DEFINIDAS, ENTÃO NÃO PODE SER VISÍVEL
    featureAction.visible = false;
  }
}
