<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!parcelamentos && !initError; else tabelaParcelamentos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaParcelamentos>
  <via-parcelamento-pgfn-table
    [nomeNi]="nomeNi"
    [parcelamentos]="parcelamentos"
    [isPessoaJuridica]="isPessoaJuridica"
    [isPessoaFisica]="isPessoaFisica"
    [isAtivos]="isAtivos"
    [isSuspensos]="isSuspensos">
  </via-parcelamento-pgfn-table>
</ng-template>
