<table suiteRfbTable>
  <caption class="caption-ccpf">Pagamentos Alocados</caption>
  <ng-container *ngIf="!semPagamento; else nenhumPagamento;">
    <thead>
      <tr *ngIf="!atendimento; else templateHeaderAtendimento;">
        <th class="text-center min-width" *ngIf="exibirAcoes">Ações</th>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
          </suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Componente</th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">Pagamento</th>
        <th class="text-center min-width">Data Arrecadação</th>
        <th class="text-center min-width">Data Vencimento</th>
        <th class="text-center min-width">Critério</th>
        <th class="text-center">Valor Total</th>
        <th class="text-center">Valor Utilizado</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let pagamento of pagamentos | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center" *ngIf="exibirAcoes">
            <ng-container *ngTemplateOutlet="templateAcoesPagamento; context: { pagamento: pagamento }">
            </ng-container>
          </td>
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>

          <ng-container *ngIf="!atendimento; else colunasPagamentoAtendimento;">
            <td class="text-center">{{ getComponente(pagamento.componente) | numeroComponentePipe | vazio }}</td>
            <td class="text-center">
              <via-codigo-receita [codigo]="pagamento.receita"></via-codigo-receita>
            </td>
            <td class="text-center">{{ pagamento.pagamento | vazio }} </td>
            <td class="text-center">{{ pagamento.dataArrecadacao | formatDataPipe }}</td>
            <td class="text-center">{{ pagamento.dataVencimento | formatDataPipe }}</td>
            <td class="text-center">{{ getCriterio(pagamento) | vazio }}</td>
            <td class="text-right">{{ pagamento.valorTotal | formatCurrency }}</td>
            <td class="text-right">{{ pagamento.valorUtilizado | formatCurrency }}</td>
            <td class="text-left">{{ pagamento.situacaoPagamento | vazio }}</td>
          </ng-container>

          <ng-template #colunasPagamentoAtendimento>
            <ng-container *ngTemplateOutlet="templateColunasPagamentoAtendimento; context: { pagamento: pagamento }">
            </ng-container>
          </ng-template>
        </tr>

        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <ng-container *ngIf="!atendimento; else detalhePagamentoAtendimento;">
              <td colspan="11">
                <div class="contorno">
                  <div class="row">
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Banco / Agência
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.banco | vazio }}&nbsp;/&nbsp;{{ pagamento.agencia | vazio }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Primeira Linha
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorPrimeiraLinha | formatCurrency }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Bloqueado
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorBloqueado | formatCurrency }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Reservado
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorReservado | formatCurrency }}
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Amortizado
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorAmortizado | formatCurrency }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Disponível
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorDisponivel | formatCurrency }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Alocações Automáticas
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorAlocacoesAutomaticas | formatCurrency }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <label class="label-formulario control-label">
                        Valor Alocações Manuais
                      </label>
                      <p class="form-control-static dado-formulario">
                        {{ pagamento.valorAlocacoesManuais | formatCurrency }}
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-template #detalhePagamentoAtendimento>
              <ng-container *ngTemplateOutlet="templateDetalhePagamentoAtendimento; context: { pagamento: pagamento }">
              </ng-container>
            </ng-template>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </ng-container>

  <ng-template #nenhumPagamento>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="11">
        Nenhum pagamento alocado encontrado para este crédito tributário.
      </td>
    </tr>
  </ng-template>
</table>

<ng-template #templateHeaderAtendimento>
  <tr>
    <th class="text-center min-width" *ngIf="exibirAcoes">Ações</th>
    <th class="text-center min-width">
      <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
      </suite-rfb-toggle>
    </th>
    <th class="text-center min-width">Componente</th>
    <th class="text-center min-width">Receita</th>
    <th class="text-center min-width">Pagamento</th>
    <th class="text-center">Data de Arrecadação</th>
    <th class="text-center">Data de Vencimento</th>
    <th class="text-center min-width">Critério</th>
  </tr>
</ng-template>

<ng-template #templateAcoesPagamento let-pagamento="pagamento">
  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
  <ng-template #menuAcoes>
    <div class="btn-group-vertical" role="group">
      <button type="button" class="btn btn-default" (click)="desalocarPagamento(pagamento); botaoAcoes.hide();">
        Desalocar
      </button>
      <button type="button" class="btn btn-default" (click)="impedirAlocacaoAutomatica(pagamento);botaoAcoes.hide();">
        Impedir Alocação Automática
      </button>
      <button type="button" class="btn btn-default" (click)="liberarAlocacaoAutomatica(pagamento);botaoAcoes.hide();">
        Liberar Alocação Automática
      </button>
      <button type="button" class="btn btn-default" (click)="tratarPagamento(pagamento);botaoAcoes.hide();">
        Tratar Pagamento
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #templateColunasPagamentoAtendimento let-pagamento="pagamento">
  <td class="text-center">{{ getComponente(pagamento.componente) | numeroComponentePipe | vazio }}</td>
  <td class="text-center">
    <via-codigo-receita [codigo]="pagamento.receita"></via-codigo-receita>
  </td>
  <td class="text-center">{{ pagamento.pagamento | vazio }} </td>
  <td class="text-center">{{ pagamento.dataArrecadacao | formatDataPipe }}</td>
  <td class="text-center">{{ pagamento.dataVencimento | formatDataPipe }}</td>
  <td class="text-center">{{ getCriterio(pagamento) | vazio }}</td>
</ng-template>

<ng-template #templateDetalhePagamentoAtendimento let-pagamento="pagamento">
  <td colspan="8">
    <div class="contorno">
      <div class="row">
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Situação
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.situacaoPagamento | vazio }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Banco / Agência
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.banco | vazio }}&nbsp;/&nbsp;{{ pagamento.agencia | vazio }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Primeira Linha
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorPrimeiraLinha | formatCurrency }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Bloqueado
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorBloqueado | formatCurrency }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Reservado
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorReservado | formatCurrency }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Utilizado
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorUtilizado | formatCurrency }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Total
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorTotal | formatCurrency }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="label-formulario control-label">
            Valor Amortizado
          </label>
          <p class="form-control-static dado-formulario">
            {{ pagamento.valorAmortizado | formatCurrency }}
          </p>
        </div>
      </div>
    </div>
  </td>
</ng-template>
