export class ParametrosPesquisaPessoaFisica {
  nome: string;
  nomeMae: string;
  numeroTituloEleitor: string;
  dataNascimento: Date;
  pesquisaFonetica: boolean;
  municipio: string;
  uf: string;
  cep: string;
}
