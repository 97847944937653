import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbTableModule } from '@serpro/ngx-suite-rfb';

@NgModule({
    imports: [
        SuiteRfbCollapseModule,
        SuiteRfbPaginationModule,
        SuiteRfbTableModule
    ],
    exports: [
        SuiteRfbCollapseModule,
        SuiteRfbPaginationModule,
        SuiteRfbTableModule
    ]
})
export class ParcelamentoSIPADESuiteRfbModule { }