<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId" *ngIf="exibeMensagem"></suite-rfb-alert-messages-with-datetime>

    <div class="container-fluid">
      <loading-animation *ngIf="!eventosCreditoTributario && !initError; else tabelaEventos;"></loading-animation>

      <ng-template #tabelaEventos>
        <ng-container *ngIf="eventosCreditoTributario?.length > 0">
          <div class="table-responsive">
            <table suiteRfbTable>
              <thead>
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Número</th>
                  <th class="text-center">Ocorrência</th>
                  <th class="text-center">Código UA</th>
                  <th class="text-center">Usuário</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let evento of eventosCreditoTributario | currentPage: currentPage;">
                  <tr class="vertical-align-middle">
                    <td class="text-center">{{ evento.dataEvento | formatDataPipe | vazio }}</td>
                    <td class="text-center">{{ evento.tipoNumero | vazio }}</td>
                    <td class="text-center">{{ evento.numero | vazio }}</td>
                    <td class="text-left">{{ evento.descricao | vazio }}</td>
                    <td class="text-center">{{ evento.numeroUA | vazio }}</td>
                    <td class="text-center">{{ evento.cpfUsuario | formatCpfCnpjPipe: 'CPF' | vazio }}</td>
                  </tr>
                </ng-container>
              </tbody>
              <tfoot>
                <tr class="tabela-acoes text-left">
                  <td colspan="6">
                    <via-pagination [itemAmount]="eventosCreditoTributario.length" [(page)]="currentPage">
                    </via-pagination>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </suite-rfb-collapse>
</div>
