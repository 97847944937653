import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnChanges } from '@angular/core';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { FeatureAction } from 'src/app/shared/model/feature';

@Component({
  selector: 'via-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class ViaMenuItemComponent implements OnChanges {

  @Input() feature: FeatureAction;

  collapsed: boolean;

  constructor(
    private dynamicModalRequest: DynamicModalRequestService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnChanges(): void {
    this.collapsed = this.feature.collapsed || this.feature.collapsed == null;
  }

  onClickFeature(feature: FeatureAction): void {
    if (feature.url) {
      window.open(feature.url, '_blank');
    } else {
      this.dynamicModalRequest.abrirModal(feature);
    }

    this.hideMenu();
  }

  private hideMenu(): void {
    this.document.body.classList.remove('menu-lateral-ativo');
  }
}
