import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComprotProcesso, ComprotProcessos, MovimentacoesProcesso } from '../model/comprot-processos';

@Injectable()
export class ComprotService {

  private baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterProcessosComprot(ni: string): Observable<ComprotProcessos> {
    const url = `${this.baseUrl}/comprot/${ni}/processos`;
    return this.httpClient.get<ComprotProcessos>(url);
  }

  public obterDetalheProcesso(ni: string, processo: ComprotProcesso): Observable<MovimentacoesProcesso> {
    const hoje = new Date();
    const dia = hoje.getDate().toString();
    const mes = (hoje.getMonth() + 1).toString().padStart(2, '0');
    const ano = hoje.getFullYear().toString();
    const dataFim = `${dia}${mes}${ano}`;
    const numeroProcesso: string = processo.numeroProcesso;
    const url = `${this.baseUrl}/comprot/${ni}/processos/${numeroProcesso}/movimentacoes/${dataFim}`;
    return this.httpClient.get<MovimentacoesProcesso>(url);
  }
}
