import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DadosBasicosPfDTO } from '../components/dados-basicos-pf/model/dados-basicos-pf-dto';
import { DadosResumidosPfDTO } from '../components/dados-basicos-pf/model/dados-resumidos-pf-dto';
import { ParametrosPesquisaPessoaFisica } from '../models/parametros-pesquisa-pessoa-fisica';
import { ResumoPessoaFisica } from '../models/resumo-pessoa-fisica';

@Injectable()
export class CpfService {
  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDadosBasicos(cpf: string): Observable<DadosBasicosPfDTO> {
    const url = `${this.baseUrl}/cpf/dados-basicos/${cpf}`;
    //console.log(url);
    return this.http.get<DadosBasicosPfDTO>(url);
  }

  public obterDadosResumidos(cpf: string): Observable<DadosResumidosPfDTO> {
    const url = `${this.baseUrl}/cpf/resumo/${cpf}`;
    //console.log(url);
    return this.http.get<DadosResumidosPfDTO>(url);
  }

  public pesquisarPessoaFisica(parametros: ParametrosPesquisaPessoaFisica): Observable<ResumoPessoaFisica[]> {
    const url = `${this.baseUrl}/cpf/pesquisa-pessoa-fisica`;
    //console.log(url);
    return this.http.post<ResumoPessoaFisica[]>(url, parametros);
  }
}
