import {
    Directive,
    OnInit,
    Renderer2,
    ElementRef,
    HostListener
  } from '@angular/core';
  import * as _ from 'lodash';
  
  @Directive({
    selector: '[suiteRfbMaskCpfCnpj]'
  })
  export class CpfCnpjMaskDirective implements OnInit {
    constructor(private renderer: Renderer2, private el: ElementRef) {}
  
    maskCpf = '999.999.999-99';
    maskCnpj = '99.999.999/9999-99';
  
    ngOnInit() {
      this.renderer.setAttribute(this.el.nativeElement, 'maxLength', '18');
    }
    @HostListener('keyup', ['$event'])
    onKeyup($event: any) {
      // retorna caso pressionado backspace/tab
      if ($event.key === 'Tab' || 
            ($event.key === 'Backspace' && 
              this.el.nativeElement.selectionStart == $event.target.value.length 
              && $event.target.value.length !=14)) {
        return;
      }
      let maskedValue = '';
      if ($event.target.value.replace(/[^\d]+/g, '').length <= 11) {
        maskedValue = this.maskInput($event.target.value, this.maskCpf);
      } else {
        maskedValue = this.maskInput($event.target.value, this.maskCnpj);
      }
      this.setValue(maskedValue);
    }
  
    @HostListener('blur', ['$event'])
    onBlur($event: any) {
      const isWrongLength =
        $event.target.value.length !== this.maskCpf.length &&
        $event.target.value.length !== this.maskCnpj.length;
      if (isWrongLength) {
        $event.stopPropagation();
        this.setValue('');
      }
    }
  
    private setValue(value: string) {
      this.el.nativeElement.value = value;
      this.el.nativeElement.dispatchEvent(new CustomEvent('input'));
    }
  
    private maskInput(input: string, mask: string): string {
      let value = input.replace(/\D/g, '');
  
      const pad = mask.replace(/\D/g, '').replace(/9/g, '_');
      const maskedValue = value + pad.substring(0, pad.length - value.length);
  
      let maskedValuePos = 0;
      value = '';
  
      for (let i = 0; i < mask.length; i++) {
        if (Number.isNaN(_.parseInt(mask.charAt(i)))) {
          value += mask.charAt(i);
        } else {
          value += maskedValue[maskedValuePos++];
        }
      }
  
      if (value.indexOf('_') > -1) {
        value = value.substr(0, value.indexOf('_'));
      }
  
      return value;
    }
  }
  