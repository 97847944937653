import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { InfoCadastraisPJSuiteRfbModule } from './info-cadastrais-pj-suite-rfb.module';
import { InfoCadastraisPJComponent } from './info-cadastrais-pj.component';
import { InfoCadastraisPJService } from './info-cadastrais-pj.service';
import { InfoCadastraisPJTableComponent } from './table/info-cadastrais-pj-table.component';

@NgModule({
    declarations: [
        InfoCadastraisPJComponent,
        InfoCadastraisPJTableComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        InfoCadastraisPJSuiteRfbModule,
        ModalModule,
        TooltipModule
    ],
    providers: [
        InfoCadastraisPJService
    ],
    entryComponents: [
        InfoCadastraisPJComponent
    ]
})
export class InfoCadastraisPJModule { }