import { Component } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';

@Component({
  templateUrl: './filtros-pesquisa-pj.component.html',
  styleUrls: ['./filtros-pesquisa-pj.component.css']
})
export class FiltrosPesquisaPjComponent implements IDynamicPanel {

  titulo = 'Pessoa Jurídica - Consultar - Filtros de Pesquisa';

  initError: Error;

  init(): void { }
}
