  <suite-rfb-collapse [collapsed]="filtroColapsado" (collapsedChange)="colapsar($event)" [title]="titulo">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <div class="container-filtros">
    <div class="container-fluid">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">

        <div class="row">
          <div class="col-md-12 col-data-arrecadacao">
            <label class="control-label" for="motivoName">Motivo de Impedimento de Alocação Automática</label>
            <div class="form-group" [suiteRfbHasError]="motivoCtrl">
              <textarea class="form-control" type="text" [formControlName]="motivoName" autocomplete="off"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12" style="margin-top: 25px;">
            <div class="form-group">
              <div class="text-center">
                <button type="reset" class="btn btn-default" [disabled]="!formGroup.dirty">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Informar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  </suite-rfb-collapse>

