import { CcitrPagamentosDarfComponenteAlocadoDTO } from './../../pagamentos-darf/model/ccitr-pagamentos-darf';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';

@Component({
  selector: 'via-ccitr-aloca-pagamento-detalhe-alocacoes',
  templateUrl: './aloca-pagamento-detalhe-alocacoes.component.html',
  styleUrls: ['../aloca-pagamento.component.css']
})
export class CcitrAlocaPagamentoDetalheAlocacoesComponent {

  @Input() atendimento: boolean = false;
  @Input() alocacoes: CcitrPagamentosDarfComponenteAlocadoDTO[] = [];

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }
}
