<div class="contorno">
  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">Órgão</label>
      <p class="dado-formulario form-control-static">{{ detalhe.orgaoOrigem | vazio }}</p>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Data Evento</label>
      <p class="dado-formulario form-control-static">{{ detalhe.dataEvento | date: 'dd/MM/yyyy' | vazio }}</p>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Data Digitação</label>
      <p class="dado-formulario form-control-static">{{ detalhe.dataDigitacao | date: 'dd/MM/yyyy' | vazio }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">Data Processamento</label>
      <p class="dado-formulario form-control-static">{{ detalhe.dataProcessamento | date: 'dd/MM/yyyy' | vazio }}</p>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Hora Processamento</label>
      <p class="dado-formulario form-control-static">{{ detalhe.horaProcessamento | slice: 1: 7 | outputMask: '99:99:99' | vazio }}</p>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Recibo</label>
      <p class="dado-formulario form-control-static">{{ detalhe.recibo | vazio }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <label class="label-formulario control-label">Terminal</label>
      <p class="dado-formulario form-control-static">{{ detalhe.terminal | vazio }}</p>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Digitado por</label>
      <ni-com-link
        *ngIf="detalhe.cpfDigitador; else templateVazio;"
        [ni]="detalhe.cpfDigitador"
        tipoNI="CPF">
      </ni-com-link>
    </div>

    <div class="col-md-4">
      <label class="label-formulario control-label">Transmitido por</label>
      <ni-com-link
        *ngIf="detalhe.cpfTransmissor; else templateVazio;"
        [ni]="detalhe.cpfTransmissor"
        tipoNI="CPF">
      </ni-com-link>
    </div>
  </div>
</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
