import { isWarning } from './../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { ParametrosConsultaEventos } from 'src/app/cobranca/models/parametros-consulta-eventos';
import { isValidNi } from 'src/app/shared/directives/ni-validator/ni-validator.directive';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { UtilService } from 'src/app/shared/util/util.service';
import { CcitrBase } from '../../base/CcitrBase.component';
import { CONSTANTS } from '../../constants';
import { CCITRService } from '../../services/ccitr.service';
import { CCITREventoDTO } from './model/ccitr-evento-dto';

@Component({
  selector: 'via-consulta-eventos',
  templateUrl: './consulta-eventos.component.html',
  styleUrls: ['./consulta-eventos.component.css', '../../base/ccitr.css'],
  providers: [ScopedMessageService]
})
export class ConsultaEventosComponent extends CcitrBase implements IDynamicPanel, OnChanges, OnDestroy, OnInit {

  @Input() parametros: ParametrosConsultaEventos;
  @Input() parentScopeId: string;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;
  @ViewChildren('rowToggleExercicio') rowToggleQueryExercicio: QueryList<ToggleComponent>;

  eventosCCITR: CCITREventoDTO[];

  classeModal: string;

  dataInicial: Date;
  dataFinal: Date;
  stopLoading = false;
  initError: Error;
  scopeId: string;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };
  showCloseModal = true;
  filtroColapsado = false;
  updateSubscription: Subscription;

  constructor(
    private messageService: ScopedMessageService,
    private utilService: UtilService,
    private cCITRService: CCITRService) {
    super();
  }

  public init(ni: string, params?: OptionalParams): void {
    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosConsultaEventos;

    if (this.parametros.dataInicial != undefined)
      this.dataInicial = new Date(this.parametros.dataInicial);

    if (this.parametros.dataFinal != undefined)
      this.dataFinal = new Date(this.parametros.dataFinal);

    this.recarregar();
  }

  ngOnInit(): void {
    this.initError = null;
    this.eventosCCITR = null;
    this.scopeId = this.messageService.scopeId;
    this.formataTitulo(this.parametros, 'CCITR - Consultar Eventos');
  }

  private obterEventos(): void {
    this.stopLoading = false;
    this.messageService.dismissMessages();
    let sDataInicial: string;
    let sDataFinal: string;

    if (this.atendimento) {
      sDataInicial = this.parametros.dataInicial ? this.utilService.converterDataParaTextoInvertidaSemHora(new Date(this.parametros.dataInicial)) : "";
      sDataFinal = this.parametros.dataFinal ? this.utilService.converterDataParaTextoInvertidaSemHora(new Date(this.parametros.dataFinal)) : "";
    } else {
      sDataInicial = this.dataInicial ? this.utilService.converterDataParaTextoInvertidaSemHora(this.dataInicial) : "";
      sDataFinal = this.dataFinal ? this.utilService.converterDataParaTextoInvertidaSemHora(this.dataFinal) : "";
    }

    this.cCITRService.obterEventos(this.parametros.nirf,
      this.parametros.cpfContribuinte ? this.parametros.cpfContribuinte : this.parametros.cnpjContribuinte,
      sDataInicial,
      sDataFinal,
      this.parametros.tipoEvento,
      this.parametros.exercicioInicial,
      this.parametros.exercicioFinal).subscribe(
        response => {
          this.eventosCCITR = response;
          if (this.eventosCCITR && isEmpty(this.eventosCCITR)) {
            this.messageService.showInfo('Nenhum resultado encontrado');
          }
        },
        (err: HttpErrorResponse) => {
          if (isWarning(err.error)) {
            this.messageService.showWarning(err.error.message);
            return null;
          } else {
            this.initError = err;
            this.messageService.showErrorException("Ocorreu um erro ao consultar CCITR Eventos ", err);
          }
        }
        ).add(() => {
        this.stopLoading = true;
        });
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  expandedAllExercicio(show: boolean) {
    this.rowToggleQueryExercicio.forEach(toggle => toggle.show = show);
  }

  isCpfValido(cpf: string): boolean {
    return isValidNi(cpf);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public recarregar(): void {
    this.initError = null;
    this.eventosCCITR = null;
    this.obterEventos();
  }
}
