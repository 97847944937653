import { Component, Input, Output } from '@angular/core';
import _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { Feature, FeatureAction } from '../model/feature';

@Component({
  selector: 'app-menu-feature-actions',
  templateUrl: './menu-feature-actions.component.html',
  styleUrls: ['./menu-feature-actions.component.css']
})
export class MenuFeatureActionsComponent {

  @Input() feature: Feature;
  @Input() featureActions: FeatureAction[];
  @Output() collapsedChange = new EventEmitter<boolean>();

  expanded: boolean = false;

  searchTerms = '';
  constructor() { }

  filtra (featureAction: FeatureAction): boolean {
    if (featureAction.title.toUpperCase().includes(this.searchTerms.toUpperCase())){
      if (featureAction.children!=null){
        featureAction.children.forEach(n => this.tornaVisivel(n))
      }
      featureAction.visible = true;
      return true;
    }
    if (featureAction.children!=null) {
      featureAction.children.forEach(n => this.filtra(n));
      if (featureAction.children.some(n => n.visible)){
        featureAction.visible = true;
        return true;
      }
    }
    featureAction.visible = false;
    return false;
  } 
   
  public search(searchTerms: string) {
    this.searchTerms = searchTerms;
    if(this.featureActions){
      if (this.searchTerms !='') {
        this.featureActions.forEach(n => this.filtra(n))
        this.expandMenu();
      }
      else {
        this.featureActions.forEach(n => this.tornaVisivel(n))
        this.atualizaFeatures();
      }
    }
  }

  tornaVisivel(feature: FeatureAction){
    feature.visible = true;
    if(feature.children){
      feature.children.forEach(n => this.tornaVisivel(n))
    }
  }

  expandMenu() {
    this.featureActions.forEach(n => this.expandFeature(n));
    this.atualizaFeatures();
    this.expanded = true;
    console.log('MenuFeatureActionsComponent.expandMenu');
  }

  expandFeature(feature: FeatureAction){
    feature.collapsed = false;
    if(feature.children){
      feature.children.forEach(n => this.expandFeature(n));
    }
  }

  retractMenu(){
    this.featureActions.forEach(n => this.retractFeature(n));
    this.atualizaFeatures();
    this.expanded = false;
    console.log('MenuFeatureActionsComponent.retractMenu');
  }

  retractFeature(feature: FeatureAction){
    feature.collapsed = true;
    if(feature.children){
      feature.children.forEach(n => this.retractFeature(n));
    }
  }

  atualizaFeatures(){
    this.featureActions = _.cloneDeep(this.featureActions);
  }

  // repassa o CollapsedChange de um componente para o pai.
  onCollapsedChange(collapsed: boolean){
    this.collapsedChange.emit(collapsed);
    this.searchTerms  = '';
  }
}
