<suite-rfb-collapse [title]="titulo" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <ng-container *ngIf="ctxSociosPessoaFisica.socios
      || ctxSociosPessoaJuridica.socios
      || ctxSociosEstrangeiros.socios; else loadingOrError;">
    <ng-container *ngIf="ctxSociosPessoaFisica.socios?.length > 0">
      <via-quadro-societario-table
        [detalheSocio]="ctxSociosPessoaFisica"
        [incluiHistorico]="incluiHistorico">
      </via-quadro-societario-table>
    </ng-container>

    <ng-container *ngIf="ctxSociosPessoaJuridica.socios?.length > 0">
      <via-quadro-societario-table
        [detalheSocio]="ctxSociosPessoaJuridica"
        [incluiHistorico]="incluiHistorico">
      </via-quadro-societario-table>
    </ng-container>

    <ng-container *ngIf="ctxSociosEstrangeiros.socios?.length > 0">
      <via-quadro-societario-table
        [detalheSocio]="ctxSociosEstrangeiros"
        [incluiHistorico]="incluiHistorico">
      </via-quadro-societario-table>
    </ng-container>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
