import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricoPjSuiteRfbModule } from './historico-pj-suite-rfb.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HistoricoPjComponent } from './components/historico-pj.component';
import { HistoricoPjService } from './services/historico-pj.service';
import { DetalheHistoricoPjComponent } from './components/detalhe/detalhe-historico-pj.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    HistoricoPjComponent,
    DetalheHistoricoPjComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    NgxMaskModule,
    HistoricoPjSuiteRfbModule
  ],
  exports: [
    HistoricoPjComponent
  ],
  providers: [
    HistoricoPjService
  ]
})
export class HistoricoPjModule { }
