import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule } from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule
  ],
  exports: [
    SuiteRfbCollapseModule
  ]
})
export class InformacoesComplementaresSuiteRfbModule { }
