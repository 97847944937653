<suite-rfb-collapse (collapsedChange)="onCollapsedChange($event)" [(collapsed)]="feature.collapsed" [title]="feature.title" vertical="true">
    <!-- TODO: IMPLEMENTAR A FUNCIONALIDADE DE ICONES PARA O MENU PRINCIPAL -->
    <!-- <span before-title class="feature-icon material-icons icon-24">{{feature.icon}}</span> -->
    <div class="menu-lateral-ativo" style="margin-top: -10px">
        <div class="menulateral">
            <div id="suite-rfb-menu-container">
                <div class="esquerda">
                    <button *ngIf="!expanded" id="expandMenu" title="Expandir Menu" type="button" class="btn-suite-rfb sem-borda" (click)="expandMenu()">
                        <span class="material-icons icon-36">expand_more</span>
                    </button>
                    <button *ngIf="expanded" id="retractMenu" title="Contrair Menu" type="button" class="btn-suite-rfb sem-borda" (click)="retractMenu()">
                        <span class="material-icons icon-36">expand_less</span>
                    </button>
                </div>
                <div class="busca">
                    <label for="busca" class="sr-only">Buscar funcionalidade</label>
                    <div class="input-group">
                        <input #searchInput type="search" id="busca" class="form-control" style="width:100% !important;"
                            (input)="search($event.target.value)" [(value)]="searchTerms" autocomplete="off">
                        <div class="input-group-btn">
                            <button id="clearFilter" title="Limpar filtros" type="button" class="btn-suite-rfb"
                                (click)="searchInput.value=''; search('')">
                                <i class="icon icon-suite-rfb-filter-remove icon-24"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <ul class="menu">
                    <div class="text-center" *ngIf="!featureActions">
                        <loading-animation></loading-animation>
                    </div>
                    <ng-container *ngFor="let featureAction of featureActions">
                        <li *ngIf="featureAction.visible" >
                            <app-menu-feature-action [root]="true" [featureAction]="featureAction" class="active"></app-menu-feature-action>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</suite-rfb-collapse>