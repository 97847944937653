import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import _, { isEmpty, isEqual } from 'lodash';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CreditoTributario, CreditosTributariosGroupedByExercicio } from '../../../../models/credito-tributario';
import { ParametrosPesquisaCreditosTributarios } from '../../../../models/parametros-pesquisa';
import { CCPFService } from '../../services/ccpf.service';
import { CCPFDebitoContaComponent } from '../debito-conta/debito-conta.component';
import { CCPFDemonstrativoCotasComponent } from '../demonstrativo-cotas/demonstrativo-cotas.component';
import { EventosCreditoTributarioFiltros } from '../eventos-credito-tributario-filtros/eventos-credito-tributario-filtros.component';
import { FiltrosPesquisaCcpfPagamentoComponent } from '../pagamento/filtros-pesquisa-pagamento/filtros-pesquisa-pagamento.component';
import { CcpfTransfereCreditoComponent } from '../transfere-credito-tributario/transfere-credito-tributario.component';

@Component({
  selector: 'via-consulta-creditos',
  templateUrl: './consulta-creditos.component.html',
  styleUrls: ['./consulta-creditos.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaCreditosComponent implements IDynamicPanel, OnChanges, OnDestroy, OnInit {

  @Input() parametros: ParametrosPesquisaCreditosTributarios;
  @Input() parentScopeId: string;
  @Input() atendimento: boolean;
  @Output() onError: EventEmitter<void> = new EventEmitter();
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;
  @ViewChildren('rowToggleExercicio') rowToggleQueryExercicio: QueryList<ToggleComponent>;

  creditosTributarios: CreditosTributariosGroupedByExercicio;

  stopLoading = false;
  initError: Error;
  scopeId: string;
  titulo: string;

  isModal = false;
  exercicios: string[];
  totalExercicios = 0;
  cpf: string;

  filtroColapsado = false;

  updateSubscription: Subscription;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  currentPages = {};

  constructor(
    private messageService: ScopedMessageService,
    private logService: LogService,
    private dynamicModalRequestService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private ccpfService: CCPFService,
    private loadingService: LoadingService) { }

  public init(ni: string, params?: OptionalParams): void {
    this.parametros = params as ParametrosPesquisaCreditosTributarios;
    this.isModal = params.isModal;
  }

  ngOnInit(): void {
    this.initError = null;
    this.creditosTributarios = null;
    this.scopeId = this.messageService.scopeId;
    this.cpf = this.parametros.cpfContribuinte;
    this.titulo = `CCPF - Consultar Créditos Tributários em Cobrança CPF: ${FormatCpfPipe.formatCPF(this.parametros.cpfContribuinte)}`;
    this.recarregar();
    this.updateSubscription = this.ccpfService.atualizarListagemEmitter.subscribe(
      parametros => {
        if (isEqual(parametros, this.parametros)) {
          this.recarregar();
        }
      }
    );
  }

  private pesquisarCreditos(): void {
    let creditosAgrupadosObservable: Observable<CreditosTributariosGroupedByExercicio>;

    this.messageService.dismissMessages();
    try {
      creditosAgrupadosObservable = this.agrupaPorExercicio(
        this.ccpfService.consultarCreditosTributarios(this.parametros, false).pipe(
          take(1),
          catchError(err => {
            this.initError = err;
            this.onError.emit();
            this.messageService.showErrorException('Ocorreu um erro ao consultar CCPF', err);
            return throwError(err);
          }))
      );

      creditosAgrupadosObservable.subscribe(
        response => {
          this.creditosTributarios = response;

          if (this.creditosTributarios && (isEmpty(this.creditosTributarios) ||
            (Object.keys(this.creditosTributarios).length == 1 && Object.values(this.creditosTributarios)[0][0].ct == null))) {
            this.stopLoading = true;
            this.messageService.showInfo('Nenhum resultado encontrado');
          }

          this.totalExercicios = Object.keys(this.creditosTributarios).length;
          this.exercicios = Object.keys(this.creditosTributarios).map(x => x);
          this.exercicios.sort().reverse();

          for (var key in this.creditosTributarios) {
            let page: Page = {
              first: this.currentPage.first,
              number: this.currentPage.number,
              last: this.creditosTributarios[key].length
            }
            this.currentPages[key] = page;
          }

          const creditos = _.flatten(this.exercicios.map(e => this.creditosTributarios[e]));
          this.ccpfService.notificarAtualizacaoListaCreditos(creditos);
        },
        error => {
          this.initError = error;
          this.onError.emit();
          this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', error);
        }
      );
    } catch (error) {
      this.initError = error;
      this.logService.logError('ConsultaCreditosComponent', 'obterCreditosCCPF', error);
      this.messageService.showErrorException('Ocorreu um erro ao executar a pesquisa', error);
    }
  }

  private agrupaPorExercicio(creditosObservable: Observable<CreditoTributario[]>): Observable<CreditosTributariosGroupedByExercicio> {
    return creditosObservable.pipe(
      map(creditos => _.orderBy(creditos, ['exercicio', 'dataVencimentoNotificacao'], ['desc', 'desc'])),
      map(creditos => _.groupBy(creditos, (credito) => credito.exercicio)),
    );
  }

  expandedAll(show: boolean) {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  expandedAllExercicio(show: boolean) {
    this.rowToggleQueryExercicio.forEach(toggle => toggle.show = show);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.titulo) {
      this.recarregar();
    }
  }

  public ngOnDestroy(): void {
    this.updateSubscription?.unsubscribe();
  }

  public recarregar(): void {
    this.initError = null;
    this.creditosTributarios = null;
    this.pesquisarCreditos();
  }

  public esconderResultado(): void {
    this.filtroColapsado = false;
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

  public pagamentos(credito: CreditoTributario): void {

    if (this.atendimento) {
      this.filtroColapsado = true;

      let item: FeatureAction = {
        id: 'ccpf-consultarpagamentos' + this.parametros.cpfContribuinte,
        title: 'CCPF - Consultar Pagamentos',
        visible: true,
        rolesAllowed: [],
        tipoMenu: TipoMenu.ATENDIMENTO,
        class: FiltrosPesquisaCcpfPagamentoComponent
      };

      let params = {};
      params['cpfContribuinte'] = this.cpf;
      params['ct'] = credito.ct;
      item.parametros = params;
      this.menuFeatureActionsService.activate(item);
    } else {
      let feature = new FeatureAction();
      feature.class = FiltrosPesquisaCcpfPagamentoComponent;
      feature.parametros = {
        cpfContribuinte: this.cpf,
        ct: credito.ct
      };
      this.dynamicModalRequestService.abrirModal(feature);
    }
  }

  abreEventosCreditoTributarioFiltros(credito: CreditoTributario): void {
    const eventosCTFeature = {
      id: `CCPFEventosCTFiltros_credito_${credito.ct}`,
      title: 'CCPF - Eventos do CT',
      class: EventosCreditoTributarioFiltros,
      parametros: {
        cpfContribuinte: this.cpf,
        numeroCT: credito.ct
      }
    } as FeatureAction;

    this.abrirQuadro(eventosCTFeature);
  }

  onListarDebitos(credito: CreditoTributario): void {
    const debitoFeature = {
      id: `CCPFDebitoConta_credito_${credito.ct}`,
      title: 'CCPF - Débito em Conta',
      class: CCPFDebitoContaComponent,
      parametros: {
        cpf: this.cpf,
        credito: credito,
        parametrosPesquisa: this.parametros
      }
    } as FeatureAction;

    this.abrirQuadro(debitoFeature);
  }

  onListarDemonstrativoCotas(credito: CreditoTributario): void {
    const demonstrativoCotasFeature = {
      id: `CCPFDemonstrativoCotas_credito_${credito.ct}`,
      title: 'CCPF - Alterar Demonstrativo de Cotas',
      class: CCPFDemonstrativoCotasComponent,
      parametros: {
        cpf: this.cpf,
        credito: credito,
        atendimento: this.atendimento,
        parametrosPesquisa: this.parametros
      }
    } as FeatureAction;

    this.abrirQuadro(demonstrativoCotasFeature);
  }

  suspendeCreditoTributario(credito: CreditoTributario): void {
    this.messageService.dismissMessages();
    this.loadingService.show();
    this.ccpfService.suspenderCreditoTributario(this.cpf, credito.ct).subscribe(
      _sucesso => {
        this.loadingService.hide();
        this.messageService.showInfo(`Crédito Tributário ${credito.ct} suspenso`);
        this.ccpfService.atualizarListagemCreditos(this.parametros);
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException(`Ocorreu um erro ao suspender o Crédito Tributário ${credito.ct}`, error);
      }
    );
  }

  private abrirQuadro(feature: FeatureAction): void {

    // COMPLEMENTA A FEATURE COM INFORMAÇÕES PARA SER POSSÍVEL ABRIR COMO QUADRO DINÂMICO
    if (this.atendimento) {
      feature.visible = true;
      feature.rolesAllowed = [];
      feature.tipoMenu = TipoMenu.ATENDIMENTO;
      this.menuFeatureActionsService.activate(feature);
      return;
    }

    this.dynamicModalRequestService.abrirModal(feature);
  }

  reativaCreditoTributario(credito: CreditoTributario): void {
    this.messageService.dismissMessages();
    this.loadingService.show();
    this.ccpfService.reativarCreditoTributario(this.cpf, credito.ct).subscribe(
      _sucesso => {
        this.loadingService.hide();
        this.messageService.showInfo(`Crédito Tributário ${credito.ct} reativado`);
        this.ccpfService.atualizarListagemCreditos(this.parametros);
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException(`Ocorreu um erro ao reativar os Crédito Tributário ${credito.ct}`, error);
      }
    );
  }

  transfereCreditoTributario(credito: CreditoTributario): void {
    const transfereCreditoTributarioFeature = {
      id: 'ccpf-transferirct' + credito.ct,
      title: 'CCPF - Transferir Crédito Tributário',
      class: CcpfTransfereCreditoComponent,
      parametros: {
        cpfContribuinte: this.parametros.cpfContribuinte,
        creditoTributario: credito,
        atendimento: this.atendimento,
        parametrosPesquisa: this.parametros
      }
    } as FeatureAction;

    this.abrirQuadro(transfereCreditoTributarioFeature);
  }
}
