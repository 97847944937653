<suite-rfb-collapse title="Documentos" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <div class="container-fluid">
    <ng-container *ngIf="comprovanteInscricao">
      <ng-container
        *ngTemplateOutlet="comprovanteOpcoes; context: { comprovante: comprovanteInscricao }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="comprovanteSituacaoCadastral">
      <ng-container
        *ngTemplateOutlet="comprovanteOpcoes; context: { comprovante: comprovanteSituacaoCadastral }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="comprovanteCertidaoNarrativa">
      <ng-container
        *ngTemplateOutlet="comprovanteOpcoes; context: { comprovante: comprovanteCertidaoNarrativa }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!hasDocumentos()">
      <label class="control-label">Nenhum documento disponível</label>
    </ng-container>
  </div>
</suite-rfb-collapse>

<ng-template #comprovanteOpcoes let-comprovante="comprovante">
  <div class="row">
    <div class="col-md-7">
      <label class="form-control-static">{{ comprovante.titulo }}</label>
    </div>

    <div class="col-md-5">
      <button type="button" class="btn btn-default" (click)="onVisualizarComprovante(comprovante)">
        Visualizar
      </button>

      <button type="button" class="btn btn-default" (click)="onSalvarComprovante(comprovante)">
        Salvar
      </button>
    </div>
  </div>
</ng-template>
