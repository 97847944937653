import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

/**
 * Agrupa os valores de um array pelo atributo informado.
 */
@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

  transform<T>(values: T[], attribute: string): Group<T>[] {
    const grouped = _.groupBy(values, value => value[attribute]);
    return Object
      .keys(grouped)
      .map(key => 
        ({ key, value: grouped[key] })
      );
  }
}

interface Group<T> {
  key: any;
  value: T[];
}