import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNiPipe'
})


export class FormatNiPipe implements PipeTransform {


  /*
  Calcula DV e formata cpf9pos ou cnpj
  niBase - recebe cpf9pos ou estbPjNr
  tpNi - recebe 'CPF' ou 'CNPJ'
  estbNr - mandar só quando for CNPJ
  Exemplo de uso:
  <td *ngIf="debito.estbPjNr">{{debito.estbPjNr | formatNiPipe : 'CNPJ' : debito.estbNr}}</td>
  <td *ngIf="!debito.estbPjNr">{{debito.pfNr | formatNiPipe : 'CPF'}}</td>
  */

  transform(niBase: number, tpNi:string, estbNr: number) {

    return (tpNi=='CPF' ? FormatNiPipe.formatCPF(niBase + FormatNiPipe.calculaDVcpf(niBase)) : FormatNiPipe.formatCNPJ(FormatNiPipe.geraCnpjComDv(niBase, estbNr)));

  }

//----------------------- Métodos para CNPJ ------------------------------

  static formatCNPJ: Function = (nroCnpj: number) => {
    if (nroCnpj) {
      let pad = "00000000000000";
      let cnpj14pos = (pad + nroCnpj).slice(-pad.length);
      return cnpj14pos.substring(0, 2).concat(".")
        .concat(cnpj14pos.substring(2, 5))
        .concat(".")
        .concat(cnpj14pos.substring(5, 8))
        .concat("/")
        .concat(cnpj14pos.substring(8, 12))
        .concat("-")
        .concat(cnpj14pos.substring(12, 14));
    }
  }

  static geraCnpjComDv(estbPjNr: number, estbNr: number): string {
    if (!estbPjNr || !estbNr) {
      return null;
    }
    let estbPjNr8str = estbPjNr + "";
    let estbNr4str = estbNr + "";
    let pad = "00000000";
    estbPjNr8str = (pad + estbPjNr8str).slice(-pad.length);
    pad = "0000";
    estbNr4str = (pad + estbNr4str).slice(-pad.length);
    return estbPjNr8str + estbNr4str + FormatNiPipe.calculaDVcnpj(estbPjNr, estbNr);
  }


  static calculaDVcnpj(estbPjNr: number, estbNr: number): string {
    if (!estbPjNr || !estbNr) {
      return null;
    }
    let estbPjNr8str = estbPjNr + "";
    let estbNr4str = estbNr + "";
    let pad = "00000000";
    estbPjNr8str = (pad + estbPjNr8str).slice(-pad.length);
    pad = "0000";
    estbNr4str = (pad + estbNr4str).slice(-pad.length);
    let estb12str = estbPjNr8str + estbNr4str;
    const dv1: number = FormatNiPipe.calculateDvCnpj(estb12str.substring(0, 12));
    estb12str += dv1;
    const dv2: number = FormatNiPipe.calculateDvCnpj(estb12str.substring(0, 13));
    return dv1 + "" + dv2;
  }

  static calculateDvCnpj(base: string): number {
    const length: number = base.length;
    let sum: number = 0;
    let pos: number = length - 7;
    for (let i: number = length; i >= 1; i--) {
      sum += Number.parseInt(base.charAt(length - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    const result: number = sum % 11 < 2 ? 0 : 11 - sum % 11;
    return result;
  }

  //----------------------- Métodos para CPF ------------------------------

  static formatCPF: Function = (cpf: any) => {
    if (cpf) {
      let pad = "00000000000";
      let cpf11pos = (pad + cpf).slice(-pad.length);
      return cpf11pos.substring(0, 3).concat(".")
        .concat(cpf11pos.substring(3, 6))
        .concat(".")
        .concat(cpf11pos.substring(6, 9))
        .concat("-")
        .concat(cpf11pos.substring(9, 11));
    }
  }

  static calculaDVcpf(cpf9pos: number): string {
    let cpf9str = cpf9pos + "";
    if (cpf9str.length > 9) {
      return null;
    }
    let pad = "000000000";
    cpf9str = (pad + cpf9str).slice(-pad.length);
    const dv1: number = FormatNiPipe.calculateDvCpf(cpf9str.substring(0, 9));
    cpf9str += dv1;
    const dv2: number = FormatNiPipe.calculateDvCpf(cpf9str.substring(0, 10));
    return dv1 + "" + dv2;
  }

  static calculateDvCpf(base: string): number {
    const length: number = base.length;
    let sum: number = 0;
    for (let i: number = 0; i < length; i++) {
      sum += Number.parseInt(base.charAt(i)) * ((length + 1) - i);
    }
    let result: number = 11 - (sum % 11);
    if (result === 10 || result === 11) {
      result = 0;
    }
    return result;
  }


}
