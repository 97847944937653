import { Component, OnInit } from "@angular/core";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

@Component({
    selector: 'via-declaracao-ausente-simei-table',
    templateUrl: 'declaracao-ausente-simei-table.component.html',
    styleUrls: ['declaracao-ausente-table.component.css']
})
export class DeclaracaoAusenteSimeiTableComponent
    extends DeclaracaoAusenteTableComponent
    implements OnInit
{
    ngOnInit(): void {
    }
}
