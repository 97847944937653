import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DeclaracaoDcftDirf } from '../../../models/declaracao-dctf-dirf';

@Component({
  selector: 'via-obrigatoriedade-dirf-dctfs-table',
  templateUrl: './obrigatoriedade-dirf-dctfs-table.component.html',
  styleUrls: ['../consulta-obrigatoriedade-dirf.component.css'],
  providers: [ScopedMessageService]
})
export class ObrigatoriedadeDirfDctfsTableComponent implements OnInit {

  @Input() cnpj: string;
  @Input() anoCalendario: string;
  @Input() dctfs: DeclaracaoDcftDirf[];

  titulo: string;

  scopeId: string;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(private messageService: ScopedMessageService) { }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;

    this.titulo = `DCTFs que obrigam a entrega da DIRF do Ano Calendário ${this.anoCalendario}`;

    if (isEmpty(this.dctfs)) {
      this.messageService.showInfo('Não foram encontradas declarações que obrigam a entrega da DIRF.');
    }
  }
}
