<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">Processo</th>
        <th class="text-center">Assunto</th>
        <th class="text-center min-width">Data de cadastramento</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let processo of processos | currentPage: currentPage;" class="vertical-align-middle">
        <tr>
          <td class="text-center">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button type="button" class="btn btn-default" (click)="onDetalharProcesso(processo);">
                  Detalhar
                </button>
              </div>
            </ng-template>
          </td>
          <td class="text-left text-nowrap">{{ processo.numeroProcessoEditado }}</td>
          <td class="text-left">{{ processo.nomeAssunto | vazio }}</td>
          <td class="text-center">{{ processo.dataUltimaMovimentacao | date: 'shortDate' }}</td>
        </tr>
      </ng-container>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="4">
          <via-pagination [itemAmount]="processos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
