import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcessoProfisc } from './model/processo-profisc';

@Injectable()
export class ProcessoProfiscService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterProcessosProfiscAtivos(ni: string, numeroProtocolo: string): Observable<ProcessoProfisc[]> {
    const url = `${this.baseUrl}/situacoesfiscais/profisc/processos/Cobranca/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ProcessoProfisc[]>(url, options);
  }

  public obterProcessosProfiscSuspensos(ni: string, numeroProtocolo: string): Observable<ProcessoProfisc[]> {
    const url = `${this.baseUrl}/situacoesfiscais/profisc/processos/Exigibilidade/${ni}`;
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ProcessoProfisc[]>(url, options);
  }
}
