import { Component, Input } from '@angular/core';
import { DetalheHistoricoAlteracaoPj } from '../../models/detalhe-historico-alteracao-pj';

@Component({
  selector: 'via-detalhe-historico-pj',
  templateUrl: './detalhe-historico-pj.component.html',
  styleUrls: ['../historico-pj.component.css']
})
export class DetalheHistoricoPjComponent {

  @Input() detalhe: DetalheHistoricoAlteracaoPj;
}
