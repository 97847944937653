import { Pipe, PipeTransform } from '@angular/core';
import { CodigoDescricaoDTO } from 'src/app/cnpj/model/codigo-descricao-dto';
import { CodigoDescricaoPipe } from 'src/app/shared/pipes/codigo-descricao/codigo-descricao.pipe';

@Pipe({
  name: 'sistemasInteressePipe'
})
export class SistemasInteressePipe implements PipeTransform {

  transform(sistemas: CodigoDescricaoDTO[]): string[] {
    return sistemas.map(sistema => new CodigoDescricaoPipe().transform(sistema));
  }
}
