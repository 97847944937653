<!-- <pre>ActionPanelComponent sou {{featureAction.title}} estou visivel {{active ? active+'' : 'false'}}</pre> -->
<fieldset #fs class="service-container">
    <div style="position: relative; z-index: 99;">
        <a after-title href="javascript:void(0)" title="Fechar"
            (click)="onCloseClick(); $event.stopPropagation()">
            <span class="material-icons icon-18" style="font-size: 28px">close</span>
        </a>
    </div>
    <!-- CASO DE USO INSTANCIADO: {{featureAction?.id}} -->
    <div #container></div>
</fieldset>