import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { ConsultaPfModule } from './components/consulta-pf/consulta-pf.module';
import { DadosBasicosPFModule } from './components/dados-basicos-pf/dados-basicos-pf.module';
import { DirpfModule } from './components/dirpf/dirpf.module';
import { FiltrosPesquisaPfModule } from './components/filtros-pesquisa-pf/filtros-pesquisa-pf.module';
import { HistoricoModule } from './components/historico/historico.module';
import { TituloEleitorModule } from './components/titulo-eleitor/titulo-eleitor.module';
import { CpfSuiteRfbModule } from './cpf-suite-rfb.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    PipesModule.forRoot(),
    CpfSuiteRfbModule,
    DadosBasicosPFModule,
    TituloEleitorModule,
    HistoricoModule,
    FiltrosPesquisaPfModule,
    ConsultaPfModule,
    DirpfModule,
  ],
  providers: [
    DatePipe
  ]
})
export class CpfModule { }
