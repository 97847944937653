import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IDynamicPanel } from '../dynamic-panel/panel-interface';
import { FeatureAction } from '../model/feature';
import { ScopedMessageService } from '../services/scoped-message.service';
import { DynamicModalRequestService } from './dynamic-modal-request.service';

@Component({
  selector: 'via-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.css'],
  providers: [ScopedMessageService]
})
export class DynamicModalComponent implements OnInit{

  @Input() featureAction: FeatureAction;
  @Input() ni: string;

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild('fs') frameSet: ElementRef;

  scopeId: string;
  imprimir: boolean = false;

  private componentRef: ComponentRef<IDynamicPanel>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private dynamicModalRequestService: DynamicModalRequestService,
    private messageService: ScopedMessageService
  ) { }

  ngOnInit(): void {

    this.scopeId = this.messageService.scopeId;

    const component = this.featureAction.class;
    this.imprimir = this.featureAction.parametros?.imprimir;
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.container.createComponent(factory);
    const widget = this.componentRef.instance;
    widget.init(this.ni, this.featureAction.parametros);
  }

  onCloseClick() {
    this.dynamicModalRequestService.fecharModal();
  }

  onPrintClick(){
    let modal = this.frameSet.nativeElement.parentElement.parentElement.cloneNode(true);
    let div_impressao = document.body.appendChild(modal);
    let approot = document.getElementsByTagName('app-root')[0];
    let modais = document.getElementsByTagName('modal-container');
    modal.classList.add('imprimir');
    approot.classList.add('naoimprimir');
    for( let cont=0; cont < modais.length; cont++){
      modais.item(cont).classList.add('naoimprimir');
    }
    window.print();
    for( let cont=0 ; cont < modais.length; cont++){
      modais.item(cont).classList.remove('naoimprimir');
    }
    approot.classList.remove('naoimprimir');
    modal.classList.remove('imprimir');
    // Remove div once printed
    div_impressao.remove();
  }


}
