import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocioEmpresa, SocioEmpresaHistorico } from './model/socio-empresa';

@Injectable()
export class QuadroSocietarioService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDadosSocios(cnpj: string): Observable<SocioEmpresa[]> {
    const url = `${this.baseUrl}/cnpj/${cnpj}/socios`;
    return this.http.get<SocioEmpresa[]>(url);
  }

  public obterDadosHistoricoSocios(cnpj: string): Observable<SocioEmpresaHistorico[]> {
    const url = `${this.baseUrl}/cnpj/${cnpj}/historico-socios`;
    return this.http.get<SocioEmpresaHistorico[]>(url);
  }
}
