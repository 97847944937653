import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheOmissao } from '../models/detalhe-omissao';

@Injectable()
export class OmissosService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterOmissoesDeclaracao(cpf: string): Observable<DetalheOmissao[]> {
    const url = `${this.baseUrl}/declaracoes-irpf/${cpf}/omissoes`;
    return this.http.get<DetalheOmissao[]>(url);
  }

  public getUrlPortalIrpf(cpf: string): string {
    const url = environment.urlPortalIrpfOmissos;
    return `${url}?CPF=${cpf}&via=1`;
  }
}
