import { NgModule, Component, ViewEncapsulation, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ProcessoSief } from 'src/app/sitfis/model/processo-sief';

@Component({
  selector: 'via-arrolamento-bens-sief-table',
  templateUrl: './arrolamento-bens-sief-table.component.html',
  styleUrls: ['./arrolamento-bens-sief-table.component.css']
})
export class ArrolamentoBensSiefTableComponent {

    @Input() processos: ProcessoSief[];

    pageSize: number = 10;
    currentPage: Page = {
        number: 1,
        first: 0,
        last: this.pageSize
    }; 
    
    @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

    expandedAll(show: boolean) {
      this.rowToggleQuery.forEach(toggle => toggle.show = show);
    }    



}