<div class="modal-container">
  <suite-rfb-collapse [title]="titulo" [collapsed]="false">
    <div *ngIf="(dadosBasicos) as db; else loadingOrError" class="container-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              CNPJ
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.cnpj | formatCnpjPipe }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Nome Empresarial
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.nomeEmpresarial | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Situaçao no CNPJ
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.situacaoNoCnpj?.descricao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Nome Fantasia
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.nomeFantasia | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Nire
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.nire | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Unidade da Receita
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.unidadeReceita | codigoDescricao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Data Primeiro Estabelec.
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.dataPrimeiroEstabelecimento | date: 'shortDate' | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Data Primeiro Vínculo
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.dataPrimeiroVinculo | date: 'shortDate' | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Data de Abertura
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.dataAbertura | date: 'shortDate' | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Data da Situação
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.dataSituacao | date: 'shortDate' | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Motivo
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.motivo?.descricao | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Responsável Titular pelo CNPJ
            </label>
            <p class="form-control-static dado-formulario">
              <ni-com-link
                *ngIf="db.responsavelTitularCnpj; else templateVazio"
                [ni]="db.responsavelTitularCnpj?.cpf"
                [nome]="db.responsavelTitularCnpj?.nome"
                [tipoNI]="CPF">
              </ni-com-link>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Situação do CPF Responsável
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.situacaoCpfResponsavel?.descricao | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Qualificação do Responsável
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.qualificacaoResponsavel?.descricao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Processo Inscrição Ofício
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.processoInscricaoOficio | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Preposto da Empresa
            </label>
            <p class="form-control-static dado-formulario">
              <ni-com-link
                *ngIf="db.prepostoEmpresa; else templateVazio"
                [ni]="db.prepostoEmpresa.cpf"
                [nome]="db.prepostoEmpresa.nome"
                [tipoNI]="CPF">
              </ni-com-link>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Porte da Empresa
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.porteEmpresa | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Correio Eletrônico
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.correioEletronico | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Endereço
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.endereco | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Bairro
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.bairro | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Município
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.municipio | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              UF
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.uf | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              CEP
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.cep | cepPipe | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Telefone
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.telefone | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Segundo Telefone
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.segundoTelefone | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">
              Fax
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.fax | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Ente Federativo
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.enteFederativo | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Tipo Estatal
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.tipoEstatal | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Capital Social
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.capitalSocial | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Natureza Jurídica
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.naturezaJuridica | codigoDescricao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Atividade Econômica Preferencial
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.atividadeEconomicaPreferencial | codigoDescricao | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Atividades Econômicas Secundárias
              <span
                *ngIf="db.atividadesEconomicasSecundarias?.length > 1"
                class="expandeAtividades"
                [class.collapsed]="atividadesCollapsed"
                (click)="atividadesCollapsed = !atividadesCollapsed">
              </span>
            </label>
            <p class="form-control-static dado-formulario">
              <ng-container *ngIf="db.atividadesEconomicasSecundarias?.length > 0; else templateVazio">
                <ng-container *ngIf="db.atividadesEconomicasSecundarias.length == 1">
                  {{ db.atividadesEconomicasSecundarias[0] | codigoDescricao | vazio }}
                </ng-container>
                <div *ngIf="!atividadesCollapsed && db.atividadesEconomicasSecundarias.length > 1"
                  class="form-control-static dado-formulario atividades-container">
                  <div *ngFor="let atividade of db.atividadesEconomicasSecundarias">
                    {{ atividade | codigoDescricao | vazio }}
                  </div>
                </div>
              </ng-container>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              CNPJ Administrador
            </label>
            <p class="form-control-static dado-formulario">
              <ni-com-link
                *ngIf="db.cnpjAdministrador; else templateVazio"
                [ni]="db.cnpjAdministrador"
                [tipoNI]="CNPJ">
              </ni-com-link>
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Unidade Aduaneira
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.unidadeAduaneira | codigoDescricao | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Contador CPF
            </label>
            <p class="form-control-static dado-formulario">
              <ni-com-link
                *ngIf="db.contadorCpf; else templateVazio"
                [ni]="db.contadorCpf?.cpf"
                [nome]="db.contadorCpf.nome"
                [tipoNI]="CPF">
              </ni-com-link>
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              CRC
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.crcContadorCpf | vazio }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="label-formulario control-label">
              Contador CNPJ
            </label>
            <p class="form-control-static dado-formulario">
              <ni-com-link
                *ngIf="db.contadorCnpj; else templateVazio"
                [ni]="db.contadorCnpj.cnpj"
                [nome]="db.contadorCnpj.nome"
                [tipoNI]="CNPJ">
              </ni-com-link>
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">
              CRC
            </label>
            <p class="form-control-static dado-formulario">
              {{ db.crcContadorCnpj | vazio }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </suite-rfb-collapse>
</div>

<ng-template #templateVazio>
  {{ '' | vazio }}
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
