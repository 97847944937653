import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConsultarReceitaSuiteRfbModule } from './consultar-receita-suite-rfb.module';
import { ConsultarReceitaComponent } from './consultar-receita.component';
import { ConsultarReceitaService } from './consultar-receita.service';

@NgModule({
  declarations: [
    ConsultarReceitaComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    PipesModule,
    ConsultarReceitaSuiteRfbModule,
  ],
  providers: [
    ConsultarReceitaService
  ],
  exports: [
    ConsultarReceitaComponent
  ], 
  entryComponents: [
    ConsultarReceitaComponent
  ]
})
export class ConsultarReceitaModule { }
