import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { SharedSuiteRfbModule } from "src/app/shared/shared-suite-rfb.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ConsultaDadosBasicosPfModule } from "../consulta-pf/consulta-dados-basicos-pf/consulta-dados-basicos-pf.module";
import { CadeiaNiComponent } from "./cadeia-ni.component";
import { CadeiaNiService } from "./cadeia-ni.service";

@NgModule({
  declarations: [
    CadeiaNiComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    SharedSuiteRfbModule,
    ConsultaDadosBasicosPfModule,
  ],
  providers: [
    CadeiaNiService
  ],
  exports: [
    CadeiaNiComponent
  ]
})
export class CadeiaNiModule {}
