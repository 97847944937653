import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule } from '@serpro/ngx-suite-rfb';
import { SharedModule } from 'src/app/shared/shared.module';
import { CnpjsVinculadosModule } from 'src/app/sitfis/components/cnpjs-vinculados/cnpjs-vinculados.module';
import { CnpjsVinculadosPfComponent } from './cnpjs-vinculados-pf.component';

@NgModule({
  declarations: [
    CnpjsVinculadosPfComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CnpjsVinculadosModule,
    SuiteRfbCollapseModule
  ],
  exports: [
    CnpjsVinculadosPfComponent
  ]
})
export class CnpjsVinculadosPfModule { }
