<div class="container-fluid" *ngFor="let ausencias of ausenciasPorNi | keyvalue | currentPage:currentPage;">
  <div class="cnpj-cei">
    <div style="float: left">
      <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
    </div>
    <div>{{ ausencias.key }}</div>
  </div>
  <ng-container *ngIf="rowToggle.show">
    <div class="cnpj-cei-conteudo">
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center" colspan="14">Exercícios Ausentes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="vertical-align-middle" *ngFor="let ausencia of ausencias.value">
              <td class="text-center">{{ ausencia.ano }}</td>
              <td class="text-center" *ngFor="let apuracao of ausencia.apuracoes">{{ apuracao }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="tabela-acoes text-left">
              <td colspan="14">
                <via-pagination [itemAmount]="ausencias.value.length" [(page)]="currentPage"></via-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</div>
