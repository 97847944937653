import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjustaTamanhoNIService } from 'src/app/shared/services/ajusta-tamanho-ni.service';
import { environment } from 'src/environments/environment';
import { TipoNI } from '../../model/enum/tipo-ni';
import { InscricaoPgfn } from './model/inscricao-pgfn';

@Injectable()
export class InscricaoPgfnService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient, private ajustaTamanhoNiService: AjustaTamanhoNIService) { }

  // O campo niDevedorPrincipal é tratado pois pode trazer CPF ou CNPJ, sempre com 14 digitos completando com zeros.
  public obterInscricoesPgfnAtivas(ni: string, numeroProtocolo: string): Observable<InscricaoPgfn[]> {
    const url = `${this.baseUrl}/situacoesfiscais/pgfn/inscricoes/${ni}/Cobranca`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<InscricaoPgfn[]>(url, options).pipe(
      map(inscricoes => {
        inscricoes.forEach(inscricao => {
          inscricao.niDevedorPrincipal = inscricao.tipoDevedorPrincipal == '1' ?
            this.ajustaTamanhoNiService.transform(inscricao.niDevedorPrincipal, TipoNI.CNPJ) :
            this.ajustaTamanhoNiService.transform(inscricao.niDevedorPrincipal, TipoNI.CPF)
          inscricao.niEstabelecimento = ni;
        });
        return inscricoes;
      })
    );
  }

  // O campo niDevedorPrincipal é tratado pois pode trazer CPF ou CNPJ, sempre com 14 digitos completando com zeros.
  public obterInscricoesPgfnSuspensas(ni: string, numeroProtocolo: string): Observable<InscricaoPgfn[]> {
    const url = `${this.baseUrl}/situacoesfiscais/pgfn/inscricoes/${ni}/Exigibilidade`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<InscricaoPgfn[]>(url, options).pipe(
      map(inscricoes => {
        inscricoes.forEach(inscricao => {
          inscricao.niDevedorPrincipal = inscricao.tipoDevedorPrincipal == '1' ?
            this.ajustaTamanhoNiService.transform(inscricao.niDevedorPrincipal, TipoNI.CNPJ) :
            this.ajustaTamanhoNiService.transform(inscricao.niDevedorPrincipal, TipoNI.CPF)
          inscricao.niEstabelecimento = ni;
        });
        return inscricoes;
      })
    );
  }

  public getUrlInscricaoPgfn(inscricao: InscricaoPgfn): string {
    return `${environment.urlInscricaoPgfn}?numeroInscricao=${inscricao.numeroInscricao}&numeroIdentificacao=${inscricao.niDevedorPrincipal}`;
  }
}
