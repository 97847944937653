import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';

@NgModule({
  imports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ],
  exports: [
    SuiteRfbCollapseModule,
    SuiteRfbPaginationModule,
    SuiteRfbTableModule,
    SuiteRfbToggleModule
  ]
})
export class SocioAdminQSASuiteRfbModule { }