import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import _ from 'lodash';

@Component({
  selector: 'via-pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.css']
})
export class ViaPaginationComponent implements OnInit{

  @Input() page: Page;
  @Input() itemAmount: number;
  @Input() pageSize: number;
  @Output() pageChange = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
    this.itemAmount = this.itemAmount ?? 0;
    this.pageSize = this.pageSize ? this.pageSize : this.page.last;
    this.page.last = this.page.last > this.itemAmount ? 
      this.itemAmount : this.page.last;
  }

  carregarMais():void {
    this.page.last = this.page.last + this.pageSize > this.itemAmount ? 
      this.itemAmount : this.page.last + this.pageSize;
    this.atualizaPagina();
  }

  carregarTodos():void {
    this.page.last = this.itemAmount;
    this.atualizaPagina();
  }

  atualizaPagina(){
    this.pageChange.emit(Object.assign(_.clone(this.page)));
  }

}
