import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheDeclaracaoOperacaoImobiliaria } from '../models/detalhe-declaracao-operacao-imobiliaria';
import { ResumoDeclaracaoOperacaoImobiliaria } from '../models/resumo-declaracao-operacao-imobiliaria';

@Injectable()
export class DoiService {

  private readonly baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDeclaracoesOperacaoImobiliaria(cpf: string): Observable<ResumoDeclaracaoOperacaoImobiliaria[]> {
    const url = `${this.baseUrl}/doi/${cpf}/declaracoes`;
    return this.http.get<ResumoDeclaracaoOperacaoImobiliaria[]>(url);
  }

  public obterDetalheDeclaracaoOperacaoImobiliaria(cpf: string, identificadorDeclaracao: string): Observable<DetalheDeclaracaoOperacaoImobiliaria> {
    const url = `${this.baseUrl}/doi/${cpf}/declaracoes/${identificadorDeclaracao}`;
    return this.http.get<DetalheDeclaracaoOperacaoImobiliaria>(url);
  }
}
