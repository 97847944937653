import { Component } from '@angular/core';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CpfService } from '../../services/cpf.service';
import { TituloEleitor } from '../titulo-eleitor/model/titulo-eleitor';
import { TituloEleitorService } from '../titulo-eleitor/titulo-eleitor.service';
import { DadosBasicosPfDTO } from './model/dados-basicos-pf-dto';

@Component({
  templateUrl: './dados-basicos-pf.component.html',
  styleUrls: ['./dados-basicos-pf.component.css']
})
export class DadosBasicosPFComponent implements IDynamicPanel {

  initError: Error;
  titulo: string;
  cpf: string;

  dadosBasicos: DadosBasicosPfDTO;
  tituloEleitor: TituloEleitor;

  constructor(
    private cpfService: CpfService,
    private tituloEleitorService: TituloEleitorService,
    private messageService: ScopedMessageService,
    private dynamicModalRequestService: DynamicModalRequestService,
    private logService: LogService) { }

  init(cpf: string) {

    this.cpf = cpf;
    this.titulo = `Pessoa Física - Dados Básicos CPF: ${FormatCpfPipe.formatCPF(cpf)}`;

    this.cpfService.obterDadosBasicos(cpf).subscribe(
      resp => {
        this.dadosBasicos = resp;
      },
      err => {
        this.initError = err;
        this.logService.logAjaxError('DadosBasicosPFComponent', 'obterDadosBasicos', err);
        this.messageService.showErrorException('Ocorreu um erro ao obter os dados basicos da pessoa física', err);
      }
    );

    this.tituloEleitorService.obterDadosTituloEleitor(cpf).subscribe(
      resp => {
        this.tituloEleitor = resp;
      },
      err => {
        this.logService.logAjaxError('DadosBasicosPFComponent', 'obterDadosTituloEleitor', err);
        this.messageService.showErrorException('Ocorreu um erro ao obter o título de eleitor', err);
      }
    );
  }

  exibeDadosTituloEleitor() {
    this.dynamicModalRequestService.exibirDadosTituloEleitor(this.cpf);
  }
}
