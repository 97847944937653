import { isWarning } from './../../../../../models/erro-padrao';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FeatureAction, TipoMenu } from './../../../../../../shared/model/feature';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { Component, Input, OnInit } from '@angular/core';
import { CcitrPagamentosDarfComponenteAlocadoDTO, CcitrPagamentosDarfDTO } from '../model/ccitr-pagamentos-darf';
import { LoadingService, Page } from '@serpro/ngx-suite-rfb';
import { CCITRService } from '../../../services/ccitr.service';
import { ParametrosConsultaPagamentosDARF } from 'src/app/cobranca/models/parametros-consulta-pagamentos_darf';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { LogService } from 'src/app/shared/services/log.service';
import { CONSTANTS } from '../../../constants';
//import { CcitrAlocaPagamentoComponent } from '../../pagamento/aloca-pagamento/aloca-pagamento.component';

@Component({
  selector: 'via-detalhe-ccitr-pagamentos-darf',
  templateUrl: './detalhe-ccitr-pagamentos-darf.component.html',
  styleUrls: ['../ccitr-pagamentos-darf.component.css','../../../base/ccitr.css','./detalhe-ccitr-pagamentos-darf.component.css']
})

export class DetalheCcitrPagamentosDarfComponent implements OnInit {

  @Input() item: CcitrPagamentosDarfDTO;
  @Input() parametrosConsultaPagamento: ParametrosConsultaPagamentosDARF;
  @Input() nirf: string;
  @Input() numeroCt: string;
  @Input() atendimento = false;

  semComponente = false;

  pageSize: number = 20;
  pageSizes: number[] = [10, 20];
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private dynamicModalService: DynamicModalRequestService,
    private cCITRService: CCITRService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService
) {

  }

  ngOnInit(): void {
    this.semComponente = isEmpty(this.item.componentes) || isEmpty(this.item.componentes[0].numero);
  }

  desalocarPagamento(componente: CcitrPagamentosDarfComponenteAlocadoDTO) {
    this.loadingService.show();

    let ni = this.parametrosConsultaPagamento.cnpjContribuinte;

    if (this.parametrosConsultaPagamento.cpfContribuinte) {
      ni = this.parametrosConsultaPagamento.cpfContribuinte;
    }

    this.cCITRService.desalocarPagamento(ni, this.nirf, this.numeroCt, componente.numero, this.item.numeroPagamento).subscribe(
      _sucess => {
        this.messageService.showInfo('Operação Desalocar Pagamento realizada com sucesso', `Pagamento ${this.item.numeroPagamento}`);
      },
      (err: HttpErrorResponse) => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          return null;
        } else {
          this.logService.logError('DetalheCcitrPagamentosDarfComponent', 'detalheCcitrPagamentosDarfComponent', err);
          this.messageService.showErrorException('Ocorreu um erro ao tentar executar a operação Desalocar Pagamento ', err);
        }
      }
    ).add(() => {
      this.loadingService.hide();
    })
 };


}






