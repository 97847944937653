import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ShortcutService } from "@serpro/ngx-suite-rfb";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'via-menu-toggler',
  templateUrl: './menu-toggler.component.html',
  styleUrls: ['./menu-toggler.component.css']
})
export class ViaMenuTogglerComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private shortcutService: ShortcutService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.registerShortcut();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleMenu(): void {
    this.document.body.classList.toggle('menu-lateral-ativo');
  }

  private registerShortcut(): void {
    this.shortcutService.registerKey('/')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.toggleMenu());
  }
}
