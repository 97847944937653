<suite-rfb-collapse [title]="titulo">
  <div *ngIf="(parcelamentosObservable$ | async) as parcelamentos; else loadingOrError">
    <div class="container-fluid">
      <via-parcelamento-sipade-table
        [parcelamentos]="parcelamentos"
        [exigibilidadeSuspensa]="exigibilidadeSuspensa">
      </via-parcelamento-sipade-table>
    </div>
  </div>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="(loadingError$ | async); else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
