import { Component, Input } from '@angular/core';
import { ParametrosPesquisaTituloEleitor } from '../../../titulo-eleitor/model/parametros-pesquisa-titulo-eleitor';

@Component({
  selector: 'via-parametros-pesquisa-titulo-eleitor',
  templateUrl: './parametros-pesquisa-titulo-eleitor.component.html',
  styleUrls: ['./parametros-pesquisa-titulo-eleitor.component.css']
})
export class ParametrosPesquisaTituloEleitorComponent {

  @Input() parametrosPesquisa: ParametrosPesquisaTituloEleitor;
}
