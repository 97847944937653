<div class="table-responsive">
  <table suiteRfbTable>
    <caption>
      Processo: {{ numeroProcesso | formatProcessoPipe | vazio }}
    </caption>

    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">
          <suite-rfb-toggle
            title="Expandir/Contrair Todos"
            (expanded)="expandedAll($event)">
          </suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Receita</th>
        <th class="text-center min-width">PA/Ex.</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Saldo Devedor</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let debito of debitos | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-popover-button
              #botaoAcoes
              [template]="menuAcoes">
            </suite-rfb-popover-button>

            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">

                <button
                  *ngIf="debito.inOrdemDarf == ordemDARF.DAS"
                  type="button"
                  class="btn btn-default"
                  (click)="onEmitirDas(debito)">
                  Emitir DAS
                </button>

                <button
                  *ngIf="debito.inOrdemDarf == ordemDARF.DARF_NUMERADO || debito.inOrdemDarf == ordemDARF.DARF_COMUM"
                  type="button"
                  class="btn btn-default"
                  (click)="onEmitirDarf(debito)">
                  Emitir DARF
                </button>

                <button
                  *ngIf="debito.inOrdemDarf == ordemDARF.CONSOLIDA || debito.inOrdemDarf == ordemDARF.NAO_EMITE_CONSOLIDA"
                  type="button"
                  class="btn btn-default"
                  (click)="onEmitirDarf(debito)">
                  Emitir DARF/DAS
                </button>
              </div>
            </ng-template>
          </td>

          <td class="text-center">
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>

          <td class="text-center">
            <via-codigo-receita
              [codigo]="debito.codigoReceita">
            </via-codigo-receita>
          </td>

          <td class="text-center">
            {{ debito.dataPaExercicio | dataPAExercicioPipe: debito.tipoPA }}
          </td>

          <td class="text-right">
            {{ debito.valorOriginal | formatCurrency }}
          </td>

          <td class="text-right">
            {{ debito.valorSaldoDevedor | formatCurrency }}
          </td>
        </tr>

        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="6">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Número do Débito
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroDebito | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Situação do Débito
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.situacaoDebito | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Data de Vencimento
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.dataVencimento | date: 'dd/MM/yyyy' | vazio }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Auto de Infração
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroAutoInfracao | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Perdcomp
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroPerdcomp | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      DOI
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroNotificacaoDoi | vazio }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Código de Extensão da Receita
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.codigoExtensaoReceita | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      NI Prestador
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.niPrestador | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Número CNO
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.numeroCno | vazio }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Tipo de Solidariedade
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.tipoSolidariedade | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      Situação do Vínculo
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.situacaoVinculo | vazio }}
                    </p>
                  </div>

                  <div class="col-md-4">
                    <label class="label-formulario control-label">
                      NI Devedor Principal
                    </label>
                    <p class="form-control-static dado-formulario">
                      {{ debito.niDevedorPrincipal | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="6">
          <via-pagination [itemAmount]="debitos?.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
