<label class="control-label label-ausencias">Exercícios Ausentes</label>
<div class="grid-1-col">
  <ng-container *ngFor="let periodo of periodosOmissao | currentPage:currentPage;">
    <div class="periodo-ausente">{{ periodo }}</div>
  </ng-container>
</div>

<label class="control-label label-total-de-registros">
  <via-pagination [itemAmount]="periodosOmissao.length" [(page)]="currentPage"></via-pagination>
</label>
