import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vazio'
})
export class VazioPipe implements PipeTransform {

  public transform(valor: any, vazios = [], valorNaoVazio = valor, valorVazio = '-'): string {
    const strValue = this.toString(valor);
    for (let e of vazios) {
      if (this.toString(e) === strValue) {
        return valorVazio;
      }
    }
    return strValue ? valorNaoVazio : valorVazio;
  }

  private toString(v: any): string {
    if (v) {
      switch (typeof v) {
        case 'object':
          return v.toString();
        case 'string':
          return v.trim();
      }
    }
    return v;
  }
}