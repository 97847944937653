import { TipoNI } from "./enum/tipo-ni"

export class ParametrosDarf
{
    protocolo: string;
    tipoNi: TipoNI;
    ni: string;
    sistemaOrigem: SistemaOrigemDarf;
    numeroDebito: string;
    numeroProcesso: string;
    numeroImovel: string;
    codigoReceita: string;
    numeroComponente: string;
    exercicio: string;
}

export enum SistemaOrigemDarf {
    SFCOB = 'SFCOB',
    CCPF = 'CCPF',
    CCITR = 'CCITR',
    PROF = 'PROF',
    SFPRO = 'SFPRO',
    SFEXC = 'SFEXC',
}
