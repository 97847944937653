import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InfoCadastralPessoaJuridicaDTO } from './model/info-cadastral-pj';

@Injectable()
export class InfoCadastraisPJService {

    private baseUrl = environment.APIEndpoint;

    constructor(private http: HttpClient) { }
    
	public obterInformacoesCadastraisPJ(cnpj: string, numeroProtocolo: string): Observable<InfoCadastralPessoaJuridicaDTO> {
		const url = `${this.baseUrl}/situacoesfiscais/pessoasjuridicas/${cnpj}/informacoescadastrais`;
		const options = {
			params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
		};
		return this.http.get<InfoCadastralPessoaJuridicaDTO>(url, options);
	}
}