import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { orderBy } from 'lodash';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Componente, CreditoTributario } from 'src/app/cobranca/models/credito-tributario';
import { CriterioSelecao } from 'src/app/cobranca/models/parametros-pesquisa';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CCPFService } from '../../../services/ccpf.service';
import { Pagamento } from '../models/pagamento';
import { ParametrosFiltroPagamentoCcpf } from '../models/parametros-pesquisa';

@Component({
  templateUrl: './alocacao-debito.component.html',
  styleUrls: ['./alocacao-debito.component.css'],
  providers: [ScopedMessageService]
})
export class CCPFAlocacaoDebitoComponent implements IDynamicPanel, OnDestroy {

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  isModal = false;
  pagamento: Pagamento;
  parametrosPesquisa: ParametrosFiltroPagamentoCcpf;

  titulo: string;

  scopeId: string;

  loadingError$ = new Subject<boolean>();
  creditos$: Observable<CreditoTributario[]>;

  initError: Error;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  private featureEventSub: Subscription;

  constructor(
    private ccpfService: CCPFService,
    private loadingService: LoadingService,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.pagamento = params?.pagamento as Pagamento;
    this.parametrosPesquisa = params?.parametrosPesquisa as ParametrosFiltroPagamentoCcpf;
    this.isModal = this.parametrosPesquisa.isModal;

    this.titulo = `CCPF - Alocar Débito - Pagamento: ${this.pagamento.numeroPagamento}`;

    this.scopeId = this.messageService.scopeId;

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        const parentFeatureId = `ccpf-consultarpagamentos${this.parametrosPesquisa.cpfContribuinte}`;
        if (featureEvent.active == false
          && (feature.id == 'CcpfConsultar' || feature.id == parentFeatureId)
          && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharAlocacaoDebito();
          this.featureEventSub?.unsubscribe();
        }
      }
    );

    this.obterCreditosTributarios();
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }

  alocarDebito(item: { componente: Componente, valorAlocar: string }, credito: CreditoTributario): void {
    this.messageService.dismissMessages();
    this.loadingService.show();

    const cpf = this.parametrosPesquisa.cpfContribuinte;
    const numeroPagamento = this.pagamento.numeroPagamento;
    const debito = credito.ct;
    const componente = item.componente.numero;
    const valorAlocar = item.valorAlocar;

    this.ccpfService.alocarPagamento(cpf, numeroPagamento, debito, componente, valorAlocar)
      .pipe(
        catchError(error => {
          this.initError = error;
          this.messageService.showErrorException(`Erro ao alocar débito ao pagamento ${numeroPagamento}`, error);
          return of<string>();
        }),
        finalize(() => this.loadingService.hide())
      )
      .subscribe(
        _sucesso => {
          this.messageService.showInfo('Débito alocado com sucesso', `Débito ${debito} - Componente ${componente}`);
          this.obterCreditosTributarios();
        }
      );
  }

  private obterCreditosTributarios(): void {
    this.creditos$ = this.ccpfService.consultarCreditosTributarios({
      cpfContribuinte: this.parametrosPesquisa.cpfContribuinte,
      criterioSelecao: CriterioSelecao.CREDITOS_TODOS,
      exercicio: '',
      exercicioFinal: '',
      isModal: undefined
    },
    true ).pipe(
      map(response => orderBy(response, ['exercicio'], 'desc')),
      catchError(error => {
        this.loadingError$.next(true);
        this.initError = error;
        return of<CreditoTributario[]>();
      })
    );
  }

  private fecharAlocacaoDebito(): void {
    const feature = {
      id: `CCPF_Alocar_Debito_pagamento_${this.pagamento.numeroPagamento}`,
      title: 'CCPF - Alocar Débito',
      class: CCPFAlocacaoDebitoComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }
}
