<div class="table-responsive">
    <table suiteRfbTable>
        <thead>
            <tr>
                <th class="text-center min-width">
                    <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
                </th>
                <th class="text-center min-width">NI</th>
                <th class="text-center min-width">Processo</th>
                <th class="text-center">Situação</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of processos | currentPage:currentPage;">
            <tr class="vertical-align-middle">
                <td>
                    <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>                
                <td class="text-center text-nowrap"><ni-com-link [ni]="item.ni" [tipoNI]="item.tipoNi"></ni-com-link></td>
                <td class="text-center text-nowrap">{{ item.numeroProcesso | formatProcessoPipe:item.inTamanhoProcesso | vazio }}</td>
                <td class="text-left">{{item.situacao | vazio}}</td>
            </tr>
            <!-- Data Inicio	Validade da Análise da Medida Judicial	Prazo Final	Localização -->
            <ng-container *ngIf="rowToggle.show">
                <tr></tr>
                <tr>
                  <td colspan="4">
                    <div class="contorno">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="label-formulario control-label">
                                    Data Início
                                </label>
                                <p class="form-control-static dado-formulario">
                                    {{item.dataInicio | date:'shortDate' | vazio}}
                                </p>
                            </div>
                            <div class="col-md-9">
                                <label class="label-formulario control-label">
                                    Validade da Análise da Medida Judicial
                                </label>
                                <p class="form-control-static dado-formulario">
                                    {{item.dataValidadeAnaliseMedidaJudicial | date:'shortDate' | vazio}}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="label-formulario control-label">
                                    Prazo Final
                                </label>
                                <p class="form-control-static dado-formulario">
                                    {{item.dataPrazoFinal | date:'shortDate' | vazio}}
                                </p>
                            </div>
                            <div class="col-md-9">
                                <label class="label-formulario control-label">
                                    Localização
                                </label>
                                <p class="form-control-static dado-formulario">
                                    {{item.localizacao | vazio}}
                                </p>
                            </div>
                        </div>
                    </div>
                  </td>
                </tr>
            </ng-container>
            </ng-container>
        </tbody>
        <tfoot>
            <tr class="tabela-acoes text-left">
                <td colspan="4">
                    <via-pagination [itemAmount]="processos.length" [(page)]="currentPage"></via-pagination>
                </td>
            </tr>
        </tfoot>
    </table>    
</div>
