<div class="modal-container">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

  <suite-rfb-collapse [title]="titulo">
    <ng-container *ngIf="resultadosPesquisa?.length > 0; else loadingOrError;">
      <ng-container *ngIf="exibirResultadoUnico()">
        <div *ngIf="resultadosPesquisa[0]" class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">Titulo de Eleitor</label>
              <p class="form-control-static dado-formulario">{{ resultadosPesquisa[0].numeroTitulo }}</p>
            </div>

            <div class="col-md-6">
              <label class="label-formulario control-label">Situação</label>
              <p class="form-control-static dado-formulario">{{ resultadosPesquisa[0].descricaoSituacao }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">Nome</label>
              <p class="form-control-static dado-formulario"> {{ resultadosPesquisa[0].nome }} </p>
            </div>

            <div class="col-md-6">
              <label class="label-formulario control-label">Data de Nascimento</label>
              <p class="form-control-static dado-formulario">
                {{ resultadosPesquisa[0].dataNascimento | outputMask: '99/99/9999' | vazio }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label class="label-formulario control-label">Nome da Mãe</label>
              <p class="form-control-static dado-formulario">{{ resultadosPesquisa[0].nomeMae }}</p>
            </div>

            <div class="col-md-6">
              <label class="label-formulario control-label">Data de Atualização</label>
              <p class="form-control-static dado-formulario">
                {{ resultadosPesquisa[0].dataAtualizacao | outputMask: '99/99/9999' | vazio }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!exibirResultadoUnico()">
        <via-consulta-titulo-eleitor-table
          [parametrosPesquisa]="parametrosPesquisa"
          [resultadosPesquisa]="resultadosPesquisa"
          (consultarDadosCadastrais)="onConsultarDadosCadastrais($event)">
        </via-consulta-titulo-eleitor-table>
      </ng-container>
    </ng-container>
  </suite-rfb-collapse>
</div>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
