import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { User, UserService, WindowService } from "@serpro/ngx-suite-rfb";
import _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FeatureAction } from "src/app/shared/model/feature";
import { FEATURES_MENU_PRINCIPAL } from "src/app/workplace/components/atendimento/atendimento.features";
import { ViaMenuService } from "../../services/menu.service";

@Component({
  selector: 'via-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class ViaMenuComponent implements OnInit, OnDestroy {

  features: FeatureAction[];

  collapsed = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private menuService: ViaMenuService,
    @Inject(DOCUMENT) private document: Document,
    private windowService: WindowService) { }

  ngOnInit(): void {
    this.userService.getUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(user => {
        if (user) {
          this.setFeatures(user);
        }
      });

    this.handleOutsideClick();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  search(searchTerms: string = ''): void {
    if (this.features) {
      if (searchTerms != '') {
        this.features.forEach(feature => this.filtrarPorTermos(feature, searchTerms));
        this.expandirMenu();
      } else {
        this.features.forEach(feature => this.tornarVisivel(feature));
        this.atualizarFeatures();
      }
    }
  }

  private setFeatures(user: User) {
    const featuresMenuPrincipal = _.cloneDeep(FEATURES_MENU_PRINCIPAL);
    featuresMenuPrincipal.forEach(feature => this.menuService.ajustarVisibilidade(feature, user));
    this.features = featuresMenuPrincipal.filter(feature => feature.visible);
  }

  private filtrarPorTermos(featureAction: FeatureAction, searchTerms: string): void {
    const featureTitle = featureAction.title.toUpperCase();
    if (featureTitle.includes(searchTerms.toUpperCase())) {
      if (featureAction.children) {
        featureAction.children.forEach(feature => this.tornarVisivel(feature));
      }

      featureAction.visible = true;
      return;
    }

    if (featureAction.children) {
      featureAction.children.forEach(feature => this.filtrarPorTermos(feature, searchTerms));

      if (featureAction.children.some(feature => feature.visible)) {
        featureAction.visible = true;
        return;
      }
    }

    featureAction.visible = false;
  }

  private tornarVisivel(featureAction: FeatureAction) {
    featureAction.visible = true;
    if (featureAction.children) {
      featureAction.children.forEach(feature => this.tornarVisivel(feature));
    }
  }

  private expandirMenu(): void {
    this.features.forEach(feature => this.expandirFeature(feature));
    this.atualizarFeatures();
  }

  private expandirFeature(featureAction: FeatureAction) {
    featureAction.collapsed = false;
    if (featureAction.children) {
      featureAction.children.forEach(feature => this.expandirFeature(feature));
    }
  }

  private atualizarFeatures(): void {
    this.features = _.cloneDeep(this.features);
  }

  private handleOutsideClick(): void {
    this.windowService.get()
      .addEventListener('click', (event: MouseEvent) => {
        if (this.document.body.classList.contains('menu-lateral-ativo')) {
          const target: Node = event.target as Node;
          const navegacao: Node = this.document.getElementById('navegacao');
          const menu: Node = this.document.getElementById('suite-rfb-menu-container');
          const outsideClick = !menu.contains(target) && !navegacao.contains(target);
          if (outsideClick) {
            this.document.body.classList.remove('menu-lateral-ativo');
          }
        }
      });
  }
}
