import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Page } from '@serpro/ngx-suite-rfb';
import { DeclaracaoIrpfMalhaDTO } from '../model/declaracao-irpf-malha';

@Component({
  selector: 'via-declaracao-irpf-malha-table',
  templateUrl: './declaracao-irpf-malha-table.component.html',
  styleUrls: ['./declaracao-irpf-malha-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DeclaracaoIrpfMalhaTableComponent {

  @Input() declaracoes: DeclaracaoIrpfMalhaDTO[];

  @Output() emitirExtrato = new EventEmitter<DeclaracaoIrpfMalhaDTO>();
  @Output() abrirPortalIrpf = new EventEmitter<void>();
  @Output() abrirPortalSiefWeb = new EventEmitter<void>();

  // paginacao
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  public onEmitirExtrato(debito: DeclaracaoIrpfMalhaDTO): void {
    this.emitirExtrato.emit(debito);
  }

  public onAbrirPortalIrpf(): void {
    this.abrirPortalIrpf.emit();
  }

  public onAbrirPortalSiefWeb(): void {
    this.abrirPortalSiefWeb.emit();
  }
}
