<suite-rfb-collapse [title]="titulo">
  <loading-animation *ngIf="!debitosAgrupados && !initError; else tabelaDebitos;"></loading-animation>
</suite-rfb-collapse>
<ng-template #tabelaDebitos>
  <div class="container-fluid" *ngFor="let debitosByNi of debitosAgrupados | keyvalue; last as isLast">
    <via-debito-sicob-table
      [debitos]="debitosByNi.value"
      [isPessoaJuridica]="isPessoaJuridica"
      [isPessoaFisica]="isPessoaFisica"
      [isAtivos]="isAtivos"
      [isSuspensos]="isSuspensos">
    </via-debito-sicob-table>
  </div>
</ng-template>
