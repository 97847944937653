<fieldset #fs [ngClass]="{ 'service-container': isModal }">
  <div style="position: relative; z-index: 99;">
    <a *ngIf="isModal" after-title href="javascript:void(0)" title="Fechar" (click)="onFecharModal()">
      <span
        class="material-icons icon-18"
        style="font-size: 28px; position: absolute; right: 0;">
        close
      </span>
    </a>
  </div>

  <suite-rfb-collapse [title]="titulo" [collapsed]="!isModal" (collapsedChange)="onCarregarDados($event)">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>

    <div class="container-omissoes">
      <ng-container *ngIf="omissoes?.length > 0; else loadingOrError;">
        <ng-container *ngFor="let omissao of omissoes">
          <div class="omissao">
            <div class="omissao-toggle" style="float: left;">
              <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
            </div>
            <div class="omissao-titulo">{{ omissao.anoCalendario }}</div>
          </div>

          <ng-container *ngIf="rowToggle.show">
            <div class="omissao-conteudo">
              <via-omissos-pf-table
                [detalheOmissao]="omissao"
                (abrirPortalIrpf)="abrirPortalIrpf()">
              </via-omissos-pf-table>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </suite-rfb-collapse>
</fieldset>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
