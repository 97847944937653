import { Component, OnInit } from "@angular/core";
import _ from "lodash";
import { AusenciaDeclaracao } from "../model/declaracao-ausente";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

const nomesPeriodos = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', '13°'];

const gdfVazio = '00000000000000';

interface AusenciaObrap {
  ano: string;
  apuracoes: string[];
}

interface DeclaracaoAusenteObrapGroupedByGdf {
  [gdf: string]: AusenciaObrap[];
}

@Component({
  selector: 'via-declaracao-ausente-obrap-table',
  templateUrl: 'declaracao-ausente-obrap-table.component.html',
  styleUrls: [
    'declaracao-ausente-obrap-table.component.css',
    'declaracao-ausente-table.component.css'
  ]
})
export class DeclaracaoAusenteObrapTableComponent
  extends DeclaracaoAusenteTableComponent
  implements OnInit {

  ausenciasPorGdf: DeclaracaoAusenteObrapGroupedByGdf;

  ngOnInit(): void {
    this.ausenciasPorGdf = this.montarAusenciasPorGdf();
  }

  private montarAusenciasPorGdf(): DeclaracaoAusenteObrapGroupedByGdf {
    const ausenciasAgrupadas = this.agruparAusenciasPorGdf(this.ausenciasDeclaracao);
    return this.montarAusenciasAgrupadas(ausenciasAgrupadas);
  }

  private agruparAusenciasPorGdf(ausenciasOrdenadas: AusenciaDeclaracao[]): _.Dictionary<AusenciaDeclaracao[]> {
    return _.groupBy(ausenciasOrdenadas, ausencia => ausencia.inscricaoGdf === gdfVazio ? this.ni : ausencia.inscricaoGdf);
  }

  private montarAusenciasAgrupadas(ausenciasAgrupadas: _.Dictionary<AusenciaDeclaracao[]>): DeclaracaoAusenteObrapGroupedByGdf {
    return _.mapValues(ausenciasAgrupadas, ausencias => {
      const ausenciasObrap: AusenciaObrap[] = [];

      _.forEach(ausencias, ausencia => {
        let ausenciaObrap: AusenciaObrap = {
          ano: ausencia.periodoApuracaoOmissao[0].slice(0, 4),// O período vem no formato YYYYMM, ex.: 202108
          apuracoes: new Array(13).fill('-')
        };

        _.forEach(ausencia.periodoApuracaoOmissao, periodo => {
          const mes = parseInt(periodo.slice(-2));
          ausenciaObrap.apuracoes[mes - 1] = nomesPeriodos[mes - 1];
        });

        ausenciasObrap.push(ausenciaObrap);
      });

      return ausenciasObrap;
    });
  }
}
