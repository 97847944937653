import { Component } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';

@Component({
  templateUrl: './filtros-pesquisa-pf.component.html',
  styleUrls: ['./filtros-pesquisa-pf.component.css']
})
export class FiltrosPesquisaPfComponent implements IDynamicPanel {

  titulo = 'Pessoa Física - Consultar - Filtros de Pesquisa';

  initError: Error;

  init(): void { }
}
