import { Component, OnInit } from "@angular/core";
import _ from "lodash";
import { DetalheImovelRuralComponent } from "src/app/cafir/components/detalhe/detalhe-imovel-rural.component";
import { DynamicModalRequestService } from "src/app/shared/dynamic-modal/dynamic-modal-request.service";
import { FeatureAction } from "src/app/shared/model/feature";
import { DeclaracaoAusenteTableComponent } from "./declaracao-ausente-table.component";

interface DeclaracaoAusenteDitrGroupedByNirf {
  [nirf: string]: string[];
}

@Component({
  selector: 'via-declaracao-ausente-ditr-table',
  templateUrl: 'declaracao-ausente-ditr-table.component.html',
  styleUrls: [
    'declaracao-ausente-ditr-table.component.css',
    'declaracao-ausente-table.component.css'
  ]
})
export class DeclaracaoAusenteDitrTableComponent
  extends DeclaracaoAusenteTableComponent
  implements OnInit {

  ausenciasPorNirf: DeclaracaoAusenteDitrGroupedByNirf;

  constructor(private dynamicModalService: DynamicModalRequestService) { super(); }

  ngOnInit(): void {
    this.ausenciasPorNirf = this.montarAusenciasPorNirf();
  }

  onAbrirCafirDetalhe(nirf: string): void {
    const detalheImovelFeature = {
      id: `CONSULTA_DETALHE_IMOVEL_RURAL_${nirf}`,
      class: DetalheImovelRuralComponent,
      title: 'Consulta Detalhe Imóvel Rural',
      parametros: {
        ni: this.ni,
        nirf: nirf,
        isModal: true
      }
    } as FeatureAction;

    this.dynamicModalService.abrirModal(detalheImovelFeature);
  }

  private montarAusenciasPorNirf(): DeclaracaoAusenteDitrGroupedByNirf {
    const ausenciasAgrupadas = _.groupBy(this.ausenciasDeclaracao, ausencia => ausencia.codigoImovel);

    return _.mapValues(ausenciasAgrupadas, ausencias => {
      const apuracoes: string[] = [];
      _.forEach(ausencias, ausencia => apuracoes.push(...ausencia.periodoApuracaoOmissao));
      return apuracoes;
    });
  }
}
