<table suiteRfbTable>
  <thead>
    <tr>
      <th class="text-center min-width">
        <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
      </th>
      <th class="text-center min-width">NI</th>
      <th class="text-center">Qualificação</th>
      <th class="text-center min-width">% Capital Social</th>

    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let socio of socios | currentPage:currentPage;">
      <tr class="vertical-align-middle">
        <td>
          <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
        </td>
        <td class="text-center">
          <ni-com-link *ngIf="socio.tipoSocio === '1' || socio.tipoSocio === '2'" [ni]="socio.niSocioAdministrador">
          </ni-com-link>
        </td>
        <td class="text-left">{{ socio.qualificacaoSocio }}</td>
        <td class="text-center">{{ socio.participacaoCapitalSocial }}</td>
      </tr>
      <ng-container *ngIf="rowToggle.show">
        <tr></tr>
        <tr>
          <td colspan="4">
            <div class="contorno">
              <div class="row">
                <div class="col-md-6">
                  <label class="label-formulario control-label">
                    Nome
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.nomeSocioAdministrador | vazio }}
                  </p>
                </div>
                <div class="col-md-6">
                  <label class="label-formulario control-label">
                    % Capital Votante
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.participacaoCapitalVotante | vazio }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="label-formulario control-label">
                    Representante Legal
                  </label>
                  <p class="form-control-static dado-formulario">
                    <ni-com-link *ngIf="socio.cpfRepresentante" [ni]="socio.cpfRepresentante" [tipoNI]="CPF"></ni-com-link>
                  </p>
                </div>
                <div class="col-md-3">
                  <label class="label-formulario control-label">
                    Qualificação do Representante
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.qualificacaoRepresentante | vazio }}
                  </p>
                </div>
                <div class="col-md-3">
                  <label class="label-formulario control-label">
                    País
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.nomePais | vazio }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="label-formulario control-label">
                    Situação Cadastral
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.situacaoCadastral | vazio }}
                  </p>
                </div>
                <div class="col-md-6">
                  <label class="label-formulario control-label">
                    Data da Situação Cadastral
                  </label>
                  <p class="form-control-static dado-formulario">
                    {{ socio.dataSituacaoCadastral | date: 'shortDate' | vazio }}
                  </p>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
  <tfoot>
    <tr class="tabela-acoes text-left">
      <td colspan="4">
        <via-pagination [itemAmount]="socios.length" [(page)]="currentPage"></via-pagination>
      </td>
    </tr>
  </tfoot>
</table>
