<table suiteRfbTable>
  <caption class="caption-ccpf">Débitos Alocados</caption>
  <ng-container *ngIf="!semDebito; else nenhumDebito;">
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)">
          </suite-rfb-toggle>
        </th>
        <th class="text-center min-width">No. Débito</th>
        <th class="text-center min-width break-spaces">Período Apuração</th>
        <th class="text-center min-width">Exercício</th>
        <th class="text-center">Valor Débito</th>
        <th class="text-center">Saldo Devedor</th>
        <th class="text-center min-width break-spaces">Unidade Monetária</th>
        <th class="text-center">Situação</th>
        <th class="text-center min-width">No. Processo</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let debito of pagamento.debitosAlocados | currentPage: currentPage;">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">{{ debito.numeroDebito | vazio }} </td>
          <td class="text-center">{{ debito.periodoApuracao | formatDataPipe }} </td>
          <td class="text-center">{{ debito.exercicio | vazio }}</td>
          <td class="text-right">{{ debito.valorOriginario | formatCurrency }}</td>
          <td class="text-right">{{ debito.saldoDevedorDebito | formatCurrency }}</td>
          <td class="text-center">{{ debito.unidadeMonetaria | vazio }}</td>
          <td class="text-left">{{ debito.situacao | vazio }}</td>
          <td class="text-right">{{ debito.numeroProcesso | vazio }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="9">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-12">
                    <via-ccpf-componentes-debito
                      [componentes]="debito.componente"
                      [cpfContribuinte]="cpfContribuinte"
                      [numeroDebito]="debito.numeroDebito"
                      [atendimento]="atendimento">
                    </via-ccpf-componentes-debito>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="9">
          <via-pagination [itemAmount]="pagamento.debitosAlocados.length" [(page)]="currentPage">
          </via-pagination>
        </td>
      </tr>
    </tfoot>
  </ng-container>
  <ng-template #nenhumDebito>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="9">
        Nenhum débito encontrado para este pagamento.
      </td>
    </tr>
  </ng-template>
</table>
