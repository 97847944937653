import { Component } from '@angular/core';

@Component({
  selector: 'via-service-status',
  templateUrl: './service-status.component.html',
  styleUrls: ['./service-status.component.css']
})
export class ServiceStatusComponent {

  constructor() { }

}
