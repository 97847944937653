<div class="contorno">
  <div class="row" style="padding: 5px 5px 10px 5px;">
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Valor Bloqueado Depuração
      </label>
      <p class="form-control-static dado-formulario">
        {{ item?.valorBloqueadoDepuracao | formatCurrency| vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Valor Reservado
      </label>
      <p class="form-control-static dado-formulario">
        {{ item?.valorReservado | vazio }}
      </p>
    </div>
    <div class="col-md-4">
      <label class="label-formulario control-label">
        Valor Bloqueado Sinal
      </label>
      <p class="form-control-static dado-formulario">
        {{ item?.valorBloqueadoSinal | formatCurrency | vazio }}
      </p>
    </div>
    <div class="row" style="padding: 5px 5px 10px 5px;">
      <div class="col-md-4">
        <label class="label-formulario control-label">
          Valor Restituído
        </label>
        <p class="form-control-static dado-formulario">
          {{ item?.valorRestituido | formatCurrency | vazio }}
        </p>
      </div>
      <div class="col-md-4">
        <label class="label-formulario control-label">
          Total Indisponível
        </label>
        <p class="form-control-static dado-formulario">
          {{ item?.totalIndisponivel | formatCurrency | vazio }}
        </p>
      </div>
  </div>

  <ng-container *ngIf="item?.detalhes && item?.detalhes.length > 0">
    <div class="table-responsive">
      <table suiteRfbTable>
        <thead>
          <tr>
            <th class="text-center coluna-12pc">Descrição</th>
            <th class="text-center coluna-22pc">DARF</th>
            <th class="text-center coluna-22pc">Alocação Automática</th>
            <th class="text-center coluna-22pc">Alocação Manual</th>
            <th class="text-center coluna-22pc">Saldo Disponível</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let detalhe of item?.detalhes  | currentPage: currentPage">
            <tr class="vertical-align-middle">
              <td class="text-left">{{ detalhe?.descricao | vazio }}</td>
              <td class="text-right">{{ detalhe?.darf | formatCurrency | vazio }}</td>
              <td class="text-right">{{ detalhe?.alocacaoAutomatica | formatCurrency | vazio }}</td>
              <td class="text-right">{{ detalhe?.alocacaoManual | formatCurrency | vazio }}</td>
              <td class="text-right">{{ detalhe?.saldoDisponivel | formatCurrency | vazio }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>


    <div class="table-responsive">
      <table suiteRfbTable class="tabelaInterna" [class.tabela_largura_minima]="this.atendimento">
        <caption style="text-align:left; padding-left:10px;">Componentes</caption>
        <ng-container *ngIf="!semComponente; else nenhumComponente;">
          <thead>
            <tr>
              <th class="text-center min-width">
                <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
              </th>
              <th class="text-center">Número</th>
              <th class="text-center">Exercício</th>
              <th class="text-center">Tipo Lançamento</th>
              <th class="text-center">Data de Vencimento</th>
              <th class="text-center">Data Alocação</th>
              <th class="text-center">Valor Original</th>
              <th class="text-center">Situação</th>
              <th class="text-center">Tipo Alocação</th>
              <th class="text-center">Data Original</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of item?.componentes | currentPage: currentPage">
              <tr class="vertical-align-middle">
                <td>
                  <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
                </td>
                <td class="text-center">{{ item?.numero | vazio }}</td>
                <td class="text-center">{{ item?.exercicio | vazio }}</td>
                <td class="text-left">{{ item?.tipoLancamento | vazio }}</td>
                <td class="text-center">{{ item?.dataVencimento | formatDataPipe | vazio }}</td>
                <td class="text-center">{{ item?.dataAlocacao | formatDataPipe | vazio }}</td>
                <td class="text-right">{{ item?.valorOriginal | formatCurrency | vazio }}</td>
                <td class="text-left">{{ item?.situacao | vazio }}</td>
                <td class="text-left">{{ item?.tipoAlocacao | vazio }}</td>
                <td class="text-center">{{ item?.dataOriginal | formatDataPipe | vazio }}</td>
              </tr>

              <ng-container *ngIf="rowToggle.show">
                <tr></tr>
                <tr>
                  <td colspan="11" class="background-inherit">

                    <div class="row">
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Critério
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.criterio | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Utilizado
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorUtilizado | formatCurrency | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Utilizado Multa
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorUtilizadoMulta | formatCurrency | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Amortizado
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorAmortizado | formatCurrency | vazio }}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Amortizado Multa
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorAmortizadoMulta | formatCurrency | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Amortizado Juros
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorAmortizadoJuros | formatCurrency | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Situação do TDA
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.situacaoTDA | vazio }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <label class="label-formulario control-label">
                          Valor Alocado de TDA
                        </label>
                        <p class="form-control-static dado-formulario">
                          {{ item?.valorAlocadoTDA | formatCurrency | vazio }}
                        </p>
                      </div>
                    </div>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="label-formulario control-label">
                            Tipo de Cálculo
                          </label>
                          <p class="form-control-static dado-formulario">
                            {{ item?.tipoCalculo | vazio }}
                          </p>
                        </div>
                      </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
      </ng-container>
      <ng-template #nenhumComponente>
        <tr class="vertical-align-middle">
          <td class="text-center" colspan="7">
            Nenhum componente encontrado para este pagamento.
          </td>
        </tr>
      </ng-template>
      </table>
    </div>
</div>

<ng-template #templateVazio>
  <p class="dado-formulario form-control-static">{{ '' | vazio }}</p>
</ng-template>
