import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { CodigoDescricaoDTO } from 'src/app/cnpj/model/codigo-descricao-dto';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DocumentoArrecadacao } from '../../models/documento-arrecadacao';
import { ParametrosPesquisaPagamento } from '../../models/parametros-pesquisa';
import { PagamentoService } from '../../services/pagamento.service';

@Component({
  templateUrl: './troca-assinalamento-pagamento.component.html',
  styleUrls: ['./troca-assinalamento-pagamento.component.css'],
  providers: [ScopedMessageService]
})
export class TrocaAssinalamentoPagamentoComponent implements IDynamicPanel {

  titulo: string;

  parametrosPesquisa: ParametrosPesquisaPagamento;
  atendimento: boolean = false;
  pagamento: DocumentoArrecadacao;
  sistemaInteresseIndicado: CodigoDescricaoDTO;
  sistemasInteresseDisponiveis: CodigoDescricaoDTO[] = [];

  formGroup: FormGroup;
  sistemasInteresseFormName = 'sistemasInteresse';

  initError: Error;

  scopeId: string;

  constructor(
    private formBuilder: FormBuilder,
    private pagamentoService: PagamentoService,
    private messageService: ScopedMessageService,
    private loadingService: LoadingService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.scopeId = this.messageService.scopeId;

    this.atendimento = params?.atendimento;
    this.pagamento = params?.pagamento;
    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.titulo = `Pagamento - Troca de Assinalamento - No: ${this.pagamento.numeroPagamento}`;

    this.formGroup = this.formBuilder.group({ sistemasInteresse: ['', [Validators.required]] });

    this.pagamentoService.obterSistemasInteresse(this.pagamento).subscribe(
      response => {
        this.sistemaInteresseIndicado = response.sistemaIndicado;
        this.sistemasInteresseDisponiveis = response.sistemasDisponiveis;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter os sistemas disponíveis', error);
        this.initError = error;
      }
    );
  }

  onConfirmarTroca(): void {
    const sistemaSelecionado = this.sistemaSelecionado;
    this.loadingService.show();

    this.pagamentoService.trocarAssinalamento(this.pagamento, sistemaSelecionado).subscribe(
      _sucesso => {
        this.loadingService.hide();
        this.messageService.showInfo('Troca de Assinalamento realizada com sucesso');
        this.formGroup.reset();
        this.pagamentoService.atualizarListagemPagamento(this.parametrosPesquisa);
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException('Ocorreu um erro ao solicitar a Troca de Assinalamento', error);
      }
    );
  }

  private get sistemaSelecionado(): CodigoDescricaoDTO {
    return this.formGroup.get(this.sistemasInteresseFormName).value as CodigoDescricaoDTO;
  }
}
