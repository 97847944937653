import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetalheHomonimo } from '../models/detalhe-homonimo';

@Injectable()
export class InformacoesComplementaresService {

  private baseUrl = environment.APIEndpoint;

  constructor(private httpClient: HttpClient) { }

  public obterHomonimos(cpf: string): Observable<DetalheHomonimo[]> {
    const url = `${this.baseUrl}/cpf/homonimos/${cpf}`;
    return this.httpClient.get<DetalheHomonimo[]>(url);
  }
}
