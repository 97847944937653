<ng-container *ngIf="detalhe != null">
  <div class="grupo background-inherit detalhe-doi-container">
    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Dados do Cartório</legend>
      <div class="grupo-conteudo">
        <div class="table-responsive">
          <table suiteRfbTable>
            <thead>
              <tr>
                <th class="text-center min-width">CNPJ</th>
                <th class="text-center">Atribuições</th>
              </tr>
            </thead>
            <tbody>
              <tr class="vertical-align-middle">
                <td class="text-center text-nowrap">
                  <ng-container *ngIf="detalhe.cnpjCartorio; else templateVazio;">
                    <ni-com-link
                      [ni]="detalhe.cnpjCartorio"
                      tipoNi="CNPJ">
                    </ni-com-link>
                  </ng-container>
                </td>
                <td class="text-left">
                  {{ detalhe.atribuicao | vazio }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </fieldset>

    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Identificação da Operação</legend>
      <div class="grupo-conteudo">
        <div class="table-responsive">
          <table suiteRfbTable>
            <thead>
              <tr>
                <th class="text-center min-width">Matrícula</th>
                <th class="text-center min-width">Registro</th>
                <th class="text-center min-width">Livro</th>
                <th class="text-center min-width">Folha</th>
                <th class="text-center">Tipo</th>
                <th class="text-center">Data de Lavratura/Registro/Averbação</th>
                <th class="text-center">Número de Controle</th>
              </tr>
            </thead>
            <tbody>
              <tr class="vertical-align-middle">
                <td class="text-center text-nowrap">
                  {{ detalhe.matricula | vazio }}
                </td>
                <td class="text-center">
                  {{ detalhe.registro | vazio }}
                </td>
                <td class="text-center">
                  {{ detalhe.livro | vazio }}
                </td>
                <td class="text-center">
                  {{ detalhe.folha | vazio }}
                </td>
                <td class="text-left">
                  {{ detalhe.tipoImovel | vazio }}
                </td>
                <td class="text-center">
                  {{ detalhe.dataLavraturaRegistroAverbacao | date: 'dd/MM/yyyy' | vazio }}
                </td>
                <td class="class-center">
                  {{ detalhe.controle | vazio }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </fieldset>

    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Identificação do Alienante</legend>
      <div class="grupo-conteudo">
        <ng-container *ngIf="detalhe.alienantes?.length > 0; else templateVazio;">
          <div class="table-responsive">
            <table suiteRfbTable>
              <thead>
                <tr>
                  <th class="text-center min-width">CPF/CNPJ</th>
                  <th class="text-center">Nome Completo/Razão Social</th>
                  <th class="text-center min-width">Participação</th>
                </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let alienante of detalhe.alienantes">
                    <tr class="vertical-align-middle">
                      <td class="text-center text-nowrap">
                        <ng-container *ngIf="alienante.identificador; else templateVazio;">
                          <ni-com-link [ni]="getIdentificadorAjustado(alienante.identificador)"></ni-com-link>
                        </ng-container>
                      </td>
                      <td class="text-left">
                        {{ alienante.nome | vazio }}
                      </td>
                      <td class="text-center">
                        {{ alienante.participacao | percent | vazio }}
                      </td>
                    </tr>
                  </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </fieldset>

    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Identificação do Adquirente</legend>
      <div class="grupo-conteudo">
        <ng-container *ngIf="detalhe.adquirentes?.length > 0; else templateVazio;">
          <div class="row">
            <div class="col-md-12">
              <label class="form-control-static">
                {{ 'Total de Adquirentes: ' + detalhe.adquirentes.length }}
              </label>
            </div>
          </div>

          <div class="table-responsive">
            <table suiteRfbTable>
              <thead>
                <tr>
                  <th class="text-center min-width">CPF/CNPJ</th>
                  <th class="text-center">Nome Completo/Razão Social</th>
                  <th class="text-center min-width">Participação</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let adquirente of detalhe.adquirentes">
                  <tr class="vertical-align-middle">
                    <td class="text-center text-nowrap">
                      <ng-container *ngIf="adquirente.identificador; else templateVazio;">
                        <ni-com-link [ni]="getIdentificadorAjustado(adquirente.identificador)"></ni-com-link>
                      </ng-container>
                    </td>
                    <td class="text-left">
                      {{ adquirente.nome | vazio }}
                    </td>
                    <td class="text-center">
                      {{ adquirente.participacao | percent | vazio }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </fieldset>

    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Identificação da Alienação</legend>
      <div class="grupo-conteudo">
        <div class="table-responsive">
          <table suiteRfbTable>
            <thead>
              <tr>
                <th class="text-center min-width">Data da Alienação</th>
                <th class="text-center">Forma de Alienação</th>
                <th class="text-center">Tipo da Transação</th>
                <th class="text-center">Valor de Alienação</th>
                <th class="text-center">Base de Cálculo (ITBI)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="vertical-align-middle">
                <td class="text-center">
                  {{ detalhe.dataAlienacao | date: 'dd/MM/yyyy' | vazio }}
                </td>
                <td class="text-left">
                  {{ detalhe.formaAlienacao | vazio }}
                </td>
                <td class="text-left">
                  {{ detalhe.tipoOperacaoImobiliaria | vazio }}
                </td>
                <td class="text-right">
                  {{ detalhe.valorAlienacao | formatCurrency | vazio }}
                </td>
                <td class="text-right">
                  {{ detalhe.baseCalculoITBI | formatCurrency | vazio }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </fieldset>

    <fieldset class="grupo background-inherit">
      <legend class="grupo-titulo text-nowrap">Informações sobre o Imóvel</legend>
      <div class="grupo-conteudo">
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">Tipo de Imóvel</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.tipoImovel | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">Imóvel</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.localizacaoImovel | vazio }}
            </p>
          </div>
          <div class="col-md-6">
            <label class="label-formulario control-label">Andamento</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.imovelConstrucao | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="label-formulario control-label">NIRF</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.nirf | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <label class="label-formulario control-label">Área</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.area | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Endereço</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.endereco | vazio }}
            </p>
          </div>
          <div class="col-md-2">
            <label class="label-formulario control-label">Número</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.numero | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Complemento</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.complemento | vazio }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="label-formulario control-label">Bairro/Distrito</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.bairro | vazio }}
            </p>
          </div>
          <div class="col-md-3">
            <label class="label-formulario control-label">CEP</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.cep | cepPipe | vazio }}
            </p>
          </div>
          <div class="col-md-4">
            <label class="label-formulario control-label">Município</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.municipio | vazio }}
            </p>
          </div>
          <div class="col-md-2">
            <label class="label-formulario control-label">UF</label>
            <p class="form-control-static dado-formulario">
              {{ detalhe.uf | vazio }}
            </p>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</ng-container>

<ng-template #templateVazio>
  <p>{{ '' | vazio }}</p>
</ng-template>
