import { Component, Input } from '@angular/core';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DadosBasicosPjDTO } from '../../model/dados-basicos-pj-dto';
import { CnpjService } from '../../services/cnpj.service';

@Component({
  templateUrl: './consulta-pj.component.html',
  styleUrls: ['./consulta-pj.component.css'],
  providers: [ScopedMessageService]
})
export class ConsultaPjComponent implements IDynamicPanel {

  @Input() cnpj: string;
  @Input() isModal: boolean = false;

  dadosBasicos: DadosBasicosPjDTO;
  titulo: string;

  initError: Error;
  scopeId: string;

  constructor(
    private cnpjService: CnpjService,
    private messageService: ScopedMessageService) { }

  init(ni: string, params?: OptionalParams): void {
    this.cnpj = ni?.length > 0 ? ni : params?.cnpj;

    this.isModal = params?.isModal ?? this.isModal;

    this.titulo = `Pessoa Jurídica - Consultar - ${FormatCnpjPipe.formatCNPJ(this.cnpj)}`;

    this.scopeId = this.messageService.scopeId;

    this.obterDadosEmpresa();
  }

  private obterDadosEmpresa(): void {
    this.cnpjService.obterDadosEmpresa(this.cnpj).subscribe(
      response => {
        this.dadosBasicos = response;
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter os dados básicos da pessoa jurídica', error);
        this.initError = error;
      }
    );
  }
}
