import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParcelamentoDivida } from './model/parcelamento-divida';

@Injectable()
export class ParcelamentoDividaService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterParcelamentosDividaAtivos(ni: string, numeroProtocolo: string): Observable<ParcelamentoDivida[]> {
    const url = `${this.baseUrl}/situacoesfiscais/divida/parcelamento/Cobranca/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoDivida[]>(url, options);
  }

  public obterParcelamentosDividaSuspensos(ni: string, numeroProtocolo: string): Observable<ParcelamentoDivida[]> {
    const url = `${this.baseUrl}/situacoesfiscais/divida/parcelamento/Exigibilidade/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ParcelamentoDivida[]>(url, options);
  }
}
