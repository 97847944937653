import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FotoPf } from './model/foto-pf';

export interface AtendimentoEvent {
  tipo: 'tab.activate' | 'tab.cancelaProcessamento'|'tab.create' ;
  dados: any;
}

@Injectable({
  providedIn: 'root'
})
export class PainelAtendimentosService {

  private baseUrl = environment.APIEndpoint;

  /**
   * TODO: Lucas - descrever a função do observable do tipo Subject...
   * 
   * Observable do tipo Subject...
   */
  private atendimentosEventSource = new Subject<AtendimentoEvent>();
  atendimentosEvent$: Observable<AtendimentoEvent> = this.atendimentosEventSource.asObservable();

  constructor(private http: HttpClient) {}

  public activateTab(tabName: string) {
    this.atendimentosEventSource.next({tipo: 'tab.activate', dados: tabName});
  }

  public createTab(ni: string) {
    this.atendimentosEventSource.next({tipo: 'tab.create', dados: ni});
  }

  public cancelaProcessamentoAtendimento(ni: string) {
    this.atendimentosEventSource.next({tipo: 'tab.cancelaProcessamento', dados: ni});
  }

  public obterFotoPessoaFisicaEmAtendimento(cpf: string): Observable<FotoPf> {
    const url = `${this.baseUrl}/cpf/foto/${cpf}`;
    console.log('url obterFotoPessoaFisicaEmAtendimento: ' + url);
    return this.http.get<FotoPf>(url);
  }
}
