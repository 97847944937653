import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DirpfSuiteRfbModule } from './dirpf-suite-rfb.module';
import { DirpfComponent } from './dirpf.component';
import { DirpfService } from './dirpf.service';
import { DirpfTableComponent } from './table/dirpf-table.component';

@NgModule({
  declarations: [
    DirpfComponent,
    DirpfTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    DirpfSuiteRfbModule
  ],
  exports: [
    DirpfComponent,
  ],
  providers: [
    DirpfService
  ]
})
export class DirpfModule { }
