<suite-rfb-collapse title="DIRPF" collapsed="true" (collapsedChange)="onCarregarDados($event)">
  <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
  <ng-container *ngIf="dirpfs && dirpfs.length > 0; else loadingOrError">
    <via-dirpf-table *ngIf="dirpfs.length>0" [dirpfs]="dirpfs" [cpf]="this.cpf">
    </via-dirpf-table>
  </ng-container>
</suite-rfb-collapse>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading">
    Ocorreu um erro...
  </div>
  <ng-template #loading>
    <loading-animation *ngIf="!stopLoading"></loading-animation>
  </ng-template>
</ng-template>
