import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { FeatureAction } from 'src/app/shared/model/feature';
import { CriterioSelecao, ParametrosPesquisaCreditosTributarios } from "../../../../models/parametros-pesquisa";
import { ConsultaCreditosComponent } from '../consulta-creditos/consulta-creditos.component';

@Component({
  templateUrl: './filtros-pesquisa-ccpf.component.html',
  styleUrls: ['./filtros-pesquisa-ccpf.component.css']
})
export class FiltrosPesquisaCcpfComponent implements IDynamicPanel {

  titulo = 'Cobrança - CCPF - Consultar';

  ni: string;

  initError: Error;

  formGroup: FormGroup;

  parametros: ParametrosPesquisaCreditosTributarios;

  cpfName: string =  'cpf';
  exercicioDeName: string = 'exercicioDe';
  exercicioAteName: string =  'exercicioAte';
  criterioSelecaoName: string = 'criterioSelecao';

  radioSelected: string;

  exibeResultado = false;
  filtroColapsado = false;

  readonly criterioSelecaoVencimento = CriterioSelecao.CREDITOS_VENCIDOS_VENCER;
  readonly criterioSelecaoTodos = CriterioSelecao.CREDITOS_TODOS;

  constructor(
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef,
    private dynamicModalRequest: DynamicModalRequestService) { }

  init(ni: string): void {

    this.ni = ni? FormatCpfPipe.formatCPF(ni): ni;

    this.formGroup = this.formBuilder.group({
      cpf: [this.ni, [Validators.required]],
      exercicioDe: [''],
      exercicioAte: [''],
      criterioSelecao: [CriterioSelecao.CREDITOS_TODOS]
    });

    if (ni) {
      this.formGroup.get('cpf').disable();
    }

    this.radioSelected = CriterioSelecao.CREDITOS_TODOS;
  }

  private get cpf(): string {
    const maskedCpf = this.formGroup.get(this.cpfName).value as string;
    return maskedCpf.replace(/[^\d]+/g, '');
  }

  get cpfCtrl(): FormControl {
    return this.formGroup.get(this.cpfName) as FormControl;
  }

  private get exercicioDe(): string {
    return this.formGroup.get(this.exercicioDeName).value as string;
  }

  private get exercicioAte(): string {
    return this.formGroup.get(this.exercicioAteName).value as string;
  }

  private get criterioSelecao(): string {
    return this.formGroup.get(this.criterioSelecaoName).value as string;
  }

  onSubmit(): void {

    this.parametros = {
      cpfContribuinte: this.cpf,
      criterioSelecao: this.criterioSelecao,
      exercicio: this.exercicioDe,
      exercicioFinal: this.exercicioAte,
      isModal: this.ni? false : true
    }

    if (this.ni) {
      this.exibeResultado = true;
      this.filtroColapsado = true;
    } else {
      let feature = new FeatureAction();
      feature.class = ConsultaCreditosComponent;
      feature.parametros = this.parametros;
      this.dynamicModalRequest.abrirModal(feature);
    }

  }

  onFecharModal(): void {
    this.bsModalRef.hide();
  }

  onReset() {
    if (!this.ni) {
      this.formGroup.get(this.cpfName)?.reset();
    }
    this.formGroup.get(this.exercicioAteName)?.reset();
    this.formGroup.get(this.exercicioDeName)?.reset();
    this.formGroup.patchValue({criterioSelecao : CriterioSelecao.CREDITOS_TODOS});
  }

  public colapsar(valor: boolean): void {
    this.filtroColapsado = valor;
  }

}
