import { Component, ViewEncapsulation } from '@angular/core';
import { LocalDate } from '@js-joda/core';
import { Observable } from 'rxjs';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DateUtilsService } from 'src/app/shared/util/date-utils.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { DebitoSief } from '../../model/debito-sief';
import { TipoNI } from '../../model/enum/tipo-ni';
import { ParametrosDarf, SistemaOrigemDarf } from '../../model/parametros-darf';
import { ParametrosDas } from '../../model/parametros-das';
import { DarfService } from '../darf/darf.service';
import { DasService } from '../das/das.service';
import { DebitoSiefService } from './debito-sief.service';
import { CodigoReceita, OrdemDARF } from '../../model/enum/arrecadacao';

@Component({
  selector: 'app-debito-sief',
  templateUrl: './debito-sief.component.html',
  styleUrls: ['./debito-sief.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DebitoSiefComponent implements IDynamicPanel {

  initError: Error;
  debitos: DebitoSief[];
  ni: string;
  titulo: string;
  protocolo: string;

  isSuspensos = false;
  isAtivos = false;
  isPessoaFisica = false;
  isPessoaJuridica = false;
  mensagem = 0;

  constructor(
    private debitoSiefService: DebitoSiefService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private darfService: DarfService,
    private dasService: DasService,
    private dateUtilsService: DateUtilsService,
  ) { }

  public init(ni: string): void {

    this.ni = ni;
    const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
    this.protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
    console.log('DebitoSiefCobranca#init NI: ', ni);
    this.logService.logAjaxCall('DebitoSiefCobrancaComponent#init', 'NI: ', ni);
    console.log('Protocolo: ' + this.protocolo);

    try {
      let debitosObservable: Observable<DebitoSief[]>;
      if (this.isSuspensos) {
        debitosObservable = this.debitoSiefService.obterDebitosSuspensos(ni, this.protocolo);
      } else if (this.isAtivos) {
        debitosObservable = this.debitoSiefService.obterDebitosAtivos(ni, this.protocolo);
      }

      debitosObservable.subscribe(
        (resp) => {
          this.debitos = resp;
          this.logService.logAjaxReturn('DebitoSiefCobrancaComponent#obterDebitosSief', 'recuperou debitos Processo Fiscal', this.debitos);
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('DebitoSiefCobrancaComponent#obterDebitosSief', 'erro recuperando debitos Processo Fisccal', err);
          this.messageService.showErrorException('Ocorreu um erro ao obter os débitos ', err);
        }
      );
    } catch (e) {
      this.initError = e;
      this.logService.logError('DebitoSiefCobrancaComponent#obterDebitosSief', 'ocorreu um erro ao obter debitos Processo Fiscal', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os débitos ', e);
    }
  }

  public emitirDas(debito: DebitoSief ): void {

    this.definirMensagemDas(debito);
    this.preencherParametroeEmitirDas(debito);

   }

   protected definirMensagemDas(debito: DebitoSief) {

      this.mensagem = 0;

      if (debito.inOrdemDarf == OrdemDARF.DAS) {
           if (debito.codigoReceita == CodigoReceita.DAS_MEI) {
              this.mensagem = 1;
            } else if (debito.codigoReceita == CodigoReceita.DAS_COMUM) {
                if (debito.indExibeDarf) {
                  const noPrazo = this.verificarDarfEstaNoPrazo(debito.dataVencimento);
                  if (noPrazo) {
                    this.mensagem = 3;
                  }
                } else {
                  this.mensagem = 2;
                }
            } else {
              this.mensagem = 1;
          }
        }

    }

  protected preencherParametroeEmitirDas(debito: DebitoSief) {
    const parametros = this.preencherParametrosDas(debito);
    this.dasService.emitirDas(parametros, this.mensagem);
  }

  private preencherParametrosDas(debito: DebitoSief): ParametrosDas {
    const parametros: ParametrosDas = {
      cnpjBasico: this.ni.substring(0, 8),
      pa: debito.dataPaExercicio.substring(0, 6),
      codigoExtensaoReceita: debito.codigoExtensaoReceita
    }
    return parametros;
  }

  public emitirDarf(debito: DebitoSief): void {

    this.definirMensagemDarf(debito);
    this.preencherParametroeEmitirDarf(debito);

  }

protected definirMensagemDarf(debito: DebitoSief) {

    this.mensagem = 0;

    switch (debito.inOrdemDarf.valueOf()) {

      case OrdemDARF.DARF_NUMERADO: {
        if (debito.indExibeDarf)
          this.mensagem = 2;
        else
          this.mensagem = 3;
        break;
      }

      case OrdemDARF.DARF_COMUM: {
        if (debito.indExibeDarf) {
          const noPrazo = this.verificarDarfEstaNoPrazo(debito.dataVencimento);
          if (noPrazo) {
            this.mensagem = 1;
          }
        } else {
          this.mensagem = 3;
        }
        break;
      }

      case OrdemDARF.CONSOLIDA:
      case OrdemDARF.NAO_EMITE_CONSOLIDA: {
        this.mensagem = 4;
        break;
      }

    }

}

private verificarDarfEstaNoPrazo(dataVencimento: string) {

  let diferencaDias = this.dateUtilsService.diasDesde(LocalDate.parse(dataVencimento));
  return diferencaDias < 60;

}

protected preencherParametroeEmitirDarf(debito: DebitoSief) {
  const parametros = this.preencherParametrosDarf(debito);
  this.darfService.emitirDarf(parametros, this.mensagem);
}

  private preencherParametrosDarf(debito: DebitoSief): ParametrosDarf {
    const parametros: ParametrosDarf = {
      protocolo: this.protocolo,
      tipoNi: this.isPessoaFisica ? TipoNI.CPF : TipoNI.CNPJ,
      ni: this.ni,
      sistemaOrigem: SistemaOrigemDarf.SFCOB,
      numeroDebito: debito.numeroDebito,
      numeroProcesso: '',
      numeroImovel: '',
      codigoReceita: '',
      numeroComponente: '',
      exercicio: '',
    }
    return parametros;
  }
}
@Component({
  selector: 'app-debito-sief',
  templateUrl: './debito-sief.component.html',
  styleUrls: ['./debito-sief.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DebitoSiefPJSuspensoComponent extends DebitoSiefComponent {
  init(ni: string) {
    this.isSuspensos = true;
    this.isPessoaJuridica = true;
    this.titulo = `Débitos Suspensos Fazendários Conta Corrente SIEF - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }

  emitirDarf(debito: DebitoSief): void {
    super.definirMensagemDarf(debito);
    if (debito.inOrdemDarf == OrdemDARF.DARF_COMUM && debito.indExibeDarf)
      this.mensagem = 0;
    super.preencherParametroeEmitirDarf(debito);
  }

  emitirDas(debito: DebitoSief): void {
    super.definirMensagemDas(debito);
    if (debito.inOrdemDarf == OrdemDARF.DAS)
      this.mensagem = 2;
    super.preencherParametroeEmitirDas(debito);
  }
}

@Component({
  selector: 'app-debito-sief',
  templateUrl: './debito-sief.component.html',
  styleUrls: ['./debito-sief.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DebitoSiefPJAtivoComponent extends DebitoSiefComponent {
  init(ni: string) {
    this.isAtivos = true;
    this.isPessoaJuridica = true;
    this.titulo = `Débitos Ativos Fazendários SIEF FISCEL - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-debito-sief',
  templateUrl: './debito-sief.component.html',
  styleUrls: ['./debito-sief.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DebitoSiefPFAtivoComponent extends DebitoSiefComponent {
  init(ni: string) {
    this.isAtivos = true;
    this.isPessoaFisica = true;
    this.titulo = `Débitos Ativos Fazendários SIEF - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }
}

@Component({
  selector: 'app-debito-sief',
  templateUrl: './debito-sief.component.html',
  styleUrls: ['./debito-sief.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DebitoSiefPFSuspensoComponent extends DebitoSiefComponent {
  init(ni: string) {
    this.isSuspensos = true;
    this.isPessoaFisica = true;
    this.titulo = `Débitos Suspensos Fazendários Conta Corrente SIEF - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni);
  }

  emitirDarf(debito: DebitoSief): void {
    super.definirMensagemDarf(debito);
    if (debito.inOrdemDarf == OrdemDARF.DARF_COMUM && debito.indExibeDarf)
      this.mensagem = 0;
    super.preencherParametroeEmitirDarf(debito);
  }
}
