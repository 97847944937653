import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'tipoCndPipe'
})
export class TipoCndPipe implements PipeTransform {
    public transform(codigo: string | number): string {
        if (codigo) {
            switch (this.normaliza(codigo)) {
                case '1':
                    return 'Negativa';
                case '2':
                    return 'Positiva com efeitos de negativa';
                case '3':
                    return 'Positiva';
                default:
                    return '';
            }
        }
        return '';
    }

    private normaliza(codigo: string | number): string {
        return codigo.toString().trim().replace(/^0+/, '');
    }

}