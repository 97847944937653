import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@serpro/ngx-suite-rfb';
import { findKey, isEqual, isNil, toNumber } from 'lodash';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DebitoAutomatico } from 'src/app/cobranca/models/debito-automatico';
import { ParametrosPesquisaCreditosTributarios } from 'src/app/cobranca/models/parametros-pesquisa';
import { TipoDebitoAlteracaoCancelamento, TipoDebitoInclusao } from 'src/app/cobranca/models/tipo-debito';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CreditoTributario } from "../../../../models/credito-tributario";
import { CCPFService } from '../../services/ccpf.service';

@Component({
  templateUrl: './debito-conta.component.html',
  styleUrls: ['./debito-conta.component.css'],
  providers: [ScopedMessageService]
})
export class CCPFDebitoContaComponent implements IDynamicPanel, OnDestroy {

  @Input() cpf: string;
  @Input() credito: CreditoTributario;
  @Input() isAtendimento = false;
  @Input() parametrosPesquisa: ParametrosPesquisaCreditosTributarios;

  _debito: DebitoAutomatico;
  debito$: Observable<DebitoAutomatico>;

  titulo: string;

  scopeId: string;

  initError: Error;

  formGroup: FormGroup;

  bancoName = 'banco';
  agenciaName = 'agencia';
  ccName = 'contaCorrente';
  dvName = 'dv';

  featureEventSub: Subscription;

  private isAtualizacaoDebitoAutomatico = false;

  constructor(
    private ccpfService: CCPFService,
    private formBuilder: FormBuilder,
    private messageService: ScopedMessageService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService) { }

  init(_ni: string, params?: OptionalParams): void {
    this.scopeId = this.messageService.scopeId;

    this.cpf = params?.cpf;
    this.credito = params?.credito;
    this.parametrosPesquisa = params?.parametrosPesquisa;

    this.titulo = `CCPF - Consultar Débito em Conta - CT: ${this.credito.ct}`;

    this.formGroup = this.formBuilder.group({
      banco: ['', [Validators.required]],
      agencia: ['', [Validators.required]],
      contaCorrente: ['', [Validators.required]],
      dv: ['', [Validators.required]]
    });

    this.featureEventSub = this.menuFeatureActionsService.featureActionEvent$.subscribe(
      featureEvent => {
        const feature = featureEvent.featureAction;
        if (featureEvent.active == false && feature.id == 'CcpfConsultar' && feature.tipoMenu == TipoMenu.ATENDIMENTO) {
          this.fecharDebitoConta();
          this.featureEventSub?.unsubscribe();
        }
      }
    );

    this.obterDebitoAutomatico();
  }

  ngOnDestroy(): void {
    this.featureEventSub?.unsubscribe();
  }

  onIncluirDebito(): void {
    if (this.formGroup.invalid) {
      this.messageService.showWarning('Preencha todos os campos');
      return;
    }

    this._debito = null;
    this.messageService.dismissMessages();
    this.loadingService.show();

    this.ccpfService.incluirDebitoAutomatico(this.cpf, this.banco, this.agencia, this.contaCorrente, this.digitoVerificador).subscribe(
      _sucesso => {
        this.messageService.showInfo(`CT ${this.credito.ct} - Inclusão de débito automático realizada com sucesso!`);
        this.isAtualizacaoDebitoAutomatico = true;
        this.obterDebitoAutomatico();
        this.loadingService.hide();
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException(`CT ${this.credito.ct} - Ocorreu um erro ao incluir débito automático`, error);
        this.initError = error;
      }
    );
  }

  onAlterarDebito(): void {
    if (this.formGroup.invalid) {
      this.messageService.showWarning('Preencha todos os campos');
      return;
    }

    this._debito = null;
    this.messageService.dismissMessages();
    this.loadingService.show();

    this.ccpfService.alterarDebitoAutomatico(this.cpf, this.banco, this.agencia, this.contaCorrente, this.digitoVerificador).subscribe(
      _sucesso => {
        this.messageService.showInfo(`CT ${this.credito.ct} - Alteração de débito automático realizada com sucesso!`);
        this.isAtualizacaoDebitoAutomatico = true;
        this.obterDebitoAutomatico();
        this.loadingService.hide();
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException(`CT ${this.credito.ct} - Ocorreu um erro ao alterar débito automático`, error);
        this.initError = error;
      }
    );
  }

  onCancelarDebito(): void {
    this._debito = null;
    this.messageService.dismissMessages();
    this.loadingService.show();

    this.ccpfService.cancelarDebitoAutomatico(this.cpf).subscribe(
      _sucesso => {
        this.messageService.showInfo(`CT ${this.credito.ct} - Cancelamento de débito automático realizado com sucesso!`);
        this.isAtualizacaoDebitoAutomatico = true;
        this.obterDebitoAutomatico();
        this.loadingService.hide();
      },
      error => {
        this.loadingService.hide();
        this.messageService.showErrorException(`CT ${this.credito.ct} - Ocorreu um erro ao cancelar débito automático`, error);
        this.initError = error;
      }
    );
  }

  private get banco() {
    return this.formGroup.get(this.bancoName).value as string;
  }

  private get agencia() {
    return this.formGroup.get(this.agenciaName).value as string;
  }

  private get contaCorrente() {
    return this.formGroup.get(this.ccName).value as string;
  }

  private get digitoVerificador() {
    return this.formGroup.get(this.dvName).value as string;
  }

  private obterDebitoAutomatico(): void {
    this.messageService.dismissMessages();
    this.loadingService.show();

    this.debito$ = this.ccpfService.obterDebitoAutomatico(this.cpf, toNumber(this.credito.exercicio))
      .pipe(
        tap(response => {
          this._debito = response;
          this.preencherFormularioDadosBancarios();

          if (this.isAtualizacaoDebitoAutomatico) {
            this.ccpfService.atualizarListagemCreditos(this.parametrosPesquisa);
          }
        }),
        catchError(error => {
          this.loadingService.hide();
          this.messageService.showErrorException(`CT ${this.credito.ct} - Ocorreu um erro ao obter dados de Débito em Conta`, error);
          this.initError = error;
          return of<DebitoAutomatico>();
        }),
        finalize(() => this.loadingService.hide())
      );
  }

  private fecharDebitoConta(): void {
    const feature = {
      id: `CCPFDebitoConta_credito_${this.credito.ct}`,
      title: 'CCPF - Débito em Conta',
      class: CCPFDebitoContaComponent,
      visible: true,
      rolesAllowed: [],
      tipoMenu: TipoMenu.ATENDIMENTO
    } as FeatureAction;

    this.menuFeatureActionsService.deactivate(feature);
  }

  private preencherFormularioDadosBancarios(): void {
    this.formGroup.get(this.bancoName).setValue(this._debito.nomeBanco);
    this.formGroup.get(this.agenciaName).setValue(this._debito.numeroAgencia);
    this.formGroup.get(this.ccName).setValue(this._debito.numeroConta);
    this.formGroup.get(this.dvName).setValue(this._debito.numeroDVConta);
  }

  private isExercicioIgualAnoCorrente(): boolean {
    const anoCorrente = new Date().getFullYear();
    return isEqual(toNumber(this.credito.exercicio), anoCorrente);
  }

  podeIncluirDebitoAutomatico(): boolean {
    if (this.formGroup.invalid) {
      return false;
    }

    if (isNil(this._debito)) {
      return true;
    }

    const tipoDebitoInclusao = findKey(TipoDebitoInclusao, tipoDebito => tipoDebito === this._debito.tipoDebito.codigo);
    const isTipoDebitoInclusao = Object.keys(TipoDebitoInclusao).includes(tipoDebitoInclusao);

    return this.formGroup.valid &&
      this.isExercicioIgualAnoCorrente() &&
      isTipoDebitoInclusao;
  }

  podeAlterarDebitoAutomatico(): boolean {
    if (this.formGroup.invalid) {
      return false;
    }

    if (isNil(this._debito)) {
      return false;
    }

    const tipoDebitoAlteracao = findKey(TipoDebitoAlteracaoCancelamento, tipoDebito => tipoDebito === this._debito.tipoDebito.codigo);
    const isTipoDebitoAlteracao = Object.keys(TipoDebitoAlteracaoCancelamento).includes(tipoDebitoAlteracao);

    return this.formGroup.valid &&
      this.isExercicioIgualAnoCorrente() &&
      isTipoDebitoAlteracao;
  }

  // USA A MESMA REGRA PARA HABILITAR ALTERAÇÃO
  podeCancelarDebitoAutomatico(): boolean {
    return this.podeAlterarDebitoAutomatico();
  }
}
