<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle title="Expandir/Contrair Todos" (expanded)="expandedAll($event)"></suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">NI</th>
        <th class="text-center min-width">Código</th>
        <th class="text-center">Parcelamento</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let parcelamento of parcelamentos | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">
            <suite-rfb-popover-button
              #popoverAcoesParcelamento
              [template]="botoesAcaoParcelamento"
              [templateContext]="{ parcelamento: parcelamento }">
            </suite-rfb-popover-button>
            <ng-template #botoesAcaoParcelamento let-parcelamento="parcelamento">
              <div class="btn-group-vertical" role="group">
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="onEmitirDarf(parcelamento); popoverAcoesParcelamento.hide()">
                  Emitir DARF
                </button>
              </div>
            </ng-template>
          </td>
          <td class="text-center">
            <ni-com-link [ni]="parcelamento.ni"></ni-com-link>
          </td>
          <td class="text-center">{{ parcelamento.codigoTipoParcelamento }}</td>
          <td class="text-left">{{ parcelamento.tipoParcelamento }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="5">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-6">
                    <label class="label-formulario control-label">Modalidade</label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.modalidadeParcelamento | vazio }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">Data Opção</label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.dataOpcao | date: 'shortDate' | vazio }}
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="label-formulario control-label">Número Conta</label>
                    <p class="form-control-static dado-formulario">
                      {{ parcelamento.numeroContaParcelamento | vazio }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="5">
          <via-pagination [itemAmount]="parcelamentos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
