import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCpfCnpjPipe } from 'src/app/shared/pipes/formata-cpf-cnpj.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { TipoNI } from '../../model/enum/tipo-ni';
import { TipoPendencia } from '../../model/enum/tipo-pendencia';
import { ParcelamentoSisparDTO } from './model/parcelamento-sispar';
import { ParcelamentoSisparService } from './parcelamento-sispar.service';

@Component({
  templateUrl: './parcelamento-sispar.component.html',
  styleUrls: ['./parcelamento-sispar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelamentoSisparComponent implements IDynamicPanel {

  titulo: string;
  parcelamentosObservable$: Observable<ParcelamentoSisparDTO[]>;

  initError: Error;
  loadingError$ = new Subject<boolean>();

  constructor(
    private sisparService: ParcelamentoSisparService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService) {
  }

  public init(_: string): void {
    throw new Error('Este método não deve ser chamado diretamente. Utilize carregaParcelamentos(ni, tipoNI, tipoPendencia)');
  }

  public carregaParcelamentos(ni: string, tipoNI: TipoNI, tipoPendencia: TipoPendencia): void {
    if (ni && tipoNI && tipoPendencia) {
      this.montaTitulo(ni, tipoNI, tipoPendencia);

      const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
      const protocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;

      this.parcelamentosObservable$ = this.sisparService
        .obterParcelamentosSispar(ni, protocolo, tipoPendencia)
        .pipe(
          catchError(err => {
            this.loadingError$.next(true);
            this.messageService.showErrorException(this.titulo, err);
            return of<ParcelamentoSisparDTO[]>();
          })
        );
    } else {
      throw new Error('Este método não recebeu os parâmetros necessários.');
    }
  }

  public emitirDarf(_parcelamento: ParcelamentoSisparDTO): void {
    window.open(this.sisparService.getUrlSisparConsultaDarf());
  }

  private montaTitulo(ni: string, tipoNI: TipoNI, tipoPendencia: TipoPendencia): void {
    this.titulo = `Parcelamentos ${this.getTipoParcelamento(tipoPendencia)} PGFN SISPAR - ${tipoNI}: ${FormatCpfCnpjPipe.format(ni)}`;
  }

  private getTipoParcelamento(tipoPendencia: TipoPendencia): string {
    return tipoPendencia === TipoPendencia.COBRANCA ? 'Ativos' : 'Suspensos';
  }
}

@Component({
  templateUrl: './parcelamento-sispar.component.html',
  styleUrls: ['./parcelamento-sispar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelamentoSisparPFCobrancaComponent extends ParcelamentoSisparComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CPF, TipoPendencia.COBRANCA);
  }
}

@Component({
  templateUrl: './parcelamento-sispar.component.html',
  styleUrls: ['./parcelamento-sispar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelamentoSisparPJCobrancaComponent extends ParcelamentoSisparComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CNPJ, TipoPendencia.COBRANCA);
  }
}

@Component({
  templateUrl: './parcelamento-sispar.component.html',
  styleUrls: ['./parcelamento-sispar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelamentoSisparPFExigibilidadeComponent extends ParcelamentoSisparComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CPF, TipoPendencia.EXIGIBILIDADE_SUSPENSA);
  }
}

@Component({
  templateUrl: './parcelamento-sispar.component.html',
  styleUrls: ['./parcelamento-sispar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParcelamentoSisparPJExigibilidadeComponent extends ParcelamentoSisparComponent {

  public init(ni: string): void {
    super.carregaParcelamentos(ni, TipoNI.CNPJ, TipoPendencia.EXIGIBILIDADE_SUSPENSA);
  }
}
