import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { ParametrosPesquisaTituloEleitor } from 'src/app/cpf/components/titulo-eleitor/model/parametros-pesquisa-titulo-eleitor';
import { TituloEleitor } from 'src/app/cpf/components/titulo-eleitor/model/titulo-eleitor';

@Component({
  selector: 'via-consulta-titulo-eleitor-table',
  templateUrl: './consulta-titulo-eleitor-table.component.html',
  styleUrls: ['./consulta-titulo-eleitor-table.component.css']
})
export class ConsultaTituloEleitorTableComponent {

  @Input() parametrosPesquisa: ParametrosPesquisaTituloEleitor;

  @Input() resultadosPesquisa: TituloEleitor[];

  @Output() consultarDadosCadastrais = new EventEmitter<string>();

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  onConsultarDadosCadastrais(numeroTituloEleitor: string): void {
    this.consultarDadosCadastrais.emit(numeroTituloEleitor);
  }
}
