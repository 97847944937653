import { DadosBasicosPFComponent } from 'src/app/cpf/components/dados-basicos-pf/dados-basicos-pf.component';
import { TituloEleitorComponent } from 'src/app/cpf/components/titulo-eleitor/titulo-eleitor.component';
import { environment } from 'src/environments/environment';
import { FeatureAction } from '../../shared/model/feature';
import { AguiaPFComponent } from './aguia/aguia.component';
import { ArrolamentoBensSiefPFComponent } from './arrolamento-bens-sief/arrolamento-bens-sief.component';
import { CnpjsVinculadosComponent } from './cnpjs-vinculados/cnpjs-vinculados.component';
import { ContaCorrenteItrPFAtivoComponent, ContaCorrenteItrPFSuspensoComponent } from './conta-corrente-itr/conta-corrente-itr.component';
import { CCPFCobrancaComponent, CCPFExigibilidadeComponent } from './conta-corrente-pf/ccpf.component';
import { DebitoDividaPFAtivoComponent, DebitoDividaPFSuspensoComponent } from './debito-divida/debito-divida.component';
import { DebitoProcessoSiefPFAtivoComponent, DebitoProcessoSiefPFSuspensoComponent } from './debito-processo-sief/debito-processo-sief.component';
import { DebitoProfiscPFAtivoComponent, DebitoProfiscPFSuspensoComponent } from './debito-profisc/debito-profisc.component';
import { DebitoSicobPFAtivoComponent, DebitoSicobPFSuspensoComponent } from './debito-sicob/debito-sicob.component';
import { DebitoSiefPFAtivoComponent, DebitoSiefPFSuspensoComponent } from './debito-sief/debito-sief.component';
import { DeclaracaoAusenteDirpfComponent, DeclaracaoAusentePFDitrComponent, DeclaracaoAusentePFGfipComponent } from './declaracao-ausente/declaracao-ausente.component';
import { DeclaracaoIrpfMalhaComponent } from './declaracao-irpf-malha/declaracao-irpf-malha.component';
import { ImpedimentoCndPFPgfnComponent, ImpedimentoCndPFRfbComponent } from './impedimento-cnd/impedimento-cnd.component';
import { InfoCadastraisPFComponent } from './info-cadastrais-pf/info-cadastrais-pf.component';
import { InscricaoPgfnPFAtivoComponent, InscricaoPgfnPFSuspensoComponent } from './inscricao-pgfn/inscricao-pgfn.component';
import { IrregularidadeCadastralPFComponent } from './irregularidade-cadastral/irregularidade-cadastral.component';
import { LiberacaoCndPFComponent } from './liberacao-cnd/liberacao-cnd.component';
import { ParcelamentoDividaPFAtivoComponent, ParcelamentoDividaPFSuspensoComponent } from './parcelamento-divida/parcelamento-divida.component';
import { ParcelamentoPaexPFAtivoComponent, ParcelamentoPaexPFSuspensoComponent } from './parcelamento-paex/parcelamento-paex.component';
import { ParcelamentoPgfnPFAtivoComponent, ParcelamentoPgfnPFSuspensoComponent } from './parcelamento-pgfn/parcelamento-pgfn.component';
import { ParcelamentoSicobPFAtivoComponent, ParcelamentoSicobPFSuspensoComponent } from './parcelamento-sicob/parcelamento-sicob.component';
import { ParcelamentoSIEFParPFCobrancaComponent, ParcelamentoSIEFParPFExigibilidadeComponent } from './parcelamento-siefpar/parcelamento-siefpar.component';
import { ParcelamentoSIPADECobrancaComponent, ParcelamentoSIPADEExigibilidadeComponent } from './parcelamento-sipade/parcelamento-sipade.component';
import { ParcelamentoSisparPFCobrancaComponent, ParcelamentoSisparPFExigibilidadeComponent } from './parcelamento-sispar/parcelamento-sispar.component';
import { ProcessoProfiscPFAtivoComponent, ProcessoProfiscPFSuspensoComponent } from './processo-profisc/processo-profisc.component';
import { ProcessoSiefPFAtivoComponent, ProcessoSiefPFSuspensoComponent } from './processo-sief/processo-sief.component';
import { RelatorioApoioCndComponent } from './relatorio-apoio-cnd/relatorio-apoio-cnd.component';

const TODOS_PERFIS = ['ATENDEARFB', 'ATENDENTE', 'CONSULTA', 'GERENCIAL1', 'GERENCIAL2'];

const ACTION_RELATORIO_APOIO_CND:       FeatureAction = { id: 'relatorio-apoio-cnd', title: 'Relatório de Apoio à Certidão', class: RelatorioApoioCndComponent, visible: true, rolesAllowed: TODOS_PERFIS };

const ACTION_DADOS_BASICOS:             FeatureAction = { id: 'DadosBasicosCpf', title: 'Dados Básicos', class: DadosBasicosPFComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_INFORMACOES_CADASTRAIS:    FeatureAction = { id: 'informacoes-cadastrais', title: 'Informações Cadastrais', class: InfoCadastraisPFComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_CNPJS_VINCULADOS:          FeatureAction = { id: 'CnpjsVinculados', title: 'CNPJs Vinculados', class: CnpjsVinculadosComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_IRREGULARIDADE_CADASTRAL:  FeatureAction = { id: 'IrregularidadeCadastral', title: 'Irregularidade Cadastral', class: IrregularidadeCadastralPFComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_ARROLAMENTO_BENS_SIEF:     FeatureAction = { id: 'SiefProcessoArrolamento', title: 'Arrolamento de Bens', class: ArrolamentoBensSiefPFComponent, rolesAllowed: TODOS_PERFIS };

/*IMPEDIMENTOS DE EMISSÃO DE CND*/
const ACTION_IMPEDIMENTO_CND_RFB:       FeatureAction = { id: 'ImpedimentoCertidaoRfb', title: 'Na RFB', class: ImpedimentoCndPFRfbComponent, rolesAllowed: TODOS_PERFIS  };
const ACTION_IMPEDIMENTO_CND_PGFN:      FeatureAction = { id: 'ImpedimentoCertidaoPgfn', title: 'Na PGFN', class: ImpedimentoCndPFPgfnComponent, rolesAllowed: TODOS_PERFIS  };
const ACTION_IMPEDIMENTOS_EMISSAO_CND:  FeatureAction = { id: 'impedimentos-emissao-cnd', title: 'Impedimentos Judiciais',
                                                          children: [ACTION_IMPEDIMENTO_CND_RFB, ACTION_IMPEDIMENTO_CND_PGFN]
                                                        };

/*DÉBITOS FAZENDÁRIOS*/
const ACTION_CCPF_ATIVO:                FeatureAction = { id: 'ContaCorrente', title: 'Conta Corrente PF', class: CCPFCobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCITR_ATIVO:               FeatureAction = { id: 'ItrContaCorrente', title: 'Conta Corrente ITR', class: ContaCorrenteItrPFAtivoComponent, rolesAllowed: TODOS_PERFIS  };
const ACTION_SIEF_ATIVO:                FeatureAction = { id: 'SiefDebitoCobranca', title: 'SIEF', class: DebitoSiefPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SIEF_SUSPENSO:             FeatureAction = { id: 'SiefDebitoExigibilidade', title: 'Conta Corrente SIEF', class: DebitoSiefPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCPF_SUSPENSO:             FeatureAction = { id: 'CcpfExigibilidade', title: 'Conta Corrente PF', class: CCPFExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_CCITR_SUSPENSO:            FeatureAction = { id: 'CcitrExigibilidade', title: 'Conta Corrente ITR', class: ContaCorrenteItrPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS PREVIDENCIÁRIOS*/
const ACTION_AGUIA_ATIVO:               FeatureAction = { id: 'AguiaCobranca', title: 'AGUIA', class: AguiaPFComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SICOB_ATIVO:               FeatureAction = { id: 'SicobDebitoCobranca', title: 'SICOB', class: DebitoSicobPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SICOB_SUSPENSO:            FeatureAction = { id: 'SicobDebitoExigibilidade', title: 'SICOB',  class: DebitoSicobPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS PGFN*/
const ACTION_INSC_PGFN_ATIVAS:          FeatureAction = { id: 'PgfnInscricaoCobranca', title: 'Inscrições', class: InscricaoPgfnPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DIVIDAS_PGFN_ATIVAS:       FeatureAction = { id: 'DividaDebitoCobranca', title: 'Dívidas', class: DebitoDividaPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_INSC_PGFN_SUSPENSAS:       FeatureAction = { id: 'PgfnInscricaoExigibilidade', title: 'Inscrições', class: InscricaoPgfnPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DIVIDAS_PGFN_SUSPENSAS:    FeatureAction = { id: 'DividaDebitoExigibilidade', title: 'Dívidas', class: DebitoDividaPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*DÉBITOS HISTÓRICO*/
const ACTION_DEBITOS_HISTORICO:         FeatureAction = { id: 'historico-debitos', title: 'Histórico da Sit. Fisc.', visible: false};

/*PROCESSOS FISCAIS*/
const ACTION_PROFISC_ATIVO:             FeatureAction = { id: 'ProfiscProcessoCobranca', title: 'PROFISC', class: ProcessoProfiscPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SIEFPROCESSO_ATIVO:        FeatureAction = { id: 'SiefProcessoCobranca', title: 'SIEF Processos', class: ProcessoSiefPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PROFISC_SUSPENSO:          FeatureAction = { id: 'ProfiscProcessoExigibilidade', title: 'PROFISC', class: ProcessoProfiscPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_SIEFPROCESSO_SUSPENSO:     FeatureAction = { id: 'SiefProcessoExigibilidade', title: 'SIEF Processos', class: ProcessoSiefPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*OUTROS PROCESSOS*/
const ACTION_PROCESSOS_ARQUIVADOS:      FeatureAction = { id: 'outros-processos-arquivados', title: 'Arquivados', visible: false}; //TODO
const ACTION_PROCESSOS_ATIVOS:          FeatureAction = { id: 'outros-processos-ativos', title: 'Em Andamento', visible: false}; //TODO

/*PARCELAMENTOS FAZENDÁRIOS*/
const ACTION_PARC_SIPADE_ATIVO:         FeatureAction = { id: 'SipadeParcelamentoCobranca', title: 'SIPADE', class: ParcelamentoSIPADECobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PAEX_ATIVO:           FeatureAction = { id: 'PaexParcelamentoCobranca', title: 'PAEX', class: ParcelamentoPaexPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIEFPAR_ATIVO:        FeatureAction = { id: 'SiefparParcelamentoCobranca', title: 'SIEFPAR', class: ParcelamentoSIEFParPFCobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIPADE_SUSPENSO:      FeatureAction = { id: 'SipadeParcelamentoExigibilidade', title: 'SIPADE', class: ParcelamentoSIPADEExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PAEX_SUSPENSO:        FeatureAction = { id: 'PaexParcelamentoExigibilidade', title: 'PAEX', class: ParcelamentoPaexPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SIEFPAR_SUSPENSO:     FeatureAction = { id: 'SiefparParcelamentoExigibilidade', title: 'SIEFPAR', class: ParcelamentoSIEFParPFExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };

/*PARCELAMENTOS PREVIDENCIÁRIOS*/
const ACTION_PARC_SICOB_ATIVO:          FeatureAction = { id: 'SicobParcelamentoCobranca', title: 'SICOB', class: ParcelamentoSicobPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_SICOB_SUSPENSO:       FeatureAction = { id: 'SicobParcelamentoExigibilidade', title: 'SICOB', class: ParcelamentoSicobPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*PARCELAMENTOS PGFN*/
const ACTION_PARC_PGFN_LEI_ATIVOS:      FeatureAction = { id: 'PgfnParcelamentoCobranca', title: 'Lei 11.941/2009', class: ParcelamentoPgfnPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_SISPAR_ATIVOS:   FeatureAction = { id: 'SisparPgfnCobranca', title: 'SISPAR', class: ParcelamentoSisparPFCobrancaComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_DIVIDA_ATIVOS:   FeatureAction = { id: 'DividaParcelamentoCobranca', title: 'Dívidas', class: ParcelamentoDividaPFAtivoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_LEI_SUSPENSOS:   FeatureAction = { id: 'PgfnParcelamentoExigibilidade', title: 'Lei 11.941/2009', class: ParcelamentoPgfnPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_SISPAR_SUSPENSOS:FeatureAction = { id: 'SisparPgfnExigibilidade', title: 'SISPAR', class: ParcelamentoSisparPFExigibilidadeComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_PARC_PGFN_DIVIDA_SUSPENSOS:FeatureAction = { id: 'DividaParcelamentoExigibilidade', title: 'Dívidas', class: ParcelamentoDividaPFSuspensoComponent, rolesAllowed: TODOS_PERFIS };

/*OUTROS PARCELAMENTOS*/
const ACTION_PARC_E_SOCIAL_ATIVOS:      FeatureAction = { id: 'parcelamentos-e-social-ativos', title: 'E-Social', visible: false};
const ACTION_PARC_E_SOCIAL_SUSPENSOS:   FeatureAction = { id: 'parcelamentos-e-social-suspensos', title: 'E-Social', visible: false};
const ACTION_PARC_PERT_ATIVOS:          FeatureAction = { id: 'parcelamentos-pert-ativos', title: 'PERT', visible: false};
const ACTION_PARC_PERT_SUSPENSOS:       FeatureAction = { id: 'parcelamentos-pert-suspensos', title: 'PERT', visible: false};

/*DECLARAÇÕES - AUSÊNCIA*/
const ACTION_DECL_AUSENCIA_DIRPF:       FeatureAction = { id: 'DirpfAusencia', title: 'DIRPF', class: DeclaracaoAusenteDirpfComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_DITR:        FeatureAction = { id: 'DitrAusencia', title: 'DITR', class: DeclaracaoAusentePFDitrComponent, rolesAllowed: TODOS_PERFIS };
const ACTION_DECL_AUSENCIA_GFIP:        FeatureAction = { id: 'GfipAusencia', title: 'GFIP', class: DeclaracaoAusentePFGfipComponent, rolesAllowed: TODOS_PERFIS };

/*DECLARAÇÕES - OBRIGATORIEDADE DE ENTREGA*/
const ACTION_DECL_OBRIGA_DIRPF:         FeatureAction = { id: 'obriga-declaracao-dirpf', title: 'DIRPF', visible: false};

/*DECLARAÇÕES - HISTÓRICO*/
const ACTION_DECL_HISTORICO:            FeatureAction = { id: 'historico-declaracao', title: 'Histórico das Declarações', visible: false};

/*MALHA - PENDÊNCIAS*/
const ACTION_DIRPF_MALHA:               FeatureAction = { id: 'DeclaracaoIrpfMalha', title: 'Declarações IRPF', class: DeclaracaoIrpfMalhaComponent, visible: true, rolesAllowed: TODOS_PERFIS };
const ACTION_OUTROS_MALHA:              FeatureAction = { id: 'declaracao-outros-malha', title: 'Outros', visible: false};

/*MALHA - HISTÓRICO*/
const ACTION_MALHA_HISTORICO:           FeatureAction = { id: 'historico-malha', title: 'Histórico da Malha', visible: false};

/*LIBERAÇÃO DE CND*/
const ACTION_LIBERACAO_CND:             FeatureAction = { id: 'LiberacaoCnd', title: 'Liberação de CND', class: LiberacaoCndPFComponent, visible: true, rolesAllowed: TODOS_PERFIS};

/*CERTIDÕES*/
const ACTION_CERTIDAO_INTRANET:         FeatureAction = { id: 'intranet', title: 'Intranet', visible: true, url: environment.urlCertidoesIntranet, rolesAllowed: TODOS_PERFIS };
const ACTION_CERTIDAO_INTERNET:         FeatureAction = { id: 'internet', title: 'Internet', visible: true, url: `${environment.urlCertidoesInternetPF}?Tipo=2`, rolesAllowed: TODOS_PERFIS };
const ACTION_CERTIDOES:                 FeatureAction = { id: 'certidoes', title: 'Certidões',
                                                          children: [ACTION_CERTIDAO_INTRANET, ACTION_CERTIDAO_INTERNET]
                                                        };

// Coleção de features que são opções do menu.
// Aqui é uma estrutura hierárquica, os itens do array são galhos de primeiro nível.
// A formatação visual do menu suporta até 2 níveis: pai -> filhos -> netos (folhas).
// Todos os itens deve possuir um id único de indentificação, os id das folhas serão utilizados para acionar
// seus respectivos quadros no panel de quadros.
const ACTION_DEBITOS: FeatureAction = {

  id: 'SITFIS - Débitos',
  title: 'Débitos',
  collapsed: false,
  children: [
    {
      id: 'SITFIS - Débitos Ativos',
      title: 'Ativos',
      collapsed: false,
      children: [
        {
          id: 'SITFIS - Débitos Ativos - Fazendários',
          title: 'Fazendários',
          collapsed: false,
          children: [ACTION_CCPF_ATIVO, ACTION_CCITR_ATIVO, ACTION_SIEF_ATIVO]
        },
        {
          id: 'SITFIS - Débitos Ativos - Previdenciários',
          title: 'Previdenciários',
          collapsed: false,
          children: [ACTION_SICOB_ATIVO, ACTION_AGUIA_ATIVO]
        },
        {
          id: 'SITFIS - Débitos Ativos - PGFN',
          title: 'PGFN',
          collapsed: false,
          children: [ACTION_INSC_PGFN_ATIVAS, ACTION_DIVIDAS_PGFN_ATIVAS]
        }
      ]
    },
    {
      id: 'SITFIS - Débitos Suspensos',
      title: 'Suspensos',
      collapsed: false,
      children: [
        {
          id: 'SITFIS - Débitos Suspensos - Fazendários',
          title: 'Fazendários',
          collapsed: false,
          children: [ACTION_CCPF_SUSPENSO, ACTION_CCITR_SUSPENSO, ACTION_SIEF_SUSPENSO]
        },
        {
          id: 'SITFIS - Débitos Suspensos - Previdenciários',
          title: 'Previdenciários',
          collapsed: false,
          children: [ACTION_SICOB_SUSPENSO]
        },
        {
          id: 'SITFIS - Débitos Suspensos - PGFN',
          title: 'PGFN',
          collapsed: false,
          children: [ACTION_INSC_PGFN_SUSPENSAS, ACTION_DIVIDAS_PGFN_SUSPENSAS]
        }
      ]
    },
    {
      id: 'SITFIS - Histórico',
      title: 'Histórico',
      children: [ACTION_DEBITOS_HISTORICO]
    }
  ]
};

const ACTION_PROCESSOS: FeatureAction = {

  id: 'SITFIS - Processos',
  title: 'Processos',
  children: [
    {
      id: 'SITFIS - Processos - Ativos',
      title: 'Ativos',
      children: [ACTION_PROFISC_ATIVO, ACTION_SIEFPROCESSO_ATIVO]
    },
    {
      id: 'SITFIS - Processos - Suspensos',
      title: 'Suspensos',
      children: [ACTION_PROFISC_SUSPENSO, ACTION_SIEFPROCESSO_SUSPENSO]
    },
    {
      id: 'SITFIS - Processos - Outros',
      title: 'Outros Processos',
      children: [ACTION_PROCESSOS_ARQUIVADOS, ACTION_PROCESSOS_ATIVOS]
    },
  ]
};

const ACTION_PARCELAMENTOS: FeatureAction = {

  id: 'SITFIS - Parcelamentos',
  title: 'Parcelamentos',
  children: [
    {
      id: 'SITFIS - Parcelamentos - Ativos',
      title: 'Ativos',
      children: [
        {
          id: 'SITFIS - Parcelamentos - Ativos - Fazendários',
          title: 'Fazendários',
          children: [ACTION_PARC_SIPADE_ATIVO, ACTION_PARC_PAEX_ATIVO, ACTION_PARC_SIEFPAR_ATIVO]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - Previdenciários',
          title: 'Previdenciários',
          children: [ACTION_PARC_SICOB_ATIVO]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - PGFN',
          title: 'PGFN',
          children: [ACTION_PARC_PGFN_LEI_ATIVOS, ACTION_PARC_PGFN_SISPAR_ATIVOS, ACTION_PARC_PGFN_DIVIDA_ATIVOS]
        },
        {
          id: 'SITFIS - Parcelamentos - Ativos - Outros',
          title: 'Outros Parcelamentos',
          children: [ACTION_PARC_E_SOCIAL_ATIVOS, ACTION_PARC_PERT_ATIVOS]
        },
      ]
    },

    {
      id: 'SITFIS - Parcelamentos - Suspensos',
      title: 'Suspensos',
      children: [
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Fazendários',
          title: 'Fazendários',
          children: [ACTION_PARC_SIPADE_SUSPENSO, ACTION_PARC_PAEX_SUSPENSO, ACTION_PARC_SIEFPAR_SUSPENSO]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Previdenciários',
          title: 'Previdenciários',
          children: [ACTION_PARC_SICOB_SUSPENSO]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - PGFN',
          title: 'PGFN',
          children: [ACTION_PARC_PGFN_LEI_SUSPENSOS, ACTION_PARC_PGFN_SISPAR_SUSPENSOS, ACTION_PARC_PGFN_DIVIDA_SUSPENSOS]
        },
        {
          id: 'SITFIS - Parcelamentos - Suspensos - Outros',
          title: 'Outros Parcelamentos',
          children: [ACTION_PARC_E_SOCIAL_SUSPENSOS, ACTION_PARC_PERT_SUSPENSOS]
        },
      ]
    },
  ]
};

const ACTION_DECLARACOES: FeatureAction = {

  id: 'SITFIS - Declarações',
  title: 'Declarações',
  children: [
    {
      id: 'SITFIS - Declarações - Ausentes',
      title: 'Ausentes',
      children: [
        ACTION_DECL_AUSENCIA_DIRPF,
        ACTION_DECL_AUSENCIA_DITR,
        ACTION_DECL_AUSENCIA_GFIP
      ]
    },
    {
      id: 'SITFIS - Declarações - Obrigatoriedade Entrega',
      title: 'Obrigatoriedade de Entrega',
      children: [ACTION_DECL_OBRIGA_DIRPF]
    },
    {
      id: 'SITFIS - Declarações - Histórico',
      title: 'Histórico',
      children: [ACTION_DECL_HISTORICO]
    },
  ]
};

const ACTION_MALHA: FeatureAction = {

  id: 'SITFIS - Malha',
  title: 'Malha',
  children: [
    {
      id: 'SITFIS - Malha - Pendências',
      title: 'Pendências',
      children: [ACTION_DIRPF_MALHA, ACTION_OUTROS_MALHA]
    },
    {
      id: 'SITFIS - Malha - Histórico',
      title: 'Histórico',
      children: [ACTION_MALHA_HISTORICO]
    }
  ]
};

export const FEATUREACTIONS_PF: FeatureAction[] = [
  ACTION_RELATORIO_APOIO_CND,
  ACTION_INFORMACOES_CADASTRAIS,
  ACTION_CNPJS_VINCULADOS,
  ACTION_IRREGULARIDADE_CADASTRAL,
  ACTION_ARROLAMENTO_BENS_SIEF,
  ACTION_IMPEDIMENTOS_EMISSAO_CND,
  ACTION_DEBITOS,
  ACTION_PROCESSOS,
  ACTION_PARCELAMENTOS,
  ACTION_DECLARACOES,
  ACTION_MALHA,
  ACTION_CERTIDOES,
  ACTION_LIBERACAO_CND,
];

export const FEATUREACTIONS_PF_VINCULADO: FeatureAction[] = [
  ACTION_CNPJS_VINCULADOS,
  ACTION_IRREGULARIDADE_CADASTRAL,
  ACTION_IMPEDIMENTOS_EMISSAO_CND,
  ACTION_DEBITOS,
  ACTION_PROCESSOS,
  ACTION_PARCELAMENTOS,
  ACTION_DECLARACOES,
  ACTION_MALHA,
  ACTION_CERTIDOES,
  ACTION_LIBERACAO_CND,
];

export const DADOS_BASICOS_PF = ACTION_DADOS_BASICOS;

export const TITULO_ELEITOR: FeatureAction = { id: 'TituloEleitor', title: 'Titulo Eleitor', class: TituloEleitorComponent, visible: true };

// AÇÕES ACIONADAS A PARTIR DOS QUADROS PROCESSOS PROFISC
export const DEBITO_PROFISC_PF_ATIVO: FeatureAction = { id: 'DebitoProfiscPFAtivo', title: 'Débito PROFISC Pessoa Fisica Ativo', class: DebitoProfiscPFAtivoComponent, visible: true };
export const DEBITO_PROFISC_PF_SUSPENSO: FeatureAction = { id: 'DebitoProfiscPFSuspenso', title: 'Débito PROFISC Pessoa Fisica Suspenso', class: DebitoProfiscPFSuspensoComponent, visible: true };

// AÇÕES ACIONADAS A PARTIR DOS QUADROS PROCESSOS SIEF
export const DEBITO_PROCESSO_SIEF_PF_ATIVO: FeatureAction = { id: 'DebitoProcessoSiefPFAtivo', title: 'Débito Processo SIEF Pessoa Fisica Ativo', class: DebitoProcessoSiefPFAtivoComponent, visible: true };
export const DEBITO_PROCESSO_SIEF_PF_SUSPENSO: FeatureAction = { id: 'DebitoProcessoSiefPFSuspenso', title: 'Débito Processo SIEF Pessoa Fisica Suspenso', class: DebitoProcessoSiefPFSuspensoComponent, visible: true };
