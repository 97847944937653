<div [ngClass]="{ 'modal-container': !atendimento }">
  <suite-rfb-collapse [title]="titulo" [collapsed]="false">
    <suite-rfb-alert-messages-with-datetime [onlyFor]="scopeId"></suite-rfb-alert-messages-with-datetime>
    <ng-container *ngIf="CTDepurados && CTDepurados.length > 0; else loadingOrError;">
      <div class="table-responsive">
        <table suiteRfbTable>
          <thead>
            <tr>
              <th class="text-center min-width">Ações</th>
              <th class="text-center">Exercício</th>
              <th class="text-center">Situação</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let item of CTDepurados | currentPage: currentPage">
              <tr class="vertical-align-middle">
                <td class="text-center">
                  <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes"></suite-rfb-popover-button>
                  <ng-template #menuAcoes>
                    <div class="btn-group-vertical" #botaoRetornarCT role="group">
                      <button [disabled]="false" #botao type="button" class="btn btn-default" (click)="retornarCTDepurado(item); botaoAcoes.hide();">
                        Retornar
                      </button>
                    </div>
                  </ng-template>
                </td>
                <td class="text-center min-width">{{ item.exercicioCT }}</td>
                <td class="text-left">{{ item.descricaoTipoLancamento }}</td>
              </tr>
            </ng-container>
          </tbody>

          <tfoot>
            <tr class="tabela-acoes text-left">
            <td colspan="5">
              <via-pagination [itemAmount]="CTDepurados.length" [(page)]="currentPage"></via-pagination>
              </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </ng-container>
  </suite-rfb-collapse>
</div>

  <ng-template #loadingOrError>
    <div *ngIf="initError; else loading;"></div>
    <ng-template #loading>
      <loading-animation *ngIf="!stopLoading"></loading-animation>
    </ng-template>
  </ng-template>

