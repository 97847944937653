import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { CpfService } from '../../services/cpf.service';
import { DirfService } from './dirf.service';
import { SumarioAnual } from './model/sumario-anual';

@Component({
  selector: 'via-dirf',
  templateUrl: './dirf.component.html',
  styleUrls: ['./dirf.component.css'],
  providers: [ScopedMessageService]
})
export class DirfComponent implements OnInit {

  @Input() cpf: string;

  scopeId: string;
  initError: Error;
  stopLoading = false;

  retencoes: SumarioAnual[] = [];
  nomeBeneficiario: string;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  private executouObterDados = false;

  constructor(
    private dirfService: DirfService,
    private cpfService: CpfService,
    private messageService: ScopedMessageService) { }

  get _window() {
    return window;
  }

  ngOnInit() {
    this.scopeId = this.messageService.scopeId;
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.executouObterDados = true;
      this.obterRetencoes();
      this.obterNomeBeneficiario();
    }
  }

  private obterRetencoes(): void {
    this.dirfService.obterRetencoes(this.cpf).subscribe(
      response => {
        this.retencoes = response;

        if (isEmpty(this.retencoes)) {
          this.messageService.showInfo('Nenhum resultado encontrado');
          this.stopLoading = true;
        }
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter as declarações de imposto retido na fonte da pessoa física', error);
        this.initError = error;
      }
    );
  }

  private obterNomeBeneficiario(): void {
    this.cpfService.obterDadosResumidos(this.cpf).subscribe(
      response => this.nomeBeneficiario = response.nome,
      error => this.messageService.showErrorException('Ocorreu um erro ao obter o nome do beneficiário', error)
    );
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  urlPortalDirf(_retencao: SumarioAnual): string {
    return this.dirfService.getUrlPortalDirf();
  }
}
