import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatNirfPipe } from 'src/app/shared/pipes/formata-nirf.pipe';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { TipoNI } from 'src/app/sitfis/model/enum/tipo-ni';
import { DetalheImovelRural } from '../../models/detalhe-imovel-rural';
import { CafirService } from '../../services/cafir.service';

@Component({
  selector: 'via-detalhe-imovel-rural',
  templateUrl: './detalhe-imovel-rural.component.html',
  styleUrls: [
    '../table/cafir-table.component.css',
    './detalhe-imovel-rural.component.css'
  ],
  providers: [ScopedMessageService]
})
export class DetalheImovelRuralComponent implements IDynamicPanel, OnInit {

  @Input() ni: string;
  @Input() nirf: string;
  @Input() detalhe: DetalheImovelRural;
  @Input() isModal = false;

  modalTitulo: string;
  modalScopeId: string;
  initError: Error;
  stopLoading = false;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  constructor(
    private messageService: ScopedMessageService,
    private cafirService: CafirService) { }

  init(_: string, params?: OptionalParams): void {
    this.ni = params.ni;
    this.nirf = params.nirf;
    this.isModal = params.isModal ?? false;
  }

  ngOnInit(): void {
    if (this.isModal) {
      this.modalTitulo = `NIRF: ${FormatNirfPipe.formatNirf(this.nirf)}`;
      this.modalScopeId = this.messageService.scopeId;
      this.obterDetalhe();
    }
  }

  getTipoNi(ni: string): TipoNI {
    const TAMANHO_CPF = 11;
    return ni.length === TAMANHO_CPF ? TipoNI.CPF : TipoNI.CNPJ;
  }

  private obterDetalhe(): void {
    this.cafirService.obterDetalheImovelRural(this.ni, this.nirf).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Detalhe do imóvel não encontrado');
          this.stopLoading = true;
        }

        this.detalhe = response;
      },
      error => {
        this.messageService.showErrorException(`Ocorreu um erro na consulta de detalhe do imóvel rural com NIRF: ${this.nirf}`, error);
        this.initError = error;
      }
    );
  }
}
