import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../shared/services/storage.service';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clonedReq = req.clone({
      withCredentials: true,
      // implementamos, quando apropriado, uma cache no gateway
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });

    let cipheredLogonDate = this.storageService.getLogonDate();
    if( cipheredLogonDate ) {
      clonedReq = clonedReq.clone({
        setHeaders: {
          data_logon: cipheredLogonDate
        }
      });
    }

    return next.handle(clonedReq);
  }
}
