<table suiteRfbTable class="tabelaInterna" class="tabelaInterna">
  <caption class="caption-ccpf">Componentes</caption>
  <ng-container *ngIf="!semComponente; else nenhumComponente;">
  <thead>
    <tr>
      <th class="text-center">Número</th>
      <th class="text-center">Exercício</th>
      <th class="text-center">Tipo Lançamento</th>
      <th class="text-center">Data Original</th>
      <th class="text-center">Data Vencimento</th>
      <th class="text-center">Valor Alocação</th>
      <th class="text-center">Situação</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let componente of componentes" class="vertical-align-middle">
      <tr class="vertical-align-middle">
        <td class="text-center">{{ componente.numero | vazio }}</td>
        <td class="text-center">{{ componente.exercicio | vazio }}</td>
        <td class="text-left">{{ componente.tipoLancamento | vazio }}</td>
        <td class="text-center">{{ componente.dataOriginaria | formatDataPipe }}</td>
        <td class="text-center">{{ componente.dataVencimento | formatDataPipe }}</td>
        <td class="text-right">{{ componente.valorAlocado | formatCurrency }}</td>
        <td class="text-left">{{ componente.situacao | vazio }}</td>
      </tr>
    </ng-container>
  </tbody>
  </ng-container>
  <ng-template #nenhumComponente>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum componente encontrado para este pagamento TDA.
      </td>
    </tr>
  </ng-template>
</table>
