import { Component } from '@angular/core';
import { IDynamicPanel } from 'src/app/shared/dynamic-panel/panel-interface';
import { FormatCnpjPipe } from 'src/app/shared/pipes/formata-cnpj.pipe';
import { FormatCpfPipe } from 'src/app/shared/pipes/formata-cpf.pipe';
import { LogService } from 'src/app/shared/services/log.service';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { AtendimentoService } from 'src/app/workplace/components/atendimento/atendimento.service';
import { ProcessoSief } from '../../model/processo-sief';
import { ProcessoSiefService } from './processo-sief.service';

@Component({
  selector: 'via-processo-sief',
  templateUrl: './processo-sief.component.html',
  styleUrls: ['./processo-sief.component.css']
})
export class ProcessoSiefComponent implements IDynamicPanel {

  initError: Error;

  ni: string;
  titulo: string;
  numeroProtocolo: string;
  processos: ProcessoSief[];
  parametros: any;

  isPessoaFisica = false;
  isPessoaJuridica = false;
  isAtivos = false;
  isSuspensos = false;

  constructor(
    private siefProcessoService: ProcessoSiefService,
    private atendimentoService: AtendimentoService,
    private messageService: ScopedMessageService,
    private logService: LogService) { }

  public init(ni: string, parametros: any): void {
    this.ni = ni;
    this.parametros = parametros;
    console.log('ProcessoSIEFComponent.init ni', ni);

    const infosContribuinte = this.atendimentoService.getInfosAtendimento(ni);
    this.numeroProtocolo = infosContribuinte.protocoloSitFis.numeroProtocolo;
    this.logService.logAjaxCall('ProcessoSiefComponent.obterProcessos', 'vai obterprocessos', ni);

    try {
      this.siefProcessoService.obterProcessos(ni, this.numeroProtocolo, this.isAtivos).subscribe(
        (resp) => {
          this.processos = resp;
          console.table(resp);
          this.logService.logAjaxReturn('ProcessoSiefComponent.obterProcessoFiscal', 'recuperou debitos Processo Fiscal', this.processos);
        },
        (err) => {
          this.initError = err;
          this.logService.logAjaxError('ProcessoSiefComponent.obterProcessoFiscal', 'erro recuperando debitos Processo Fisccal', err);
        }
      );
    } catch (e) {
      this.initError = e;
      this.logService.logError('ProcessoSiefComponent.obterProcessoFiscal', 'ocorreu um erro ao obter debitos Processo Fiscal', e);
      this.messageService.showErrorException('Ocorreu um erro ao obter os processos ', e);
    }
  }
}

@Component({
  selector: 'via-processo-sief',
  templateUrl: './processo-sief.component.html',
  styleUrls: ['./processo-sief.component.css']
})
export class ProcessoSiefPJSuspensoComponent extends ProcessoSiefComponent {
  init(ni: string, parametros: any) {
    this.isPessoaJuridica = true;
    this.isSuspensos = true;
    this.titulo = `Processos Suspensos SIEF Processos - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-sief',
  templateUrl: './processo-sief.component.html',
  styleUrls: ['./processo-sief.component.css']
})
export class ProcessoSiefPJAtivoComponent extends ProcessoSiefComponent {
  init(ni: string, parametros: any) {
    this.isPessoaJuridica = true;
    this.isAtivos = true;
    this.titulo = `Processos Ativos SIEF Processos - CNPJ: ${FormatCnpjPipe.formatCNPJ(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-sief',
  templateUrl: './processo-sief.component.html',
  styleUrls: ['./processo-sief.component.css']
})
export class ProcessoSiefPFAtivoComponent extends ProcessoSiefComponent {
  init(ni: string, parametros: any) {
    this.isPessoaFisica = true;
    this.isAtivos = true;
    this.titulo = `Processos Ativos SIEF Processos - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni, parametros);
  }
}

@Component({
  selector: 'via-processo-sief',
  templateUrl: './processo-sief.component.html',
  styleUrls: ['./processo-sief.component.css']
})
export class ProcessoSiefPFSuspensoComponent extends ProcessoSiefComponent {
  init(ni: string, parametros: any) {
    this.isPessoaFisica = true;
    this.isSuspensos = true;
    this.titulo = `Processos Suspensos SIEF Processos - CPF: ${FormatCpfPipe.formatCPF(ni)}`;
    super.init(ni, parametros);
  }
}
