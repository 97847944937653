import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuFeatureActionsService } from '../../menu-feature-actions/menu-feature-actions.service';
import { FeatureAction } from '../../model/feature';
import { LogService } from '../../services/log.service';
import { IDynamicPanel } from '../panel-interface';

@Component({
  selector: 'dynamic-app-action-panel',
  templateUrl: './dynamic-action-panel.component.html',
  styleUrls: ['./dynamic-action-panel.component.css']
})
export class DynamicActionPanelComponent {

  @Output()
  public onFeatureClosed = new EventEmitter<string>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  featureAction: FeatureAction;

  // referência para o elemento (html nativo) parent de tudo
  @ViewChild('fs')
  fs: ElementRef;

  // referência para o parent do componente instanciado
  @ViewChild('container', { read: ViewContainerRef, static: true })
  panelContainerRef: ViewContainerRef;

  // referência para o componente instanciado dinamicamente
  public panelRef: ComponentRef<IDynamicPanel>;

  constructor(
    private menuFeatureActionService: MenuFeatureActionsService,
    private resolver: ComponentFactoryResolver,
    private logService: LogService) {
  }

  public destroy(): void {
    this.logService.logStep('DynamicActionPanelComponent.destroy', 'this', this);
    try {
      this.logService.logStep('DynamicActionPanelComponent.destroy', 'vai eliminar o componente');
      // destroi o componente filho que foi instanciado
      this.onFeatureClosed.emit(this.featureAction.id);
      this.panelRef.destroy();

      this.logService.logStep('DynamicActionPanelComponent.destroy', 'componente eliminado com sucesso!');
    } catch (error) {
      this.logService.logError('DynamicActionPanelComponent.destroy', 'ocorreu erro no encerrramento do quadro ' + this.featureAction, error);
    }
  }

  onCloseClick() {
    this.logService.logStep('DynamicActionPanelComponent.destroy', 'notifica o fechamento do painel', this.featureAction);
    this.menuFeatureActionService.deactivate(this.featureAction);
  }

  public closeRequest(): void {
    this.destroy();
  }

  public instantiatePanel(featureAction: FeatureAction, ni: string) {
    this.featureAction = featureAction;
    let parametros = featureAction.parametros ?? {};
    parametros.idFeature = featureAction.id;

    try {
      this.logService.logStep('DynamicActionPanelComponent.instantiatePanel', `vai instanciar um painel dinamico interno...`, featureAction);
      const pFactory = this.resolver.resolveComponentFactory(featureAction.class);
      this.panelRef = this.panelContainerRef.createComponent(pFactory);
      this.logService.logStep('DynamicActionPanelComponent.instantiatePanel', `conteiner interno`, this.panelRef);

      const panel = this.panelRef.instance;
      this.logService.logStep('DynamicActionPanelComponent.instantiatePanel', `inicializando painel instanciado...`, panel);
      panel.init(ni, parametros);
      this.logService.logStep('DynamicActionPanelComponent.instantiatePanel', `painel inicializado com sucesso!`, panel);

      setTimeout(() => {
        (this.fs.nativeElement as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }, 200);

    } catch (error) {
      this.logService.logError('DynamicActionPanelComponent.destroy', 'ocorreu erro no instanciação do quadro ' + this.featureAction, error);
      throw error;
    }

    this.menuFeatureActionService.featureActionEvent$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(featureEvent => {
        if (featureEvent.active && featureEvent.featureAction.id === this.featureAction.id) {
          setTimeout(() => {
            (this.fs.nativeElement as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }, 200);
        }
      });
  }
}
