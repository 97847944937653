import { Pipe, PipeTransform } from '@angular/core';
import { NaturezaDocumentoArrecadacao } from '../models/natureza-documento-arrecadacao';

@Pipe({
  name: 'naturezasDocArrecadacaoPipe'
})
export class NaturezasDocArrecadacaoPipe implements PipeTransform {

  transform(naturezas: NaturezaDocumentoArrecadacao[]): string[] {
    return naturezas.map(natureza => natureza.tnrNm);
  }
}
