import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ParametrosPesquisaTituloEleitor } from './model/parametros-pesquisa-titulo-eleitor';
import { TituloEleitor } from './model/titulo-eleitor';

@Injectable()
export class TituloEleitorService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterDadosTituloEleitor(cpf: string): Observable<TituloEleitor> {
    const url = `${this.baseUrl}/cpf/titulo-eleitor/${cpf}`;
    return this.http.get<TituloEleitor>(url);
  }

  public pesquisarTituloEleitor(parametros: ParametrosPesquisaTituloEleitor): Observable<TituloEleitor[]> {
    const url = `${this.baseUrl}/cpf/titulo-eleitor`;
    return this.http.post<TituloEleitor[]>(url, parametros);
  }
}
