import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImpedimentoCnd } from './model/impedimento-cnd';

@Injectable()
export class ImpedimentoCndService {

  private baseUrl = environment.APIEndpoint;

  constructor(private http: HttpClient) { }

  public obterImpedimentosCpfAtivoRfb(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CPFAtivo/RFB/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCpfAtivoPgfn(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CPFAtivo/PGFN/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCpfCanceladoRfb(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CPFCancelado/RFB/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCpfCanceladoPgfn(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CPFCancelado/PGFN/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCnpjMatrizRfb(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CNPJMatriz/RFB/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCnpjMatrizPgfn(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CNPJMatriz/PGFN/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCnpjAdquiridaRfb(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CNPJAdquirida/RFB/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }

  public obterImpedimentosCnpjAdquiridaPgfn(ni: string, numeroProtocolo: string): Observable<ImpedimentoCnd[]> {
    const url = `${this.baseUrl}/situacoesfiscais/impedimentos/CNPJAdquirida/PGFN/${ni}`;
    console.log('url= ' + url);
    const options = {
      params: new HttpParams().set('numeroProtocolo', numeroProtocolo)
    };
    return this.http.get<ImpedimentoCnd[]>(url, options);
  }
}
