import { Component, Input, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { DetalheSocio } from './model/detalhe-socio';
import { SocioEmpresa, SocioEmpresaHistorico, TipoSocio } from './model/socio-empresa';
import { QuadroSocietarioService } from './quadro-societario.service';

@Component({
  selector: 'via-quadro-societario',
  templateUrl: './quadro-societario.component.html',
  styleUrls: ['./quadro-societario.component.css'],
  providers: [ScopedMessageService]
})
export class QuadroSocietarioComponent implements OnInit {

  @Input() cnpj: string;
  @Input() incluiHistorico: boolean;

  titulo: string;
  scopeId: string;

  initError: Error;
  stopLoading = false;

  private executouObterDados = false;

  ctxSociosPessoaFisica: DetalheSocio = {
    socios: null,
    rotuloQuadro: 'Sócios Pessoa Física',
    rotuloNi: 'CPF',
    tipoSocio: TipoSocio.SocioPessoaFisica
  };

  ctxSociosPessoaJuridica: DetalheSocio = {
    socios: null,
    rotuloQuadro: 'Sócios Pessoa Jurídica',
    rotuloNi: 'CNPJ',
    tipoSocio: TipoSocio.SocioPessoaJuridica
  };

  ctxSociosEstrangeiros: DetalheSocio = {
    socios: null,
    rotuloQuadro: 'Sócios Estrangeiros',
    rotuloNi: 'NI',
    tipoSocio: TipoSocio.SocioEstrangeiro
  };

  constructor(
    private quadroSocietarioService: QuadroSocietarioService,
    private messageService: ScopedMessageService) { }

  ngOnInit() {
    this.scopeId = this.messageService.scopeId;
    if (this.incluiHistorico) {
      this.titulo = 'Histórico Quadro Societário';
    }
    else {
      this.titulo = 'Quadro Societário';
    }
  }

  private obterDadosHistoricoQuadroSocietario(): void {
    this.executouObterDados = true;
    this.quadroSocietarioService.obterDadosHistoricoSocios(this.cnpj).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum resultado encontrado');
          this.stopLoading = true;
        }

        this.processarResposta(response);
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter o histórico do quadro societário', error);
        this.initError = error;
      }
    );
  }

  private obterDadosQuadroSocietario(): void {
    this.executouObterDados = true;
    this.quadroSocietarioService.obterDadosSocios(this.cnpj).subscribe(
      response => {
        if (isEmpty(response)) {
          this.messageService.showInfo('Nenhum resultado encontrado');
          this.stopLoading = true;
        }

        this.processarResposta(response);
      },
      error => {
        this.messageService.showErrorException('Ocorreu um erro ao obter o quadro societário', error);
        this.initError = error;
      }
    );
  }

  private processarResposta(response: SocioEmpresa[] | SocioEmpresaHistorico[]) {
    response.forEach(socio => this.classificarSocio(socio));
  }

  private classificarSocio(socio: SocioEmpresa | SocioEmpresaHistorico): void {
    if (socio.inCpfCnpjSocio === '1') {
      (this.ctxSociosPessoaJuridica.socios || (this.ctxSociosPessoaJuridica.socios = [])).push(socio);
      return;
    }

    if (socio.inCpfCnpjSocio === '2') {
      (this.ctxSociosPessoaFisica.socios || (this.ctxSociosPessoaFisica.socios = [])).push(socio);
      return;
    }

    if (socio.inCpfCnpjSocio === '3') {
      (this.ctxSociosEstrangeiros.socios || (this.ctxSociosEstrangeiros.socios = [])).push(socio);
    }
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      if (this.incluiHistorico) {
        this.obterDadosHistoricoQuadroSocietario();
      }
      else {
        this.obterDadosQuadroSocietario();
      }
    }
  }
}
