<div class="table-group-container">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">Exercício</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let declaracao of declaracoes | currentPage: currentPage;" class="vertical-align-middle">
        <td class="text-center">
          <suite-rfb-popover-button
            #popoverAcoes
            [template]="botoesAcao"
            [templateContext]="{ declaracao: declaracao }">
          </suite-rfb-popover-button>
          <ng-template #botoesAcao let-declaracao="declaracao">
            <div class="btn-group-vertical" role="group">
              <button
                type="button"
                class="btn btn-default"
                (click)="onEmitirExtrato(declaracao); popoverAcoes.hide()">
                Extrato
              </button>
            </div>
          </ng-template>
        </td>
        <td class="text-center">{{ declaracao.anoExercicio }}</td>
        <td class="text-left">{{ declaracao.status }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="3">
          <via-pagination [itemAmount]="declaracoes.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
  <button type="button" class="btn btn-md btn-default" (click)="onAbrirPortalIrpf()">Portal IRPF</button>
  <button type="button" class="btn btn-md btn-default" (click)="onAbrirPortalSiefWeb()">SIEF Web</button>
</div>
