<div class="table-responsive">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Detalhe</th>
        <th class="text-center min-width">CNPJ do Cartório</th>
        <th class="text-center min-width">Controle</th>
        <th class="text-center min-width">Recibo</th>
        <th class="text-center min-width">Tipo da Declaração</th>
        <th class="text-center min-width">Tipo de Operação</th>
        <th class="text-center min-width">Data da Alienação</th>
        <th class="text-center">Valor da Operação</th>
        <th class="text-center">Participantes</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let declaracao of declaracoes | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-toggle
              #rowToggle
              (expanded)="onCarregarDetalhe($event, declaracao)"
              title="Detalhar Declaração">
            </suite-rfb-toggle>
          </td>

          <td class="text-center text-nowrap">
            <ng-container *ngIf="declaracao.cnpjCartorio; else templateVazio;">
              <ni-com-link
                [ni]="declaracao.cnpjCartorio"
                tipoNi="CNPJ">
              </ni-com-link>
            </ng-container>
          </td>

          <td class="text-center">
            {{ declaracao.controle | vazio }}
          </td>

          <td class="text-center">
            {{ declaracao.recibo | vazio }}
          </td>

          <td class="text-center">
            {{ declaracao.tipoDeclaracao | vazio }}
          </td>

          <td class="text-center">
            {{ declaracao.tipoOperacao | vazio }}
          </td>

          <td class="text-center">
            {{ declaracao.dataAlienacao | date: 'dd/MM/yyyy' | vazio }}
          </td>

          <td class="text-right text-nowrap">
            {{ declaracao.valorAlienacao | formatCurrency | vazio }}
          </td>

          <td class="text-center">
            {{ declaracao.participantes | vazio }}
          </td>
        </tr>

        <ng-container *ngIf="rowToggle.show">
          <ng-container *ngIf="detalhePorDeclaracao.get(declaracao.id).executouChamada; else loadingOrError;">
            <tr></tr>
            <tr>
              <td colspan="9" class="background-inherit">
                <via-doi-detalhe
                  [cpf]="cpf"
                  [detalhe]="detalhePorDeclaracao.get(declaracao.id).declaracao">
                </via-doi-detalhe>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="9">
          <via-pagination [itemAmount]="declaracoes.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #templateVazio>
  <p>{{ '' | vazio }}</p>
</ng-template>

<ng-template #loadingOrError>
  <div *ngIf="initError; else loading;"></div>
  <ng-template #loading>
    <loading-animation></loading-animation>
  </ng-template>
</ng-template>
