import { isWarning } from './../../../../models/erro-padrao';
import { CCITRAlocacaoDebitoComponent } from './../pagamento/alocacao-debito/alocacao-debito.component';
import { CONSTANTS } from './../../constants';
import { CCITRService } from './../../services/ccitr.service';
import { CcitrPagamentosDarfDTO } from './model/ccitr-pagamentos-darf';
import { ParametrosConsultaPagamentosDARF } from 'src/app/cobranca/models/parametros-consulta-pagamentos_darf';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { LoadingService, Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { isEmpty } from 'lodash';
import { ScopedMessageService } from 'src/app/shared/services/scoped-message.service';
import { IDynamicPanel, OptionalParams } from 'src/app/shared/dynamic-panel/panel-interface';
import { CcitrBase } from '../../base/CcitrBase.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LogService } from 'src/app/shared/services/log.service';
import { catchError } from 'rxjs/operators';
import { FeatureAction, TipoMenu } from 'src/app/shared/model/feature';
import { DynamicModalRequestService } from 'src/app/shared/dynamic-modal/dynamic-modal-request.service';
import { MenuFeatureActionsService } from 'src/app/shared/menu-feature-actions/menu-feature-actions.service';

@Component({
  selector: 'via-ccitr-pagamentos-darf',
  templateUrl: './ccitr-pagamentos-darf.component.html',
  styleUrls: ['./ccitr-pagamentos-darf.component.css','../../base/ccitr.css'],
  providers: [ScopedMessageService]})

export class CcitrPagamentosDarfComponent extends CcitrBase implements IDynamicPanel, OnChanges, OnDestroy {

  @Input() parametros: ParametrosConsultaPagamentosDARF ;
  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;
  @Output() onError: EventEmitter<void> = new EventEmitter();

  nirf: string;
  numeroCreditoTributario: string;

  ccitrPagamentosDarf: CcitrPagamentosDarfDTO[];

  stopLoading = false;
  initError: Error;
  scopeId: string;
  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  showCloseModal = true;
  updateSubscription: Subscription;

  private executouObterDados = false;


  constructor(
    private bsModalRef: BsModalRef,
    private messageService: ScopedMessageService,
    private logService: LogService,
    private cCITRService: CCITRService,
    private dynamicModalService: DynamicModalRequestService,
    private menuFeatureActionsService: MenuFeatureActionsService,
    private loadingService: LoadingService) {
      super();
    }

  public init(ni: string, params?: OptionalParams): void {
    super.init(ni, params);
    this.showCloseModal = false;
    this.parametros = params as ParametrosConsultaPagamentosDARF;

    this.nirf = this.parametros.nirf;
    this.numeroCreditoTributario = this.parametros.numeroCreditoTributario;

    this.recarregar();
  }

  ngOnInit(): void {
    this.scopeId = this.messageService.scopeId;
    this.formataTitulo(this.parametros, 'CCITR - Consultar Pagamentos DARF');
 //   this.recarregar();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if(this.titulo) {
      this.recarregar();
    }
  }

  onFecharModal(): void {
    this.bsModalRef?.hide();
  }

  private obterCcitrPagamentosDarf(): void {
    this.executouObterDados = true;

    let pagamentosDARFObservable: Observable<CcitrPagamentosDarfDTO[]>;

    pagamentosDARFObservable = this.cCITRService.obterPagamentosDarf(
        this.parametros.nirf,
        this.parametros.tipoLancamento,
        this.parametros.cpfContribuinte,
        this.parametros.cnpjContribuinte,
        this.parametros.numeroCreditoTributario,
        this.parametros.paraAlocacao);

    pagamentosDARFObservable
    .pipe(
      catchError(err => {
        if (isWarning(err.error)) {
          this.messageService.showWarning(err.error.message);
          this.stopLoading = true;
          return null;
        } else {
          this.initError = err;
          this.onError.emit();
          this.messageService.showErrorException("Ocorreu um erro ao consultar CCITR Pagamentos DARF ", err);
          return throwError(err);
        }
    }))
    .subscribe(
      response => {
        try{
          this.ccitrPagamentosDarf = response;
          if (isEmpty(response)) {
            this.messageService.showInfo('Nenhum registro encontrado');
          }
           this.stopLoading = true;
        } catch(e) {
          this.initError = e;
          this.logService.logError('CcitrPagamentosDarfComponent', 'ccitrPagamentosDarfComponent', e);
          this.messageService.showErrorException('Ocorreu um erro ao consultar CCITR Pagamentos DARF', e);
        }
      }
    );
  }

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(row => row.show = show);
  }

  public ngOnDestroy(): void {
    if(this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public recarregar(): void {
    this.initError = null;
    // this.creditosTributarios = null;
    this.obterCcitrPagamentosDarf();
  }

  onCarregarDados(collapsed: boolean): void {
    if (!collapsed && !this.executouObterDados) {
      this.obterCcitrPagamentosDarf();
    }
  }

  onAlocarDebito(pagamento: CcitrPagamentosDarfDTO): void {

console.log("on alocar debito");
console.log({ pagamento });

    const feature = {
      id: `CCITR_Alocar_Debito_pagamento_${pagamento.numeroPagamento}`,
      title: 'CCITR - Alocar Débito',
      class: CCITRAlocacaoDebitoComponent,
      parametros: {
        pagamento: pagamento,
        parametrosPesquisa: this.parametros
      }
    } as FeatureAction;

    if (!this.parametros.atendimento) {
      this.dynamicModalService.abrirModal(feature);
      return;
    }

    feature.visible = true;
    feature.rolesAllowed = [];
    feature.tipoMenu = TipoMenu.ATENDIMENTO;

    this.menuFeatureActionsService.activate(feature);
  }

}
