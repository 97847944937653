<div class="table-responsive" style="margin-left: -20px;">
  <table suiteRfbTable>
    <thead>
      <tr>
        <th class="text-center min-width">Ações</th>
        <th class="text-center min-width">
          <suite-rfb-toggle
            title="Expandir/Contrair Todos"
            (expanded)="expandedAll($event)">
          </suite-rfb-toggle>
        </th>
        <th class="text-center min-width">Processo</th>
        <th class="text-center min-width">Devedor Principal</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let processo of processos | currentPage: currentPage">
        <tr class="vertical-align-middle">
          <td class="text-center">
            <suite-rfb-popover-button #botaoAcoes [template]="menuAcoes">
            </suite-rfb-popover-button>

            <ng-template #menuAcoes>
              <div class="btn-group-vertical" role="group">
                <button
                  class="btn btn-default"
                  type="button"
                  (click)="onExibirDebitos(processo); botaoAcoes.hide();">
                  Débitos
                </button>
              </div>
            </ng-template>
          </td>

          <td class="text-center">
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>

          <td class="text-center text-nowrap">
            {{ processo.numeroProcesso | formatProcessoPipe:processo.inTamanhoProcesso | vazio }}
          </td>

          <td class="text-center text-nowrap" *ngIf="isPessoaFisica">
            {{ processo.ni | formatCpfPipe }}
          </td>

          <td class="text-center text-nowrap" *ngIf="isPessoaJuridica">
            {{ processo.ni | formatCnpjPipe }}
          </td>

          <td class="text-left">{{ processo.situacao }}</td>
        </tr>

        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="5">
              <div class="contorno">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label label-formulario">Localização</label>
                    <p class="form-control-static dado-formulario">{{ processo.localizacao }}</p>
                  </div>

                  <div class="col-md-6" *ngIf="processo.dataValidadeAnaliseMedidaJudicial">
                    <label class="control-label label-formulario">Validade da Análise da Medida Judicial</label>
                    <p class="form-control-static dado-formulario">
                      {{ processo.dataValidadeAnaliseMedidaJudicial | date: 'dd/MM/yyyy' | vazio }}
                    </p>
                  </div>

                  <div class="col-md-6" *ngIf="!processo.dataValidadeAnaliseMedidaJudicial">
                    <label class="control-label label-formulario">Prazo Final</label>
                    <p class="form-control-static dado-formulario">{{ processo.dataPrazoFinal | date: 'dd/MM/yyyy' | vazio }}</p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>

    <tfoot>
      <tr class="tabela-acoes text-left">
        <td colspan="5">
          <via-pagination [itemAmount]="processos.length" [(page)]="currentPage"></via-pagination>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
