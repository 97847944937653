import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { PainelAtendimentosService } from '../painel-atendimentos/painel-atendimentos.service';

@Component({
  selector: 'via-entrada-ni',
  templateUrl: './entrada-ni.component.html',
  styleUrls: ['./entrada-ni.component.css']
})
export class EntradaNiComponent implements AfterViewInit {

  // idenfificador do NI em atendimento
  ni: string = '';

  maxLenghtUnmasked = 14;

  @ViewChild('niInput') niInput: ElementRef;

  constructor(
    private painelAtendimentosService: PainelAtendimentosService,
    private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.renderer.listen(this.niInput.nativeElement, 'paste', (event: ClipboardEvent): void => {
      event.preventDefault();

      const data = event.clipboardData.getData('text');

      if (data) {
        this.sanitizeInput(data);
      }
    });
  }

  criarAba(): void {
    this.painelAtendimentosService.createTab(this.ni);
    this.ni = '';
  }

  private sanitizeInput(ni: string): void {
    const unmaskedNi = ni.replace(/[^\d]+/g, '');

    if (unmaskedNi.length > this.maxLenghtUnmasked) {
      this.ni = '';
      return;
    }

    this.ni = unmaskedNi;
  }
}
