import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { ResumoAlocacaoPagamento } from '../../models/pagamento';

@Component({
  selector: 'via-ccpf-aloca-pagamento-detalhe-alocacoes',
  templateUrl: './aloca-pagamento-detalhe-alocacoes.component.html',
  styleUrls: ['../aloca-pagamento.component.css']
})
export class CcpfAlocaPagamentoDetalheAlocacoesComponent {

  @Input() atendimento: boolean = false;
  @Input() alocacoes: ResumoAlocacaoPagamento[] = [];

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(item => item.show = show);
  }
}
