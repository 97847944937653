import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Page, ToggleComponent } from '@serpro/ngx-suite-rfb';
import { DebitoSief } from 'src/app/sitfis/model/debito-sief';
import {  CodigoReceita, OrdemDARF} from './../../../model/enum/arrecadacao';

@Component({
  selector: 'via-debito-processo-sief-table',
  templateUrl: './debito-processo-sief-table.component.html',
  styleUrls: ['./debito-processo-sief-table.component.css']
})
export class DebitoProcessoSiefTableComponent {

  @Input() numeroProcesso: string;

  @Input() debitos: DebitoSief[];

  @Output() emitirDarf = new EventEmitter<DebitoSief>();
  @Output() emitirDas = new EventEmitter<{ numeroProcesso: string, debito: DebitoSief }>();

  @ViewChildren('rowToggle') rowToggleQuery: QueryList<ToggleComponent>;

  ordemDARF= OrdemDARF;
  codigoReceita= CodigoReceita;

  pageSize: number = 10;
  currentPage: Page = {
    number: 1,
    first: 0,
    last: this.pageSize
  };

  expandedAll(show: boolean): void {
    this.rowToggleQuery.forEach(toggle => toggle.show = show);
  }

  onEmitirDarf(debito: DebitoSief): void {
    this.emitirDarf.emit(debito);
  }

  onEmitirDas(debito: DebitoSief): void {
    this.emitirDas.emit({ numeroProcesso: this.numeroProcesso, debito });
  }
}
