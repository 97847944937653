import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiteRfbCollapseModule, SuiteRfbPaginationModule, SuiteRfbPopoverModule, SuiteRfbTableModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { DebitoSicobComponent, DebitoSicobPFAtivoComponent, DebitoSicobPFSuspensoComponent, DebitoSicobPJAtivoComponent, DebitoSicobPJSuspensoComponent } from './debito-sicob.component';
import { DebitoSicobService } from './debito-sicob.service';
import { DebitoSicobTableComponent } from './table/debito-sicob-table.component';

@NgModule({
  declarations: [
    DebitoSicobTableComponent,
    DebitoSicobComponent,
    DebitoSicobPFAtivoComponent,
    DebitoSicobPFSuspensoComponent,
    DebitoSicobPJAtivoComponent,
    DebitoSicobPJSuspensoComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forChild(),
    SharedModule,
    PipesModule,
    SitfisPipesModule,
    SuiteRfbPaginationModule,
    SuiteRfbCollapseModule,
    SuiteRfbTableModule,
    SuiteRfbPopoverModule,
    SuiteRfbToggleModule
  ],
  providers: [
    DebitoSicobService
  ],
  entryComponents: [
    DebitoSicobComponent,
    DebitoSicobPFAtivoComponent,
    DebitoSicobPFSuspensoComponent,
    DebitoSicobPJAtivoComponent,
    DebitoSicobPJSuspensoComponent
  ]
})
export class DebitoSicobModule { }
