import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from '@serpro/ngx-suite-rfb';
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable(
  { providedIn: 'root' }
)
export class AplicacaoInfoService {

  private baseUrl = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) { }

  public getBackendVersion(): Observable<string> {
    const url = this.baseUrl + '/info';
    const options = {
      responseType: 'text' as 'json',
    };
    return this.http.get<string>(url, options);
  }

  public getFrontendVersion(): string {
    return environment.version;
  }

  public getEnvironment(): string {
    if (this.baseUrl.includes('suiterfb')) {
      return this.environmentService.environment.description;
    } else {
      return 'Desenvolvimento (Local)'
    }
  }
}
