<table suiteRfbTable class="tabelaInterna" [class.tabela_largura_minima]="atendimento">
  <caption class="caption-ccpf">Componentes</caption>
  <ng-container *ngIf="!semComponente; else nenhumComponente;">
    <thead>
      <tr>
        <th class="text-center min-width">
          <suite-rfb-toggle
            title="Expandir/Contrair Todos"
            (expanded)="expandedAll($event)">
          </suite-rfb-toggle>
        </th>
        <th class="text-center">Número</th>
        <th class="text-center">Período de Apuração</th>
        <th class="text-center">Receita</th>
        <th class="text-center">Data de Vencimento</th>
        <th class="text-center">Valor Original</th>
        <th class="text-center">Situação</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let componente of componentes | currentPage: currentPage;" class="vertical-align-middle">
        <tr class="vertical-align-middle">
          <td>
            <suite-rfb-toggle #rowToggle></suite-rfb-toggle>
          </td>
          <td class="text-center">{{ componente.numero | vazio }}</td>
          <td class="text-center">{{ componente.periodoApuracao | formatDataPipe }}</td>
          <td class="text-center">
            <via-codigo-receita [codigo]="componente.receita"></via-codigo-receita>
          </td>
          <td class="text-center">{{ componente.dataVencimento | formatDataPipe }}</td>
          <td class="text-right">{{ componente.valorOriginal | formatCurrency }}</td>
          <td class="text-left">{{ componente.situacao | vazio }}</td>
        </tr>
        <ng-container *ngIf="rowToggle.show">
          <tr></tr>
          <tr>
            <td colspan="7" class="background-inherit" >
              <via-detalhe-componentes-dados-consolidar-ct [componente]="componente">
              </via-detalhe-componentes-dados-consolidar-ct>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </ng-container>
  <ng-template #nenhumComponente>
    <tr class="vertical-align-middle">
      <td class="text-center" colspan="7">
        Nenhum componente encontrado para este crédito tributário.
      </td>
    </tr>
  </ng-template>
</table>
