import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByAttributePipe'
})
export class SortByAttributePipe implements PipeTransform {
  transform(listaQq: any[], asc: boolean, atributo: string): any[] {
    // cria uma nova instancia de array (_tmp) para que os pipes puros detectem a mudanca no array
    // ver: https://angular.io/guide/pipes#pure-and-impure-pipes
    return (!listaQq) ? undefined : (asc === undefined) ? listaQq : [].concat(listaQq.sort((item1, item2) => {
      let sort = null;
      //TODO: estudar para fazer uma função que vá pegando os atributos encadeados pelos pontos, para o caso
      // de sortear por atributos em mais níveis do lote. Algo do tipo: ver se tem "." no nomeAtributo e ir encadeando
      if (typeof item1[atributo] == "string") {
        sort = item1[atributo].localeCompare(item2[atributo]);
      }
      //campos de data trata como number
      else {
        if (item1[atributo] == null || typeof item1[atributo] == "number") {
          let atr1 = item1[atributo];
          let atr2 = item2[atributo];
          if (item1[atributo] == null) {
            atr1 = 0;
          }
          if (item2[atributo] == null) {
            atr2 = 0;
          }
          sort = atr1.toString().localeCompare(atr2.toString());
        }
      }
      return asc ? sort : (-1) * sort;
    }));
  }
}
