import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SitfisPipesModule } from '../../pipes/sitfis-pipes.module';
import { SuiteRfbPaginationModule, SuiteRfbCollapseModule, SuiteRfbTableModule, SuiteRfbPopoverModule, SuiteRfbToggleModule } from '@serpro/ngx-suite-rfb';
import { DebitoDividaPFAtivoComponent, DebitoDividaPFSuspensoComponent, DebitoDividaPJAtivoComponent, DebitoDividaPJSuspensoComponent } from './debito-divida.component';
import { DebitoDividaComponent } from './debito-divida.component';
import { DebitoDividaService } from './debito-divida.service';
import { DebitoDividaTableComponent } from './table/debito-divida-table.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule( {
    declarations: [
        DebitoDividaTableComponent,
        DebitoDividaComponent,
        DebitoDividaPFAtivoComponent,
        DebitoDividaPFSuspensoComponent,
        DebitoDividaPJAtivoComponent,
        DebitoDividaPJSuspensoComponent
    ],
    imports: [
        CommonModule,
        NgxMaskModule.forChild(),
        SharedModule,
        PipesModule,
        SitfisPipesModule,
        SuiteRfbPaginationModule,
        SuiteRfbCollapseModule,
        SuiteRfbTableModule,
        SuiteRfbPopoverModule,
        SuiteRfbToggleModule
    ],
    providers: [
        DebitoDividaService
    ],
    entryComponents: [
        DebitoDividaComponent,
        DebitoDividaPFAtivoComponent,
        DebitoDividaPFSuspensoComponent,
        DebitoDividaPJAtivoComponent,
        DebitoDividaPJSuspensoComponent
    ]
})
export class DebitoDividaModule {}
