import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCnpjPipe'
})
export class FormatCnpjPipe implements PipeTransform {
  transform(nroCnpj: number) {
      return FormatCnpjPipe.formatCNPJ(nroCnpj);
  }

  //static formatCNPJ: Function = (nroCnpj: number) => {
  static formatCNPJ: Function = (nroCnpj: any) => {
    if(nroCnpj){
      let pad = "00000000000000";
      let cnpj14pos = (pad+nroCnpj).slice(-pad.length);
      return cnpj14pos.substring(0,2).concat(".")
                                  .concat(cnpj14pos.substring(2,5))
                                  .concat(".")
                                  .concat(cnpj14pos.substring(5,8))
                                  .concat("/")
                                  .concat(cnpj14pos.substring(8,12))
                                  .concat("-")
                                  .concat(cnpj14pos.substring(12,14));
    }
  }
}



